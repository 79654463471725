import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LeftAngle from '@/assets/Icons/LeftAngleIcon.png';
import MainDetailSection from './DoctorDetails/MainDetailSection';
import { ContextProvider } from '@/context/ProjectContext';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { Context as AuthContext } from '@/context/AuthContext';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('md')]: {
            paddingBottom: 30,
        },

        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 20,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        marginTop: 1,
        textTransform: 'none',
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    goBackIcon: {
        height: 14,
        marginRight: 10,
    },
    progressBar: {
        color: theme.palette.common.yellow,
    },
    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const DoctorsDetails = () => {
    const classes = useStyles();
    let { id } = useParams();
    let history = useHistory();
    const { i18n } = useTranslation();

    const { setMyAppointmentsData, setMyAppointmentsDataStatus, myAppointmentsData, myAppointmentsDataStatus } =
        useContext(ContextProvider);

    const { [i18n.language]: registerPrismicData } = usePersistedPrismicStore((state) => state.professionalProfileData);
    const { dataSet, content, mainData } = registerPrismicData;

    const [loading, setLoading] = useState(true);
    const {
        state: { user },
    } = useContext(AuthContext);

    console.debug('step1: mainData', mainData, content);

    const renderStep = () => {
        console.debug('step3', id, content);
        const practitionerData = content.find((fil) => fil.key[0].text === id);
        if (user && !practitionerData) {
            history.replace('/practitioners');
            return;
        }
        const data = {
            'en-us': practitionerData,
            'de-de': practitionerData,
        };
        console.debug('step3: data book', data);
        setMyAppointmentsData(data);
        setMyAppointmentsDataStatus(true);
        setTimeout(() => {
            setLoading(false);
        }, [1000]);
    };

    useEffect(() => {
        if (content && id) renderStep();
    }, [content]);

    useEffect(() => {
        if (user && !loading && !myAppointmentsData) {
            history.replace('/practitioners');
        }
    }, [loading]);

    return (
        <>
            {myAppointmentsDataStatus && (
                <div className={classes.contentContainer}>
                    <div
                        className={classes.goBack}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <img src={LeftAngle} alt="Go Bac" className={classes.goBackIcon} />
                        <Typography className={classes.shortText}>
                            {dataSet ? mainData?.go_back_cta[0].text : 'Go Bak'}
                        </Typography>
                    </div>
                    <MainDetailSection
                        mainData={mainData}
                        dataSet={dataSet}
                        myAppointmentsData={myAppointmentsData}
                        myAppointmentsDataStatus={myAppointmentsDataStatus}
                    />
                </div>
            )}

            {loading && (
                <div className={classes.loadingProgress}>
                    <CircularProgress className={classes.progressBar} size={50} />
                </div>
            )}
        </>
    );
};

export default DoctorsDetails;
