import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import i18next from 'i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { useContext, useEffect } from 'react';
import { useConfigStore, usePersistedPrismicStore } from '@/store';
import { Context as AuthContext } from '@/context/AuthContext';
import { ContextProvider } from '@/context/ProjectContext';
import { isEternoDomain, processAPIWithHeaders } from '@/utils/helpers';
import { FormControl, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ic_facebook from '@/assets/Icons/ic_facebook.svg';
import ic_instagram from '@/assets/Icons/ic_instagram.svg';
import { dayjs } from '@/utils/dayjsSetup';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footer: {
        padding: '150px 50px 40px 50px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontFamily: 'MessinaSans-Regular',
        width: '100%',
        maxWidth: theme.layout.contentWidth,
    },
    line: {
        background: 'rgba(46, 46, 46, 0.15)',
        height: '1px',
        width: '100%',
        marginBottom: '40px',
    },
    contentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
    },
    contentWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: '1.5rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    linksContainer: {
        display: 'flex',
        gap: '1.5rem',
        flexWrap: 'wrap',

        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            flexWrap: 'unset',
            flexDirection: 'column',
        },
    },
    link: {
        textDecoration: 'none',
        color: 'rgba(46, 46, 46, 0.70)',
        fontSize: '1rem',
        textAlign: 'center',
    },
    registrationNoteTitle: {
        color: theme.palette.common.red,
        fontSize: '1rem',
    },
    registrationNoteText: {
        color: 'rgba(46, 46, 46, 0.50)',
        fontSize: '1rem',
    },
    copyrightLabel: {
        color: 'rgba(46, 46, 46, 0.50)',
        fontSize: '1rem',
    },
    languageDropdownWrapper: {
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0.5rem',
    },
    languageDropdown: {
        width: '180px',
        borderRadius: '0.5rem',
        border: '1px solid rgba(46, 46, 46, 0.70)',
        padding: '0.75rem 1rem',
        '&:active': {
            borderRadius: '0.5rem',
        },
        '&:focus': {
            borderRadius: '0.5rem',
        },
    },
    socialLinks: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
    },
    socialIcon: {
        height: 30,
        width: 30,
    },
    legalContainer: {
        '& > *': {
            color: 'rgba(46, 46, 46, 0.50)',
            fontSize: '12px',
        },
    },
}));

const SocialPlatform = {
    INSTAGRAM: ic_instagram,
    FACEBOOK: ic_facebook,
};

const Footer = () => {
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const { setLanguageGlobal } = useContext(ContextProvider);
    const { [i18n.language]: footerPrismicData } = usePersistedPrismicStore((state) => state.footerData);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    useEffect(() => {
        const lang = localStorage.getItem('languageGlobal');
        setLanguageGlobal(lang || 'de');
    }, []);

    const {
        state: { usersession, userAccount },
        setuseraccounts,
    } = useContext(AuthContext);

    const updateLanguage = (language = '') => {
        if (language !== '' && language !== i18n.language) {
            i18next.changeLanguage(language, (err) => {
                if (err) return console.debug('something went wrong loading', err);

                setLanguageGlobal(language);
                localStorage.setItem('languageGlobal', language);
            });
            if (usersession) {
                updateUserAccount(language);
            }
        }
    };

    const updateUserAccount = async (language) => {
        const updateAccount = await processAPIWithHeaders('secure/user/accounts/' + userAccount.user_id, 'POST', {
            body: {
                user_id: userAccount.user_id,
                privacy_policy_status: userAccount.privacy_policy_status,
                terms_and_conditions_status: userAccount.terms_and_conditions_status,
                use_of_analytics_status: userAccount.use_of_analytics_status,
                verification_status: userAccount.verification_status,
                locale: {
                    language: language,
                    country: userAccount.locale.country,
                },
                status: userAccount.status,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: usersession.idToken.jwtToken,
            },
        });
        setuseraccounts(updateAccount);
    };

    if (!footerPrismicData.dataSet) return null;

    return (
        <div className={classes.container}>
            <div className={classes.footer}>
                <div className={classes.line} />
                <div className={classes.contentContainer}>
                    <div className={classes.contentWrapper}>
                        <div className={classes.linksContainer}>
                            {currentCustomer.footer_links.map((footerLink) => (
                                <a
                                    key={footerLink.link.url}
                                    className={classes.link}
                                    target="_self"
                                    rel="noopener noreferrer"
                                    href={footerLink.link.url}
                                >
                                    {footerLink.title[0].text}
                                </a>
                            ))}
                        </div>
                        <FormControl className={classes.languageDropdownWrapper}>
                            <Select
                                disableUnderline
                                onChange={(e) => updateLanguage(e.target.value)}
                                value={i18n.language}
                                classes={{ root: classes.languageDropdown }}
                            >
                                {footerPrismicData.content.language_values.map((languageOption) => (
                                    <MenuItem
                                        key={languageOption.language_code}
                                        disableRipple
                                        value={languageOption.language_code}
                                    >
                                        {languageOption.display_value[0].text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {currentCustomer?.is_registration_hint_shown && (
                        <div>
                            <Typography className={classes.registrationNoteTitle}>
                                {t('RegistrationNoteTitle')}:
                            </Typography>
                            <Typography className={classes.registrationNoteText}>
                                {t('RegistrationNoteText')}
                            </Typography>
                        </div>
                    )}
                    <div className={classes.contentWrapper}>
                        <Typography className={classes.copyrightLabel}>
                            {`Copyright © ${dayjs().format('YYYY')} Eterno GmbH`}
                        </Typography>
                        <div className={classes.socialLinks}>
                            {!currentCustomer ? (
                                <>
                                    <a href={footerPrismicData.content.fb_url.url} target="_self">
                                        <img src={ic_facebook ?? ''} alt="facebook link" />
                                    </a>
                                    <a href={footerPrismicData.content.instagram_url.url} target="_self">
                                        <img src={ic_instagram ?? ''} alt="instagram link" />
                                    </a>
                                </>
                            ) : (
                                currentCustomer.social_media_links.map((socialLink) => (
                                    <a key={socialLink.link.url} href={socialLink.link.url} target="_self">
                                        <img
                                            src={SocialPlatform[socialLink.platform]}
                                            alt={`${socialLink.platform.toLowerCase()} link`}
                                        />
                                    </a>
                                ))
                            )}
                        </div>
                    </div>
                    {!isEternoDomain() && (
                        <div className={classes.legalContainer}>
                            <span>{footerPrismicData.content.legal_text[0].text.split('{link_placeholder}')[0]}</span>
                            <a href={footerPrismicData.content.legal_link.url} target="_blank">
                                {footerPrismicData.content.legal_link_text[0].text}
                            </a>
                            <span>{footerPrismicData.content.legal_text[0].text.split('{link_placeholder}')[1]}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Footer;
