import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import FilledButton from '../FilledButton';
import UnderlinedButton from '../UnderlinedButton';
import close from '@/assets/Icons/close_grey.svg';
import FileDrop from './UploadDocumentComponents/FileDrop';
import FileUploaded from './UploadDocumentComponents/FileUploaded';
// Contexts
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { Context as AuthContext } from '@/context/AuthContext';
import { Context as DocumentContext } from '@/context/DocumentContext';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        backgroundColor: 'transparent',
    },
    scrollRoot: {
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    text: {
        ...theme.typography.content,
        marginTop: 10,
    },
    title: {
        ...theme.typography.HL1,
        marginTop: 10,
        width: '90%',
        padding: 0,
    },
    btnClose: {
        position: 'absolute',
        right: 10,
    },
}));

const UploadFileSteps = (setFile, setDocType, fileName, setFileName, setSpecialtyType) => {
    const {
        state: { fileUploadSteps },
    } = useContext(HealthProfileContextMain);

    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
    }, []);

    switch (parseInt(fileUploadSteps)) {
        case 1:
            return <FileDrop onDrop={onDrop} />;
        case 2:
            return (
                <FileUploaded
                    setDocType={setDocType}
                    fileName={fileName}
                    setFileName={setFileName}
                    setSpecialtyType={setSpecialtyType}
                />
            );
        default:
            return <FileDrop onDrop={onDrop} />;
    }
};

const LegacyUploadDocumentModal = ({ open, onClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesmd = useMediaQuery(theme.breakpoints.down('md'));
    const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesxl = useMediaQuery(theme.breakpoints.down('xl'));

    // context
    const {
        state: { acceptedFileName, isUploadButtonDisabled, uploadedByMeData },
        resetFile,
        setIsDocumentAvailable,
    } = useContext(HealthProfileContextMain);

    const {
        state: { user, usersession, userDocuments },
        fetchUserAllDocuments,
    } = useContext(AuthContext);

    // document type (will set by child component - TypeOfDoc.jsx)
    const [docType, setDocType] = useState('');
    const [specialtyType, setSpecialtyType] = useState('');
    const [fileName, setFileName] = useState(acceptedFileName);

    // ---- Document Context ----
    const {
        state: { isDocumentUploading, isDocumentUploaded },
        beginFileUpload,
    } = useContext(DocumentContext);

    // ---- document state ----
    const [file, setFile] = useState([]);

    useEffect(() => {
        console.debug('files', file);
    }, [file]);

    useMemo(() => {
        if (isDocumentUploaded) {
            // Reset uploaded file from context
            resetFile();
            // closes modal
            onClose();
        }
    }, [isDocumentUploaded]);

    // ---- upload document ----
    const prepareToUpload = async (file = null) => {
        if (file && user && user['id'] !== undefined && usersession) {
            let user_id = user.id;
            let type = docType.toUpperCase();
            let mime_type = file.type;

            // Extract the file name without the extension
            const lastDotIndex = fileName.lastIndexOf('.');
            const fileNameWithoutExtension = lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName;

            let data = {
                user_id,
                type,
                mime_type,
                name: fileNameWithoutExtension,
                specialty_type: specialtyType || '',
            };
            console.debug('fileUpload: Preparing to process this data:', data);
            await beginFileUpload(data, file, usersession);
            const authUser = await Auth.currentAuthenticatedUser();
            await fetchUserAllDocuments(authUser.signInUserSession);
        }
    };

    useMemo(() => {
        if (userDocuments?.data.length > 0) {
            setIsDocumentAvailable(true);
        }
    }, [userDocuments]);

    return (
        <>
            {Object.keys(uploadedByMeData).length !== 0 && (
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{
                        root: classes.dialogRoot,
                    }}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                            // minHeight:  378,
                            width: '609px',
                            // maxWidth: 570,
                            borderRadius: 8,

                            paddingLeft: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                            paddingRight: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                            paddingTop: matches ? 20 : matchesmd ? 20 : matcheslg ? 20 : matchesxl && 20,
                            paddingBottom: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                        },
                    }}
                >
                    <Button color="primary" className={classes.btnClose} onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: 20,
                                height: 20,
                            }}
                        />
                    </Button>
                    <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
                        {uploadedByMeData?.popup_title_upload[0].text ?? 'Upload your document'}
                    </DialogTitle>

                    <DialogContent
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            paddingBottom: 20,
                            paddingLeft: 0,
                            paddingRight: 0,
                            padding: 0,
                        }}
                    >
                        <DialogContentText
                            id="alert-dialog-description"
                            classes={{
                                root: classes.text,
                            }}
                        >
                            {uploadedByMeData?.subtitle_upload[0].text}{' '}
                        </DialogContentText>

                        {/* File Drop Area Code */}
                        <div>{UploadFileSteps(setFile, setDocType, fileName, setFileName, setSpecialtyType)}</div>
                        {/* File Drop Area Code */}

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: matches ? 'column' : 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                                marginTop: 20,
                            }}
                        >
                            <FilledButton
                                disabled={isUploadButtonDisabled || isDocumentUploading}
                                text={
                                    isDocumentUploading ? (
                                        <CircularProgress />
                                    ) : (
                                        uploadedByMeData?.save_cta[0].text || 'Save'
                                    )
                                }
                                onPress={() => {
                                    // upload file
                                    prepareToUpload(file);
                                }}
                                fullWidth={matches && true}
                            />

                            <div
                                style={{
                                    display: !matches ? 'flex' : 'contents',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <UnderlinedButton
                                    text={uploadedByMeData?.cancel_cta[0].text ?? 'Cancel'}
                                    onPress={() => {
                                        // Reset uploaded file from context
                                        resetFile();
                                        // closes modal
                                        onClose();
                                    }}
                                    noMarginTop={!matches && true}
                                    marginLeft={!matches && 30}
                                    fullWidth={matches && true}
                                    noJSLeft={true}
                                />
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default LegacyUploadDocumentModal;
