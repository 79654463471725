import { makeStyles } from '@material-ui/core/styles';

const StepMenuStyles = makeStyles((theme) => ({
    flex: { display: 'flex' },
    w100: { width: '100%' },
    mainHeading: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: 28,
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
    show: { visibility: 'visible' },
    hide: { visibility: 'hidden' },
    heading: {
        ...theme.typography.HL1,
    },
    paragraph: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('sm')]: {
            fontSize: '.9rem',
        },
    },

    sideMenu: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        width: '80%',
    },

    menuItem: {
        fontFamily: 'MessinaSans-Regular',
        color: theme.palette.common.brown,
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: 15,
    },

    highlightText: {
        color: `${theme.palette.common.footerGreen} !important`,
    },

    checkIcon: {
        width: 18,
        height: 18,
    },

    //** Consent Treatment */
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        textTransform: 'none',
        marginBottom: 0,
        fontWeight: 600,
        marginLeft: 10,
        whiteSpace: 'nowrap',
    },
    progressBar: {
        color: theme.palette.common.yellow,
    },
    progressBarSideBar: {
        color: theme.palette.common.green,
    },
    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexClass: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    progressText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    greenImg: {
        width: 18,
        height: 18,
    },
    hamIcon: {
        height: 24,
        width: 18,
    },

    formControl: {
        backgroundColor: 'white',
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        paddingTop: 13,
        paddingBottom: 13,
        '&:hover': {
            backgroundColor: 'white',
        },
    },

    sideBarMain: {
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
    },
    classRoot: {
        minWidth: 0,
        paddingLeft: 13,
        paddingRight: 13,
        borderTopLeftRadius: 4,
        borderEndEndRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
    },
    borderLeftNone: { borderBottomLeftRadius: 0 },
    menuRoot: {
        paddingLeft: 0,
        paddingRight: 0,
        whiteSpace: 'inherit',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    // circular progressBar
    root: {
        position: 'relative',
        width: 'fit-content',
    },
    bottom: {
        color: '#D5D5D5',
    },
    top: {
        color: theme.palette.common.footerGreen,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        borderRadius: 0,
    },
    mobileMenuItemIcon: {
        flex: 0.2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileMenuItemText: {
        flex: 0.7,
    },
    mobileMenuText: {
        marginBottom: 5,
        marginTop: 5,
        color: theme.palette.common.brown,
    },
    unSelect: {
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
}));

export const menuPropsStyles = {
    backgroundColor: 'white',
    boxShadow: '0px 8px 12px rgba(120, 120, 120, 0.21)',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
};

export default StepMenuStyles;
