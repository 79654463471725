import { memo } from 'react';
import { IconButton, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import pencilIcon from '@/assets/images/pencilIcon.png';

const useStyles = makeStyles((theme) => ({
    mainPage: {
        backgroundColor: theme.palette.common.white,
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
        position: 'relative',
        right: 0,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    editButton: {
        position: 'absolute',
        top: '.8rem',
        right: '.8rem',
    },
}));

const CardWrapper = ({ children, isEditable, onEdit, height, pBlockXs, pInlineXs, mt = true }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div
            className={classes.mainPage}
            style={{
                marginTop: mt ? 20 : null,
                minHeight: height ? height : null,
                paddingBlock: matchesXs ? pBlockXs : null,
                paddingInline: matchesXs ? pInlineXs : null,
            }}
        >
            {children}
            {isEditable && (
                <IconButton onClick={onEdit} className={classes.editButton} disableRipple>
                    <img src={pencilIcon} />
                </IconButton>
            )}
        </div>
    );
};

export default memo(CardWrapper);
