import React from 'react';
import DateOfBirthField from './DateOfBirh/DateOfBirthField';
import DateOfMonthAndYear from './DateOfMonthAndYear/DateOfMonthAndYear';
import InsuranceDate from './InsuranceDate/InsuranceDate';

export const DateTextField = ({
    type,
    separator,
    setDOB,
    dob,
    setError,
    error,
    errorMessage,
    placeHolderMonth,
    placeHolderYear,
    placeHolderDay,
    mainHeading,
    requiredDateField,
    mt = 15,
    isOnBlur,
    onBlur,
}) => {
    const properties = {
        type,
        separator,
        setDOB,
        dob,
        setError,
        error,
        errorMessage,
        placeHolderMonth,
        placeHolderYear,
        placeHolderDay,
        mainHeading,
        requiredDateField,
        mt,
        isOnBlur,
        onBlur,
    };

    const DateComponentsMap = {
        dateOfBirth: DateOfBirthField, //lazy(() => import('./DateOfBirh/DateOfBirthField')),
        monthAndYear: DateOfMonthAndYear, // lazy(() => import('./DateOfMonthAndYear/DateOfMonthAndYear')),
        insuranceDate: InsuranceDate, // lazy(() => import('./InsuranceDate/InsuranceDate')),
    };

    const renderDateFields = () => {
        const Component = DateComponentsMap[type];
        return (
            <div>
                {/* <Suspense fallback={<div>Loading</div>}> */}
                <Component {...properties} />
                {/* </Suspense> */}
            </div>
        );
    };

    return (
        <>
            <div className="">{renderDateFields()}</div>
        </>
    );
};
