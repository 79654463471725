import { useEffect } from 'react';
import { professionalsId } from '@/service/AnamnesisSectionAPI/ProfessionalAPI';
import { useProfessionalsStore } from '@/store';
import useSWRImmutable from 'swr/immutable';

const useProfessionalMappings = () => {
    const professionalMappings = useProfessionalsStore((state) => state.professionalMappings);
    const { data: response, isLoading } = useSWRImmutable(
        !professionalMappings ? 'professional-mappings' : null,
        professionalsId
    );

    useEffect(() => {
        if (isLoading || professionalMappings || !response?.data?.data) return;

        const professionalMappingsObj = response.data.data.reduce((obj, entry) => {
            if (!entry.prismic_key) return obj;

            obj[entry.prismic_key] = entry;
            return obj;
        }, {});

        useProfessionalsStore.setState({ professionalMappings: professionalMappingsObj });
    }, [response]);
};

export default useProfessionalMappings;
