import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import App from './App';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import { AuthContext } from '@/context/ProjectContext';
import { AuthContextTwo } from '@/context/DoctorsContextTwo';
import { useLoggingStore, usePersistedPrismicStore } from '@/store';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@/components/ErrorFallback';

Amplify.configure(awsExports);

// hydrate prismic store
usePersistedPrismicStore.getState().loadStore();

const logError = (error, info) => {
    console.debug(error);
    console.debug(info);
    console.debug(useLoggingStore.getState().context);
};

ReactDOM.createRoot(document.getElementById('root')).render(
    <AuthContextTwo>
        <AuthContext>
            <ErrorBoundary onError={logError} FallbackComponent={ErrorFallback}>
                <App />
            </ErrorBoundary>
        </AuthContext>
    </AuthContextTwo>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
