import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import FilledButton from '@/components/FilledButton';
import { useHistory } from 'react-router-dom';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import OutlinedButton from '@/components/OutlinedButton';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { yourBodyAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';
import { useStyles } from './ExistingConditionComponents/ExistingConditionStyles';
import ExistingConditionPast from './ExistingConditionComponents/ExistingConditonPast';
import ExistingConditionHadSurgery from './ExistingConditionComponents/ExistingConditionHadSurgery';
import ExistingConditionTestPerfomed from './ExistingConditionComponents/ExistingConditionTestPerfomed';
import ExistingConditionImagingPerfomed from './ExistingConditionComponents/ExistingConditionImagingPerfomed.jsx';
import { ContextProvider } from '@/context/ProjectContext';
import { anamnesisDateFormat, getLocalDateMonth, menuItems, trackStormlyAnamnesisSection } from '@/utils/helpers';
import { Context as AuthContext } from '@/context/AuthContext';
import scrollIntoView from 'scroll-into-view';
import { useTrackingStore } from '@/store';
import useFormSteps from '@/hooks/useFormSteps';

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

function ExistingCondition({ mainData, allData, user, usersession, isFromHealthData = false }) {
    // console.debug('🚀 ~ file: ExistingCondition.jsx:28 ~ mainData:', mainData);
    const { i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [anySurgeryLocal, setAnySuergeryLocal] = useState([]);
    const [loaclDelete, setLocalDelete] = useState([]);
    const [addingImaging, setAddingImaging] = useState([]);
    const [sergeriesArr1, setSergeriesArr1] = useState([]);
    const [checkBoxObj, setCheckBoxObj] = useState({});
    const [errorObj, setErrorObj] = useState({});
    const [errOthers, setErrOthers] = useState('');
    const [counter, setCounter] = useState(0);
    let haveProperty = checkBoxObj.hasOwnProperty(0);

    // context for const variables
    const {
        pastCondition,
        setPastCondition,
        setSurgeries,
        setSergeriesArr,
        setSpecific,
        setSergeriesQuestion,
        setSergerieDate,
        setSergeryDataAll,
        setNewCondition,
        setEditState,
        setCancelEdit,
        setEditPencil,
        setSeugeryData,
        surgeries,
        specific,
        surgeriesArr,
        coditionStatus,
        sergeriesQuestion,
        sergerieDate,
        sergeryDataAll,
        newCondition,
        cancelEdit,
        surgeryData,
        setWhenPastCondition,
        whenPastCondition,
        setTestPerfomed,
        setTestPerfomedDate,
        setTestPerfomedArr,
        setTestPerfomedArrEasy,
        testPerfomed,
        testPerfomedDate,
        testPerfomedArr,
        testPerfomedArrEasy,
        setTestEdit,
        setCancelTestEdit,
        cancelTestEdit,
        //** Imaging states for Test Comp */
        imagingPerfomed,
        setImagingPerfomed,
        imagingPerfomedDate,
        setImagingPerfomedDate,
        imagingPerfomedArr,
        setImagingPerfomedArr,
        imagingPerfomedArrEasy,
        setImagingPerfomedArrEasy,
        setImagingEdit,
        cancelImagingEdit,
        setCancelImagingEdit,
        setBody5,
        body5,
        allDataApi,
        setHasAccount,
        error,
        errorDate2,
        errorDate3,
        errorDate4,
        otherFieldError,
        setOtherFieldError,
        newFromTrue,
        setNewFromTrue,
    } = useContext(ContextProviderTwo);

    const messageRefTwo = useRef();
    useEffect(() => {
        console.debug('sergeriesArr12', counter);
        if (messageRefTwo.current) {
            messageRefTwo.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }, [counter]);

    useEffect(() => {
        useTrackingStore.setState({
            anamnesisSectionStarted: Date.now(),
            currentAnamnesisSection: menuItems()[step - 1],
        });
    }, []);

    const { languageGlobal } = useContext(ContextProvider);
    let total = mainData['pre-existing_conditions_answers'];

    console.debug(
        '🚀 ~ file: ExistingCondition.jsx ~ line 81 ~ coditionStatus',
        coditionStatus,
        pastCondition,
        isFromHealthData,
        surgeriesArr,
        surgeriesArr.length === 0,
        surgeriesArr.length === 0 || pastCondition === '',
        pastCondition === '' && (surgeriesArr.length === 0 || pastCondition === '') && isFromHealthData
    );

    const {
        state: { userData },
    } = useContext(AuthContext);

    const {
        state: { step, save_changes_option, existing_condition, is_edit_overview },
        BackBtn,
        UpdateSteps,
        setExistingCondition,
        onSaveButton,
        onCancelButton,
        setIsOpenState,
        setReviewOption,
    } = useContext(HealthProfileContextMain);

    const { isConsentAllForms, isLastStep } = useFormSteps();

    let lan = languageMap[i18n.language];

    useEffect(() => {
        if (pastCondition === 'NONE OF THE ABOVE') {
            return;
        }
        setPastCondition('');
    }, []);

    // edited

    useEffect(() => {
        if (surgeriesArr?.length > 0) {
            let fatherDiseasee = [];
            {
                surgeriesArr.forEach((medi) => {
                    let fatherDisease = {};
                    Object.keys(allData).forEach((val) => {
                        if (val === 'en-us') {
                            allData[val]['pre-existing_conditions_answers'].find((valu) => {
                                if (medi.pastCondition === valu['pre-existing_conditions_response_api_value']) {
                                    fatherDisease['en-us'] = valu['pre-existing_conditions_answer'][0].text;
                                }
                            });
                        }
                        if (val === 'de-de') {
                            allData[val]['pre-existing_conditions_answers'].find((valu) => {
                                if (medi.pastCondition === valu['pre-existing_conditions_response_api_value']) {
                                    fatherDisease['de-de'] = valu['pre-existing_conditions_answer'][0].text;
                                }
                            });
                        }
                    });
                    fatherDiseasee.push({
                        a: fatherDisease[lan],
                        b: medi.specific,
                        c: medi.pastCondition,
                        d: medi.whenPastCondition,
                    });
                    // console.debug('Questions5: fatherDiseasee', fatherDiseasee);
                    setNewCondition(fatherDiseasee);
                });
            }
        }
        // imaging
        if (testPerfomedArrEasy.length > 0) {
            let fatherDiseasee = [];
            let fatherDisease = {};
            testPerfomedArrEasy.forEach((a) => {
                Object.keys(allData).forEach((val) => {
                    if (val === 'en-us') {
                        allData[val]['imaging_answers_group'].find((valu) => {
                            if (a.testPerfomed === valu['imaging_answer_selector']) {
                                fatherDisease['en-us'] = valu['imagine_answer'][0].text;
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val]['imaging_answers_group'].find((valu) => {
                            if (a.testPerfomed === valu['imaging_answer_selector']) {
                                fatherDisease['de-de'] = valu['imagine_answer'][0].text;
                            }
                        });
                    }
                });

                fatherDiseasee.push({
                    a: fatherDisease[lan],
                    b: a.testPerfomed,
                    c: a.testPerfomedDate,
                });
                setTestPerfomedArr(fatherDiseasee);
            });
        }

        // test
        if (imagingPerfomedArrEasy.length > 0) {
            let fatherDiseasee = [];
            let fatherDisease = {};
            imagingPerfomedArrEasy.forEach((a) => {
                Object.keys(allData).forEach((val) => {
                    if (val === 'en-us') {
                        allData[val]['3_tests_answer_group'].find((valu) => {
                            if (a.imagingPerfomed === valu['tests_answer_selector']) {
                                fatherDisease['en-us'] = valu['tests_answer'][0].text;
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val]['3_tests_answer_group'].find((valu) => {
                            if (a.imagingPerfomed === valu['tests_answer_selector']) {
                                fatherDisease['de-de'] = valu['tests_answer'][0].text;
                            }
                        });
                    }
                });

                fatherDiseasee.push({
                    a: fatherDisease[lan],
                    b: a.imagingPerfomed,
                    c: a.imagingPerfomedDate,
                });
                setImagingPerfomedArr(fatherDiseasee);
            });
        }
    }, [surgeriesArr]);

    const addData = ({
        condition2 = false,
        condition = false,
        sergeryDataAllFunction = [],
        sergeryQuestionsFunction = '',
        sergeryDateFunction = '',
        condition3 = false,
        openState2 = false,
        testPerfomedEasyLocal = [],
        testPerfomedArrLocal = [],
        testPerfomedLocal = '',
        openState3 = false,
    }) => {
        let dataStore = [];
        sergeriesArr1.forEach((singleData) => {
            dataStore.push({
                specific: singleData?.specific,
                pastCondition: singleData?.pastCondition,
                whenPastCondition: singleData?.whenPastCondition
                    ? anamnesisDateFormat(singleData?.whenPastCondition, languageGlobal)
                    : '',
            });
        });

        setSergeriesArr(dataStore);
        let serrgeryArrFunction = dataStore;
        console.debug('sergeriesArr12', dataStore);

        if (openState2) {
            existingCondition({
                openState2,
                serrgeryArrFunction,
                condition,
                sergeryDataAllFunction,
                sergeryQuestionsFunction,
                sergeryDateFunction,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal,
            });
        }
        if (openState3) {
            existingCondition({
                condition2,
                serrgeryArrFunction,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal,
                openState3,
            });
        }
        if (condition2) {
            existingCondition({
                condition2,
                serrgeryArrFunction,
            });
        }
        if (condition3) {
            existingCondition({
                condition3,
                condition2,
                serrgeryArrFunction,
                condition,
                sergeryDataAllFunction,
                sergeryQuestionsFunction,
                sergeryDateFunction,
            });
        }
    };

    const addDataSurgeries = ({
        condition = false,
        condition3 = false,
        openState2 = false,
        openState4 = false,
        testPerfomedEasyLocal = [],
        testPerfomedArrLocal = [],
        testPerfomedLocal = '',
    }) => {
        anySurgeryLocal.push({
            sergeriesQuestion,
            sergerieDate: sergerieDate ? anamnesisDateFormat(sergerieDate, languageGlobal) : '',
        });
        // anySurgeryLocal.push({ sergeriesQuestion, sergerieDate });
        setSergeryDataAll([...sergeryDataAll, ...anySurgeryLocal]);
        let sergeryDataAllFunction = [...sergeryDataAll, ...anySurgeryLocal];
        let sergeryQuestionsFunction = '';
        let sergeryDateFunction = '';
        setSergeriesQuestion('');
        setSergerieDate('');
        setEditPencil(false);
        setAnySuergeryLocal([]);
        if (condition) {
            existingCondition({
                condition,
                sergeryDataAllFunction,
                sergeryQuestionsFunction,
                sergeryDateFunction,
            });
        }
        if (condition3) {
            addData({
                condition,
                sergeryDataAllFunction,
                sergeryQuestionsFunction,
                sergeryDateFunction,
                condition3,
            });
        }
        if (openState2) {
            addData({
                sergeryDataAllFunction,
                sergeryQuestionsFunction,
                sergeryDateFunction,
                testPerfomedEasyLocal,
                openState2,
                testPerfomedArrLocal,
                testPerfomedLocal,
            });
            return;
        }
        if (openState4) {
            existingCondition({
                openState4,
                sergeryDataAllFunction,
                sergeryQuestionsFunction,
                sergeryDateFunction,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal,
            });
        }
    };

    const existingCondition = ({
        sergeryDateFunction = '',
        sergeryDataAllFunction = [],
        condition = false,
        sergeryQuestionsFunction = '',
        condition2 = false,
        pastConditionFunction = '',
        fatherDiseasee = [],
        serrgeryArrFunction = [],
        condition3 = false,
        openState = false,
        testPerfomedEasyLocal = [],
        testPerfomedArrLocal = [],
        testPerfomedLocal = '',
        openState2,
        openState3,
        openState4,
    }) => {
        if (disabledFunction()) {
            console.debug('sergeries12 hello');
            setCounter(counter + 1);
            setNewFromTrue(true);
            return;
        }
        setNewFromTrue(false);

        if (surgeries === 'YES' && sergeriesQuestion.length === 0 && sergeryDataAll.length === 0) {
            console.debug('index1: hello inner');
            return;
        }

        if (condition) {
            // console.debug('existing_condition1: if ');
            console.debug('index1: 1');
            setExistingCondition({
                pastCondition: pastCondition,
                surgeries: surgeries,
                surgeriesArr: surgeriesArr,
                sergeryDataAll: sergeryDataAllFunction,
                sergeryQuestion: sergeryQuestionsFunction,
                sergeryWhen: sergeryDateFunction,
                newCondition: newCondition,
                whenPastCondition: whenPastCondition,
                imagingArr: testPerfomedArr,
                imagingTest: testPerfomed,
                imagingEasy: testPerfomedArrEasy,
            });
            ExistingConditionAPI({
                serrgeryArrFunction: null,
                sergeryDataAllFunction,
                testPerfomedEasyLocal: null,
            });
        } else if (condition2) {
            console.debug('index1: 2', serrgeryArrFunction);
            setExistingCondition({
                pastCondition: pastConditionFunction,
                surgeries: surgeries,
                surgeriesArr: serrgeryArrFunction,
                sergeryDataAll: sergeryDataAll,
                sergeryQuestion: sergeriesQuestion,
                sergeryWhen: sergerieDate,
                newCondition: fatherDiseasee,
                whenPastCondition: whenPastCondition,
                imagingArr: testPerfomedArr,
                imagingTest: testPerfomed,
                imagingEasy: testPerfomedArrEasy,
            });
            ExistingConditionAPI({
                serrgeryArrFunction,
                sergeryDataAllFunction: null,
                testPerfomedEasyLocal: null,
            });
        } else if (condition3) {
            console.debug('index1: 3');
            // console.debug('existing_condition1: if 3rd ');
            setExistingCondition({
                pastCondition: pastConditionFunction,
                surgeries: surgeries,
                surgeriesArr: serrgeryArrFunction,
                sergeryDataAll: sergeryDataAllFunction,
                sergeryQuestion: sergeryQuestionsFunction,
                sergeryWhen: sergeryDateFunction,
                newCondition: fatherDiseasee,
                whenPastCondition: whenPastCondition,
                imagingArr: testPerfomedArr,
                imagingTest: testPerfomed,
                imagingEasy: testPerfomedArrEasy,
            });
            ExistingConditionAPI({
                serrgeryArrFunction,
                sergeryDataAllFunction,
                testPerfomedEasyLocal: null,
            });
        } else if (openState) {
            console.debug('index1: 4');
            setExistingCondition({
                pastCondition: pastCondition,
                surgeries: surgeries,
                surgeriesArr: surgeriesArr,
                sergeryDataAll: sergeryDataAll,
                sergeryQuestion: sergeriesQuestion,
                sergeryWhen: sergerieDate,
                newCondition: newCondition,
                whenPastCondition: whenPastCondition,
                imagingArr: testPerfomedArrLocal,
                imagingTest: testPerfomedLocal,
                imagingEasy: testPerfomedEasyLocal,
            });
            ExistingConditionAPI({
                serrgeryArrFunction: null,
                sergeryDataAllFunction: null,
                testPerfomedEasyLocal,
            });
        } else if (openState2) {
            console.debug('index1: 5');
            setExistingCondition({
                pastCondition: pastConditionFunction,
                surgeries: surgeries,
                surgeriesArr: serrgeryArrFunction,
                sergeryDataAll: sergeryDataAllFunction,
                sergeryQuestion: sergeryQuestionsFunction,
                sergeryWhen: sergeryDateFunction,
                newCondition: fatherDiseasee,
                whenPastCondition: whenPastCondition,
                imagingArr: testPerfomedArrLocal,
                imagingTest: testPerfomedLocal,
                imagingEasy: testPerfomedEasyLocal,
            });
            ExistingConditionAPI({
                serrgeryArrFunction,
                sergeryDataAllFunction,
                testPerfomedEasyLocal,
            });
        } else if (openState3) {
            console.debug('index1: 6');
            setExistingCondition({
                pastCondition: pastConditionFunction,
                surgeries: surgeries,
                surgeriesArr: serrgeryArrFunction,
                sergeryDataAll: sergeryDataAll,
                sergeryQuestion: sergeriesQuestion,
                sergeryWhen: sergerieDate,
                newCondition: fatherDiseasee,
                whenPastCondition: whenPastCondition,
                imagingArr: testPerfomedArrLocal,
                imagingTest: testPerfomedLocal,
                imagingEasy: testPerfomedEasyLocal,
            });
            ExistingConditionAPI({
                serrgeryArrFunction,
                sergeryDataAllFunction: null,
                testPerfomedEasyLocal,
            });
        } else if (openState4) {
            console.debug('index1: 7');
            setExistingCondition({
                pastCondition: pastCondition,
                surgeries: surgeries,
                surgeriesArr: surgeriesArr,
                sergeryDataAll: sergeryDataAllFunction,
                sergeryQuestion: sergeryQuestionsFunction,
                sergeryWhen: sergeryDateFunction,
                newCondition: newCondition,
                whenPastCondition: whenPastCondition,
                imagingArr: testPerfomedArrLocal,
                imagingTest: testPerfomedLocal,
                imagingEasy: testPerfomedEasyLocal,
            });
            ExistingConditionAPI({
                serrgeryArrFunction: null,
                sergeryDataAllFunction,
                testPerfomedEasyLocal,
            });
        } else {
            console.debug('index1: 8');
            setExistingCondition({
                pastCondition: pastCondition,
                surgeries: surgeries,
                surgeriesArr: surgeriesArr,
                sergeryDataAll: sergeryDataAll,
                sergeryQuestion: sergeriesQuestion,
                sergeryWhen: sergerieDate,
                newCondition: newCondition,
                whenPastCondition: whenPastCondition,
                imagingArr: testPerfomedArr,
                imagingTest: testPerfomed,
                imagingEasy: testPerfomedArrEasy,
            });
            ExistingConditionAPI({
                serrgeryArrFunction: null,
                sergeryDataAllFunction: null,
            });
        }

        setPastCondition(pastCondition);

        if (!save_changes_option) {
            trackStormlyAnamnesisSection();
            UpdateSteps(step, history, user, isFromHealthData, isConsentAllForms, isLastStep);
        }
        if (save_changes_option) {
            setHasAccount(false);
            setReviewOption(false);

            onSaveButton(history, is_edit_overview);
        }
    };
    useEffect(() => {
        setLocalDelete(surgeriesArr);
    }, [surgeriesArr]);

    const editOption = (index, all) => {
        if (loaclDelete.length > 0) {
            let newR = loaclDelete.filter((a) => a.pastCondition !== all.c);
            setSergeriesArr(newR);
        }
        if (newCondition.length > 0) {
            let newArr = newCondition.filter((a) => newCondition.indexOf(a) !== index);
            setNewCondition(newArr);
        }
        setPastCondition(all.c);
        setSpecific(all.b);
        setWhenPastCondition(all.d);
        setEditState(true);
        setCancelEdit(all);
    };

    const deleteGray = (index, all) => {
        if (loaclDelete.length > 0) {
            let newR = loaclDelete.filter((a) => a.pastCondition !== all.c);
            setSergeriesArr(newR);
        }
        if (newCondition.length > 0) {
            let newArr = newCondition.filter((a) => newCondition.indexOf(a) !== index);
            setNewCondition(newArr);
        }
    };

    const deleteEdit = () => {
        surgeriesArr.push({
            specific: cancelEdit.b,
            pastCondition: cancelEdit.c,
        });
        newCondition.push(cancelEdit);
        setPastCondition('');
        setSpecific('');
        setCancelEdit({});
        setEditState(false);
    };

    const deeletOptionSurgery = (index) => {
        let fil = sergeryDataAll.filter((d) => sergeryDataAll.indexOf(d) !== index);
        setSergeryDataAll(fil);
        setSergeriesQuestion('');
        setSergerieDate('');
    };
    const editOptionSurgery = (index, all) => {
        let fil = sergeryDataAll.filter((d) => sergeryDataAll.indexOf(d) !== index);
        setSergeryDataAll(fil);
        setSeugeryData(all);
        setSergeriesQuestion(all.sergeriesQuestion);
        setSergerieDate(all.sergerieDate ? getLocalDateMonth(all.sergerieDate, languageGlobal) : '');
        setEditPencil(true);
    };

    const deleteEditSurgery = () => {
        // surgeryData
        sergeryDataAll.push({
            sergeriesQuestion: surgeryData.sergeriesQuestion,
            sergerieDate: surgeryData.sergerieDate,
        });
        setSergeriesQuestion('');
        setSergerieDate('');
        setSeugeryData({});
        setEditPencil(false);
    };

    const CancelFunction = () => {
        if (isFromHealthData) {
            history.push('/health-profile-overview');
            return;
        }
        if (existing_condition.pastCondition === 'NONE OF THE ABOVE') {
            setNewCondition([]);
        }
        setPastCondition(existing_condition.pastCondition);
        setSurgeries(existing_condition.surgeries);
        setSergeriesArr(existing_condition.surgeriesArr);
        setSergeryDataAll(existing_condition.sergeryDataAll);
        setTestPerfomedArrEasy(existing_condition.imagingEasy);
        // setTestPerfomedArr(existing_condition.imagingArr);
        onCancelButton(history);
        setIsOpenState(false);
    };

    const preExistingIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'history');
    useEffect(() => {
        if (user && allDataApi !== null && allDataApi !== undefined && body5 === null) {
            if (
                allDataApi?.category_data[preExistingIndex] &&
                allDataApi?.category_data[preExistingIndex]['category'] === 'history'
            ) {
                let category2 = allDataApi?.category_data[preExistingIndex].data;
                setSurgeries(category2.had_surgery ? 'YES' : 'NO');
                let arr = [];
                if (category2?.preexisting_conditions.length > 0) {
                    let designedArr = category2?.preexisting_conditions;
                    designedArr.forEach((d) => {
                        let s = d.since.month !== '' ? d.since.month + '-' + d.since.year : '';
                        arr.push({
                            pastCondition: d.name,
                            specific: d.condition,
                            whenPastCondition: s,
                        });
                    });
                } else {
                    setPastCondition('NONE OF THE ABOVE');
                }
                setSergeriesArr(arr);
                if (category2?.preexisting_conditions.length > 0) {
                    let a = arr;
                    let b = [];
                    let p = {};
                    let e = {};
                    for (let i = 0; i < a.length; i++) {
                        let c = a[i];
                        b.push({
                            pastCondition: c.pastCondition,
                            specific: c.specific,
                            whenPastCondition: c.whenPastCondition
                                ? getLocalDateMonth(c.whenPastCondition, languageGlobal)
                                : '',
                        });
                        for (let k = 0; k < total.length; k++) {
                            if (total[k]['pre-existing_conditions_response_api_value'] === c.pastCondition) {
                                p[k] = true;
                                e[k] = false;
                            }
                            setCheckBoxObj(p);
                            setErrorObj(e);
                        }
                    }
                    setSergeriesArr1(b);
                } else {
                    console.debug('index1: clicked');
                    setCheckBoxObj({ 0: true });
                }
                let arr2 = [];
                if (category2?.surgeries.length > 0) {
                    let designedArr = category2?.surgeries;
                    designedArr.forEach((d) => {
                        let s = d.since.month !== '' ? d.since.month + '-' + d.since.year : '';
                        arr2.push({
                            sergeriesQuestion: d.name,
                            sergerieDate: s,
                        });
                    });
                }
                setSergeryDataAll(arr2);
                let arr3 = [];
                if (category2?.imaging && category2?.imaging.length > 0) {
                    let designedArr = category2?.imaging;
                    designedArr.forEach((f) => {
                        let s = f.since.year !== '' ? f.since.year : '';
                        arr3.push({
                            testPerfomed: f.name,
                            testPerfomedDate: s,
                        });
                    });
                } else {
                    setTestPerfomed('None');
                }
                setTestPerfomedArrEasy(arr3);

                let arr4 = [];
                if (category2.hasOwnProperty('testing') && category2?.testing) {
                    console.debug('category2-inline::', category2, imagingPerfomed);
                    let designedArr = category2?.testing;
                    if (designedArr.length > 0) {
                        designedArr.forEach((f) => {
                            let s = f.since.year !== '' ? f.since.year : '';
                            arr4.push({
                                imagingPerfomed: f.name,
                                imagingPerfomedDate: s,
                            });
                        });
                    } else {
                        setImagingPerfomed('None');
                    }
                }
                setImagingPerfomedArrEasy(arr4);
            }
        }
    }, [allDataApi]);

    const ExistingConditionAPI = ({
        serrgeryArrFunction = null,
        sergeryDataAllFunction = null,
        testPerfomedEasyLocal = null /* IMAGING */,
        imagingPerfomedEasyLocal = null /* TEST */,
    }) => {
        let user_id = user ? usersession?.idToken.payload.sub : '';
        let preexisting_conditions_ = [];

        let sergeryArray =
            serrgeryArrFunction !== undefined && serrgeryArrFunction !== null ? serrgeryArrFunction : surgeriesArr;
        if (!haveProperty) {
            sergeryArray.forEach((m) => {
                preexisting_conditions_.push({
                    name: m.pastCondition,
                    condition: m.specific,
                    since: {
                        month: m.whenPastCondition === '' ? '' : m.whenPastCondition.split('-')[0],
                        year: m.whenPastCondition === '' ? '' : m.whenPastCondition.split('-')[1],
                    },
                });
            });
        }
        console.debug('index1: sergeryArray', sergeryArray, preexisting_conditions_, haveProperty);
        let sergeryData = [];
        let sergeryArr =
            sergeryDataAllFunction !== undefined && sergeryDataAllFunction !== null
                ? sergeryDataAllFunction
                : sergeryDataAll;
        sergeryArr.forEach((sm) => {
            sergeryData.push({
                name: sm.sergeriesQuestion,
                since: {
                    month: sm.sergerieDate === '' ? '' : sm.sergerieDate.split('-')[0],
                    year: sm.sergerieDate === '' ? '' : sm.sergerieDate.split('-')[1],
                },
            });
        });
        // test Perfomed - IMAGING
        let APITestPerfomed = [];
        let testPerfomedApiArr =
            testPerfomedEasyLocal !== null && testPerfomedEasyLocal !== undefined
                ? testPerfomedEasyLocal
                : testPerfomedArrEasy;

        if (testPerfomed !== 'None' && testPerfomedApiArr.length > 0) {
            testPerfomedApiArr.forEach((f) => {
                APITestPerfomed.push({
                    name: f.testPerfomed,
                    since: {
                        year: f.testPerfomedDate,
                    },
                });
            });
        }

        // imaging perform - TEST
        let APIImagingPerfomed = [];
        let imagingPerfomedApiArr =
            imagingPerfomedEasyLocal !== null && imagingPerfomedEasyLocal !== undefined
                ? imagingPerfomedEasyLocal
                : imagingPerfomedArrEasy;
        if (imagingPerfomed !== 'None' && imagingPerfomedApiArr.length > 0) {
            imagingPerfomedApiArr.forEach((f) => {
                APIImagingPerfomed.push({
                    name: f.imagingPerfomed,
                    since: {
                        month:
                            f.imagingPerfomedDate === '' || f.imagingPerfomedDate === undefined
                                ? ''
                                : f.imagingPerfomedDate.split('-')[0],
                        year:
                            f.imagingPerfomedDate === '' || f.imagingPerfomedDate === undefined
                                ? ''
                                : f.imagingPerfomedDate.split('-')[1],
                    },
                });
            });
        }

        const body = {
            user_id: user_id,
            category: 'history',
            data: {
                preexisting_conditions: preexisting_conditions_,
                had_surgery: surgeries === 'YES',
                surgeries: sergeryData,

                imaging: APITestPerfomed,
                had_imaging: APITestPerfomed.length > 0,

                testing: APIImagingPerfomed,
                had_testing: APIImagingPerfomed.length > 0,
            },
        };
        if (imagingPerfomed !== null) {
            body.data['testing'] = APIImagingPerfomed;
            body.data['had_testing'] = APIImagingPerfomed.length > 0;
        }

        // console.debug('BODY1::', body.data);
        setBody5(body);
        user && yourBodyAPI(usersession, body);
    };

    const addDataImaging = ({ openState = false, openState2 = false, openState3 = false, openState4 = false }) => {
        if (testPerfomed === 'None') {
            // console.debug('existing_condition1: no entry');
            return;
        }

        addingImaging.push({
            testPerfomed,
            testPerfomedDate,
        });
        let addingImagingFunction = { testPerfomed, testPerfomedDate };
        setTestPerfomedArrEasy([...testPerfomedArrEasy, ...addingImaging]);
        let testPerfomedEasyLocal = [...testPerfomedArrEasy, ...addingImaging];
        // for gray box changing data
        setAddingImaging([]);
        let fatherDiseasee = [];
        let fatherDisease = {};
        Object.keys(allData).forEach((val) => {
            if (val === 'en-us') {
                allData[val]['imaging_answers_group'].find((valu) => {
                    if (addingImagingFunction.testPerfomed === valu['imaging_answer_selector']) {
                        fatherDisease['en-us'] = valu['imagine_answer'][0].text;
                    }
                });
            }
            if (val === 'de-de') {
                allData[val]['imaging_answers_group'].find((valu) => {
                    if (addingImagingFunction.testPerfomed === valu['imaging_answer_selector']) {
                        fatherDisease['de-de'] = valu['imagine_answer'][0].text;
                    }
                });
            }
        });

        fatherDiseasee.push({
            a: fatherDisease[lan],
            b: addingImagingFunction.testPerfomed,
            c: addingImagingFunction.testPerfomedDate,
        });
        setTestPerfomedArr([...testPerfomedArr, ...fatherDiseasee]);
        let testPerfomedArrLocal = [...testPerfomedArr, ...fatherDiseasee];

        setTestPerfomedDate('');
        setTestPerfomed('');
        if (openState) {
            existingCondition({
                openState,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal: '',
            });
            return;
        }
        if (openState2) {
            // console.debug('existing_condition1 4sdaada325', openState2);
            addDataSurgeries({
                openState2,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal: '',
            });
            return;
        }
        if (openState3) {
            // console.debug('existing_condition1 333333', openState3);
            addData({
                openState3,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal: '',
            });
            return;
        }
        if (openState4) {
            // console.debug('existing_condition1 addImaging openState4', openState4);
            addDataSurgeries({
                openState4,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal: '',
            });
        }
    };

    const addDataTest = ({ openState = false, openState2 = false, openState3 = false, openState4 = false }) => {
        if (imagingPerfomed === 'None') {
            // console.debug('existing_condition1: no entry');
            return;
        }

        addingImaging.push({
            imagingPerfomed,
            imagingPerfomedDate: imagingPerfomedDate ? anamnesisDateFormat(imagingPerfomedDate, languageGlobal) : '',
        });
        let addingImagingFunction = { imagingPerfomed, imagingPerfomedDate };
        setImagingPerfomedArrEasy([...imagingPerfomedArrEasy, ...addingImaging]);
        let testPerfomedEasyLocal = [...imagingPerfomedArrEasy, ...addingImaging];
        // for gray box changing data
        setAddingImaging([]);
        let fatherDiseasee = [];
        let fatherDisease = {};
        Object.keys(allData).forEach((val) => {
            if (val === 'en-us') {
                allData[val]['3_tests_answer_group'].find((valu) => {
                    if (addingImagingFunction.imagingPerfomed === valu['tests_answer_selector']) {
                        fatherDisease['en-us'] = valu['tests_answer'][0].text;
                    }
                });
            }
            if (val === 'de-de') {
                allData[val]['3_tests_answer_group'].find((valu) => {
                    if (addingImagingFunction.imagingPerfomed === valu['tests_answer_selector']) {
                        fatherDisease['de-de'] = valu['tests_answer'][0].text;
                    }
                });
            }
        });

        fatherDiseasee.push({
            a: fatherDisease[lan],
            b: addingImagingFunction.imagingPerfomed,
            c: addingImagingFunction.imagingPerfomedDate,
        });
        setImagingPerfomedArr([...imagingPerfomedArr, ...fatherDiseasee]);
        let testPerfomedArrLocal = [...imagingPerfomedArr, ...fatherDiseasee];

        setImagingPerfomedDate('');
        setImagingPerfomed('');
        if (openState) {
            existingCondition({
                openState,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal: '',
            });
            return;
        }
        if (openState2) {
            // console.debug('existing_condition1 4sdaada325', openState2);
            addDataSurgeries({
                openState2,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal: '',
            });
            return;
        }
        if (openState3) {
            // console.debug('existing_condition1 333333', openState3);
            addData({
                openState3,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal: '',
            });
            return;
        }
        if (openState4) {
            // console.debug('existing_condition1 addImaging openState4', openState4);
            addDataSurgeries({
                openState4,
                testPerfomedEasyLocal,
                testPerfomedArrLocal,
                testPerfomedLocal: '',
            });
        }
    };

    const deleteEditImaging = () => {
        addingImaging.push({
            testPerfomed: cancelTestEdit.b,
            testPerfomedDate: cancelTestEdit.c,
        });
        let addingImagingFunction = {
            testPerfomed: cancelTestEdit.b,
            testPerfomedDate: cancelTestEdit.c,
        };
        // console.debug('Questions5: ', addingImaging);
        setTestPerfomedArrEasy([...testPerfomedArrEasy, ...addingImaging]);

        // for gray box changing data
        setAddingImaging([]);
        let fatherDiseasee = [];
        {
            let fatherDisease = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val]['imaging_answers_group'].find((valu) => {
                        if (addingImagingFunction.testPerfomed === valu['imaging_answer_selector']) {
                            fatherDisease['en-us'] = valu['imagine_answer'][0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val]['imaging_answers_group'].find((valu) => {
                        if (addingImagingFunction.testPerfomed === valu['imaging_answer_selector']) {
                            fatherDisease['de-de'] = valu['imagine_answer'][0].text;
                        }
                    });
                }
            });

            fatherDiseasee.push({
                a: fatherDisease[lan],
                b: addingImagingFunction.testPerfomed,
                c: addingImagingFunction.testPerfomedDate,
            });
            setTestPerfomedArr([...testPerfomedArr, ...fatherDiseasee]);
        }
        setTestPerfomedDate('');
        setTestPerfomed('');
    };

    const deleteEditTest = () => {
        addingImaging.push({
            imagingPerfomed: cancelImagingEdit.b,
            imagingPerfomedDate: cancelImagingEdit.c,
        });
        let addingImagingFunction = {
            imagingPerfomed: cancelImagingEdit.b,
            imagingPerfomedDate: cancelImagingEdit.c,
        };
        // console.debug('Questions5: ', addingImaging);
        setImagingPerfomedArrEasy([...imagingPerfomedArrEasy, ...addingImaging]);

        // for gray box changing data
        setAddingImaging([]);
        let fatherDiseasee = [];
        {
            let fatherDisease = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val]['3_tests_answer_group'].find((valu) => {
                        if (addingImagingFunction.imagingPerfomed === valu['tests_answer_selector']) {
                            fatherDisease['en-us'] = valu['tests_answer'][0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val]['3_tests_answer_group'].find((valu) => {
                        if (addingImagingFunction.imagingPerfomed === valu['tests_answer_selector']) {
                            fatherDisease['de-de'] = valu['tests_answer'][0].text;
                        }
                    });
                }
            });

            fatherDiseasee.push({
                a: fatherDisease[lan],
                b: addingImagingFunction.imagingPerfomed,
                c: addingImagingFunction.imagingPerfomedDate,
            });
            setImagingPerfomedArr([...imagingPerfomedArr, ...fatherDiseasee]);
        }
        setImagingPerfomedDate('');
        setImagingPerfomed('');
    };

    const deleteGrayImaging = (index, all) => {
        setTestPerfomedArr([]);
        let newTestPerfomedArr = testPerfomedArrEasy.filter((del) => del.testPerfomed !== all.b);
        setTestPerfomedArrEasy(newTestPerfomedArr);
        let fatherDiseasee = [];
        {
            let fatherDisease = {};
            newTestPerfomedArr.forEach((medi) => {
                Object.keys(allData).forEach((val) => {
                    if (val === 'en-us') {
                        allData[val]['imaging_answers_group'].find((valu) => {
                            if (medi.testPerfomed === valu['imaging_answer_selector']) {
                                fatherDisease['en-us'] = valu['imagine_answer'][0].text;
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val]['imaging_answers_group'].find((valu) => {
                            if (medi.testPerfomed === valu['imaging_answer_selector']) {
                                fatherDisease['de-de'] = valu['imagine_answer'][0].text;
                            }
                        });
                    }
                });

                fatherDiseasee.push({
                    a: fatherDisease[lan],
                    b: medi.testPerfomed,
                    c: medi.testPerfomedDate,
                });
            });
            setTestPerfomedArr([...fatherDiseasee]);
        }
    };

    const deleteGrayTest = (index, all) => {
        setImagingPerfomedArr([]);
        let newTestPerfomedArr = imagingPerfomedArrEasy.filter((del) => del.imagingPerfomed !== all.b);
        setImagingPerfomedArrEasy(newTestPerfomedArr);
        let fatherDiseasee = [];
        {
            let fatherDisease = {};
            newTestPerfomedArr.forEach((medi) => {
                Object.keys(allData).forEach((val) => {
                    if (val === 'en-us') {
                        allData[val]['3_tests_answer_group'].find((valu) => {
                            if (medi.imagingPerfomed === valu['tests_answer_selector']) {
                                fatherDisease['en-us'] = valu['tests_answer'][0].text;
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val]['3_tests_answer_group'].find((valu) => {
                            if (medi.imagingPerfomed === valu['tests_answer_selector']) {
                                fatherDisease['de-de'] = valu['tests_answer'][0].text;
                            }
                        });
                    }
                });

                fatherDiseasee.push({
                    a: fatherDisease[lan],
                    b: medi.imagingPerfomed,
                    c: medi.imagingPerfomedDate,
                });
            });
            setImagingPerfomedArr([...fatherDiseasee]);
        }
    };

    const editOptionImaging = (index, all) => {
        setCancelTestEdit(all);
        deleteGrayImaging(index, all);
        setTestPerfomedDate(all.c);
        setTestPerfomed(all.b);
        setTestEdit(true);
    };

    const editOptionTest = (index, all) => {
        setCancelImagingEdit(all);
        deleteGrayTest(index, all);
        setImagingPerfomedDate(all.c ? getLocalDateMonth(all.c, languageGlobal) : '');
        setImagingPerfomed(all.b);
        setImagingEdit(true);
    };

    let errorObjTrue = Object.keys(errorObj).every((k) => {
        return errorObj[k] === false;
    });
    let allTrue = Object.values(checkBoxObj).some((val) => val === true);
    let ifOther = sergeriesArr1.some((val) => val.pastCondition === 'OTHERS');
    let other = ifOther
        ? sergeriesArr1.some((val) => val.pastCondition === 'OTHERS' && val.specific.length > 0)
        : false;

    useMemo(() => {
        if (ifOther && other) {
            setOtherFieldError(false);
        } else if (ifOther) {
            setOtherFieldError(true);
        } else {
            setOtherFieldError(false);
        }
    }, [sergeriesArr1]);
    console.debug('errorObjTrue123', !errorObjTrue);

    const disabledFunction = () => {
        if (!errorObjTrue || error || errorDate2 || errorDate3 || errorDate4) {
            return true;
        }
        if (surgeries === '') {
            return true;
        } else if ((!allTrue || surgeries === '') && !allTrue) {
            return true;
        } else if (otherFieldError && specific === '') {
            return true;
        } else if (surgeries === 'YES' && sergeriesQuestion.length === 0 && sergeryDataAll.length === 0) {
            return true;
        } else if (testPerfomed !== '' && testPerfomedArrEasy.length === 0) {
            return false;
        } else
            return (
                (testPerfomed === '' || pastCondition === '' || surgeries === '') && testPerfomedArrEasy.length === 0
            );
    };
    const sergeryDisabledFunction = () => {
        if (surgeries.length === 0) {
            return true;
        }
        return surgeries === 'YES' && sergeriesQuestion.length === 0 && sergeryDataAll.length === 0;
    };

    const existingConditionPastDisabledFunction = () => {
        if ((!allTrue || surgeries === '') && !allTrue) {
            return true;
        }
        if (otherFieldError && specific === '') {
            return true;
        }
    };
    const allStates = [
        { name: 'surgeriesArr', value: surgeriesArr.length === 0 && pastCondition === '' },
        { name: 'surgeries', value: !!surgeries },
        { name: 'testPerfomedArr', value: testPerfomedArr.length === 0 && testPerfomed === '' },
        { name: 'imagingPerfomedArr', value: imagingPerfomedArr.length === 0 && imagingPerfomed === null },
    ];

    const whichIsNull = () => {
        const firstNull = allStates.find((state) => state.value === false);
        if (typeof firstNull === 'object') return firstNull.name;
    };

    useEffect(() => {
        if (isFromHealthData && userData) {
            const elementName = whichIsNull();
            const element = document.getElementById(elementName);
            scrollIntoView(element, {
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    }, [
        surgeriesArr.length === 0 && pastCondition === '',
        !!surgeries,
        testPerfomedArr.length === 0 && testPerfomed === '',
        imagingPerfomedArr.length === 0 && imagingPerfomed === null,
    ]);

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div
                    id={whichIsNull() === 'surgeriesArr' ? 'surgeriesArr' : null}
                    className={
                        !otherFieldError && existingConditionPastDisabledFunction() && (isFromHealthData || newFromTrue)
                            ? classes.highlight
                            : classes.main
                    }
                    ref={!allTrue && existingConditionPastDisabledFunction() ? messageRefTwo : null}
                >
                    <ExistingConditionPast
                        mainData={mainData}
                        allData={allData}
                        deleteEdit={deleteEdit}
                        deleteGray={deleteGray}
                        editOption={editOption}
                        addData={addData}
                        sergeriesArr1={sergeriesArr1}
                        setSergeriesArr1={setSergeriesArr1}
                        checkBoxObj={checkBoxObj}
                        setCheckBoxObj={setCheckBoxObj}
                        haveProperty={haveProperty}
                        user={user}
                        errOthers={errOthers}
                        setErrOthers={setErrOthers}
                        errorObj={errorObj}
                        setErrorObj={setErrorObj}
                        isFromHealthData={isFromHealthData}
                        allTrue={allTrue}
                        messageRefTwo={messageRefTwo}
                    />
                </div>
                {/* divider */}
                {isFromHealthData ? <hr className={classes.divider} /> : null}
                {/* divider */}
                {/* HAD surgery */}
                <div
                    className={
                        sergeryDisabledFunction() && (isFromHealthData || newFromTrue)
                            ? classes.highlight
                            : classes.main
                    }
                    ref={!existingConditionPastDisabledFunction() && sergeryDisabledFunction() ? messageRefTwo : null}
                    id={whichIsNull() === 'surgeries' ? 'surgeries' : null}
                >
                    <ExistingConditionHadSurgery
                        mainData={mainData}
                        addDataSurgeries={addDataSurgeries}
                        deeletOptionSurgery={deeletOptionSurgery}
                        editOptionSurgery={editOptionSurgery}
                        deleteEditSurgery={deleteEditSurgery}
                        isFromHealthData={isFromHealthData}
                    />
                </div>
                {/* divider */}
                {isFromHealthData && <hr className={classes.divider} />}
                {/* divider */}
                {/* ExistingConditionImagingPerfomed start */}
                <div
                    id={whichIsNull() === 'testPerfomedArr' ? 'testPerfomedArr' : null}
                    className={
                        testPerfomedArr.length > 0 && testPerfomed === '' && (isFromHealthData || newFromTrue)
                            ? null
                            : testPerfomed === '' && (isFromHealthData || newFromTrue)
                              ? classes.highlight
                              : classes.main
                    }
                >
                    <ExistingConditionImagingPerfomed
                        mainData={mainData}
                        addDataImaging={addDataImaging}
                        deleteEditImaging={deleteEditImaging}
                        deleteGrayImaging={deleteGrayImaging}
                        editOptionImaging={editOptionImaging}
                    />
                </div>
                {/* )} */}

                {isFromHealthData && (
                    <div
                        id={whichIsNull() === 'imagingPerfomedArr' ? 'imagingPerfomedArr' : null}
                        className={
                            imagingPerfomedArr.length > 0 && imagingPerfomed === '' && isFromHealthData
                                ? null
                                : imagingPerfomed === '' && isFromHealthData && imagingPerfomedArr.length === 0
                                  ? classes.highlight
                                  : null
                        }
                    >
                        <ExistingConditionTestPerfomed
                            mainData={mainData}
                            addDataTest={addDataTest}
                            deleteEditTest={deleteEditTest}
                            deleteGrayTest={deleteGrayTest}
                            editOptionTest={editOptionTest}
                        />
                    </div>
                )}
                {/* ExistingConditionImagingPerfomed end */}
                {/* existing codition ends */}
                {/* Buttons */}
                <div
                    className=""
                    style={{
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: matches && 'column-reverse',
                    }}
                >
                    {!save_changes_option ? (
                        <OutlinedButton
                            text={mainData?.back_cta[0].text}
                            onPress={() => {
                                BackBtn(step, history, user, isFromHealthData);
                                setNewFromTrue(false);
                            }}
                            fullWidth={matches}
                        />
                    ) : (
                        <OutlinedButton
                            text={!isFromHealthData ? mainData?.cancel[0].text : mainData?.back_cta[0].text}
                            onPress={() => {
                                CancelFunction();
                                setNewFromTrue(false);
                            }}
                            fullWidth={matches}
                        />
                    )}
                    {!save_changes_option ? (
                        <FilledButton
                            text={mainData?.continue_cta[0].text}
                            onPress={() => {
                                if (testPerfomed === 'None' && haveProperty && surgeries === 'NO') {
                                    console.debug('index1: 1');
                                    let condition2 = true;
                                    addData({ condition2 });
                                    return;
                                }
                                if (haveProperty && sergeriesQuestion.length > 0 && testPerfomed === 'None') {
                                    console.debug('index1: 2');
                                    let condition = true;
                                    addDataSurgeries({ condition });
                                    return;
                                }

                                if (
                                    testPerfomed !== '' &&
                                    sergeriesArr1.length > 0 &&
                                    sergeriesQuestion.length > 0 &&
                                    testPerfomed !== 'None'
                                ) {
                                    console.debug('index1: 3');
                                    let openState2 = true;
                                    addDataImaging({ openState2 });
                                    return;
                                }
                                if (
                                    (testPerfomed !== '' && testPerfomed !== 'None' && sergeriesArr1.length > 0) ||
                                    (testPerfomed !== '' &&
                                        testPerfomed !== 'None' &&
                                        sergeriesArr1.length > 0 &&
                                        sergeriesQuestion === 'NO')
                                ) {
                                    console.debug('index1: 4');
                                    let openState3 = true;
                                    addDataImaging({ openState3 });
                                    return;
                                }
                                if (
                                    (testPerfomed !== '' && testPerfomed !== 'None' && sergeriesQuestion.length > 0) ||
                                    (testPerfomed !== '' &&
                                        testPerfomed !== 'None' &&
                                        sergeriesQuestion.length > 0 &&
                                        haveProperty)
                                ) {
                                    console.debug('index1: 5');
                                    let openState4 = true;
                                    addDataImaging({ openState4 });
                                    return;
                                }
                                if (
                                    (testPerfomed !== '' && testPerfomed !== 'None') ||
                                    (testPerfomed !== '' &&
                                        testPerfomed !== 'None' &&
                                        haveProperty &&
                                        sergeriesQuestion === 'NO')
                                ) {
                                    console.debug('index1: 6');
                                    let openState = true;
                                    addDataImaging({ openState });
                                    return;
                                }
                                if (
                                    (sergeriesArr1.length > 0 && sergeriesQuestion.length > 0) ||
                                    (sergeriesArr1.length > 0 &&
                                        sergeriesQuestion.length > 0 &&
                                        testPerfomed === 'None')
                                ) {
                                    console.debug('index1: 7');
                                    let condition3 = true;
                                    addDataSurgeries({ condition3 });
                                    return;
                                }
                                if (
                                    sergeriesQuestion.length > 0 ||
                                    (sergeriesQuestion.length > 0 && testPerfomed === 'None' && haveProperty)
                                ) {
                                    console.debug('index1: 8');
                                    let condition = true;
                                    addDataSurgeries({ condition });
                                    return;
                                }
                                if (haveProperty) {
                                    existingCondition('', [], false, '', false, '', [], [], false);
                                    return;
                                }
                                if (
                                    sergeriesArr1.length > 0 ||
                                    (sergeriesArr1.length > 0 && testPerfomed === 'None' && sergeriesQuestion === 'NO')
                                ) {
                                    console.debug('index1: 9');
                                    let condition2 = true;
                                    addData({ condition2 });
                                    return;
                                }
                                console.debug('index1: 10');
                                existingCondition('', [], false, '', false, '', [], [], false);
                            }}
                            marginBottom={matches && 20}
                            fullWidth={matches}
                        />
                    ) : (
                        <FilledButton
                            text={mainData?.save_cta[0].text}
                            onPress={() => {
                                if (testPerfomed === 'None' && sergeriesArr1.length === 0 && surgeries === 'NO') {
                                    existingCondition('', [], false, '', false, '', [], [], false);
                                    return;
                                }
                                if (
                                    (sergeriesArr1.length === 0 && sergeriesQuestion.length > 0) ||
                                    (sergeriesArr1.length === 0 &&
                                        sergeriesQuestion.length > 0 &&
                                        testPerfomed === 'None')
                                ) {
                                    let condition = true;
                                    addDataSurgeries({ condition });
                                    return;
                                }

                                if (
                                    testPerfomed !== '' &&
                                    sergeriesArr1.length > 0 &&
                                    sergeriesQuestion.length > 0 &&
                                    testPerfomed !== 'None'
                                ) {
                                    let openState2 = true;
                                    addDataImaging({ openState2 });
                                    return;
                                }
                                if (
                                    (testPerfomed !== '' && testPerfomed !== 'None' && sergeriesArr1.length > 0) ||
                                    (testPerfomed !== '' &&
                                        testPerfomed !== 'None' &&
                                        sergeriesArr1.length > 0 &&
                                        sergeriesQuestion === 'NO')
                                ) {
                                    let openState3 = true;
                                    addDataImaging({ openState3 });
                                    return;
                                }
                                if (
                                    (testPerfomed !== '' && testPerfomed !== 'None' && sergeriesQuestion.length > 0) ||
                                    (testPerfomed !== '' &&
                                        testPerfomed !== 'None' &&
                                        sergeriesQuestion.length > 0 &&
                                        sergeriesArr1.length === 0)
                                ) {
                                    let openState4 = true;
                                    addDataImaging({ openState4 });
                                    return;
                                }
                                if (
                                    (testPerfomed !== '' && testPerfomed !== 'None') ||
                                    (testPerfomed !== '' &&
                                        testPerfomed !== 'None' &&
                                        sergeriesArr1.length === 0 &&
                                        sergeriesQuestion === 'NO')
                                ) {
                                    // console.debug('existing_condition1 : testPerfomed openState');
                                    let openState = true;
                                    addDataImaging({ openState });
                                    return;
                                }
                                if (
                                    (sergeriesArr1.length > 0 && sergeriesQuestion.length > 0) ||
                                    (sergeriesArr1.length > 0 &&
                                        sergeriesQuestion.length > 0 &&
                                        testPerfomed === 'None')
                                ) {
                                    // console.debug('existing_condition1: if father');
                                    let condition3 = true;
                                    addDataSurgeries({ condition3 });
                                    return;
                                }
                                if (
                                    sergeriesQuestion.length > 0 ||
                                    (sergeriesQuestion.length > 0 &&
                                        testPerfomed === 'None' &&
                                        sergeriesArr1.length === 0)
                                ) {
                                    let condition = true;
                                    // console.debug('existing_condition1: sergeriesQuestion');
                                    addDataSurgeries({ condition });
                                    return;
                                }
                                if (sergeriesArr1.length === 0) {
                                    existingCondition('', [], false, '', false, '', [], [], false);
                                    return;
                                }
                                if (
                                    sergeriesArr1.length > 0 ||
                                    (sergeriesArr1.length > 0 && testPerfomed === 'None' && sergeriesQuestion === 'NO')
                                ) {
                                    // console.debug('existing_condition1: elsesdasdas');
                                    let condition2 = true;
                                    addData({ condition2 });
                                    return;
                                }
                                existingCondition('', [], false, '', false, '', [], [], false);
                            }}
                            marginBottom={matches && 20}
                            fullWidth={matches}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default memo(ExistingCondition);
