import { Typography } from '@material-ui/core';
import { colorStyles } from './BasicStyles';
import clsx from 'clsx';

const GrayTextBox = ({ text }) => {
    const basicClasses = colorStyles();
    //   clsx(classes.coloredRoot, classes.filledInputRoot);
    return (
        <Typography
            className={clsx(basicClasses.bg_creamDisable, basicClasses.p20, basicClasses.b10, basicClasses.br1)}
        >
            {text}
        </Typography>
    );
};

export default GrayTextBox;
