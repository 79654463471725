import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 20,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1.125rem',
        color: theme.palette.common.darkGrey,
        textAlign: 'center',
    },
    selectItemCard: {
        backgroundColor: theme.palette.common.lightGray,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'flex',
        width: '23rem',

        '&:hover': {
            backgroundColor: theme.palette.common.lightGray,
            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    selectItemCardBorder: {
        boxShadow: `0 0 0 1px ${theme.palette.common.mediumBrown}`,
    },
    selectedCard: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    innerContainer: {
        marginTop: 15,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },

        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px',
        },
        [theme.breakpoints.down('xl')]: {
            display: 'flex',
            flexWrap: 'wrap',
            rowGap: '20px',
            alignItems: 'center',
        },
    },
    buttonContentText: {
        ...theme.typography.subheading,
        width: '100%',
        textAlign: 'center',
        fontWeight: '500',
    },
}));

const SecondaryLocationSelection = ({
    secondaryLocations,
    selectedSecondaryLocation,
    setSelectedSecondaryLocation,
    setPatientDetails,
    startDate,
    dataSet,
    mainData,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.container}>
            <Typography className={classes.text}>
                {dataSet ? mainData.location_selection[0].text : 'Select the location you prefer for your appointment'}
            </Typography>
            <div className={classes.innerContainer}>
                {secondaryLocations.map((location) => (
                    <Button
                        onClick={() => {
                            if (location !== selectedSecondaryLocation) {
                                setSelectedSecondaryLocation(location);
                                setPatientDetails('nothing', startDate, location.key[0].text);
                            }
                        }}
                        style={{
                            marginRight: matches ? 10 : null,
                            width: matches ? '100%' : null,
                            display: matches ? 'flex' : null,
                        }}
                        disableRipple
                        className={
                            selectedSecondaryLocation.title[0].text === location.title[0].text
                                ? clsx(classes.selectItemCard, classes.selectedCard)
                                : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                        }
                    >
                        <Typography className={classes.buttonContentText}>{location.title[0].text}</Typography>
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default SecondaryLocationSelection;
