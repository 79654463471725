import { Grid, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useContext, useEffect } from 'react';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import bin from '@/assets/Icons/bin.svg';
import TypeOfDoc from './TypeOfDoc';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    fileNameParent: {
        marginTop: 10,
        alignItems: 'center',
    },
    delButtonParent: {
        display: 'flex',
        justifyContent: 'end',
    },
    icon: {
        height: '22px',
        width: '22px',
    },
    heading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '18px',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '2rem',
        marginBottom: '1rem',
        width: '100%',
    },
}));

const FileUploaded = ({ setDocType, fileName, setFileName, specialtyType, setSpecialtyType }) => {
    const classes = useStyles();

    const {
        state: { acceptedFileName },
        resetFile,
    } = useContext(HealthProfileContextMain);

    useEffect(() => {
        setFileName(acceptedFileName);
    }, []);

    return (
        <Grid container>
            <Grid container className={classes.fileNameParent}>
                <Grid item md={11} sm={11} xs={10}>
                    <TextField
                        id="fileName"
                        contentEditable={false}
                        label={'Document Name'}
                        variant="filled"
                        color="primary"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                        fullWidth
                        style={{ height: '3.75em' }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                </Grid>
                <Grid item md={1} sm={1} xs={2} className={classes.delButtonParent}>
                    <IconButton onClick={() => resetFile()}>
                        <img src={bin} className={classes.icon} />
                    </IconButton>
                </Grid>
            </Grid>
            <div>
                <TypeOfDoc setDocType={setDocType} setSpecialtyType={setSpecialtyType} />
            </div>
        </Grid>
    );
};

export default memo(FileUploaded);
