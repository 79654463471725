import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './YourBodyStyles';

export default function YouBodyBloodType({ mainData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const { bloodType, setBloodType } = useContext(ContextProviderTwo);
    console.debug('setBloodType1', mainData);
    return (
        <>
            <div className={classes.contentContainerBody} style={{ marginTop: bloodType !== '' && 40 }}>
                <div className={classes.fixedQuestion}>
                    <Typography className={classes.mainHeading}>{mainData.blood_type_title[0].text}</Typography>
                    <Grid
                        style={{
                            marginTop: 15,
                            // marginLeft: -10,
                            display: matches && 'flex',
                            flexWrap: 'wrap',
                            gap: 15,
                        }}
                        container
                        // spacing={3}
                    >
                        {mainData &&
                            mainData.blood_type_responses.map((bloodTypeItem, index) => (
                                <Button
                                    key={index}
                                    onClick={() => {
                                        console.debug(bloodTypeItem.blood_type_answers);
                                        setBloodType(bloodTypeItem.blood_type_answers);
                                    }}
                                    disableRipple
                                    className={
                                        bloodType === bloodTypeItem.blood_type_answers
                                            ? clsx(classes.selectItemCard, classes.selectedCard)
                                            : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                    }
                                    style={{
                                        marginBottom: matches && 15,
                                        width: 'auto',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <div className="">
                                        <Typography className={classes.BtncontentText}>
                                            {bloodTypeItem.blood_type_answer_title[0].text}
                                        </Typography>
                                    </div>
                                </Button>
                            ))}
                    </Grid>
                </div>
            </div>
        </>
    );
}
