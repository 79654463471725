import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './MedicationClasses';

export default function MedicationBloodThinner({ mainData, user }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { bloodThinner, whichBloodThinner, setBloodThinner, setWhichBloodThinner } = useContext(ContextProviderTwo);
    return (
        <>
            <div className={classes.contentContainerBody}>
                <div className={classes.fixedQuestion}>
                    <Typography className={classes.mainHeading}>{mainData.blood_thinners_question[0].text}</Typography>
                    <Grid
                        style={{
                            marginTop: 15,
                        }}
                        container
                    >
                        {mainData &&
                            mainData.blood_thinner_responses.map((parts) => (
                                <Grid lg={'auto'} sm={'auto'} xs={6}>
                                    <Button
                                        onClick={() => {
                                            console.debug(parts.blood_thinner_response_api_value);
                                            setBloodThinner(parts.blood_thinner_response_api_value);
                                        }}
                                        disableRipple
                                        className={
                                            bloodThinner === parts.blood_thinner_response_api_value
                                                ? clsx(classes.selectItemCard, classes.selectedCard)
                                                : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                        }
                                        style={{
                                            marginBottom: matches && 10,
                                            width: matches && '90%',
                                            display: matches ? null : 'flex',
                                            marginRight: 15,
                                        }}
                                    >
                                        <div className="">
                                            <Typography className={classes.BtncontentText}>
                                                {parts.yes_no_responses[0].text}
                                            </Typography>
                                        </div>
                                    </Button>
                                </Grid>
                            ))}
                    </Grid>
                </div>
                {bloodThinner.toLocaleLowerCase() === 'yes' && (
                    <TextField
                        id="medicationWhichOne"
                        label={mainData ? mainData.which_one[0].text : t('TransferSlip')}
                        variant="filled"
                        autoComplete="off"
                        color="primary"
                        value={whichBloodThinner}
                        onChange={(event) => {
                            setWhichBloodThinner(event.target.value);
                        }}
                        classes={{
                            root: classes.textFields,
                        }}
                        style={{
                            width: matches ? '100%' : '100%',
                            flex: matches ? 1 : 0.5,
                            marginTop: !matches && 20,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                )}
            </div>
        </>
    );
}
