import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';
import { Menu, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './LifeStyleStyles';

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

export default function LifeStyleAlcohol({ mainData, allData, isFromHealthData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const { alcohol, alcoholKind, alcoholPerWeek, setAlcohol, setAlcoholKind, setAlcoholPerWeek } =
        useContext(ContextProviderTwo);
    let lan = languageMap[i18n.language];
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');
    const [anchorE1, setAnchorE1] = useState(null);
    const [alcoholKindLocal, setAlcholKindLocal] = useState('');
    useEffect(() => {
        if (alcoholKind !== undefined && alcoholKind !== null && alcoholKind !== '') {
            let alcholKind_ = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val]._5_alcohol_type_group_answer.find((valu) => {
                        if (alcoholKind === valu.alcohol_type_selector) {
                            alcholKind_['en-us'] = valu.alcohol_type_answer[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val]._5_alcohol_type_group_answer.forEach((valu) => {
                        if (alcoholKind === valu.alcohol_type_selector) {
                            alcholKind_['de-de'] = valu.alcohol_type_answer[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', alcholKind_[lan]);
            let gender = alcholKind_[lan];
            setAlcholKindLocal(gender);
        }
    }, [alcoholKind]);

    const handleClick = (event) => {
        var w = document.getElementById('proBTN').clientWidth;
        console.debug(w);
        setMobileCodeWidth(w.toString());
        setAnchorE1(event.currentTarget);
    };
    const handleClose = (selectedAlchol = '') => {
        setAnchorE1(null);
        if (selectedAlchol !== '') {
            setAlcoholKind(selectedAlchol);
        }
    };
    console.debug('Mobuike Check', alcoholKind);

    return (
        <>
            <div
                className={classes.contentContainerBody}
                style={{
                    marginTop: isFromHealthData ? alcohol && (matches ? 20 : 30) : 30,
                }}
            >
                {/* main Part */}
                <div className={classes.fixedQuestion}>
                    <div className="">
                        <Typography className={classes.mainHeading}>{mainData.alcohol_question[0].text}</Typography>
                        <Grid
                            style={{
                                marginTop: 20,
                            }}
                            container
                        >
                            {mainData &&
                                mainData.alcohol_reponses.map((parts) => (
                                    <Grid lg={'auto'} sm={'auto'} xs={6}>
                                        <Button
                                            onClick={() => {
                                                console.debug(parts.alcohol_yes_no_response);
                                                setAlcohol(parts.alcohol_yes_no_response);
                                            }}
                                            disableRipple
                                            className={
                                                alcohol === parts.alcohol_yes_no_response
                                                    ? clsx(classes.selectItemCard, classes.selectedCard)
                                                    : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                            }
                                            style={{
                                                marginBottom: matchesMD && 20,
                                                marginRight: !matches && 15,
                                                // whiteSpace: "nowrap",
                                                width: matches && '90%',
                                                display: matches ? null : 'flex',
                                                // height: "100%",
                                            }}
                                        >
                                            <div className="">
                                                <Typography className={classes.BtncontentText}>
                                                    {parts.alcohol_yes_no_responses[0].text}
                                                </Typography>
                                            </div>
                                        </Button>
                                    </Grid>
                                ))}
                        </Grid>
                        {/* optional */}
                        {alcohol.toLocaleLowerCase() === 'yes' && (
                            <Grid
                                container
                                className=""
                                spacing={1}
                                style={{
                                    marginTop: !matchesMD && 20,
                                }}
                            >
                                {/* drop down */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="">
                                    <Button
                                        id="proBTN"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        disableRipple
                                        className={classes.formControl}
                                        classes={{ label: classes.menuLabel }}
                                    >
                                        <div>
                                            <Typography
                                                style={{
                                                    fontFamily: 'MessinaSans-Regular',
                                                    fontSize: '0.875rem',
                                                    color: theme.palette.common.darkGrey,
                                                    textTransform: 'none',
                                                    padding: 0,
                                                    marginLeft: 3,
                                                    textAlign: 'left',
                                                }}
                                            >
                                                {mainData?.alcohol_subquestion_what_kind[0].text}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontFamily: 'MessinaSans-SemiBold',
                                                    fontSize: '1rem',
                                                    color: theme.palette.common.darkGrey,
                                                    textTransform: 'none',
                                                    marginLeft: 3,
                                                    textAlign: 'left',
                                                }}
                                            >
                                                {alcoholKindLocal}
                                            </Typography>
                                        </div>
                                        <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
                                    </Button>

                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorE1}
                                        keepMounted
                                        open={Boolean(anchorE1)}
                                        onClose={() => {
                                            handleClose();
                                        }}
                                        PaperProps={{
                                            style: {
                                                width: parseFloat(mobileCodeWidth),
                                                backgroundColor: 'white',
                                                border: `2px solid ${theme.palette.common.yellow}`,
                                                height: 125,
                                            },
                                        }}
                                    >
                                        {mainData._5_alcohol_type_group_answer.map((data) => (
                                            <MenuItem
                                                className={classes.menuItem}
                                                onClick={() => {
                                                    handleClose(data.alcohol_type_selector);
                                                }}
                                            >
                                                <div
                                                    className=""
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    {data.alcohol_type_answer[0].text}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                                {/* drop down ends */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="">
                                    <TextField
                                        id="lifeStyleAlcholHowLong"
                                        label={
                                            mainData ? mainData.alcohol_subquestion_how_many[0].text : t('TransferSlip')
                                        }
                                        variant="filled"
                                        autoComplete="off"
                                        color="primary"
                                        value={alcoholPerWeek.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '')}
                                        onChange={(event) => {
                                            setAlcoholPerWeek(event.target.value);
                                        }}
                                        classes={{
                                            root: classes.textFields,
                                        }}
                                        style={{
                                            width: '100%',
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.inputLabel,
                                                focused: 'focused',
                                                shrink: 'shrink',
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.filledInputRoot,
                                                focused: classes.fieldFocused,
                                                shrink: 'shrink',
                                            },
                                        }}
                                    />
                                    {/* </Gird> */}
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
