import { useCallback, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ConsentStyles } from '..';
import TextInputField from '../../Common/TextInputField';
import { ConsentFormsContext, guardianValue, toggleValues } from '@/context/ConsentFormsContext';
import SelectableButton from '../../Common/SelectableButton/SelectableButton';
import clsx from 'clsx';

const LegalGuardian = () => {
    const classes = ConsentStyles();

    const {
        consentFormsContent: content,
        guardianData,
        setGuardianData,
        handleGuardianChange,
        resetGuardianData,
        guardianErrors,
        setGuardianErrors,
        resetGuardianErrors,
        validateForm,
    } = useContext(ConsentFormsContext);

    const { guardian, guardianFirstName, guardianLastName, guardianStreet, guardianZipCode, guardianCity } =
        guardianData;

    const setGuardian = useCallback((value) => {
        if (value === toggleValues.NO) {
            resetGuardianErrors();
            resetGuardianData();
        }

        setGuardianData((prev) => ({
            ...prev,
            guardian: value,
        }));
    }, []);

    return (
        <Grid container spacing={1}>
            <Typography className={classes.secondaryHeading}>
                {content?.corterierContract?.additionalQuestion}
            </Typography>
            <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} direction="row">
                    <div className={clsx(classes.dFlex, classes.gap20, classes.mt15)}>
                        {content?.formData?.representativeLegalAnswer.map(
                            ({ legal_representative_yes_no, yes_no_answer_to_legal }) => {
                                const value = legal_representative_yes_no;
                                const label = yes_no_answer_to_legal[0].text;
                                return (
                                    <SelectableButton
                                        key={value}
                                        selected={guardian === value}
                                        text={label}
                                        onClick={() => setGuardian(value)}
                                    />
                                );
                            }
                        )}
                    </div>
                </Grid>
            </Grid>
            {guardian?.toLocaleLowerCase() === guardianValue.YES.toLocaleLowerCase() ? (
                <>
                    <Grid container spacing={2} className={classes.mt5} direction="row">
                        <Grid item md={6} xs={12} direction="row">
                            <TextInputField
                                label={content?.formData?.representativeName}
                                name="guardianFirstName"
                                value={guardianFirstName}
                                onChange={handleGuardianChange}
                                // Validators
                                validateForm={validateForm}
                                errorName={'guardianFirstName'}
                                errorMessage={content?.heraklesContent?.[guardianErrors?.guardianFirstNameError]}
                                formData={guardianData}
                                errors={guardianErrors}
                                setErrors={setGuardianErrors}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} direction="row">
                            <TextInputField
                                label={content?.formData?.representativeLastName}
                                name="guardianLastName"
                                value={guardianLastName}
                                onChange={handleGuardianChange}
                                // Validators
                                validateForm={validateForm}
                                errorName={'guardianLastName'}
                                errorMessage={content?.heraklesContent?.[guardianErrors?.guardianLastNameError]}
                                formData={guardianData}
                                errors={guardianErrors}
                                setErrors={setGuardianErrors}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <TextInputField
                            label={content?.formData?.representativeAddress}
                            name="guardianStreet"
                            value={guardianStreet}
                            onChange={handleGuardianChange}
                            // Validators
                            validateForm={validateForm}
                            errorName={'guardianStreet'}
                            errorMessage={content?.heraklesContent?.[guardianErrors?.guardianStreetError]}
                            formData={guardianData}
                            errors={guardianErrors}
                            setErrors={setGuardianErrors}
                        />
                    </Grid>
                    <Grid container spacing={1} direction="row">
                        <Grid item md={2} xs={12}>
                            <TextInputField
                                label={content?.formData?.zipCode}
                                name="guardianZipCode"
                                value={guardianZipCode}
                                onChange={handleGuardianChange}
                                // Validators
                                validateForm={validateForm}
                                errorName={'guardianZipCode'}
                                errorMessage={content?.heraklesContent?.[guardianErrors?.guardianZipCodeError]}
                                formData={guardianData}
                                errors={guardianErrors}
                                setErrors={setGuardianErrors}
                            />
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <TextInputField
                                label={content?.formData?.city}
                                name="guardianCity"
                                value={guardianCity}
                                onChange={handleGuardianChange}
                                // Validators
                                validateForm={validateForm}
                                errorName={'guardianCity'}
                                errorMessage={content?.heraklesContent?.[guardianErrors?.guardianCityError]}
                                formData={guardianData}
                                errors={guardianErrors}
                                setErrors={setGuardianErrors}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default LegalGuardian;
