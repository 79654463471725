import { useEffect, useRef, useState } from 'react';
import { Button, Grid, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';
import countryCode from './countryCode.json';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    menuItem: {
        fontSize: 12,
        height: 55,
    },
    formControl: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.14em',
        justifyContent: 'space-between',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightWidth: 0,
        '&:hover': {
            backgroundColor: 'white',
        },
        [theme.breakpoints.down('xs')]: {
            height: '4.11em',
        },
    },
    errorShadow: { boxShadow: '0 0 0 1px #da3030' },
    downArrow: {
        height: '0.65em',
    },
    form: {
        marginTop: 23,
    },
    textFields: {
        borderRadius: 5,
    },
    textFieldsError: {
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    buttonText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.9rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
        textAlign: 'center',
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
}));

/**
 *
 * @param {*} phone, setPhone, label, mt (margin-top)
 * @returns Phone Number Input Field
 */
const PhoneInput = ({
    phone,
    setPhone,
    label = '',
    mt = 15,
    disabled = false,
    validateForm = () => {},
    errorName = '',
    errorMessage = '',
    errors = {},
    setErrors = () => {},
    formData = {},
}) => {
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');
    const [mobileCode, setMobileCode] = useState('+49');
    const [anchorE1, setAnchorE1] = useState(null);
    const [mobileNumber, setMobileNumber] = useState('');

    const handleClick = (event) => {
        var w = document.getElementById('mobileNumber').clientWidth;
        setMobileCodeWidth(w.toString());
        setAnchorE1(event.currentTarget);
    };
    let testingRegex = /[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_ ||[\]]/;
    const messageRef = useRef();
    const theme = useTheme();
    const classes = useStyles();

    const handleClose = (mobileCodee = '') => {
        setAnchorE1(null);
        if (mobileCodee !== '') {
            setMobileCode(mobileCodee);
        }
    };

    useEffect(() => {
        if (!phone) return;

        const numberParts = phone.split(' ');
        if (numberParts.length === 2) {
            setMobileCode(numberParts[0]);
            setMobileNumber(numberParts[1]);
            return;
        }

        setMobileCode('+49');
        setMobileNumber(numberParts[0]);
    }, [phone]);

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    });

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Grid item md={2} sm={3} xs={3}>
                    <Button
                        id="mobileCodeButton"
                        style={{ marginTop: mt || 15 }}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        disabled={disabled}
                        disableRipple
                        className={[classes.formControl, ...(errorMessage && [classes.errorShadow])]}
                        classes={{ label: classes.menuLabel }}
                    >
                        <div>
                            <Typography
                                className={classes.buttonText}
                                style={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : null }}
                            >
                                {mobileCode}
                            </Typography>
                        </div>
                        <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
                    </Button>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorE1}
                        keepMounted
                        open={Boolean(anchorE1)}
                        onClose={() => {
                            handleClose();
                        }}
                        PaperProps={{
                            style: {
                                width: parseFloat(mobileCodeWidth),
                                backgroundColor: 'white',
                                border: `2px solid ${theme.palette.common.yellow}`,
                                height: '40%',
                            },
                        }}
                    >
                        {countryCode.map((code) => (
                            <MenuItem
                                className={classes.menuItem}
                                onClick={() => {
                                    handleClose(code.dialCode);
                                    if (mobileNumber) setPhone(code.dialCode.concat(' ' + mobileNumber));
                                    else setPhone('');
                                }}
                                style={{ backgroundColor: code.dialCode === mobileCode ? '#F9F8F4' : 'white' }}
                                ref={code.dialCode === mobileCode ? messageRef : null}
                            >
                                <div className={classes.flex}>
                                    <div style={{ paddingRight: 10 }}>
                                        <img src={code.flag.toLowerCase()} />
                                    </div>
                                    {code.name}
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>

                {/* Text field */}
                <Grid item md={10} sm={9} xs={9}>
                    <TextField
                        id="mobileNumber"
                        label={label || 'Mobile Number (optional)'}
                        variant="filled"
                        color="primary"
                        value={mobileNumber?.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_ ||[\]]/, '')}
                        onChange={(event) => {
                            const text = event.target.value;
                            if (testingRegex.test(text)) {
                                return;
                            }
                            setMobileNumber(text);

                            if (event.target.value) setPhone(mobileCode.concat(' ' + text));
                            else setPhone('');
                        }}
                        disabled={disabled}
                        fullWidth
                        style={{ marginTop: mt || 15 }}
                        classes={{
                            root: errorMessage ? classes.textFieldsError : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                </Grid>
            </div>
            <Grid xs="12" sm="12">
                {errorMessage ? <Typography className={classes.errorText}>{errorMessage}</Typography> : null}
            </Grid>
        </>
    );
};

export default PhoneInput;
