import { createContext, useState } from 'react';

export const ContextProviderTwo = createContext();

export const AuthContextTwo = (props) => {
    const [error, setError] = useState(false);
    const [errorDate2, setErrorDate2] = useState(false);
    const [errorDate3, setErrorDate3] = useState(false);
    const [errorDate4, setErrorDate4] = useState(false);
    const [mainDataArray, setMainDataArray] = useState('hello99');
    // existing condition start
    const [pastCondition, setPastCondition] = useState('');
    const [surgeries, setSurgeries] = useState('');
    const [specific, setSpecific] = useState('');
    const [surgeriesArr, setSergeriesArr] = useState([]);
    const [sergeriesArr1, setSergeriesArr1] = useState([]);

    const [coditionStatus, setConditionStatus] = useState('YES');
    const [sergeriesQuestion, setSergeriesQuestion] = useState('');
    const [sergerieDate, setSergerieDate] = useState('');
    const [sergeryDataAll, setSergeryDataAll] = useState([]);
    const [newCondition, setNewCondition] = useState([]);
    const [editState, setEditState] = useState(false);
    const [cancelEdit, setCancelEdit] = useState({});
    const [editPencil, setEditPencil] = useState(false);
    const [surgeryData, setSeugeryData] = useState({});
    const [whenPastCondition, setWhenPastCondition] = useState('');
    const [imaging, setImaging] = useState('');
    const [imagingQuestion, setImagingQuestion] = useState('');
    const [imagingDate, setImagingDate] = useState('');
    const [oldData, setOldData] = useState([]);
    const [compulsoryOther, setCompulsoryOther] = useState(false);
    const [newFromTrue, setNewFromTrue] = useState(false);
    // existing condition end
    const [errPhoneNumber, setErrPhoneNumber] = useState('');
    const [errorDate, setErrorDate] = useState(false);
    // professional Info
    const [nameOfDoctor, setNameOfDoctor] = useState('');

    // personal information start
    const [efirstName, seteFirstName] = useState('');
    const [elastName, seteLastName] = useState('');
    const [emiddleName, seteMiddleName] = useState('');
    const [eemail, seteEmail] = useState('');
    const [estreetMainAdress, seteSreetMainAdress] = useState('');
    const [ezipMainAdress, seteZipMainAdress] = useState('');
    const [ecityMainAdress, seteCityMainAdress] = useState('');
    const [dob, setDOB] = useState('');
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');
    const [mobileCode, setMobileCode] = useState('+49');
    const [anchorE7, setanchorE7] = useState(null);
    const [mobileNumber, setMobileNumber] = useState('');
    const [privateTitle, setPrivateTitle] = useState('0');
    const [anchorE6, setAnchorE6] = useState(null);
    const [privateGender, setPrivateGender] = useState('0');
    const [anchorE5, setAnchorE5] = useState(null);
    const [pcountry, setPcountry] = useState('');
    const [epcountry, setePcountry] = useState('');
    const [egender, seteGender] = useState('');
    const [etitle, seteTitle] = useState('');
    const [pcode, setPcode] = useState('DE');
    const [epcode, setePcode] = useState('DE');
    const [occuption, setOccuption] = useState('');
    const [countrySelect, setCountrySelect] = useState({});
    const [testPerfomed, setTestPerfomed] = useState('');
    const [testPerfomedDate, setTestPerfomedDate] = useState('');
    const [testPerfomedArr, setTestPerfomedArr] = useState([]);
    let [testPerfomedArrEasy, setTestPerfomedArrEasy] = useState([]);
    let [testEdit, setTestEdit] = useState(false);
    let [cancelTestEdit, setCancelTestEdit] = useState({});
    const [imagingPerfomed, setImagingPerfomed] = useState('');
    const [imagingPerfomedDate, setImagingPerfomedDate] = useState('');
    const [imagingPerfomedArr, setImagingPerfomedArr] = useState([]);
    let [imagingPerfomedArrEasy, setImagingPerfomedArrEasy] = useState([]);
    let [imagingEdit, setImagingEdit] = useState(false);
    let [cancelImagingEdit, setCancelImagingEdit] = useState({});
    const [otherFieldError, setOtherFieldError] = useState('');
    const [other, setOther] = useState(false);

    // personal information ends

    // personal information health-data
    let [occupation, setOccupation] = useState('');

    // your body
    const [genderQuestion, setGenderQuestion] = useState('');
    const [gainedWeight, setGainedWeight] = useState('');
    const [pregnancy, setPregnancy] = useState('');
    const [pregnancyCount, setPregnancyCount] = useState('');
    const [KGs, setKGs] = useState();
    const [weeks, setWeeks] = useState();
    const [value__, setValue__] = useState('');
    const [valueHeight, setValueHeight] = useState('');
    const [bloodType, setBloodType] = useState('');
    const [disability, setDisability] = useState('');
    const [disabilityValue, setDisabilityValue] = useState('');
    const [cholesterol, setCholesterol] = useState('');
    const [bloodPressure, setBloodPressure] = useState('');
    const [bloodPressureValue, setBloodPressureValue] = useState('');
    const [sBP, setSBP] = useState('');
    const [dBP, setDBP] = useState('');
    // your body ends
    // current problem
    const [anyProblem, setAnyProblem] = useState('');
    const [whichOne, setWhichOne] = useState('');
    // current ends
    // medication starts
    const [bloodThinner, setBloodThinner] = useState('');
    const [whichBloodThinner, setWhichBloodThinner] = useState('');
    const [sleepingPills, setSleepingPills] = useState('');
    const [whichSleepingPills, setWhichSleepingPills] = useState('');
    const [medicationType, setMedicationType] = useState('');
    const [whichMedication, setWhichMedication] = useState('');
    const [allData, setAllData] = useState([]);
    const [editOptions, setEditOptions] = useState(false);
    // medication ends
    // allergy starts
    const [anyAllergy, setAnyAllergy] = useState('');
    const [type, setType] = useState('');
    const [reaction, setReaction] = useState('');
    const [value, setValue] = useState('');
    const [allergyArrCon, setAllergyArr] = useState({});
    const [data2Con, setData2] = useState([]);
    const [continueEdit, setContinueEdit] = useState(false);
    const [allergiesTwo, setAllergiesTwo] = useState({
        anyAllergy: '',
        allergyType: '',
        allergyArr: {},
        data2: [],
    });
    // allergy ends
    // family states
    const [relativeDisease, setRelativeDisease] = useState('');
    const [whichRelative, setWhichRelative] = useState('');
    const [relativeAge, setRelativeAge] = useState('');
    const [allDisease, setAllDisease] = useState([]);
    const [currentResponse, setCurrentResponse] = useState('YES');
    const [data2_, setData2_] = useState([]);
    const [allDisease1, setAllDisease1] = useState([]);
    const [newArr, setNewArr] = useState([]);
    // const [editOptions, setEditOptions] = useState(false);
    const [whichOther, setWhichOther] = useState('');
    const [addingData, setAddingData] = useState({});
    // family end
    // life style states
    const [whichOtherDietSubQuestion, setWhichOtherDietSubQuestion] = useState('');
    const [multipleDiet, setMultipleDiet] = useState([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [whichDiet, setWhichDiet] = useState('');
    const [sports, setSports] = useState('');
    const [value_, setValue_] = useState('');
    const [exercise, setExercise] = useState('');
    const [smoke, setSmoke] = useState('');
    const [smokePerDay, setSmokePerDay] = useState('');
    const [smokeHowLong, setSmokeHowLong] = useState('');
    const [alcohol, setAlcohol] = useState('');
    const [alcoholKind, setAlcoholKind] = useState('');
    const [alcoholPerWeek, setAlcoholPerWeek] = useState('');
    const [drugs, setDrugs] = useState('');
    const [rSelect, setRSelect] = useState('');
    const [nightSleep, setNightSleep] = useState('');
    const [snoreSelect, setSnoreSelect] = useState('');
    const [grindSelect, setGrindSelect] = useState('');
    const [restedSelect, setRestedSelect] = useState('');
    const [otherInfo, setOtherInfo] = useState('');
    const [sleepHours, setSleepHours] = useState('');
    const [stressSelect, setStressSelect] = useState('');
    const [moodSelect, setMoodSelect] = useState('');
    const [energySelect, setEnergySelect] = useState('');
    const [whichOneDrugsLocal, setWhichOneDrugsLocal] = useState([{ whichOne: '', howOftenDrugs: '' }]);

    // life style end
    // account page starts
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [wantAccount, setWantAccount] = useState('');
    const [passwordValid, setPasswordValid] = useState({
        characters: false,
        number: false,
        special: false,
        password: '',
    });
    const [validUntil, setValidUntil] = useState('');
    const [insuranceCompany, setInsuranceCompany] = useState('');
    const [insuranceId, setInsuranceId] = useState('');
    const [insuranceNumber, setInsuranceNumber] = useState('');
    const [insuranceStatus, setInsuranceStatus] = useState('');
    const [privateText, setPrivateText] = useState('0');
    const [insuranceType, setInsuraceType] = useState('');
    const [checked, setchecked] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [verifiactionPortion, setVerificationPortion] = useState(false);
    const [user, setUser] = useState(null);

    // account page ends

    const [body1, setBody1] = useState(null);
    const [body2, setBody2] = useState(null);
    const [body3, setBody3] = useState(null);
    const [body4, setBody4] = useState(null);
    const [body5, setBody5] = useState(null);
    const [body6, setBody6] = useState(null);
    const [body7, setBody7] = useState(null);
    const [body8, setBody8] = useState(null);
    const [allDataApi, setAllDataApi] = useState(null);
    const [hasAccount, setHasAccount] = useState(false);
    const [fromAppointment, setFromAppointment] = useState(false);
    const [allDoctorsDataApi, setAllDoctorsDataApi] = useState([]);
    const [selectedDoctorGlobal, setSelectedDoctorGlobal] = useState([]);
    const [apppointmentDoctorInfo, setAppointmentDoctorInfo] = useState(null);
    const [isConcent, setIsConcent] = useState(false);
    const [apiData, setApiData] = useState({});
    const [isSaveLifeStyle, setIsSaveLifeStyle] = useState(false);
    const [errEmail, setErrEmail] = useState('');
    const [errGender, setErrGender] = useState('');
    const [errFirstName, setErrFirstName] = useState('');
    const [errLastName, setErrLastName] = useState('');
    const [errDOB, setErrDOB] = useState('');
    const [errStreet, setErrorStreet] = useState('');
    const [errCity, setErrorCity] = useState('');
    const [errZip, setErrZip] = useState('');
    const [fromLocation, setFromLocation] = useState(false);
    const emptyStates = () => {
        setBody5(null);
        setBody6(null);
        setNameOfDoctor('');
        seteFirstName('');
        seteLastName('');
        seteMiddleName('');
        seteEmail('');
        seteSreetMainAdress('');
        seteZipMainAdress('');
        seteCityMainAdress('');
        setDOB('');
        setMobileCode('+49');
        setMobileNumber('');
        setPrivateTitle('');
        setPrivateGender('');
        setPcountry('');
        setePcountry('');
        seteGender('');
        seteTitle('');
        setOccuption('');
        setCountrySelect('');
        setGenderQuestion('');
        setGainedWeight('');
        setPregnancy('');
        setPregnancyCount('');
        setKGs('');
        setWeeks('');
        setValue__('');
        setValueHeight('');
        setAnyProblem('');
        setWhichOne('');
        setBloodThinner('');
        setWhichBloodThinner('');
        setMedicationType('');
        setWhichMedication('');
        setAllData([]);
        setEditOptions(false);
        setAnyAllergy('');
        setType('');
        setReaction('');
        setValue('');
        setData2([]);
        setAllergyArr({});
        setPastCondition('');
        setSurgeries('');
        setSergeriesArr([]);
        setSpecific('');
        setConditionStatus('YES');
        setSergeriesQuestion('');
        setSergerieDate('');
        setSergeryDataAll([]);
        setNewCondition([]);
        setEditState(false);
        setCancelEdit({});
        setEditPencil(false);
        setSeugeryData({});
        setWhenPastCondition('');
        setTestPerfomed('');
        setTestPerfomedDate('');
        setTestPerfomedArr([]);
        setTestPerfomedArrEasy([]);
        setTestEdit(false);
        setCancelTestEdit({});
        setImagingPerfomed('');
        setImagingPerfomedDate('');
        setImagingPerfomedArr([]);
        setImagingPerfomedArrEasy([]);
        setImagingEdit('');
        setCancelImagingEdit('');
        setAddingData({});
        setRelativeDisease('');
        setWhichRelative('');
        setRelativeAge('');
        setAllDisease([]);
        setCurrentResponse('YES');
        setNewArr([]);
        setWhichOther('');
        setData2_([]);
        setAllDisease1([]);
        setMultipleDiet('');
        setSelectedCount(0);
        setWhichDiet('');
        setSports('');
        setValue_('');
        setExercise('');
        setSmoke('');
        setSmokePerDay('');
        setSmokeHowLong('');
        setAlcohol('');
        setAlcoholKind('');
        setAlcoholPerWeek('');
        setDrugs('');
        setWantAccount('');
        setchecked(false);
        setLocalLoading(false);
        setVerificationPortion(false);
        setShowPassword(false);
        setConfirmPassword('');
        setPasswordValid({
            characters: false,
            number: false,
            special: false,
            password: '',
        });
        setValidUntil('');
        setInsuranceCompany('');
        setInsuranceId('');
        setInsuranceNumber('');
        setInsuranceStatus('');
        setPrivateText('0');
        setInsuraceType('');
        setWhichSleepingPills('');
        setSleepingPills('');
        setWhichOtherDietSubQuestion('');

        // YOUR BODY STATES
        setGenderQuestion('');
        setGainedWeight('');
        setPregnancy('');
        setPregnancyCount('');
        setKGs('');
        setWeeks('');
        setValue__('');
        setValueHeight('');
        setBloodType('');
        setDisability('');
        setDisabilityValue('');
        setCholesterol('');
        setBloodPressure('');
        setBloodPressureValue('');
        setSBP('');
        setDBP('');
        // YOUR BODY STATES

        // current problem
        setAnyProblem('');
        setWhichOne('');
        // current ends
        // medication starts
        setBloodThinner('');
        setWhichBloodThinner('');
        setSleepingPills('');
        setWhichSleepingPills('');
        setMedicationType('');
        setWhichMedication('');
        setAllData('');
        setEditOptions('');
        // medication ends
        // allergy starts
        setAnyAllergy('');
        setType('');
        setReaction('');
        setValue('');
        setAllergyArr('');
        setData2('');
        setContinueEdit('');
        setAllergiesTwo({
            anyAllergy: '',
            allergyType: '',
            allergyArr: {},
            data2: [],
        });
        // allergy ends
        // family states
        setRelativeDisease('');
        setWhichRelative('');
        setRelativeAge('');
        setAllDisease('');
        setCurrentResponse('');
        setData2_('');
        setNewArr('');

        setWhichOther('');
        setAddingData({});
        // family end
        // life style states
        setWhichOtherDietSubQuestion('');
        setMultipleDiet([]);
        setSelectedCount(0);
        setWhichDiet('');
        setSports('');
        setValue_('');
        setExercise('');
        setSmoke('');
        setSmokePerDay('');
        setSmokeHowLong('');
        setAlcohol('');
        setAlcoholKind('');
        setAlcoholPerWeek('');
        setDrugs('');
        setRSelect('');
        setNightSleep('');
        setSnoreSelect('');
        setGrindSelect('');
        setRestedSelect('');
        setOtherInfo('');
        setSleepHours('');
        setStressSelect('');
        setMoodSelect('');
        setEnergySelect('');
        setWhichOneDrugsLocal([{ whichOne: '', howOftenDrugs: '' }]);

        // life style end
    };

    // edited

    return (
        <ContextProviderTwo.Provider
            value={{
                errZip,
                setErrZip,
                errCity,
                setErrorCity,
                errStreet,
                setErrorStreet,
                errDOB,
                setErrDOB,
                errLastName,
                setErrLastName,
                errFirstName,
                setErrFirstName,
                errGender,
                setErrGender,
                errEmail,
                setErrEmail,
                compulsoryOther,
                setCompulsoryOther,
                other,
                setOther,
                otherFieldError,
                setOtherFieldError,
                setApiData,
                apiData,
                setWhichOneDrugsLocal,
                whichOneDrugsLocal,
                isSaveLifeStyle,
                setIsSaveLifeStyle,
                isConcent,
                setIsConcent,
                setError,
                error,
                errorDate2,
                setErrorDate2,
                errorDate3,
                setErrorDate3,
                errorDate4,
                setErrorDate4,
                whichOtherDietSubQuestion,
                setWhichOtherDietSubQuestion,
                apppointmentDoctorInfo,
                setAppointmentDoctorInfo,
                selectedDoctorGlobal,
                setSelectedDoctorGlobal,
                allDoctorsDataApi,
                setAllDoctorsDataApi,
                emptyStates,
                fromAppointment,
                setFromAppointment,
                setAllDataApi,
                allDataApi,
                setMainDataArray,
                mainDataArray,
                //existing condition
                setPastCondition,
                pastCondition,
                setSurgeries,
                setSergeriesArr,
                setSpecific,
                sergeriesArr1,
                setSergeriesArr1,
                setConditionStatus,
                setSergeriesQuestion,
                setSergerieDate,
                setSergeryDataAll,
                setNewCondition,
                setEditState,
                setCancelEdit,
                setEditPencil,
                setSeugeryData,
                setImaging,
                setImagingQuestion,
                setImagingDate,
                setOldData,
                oldData,
                surgeries,
                specific,
                surgeriesArr,
                coditionStatus,
                sergeriesQuestion,
                sergerieDate,
                sergeryDataAll,
                newCondition,
                editState,
                cancelEdit,
                editPencil,
                surgeryData,
                imaging,
                imagingQuestion,
                imagingDate,
                setWhenPastCondition,
                whenPastCondition,
                // existing condition ends
                // personal Info
                efirstName,
                seteFirstName,
                elastName,
                seteLastName,
                emiddleName,
                seteMiddleName,
                eemail,
                seteEmail,
                estreetMainAdress,
                seteSreetMainAdress,
                ezipMainAdress,
                seteZipMainAdress,
                ecityMainAdress,
                seteCityMainAdress,
                dob,
                setDOB,
                mobileCodeWidth,
                setMobileCodeWidth,
                mobileCode,
                setMobileCode,
                anchorE7,
                setanchorE7,
                mobileNumber,
                setMobileNumber,
                privateTitle,
                setPrivateTitle,
                anchorE6,
                setAnchorE6,
                privateGender,
                setPrivateGender,
                anchorE5,
                setAnchorE5,
                pcountry,
                setPcountry,
                epcountry,
                setePcountry,
                egender,
                seteGender,
                etitle,
                seteTitle,
                pcode,
                setPcode,
                epcode,
                setePcode,
                occuption,
                setOccuption,
                countrySelect,
                setCountrySelect,
                setTestPerfomed,
                setTestPerfomedDate,
                setTestPerfomedArr,
                setTestPerfomedArrEasy,
                setTestEdit,
                setCancelTestEdit,
                setImagingPerfomed,
                setImagingPerfomedDate,
                setImagingPerfomedArr,
                setImagingPerfomedArrEasy,
                setImagingEdit,
                setCancelImagingEdit,
                imagingPerfomedDate,
                imagingPerfomedArr,
                imagingPerfomedArrEasy,
                imagingEdit,
                cancelImagingEdit,
                imagingPerfomed,
                testEdit,
                cancelTestEdit,
                testPerfomed,
                testPerfomedDate,
                testPerfomedArr,
                testPerfomedArrEasy,
                // personal info ends
                // your body starts
                genderQuestion,
                setGenderQuestion,
                gainedWeight,
                setGainedWeight,
                pregnancy,
                setPregnancy,
                pregnancyCount,
                setPregnancyCount,
                KGs,
                setKGs,
                weeks,
                setWeeks,
                value__,
                setValue__,
                valueHeight,
                setValueHeight,
                bloodType,
                setBloodType,
                disability,
                setDisability,
                disabilityValue,
                setDisabilityValue,
                cholesterol,
                setCholesterol,
                bloodPressure,
                setBloodPressure,
                bloodPressureValue,
                setBloodPressureValue,
                sBP,
                setSBP,
                dBP,
                setDBP,
                // your body ends
                // current problem
                anyProblem,
                setAnyProblem,
                whichOne,
                setWhichOne,
                // current ends
                // medication starts
                bloodThinner,
                setBloodThinner,
                whichBloodThinner,
                setSleepingPills,
                sleepingPills,
                whichSleepingPills,
                setWhichSleepingPills,
                setWhichBloodThinner,
                medicationType,
                setMedicationType,
                whichMedication,
                setWhichMedication,
                allData,
                setAllData,
                editOptions,
                setEditOptions,
                // medication ends
                // allergy
                anyAllergy,
                setAnyAllergy,
                type,
                setType,
                reaction,
                setReaction,
                value,
                setValue,
                allergyArrCon,
                setAllergyArr,
                data2Con,
                setData2,
                continueEdit,
                setContinueEdit,
                setAllergiesTwo,
                allergiesTwo,
                // allergy ends
                // family style starts
                relativeDisease,
                setRelativeDisease,
                whichRelative,
                setWhichRelative,
                relativeAge,
                setRelativeAge,
                allDisease,
                setAllDisease,
                currentResponse,
                setCurrentResponse,
                data2_,
                setData2_,
                allDisease1,
                setAllDisease1,
                newArr,
                setNewArr,
                setAddingData,
                addingData,
                setWhichOther,
                whichOther,
                // family ends
                // life starts
                multipleDiet,
                setMultipleDiet,
                selectedCount,
                setSelectedCount,
                whichDiet,
                setWhichDiet,
                sports,
                setSports,
                value_,
                setValue_,
                exercise,
                setExercise,
                smoke,
                setSmoke,
                smokePerDay,
                setSmokePerDay,
                smokeHowLong,
                setSmokeHowLong,
                alcohol,
                setAlcohol,
                alcoholKind,
                setAlcoholKind,
                alcoholPerWeek,
                setAlcoholPerWeek,
                drugs,
                setDrugs,
                rSelect,
                setRSelect,
                nightSleep,
                setNightSleep,
                snoreSelect,
                setSnoreSelect,
                grindSelect,
                setGrindSelect,
                restedSelect,
                setRestedSelect,
                otherInfo,
                setOtherInfo,
                sleepHours,
                setSleepHours,
                setStressSelect,
                stressSelect,
                setMoodSelect,
                moodSelect,
                setEnergySelect,
                energySelect,
                // life ends
                // account starts
                showPassword,
                setShowPassword,
                confirmPassword,
                setConfirmPassword,
                wantAccount,
                setWantAccount,
                passwordValid,
                setPasswordValid,
                validUntil,
                setValidUntil,
                insuranceCompany,
                setInsuranceCompany,
                insuranceId,
                setInsuranceId,
                insuranceNumber,
                setInsuranceNumber,
                insuranceStatus,
                setInsuranceStatus,
                privateText,
                setPrivateText,
                checked,
                setInsuraceType,
                insuranceType,
                setchecked,
                localLoading,
                setLocalLoading,
                verifiactionPortion,
                setVerificationPortion,
                setUser,
                user,
                // account ends
                setNameOfDoctor,
                nameOfDoctor,
                // summrary API
                body1,
                body2,
                body3,
                body4,
                body5,
                body6,
                body7,
                body8,
                setBody1,
                setBody2,
                setBody3,
                setBody4,
                setBody5,
                setBody6,
                setBody7,
                setBody8,
                setHasAccount,
                hasAccount,
                occupation,
                setOccupation,
                newFromTrue,
                setNewFromTrue,
                errorDate,
                setErrorDate,
                fromLocation,
                setFromLocation,
                errPhoneNumber,
                setErrPhoneNumber,
            }}
        >
            {props.children}
        </ContextProviderTwo.Provider>
    );
};
