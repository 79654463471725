import { useMemo } from 'react';
import { getText } from '@/utils/helpers';

export const pageNameLabels = {
    PERSONAL_INFO: 'personalInfo',
    CONTACT_INFO: 'contactInfo',
    HEALTH_INSURANCE: 'healthInsurance',
};

const useFormLabels = (content) =>
    useMemo(() => {
        const labels = {
            personalInfo: {
                heading: content?.corterierContract?.introText || '',
                titleLabel: content?.formData?.title || 'Title',
                firstNameLabel: content?.formData?.firstName || 'First Name',
                middleNameLabel: content?.formData?.middleName || 'Middle Name',
                lastNameLabel: content?.formData?.lastName || 'Last Name',
                dobLabel: content?.formData?.dob || 'DOB',
                phoneLabel: content?.formData?.phone || 'Mobile Number (optional)',
                phoneNoLabel: content?.formData?.phoneNo,
                emailLabel: content?.formData?.email || 'Email',
                genderLabel: getText(content?.gender_label || []) || 'Gender',
            },
            contactInfo: {
                heading: content?.corterierContract?.contactInfoTitle || '',
                streetLabel: content?.formData?.streetAndNumber || 'Street & Number',
                zipCodeLabel: content?.formData?.zipCode || 'Zip Code',
                cityLabel: content?.formData?.city || 'City',
                countryLabel: content?.formData?.country || 'Country',
            },
            healthInsurance: {
                heading: content?.titles?.insuranceDetails || '',
                typeLable: content?.formData?.insuranceType || '',
                nameLabel: content?.formData?.insuranceName || '',
            },
        };
        return labels;
    }, [content]);

export default useFormLabels;
