import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from './LoginClasses';
import UnderlinedButton from '@/components/UnderlinedButton';
import { AntSwitch } from '@/components/Common/AntSwitchCommon';
import { Auth } from 'aws-amplify';
import { usePersistedPrismicStore } from '@/store';
import isEmail from 'validator/es/lib/isEmail';
import { useTranslation } from 'react-i18next';

const LoginMain = ({
    signIn,
    setError,
    setOpen,
    setCode,
    code,
    setEmail,
    email,
    setPassword,
    password,
    setchecked,
    checked,
    localLoading,
    verifyAccount,
    setMainData,
    setLocalLoading,
}) => {
    const history = useHistory();
    const classes = useStyles();
    const { i18n } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);
    const [errEmail, setErrEmail] = useState('');
    const [errPassword, setErrPassword] = useState('');
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const {
        [i18n.language]: { content, dataSet },
    } = usePersistedPrismicStore((state) => state.loginData);

    useEffect(() => {
        setMainData(content);
    }, [content]);

    console.debug('loginPrismic1: ', content);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrEmail('');
        setErrPassword('');
        if (verifyAccount) {
            if (code.length !== 6) {
                setError({
                    title: content?.invalid_code_error[0].text,
                    text: content?.invalid_code_message[0].text,
                });
                setOpen(true);
                return;
            }
            verifyCode();
        } else {
            let err = false;
            if (email === '') {
                setErrEmail(content.required_field_error_message[0].text);
                err = true;
            } else {
                if (!isEmail(email)) {
                    setErrEmail(dataSet && content?.invalid_email_error_message[0].text);
                    err = true;
                }
            }

            if (password === '') {
                setErrPassword(content.required_field_error_message[0].text);
                err = true;
            }

            if (err) {
                return;
            }
            signIn();
        }
    };

    async function verifyCode() {
        console.debug('verifying');

        try {
            setLocalLoading(true);
            const result = await Auth.confirmSignUp(email, code);
            console.debug(result);
            signIn(true);
        } catch (error) {
            setLocalLoading(false);
            setError({
                title: content?.invalid_code_error[0].text,
                text: content?.invalid_code_message[0].text,
            });
            setOpen(true);
            console.debug('error verifying:', error);
        }
    }

    console.debug(content);
    if (!dataSet) {
        return <div></div>;
    }
    // call context for reset password data
    const forgetPaswd = () => {
        history.push('/reset-password');
    };

    return (
        <div className={classes.contentContainer}>
            <Grid container spacing={4} justifyContent="center">
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <div className={classes.registrationForm}>
                        <div className={classes.registrationFormContent}>
                            <Typography className={classes.heading}>
                                {verifyAccount ? content.verify_account_heading[0].text : content.heading[0].text}
                            </Typography>
                            <Typography className={classes.subheading} style={{ marginTop: 5 }}>
                                {verifyAccount
                                    ? content.verify_account_sub_heading[0].text
                                    : content.sub_heading[0].text}
                            </Typography>

                            <form className={classes.form} noValidate autoComplete="off" onSubmit={handleFormSubmit}>
                                {verifyAccount && (
                                    <TextField
                                        id="verifyCode"
                                        label={content.verify_code_textfield_label[0].text}
                                        variant="filled"
                                        color="primary"
                                        placeholder={content.verify_code_textfield_placeholder[0].text}
                                        value={code}
                                        onChange={(event) => {
                                            const text = event.target.value;
                                            setCode(text);
                                        }}
                                        fullWidth
                                        classes={{ root: classes.textFields }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.inputLabel,
                                                focused: 'focused',
                                                shrink: 'shrink',
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.filledInputRoot,
                                                focused: classes.fieldFocused,
                                            },
                                        }}
                                    />
                                )}

                                {!verifyAccount && (
                                    <div className="">
                                        <div>
                                            <TextField
                                                id="email"
                                                label={content.email_textfield_label[0].text}
                                                value={email}
                                                onChange={(event) => {
                                                    const text = event.target.value;
                                                    setEmail(text);
                                                }}
                                                variant="filled"
                                                color="primary"
                                                fullWidth
                                                style={{ marginTop: 15 }}
                                                classes={{
                                                    root: errEmail ? classes.textFieldsError : classes.textFields,
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.inputLabel,
                                                        focused: 'focused',
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.filledInputRoot,
                                                        focused: classes.fieldFocused,
                                                    },
                                                }}
                                            />
                                            {errEmail && (
                                                <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                                                    {errEmail}
                                                </Typography>
                                            )}
                                        </div>

                                        <div>
                                            <TextField
                                                id="password"
                                                label={content.password_textfield_label[0].text}
                                                variant="filled"
                                                color="primary"
                                                value={password}
                                                onChange={(event) => {
                                                    const password = event.target.value;
                                                    setPassword(password);
                                                }}
                                                fullWidth
                                                type={showPassword ? 'text' : 'password'}
                                                style={{ marginTop: 15 }}
                                                classes={{
                                                    root: errPassword ? classes.textFieldsError : classes.textFields,
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.inputLabel,
                                                        focused: 'focused',
                                                        shrink: 'shrink',
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.filledInputRoot,
                                                        focused: classes.fieldFocused,
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOutlinedIcon />
                                                                ) : (
                                                                    <VisibilityOffOutlinedIcon />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {errPassword && (
                                                <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                                                    {errPassword}
                                                </Typography>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {!verifyAccount && (
                                    <div
                                        className=""
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        <UnderlinedButton
                                            onPress={forgetPaswd}
                                            text={content?.forgot_password_button[0].text}
                                        />
                                    </div>
                                )}

                                {!verifyAccount && (
                                    <div
                                        style={{
                                            marginTop: 20,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AntSwitch
                                            checked={checked}
                                            onChange={() => {
                                                setchecked(!checked);
                                            }}
                                            name="notifications"
                                            className={classes.userData}
                                        />

                                        <div className={classes.subheading} style={{ marginLeft: 10 }}>
                                            <span>{content.remember_credentials_text[0].text}</span>
                                        </div>
                                    </div>
                                )}

                                <Button
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    disableElevation
                                    classes={{
                                        root: classes.submitButton,
                                        label: classes.buttonText,
                                    }}
                                >
                                    {localLoading ? (
                                        <CircularProgress />
                                    ) : verifyAccount ? (
                                        content.verify_button[0].text
                                    ) : (
                                        content.login_button[0].text
                                    )}
                                </Button>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default LoginMain;
