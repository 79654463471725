import { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import FilledButton from '@/components/FilledButton';
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import OutlinedButton from '@/components/OutlinedButton';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { getPractitionerForUser } from '@/service/Appointments/appointmentService';
import { Context as AuthContext } from '@/context/AuthContext';
import { Context as DoctorsContext } from '@/context/DoctorsContext';
import { userAccountsApi } from '@/service/AnamnesisSectionAPI/PersonalnformationAPI';
import { getISODate } from '@/utils/helpers';
import { ContextProvider } from '@/context/ProjectContext';
import useFormSteps from '@/hooks/useFormSteps';
import { getColorWithOpacity } from '@/utils/colorHelpers';

const CURRENT_ENV = import.meta.env.VITE_ENVIRONMENT;

const useStyles = makeStyles((theme) => ({
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
    },

    mainHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
        marginBottom: 10,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        textTransform: 'none',
    },
    menuItem: {
        fontSize: 16,
        fontFamily: 'MessinaSans-Regular',
        height: 55,
    },
    formControl: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.14em',

        justifyContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            marginBottom: 15,
            height: '4.11em',
        },
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },

    downArrow: {
        height: '0.65em',
        marginRight: 10,
    },

    form: {
        marginTop: 23,
    },
    textFields: {
        height: '3.75em',
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
    },
    imges: {
        paddingLeft: 20,
    },
    upArrow: {
        height: 'fit-content',
    },
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    main: {
        transition: 'background .5s',
    },
    highlight: {
        background: getColorWithOpacity(theme.palette.common.secondary, 0.15),
        padding: 10,
        marginBlock: 10,
    },
}));

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

export default function PersonalInfoOccupation({
    mainData,
    mainDataProfessionalDoctors,
    contentProfessional,
    isFromHealthData = false,
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    let history = useHistory();

    const {
        state: { selectedPrac, step, save_changes_option },
        setSelectedPrac,
        UpdateSteps,
        onSaveButton,
        onCancelButton,
    } = useContext(HealthProfileContextMain);

    const {
        efirstName,
        elastName,
        emiddleName,
        dob,
        mobileCode,
        mobileNumber,
        egender,
        etitle,
        occuption,
        setOccuption,
        seteFirstName,
        seteLastName,
        seteMiddleName,
        seteEmail,
        setDOB,
        setMobileCode,
        setMobileNumber,
        setPrivateTitle,
        setPrivateGender,
        seteGender,
        seteTitle,
    } = useContext(ContextProviderTwo);

    const { languageGlobal } = useContext(ContextProvider);

    const { setMainDetaArrActive, setMainDataMyDoctorsID, setMainDataMyDoctors } = useContext(DoctorsContext);

    const [dataSet, setDataSet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');
    const [anchorE1, setAnchorE1] = useState(null);
    const {
        state: { usersession, user, setuser },
    } = useContext(AuthContext);

    const { isConsentAllForms, isLastStep } = useFormSteps();

    useEffect(() => {
        if (user) {
            console.debug('🚀 ~ file: PersonalInfoOccupation.jsx:223 ~ user', user);
            let phoneCode =
                user.phone['number'] &&
                user.phone.number.split(' ')[0] !== null &&
                user.phone.number.split(' ')[0] !== undefined &&
                user.phone.number.split(' ')[0] !== ''
                    ? user.phone.number.split(' ')[0]
                    : '+49';
            let phoneNumber =
                user.phone['number'] &&
                user.phone.number.split(' ')[1] !== null &&
                user.phone.number.split(' ')[1] !== undefined &&
                user.phone.number.split(' ')[1] !== ''
                    ? user.phone.number.split(' ')[1]
                    : '';

            let dob = user?.date_of_birth?.split('-').length ? user?.date_of_birth.split('-') : '';
            let validFormat = `${dob[2]}/${dob[1]}/${dob[0]}`;

            console.debug('personal_info1: phoneCode: ', phoneCode);
            seteFirstName(user.name.first);
            seteLastName(user.name.last);
            seteMiddleName(
                user.name.middle !== '' && user.name.middle !== undefined && user.name.middle !== null
                    ? user.name.middle
                    : ''
            );
            seteEmail(user.email);
            setDOB(validFormat);
            setMobileCode(phoneCode !== '' && phoneCode !== undefined && phoneCode !== null ? phoneCode : '');
            setMobileNumber(phoneNumber !== '' && phoneNumber !== undefined && phoneNumber !== null ? phoneNumber : '');
            setPrivateTitle(user.title !== '' && user.title !== undefined && user.title !== null ? user.title : '-');
            setPrivateGender(user.gender);
            seteGender(user.gender);
            seteTitle(user.title !== '' && user.title !== undefined && user.title !== null ? user.title : '-');
            if (user.occupation !== null && user.occupation !== undefined) setOccuption(user.occupation);
        }
    }, []);

    const fetchAndSetUserDoctors = () => {
        getPractitionerForUser(usersession)
            .then((response) => {
                let data = response.data.data;
                let enDoctors = contentProfessional['en-us'];
                let deDoctors = contentProfessional['de-de'];
                let doctorIds = [];
                let myEnDoctors = [];
                let myDeDoctors = [];
                console.debug('MyDoctors: Data', data);
                data.forEach((doctor) => {
                    doctorIds.push(doctor.key);
                    let filteredEnDoctor = enDoctors.filter((r) => r.key[0].text === doctor.key);
                    let filteredDeDoctor = deDoctors.filter((r) => r.key[0].text === doctor.key);

                    myEnDoctors.push(filteredEnDoctor[0]);
                    myDeDoctors.push(filteredDeDoctor[0]);
                });
                console.debug('MyDoctors: Data', {
                    'en-us': myEnDoctors,
                    'de-de': myDeDoctors,
                });
                console.debug('MyDoctors: Ids', doctorIds);
                setMainDataMyDoctors({
                    'en-us': myEnDoctors,
                    'de-de': myDeDoctors,
                });
                setMainDataMyDoctorsID(doctorIds);
                setMainDetaArrActive(true);
                setDataSet(true);
                setLoading(false);
            })
            .catch((err) => {
                console.debug('MyDoctors: Error', err.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (
            selectedPrac.length > 0 &&
            mainDataProfessionalDoctors !== undefined &&
            mainDataProfessionalDoctors !== null
        ) {
            let selectedDoctor = mainDataProfessionalDoctors.filter((f) => f.key[0].text === selectedPrac);
            console.debug('selectedDoctor1:', selectedDoctor);
            setOccuption(selectedDoctor[0].display_name[0].text);
            return;
        }
        if (user) {
            fetchAndSetUserDoctors();
        }
    }, []);

    const Cancel = () => {
        console.debug('hello3', selectedPrac);
        let old = selectedPrac;
        setSelectedPrac(old);
        onCancelButton(history);
    };

    useEffect(() => {
        if (!occuption && Object.keys(user).length > 0) {
            console.debug(
                '🚀 ~ file: PersonalInfoOccupation.jsx ~ line 301 ~ useEffect ~ user.occupation',
                user.occupation,
                user
            );
            setOccuption(user.occupation);
        }
    }, [user]);

    const PersonalInfoApi = () => {
        if (user) {
            // update user Accounts
            // for user
            console.debug('usersessionusersession', usersession);
            const body = {
                name: {
                    first: efirstName,
                    middle: emiddleName,
                    last: elastName,
                },
                gender: egender,
                date_of_birth: getISODate(
                    dob
                        .replace(languageGlobal === 'en' && '.', '/')
                        .replace(languageGlobal === 'en' && '.', '/')
                        .replace(languageGlobal === 'de' && '/', '.')
                        .replace(languageGlobal === 'de' && '/', '.'),
                    languageGlobal
                ),
                title: etitle === '-' ? '' : etitle,
                phone: {
                    number: mobileCode.concat(' ' + mobileNumber),
                },
                occupation: occuption || '',
            };

            userAccountsApi(usersession, body)
                .then((response) => {
                    setOccuption(response.occupation);
                    setuser(response);
                })
                .catch((error) => {
                    console.debug('ErrorInPersonalInfo', error);
                });
        }
    };

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div className={classes.main}>
                    <Typography className={classes.mainHeading}>{mainData?.occupation_question[0].text}</Typography>
                    <TextField
                        label={mainData ? mainData.textbox_occupation_placeholder_text[0].text : t('City')}
                        variant="filled"
                        color="primary"
                        value={occuption}
                        placeholder={mainData.textbox_occupation_placeholder_text[0].text}
                        onChange={(event) => {
                            const text = event.target.value;
                            setOccuption(text);
                        }}
                        fullWidth
                        classes={{
                            root: classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                        style={{ flex: 0.6 }}
                    />
                </div>

                {/* btns */}
                <div
                    style={{
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: matches && 'column-reverse',
                    }}
                >
                    {!save_changes_option ? (
                        <OutlinedButton
                            text={mainData?.back_cta[0].text}
                            onPress={() => {}}
                            disabled={true}
                            fullWidth={matches && true}
                        />
                    ) : (
                        <OutlinedButton
                            text={mainData?.cancel[0].text}
                            onPress={() => {
                                Cancel();
                            }}
                            fullWidth={matches && true}
                        />
                    )}
                    {!save_changes_option ? (
                        <FilledButton
                            text={mainData?.continue_cta[0].text}
                            onPress={() => {
                                PersonalInfoApi();
                                UpdateSteps(step, history, user, isFromHealthData, isConsentAllForms, isLastStep);
                            }}
                            fullWidth={matches && true}
                            marginBottom={matches && 20}
                        />
                    ) : (
                        <FilledButton
                            text={mainData?.save_cta[0].text}
                            onPress={() => {
                                if (save_changes_option) {
                                    onSaveButton(history);
                                }
                            }}
                            fullWidth={matches && true}
                            marginBottom={matches && 20}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
