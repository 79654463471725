import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: (colorInvert) => (colorInvert ? '#FFFFFF' : theme.palette.common.black),
    },
    tooltip: {
        backgroundColor: (colorInvert) => (colorInvert ? '#FFFFFF' : theme.palette.common.black),
        boxShadow: (colorInvert) => (colorInvert ? '0px 3px 12px rgba(120, 120, 120, 0.21)' : ''),
        color: 'white',
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.125rem',
    },
}));

function BootstrapTooltip(props) {
    const { colorInvert, placement } = props;

    const classes = useStylesBootstrap(colorInvert);

    return <Tooltip placement={placement} arrow classes={classes} {...props} />;
}

export default function ToolTipBox({ children, text, colorInvert = false, placement = 'top', props }) {
    return (
        <div>
            <BootstrapTooltip title={text} colorInvert={colorInvert} placement={placement} {...props}>
                {children}
            </BootstrapTooltip>
        </div>
    );
}
