import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { BookingStep } from '@/components/BookingModal';
import { produce } from 'immer';
import { dayjs } from '@/utils/dayjsSetup';
import { debounce } from 'lodash';
import localForage from './services/localforage';
import { compressToUTF16, decompressFromUTF16 } from 'async-lz-string';

const isCompressionRequired = !localForage.supports(localForage.INDEXEDDB);

const SESSION_LIFETIME = 3600000; // 1 hour lifetime

export const useAppointmentStore = create((set, get) => ({
    session: JSON.parse(localStorage.getItem('appointmentSearch')),
    setSession: (newState) => {
        if (get().bookFromDoctor || !get().sessionChecked || get().disabled) return;
        set({ session: { ...newState, validUntil: Date.now() + SESSION_LIFETIME } });
        localStorage.setItem('appointmentSearch', JSON.stringify(newState));
    },
    bookFromDoctor: false,
    disabled: true,
    setBookFromDoctor: (value) => set({ bookFromDoctor: value }),
    isSavedInsuranceUsed: false,
    insuranceTypeContext: '',
    clearSession: () => {
        set({ session: {} });
        localStorage.removeItem('appointmentSearch');
    },
    sessionChecked: false,
}));

export const useTrackingStore = create(() => ({
    calendarMoves: [],
    anamnesisStarted: undefined,
    anamnesisSectionStarted: undefined,
    currentAnamnesisSection: undefined,
    completedAnamnesisSections: {},
}));

export const useCookiesStore = create(
    persist(
        () => ({
            accepted: null,
            essentialCookies: false,
            analyticsCookies: false,
        }),
        {
            name: 'cookies-storage',
        }
    )
);

export const useNotificationsStore = create((set) => ({
    hideSmsNotificationCard: localStorage.getItem('hideSmsNotificationCard'),
    setHideSmsNotificationCard: () => {
        localStorage.setItem('hideSmsNotificationCard', 'true');
        set({ hideSmsNotificationCard: 'true' });
    },
    showSuccessMessage: false,
}));

export const useLoggingStore = create(() => ({
    context: {},
    largeText: '',
    smallText: '',
    ctaText: '',
}));

export const usePrismicStore = create(() => ({
    customers: null,
    customersById: null,
    pageNotFoundData: null,
    unsubscribeData: null,
}));

export const useFormLocation = create(
    persist(
        () => ({
            hub: {},
            secondary_location: {},
            dc_instance_id: '',
        }),
        {
            name: 'formlocation-storage', // Storage key
        }
    )
);

export const useCustomAnamnesisStore = create(() => ({
    questions: {},
    subQuestions: {},
    anamnesisTemplate: null,
    currentCategory: null,
    completedCategories: {},
    loading: true,
    hasFailed: false,
    answers: {},
    highlightedQuestions: {},
    highlightedQuestionForScrolling: null,
    prismicData: {},
    storedProfessionalId: null,
    storedTemplateId: null,
    loginRedirect: false,
    professional: null,
    isPreview: false,
    isFormSent: false,
    redirectToConsentForms: false,
    formsToFill: null,
    currentForm: null,
}));

export const useSearchStore = create(() => ({
    prismicData: null,
    selectedHealthInsurance: null,
    selectedLocation: null,
    selectedSpecialty: null,
    selectedProfessional: null,
    locations: null,
    professionals: null,
    specialties: null,
    treatments: null,
    treatmentTypeCategories: null,
    search: '',
    selectedTreatments: {},
    allDocuments: null,
    showAvailableProfessionals: false,
    isOnlineAppointment: false,
    availableProfessionals: [],
    queryValuesSet: false,
}));

export const useConfigStore = create(() => ({
    currentCustomer: null,
    showPrismicFallback: false,
}));

export const useProfessionalsStore = create(() => ({
    mainDataArr: null,
    mainDataArrActive: null,
    mainDataMyProfessionals: {
        'en-us': [],
        'de-de': [],
    },
    mainDataMyProfessionalsID: [],
    allProfessionals: {
        'en-us': [],
        'de-de': [],
    },
    shuffledMyProfessionals: [],
    hubProfessionals: [],
    professionalMappings: null,
}));

export const useHeaderStore = create(() => ({
    headerDoctor: '',
    headerDoctorKey: '',
}));

const initialBookingStoreState = {
    currentStep: BookingStep.TIMESLOT_SELECTION,
    selectedProfessional: null,
    showReturningPatientToggle: true,
    isReturningPatient: false,
    isOnlineAppointment: false,
    selectedTimeslot: null,
    registrationFormData: {
        selectedGender: null,
        dob: null,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        isEmailInUse: false,
        previouslyTriedEmail: '',
        street: '',
        zipCode: '',
        city: '',
        country: 'DE',
        verificationCode: '',
        isConsentConfirmed: false,
    },
    loading: false,
    appointmentData: null,
    additionalInfo: '',
    showBookingModal: false,
    signUpStarted: false,
    calendarStartDate: dayjs().format('YYYY-MM-DD'),
    secondaryLocation: null,
    secondaryLocationOptions: [],
    isAtSecondaryLocation: false,
    isCodeIncorrect: false,
    previouslyTriedCode: '',
    dcCalendarId: null,
    isProfessionalSelectionSkipped: true,
    consentTreatments: null,
    bookedAppointmentId: '',
    invalidTimeSlot: null,
};

export const useBookingStore = create((set) => ({
    ...initialBookingStoreState,
    resetStore: () => set({ ...initialBookingStoreState }),
    setSelectedGender: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.selectedGender = value;
            })
        ),
    setDob: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.dob = value;
            })
        ),
    setFirstName: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.firstName = value;
            })
        ),
    setLastName: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.lastName = value;
            })
        ),
    setEmail: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.email = value;
            })
        ),
    setPhoneNumber: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.phoneNumber = value;
            })
        ),
    setPassword: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.password = value;
            })
        ),
    setConfirmPassword: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.confirmPassword = value;
            })
        ),
    setEmailCheck: (isEmailInUse, previouslyTriedEmail) =>
        set(
            produce((state) => {
                state.registrationFormData.isEmailInUse = isEmailInUse;
                state.registrationFormData.previouslyTriedEmail = previouslyTriedEmail;
            })
        ),
    setStreet: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.street = value;
            })
        ),
    setZipCode: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.zipCode = value;
            })
        ),
    setCity: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.city = value;
            })
        ),
    setCountry: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.country = value;
            })
        ),
    setVerificationCode: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.verificationCode = value;
            })
        ),
    setVerificationCodeCheck: (isCodeIncorrect, previouslyTriedCode) =>
        set(
            produce((state) => {
                state.isCodeIncorrect = isCodeIncorrect;
                state.previouslyTriedCode = previouslyTriedCode;
            })
        ),
    setIsConsentConfirmed: (value) =>
        set(
            produce((state) => {
                state.registrationFormData.isConsentConfirmed = value;
            })
        ),
}));

// Configure localForage
localForage.config({
    name: 'EternoPatientsApp', // Database name
    storeName: 'prismicStore', // Object store name
});

const storage = createJSONStorage(() => {
    return {
        getItem: () => null,
        setItem: debounce(async (key, value) => {
            let storedValue = value;
            if (isCompressionRequired) {
                storedValue = await compressToUTF16(value);
            }

            await localForage.setItem(key, storedValue);
        }, 2000),
        removeItem: async (key) => {
            await localForage.removeItem(key);
        },
    };
});

const initialPersistedPrismicStoreState = {
    // Prismic Caching
    appVersion: null,
    timestamp: undefined,
    ref: undefined,

    // Header and FooterData
    footerData: {
        en: { content: {}, aboutMenu: {}, legalMenu: {}, dataSet: false },
        de: { content: {}, aboutMenu: {}, legalMenu: {}, dataSet: false },
    },
    headerData: {
        en: { contentHeading: {}, mainNav: {}, dataSet: false },
        de: { contentHeading: {}, mainNav: {}, dataSet: false },
    },

    // Confirm Appointment
    confirmAppointmentData: { en: undefined, de: undefined },

    // Static Pages
    staticPages: { en: undefined, de: undefined },

    // Consent Forms
    consentFormsTreatment: { en: undefined, de: undefined },
    treatmentContractWelcomeData: { en: undefined, de: undefined },

    // Cookie Consent
    cookieConsentData: { en: undefined, de: undefined },

    // Welcome
    welcomePageAnamnesisData: { data: undefined, dataSet: false },

    // Herakles Data
    heraklesAllForms: { en: undefined, de: undefined },
    heraklesCommunication: { en: undefined, de: undefined },
    heraklesCourse: { en: undefined, de: undefined },
    heraklesTherapy: { en: undefined, de: undefined },
    heraklesFormsCheckin: { en: undefined, de: undefined },

    // Wieland Forms
    wielandFormsThankYou: { en: undefined, de: undefined },

    // Login
    loginData: { en: { content: undefined, dataSet: false }, de: { content: undefined, dataSet: false } },

    // Registration
    registerData: { en: { dataSet: false, benefits: undefined }, de: { dataSet: false, benefits: undefined } },

    // Doctor Data
    doctorData: { en: { content: {}, dataSet: false }, de: { content: {}, dataSet: false } },

    // Professional Profile Data
    professionalProfileData: {
        en: { mainData: {}, content: [], dataSet: false },
        de: { mainData: {}, content: [], dataSet: false },
    },

    // health profile
    healthProfileData: {
        en: { content: {}, dataArr: {}, dataSet: false },
        de: { content: {}, dataArr: {}, dataSet: false },
    },

    // medical records data
    medicalRecordsData: { en: { content: {}, dataSet: false }, de: { content: {}, dataSet: false } },

    // Dashboard
    dashboardData: { en: { content: {}, dataSet: false }, de: { content: {}, dataSet: false } },

    // Account
    accountData: { en: { content: {}, dataSet: false }, de: { content: {}, dataSet: false } },

    // Location Data
    locationData: {
        en: { content: {}, dataSet: false, allAppointmentsPrismicData: undefined },
        de: { content: {}, dataSet: false, allAppointmentsPrismicData: undefined },
    },

    // Health Data
    healthData: {
        en: { content: {}, dataSet: false, mainDataProfessionalDoctors: [] },
        de: { content: {}, dataSet: false, mainDataProfessionalDoctors: [] },
    },

    // Anamnesis Data
    anamnesisData: {
        en: {
            allData: {},
            mainDataProfessionalDoctors: {},
            mainDataAccountEterno: {},
            mainDataSummary: [],
            mainData: {},
            mainDataAccount: {},
            contentProfessional: [],
            welcomeData: {},
            thankYouData: {},
            dataSet: false,
        },
        de: {
            allData: {},
            mainDataProfessionalDoctors: {},
            mainDataAccountEterno: {},
            mainDataSummary: [],
            mainData: {},
            mainDataAccount: {},
            contentProfessional: [],
            welcomeData: {},
            thankYouData: {},
            dataSet: false,
        },
    },

    // Book Appointment Data
    bookAppointmentData: {
        en: { mainData: {}, dataSet: false },
        de: { mainData: {}, dataSet: false },
    },

    // Consent Data
    consentData: {
        en: {
            content: {},
            loading: false,
        },
        de: {
            content: {},
            loading: false,
        },
    },

    // Appointment data
    appointmentData: {
        en: { content: {}, dataSet: false, allAppointmentsPrismicData: [] },
        de: { content: {}, dataSet: false, allAppointmentsPrismicData: [] },
    },

    findAppointmentData: {
        en: {
            content: {},
            contentHeading: [],
            bookingHeading: {},
            professionalProfiles: [],
            dataSet: false,
            unprocessedData: null,
        },
        de: {
            content: {},
            contentHeading: [],
            bookingHeading: {},
            professionalProfiles: [],
            dataSet: false,
            unprocessedData: null,
        },
    },

    // Feature Flags
    featureFlags: {},
};

export const usePersistedPrismicStore = create(
    persist(
        (set) => ({
            ...initialPersistedPrismicStoreState,
            _hasHydrated: false,
            resetStore: () => set({ ...initialPersistedPrismicStoreState }),
            loadStore: async () => {
                const value = await localForage.getItem('prismic-storage');

                if (!value) {
                    set({ _hasHydrated: true });
                    return;
                }

                if (isCompressionRequired && typeof value === 'string') {
                    const decompressed = await decompressFromUTF16(value);
                    set({ ...JSON.parse(decompressed).state, _hasHydrated: true });
                    return;
                }

                if (typeof value === 'string') {
                    const parsedValue = JSON.parse(value);
                    set({ ...parsedValue.state, _hasHydrated: true });
                    return;
                }

                set({ ...value.state, _hasHydrated: true });
            },
        }),
        {
            name: 'prismic-storage',
            storage,
        }
    )
);

export const useUploadDocumentModalStore = create((set) => ({
    appointmentId: undefined,
    isAppointmentSelectDisabled: false,
    isModalOpen: false,
    openModal: (appointmentId) =>
        set({ appointmentId, isModalOpen: true, isAppointmentSelectDisabled: !!appointmentId }),
    closeModal: () => set({ appointmentId: undefined, isModalOpen: false, isAppointmentSelectDisabled: false }),
}));
