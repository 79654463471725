import { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import plus_past from '@/assets/images/plus_past.png';
import minus_past from '@/assets/images/minus_past.png';
import { useTranslation } from 'react-i18next';
import PastAppointmentDetails from './PastAppointmentDetails';
import { useSearchStore } from '@/store';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 20,
        paddingRight: 20,
    },
    summaryContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',

        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {},
    },
    accordionRoot: {
        borderRadius: 8,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        backgroundColor: 'white',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },
    accordionSummaryRoot: {
        padding: 0,
        paddingLeft: 30,
        marginRight: 30,
        minHeight: 64,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-end',
            marginRight: 20,
        },
    },
    date: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        width: 200,
        textAlign: 'left',
    },
    title: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textAlign: 'left',
        width: 200,
        [theme.breakpoints.down('sm')]: {
            minWidth: 160,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 0,
        },
    },
    by: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textAlign: 'left',
        width: 200,
        [theme.breakpoints.down('sm')]: {
            minWidth: 160,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 0,
        },
    },
    price: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textAlign: 'left',
        width: 100,
    },
    accordionDetailsRoot: {
        padding: 0,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,

        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
    },
    accordionDetailsContainer: {
        padding: 30,
    },
    separator: {
        width: '100%',
        height: 1,
        background: '#d5d5d5',
    },
    paidContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            flexDirection: 'row',
        },
    },
    icon: { width: 48, height: 48, objectFit: 'contain' },
    accordionButtons: {
        backgroundColor: 'white',

        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        height: 117,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            height: 80,
            paddingLeft: 15,
        },
    },
    buttonText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        marginTop: 10,
        marginLeft: 0,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 10,
            marginTop: 0,
        },
    },
    paidText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        marginLeft: 0,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 10,
        },
    },
    priceSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            marginRight: -23,
        },
    },
    cancelledClass: {
        display: 'flex',
        width: 300,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cancelledText: {
        color: theme.palette.common.red,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
    },
}));

const PastAppointmentAccrodian = ({ appointment, dataSet, mainData, dataSetDoctos }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matchessm = useMediaQuery(theme.breakpoints.down('sm'));
    const [toShow, setToShow] = useState(false);
    const [twoSeconds, setTwoSeconds] = useState(false);

    const treatments = useSearchStore((state) => state.treatments);

    const handleChange = () => {
        setToShow(!toShow);
    };

    const languageMap = {
        en: 'en-us',
        de: 'de-de',
    };
    let lan = languageMap[i18n.language];

    setTimeout(() => {
        setTwoSeconds(true);
    }, 2000);

    let doctors_data =
        dataSetDoctos && twoSeconds && appointment['doctor'] !== undefined && appointment['doctor'] !== null
            ? appointment?.doctor[lan]
            : null;
    let doctor_location =
        dataSetDoctos && twoSeconds && appointment['doctor'] !== undefined && appointment['doctor'] !== null
            ? appointment?.location[lan]
            : null;

    const getDateWithMonth = (date) => {
        if (date !== null && date !== undefined) {
            const d = new Date(date);
            if (d && d !== 'Invalid Date') {
                const options = { day: 'numeric', month: 'long' };
                return new Intl.DateTimeFormat(lan, options).format(d);
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    return (
        <>
            <div>
                <Accordion classes={{ root: classes.accordionRoot }}>
                    <AccordionSummary
                        onClick={handleChange}
                        expandIcon={
                            toShow ? (
                                <img
                                    src={minus_past}
                                    style={{
                                        width: 16,
                                        height: 15,
                                        objectFit: 'contain',
                                    }}
                                />
                            ) : (
                                <img
                                    src={plus_past}
                                    style={{
                                        width: 16,
                                        height: 15,
                                        objectFit: 'contain',
                                    }}
                                />
                            )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{
                            root: classes.accordionSummaryRoot,
                            content: classes.accordionSummaryContent,
                        }}
                    >
                        {matchessm ? (
                            <div className={classes.summaryContainer}>
                                <div>
                                    <Typography className={classes.date}>
                                        {dataSetDoctos && appointment.name}
                                    </Typography>
                                    {dataSetDoctos && appointment?.status === 'CANCELLED' && (
                                        <Typography className={classes.cancelledText}>
                                            {dataSet ? mainData?.cancelled_label1[0].text : 'Cancelled'}
                                        </Typography>
                                    )}
                                    <Typography className={classes.by}>
                                        {dataSetDoctos && doctors_data?.display_name[0].text}
                                    </Typography>

                                    <Typography className={classes.price}>
                                        {dataSetDoctos && lan === 'en-us'
                                            ? getDateWithMonth(appointment.start).split(' ').reverse().join(' ')
                                            : getDateWithMonth(appointment.start).replace('.', ' ')}
                                    </Typography>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.summaryContainer}>
                                <div className={classes.cancelledClass}>
                                    <Typography className={classes.date}>
                                        {dataSetDoctos &&
                                            (treatments[appointment.location_key]?.[appointment.data.services[0].key]
                                                ?.name[0].text ??
                                                appointment.name)}
                                    </Typography>
                                    {dataSetDoctos && appointment?.status === 'CANCELLED' && (
                                        <Typography className={classes.cancelledText}>
                                            {dataSet ? mainData?.cancelled_label1[0].text : 'Cancelled'}
                                        </Typography>
                                    )}
                                </div>

                                <Typography className={classes.by}>
                                    {dataSetDoctos && doctors_data?.display_name[0].text}
                                </Typography>
                                <Typography className={classes.price}>
                                    {dataSetDoctos && lan === 'en-us'
                                        ? getDateWithMonth(appointment.start).split(' ').reverse().join(' ')
                                        : getDateWithMonth(appointment.start).replace('.', ' ')}
                                </Typography>
                            </div>
                        )}
                    </AccordionSummary>
                    <AccordionDetails
                        classes={{
                            root: classes.accordionDetailsRoot,
                        }}
                    >
                        <div className={classes.separator}></div>
                        <div className={classes.accordionDetailsContainer}>
                            <PastAppointmentDetails
                                appointment={appointment}
                                dataSet={dataSet}
                                mainData={mainData}
                                dataSetDoctos={dataSetDoctos}
                                doctor_location={doctor_location}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
};

export default PastAppointmentAccrodian;
