import { TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useCustomAnamnesisStore } from '@/store';
import clsx from 'clsx';
import { useCustomAnamnesisStyles } from '../customAnamnesisStyles';
import { useScrollToQuestion } from '@/hooks/useScrollToQuestion';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
    },
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
}));

const TextareaQuestion = ({ question, answer, setAnswer, isSubQuestion = false }) => {
    const classes = useStyles();
    const customAnamnesisStyles = useCustomAnamnesisStyles();
    const highlightedQuestions = useCustomAnamnesisStore((state) => state.highlightedQuestions);
    const questionRef = useScrollToQuestion(question);

    const onChange = (e) => {
        if (!isSubQuestion) {
            setAnswer(e.target.value);
        } else {
            setAnswer({
                ...answer,
                subQuestions: {
                    ...answer.subQuestions,
                    [question.question_key[0].text]: e.target.value,
                },
            });
        }

        if (e.target.value && highlightedQuestions[question.question_key[0].text]) {
            useCustomAnamnesisStore.setState({
                highlightedQuestions: {
                    ...highlightedQuestions,
                    [question.question_key[0].text]: false,
                },
            });
        }
    };

    return (
        <div
            ref={questionRef}
            className={clsx(
                customAnamnesisStyles.defaultQuestion,
                highlightedQuestions[question.question_key[0].text] && customAnamnesisStyles.highlight
            )}
        >
            {!isSubQuestion && (
                <Typography style={{ marginBottom: '1rem' }} className={classes.title}>
                    {question.title[0].text}
                </Typography>
            )}
            <TextField
                label={!isSubQuestion ? question.label[0]?.text : question.title[0].text}
                variant="filled"
                value={(!isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text]) ?? ''}
                multiline
                onChange={onChange}
                fullWidth
                InputLabelProps={{
                    classes: {
                        root: classes.inputLabel,
                        focused: 'focused',
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.filledInputRoot,
                        focused: classes.fieldFocused,
                    },
                }}
            />
        </div>
    );
};

export default TextareaQuestion;
