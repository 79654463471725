import { useContext, useEffect, useMemo } from 'react';
import usePrismicData from '@/hooks/usePrismicData';
import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';
/** Context */
import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
/** Child Components */
import { WelcomeComponent } from '@/components/ConsentForms';
import Loader from '@/components/Loader';
/** Router */
import { useHistory } from 'react-router-dom';
import { getText } from '@/utils/helpers';
import { uniqBy } from 'lodash';
import { isSafari } from 'react-device-detect';
import { usePersistedPrismicStore } from '@/store';

const description =
    'Please read the following documents thoroughly and fill them out. After completing the required sections, these documents will be forwarded to your practitioner immediately.';

const AllFormsWelcome = () => {
    const history = useHistory();

    const welcomePrismic = usePersistedPrismicStore((state) => state.wielandFormsThankYou);
    const { loading: welcomeLoading, content: welcomeContent } = usePrismicData(welcomePrismic);

    //** Context */
    const { allFormsPages, movePage, whichEnum } = useContext(ConsentFormsContext);
    const {
        state: { user },
    } = useContext(AuthContext);

    const handleContinueWithAccount = () => {
        //** Setting Next Page */
        movePage(whichEnum.NEXT, history);

        if (!user) {
            history.push(`/login?redirectTo=${allFormsPages[1]?.route}`);
        }

        return false;
    };

    const handleContinueAsGuest = () => {
        //** Setting Next Page */
        movePage(whichEnum.NEXT, history);
    };

    const continueText = useMemo(() => {
        return !welcomeLoading ? getText(welcomeContent?.continue_as_guest_title) : 'You don’t have an Eterno account?';
    }, [welcomeLoading, welcomeContent]);

    useEffect(() => {
        //** Redirect back user to checkin page */
        const redirectBack = () => {
            history.replace('/all-forms-checkin');
        };
        window.addEventListener('popstate', redirectBack);

        window.addEventListener('load', redirectBack);
        // for safari browser
        if (isSafari && allFormsPages.length === 1) redirectBack();

        return () => {
            window.removeEventListener('popstate', redirectBack);
            window.removeEventListener('load', redirectBack);
        };
    }, []);

    if (welcomeLoading) return <Loader />;
    return (
        <WelcomeComponent
            width={'70% !important'}
            icon={WelcomeIcon}
            pages={uniqBy(allFormsPages?.slice(1, -1), 'name')}
            title={getText(welcomeContent?.welcome_title) ?? 'Data protection patient information'}
            paragraphs={[getText(welcomeContent?.welcome_text) ?? description]}
            primaryBtnText={getText(welcomeContent?.continue_with_account_cta) ?? 'Continue with your eterno account'}
            {...(!user && { continueText })}
            continueBtnText={getText(welcomeContent?.continue_as_guest_cta) ?? 'Continue as guest'}
            onPrimaryBtnClick={handleContinueWithAccount}
            onContinueBtnClick={handleContinueAsGuest}
        />
    );
};

export default AllFormsWelcome;
