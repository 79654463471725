import { useContext, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import FilledButton from '@/components/FilledButton';
import UnderlinedButton from '@/components/UnderlinedButton';
import pencilIcon from '@/assets/images/pencilIcon.png';
import dustbinIcon from '@/assets/images/dustbinIcon.png';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './AllergiesClasses';
import UnderLinedAddButton from '../UnderLinedAddButton/UnderLinedAddButton';

export const AllergiesMainQuestion = ({ mainData, addData, editOption, deleteEdit, deleteOption, editOptions }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesLG = useMediaQuery(theme.breakpoints.down('xl'));
    const [errAllergy, setErrAllergy] = useState('');

    const {
        anyAllergy,
        type,
        reaction,
        value,
        allergyArrCon,
        data2Con,
        setAnyAllergy,
        setType,
        setReaction,
        setValue,
        setData2,
        setAllergyArr,
    } = useContext(ContextProviderTwo);

    const {
        state: { is_open_state },
        setIsOpenState,
    } = useContext(HealthProfileContextMain);

    const validateForm = (type) => {
        switch (type) {
            case 'whichErr':
                setErrAllergy('');
                if (value === '') {
                    setErrAllergy(t('RequiredField'));
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div className={classes.fixedQuestion}>
                    <Typography className={classes.mainHeading}>{mainData.allergies_question_title[0].text}</Typography>
                    <Typography className={classes.subHeading}>
                        {mainData.allergies_question_subtitle[0].text}
                    </Typography>
                    <Grid
                        style={{
                            marginTop: 20,
                        }}
                        container
                    >
                        {mainData &&
                            mainData.allergies_yes_no_responses.map((parts) => (
                                <Grid lg={'auto'} sm={'auto'} xs={6}>
                                    <Button
                                        onClick={() => {
                                            console.debug(parts.allergies_response_api_value);
                                            setAnyAllergy(parts.allergies_response_api_value);
                                            if (parts.allergies_response_api_value === 'NO') {
                                                setData2([]);
                                                setAllergyArr([]);
                                            }
                                            if (parts.allergies_response_api_value === 'YES' && is_open_state) {
                                                setIsOpenState(false);
                                            }
                                        }}
                                        disableRipple
                                        className={
                                            anyAllergy === parts.allergies_response_api_value
                                                ? clsx(classes.selectItemCard, classes.selectedCard)
                                                : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                        }
                                        style={{
                                            marginBottom: matches && 10,
                                            width: matches && '90%',
                                            display: matches ? null : 'flex',
                                            marginRight: 15,
                                        }}
                                    >
                                        <div className="">
                                            <Typography className={classes.BtncontentText}>
                                                {parts.allergies_response[0].text}
                                            </Typography>
                                        </div>
                                    </Button>
                                </Grid>
                            ))}
                    </Grid>
                </div>

                {/* add more */}
                {anyAllergy.toLocaleLowerCase() === 'yes' && data2Con.length > 0 && (
                    <div className={classes.graySection}>
                        <Typography className={classes.mainHeading}>
                            {mainData.allergies_summary_title[0].text}
                        </Typography>
                        {data2Con.map((all, index) => (
                            <div className={classes.flexClass}>
                                <Typography
                                    className={classes.mainHeading}
                                    style={{ wordBreak: 'break-all', paddingRight: 5 }}
                                >
                                    {all.value}
                                    {all.reaction && `. ${all.reaction}`}
                                </Typography>
                                <div className="" style={{ display: 'flex' }}>
                                    <img
                                        src={pencilIcon}
                                        alt=""
                                        className={classes.icons}
                                        onClick={() => {
                                            if (is_open_state) {
                                                setIsOpenState(false);
                                            }
                                            !editOptions && editOption(index, all);
                                        }}
                                    />
                                    <img
                                        src={dustbinIcon}
                                        alt=""
                                        className={classes.pointer}
                                        onClick={() => {
                                            if (is_open_state) {
                                                setIsOpenState(false);
                                            }
                                            deleteOption(index, all);
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {anyAllergy.toLocaleLowerCase() === 'yes' && allergyArrCon.length > 0 && (
                    <div className={classes.line}></div>
                )}
                {/* end */}

                {anyAllergy.toLocaleLowerCase() === 'yes' && !is_open_state && (
                    <div className={classes.type}>
                        <Typography className={classes.mainHeading}>
                            {(data2Con.length > 0 || data2Con.length === 0) && editOptions
                                ? mainData.edit_allergie[0].text
                                : data2Con.length > 0
                                  ? mainData.add_more_allergies_title[0].text
                                  : mainData.allergies_type_subquestion_required[0].text}
                        </Typography>
                        <Grid
                            style={{
                                marginTop: 20,
                            }}
                            container
                        >
                            {mainData &&
                                mainData.allergies_type_subquestion_responses.map((parts) => (
                                    <Grid lg={'auto'} sm={'auto'} xs={12}>
                                        <Button
                                            onClick={() => {
                                                console.debug(parts.allergies_type_subquestion_response_api_value);
                                                setType(parts.allergies_type_subquestion_response_api_value);
                                            }}
                                            disableRipple
                                            className={
                                                type === parts.allergies_type_subquestion_response_api_value
                                                    ? clsx(classes.selectItemCard, classes.selectedCard)
                                                    : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                            }
                                            style={{
                                                marginBottom: matches && 20,
                                                width: matches && '100%',
                                                display: matches ? null : 'flex',
                                                marginRight: 15,
                                            }}
                                        >
                                            <div className="">
                                                <Typography className={classes.BtncontentText}>
                                                    {parts.allergies_type_response[0].text}
                                                </Typography>
                                            </div>
                                        </Button>
                                    </Grid>
                                ))}
                        </Grid>

                        {type && (
                            <div className="">
                                <TextField
                                    id="allergyWhich"
                                    label={mainData ? mainData.allergies_subquestion_which[0].text : t('TransferSlip')}
                                    variant="filled"
                                    autoComplete="off"
                                    onBlur={() => validateForm('whichErr')}
                                    color="primary"
                                    value={value}
                                    onChange={(event) => {
                                        setValue(event.target.value);
                                    }}
                                    classes={{
                                        root: classes.textFields,
                                    }}
                                    style={{
                                        width: matches ? '100%' : '100%',
                                        flex: matches ? 1 : 0.5,
                                        marginTop: matches ? 0 : matchesLG && 20,
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRoot,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                    }}
                                />
                                {errAllergy && (
                                    <Typography className={classes.errorText}>
                                        {mainData.required_field_error_message1[0].text}
                                    </Typography>
                                )}

                                <TextField
                                    id="reaction"
                                    label={
                                        mainData ? mainData.allergies_subquestion_reaction[0].text : t('TransferSlip')
                                    }
                                    variant="filled"
                                    autoComplete="off"
                                    color="primary"
                                    value={reaction}
                                    onChange={(event) => {
                                        setReaction(event.target.value);
                                    }}
                                    classes={{
                                        root: classes.textFields,
                                    }}
                                    style={{
                                        width: matches ? '100%' : '100%',
                                        flex: matches ? 1 : 0.5,
                                        marginTop: matchesLG && 20,
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRoot,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}

                {anyAllergy.toLocaleLowerCase() === 'yes' &&
                    (is_open_state ? data2Con.length > 0 : type) &&
                    !editOptions && (
                        <UnderLinedAddButton
                            onClick={() => {
                                if (is_open_state) {
                                    setIsOpenState(false);
                                    return;
                                }
                                addData();
                            }}
                            underLineText={mainData.add_allergie_cta[0].text}
                            marginTop={true}
                        />
                    )}

                {anyAllergy.toLocaleLowerCase() === 'yes' && is_open_state
                    ? data2Con.length > 0
                    : type &&
                      editOptions && (
                          <div
                              className=""
                              style={{
                                  marginTop: 30,
                                  display: 'flex',
                                  justifyContent: 'start',
                              }}
                          >
                              <FilledButton
                                  text={mainData?.continue_cta[0].text}
                                  onPress={() => {
                                      addData();
                                  }}
                              />
                              <UnderlinedButton
                                  text={mainData?.cancel[0].text}
                                  onPress={() => {
                                      deleteEdit();
                                  }}
                                  noMarginTop={true}
                                  marginLeft={20}
                              />
                          </div>
                      )}
            </div>
        </>
    );
};
