import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { usePersistedPrismicStore } from '../../../store';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '18px',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '2rem',
        marginBottom: '1rem',
        width: '100%',
    },
    headingSec: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '18px',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '1rem',
        marginBottom: '1rem',
        width: '100%',
    },
    buttoncontentText: {
        ...theme.typography.subheading,
        width: '100%',
        textAlign: 'center',
    },
    selectionContainer: {
        padding: 3,
        marginTop: 15,
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    },
    selectItemCard: {
        backgroundColor: theme.palette.common.lightGray,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'inline-block',
        marginRight: 20,
        marginBottom: 20,
        '&:hover': {
            backgroundColor: theme.palette.common.lightGray,
            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'fit-content',
            marginRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'fit-content',
            marginRight: 10,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 25,
            paddingRight: 25,
        },
    },
    selectItemCardBorder: {
        boxShadow: `0 0 0 1px ${theme.palette.common.mediumBrown}`,
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'fit-content',
        },
    },
    selectedCard: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'fit-content',
        },
    },
}));

const TypeOfDoc = ({ setDocType, docType }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const [selectedBox, setSelectedBox] = useState(null);

    const list = content?.type_of_doc_response ?? [];

    const selectItem = (item, index) => {
        if (selectedBox === index) {
            setSelectedBox(null);
            setDocType('');
        } else {
            setSelectedBox(index);
            setDocType(item);
        }
    };

    return (
        <div>
            <Typography className={classes.heading}>
                {content?.type_of_doc_question[0].text ?? 'Select the type of document'}
            </Typography>
            <div className={classes.selectionContainer}>
                {list.map((item, index) => {
                    return (
                        <Button
                            key={`${index}_${item.type_of_doc_responses}`}
                            onClick={() => {
                                selectItem(item.type_of_doc_responses, index);
                            }}
                            disableRipple
                            className={
                                selectedBox === index
                                    ? clsx(classes.selectItemCard, classes.selectedCard)
                                    : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                            }
                        >
                            <Typography className={classes.buttoncontentText}>
                                {item.type_of_document[0].text}
                            </Typography>
                        </Button>
                    );
                })}
            </div>
        </div>
    );
};

export default TypeOfDoc;
