import { makeStyles, TextField } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useStyles as useAnamnesisStyles } from '../../../pages/HealthProfileAnamnesis/YourBodyComponents/YourBodyStyles';
import Typography from '@material-ui/core/Typography';
import isInt from 'validator/es/lib/isInt';
import isDecimal from 'validator/es/lib/isDecimal';
import clsx from 'clsx';
import { useCustomAnamnesisStyles } from '../customAnamnesisStyles';
import { useCustomAnamnesisStore } from '@/store';
import { useScrollToQuestion } from '@/hooks/useScrollToQuestion';
import useDynamicImport from '@/hooks/useDynamicImport';

export const NumberValidation = {
    WHOLE_NUMBER: 'Whole Number',
    DECIMAL_NUMBER: 'Decimal Number',
};

export const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
    },
    textfield: {
        height: '3.75rem',
    },
    error: {
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
    },
}));

const NumberfieldQuestion = ({
    question,
    answer,
    setAnswer,
    isSubQuestion = false,
    hideTitle = false,
    customValidation = () => true,
}) => {
    const anamnesisClasses = useAnamnesisStyles();
    const classes = useStyles();
    const [isValidAnswer, setIsValidAnswer] = useState(false);
    const customAnamnesisStyles = useCustomAnamnesisStyles();
    const highlightedQuestions = useCustomAnamnesisStore((state) => state.highlightedQuestions);
    const questionRef = useScrollToQuestion(question);
    const Joi = useDynamicImport(() => import('joi'));
    const schema = useMemo(() => Joi?.number(), [Joi]);

    useEffect(() => {
        if ((!isSubQuestion && !answer) || (isSubQuestion && !answer?.subQuestions[question.question_key[0].text])) {
            return;
        }
        switch (question.number_field_validation) {
            case NumberValidation.WHOLE_NUMBER:
                setIsValidAnswer(isInt(!isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text]));
                break;
            case NumberValidation.DECIMAL_NUMBER:
            default:
                setIsValidAnswer(
                    isDecimal(!isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text])
                );
                break;
        }
    }, [answer, isSubQuestion]);

    const handleChange = async (e) => {
        if (!schema || (e.target.value && schema.validate(e.target.value).error)) return;

        if (!isSubQuestion) {
            setAnswer(e.target.value);
        } else {
            setAnswer({
                ...answer,
                subQuestions: {
                    ...answer.subQuestions,
                    [question.question_key[0].text]: e.target.value,
                },
            });
        }

        if (e.target.value && highlightedQuestions[question.question_key[0].text]) {
            useCustomAnamnesisStore.setState({
                highlightedQuestions: {
                    ...highlightedQuestions,
                    [question.question_key[0].text]: false,
                },
            });
        }
    };

    return (
        <div
            ref={questionRef}
            className={clsx(
                customAnamnesisStyles.defaultQuestion,
                highlightedQuestions[question.question_key[0].text] && customAnamnesisStyles.highlight
            )}
        >
            {!isSubQuestion && !hideTitle && (
                <Typography style={{ marginBottom: '1rem' }} className={classes.title}>
                    {question.title[0].text}
                </Typography>
            )}
            <TextField
                label={!isSubQuestion ? question.label[0]?.text : question.title[0].text}
                variant="filled"
                autoComplete="off"
                color="primary"
                value={(!isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text]) ?? ''}
                onChange={handleChange}
                classes={{
                    root: clsx(
                        classes.textfield,
                        (!isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text]) &&
                            (!isValidAnswer ||
                                !customValidation(
                                    !isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text]
                                )) &&
                            classes.error
                    ),
                }}
                style={{ width: '100%' }}
                InputLabelProps={{
                    classes: {
                        root: anamnesisClasses.inputLabel,
                        focused: 'focused',
                    },
                }}
                InputProps={{
                    classes: {
                        root: anamnesisClasses.filledInputRoot,
                        focused: anamnesisClasses.fieldFocused,
                    },
                }}
            />
        </div>
    );
};

export default NumberfieldQuestion;
