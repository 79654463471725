import { useContext, useMemo, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

/** Components */
import TextInputField from '../../../Common/TextInputField';
import SignatureTabs from './SignatureTabs';
import FilledButton from '../../../FilledButton';
import UnderlinedButton from '../../../UnderlinedButton';
import { DateTextField } from '../../../Common/Date/DateTextField';
import SignatureFieldMobile from './SignatureFieldMobile';
import consentFormsStyles from '../../ConsentFormsStyles';

/** Context */
import { ConsentFormsContext, signEnum } from '@/context/ConsentFormsContext';

/** Hooks & others */
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import close from '@/assets/Icons/close_grey.svg';
import clsx from 'clsx';
import dayjs from 'dayjs';

const dialogPaper = {
    style: {
        backgroundColor: 'white',
        width: '60%',
        borderRadius: 8,
        paddingBlock: 15,
    },
};

const dialogMobile = {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    paddingBlock: 15,
};

const SignDocumentModal = ({ open, onClose, city = 'Preview' }) => {
    const { i18n } = useTranslation();
    const classes = consentFormsStyles();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const formattedDate = useMemo(() => dayjs().format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD.MM.YYYY'), [i18n]);

    /** Context */
    const {
        consentFormsContent: content,
        setSignature,
        setSignState,
        setOpenSignModal,
        formData: { firstName, lastName },
    } = useContext(ConsentFormsContext);

    /** Sign Ref */
    const signCanvas = useRef({});

    /** Get Signature */
    const getSignature = () => {
        if (signCanvas.current && Object.keys(signCanvas.current).length) {
            setSignature({ type: 'image', src: signCanvas.current.getTrimmedCanvas().toDataURL('image/png') });
            setSignState(signEnum.SIGNED);
        } else {
            setSignature({ type: 'text', src: `${firstName || ''} ${lastName || ''}` });
            setSignState(signEnum.SIGNED);
        }

        // Closing the modal window
        setOpenSignModal(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.dialogRoot,
            }}
            PaperProps={matchesXs ? dialogMobile : dialogPaper}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h4" className={classes.mainHeading}>
                    {content ? content?.signaturePopup.title : 'Sign document'}
                </Typography>
                <Typography variant="body1" className={clsx(classes.paragraph, classes.fontSerif, classes.my10)}>
                    {content
                        ? content?.signaturePopup.subTitle
                        : 'You can use a generated signature or sign with your own signature.'}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.mobileModalContent}>
                {/* Signature Tabs */}
                {matchesXs ? (
                    <SignatureFieldMobile signCanvas={signCanvas} />
                ) : (
                    <SignatureTabs signCanvas={signCanvas} setSignature={setSignature} />
                )}
                {/* -- MODAL FOOTER -- */}
                {/* Input Text Fields */}
                <div className={clsx(classes.my10, matchesXs ? classes.px24 : null)}>
                    <Grid container spacing={matchesXs ? 0 : 2}>
                        <Grid item md={6} sm={6} xs={12}>
                            <TextInputField label={content ? content?.formData.location : 'Location'} value={city} />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <DateTextField
                                type={'dateOfBirth'}
                                separator={'.'}
                                mainHeading={content ? content?.formData.date : 'Date'}
                                setDOB={() => {}}
                                dob={formattedDate}
                                setError={() => {}}
                                error={''}
                                placeHolderMonth={'MM'}
                                placeHolderYear={'YYYY'}
                                placeHolderDay={'DD'}
                                mt={10}
                            />
                        </Grid>
                    </Grid>
                </div>
                {/* Buttons */}
                <div className={clsx(classes.signModalTextBoxContainer, classes.my15, matchesXs ? classes.px24 : null)}>
                    <FilledButton
                        text={content ? content?.buttons.signDocument : 'Sign Document'}
                        classNameProp={classes.btnMobile}
                        fullWidth={matchesXs}
                        onPress={getSignature}
                    />
                    <UnderlinedButton
                        text={content ? content?.buttons.cancel : 'Cancel'}
                        noMarginTop
                        classNameProp={classes.btnMobile}
                        onPress={onClose}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button disableRipple color="primary" onClick={onClose}>
                    <img src={close} className={classes.closeButton} alt="eterno-cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SignDocumentModal;
