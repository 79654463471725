import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FilledButton from '@/components/FilledButton';
import { useHistory } from 'react-router-dom';
import { Context as AuthContext } from '@/context/AuthContext';
import { Auth } from 'aws-amplify';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { isSafari } from 'react-device-detect';
import { usePersistedPrismicStore, useTrackingStore } from '@/store';
import { trackStormlyEvent } from '@/utils/helpers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    contentContainerBody: {
        maxWidth: 630,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        marginTop: 40,
        minHeight: 600,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 0,
        },
    },
    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 28,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        textTransform: 'none',
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    goBackIcon: {
        height: 14,
        marginRight: 10,
    },
    progressBar: {
        color: theme.palette.common.yellow,
    },
    progressBarSideBar: {
        color: theme.palette.common.green,
    },
    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    //
    mainContainerImgIner: {
        height: 25,
        width: 25,
    },
    flexClass: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    sideBarContainer: {
        padding: 20,
        marginRight: 20,
        height: 'fit-content',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
        paddingTop: 20,
    },
    containerMainHeading: {
        display: 'flex',
    },
    sideBarGreenText: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    sideBarGreenTextMain: {
        color: theme.palette.common.green,
        paddingLeft: 20,
        paddingRight: 20,
    },
    mainPage: {
        backgroundColor: theme.palette.common.white,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
    },
    progressText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    greenImg: {
        width: 18,
        height: 18,
    },
    hamIcon: {
        height: 24,
        width: 18,
    },

    formControl: {
        backgroundColor: 'white',
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        paddingTop: 13,
        paddingBottom: 13,
        marginRight: 20,
        '&:hover': {
            backgroundColor: 'white',
        },
    },

    selectedCard: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    sideBarMain: {
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
    },
    classRoot: {
        minWidth: 0,
        paddingLeft: 13,
        paddingRight: 13,
    },
    menuItem: {
        fontSize: 16,
        fontFamily: 'MessinaSans-Regular',
    },
    menuRoot: {
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    // das
    mainText: {
        marginTop: 15,
    },
    centerClass: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    imge: theme.icons.secondary,
}));

export const ConfirmConsent = () => {
    const classes = useStyles();
    const history = useHistory();
    const { i18n } = useTranslation();
    const [logOutUser, setLogOutUser] = useState(false);
    const [logInUser, setLogInUser] = useState(false);
    const [logOutUser2, setLogOutUser2] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        state: { user },
    } = useContext(AuthContext);

    const { fromAppointment, fromLocation, setFromAppointment } = useContext(ContextProviderTwo);
    const {
        [i18n.language]: { thankYouData },
    } = usePersistedPrismicStore((state) => state.anamnesisData);

    useEffect(() => {
        if (fromLocation) {
            setFromAppointment(false);
        }
        window.scrollTo(0, 0);

        if (isSafari && !user && !fromLocation) {
            window.onload = history.replace('/location');
        }
    }, []);

    useEffect(() => {
        const time = useTrackingStore.getState().anamnesisStarted;
        if (time) {
            trackStormlyEvent('anamnesisCompleted', {
                completed: true,
                time: time,
            });
        }
    }, []);

    console.debug('confirm_consent1: main', thankYouData);

    const signOut = async () => {
        await Auth.signOut();
        setLoading(false);
        setLogOutUser(true);
    };

    const logOutClicked = async () => {
        setLoading(true);
        signOut();
    };

    window.addEventListener('load', function () {
        if (!user) {
            window.location.reload();
            console.debug('refreshPage123');
            history.replace('/location');
        }
    });
    window.addEventListener('popstate', function () {
        if (!user) {
            console.debug('refreshPage123');
            history.replace('/location');
        }
    });

    setTimeout(async () => {
        setLogInUser(true);
        user && !fromAppointment && setLogOutUser2(true);
        if (logOutUser2) {
            signOut();
        }
    }, 5000);

    const goToDashboard = () => {
        history.replace('/');
    };

    return (
        <>
            <div className={classes.contentContainerBody}>
                {!!thankYouData && user && !logOutUser && (
                    <div className={classes.centerClass}>
                        <div className="">
                            <div className={classes.imge}>
                                <img src={thankYouData?.icon_registered.url} alt="" />
                            </div>
                            <div className={classes.mainText}>
                                <Typography className={classes.mainHeading}>
                                    {thankYouData.thank_you_title_2_registered[0].text}
                                </Typography>
                                <Typography className={classes.shortText}>
                                    {thankYouData.thank_you_subtitle_2_registered_[0].text}
                                </Typography>
                                <FilledButton
                                    loading={loading}
                                    onPress={() => {
                                        user && fromAppointment ? goToDashboard() : logOutClicked();
                                    }}
                                    text={
                                        user && fromAppointment
                                            ? thankYouData?.dashboard_button[0].text
                                            : thankYouData.log_out_cta___for_specific_cases_[0].text
                                    }
                                    marginTop={40}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {!!thankYouData && logOutUser && user && (
                    <div>
                        <div className={classes.centerClass}>
                            <div className="">
                                <div className={classes.imge}>
                                    <img src={thankYouData?.icon_registered.url} alt="" />
                                </div>
                                <div className={classes.mainText}>
                                    <Typography className={classes.mainHeading}>
                                        {thankYouData.logged_out_title[0].text}
                                    </Typography>
                                    <Typography className={classes.shortText}>
                                        {thankYouData['logged_out_subtitle_2_-_registered'][0].text}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* 3rd condition logInUser */}
                {!!thankYouData && !user && !logInUser && (
                    <div className={classes.centerClass}>
                        <div className="">
                            <div className={classes.imge}>
                                <img src={thankYouData?.icon_unregistered.url} alt="" />
                            </div>
                            <div className={classes.mainText}>
                                <Typography className={classes.mainHeading}>
                                    {thankYouData.thank_you_title_1_unregistered[0].text}
                                </Typography>
                                <Typography className={classes.shortText}>
                                    {thankYouData.thank_you_subtitle_1_unregistered[0].text}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}

                {!!thankYouData && !user && logInUser && (
                    <div className={classes.centerClass}>
                        <div className="">
                            <div className={classes.imge}>
                                <img src={thankYouData?.logout_icon_unregistered.url} alt="" />
                            </div>
                            <div className={classes.mainText}>
                                <Typography className={classes.mainHeading}>
                                    {thankYouData.logged_out_title[0].text}
                                </Typography>
                                <Typography className={classes.shortText}>
                                    {thankYouData['logged_out_subtitle_-_unregistered'][0].text}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
