import { useContext, useMemo, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import FilledButton from '@/components/FilledButton';
import { ContextProvider } from '@/context/ProjectContext';
import { useHistory } from 'react-router-dom';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import OutlinedButton from '@/components/OutlinedButton';
import clsx from 'clsx';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { AntSwitch } from '@/components/Common/AntSwitchCommon';
import { Context as AuthContext } from '@/context/AuthContext';
import { getISODate, processAPIWithHeaders } from '@/utils/helpers';
import DialogBox from '@/components/Common/DialogBox';
import { useStyles } from './AccountCommponents/AccountsStyles';
import { Auth } from 'aws-amplify';
import AccountVerification from './AccountCommponents/AccountVerification';
import AccountPasswordSection from './AccountCommponents/AccountPasswordSection';
import { adressApi } from '@/service/AnamnesisSectionAPI/PersonalnformationAPI';
import AccountInsuranceSection from './AccountCommponents/AccountInsuranceSection';
import { insuranceApi } from '@/service/AnamnesisSectionAPI/AccountHealthAPI';
import PrivacyPolicyText from '@/components/Common/PrivacyPolicyText';
import { useConfigStore } from '@/store';
import useI18nCountries from '@/hooks/useI18nCountries';

export default function Account({ mainData, mainDataAccount, mainDataAccountEterno }) {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const {
        state: { step, personal_information },
        BackBtn,
        UpdateSteps,
    } = useContext(HealthProfileContextMain);

    const { languageGlobal } = useContext(ContextProvider);
    const {
        confirmPassword,
        wantAccount,
        setWantAccount,
        passwordValid,
        checked,
        setchecked,
        localLoading,
        setLocalLoading,
        setVerificationPortion,
        setHasAccount,
        newFromTrue,
        setNewFromTrue,
        insuranceCompany,
        insuranceId,
        insuranceType,
    } = useContext(ContextProviderTwo);
    const [errorDate, setErrorDate] = useState(false);
    let isValidated = insuranceType === '' || (insuranceType === 'PRIVATE' && insuranceCompany === '');

    let isPasswordValid = passwordValid.password === '' || !confirmPassword;

    const countryList = useI18nCountries();
    // signUp
    const [open, setOpen] = useState(false);
    const [error, setError] = useState({
        title: 'Invalid Code',
        text: 'Please enter correct code',
    });

    const [errPassword, setErrPassword] = useState('');
    const [user1, setUser] = useState(null);
    const {
        state: { user },
        setuser,
        setusersession,
        setuseraccounts,
    } = useContext(AuthContext);
    const [code, setCode] = useState('');

    const AccountDisabled = () => {
        return wantAccount === 'NO' ? false : isPasswordValid || !checked || isValidated;
    };

    const account = () => {
        if (AccountDisabled()) {
            setNewFromTrue(true);
            return;
        }
        setNewFromTrue(false);
        if (wantAccount === 'YES') {
            if (!user1) {
                if (passwordValid.password !== confirmPassword) {
                    setErrPassword(mainData.password_not_match[0].text);
                    return;
                }
                if (passwordValid.password === confirmPassword) {
                    setErrPassword('');
                }
                setVerificationPortion(true);
                signUp();
            } else {
                if (user1) {
                    if (code.length !== 6) {
                        console.debug('user123 is error');
                        setError({
                            title: mainDataAccount?.invalid_code_error[0].text,
                            text: mainDataAccount?.invalid_code_message[0].text,
                        });
                        setOpen(true);
                        return;
                    }

                    verifyCode();
                }
            }
        } else if (wantAccount === 'NO') {
            UpdateSteps(step, history, user1);
            setVerificationPortion(false);
            console.debug('user123 No ', user1, user);
        }
    };

    // signUp function
    async function signUp() {
        console.debug('signing up');

        try {
            setLocalLoading(true);
            const { user } = await Auth.signUp({
                username: personal_information.email,
                password: confirmPassword,
                attributes: {
                    email: personal_information.email, // optional
                    'custom:firstName': personal_information.firstName.trim(),
                    'custom:middleName': personal_information.middleName ? personal_information.middleName.trim() : '',
                    'custom:lastName': personal_information.lastName.trim(),
                    'custom:gender': personal_information.gender.trim(),
                    'custom:title': personal_information.title === '-' ? '' : personal_information.title.trim(),
                    'custom:dob': getISODate(
                        personal_information.dob
                            .replaceAll(languageGlobal === 'en' && '.', '/')
                            .replaceAll(languageGlobal === 'de' && '/', '.'),
                        languageGlobal
                    ),
                    'custom:phone': personal_information?.phoneNumber?.trim(),
                    'custom:language': i18n.language,
                },
            });
            console.debug(user);

            setUser(user);
            setLocalLoading(false);
        } catch (err) {
            setLocalLoading(false);
            setError({
                title: 'Error signing up',
                text: err.message,
            });
            setOpen(true);
            console.debug('error signing up:', err);
        }
    }

    // verfication
    async function verifyCode() {
        console.debug('verifying');
        console.debug('hello ', user1.username, code);

        try {
            setLocalLoading(true);
            const result = await Auth.confirmSignUp(user1.username, code);
            console.debug(result);
            signIn();
        } catch (err) {
            setLocalLoading(false);
            setError({
                title: mainDataAccount?.invalid_code_error[0].text,
                text: mainDataAccount?.invalid_code_message[0].text,
            });
            console.debug('error verifying:', err);
            setOpen(true);
        }
    }

    const timeout = (delay) => {
        return new Promise((res) => setTimeout(res, delay));
    };

    async function signIn() {
        console.debug('signing in');
        try {
            const user = await Auth.signIn(personal_information.email, confirmPassword);
            console.debug('userAPI11 11', user);
            const userData = user.attributes;
            const currentCustomer = useConfigStore.getState().currentCustomer;
            const customer_id = currentCustomer?.customer_id?.[0].text;
            const response = await processAPIWithHeaders('secure/users', 'POST', {
                body: {
                    id: user.username,
                    email: personal_information.email,
                    name: {
                        first: userData['custom:firstName'],
                        middle: userData['custom:middleName'],
                        last: userData['custom:lastName'],
                    },
                    gender: userData['custom:gender'],
                    date_of_birth: userData['custom:dob'],
                    title: userData['custom:title'],
                    phone: { number: userData['custom:phone'] },
                    occupation: personal_information.occuption,
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.signInUserSession.idToken.jwtToken,
                    customer_id,
                },
            });

            console.debug('userAPI11', response);
            const userAccounts = await processAPIWithHeaders('secure/user/accounts/' + user.username, 'GET', {
                headers: {
                    Authorization: user.signInUserSession.idToken.jwtToken,
                },
            });
            console.debug(userAccounts);
            await timeout(1000);
            if (userAccounts && userAccounts.status) {
                const updateAccount = await processAPIWithHeaders('secure/user/accounts/' + user.username, 'POST', {
                    body: {
                        user_id: user.username,
                        privacy_policy_status: 'ACCEPTED',
                        terms_and_conditions_status: 'ACCEPTED',
                        use_of_analytics_status: 'UNKNOWN',
                        verification_status: 'VERIFIED',
                        locale: {
                            language: i18n.language,
                            country: 'DE',
                        },
                        status: 'ACTIVE',
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.signInUserSession.idToken.jwtToken,
                    },
                });
                console.debug(updateAccount);
                setuseraccounts(updateAccount);
            } else {
                const createAccount = await processAPIWithHeaders('secure/user/accounts/' + user.username, 'POST', {
                    body: {
                        user_id: user.username,
                        privacy_policy_status: 'ACCEPTED',
                        terms_and_conditions_status: 'ACCEPTED',
                        use_of_analytics_status: 'UNKNOWN',
                        verification_status: 'VERIFIED',
                        locale: {
                            language: i18n.language,
                            country: 'DE',
                        },
                        status: 'ACTIVE',
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.signInUserSession.idToken.jwtToken,
                    },
                });
                console.debug(createAccount);
                setuseraccounts(createAccount);
            }

            setuser(response);
            setusersession(user.signInUserSession);
            setLocalLoading(false);
            setVerificationPortion(false);
            const bodyTwo = {
                user_id: user?.signInUserSession.idToken.payload.sub,
                street: personal_information.streetNumber,
                zip: personal_information.zipCode,
                city: personal_information.city,
                country: {
                    code: personal_information.countryCode,
                    name: countryList[personal_information.countryCode],
                },
            };
            adressApi(user?.signInUserSession, bodyTwo);
            // insurance API

            const bodyThree = {
                insurance_type: insuranceType.toUpperCase().replaceAll('-', '_'),
                user_id: user?.signInUserSession?.idToken?.payload?.sub,
            };
            if (insuranceId) bodyThree.insurance_id = insuranceId;
            if (insuranceCompany) bodyThree.insurance_company = insuranceCompany;

            insuranceApi(user.signInUserSession, bodyThree);
            console.debug('user123', user1, user);
            history.push(`/anamnesis-health-profile?step=summary`);
        } catch (err) {
            setLocalLoading(false);

            console.debug('error signing in', err);
        }
    }

    const privacyPolicyContent = useMemo(
        () => [
            {
                id: 1,
                text: mainDataAccount['opt-in_text'][0]?.text,
                link: mainDataAccount.term_and_condition_one[0]?.text,
                href: mainDataAccount.link_one[0]?.text,
            },
            {
                id: 2,
                text: mainDataAccount.opt_text_2[0]?.text,
                link: mainDataAccount.term_and_condition_two[0]?.text,
                href: mainDataAccount.link_two[0]?.text,
            },
            {
                id: 3,
                text: mainDataAccount.opt_text_3[0]?.text,
                link: mainDataAccountEterno?.privacy_policy_link_[0]?.data.title[0]?.text,
                href: mainDataAccountEterno?.privacy_policy_link_[0]?.data.path[0]?.text,
            },
        ],
        [mainDataAccount, mainDataAccountEterno?.privacy_policy_link_]
    );

    return (
        <>
            {user1 ? (
                <AccountVerification mainDataAccount={mainDataAccount} setCode={setCode} code={code} user1={user1} />
            ) : (
                <div className={classes.contentContainerBody}>
                    <div>
                        <div className={newFromTrue && wantAccount.length === 0 ? classes.highlight : classes.main}>
                            <Typography className={classes.mainHeading}>{mainData.account_question[0].text}</Typography>
                            <Grid
                                style={{
                                    marginTop: 20,
                                }}
                                container
                            >
                                {mainData &&
                                    mainData.register_yes_no_response.map((parts) => (
                                        <Grid lg={'auto'} sm={'auto'} xs={12}>
                                            <Button
                                                onClick={() => {
                                                    console.debug(parts.register_yes_no_api_value);
                                                    setWantAccount(parts.register_yes_no_api_value);
                                                    setHasAccount(true);
                                                }}
                                                disableRipple
                                                className={
                                                    wantAccount === parts.register_yes_no_api_value
                                                        ? clsx(classes.selectItemCard, classes.selectedCard)
                                                        : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                                }
                                                style={{
                                                    marginBottom: matches && 20,
                                                    width: matches && '100%',
                                                    display: !matches && 'flex',
                                                    marginRight: 15,
                                                }}
                                            >
                                                <div className="">
                                                    <Typography className={classes.BtncontentText}>
                                                        {parts.register_yes_no_responses[0].text}
                                                    </Typography>
                                                </div>
                                            </Button>
                                        </Grid>
                                    ))}
                            </Grid>
                        </div>
                        {/* password field */}

                        {wantAccount === 'YES' && (
                            <div className="">
                                <div className={newFromTrue && isPasswordValid ? classes.highlight : classes.main}>
                                    <AccountPasswordSection
                                        mainData={mainData}
                                        mainDataAccount={mainDataAccount}
                                        errPassword={errPassword}
                                    />
                                </div>
                                {/* insurance section API start */}
                                <div className={newFromTrue && isValidated ? classes.highlight : classes.main}>
                                    <AccountInsuranceSection
                                        mainData={mainData}
                                        mainDataAccountEterno={mainDataAccountEterno}
                                        errorDate={errorDate}
                                        setErrorDate={setErrorDate}
                                    />
                                </div>
                                {/* insurance section API ends */}
                                <div
                                    className={newFromTrue && !checked ? classes.highlight : classes.main}
                                    style={{
                                        marginTop: 40,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'start',
                                        width: '98%',
                                    }}
                                >
                                    <AntSwitch
                                        checked={checked}
                                        onChange={() => {
                                            setchecked(!checked);
                                        }}
                                        name="notifications"
                                        className={classes.userData}
                                    />
                                    <PrivacyPolicyText
                                        data={privacyPolicyContent}
                                        seeLess={mainDataAccount?.see_more_cta[0]?.text}
                                        seeMore={mainDataAccount?.see_less_cta[0]?.text}
                                    />
                                </div>
                            </div>
                        )}
                        {/* Last text section ends */}
                    </div>
                    {/* is eterno Account */}
                </div>
            )}
            <div
                className=""
                style={{
                    marginTop: 30,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <OutlinedButton
                    text={mainData?.back_cta[0].text}
                    onPress={() => {
                        BackBtn(step, history, user1);
                    }}
                />
                <FilledButton
                    text={mainData?.continue_cta[0].text}
                    onPress={() => {
                        account();
                    }}
                    loading={localLoading}
                />
            </div>
            <DialogBox
                open={open}
                onClose={() => {
                    console.debug('closing dialog');
                    setOpen(false);
                }}
                title={error.title}
                text={error.text}
            />
        </>
    );
}
