import { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import FilledButton from '@/components/FilledButton';
import { useHistory, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as AuthContext } from '@/context/AuthContext';
import OutlinedButton from '@/components/OutlinedButton';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    contentContainerBody: {
        maxWidth: 630,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        marginTop: 40,
        minHeight: 600,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 0,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 20,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        textTransform: 'none',
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    goBackIcon: {
        height: 14,
        marginRight: 10,
    },
    progressBar: {
        color: theme.palette.common.yellow,
    },
    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainContainerImgIner: {
        height: 65,
        width: 65,
    },
    flexClass: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
}));

export default function WelcomePage() {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const location = useLocation();
    const { i18n } = useTranslation();

    const { setLoginAnamnesis } = useContext(HealthProfileContextMain);
    const { fromAppointment, emptyStates } = useContext(ContextProviderTwo);
    console.debug('🚀 ~ file: WelcomePage.jsx:85 ~ WelcomePage ~ fromAppointment:', fromAppointment);
    const {
        state: { user },
    } = useContext(AuthContext);

    const {
        [i18n.language]: { welcomeData: mainData, dataSet },
    } = usePersistedPrismicStore((state) => state.anamnesisData);

    window.addEventListener('load', function () {
        if (!user) {
            window.location.reload();
            history.replace('/location');
        }
    });

    const handleLogin = () => {
        history.push('/login');
        setLoginAnamnesis(true);
    };

    const timeOut = () => {
        if (location.pathname === '/anamnesis-welcome') {
            emptyStates();
        }
    };

    setTimeout(() => {
        timeOut();
    }, 300000);

    return (
        <>
            {dataSet ? (
                <div className={classes.contentContainerBody}>
                    <div className={classes.flexClass}>
                        <div className={classes.mainContainerImg}>
                            <img src={mainData.icon.url} alt="welcome Page" className={classes.mainContainerImgIner} />
                        </div>
                        <div className={classes.textContainer}>
                            <Typography className={classes.mainHeading}>{mainData?.welcome_title[0].text}</Typography>
                            <Typography className={classes.shortText}>
                                {mainData?.welcome_anamnesis_description[0].text}
                            </Typography>

                            <FilledButton
                                text={mainData?.login_cta[0].text}
                                onPress={() => {
                                    handleLogin();
                                }}
                                marginBottom={30}
                                fullWidth={matches && true}
                                marginTop={30}
                            />
                            <Typography className={classes.shortText}>{mainData?.login_title[0].text}</Typography>
                            <div style={{ marginTop: 30 }}>
                                <OutlinedButton
                                    text={mainData?.lets_start_cta[0].text}
                                    onPress={() => {
                                        setLoginAnamnesis(true);
                                        history.push('/anamnesis-health-profile');
                                    }}
                                    color={'yellow'}
                                    fullWidth={matches}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classes.loadingProgress}>
                    <CircularProgress className={classes.progressBar} size={50} />
                </div>
            )}
        </>
    );
}
