import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },

    contentContainer: {
        maxWidth: theme.layout.contentWidth,

        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    placeholder: {
        backgroundColor: 'blue',
        height: 100,
        width: '100%',
    },

    mainContent: {
        width: '100%',
        height: 220,
        backgroundColor: '#d8d8d8',
        borderRadius: 7,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    heading: {
        ...theme.typography.HL1,
    },

    contentText: {
        ...theme.typography.content,
    },
    checkMark: {
        width: '0.875em',
        height: '0.875em',
        marginTop: 2,
    },
    icon: {
        width: '3.75em',
        height: '3.75em',
    },
    registrationForm: {
        backgroundColor: 'white',

        width: '100%',
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
    },
    registrationFormContent: {
        marginLeft: 50,
        marginRight: 50,
        paddingTop: 43,
        paddingBottom: 43,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 30,
            marginRight: 30,
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 20,
            marginRight: 20,
            paddingTop: 23,
            paddingBottom: 23,
        },
    },
    formControl: {
        width: '100%',
        backgroundColor: theme.palette.common.lightBrown,
        marginBottom: 20,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    downArrow: {
        height: '0.75em',
    },
    dropdownFilled: {
        backgroundColor: theme.palette.common.lightBrown,
    },
    form: {
        marginTop: 23,
    },
    textFields: {
        height: '3.75em',
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
    },
    dropDownContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    dropDownRoot: {
        '&:hover': {
            backgroundColor: theme.palette.common.lightBrown,
        },
    },
    blackCircle: {
        backgroundColor: theme.palette.common.darkGrey,
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    greenCircle: {
        backgroundColor: '#5ba668',
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    passwordText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    passwordValidText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 18,
        borderRadius: '2em',
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    buttonText: {
        ...theme.typography.buttonText,
    },
    alreadyMember: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
        width: '100%',
        textAlign: 'center',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
    },
    check: {
        widht: 30,
        height: 30,
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    dropdownStyle: {
        backgroundColor: theme.palette.common.lightBrown,
    },
    underlineText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',

        lineHeight: '1.5rem',
        textTransform: 'uppercase',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        width: '100%',
        textAlign: 'center',
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    subheading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
            //backgroundColor: "red",
        },
    },
}));
