import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { memo, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { menuItems } from '@/utils/helpers';
import FilledButton from '../../FilledButton';
import CardWrapper from './CardWrapper';
import PercentageProgressBar from './PercentageProgressBar';
// contexts
import { Context as AuthContext } from '@/context/AuthContext';
import { getCurrentUser } from '@/service/user';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        position: 'relative',
    },

    progressParent: {
        position: 'relative',
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            position: 'static',
        },
    },

    mainHeading: {
        fontSize: '1.2rem',
        marginBottom: 20,
        color: theme.palette.common.brown,
        fontFamily: 'MessinaSans-SemiBold',
    },

    progressHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '24px',
        fontWeight: 500,
    },

    buttonParent: {
        position: 'absolute',
        bottom: 0,
        [theme.breakpoints.down('xs')]: {
            position: 'static',
            width: '100%',
            marginTop: '.8rem',
        },
    },
}));

const menuList = menuItems();

const list = ['body', 'active', 'medications', 'allergies', 'history', 'family-history', 'lifestyle'];

const checkWhichEmpty = (data) => {
    const emptyList = [];
    data.forEach((d) => {
        for (const key in d.data) {
            if (d.data[key] === '') {
                /* Pushing empty items to new array */
                emptyList.push({
                    category: d.category,
                    emptyProperty: key,
                });
            }
        }
    });
    return emptyList;
};

const checkWhichNotExists = (data) => {
    let pageIndex;
    data.forEach((d, i) => {
        if (i + 1 === data.length) {
            const whichItem = list.findIndex((listItem) => listItem === d.category);
            // Setting next page index
            pageIndex = whichItem + 1;
        }
    });
    return pageIndex;
};

const HealthProfileComplete = ({ percentage = 1, userData }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const { i18n } = useTranslation();

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.healthProfileData);

    const {
        state: { user },
    } = useContext(AuthContext);

    const [occ, setOcc] = useState('');

    useEffect(() => {
        getCurrentUser().then((res) => {
            if (res.hasOwnProperty('occupation')) setOcc(res?.occupation);
        });
    }, []);

    // click callback
    const handleClick = () => {
        const { category_data } = userData;

        const lifeStyleIndex = category_data?.findIndex((c) => c.category === 'lifestyle');
        const bodyIndex = category_data?.findIndex((c) => c.category === 'body');
        const existingIndex = category_data?.findIndex((c) => c.category === 'history');

        const isYourBody = category_data[bodyIndex]?.data && Object.keys(category_data[bodyIndex]?.data).length < 7;
        const isLifeStyle =
            category_data[lifeStyleIndex] && Object.keys(category_data[lifeStyleIndex]?.data).length < 6;
        const isExisting = category_data[existingIndex] && Object.keys(category_data[existingIndex]?.data).length < 7;

        // Redirecting to the personalinfo page
        if (occ.length === 0 || !user?.hasOwnProperty('occupation')) {
            history.push(`/health-data?step=${menuList[0]}`);
        } else if (!!category_data) {
            const [emptyData] = checkWhichEmpty(category_data);

            /* checks which field is missing */
            let missignDataIndex = checkWhichNotExists(category_data);

            /* makes local copy to prevent original list from changes */
            const currMenuList = [...menuList];
            currMenuList.shift();

            if ((missignDataIndex < 0 || missignDataIndex === 7) && !isYourBody && !isLifeStyle && !isExisting) {
                list.forEach((pageName, index) => {
                    if (pageName === emptyData?.category) {
                        history.push(`/health-data?step=${currMenuList[index]}`);
                    }
                });
            } else if (isYourBody || isExisting || isLifeStyle) {
                history.push(
                    `/health-data?step=${
                        currMenuList[
                            isYourBody ? bodyIndex : isExisting ? existingIndex : isLifeStyle ? lifeStyleIndex : 0
                        ]
                    }`
                );
            } else {
                history.push(`/health-data?step=${currMenuList[missignDataIndex]}`);
            }
        } else {
            /* redirecting to personal info page if no data found */
            history.push(`/health-data?step=${menuList[0]}`);
        }
    };

    return (
        <>
            {Object.keys(content).length !== 0 && (
                <CardWrapper>
                    <Grid container spacing={2} className={classes.progressParent}>
                        <Grid item md={10} xs={9} style={{ padding: 0 }}>
                            <Typography className={classes.progressHeading}>
                                {content?.health_profile_progress_title[0].text}
                            </Typography>
                        </Grid>
                        <Grid item md={2} xs={3} style={{ padding: 0 }}>
                            <PercentageProgressBar
                                size={matches ? 80 : 100}
                                progressValue={percentage}
                                progressPercent={percentage}
                            />
                        </Grid>
                        <div className={classes.buttonParent}>
                            <FilledButton
                                fullWidth={matches}
                                text={content?.health_profile_progress_cta[0].text}
                                onPress={handleClick}
                            />
                        </div>
                    </Grid>
                </CardWrapper>
            )}
        </>
    );
};

export default memo(HealthProfileComplete);
