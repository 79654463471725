import { makeStyles } from '@material-ui/core/styles';
import { useConfigStore, useSearchStore } from '@/store';
import { useQuery } from '@/utils/helpers';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import downArrow from '@/assets/Icons/Chevron Down.svg';
import { Context as AuthContext } from '@/context/AuthContext';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 'lighter',
        borderRadius: '8px',
        boxShadow: '0px 3px 12px 0px rgba(120, 120, 120, 0.22)',
        width: '100%',
        padding: '20px',
        gap: '20px',
        boxSizing: 'border-box',
        marginBottom: '2rem',
        background: 'white',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    elementContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    dropdownContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        flex: 2,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            flexDirection: 'column',
        },
    },
    label: {
        fontSize: 15,
        margin: 0,
    },
    formControl: {
        padding: '12px 16px',
        borderRadius: '8px',
        border: '1px solid rgba(46, 46, 46, 0.70)',
        fontSize: '16px',
        outline: 'none',
        WebkitAppearance: 'none',
        color: 'black',
        background: 'white',
        // '&:focus': {
        //     border: '2px solid rgba(46, 46, 46, 0.70)',
        // },
    },
    searchInput: {
        padding: '12px 32px 12px 16px',
    },
    searchWrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    clearIcon: {
        position: 'absolute',
        right: '0.5rem',
        top: '50%',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        transition: 'opacity 0.3s ease',
    },
    downArrow: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        pointerEvents: 'none',
    },
}));

export const HealthInsurance = {
    PUBLIC: 'public',
    PRIVATE: 'private',
    SELF_PAYER: 'self_payer',
};

const SearchBar = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const query = useQuery();
    const {
        state: { userHealthInsurance },
    } = useContext(AuthContext);
    const prismicData = useSearchStore((state) => state.prismicData);
    const search = useSearchStore((state) => state.search);
    const selectedHealthInsurance = useSearchStore((state) => state.selectedHealthInsurance);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const selectedProfessional = useSearchStore((state) => state.selectedProfessional);
    const treatments = useSearchStore((state) => state.treatments);
    const specialties = useSearchStore((state) => state.specialties);
    const queryValuesSet = useSearchStore((state) => state.queryValuesSet);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);
    const [insuranceOptions, setInsuranceOptions] = useState(Object.values(HealthInsurance));

    const getSelectedHealthInsurance = (availableInsuranceTypes) => {
        const queryInsurance = query.get('insurance_type');

        if (queryInsurance) {
            return availableInsuranceTypes.includes(HealthInsurance[queryInsurance.toUpperCase()])
                ? HealthInsurance[queryInsurance.toUpperCase()]
                : availableInsuranceTypes[0];
        }

        if (
            (currentCustomer?.is_accordion_view_active && !currentCustomer?.is_accordion_for_multiple_professionals) ||
            !userHealthInsurance?.insurance_type
        ) {
            return currentCustomer.preselected_insurance ?? availableInsuranceTypes[0];
        }

        if (
            userHealthInsurance.insurance_type === 'SELFPAYER' &&
            availableInsuranceTypes.includes(HealthInsurance.SELF_PAYER)
        ) {
            return 'self_payer';
        } else {
            return availableInsuranceTypes.includes(userHealthInsurance.insurance_type.toLowerCase())
                ? userHealthInsurance.insurance_type.toLowerCase()
                : availableInsuranceTypes[0];
        }
    };

    useEffect(() => {
        if (!treatments[selectedLocation]) return;

        const insuranceFlags = {
            isPublicAvailable: false,
            isPrivateAvailable: false,
            isSelfPayerAvailable: false,
        };

        for (const treatment of Object.values(treatments[selectedLocation])) {
            if (treatment.public_insurance) insuranceFlags.isPublicAvailable = true;
            if (treatment.private_insurance) insuranceFlags.isPrivateAvailable = true;
            if (treatment.self_payer) insuranceFlags.isSelfPayerAvailable = true;

            // Break out early if all flags are true
            if (
                insuranceFlags.isPublicAvailable &&
                insuranceFlags.isPrivateAvailable &&
                insuranceFlags.isSelfPayerAvailable
            ) {
                break;
            }
        }

        const availableInsuranceTypes = [];
        if (insuranceFlags.isPublicAvailable) availableInsuranceTypes.push(HealthInsurance.PUBLIC);
        if (insuranceFlags.isPrivateAvailable) availableInsuranceTypes.push(HealthInsurance.PRIVATE);
        if (insuranceFlags.isSelfPayerAvailable) availableInsuranceTypes.push(HealthInsurance.SELF_PAYER);

        setInsuranceOptions(availableInsuranceTypes);

        useSearchStore.setState({
            selectedHealthInsurance: getSelectedHealthInsurance(availableInsuranceTypes),
        });
    }, [treatments, selectedLocation]);

    useEffect(() => {
        if (queryValuesSet) return;

        const querySearch = query.get('search');
        const querySpecialty = query.get('specialty');
        const querySelectedProfessional = query.get('selected_professional');
        const queryLocation = query.get('location');
        const queryTreatment = query.get('service_type');
        const queryIsOnlineService = query.get('is_online_service');

        const doesQueryLocationExist =
            queryLocation && currentCustomer.locations.some((entry) => entry.key[0]?.text === queryLocation);
        const newSelectedLocation = doesQueryLocationExist
            ? queryLocation
            : (selectedLocation ?? currentCustomer.locations[0]?.key[0].text);
        const newSelectedTreatment = treatments[newSelectedLocation]?.[queryTreatment];
        const isOnlineService = queryIsOnlineService === 'true';

        const getSearch = () => {
            if (newSelectedTreatment) return newSelectedTreatment.name[0].text;

            if (querySpecialty && specialties[decodeURI(querySpecialty)]) {
                return specialties[decodeURI(querySpecialty)].name[0].text;
            }

            if (querySearch) return decodeURI(querySearch);

            return '';
        };

        useSearchStore.setState({
            search: getSearch(),
            selectedSpecialty: querySpecialty,
            selectedLocation: newSelectedLocation,
            selectedProfessional: querySelectedProfessional,
            selectedTreatments: newSelectedTreatment
                ? {
                      [queryTreatment]: true,
                  }
                : {},
            showAvailableProfessionals: !!newSelectedTreatment,
            isOnlineAppointment: !!newSelectedTreatment && isOnlineService,
            queryValuesSet: true,
        });

        return () => {
            useSearchStore.setState({ queryValuesSet: false });
        };
    }, []);

    const updateQueryParams = () => {
        history.replace({ search: query.toString() });
    };

    const updateSearch = (value) => {
        useSearchStore.setState({
            search: value,
            selectedSpecialty: null,
            showAvailableProfessionals: false,
            selectedProfessional: null,
        });
        query.set('search', encodeURI(value));
        query.delete('specialty');
        updateQueryParams();
    };

    const updateHealthInsurance = (value) => {
        useSearchStore.setState({
            selectedHealthInsurance: value,
            selectedTreatments: {},
            showAvailableProfessionals: false,
        });
        query.set('insurance_type', encodeURI(value));
        updateQueryParams();
    };

    const updateSelectedLocation = (event) => {
        useSearchStore.setState({
            selectedLocation: event.target.value,
            selectedTreatments: {},
            showAvailableProfessionals: false,
            selectedSpecialty: null,
            search: '',
        });
        query.delete('specialty');
        query.delete('search');
        updateQueryParams();
    };

    const searchInputComponent = useMemo(
        () => (
            <div className={classes.elementContainer} style={{ flex: 2 }}>
                <p className={classes.label}>{prismicData.search_input_title[0].text}</p>
                <div className={classes.searchWrapper}>
                    <input
                        value={search}
                        onChange={(e) => updateSearch(e.target.value)}
                        className={clsx(classes.formControl, classes.searchInput)}
                        placeholder={
                            currentCustomer?.is_accordion_view_active
                                ? prismicData.accordion_search_input_placeholder[0].text
                                : prismicData.search_input_placeholder[0].text
                        }
                        type="text"
                    />
                    <svg
                        className={classes.clearIcon}
                        style={{
                            opacity: search ? 1 : 0,
                            pointerEvents: search ? 'auto' : 'none',
                        }}
                        onClick={() => {
                            history.replace({ search: '' });
                            useSearchStore.setState({
                                showAvailableProfessionals: false,
                                selectedProfessional: null,
                                search: '',
                            });
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M16.6875 15.3125C17.0938 15.6875 17.0938 16.3438 16.6875 16.7188C16.5 16.9062 16.25 17 16 17C15.7188 17 15.4688 16.9062 15.2812 16.7188L12 13.4375L8.6875 16.7188C8.5 16.9062 8.25 17 8 17C7.71875 17 7.46875 16.9062 7.28125 16.7188C6.875 16.3438 6.875 15.6875 7.28125 15.3125L10.5625 12L7.28125 8.71875C6.875 8.34375 6.875 7.6875 7.28125 7.3125C7.65625 6.90625 8.3125 6.90625 8.6875 7.3125L12 10.5938L15.2812 7.3125C15.6562 6.90625 16.3125 6.90625 16.6875 7.3125C17.0938 7.6875 17.0938 8.34375 16.6875 8.71875L13.4062 12.0312L16.6875 15.3125Z"
                            fill="#2E2E2E"
                        />
                    </svg>
                </div>
            </div>
        ),
        [classes, prismicData, search, currentCustomer, history]
    );

    const insuranceDropdownComponent = useMemo(
        () => (
            <div className={classes.elementContainer}>
                <p className={classes.label}>{prismicData.search_insurance_title[0].text}</p>
                <div style={{ position: 'relative', width: '100%' }}>
                    <select
                        value={selectedHealthInsurance}
                        onChange={(e) => updateHealthInsurance(e.target.value)}
                        className={classes.formControl}
                        style={{ width: '100%' }}
                    >
                        {insuranceOptions.map((healthInsurance) => (
                            <option key={healthInsurance} value={healthInsurance}>
                                {t(healthInsurance)}
                            </option>
                        ))}
                    </select>
                    <img className={classes.downArrow} src={downArrow ?? ''} alt="arrow down" />
                </div>
            </div>
        ),
        [classes, prismicData, selectedHealthInsurance, insuranceOptions, downArrow]
    );

    const locationDropdownComponent = useMemo(
        () =>
            currentCustomer.locations.length > 1 ? (
                <div className={classes.elementContainer}>
                    <p className={classes.label}>{prismicData.search_location_title[0].text}</p>
                    <div style={{ position: 'relative', width: '100%', opacity: selectedProfessional ? 0.5 : 1 }}>
                        <select
                            value={selectedLocation}
                            onChange={updateSelectedLocation}
                            className={classes.formControl}
                            disabled={!!selectedProfessional}
                            style={{
                                width: '100%',
                                cursor: selectedProfessional ? 'not-allowed' : 'unset',
                            }}
                        >
                            {currentCustomer.locations.map((location) => (
                                <option key={location.key[0].text} value={location.key[0].text}>
                                    {location.title[0].text}
                                </option>
                            ))}
                        </select>
                        <img className={classes.downArrow} src={downArrow ?? ''} alt="arrow down" />
                    </div>
                </div>
            ) : null,
        [classes, currentCustomer, prismicData, selectedProfessional, selectedLocation, downArrow]
    );

    return (
        <div className={classes.container}>
            <div className={classes.dropdownContainer}>
                {insuranceDropdownComponent}
                {locationDropdownComponent}
            </div>
            {searchInputComponent}
        </div>
    );
};

export default SearchBar;
