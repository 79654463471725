import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PastAppointmentAccrodian from './PastAppointmentAccrodian';
import { ContextProvider } from '@/context/ProjectContext';
import { orderBy } from 'lodash';
import { useConfigStore } from '@/store';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    cardContentClassDetail: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
        marginBottom: 20,
    },

    mainHeading: {
        ...theme.typography.HL1,
    },
    subHeading: {
        ...theme.typography.HL2,
        marginBottom: 15,
    },
    appointmentContainer: {
        marginBottom: 30,
        maxWidth: theme.layout.contentWidth,
    },
    highlightedBorder: {
        border: `2px solid ${theme.palette.common.secondary}`,
    },
}));

const PastAppointments = ({
    dataSet,
    mainData,
    dataSetDoctos,
    inactiveResponseData,
    addAppointmentToUpcoming,
    showAllAppointments,
}) => {
    const classes = useStyles();
    const { calenderCancelledData } = useContext(ContextProvider);
    const [responseData, setResponseData] = useState(null);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    useEffect(() => {
        if (!inactiveResponseData) return;

        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;

        const sortedArray = orderBy(inactiveResponseData, [(d) => new Date(d.start)], ['desc']);
        setResponseData(
            showAllAppointments ? sortedArray : sortedArray.filter((entry) => entry.customer_id === customer_id)
        );
    }, [inactiveResponseData, showAllAppointments]);

    useEffect(() => {
        if (calenderCancelledData && inactiveResponseData) {
            let isPresent = false;
            inactiveResponseData?.forEach((a) => {
                if (a.id === calenderCancelledData.id) {
                    isPresent = true;
                }
            });
            if (!isPresent) {
                addAppointmentToUpcoming(calenderCancelledData);
            }
        }
    }, [calenderCancelledData, inactiveResponseData]);

    return (
        <>
            <div>
                <Typography className={classes.subHeading} style={{}}>
                    {dataSet && mainData?.inactive_title?.[0]?.text
                        ? mainData.inactive_title[0].text
                        : 'Past Appointments'}
                </Typography>
                {responseData !== null &&
                    responseData !== undefined &&
                    responseData.map((appointment) => (
                        <div
                            key={appointment.id}
                            className={clsx(
                                classes.appointmentContainer,
                                currentCustomer.customer_id[0].text !== appointment.customer_id &&
                                    classes.highlightedBorder
                            )}
                        >
                            <PastAppointmentAccrodian
                                dataSetDoctos={dataSetDoctos}
                                appointment={appointment}
                                dataSet={dataSet}
                                mainData={mainData}
                            />
                        </div>
                    ))}
            </div>
        </>
    );
};

export default PastAppointments;
