import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import './Acount.css';
import PersonalData from './Account/PersonalData';
import Security from './Account/Security';
import HealthInsurance from './Account/HealthInsurance';
import EternoAccount from './Account/EternoAccount';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    //
    contentContainer: {
        maxWidth: theme.layout.contentWidth,

        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    //
    heading: {
        ...theme.typography.HL1,
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        marginTop: 23,
    },
}));

export default function Account() {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { content, dataSet },
    } = usePersistedPrismicStore((state) => state.accountData);
    return (
        <Fragment>
            <div className={classes.contentContainer}>
                {/* Eterno Account */}
                <Typography className={classes.heading}>{dataSet && content.eterno_account[0].text}</Typography>
                <div className={classes.cardContent}>
                    <PersonalData mainData={content} dataSet={dataSet} />
                </div>

                {/* Security Starts */}
                <div className={classes.cardContent}>
                    <Security mainData={content} dataSet={dataSet} />
                </div>
                {/* Security Ends */}

                {/* start */}
                <div className={classes.cardContent}>
                    <HealthInsurance mainData={content} dataSet={dataSet} />
                </div>
                {/* end */}

                <div className={classes.cardContent}>
                    <EternoAccount mainData={content} dataSet={dataSet} />
                </div>
            </div>
        </Fragment>
    );
}
