import { useContext, useMemo } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './Login/LoginClasses';
import FilledButton from '@/components/FilledButton';

// import from login
import { useHistory, useLocation } from 'react-router-dom';
import ResetPage from './ResetPaswd/ResetPage';
import ResetVarification from './ResetPaswd/ResetVarification';
import ResetNewPswd from './ResetPaswd/ResetNewPswd';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
    const classes = useStyles();
    const history = useHistory();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { content, dataSet },
    } = usePersistedPrismicStore((state) => state.loginData);

    const {
        state: { loginAnamnesis },
    } = useContext(HealthProfileContextMain);

    let query = useQuery();

    console.debug('mainDataReset:', content, dataSet);
    const goDashBoard = () => {
        history.push('/');
    };

    console.debug('loginAnamnesis1', loginAnamnesis);

    const renderStep = () => {
        let step = query.get('step');
        if (step === undefined || step === null) {
            step = 'enter-email';
        }
        switch (step.toLocaleLowerCase()) {
            case 'enter-email':
                return <ResetPage dataSet={dataSet} mainData={content} />;
            case 'verify-code':
                return <ResetVarification dataSet={dataSet} mainData={content} />;
            case 'set-new-password':
                return <ResetNewPswd dataSet={dataSet} mainData={content} />;
            case 'reset-completed':
                return (
                    <div className="">
                        <Typography className={classes.heading}>
                            {dataSet && content.heading_4_password_is_reset[0].text}
                        </Typography>
                        {loginAnamnesis ? (
                            history.replace('/anamnesis-health-profile')
                        ) : (
                            <div className="" style={{ marginTop: 20 }}>
                                <FilledButton
                                    text={dataSet && content.password_is_reset_cta[0].text}
                                    onPress={goDashBoard}
                                    fullWidth={true}
                                />
                            </div>
                        )}
                    </div>
                );
            default:
                return <ResetPage dataSet={dataSet} mainData={content} />;
        }
    };

    return (
        <div className={classes.contentContainer}>
            <Grid container spacing={4} justifyContent="center">
                <Grid item md={8} sm={10} xs={12}>
                    <div className={classes.contentContainer}>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <div className={classes.registrationForm}>
                                    <div className={classes.registrationFormContent}>{renderStep()}</div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default ResetPassword;
