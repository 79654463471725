import { useMemo, useState } from 'react';
import useHubName from './useHubName';
import { useTranslation } from 'react-i18next';
import { usePersistedPrismicStore } from '@/store';

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

const useHubValues = () => {
    const { i18n } = useTranslation();
    const { eternolocationDoctorsData, welcomePagePrismicText } = useHubName();
    const {
        [i18n.language]: { mainDataProfessionalDoctors },
    } = usePersistedPrismicStore((state) => state.anamnesisData);

    console.debug('contentProfessional1', mainDataProfessionalDoctors);
    const [data, setData] = useState([]);

    let lan = languageMap[i18n.language];
    let mainData = welcomePagePrismicText[lan];

    useMemo(() => {
        eternolocationDoctorsData.forEach(({ title, key }) => {
            setData((prev) => [
                ...prev,
                {
                    value: title,
                    label: key,
                },
            ]);
        });
    }, [eternolocationDoctorsData]);
    // specific doctors

    return { mainData, data };
};

export default useHubValues;
