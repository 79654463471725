import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import pencilIcon from '@/assets/images/pencilIcon.png';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import summaryFamily from '@/assets/images/summaryFamily.svg';
import { useStyles } from './SummaryStyles';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import Icon from '@/components/Icon/Icon';

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

export default function SummaryFamilyHistory({ mainData, allData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [fatherCondition, setFatherCondition] = useState([]);
    const [motherCondition, setMotherCondition] = useState([]);
    const [sibilingsCondition, setSibilingsCondition] = useState([]);
    const [oherCondition, setOherCondition] = useState([]);
    const {
        state: { families, review_option },
        redirectFunctionSummary,
        setFamilies,
    } = useContext(HealthProfileContextMain);
    let lan = languageMap[i18n.language];

    const { allDataApi } = useContext(ContextProviderTwo);
    const familyHistoryIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'family-history');

    useLayoutEffect(() => {
        if (allDataApi?.meta?.has_anamnesis && review_option) {
            // your body
            if (
                allDataApi?.category_data[familyHistoryIndex] &&
                allDataApi?.category_data[familyHistoryIndex]['category'] === 'family-history'
            ) {
                let category2 = allDataApi?.category_data[familyHistoryIndex].data;
                let arr = [];
                let arr2 = [];
                let allDieseasLocal = [];
                if (category2?.history.length > 0) {
                    category2?.history.forEach((m) => {
                        arr.push({
                            relativeDisease: m.name,
                            whichRelative: m.family_member,
                            relativeAge: m.since_age,
                            whichOther: m.condition,
                        });
                    });
                    arr.forEach((m) => {
                        let a = arr.filter((b) => b.whichRelative === m.whichRelative);
                        allDieseasLocal[m.whichRelative] = a;
                    });
                    arr2.push(allDieseasLocal);

                    console.debug('welcome123', arr, arr2);
                }

                setFamilies({
                    relativeDisease: category2?.history.length === 0 ? 'NONE OF THE ABOVE' : '',
                    whichRelative: '',
                    relativeAge: '',
                    allDisease: arr2[0],
                    dataFamily: arr,
                });
            }
        }
    }, [allDataApi]);

    useEffect(() => {
        if (
            families['allDisease'] !== undefined &&
            families['allDisease'] !== null &&
            families.allDisease['FATHER'] &&
            families.allDisease['FATHER'] !== undefined &&
            families.allDisease['FATHER'] !== null
        ) {
            let fatherDiseasee = [];
            {
                families.allDisease['FATHER'].forEach((medi) => {
                    console.debug('Questions3: medi', medi.relativeDisease);
                    let fatherDisease = {};
                    Object.keys(allData).forEach((val) => {
                        if (val === 'en-us') {
                            allData[val].diseases_responses.find((valu) => {
                                if (medi.relativeDisease === valu.desease_response_api_value) {
                                    fatherDisease['en-us'] = valu.disease_response_value[0].text;
                                }
                            });
                        }
                        if (val === 'de-de') {
                            allData[val].diseases_responses.forEach((valu) => {
                                if (medi.relativeDisease === valu.desease_response_api_value) {
                                    fatherDisease['de-de'] = valu.disease_response_value[0].text;
                                }
                            });
                        }
                    });
                    console.debug('Questions3: value', fatherDisease[lan]);

                    fatherDiseasee.push({
                        a: fatherDisease[lan],
                        b: medi.relativeAge,
                        c: medi.whichOther,
                    });
                    console.debug('Questions3: fatherDiseasee', fatherDiseasee);
                    setFatherCondition(fatherDiseasee);
                });
            }
        }

        if (
            families['allDisease'] !== undefined &&
            families['allDisease'] !== null &&
            families.allDisease['MOTHER'] &&
            families.allDisease['MOTHER'] !== undefined &&
            families.allDisease['MOTHER'] !== null
        ) {
            let motherDiseasee = [];
            families.allDisease['MOTHER'].forEach((medi) => {
                console.debug('Questions3: medi', medi.relativeDisease);
                let motherDisease = {};
                Object.keys(allData).forEach((val) => {
                    if (val === 'en-us') {
                        allData[val].diseases_responses.find((valu) => {
                            if (medi.relativeDisease === valu.desease_response_api_value) {
                                motherDisease['en-us'] = valu.disease_response_value[0].text;
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val].diseases_responses.forEach((valu) => {
                            if (medi.relativeDisease === valu.desease_response_api_value) {
                                motherDisease['de-de'] = valu.disease_response_value[0].text;
                            }
                        });
                    }
                });
                console.debug('Questions3: value', motherDisease[lan]);

                motherDiseasee.push({
                    a: motherDisease[lan],
                    b: medi.relativeAge,
                    c: medi.whichOther,
                });
                console.debug('Questions3: motherDiseasee', motherDiseasee);
                setMotherCondition(motherDiseasee);
            });
        }

        // sibilings

        if (
            families['allDisease'] !== undefined &&
            families['allDisease'] !== null &&
            families.allDisease['SIBLINGS'] &&
            families.allDisease['SIBLINGS'] !== undefined &&
            families.allDisease['SIBLINGS'] !== null
        ) {
            let sibilingsDisease = [];
            families.allDisease['SIBLINGS'].forEach((medi) => {
                let sibilingsD = {};
                Object.keys(allData).forEach((val) => {
                    if (val === 'en-us') {
                        allData[val].diseases_responses.find((valu) => {
                            if (medi.relativeDisease === valu.desease_response_api_value) {
                                sibilingsD['en-us'] = valu.disease_response_value[0].text;
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val].diseases_responses.forEach((valu) => {
                            if (medi.relativeDisease === valu.desease_response_api_value) {
                                sibilingsD['de-de'] = valu.disease_response_value[0].text;
                            }
                        });
                    }
                });
                console.debug('Questions3: value', sibilingsD[lan]);

                sibilingsDisease.push({
                    a: sibilingsD[lan],
                    b: medi.relativeAge,
                    c: medi.whichOther,
                });
                console.debug('Questions3: sibilingsDisease', sibilingsDisease);
                setSibilingsCondition(sibilingsDisease);
            });
        }

        // other
        if (
            families['allDisease'] !== undefined &&
            families['allDisease'] !== null &&
            families.allDisease['OTHER'] &&
            families.allDisease['OTHER'] !== undefined &&
            families.allDisease['OTHER'] !== null
        ) {
            let otherDiseasee = [];
            families.allDisease['OTHER'].forEach((medi) => {
                console.debug('Questions3: medi', medi.relativeDisease);
                let otherDisease = {};
                Object.keys(allData).forEach((val) => {
                    if (val === 'en-us') {
                        allData[val].diseases_responses.find((valu) => {
                            if (medi.relativeDisease === valu.desease_response_api_value) {
                                otherDisease['en-us'] = valu.disease_response_value[0].text;
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val].diseases_responses.forEach((valu) => {
                            if (medi.relativeDisease === valu.desease_response_api_value) {
                                otherDisease['de-de'] = valu.disease_response_value[0].text;
                            }
                        });
                    }
                });
                console.debug('Questions3: value', otherDisease[lan]);

                otherDiseasee.push({
                    a: otherDisease[lan],
                    b: medi.relativeAge,
                    c: medi.whichOther,
                });
                console.debug('Questions3: otherDiseasee', otherDiseasee);
                setOherCondition(otherDiseasee);
            });
        }
    }, [families]);
    return (
        <>
            <div className={classes.contentContainerBody}>
                {families['dataFamily'].length > 0 ? (
                    <Grid container direction={'row'} style={{ height: '50%' }}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 15 }}>
                            {!matches && (
                                <Icon src={summaryFamily} description="family history icon" width={48} height={48} />
                            )}
                            <Typography className={classes.shortHeading} style={{ marginTop: !matches && 10 }}>
                                {mainData.family_disease_history_title[0].text}
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <div className={classes.flexClass}>
                                <img
                                    src={pencilIcon}
                                    alt=""
                                    className={classes.pointer}
                                    onClick={() => {
                                        redirectFunctionSummary(history, 6, 'set_families', families, true);
                                    }}
                                />
                            </div>
                        </Grid>
                        {/* change */}
                        {families['allDisease'] !== undefined &&
                            families['allDisease'] !== null &&
                            families.allDisease['FATHER'] &&
                            families.allDisease['FATHER'] !== undefined &&
                            families.allDisease['FATHER'] !== null &&
                            families.allDisease['FATHER'].length > 0 && (
                                <Grid
                                    container
                                    direction={'row'}
                                    style={{
                                        height: '50%',
                                        marginBottom: matches ? 10 : 15,
                                    }}
                                >
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.subtitle_father[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        {/* ----------------------------------------- */}
                                        {fatherCondition.map((maa) => (
                                            <div className="">
                                                <Typography className={classes.subHeading}>
                                                    {`${maa.a}${
                                                        maa.c !== undefined && maa.c !== null && maa.c.length > 0
                                                            ? ': '
                                                            : ''
                                                    }${maa.c}${
                                                        maa.b !== '' && maa.b !== undefined && maa.b !== null
                                                            ? `. ${maa.b} ${mainData.years_old[0].text}`
                                                            : ''
                                                    }`}
                                                </Typography>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}

                        {families['allDisease'] !== undefined &&
                            families['allDisease'] !== null &&
                            families.allDisease['MOTHER'] &&
                            families.allDisease['MOTHER'] !== undefined &&
                            families.allDisease['MOTHER'] !== null &&
                            families.allDisease['MOTHER'].length > 0 && (
                                <Grid
                                    container
                                    direction={'row'}
                                    style={{
                                        height: '50%',
                                        marginBottom: matches ? 10 : 15,
                                    }}
                                >
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.subtitle_mother[0].text}
                                        </Typography>
                                    </Grid>
                                    {/* food */}
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        {motherCondition.map((maa) => (
                                            <div className="">
                                                <Typography className={classes.subHeading}>
                                                    {`${maa.a}${
                                                        maa.c !== undefined && maa.c !== null && maa.c.length > 0
                                                            ? ': '
                                                            : ''
                                                    }${maa.c}${
                                                        maa.b !== '' && maa.b !== undefined && maa.b !== null
                                                            ? `. ${maa.b} ${mainData.years_old[0].text}`
                                                            : ''
                                                    }`}
                                                </Typography>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}

                        {families['allDisease'] !== undefined &&
                            families['allDisease'] !== null &&
                            families.allDisease['SIBLINGS'] &&
                            families.allDisease['SIBLINGS'] !== undefined &&
                            families.allDisease['SIBLINGS'] !== null &&
                            families.allDisease['SIBLINGS'].length > 0 && (
                                <Grid
                                    container
                                    direction={'row'}
                                    style={{
                                        height: '50%',
                                        marginBottom: matches ? 10 : 15,
                                    }}
                                >
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.subtitle_siblings[0].text}
                                        </Typography>
                                    </Grid>
                                    {/* other */}
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        {sibilingsCondition.map((maa) => (
                                            <div className="">
                                                <Typography className={classes.subHeading}>
                                                    {`${maa.a}${
                                                        maa.c !== undefined && maa.c !== null && maa.c.length > 0
                                                            ? ': '
                                                            : ''
                                                    }${maa.c}${
                                                        maa.b !== '' && maa.b !== undefined && maa.b !== null
                                                            ? `. ${maa.b} ${mainData.years_old[0].text}`
                                                            : ''
                                                    }`}
                                                </Typography>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                        {families['allDisease'] !== undefined &&
                            families['allDisease'] !== null &&
                            families.allDisease['OTHER'] &&
                            families.allDisease['OTHER'] !== undefined &&
                            families.allDisease['OTHER'] !== null &&
                            families.allDisease['OTHER'].length > 0 && (
                                <Grid
                                    container
                                    direction={'row'}
                                    style={{
                                        height: '50%',
                                        marginBottom: matches ? 10 : 15,
                                    }}
                                >
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.subtitle_other_relatives[0].text}
                                        </Typography>
                                    </Grid>
                                    {/* other */}
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        {oherCondition.map((maa) => (
                                            <div className="">
                                                <Typography className={classes.subHeading}>
                                                    {`${maa.a}${
                                                        maa.c !== undefined && maa.c !== null && maa.c.length > 0
                                                            ? ': '
                                                            : ''
                                                    }${maa.c}${
                                                        maa.b !== '' && maa.b !== undefined && maa.b !== null
                                                            ? `. ${maa.b} ${mainData.years_old[0].text}`
                                                            : ''
                                                    }`}
                                                </Typography>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                ) : (
                    <Grid
                        container
                        direction={'row'}
                        style={{
                            height: '50%',
                            marginBottom: matches ? 10 : 15,
                        }}
                    >
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            {!matches && (
                                <Icon src={summaryFamily} description="family history icon" width={48} height={48} />
                            )}
                            <Typography className={classes.shortHeading} style={{ marginTop: !matches && 10 }}>
                                {mainData.family_disease_history_title[0].text}
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <div className={classes.flexClass}>
                                <img
                                    src={pencilIcon}
                                    alt=""
                                    className={classes.pointer}
                                    onClick={() => {
                                        redirectFunctionSummary(history, 6, 'set_families', families, true);
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading}>{mainData.nothing_added[0].text}</Typography>
                        </Grid>
                    </Grid>
                )}
            </div>
        </>
    );
}
