import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';
import { SignatureType, useConsentFormsContext } from '@/context/ConsentFormsContext';
import { getSingleFormTemplate } from '@/service/ConsentForms/consentFormService';
import { getText, useQuery } from '@/utils/helpers';
import { getByTag } from '@/utils/fetchers';
import { usePersistedPrismicStore } from '@/store';

/**
 * @param {{ type: "query" | "form" }}
 * @returns { professional, professionalAddress, contractContent, loading }
 */
const useFormTemplate = ({ type = 'query' }) => {
    const query = useQuery();
    const { i18n } = useTranslation();

    const {
        [i18n.language]: { mainDataProfessionalDoctors: allDoctors },
    } = usePersistedPrismicStore((state) => state.healthData);

    const { data, isLoading } = useSWRImmutable(
        [
            'loctaion',
            {
                lang: i18n.language === 'en' ? 'en-us' : 'de-de',
            },
        ],
        getByTag
    );

    const [hasAnamnesis, setHasAnamnesis] = useState(false);
    const [{ professional, professionalAddress, contractContent, loading }, setState] = useState({
        professional: null,
        professionalAddress: null,
        contractContent: null,
        loading: true,
    });

    const {
        professional: contextProfessional,
        setProfessional: setContextProfessional,
        setProfessionalAddress: setContextProfessionalAddress,
        setContractContent: setContextContractContent,
        setSignState,
        consentAllForms: { forms },
        setConsentAllForms,
        formTemplateId,
        setFormTemplateId,
    } = useConsentFormsContext();

    // continue from getTemplates API | Returns : Array | Object | Null
    const formTemplateParams = useCallback(() => {
        if (type === 'form' && forms?.length > 0) {
            const professionalId = contextProfessional?.professional_id ?? contextProfessional?.id ?? '';
            setHasAnamnesis(forms?.some((form) => form.id === 'ANAMNESIS'));
            const filteredForms = forms?.filter(({ id }) => id !== 'ANAMNESIS');
            const templateIds = filteredForms?.map((form) => ({
                professionalId,
                templateId: typeof form === 'string' ? form : form.id,
            }));
            return templateIds;
        }

        const professionalId = query.get('professional_id');
        const templateId = query.get('template_id');

        if (!professionalId && !templateId) {
            return null;
        }

        return { professionalId, templateId };
    }, [contextProfessional?.id, contextProfessional?.professional_id, forms, query, type]);

    const createLocation = useCallback(
        (locationHubId = '') => {
            if (isLoading) return;
            if (typeof data === 'object' && 'results' in data) {
                const locations = data.results;
                const filteredLocation = locations.find((l) => l.id === locationHubId);
                if (filteredLocation) return getText(filteredLocation.data.address);
            }
        },
        [isLoading, data]
    );

    const createProfessionalData = useCallback((professional, professionalId) => {
        if (!professional) return;
        const { key = '', display_name = '' } = professional;
        return {
            id: professionalId,
            key: getText(key),
            name: getText(display_name),
        };
    }, []);

    const resetLoading = () => setState((prev) => ({ ...prev, loading: false }));

    const saveFormTemplateData = useCallback(
        (apiRes, professionalId, hasMultipleForms = false) => {
            if (!allDoctors) return;

            const {
                professional: { key },
                configuration: { signature_type },
                content,
            } = apiRes;
            const filteredProfessional = allDoctors?.find((doctor) => getText(doctor.key) === key);

            if (filteredProfessional && !isLoading) {
                const { id = '' } = filteredProfessional.location_hub;
                const professionalObj = createProfessionalData(filteredProfessional, professionalId);
                const location = createLocation(id);

                if (hasMultipleForms) {
                    setContextProfessional(professionalObj);
                    setContextProfessionalAddress(location);
                } else {
                    // Context States
                    setContextProfessional(professionalObj);
                    setContextProfessionalAddress(location);
                    setContextContractContent(content);
                    setContextContractContent(content);
                    setSignState(SignatureType[signature_type]);

                    // Local State
                    setState((prev) => ({
                        ...prev,
                        professional: professionalObj,
                        professionalAddress: location,
                        contractContent: content,
                    }));
                }
            }
        },
        [allDoctors, createLocation, createProfessionalData, forms, isLoading]
    );

    const saveFormTemplate = useCallback(
        async ({ professionalId, templateId, hasMultipleForms = false }) => {
            try {
                const res = await getSingleFormTemplate(professionalId, templateId);
                if (res && !hasMultipleForms) saveFormTemplateData(res, professionalId, hasMultipleForms);
                return res;
            } catch (error) {
                resetLoading();
                console.error(error);
            }
        },
        [saveFormTemplateData]
    );

    const fetchFormTemplate = useCallback(async () => {
        const formTemplate = formTemplateParams();

        if (!formTemplate || forms.every((form) => form.name)) {
            resetLoading();
            return;
        }

        try {
            if (Array.isArray(formTemplate) && formTemplate.length === 0 && hasAnamnesis) {
                // Only Anamnesis
                setConsentAllForms((prev) => ({
                    ...prev,
                    forms: hasAnamnesis ? [{ id: 'ANAMNESIS', key: 'ANAMNESIS', name: 'Anamnesis' }] : [],
                }));
                return;
            }

            if (Array.isArray(formTemplate) && formTemplate.length > 0) {
                const resMap = formTemplate.map(async ({ professionalId, templateId }) => {
                    const res = await saveFormTemplate({ professionalId, templateId, hasMultipleForms: true });
                    if (!res) return null;
                    return { professionalId, res };
                });

                const responses = await Promise.all(resMap);
                const filteredResponses = responses.filter(Boolean);

                const formTemplates = filteredResponses.map(({ res, professionalId }) => {
                    const {
                        configuration: { signature_type },
                        content,
                    } = res;
                    saveFormTemplateData(res, professionalId, true);
                    return { content, signatureType: SignatureType[signature_type] };
                });

                const newForms = filteredResponses.map(({ res }) => {
                    const { id, template_key, content } = res;
                    return { id, key: template_key, name: content.name };
                });

                if (filteredResponses.length === formTemplates.length) {
                    setConsentAllForms((prev) => ({
                        ...prev,
                        formTemplates,
                        forms: [
                            ...(hasAnamnesis ? [{ id: 'ANAMNESIS', key: 'ANAMNESIS', name: 'Anamnesis' }] : []),
                            ...newForms,
                        ],
                    }));
                }
            } else {
                const { professionalId, templateId } = formTemplate;
                await saveFormTemplate({ professionalId, templateId });
                if (!formTemplateId) setFormTemplateId(templateId);
            }
        } catch (error) {
            console.debug(error);
        }

        resetLoading();
    }, [formTemplateParams, saveFormTemplate]);

    useEffect(() => {
        fetchFormTemplate();
    }, [fetchFormTemplate]);

    return { professional, professionalAddress, contractContent, loading: loading || isLoading };
};

export default useFormTemplate;
