import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { uniqWith } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import red_cross from '@/assets/Icons/not-uploaded-icon.svg';
import blackArrowUp from '@/assets/images/blackArrowUp.png';
import greenTick from '@/assets/images/greenTick.png';
import FilledButton from '@/components/FilledButton';
import Loader from '@/components/Loader';
import { ConsentAllFormsInitialRoutes, useConsentFormsContext } from '@/context/ConsentFormsContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import useFormSteps from '@/hooks/useFormSteps';
import useFormTemplate from '@/hooks/useFormTemplate';
import useProfessionalMappings from '@/hooks/useProfessionalMappings';
import useRequiredTemplatesAndAnamnesis from '@/hooks/useRequiredTemplatesAndAnamnesis';
import { professionalsId } from '@/service/AnamnesisSectionAPI/ProfessionalAPI';
import { useCustomAnamnesisStore } from '@/store';
import UnderlinedButton from '../../components/UnderlinedButton';
import { usePersistedPrismicStore, useUploadDocumentModalStore } from '../../store';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,

        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
    },

    subHeading: {
        ...theme.typography.HL2,
    },
    allDetails: {
        fontFamily: 'MessinaSans-Regular',
    },
    greenSection: {
        display: 'flex',
    },
    greenTick: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
    mainBtn: {
        display: 'flex',
        justifyContent: 'left',
        marginTop: 20,
    },
    mainButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    hideDetails: {
        marginTop: 20,
        textAlign: 'center',
        fontFamily: 'MessinaSans-Regular',
        display: 'flex',
        justifyContent: 'center',
    },
    shortText: {
        cursor: 'pointer',
    },
    mainGrid: {
        flexWrap: 'inherit',
    },
    gridItemUploaded: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    values: {
        paddingTop: 2,
    },
    icon: {
        objectFit: 'contain',
        marginRight: 5,
    },
    checkInForms: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15,
    },
}));

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

const DetailShowMore = ({ setShowAll, setHideAll, dataSet, mainData, upComming }) => {
    console.debug('🚀 ~ file: DetailShowMore.jsx:97 ~ mainData:', mainData);
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const { setAllDoctorsDataApi } = useContext(ContextProviderTwo);
    const {
        consentAllForms: { isConsentAllForms, formTemplates },
        setConsentAllForms,
        setProfessional,
        setSignState,
    } = useConsentFormsContext();
    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);
    const openModal = useUploadDocumentModalStore((state) => state.openModal);
    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');

    const { isLoading, requiredFormsCount, templates, customAnamnesisTemplates, sharedDocuments, sharedFormTemplates } =
        useRequiredTemplatesAndAnamnesis({
            appointmentTypeKeys: upComming.data.services.map(({ key }) => key).join(),
            insuranceType:
                upComming?.data?.insurance_coverage_type === 'selfpayer'
                    ? 'SELF_PAYER'
                    : (upComming?.data?.insurance_coverage_type?.toUpperCase() ?? ''),
            professionalId: upComming?.professional?.id ?? '',
        });

    const getFormName = (form) => {
        const formTemplate = sharedFormTemplates[form.context.template_id];

        if (formTemplate.is_custom) return formTemplate[`form_name_${i18n.language}`];
        else return formTemplate.content?.name ?? t('UnknownForm');
    };

    const sharedForms = useMemo(() => {
        const sharedForms = uniqWith(sharedDocuments, (a, b) => a?.context?.template_id === b?.context?.template_id);

        return sharedForms?.map((form) => ({
            name: getFormName(form),
        }));
    }, [sharedDocuments, upComming]);

    useEffect(() => {
        professionalsId()
            .then((response) => {
                let responseData = response?.data.data;
                setAllDoctorsDataApi(responseData);
                console.debug('allDoctorsAPI resp: ', response);
            })
            .catch((error) => {
                console.debug('allDoctorsAPI error: ', error);
                setAllDoctorsDataApi([]);
            });
    }, []);

    const getPrescriptionStatusLabel = () => {
        const isWithPrescription = upComming?.data.is_with_prescription;
        const hasDocuments = upComming?.data.documents.length > 0;
        const isDataSet = dataSet && mainData && mainData?.booking_summary;

        if (isWithPrescription && hasDocuments) {
            return isDataSet ? mainData.booking_summary.prescription_status[0].text : 'Uploaded';
        } else {
            return isDataSet
                ? isWithPrescription
                    ? mainData.booking_summary.not_uploaded[0].text
                    : 'Uploaded'
                : 'Not Uploaded';
        }
    };

    const IconText = ({ isSuccess = false, text = '' }) => (
        <div className={classes.gridItemUploaded}>
            <img className={classes.icon} src={isSuccess ? greenTick : red_cross} alt={`${text} status`} />
            <Typography className={classes.values}>{text}</Typography>
        </div>
    );

    const { currentStepIndex, step } = useFormSteps();
    const { loading: formTemplateLoading } = useFormTemplate({ type: isConsentAllForms ? 'form' : 'query' });
    const signatureTypeForNextForm = formTemplates[currentStepIndex]?.signatureType;

    // Get the correct professional
    useProfessionalMappings();

    const onConfirmCheckIn = () => {
        setSignState(signatureTypeForNextForm);

        setProfessional({
            ...upComming?.professional,
            id: upComming?.professional?.id,
            name: upComming?.doctor[languageMap[i18n.language]]?.display_name[0].text,
        });
        setConsentAllForms((prev) => ({
            ...prev,
            isConsentAllForms: true,
            forms: templates,
            initialRoute: ConsentAllFormsInitialRoutes.MY_APPOINTMENT,
        }));

        if (customAnamnesisTemplates.length > 0) {
            useCustomAnamnesisStore.setState({
                redirectToConsentForms: templates.length > 0,
                formsToFill: customAnamnesisTemplates,
                currentForm: 1,
                loading: true,
            });
            history.push(
                `/custom-anamnesis?professional_id=${upComming?.professional?.id}&template_id=${customAnamnesisTemplates[0].id}`
            );
        } else history.push(step);
    };

    if (isLoading || formTemplateLoading) return <Loader height="100px" />;

    return (
        <div>
            <Typography className={classes.mainHeading} style={{ marginBottom: 20 }}>
                {dataSet ? mainData.documents_heading[0].text : 'Documents'}
            </Typography>
            {upComming?.data?.is_with_prescription ? (
                <Grid container direction="row" className={classes.mainGrid} style={{ marginBottom: 20 }}>
                    <Grid item md={4} xs={12}>
                        <Typography className={classes.allDetails}>
                            {dataSet ? mainData.prescription_label[0].text : 'Prescription'}
                        </Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Grid item container>
                            {/* text */}
                            <Grid item md={6} xs={12} style={{ marginTop: 5 }}>
                                <div className={classes.gridItemUploaded}>
                                    {upComming?.data.is_with_prescription && upComming?.data.documents.length > 0 ? (
                                        <img
                                            src={greenTick}
                                            style={{
                                                objectFit: 'contain',
                                                marginRight: 5,
                                            }}
                                            alt="Green Tick"
                                        />
                                    ) : (
                                        upComming?.data.is_with_prescription &&
                                        !upComming?.data.documents.length > 0 && (
                                            <img
                                                src={red_cross}
                                                style={{
                                                    objectFit: 'contain',
                                                    marginRight: 5,
                                                }}
                                                alt="Red Cross"
                                            />
                                        )
                                    )}
                                    <Typography className={classes.values}>{getPrescriptionStatusLabel()}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
            <Grid container direction="row" className={!matches ? classes.mainGrid : ''} style={{ marginBottom: 20 }}>
                <Grid item md={4} sm={12} xs={12}>
                    <div className={classes.greenSection}>
                        <Typography className={classes.allDetails}>
                            {dataSet ? mainData.checkin_forms_label[0].text : 'Check-in Forms'}
                        </Typography>
                    </div>
                </Grid>

                <Grid item md={8} sm={12} xs={12} className={classes.checkInForms}>
                    {sharedForms?.map(({ name }) => (
                        <IconText key={name} isSuccess text={name} />
                    ))}
                    {templates.length + customAnamnesisTemplates.length > 0 ? (
                        <Typography className={classes.allDetails}>
                            {dataSet
                                ? mainData.anamnesis_fill[0].text
                                : 'Fill out and read the documents before your appointment'}
                        </Typography>
                    ) : null}
                    {templates.length + customAnamnesisTemplates.length > 0 ? (
                        <div>
                            <FilledButton
                                text={`${
                                    requiredFormsCount + customAnamnesisTemplates.length !== 1
                                        ? `(${requiredFormsCount + customAnamnesisTemplates.length}) `
                                        : ''
                                } ${t('FormsFlowCta')}`}
                                onPress={onConfirmCheckIn}
                            />
                        </div>
                    ) : null}
                    {isDocumentExchangeFeatureFlagActivated && (
                        <UnderlinedButton
                            text={content?.upload_document_cta[0]?.text}
                            noMarginTop={true}
                            onPress={() => {
                                openModal(upComming?.id);
                            }}
                            noJSLeft={false}
                        />
                    )}
                </Grid>
            </Grid>
            <div
                className={classes.hideDetails}
                onClick={() => {
                    setShowAll(false);
                    setHideAll(true);
                }}
            >
                <Typography className={classes.shortText}>
                    {dataSet ? mainData.hide_details_cta[0].text : 'Hide details'}
                </Typography>
                <img src={blackArrowUp} alt="Arrow Up" />
            </div>
        </div>
    );
};

export default DetailShowMore;
