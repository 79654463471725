import { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import pencilIcon from '@/assets/images/pencilIcon.png';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import summaryLifeStyle from '@/assets/images/summaryLifestyle.svg';
import { useStyles } from './SummaryStyles';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import Icon from '@/components/Icon/Icon';

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

export default function SummaryLifeStyle({ mainData, allData, mainDataAllSection }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [sport, setSport] = useState('');
    const [smoke, setSmoke] = useState('');
    const [alchol, setAlchol] = useState('');
    const [drugs, setDrugs] = useState('');
    const [dietSummary, setDietSummary] = useState([]);
    const [everyDayLife, setEveryDayLife] = useState('');
    const [alcholKindLocal, setAlcholKindLocal] = useState('');
    const {
        state: { life_style, review_option, whichOneDrugs },
        redirectFunctionSummary,
        setLifeStyle,
    } = useContext(HealthProfileContextMain);

    let lan = languageMap[i18n.language];
    const { allDataApi, setOldData } = useContext(ContextProviderTwo);

    useMemo(() => {
        life_style['whichOneDrugsContext'] = whichOneDrugs;
    }, []);
    const lifestyleIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'lifestyle');

    useLayoutEffect(() => {
        if (allDataApi?.meta?.has_anamnesis && review_option) {
            if (
                allDataApi?.category_data[lifestyleIndex] &&
                allDataApi?.category_data[lifestyleIndex]['category'] === 'lifestyle'
            ) {
                let category2 = allDataApi?.category_data[lifestyleIndex].data;
                let arrangedData = [];
                if (category2?.habits.drugs?.active && category2?.habits.drugs?.data?.length > 0) {
                    for (let i = 0; i < category2?.habits.drugs.data.length; i++) {
                        arrangedData.push({
                            whichOne: category2?.habits.drugs?.data[i]?.type ?? '',
                            howOftenDrugs: category2?.habits.drugs?.data[i]?.frequency ?? '',
                        });
                    }
                }

                setLifeStyle({
                    multipleDiet:
                        category2?.diet.length > 0
                            ? category2?.diet
                            : [mainDataAllSection?.diet_responses[8]?.diet_response_api_value],
                    sports: category2.sports.active ? 'YES' : 'NO',
                    weekDays: category2?.sports.excercise_per_week,
                    exercise: category2?.sports.daily_exercise_type,
                    smoke: category2?.habits.smoke.active ? 'YES' : 'NO',
                    smokePerDay: category2?.habits.smoke.packs_per_day,
                    smokeHowLong: category2?.habits.smoke.num_years,
                    alcohol: category2?.habits.alcohol.active ? 'YES' : 'NO',
                    alcoholKind: category2?.habits.alcohol.type,
                    alcoholPerWeek: category2?.habits.alcohol.drinks_per_week,
                    drugs: category2?.habits.drugs.active ? 'YES' : 'NO',
                    whichOneDrugsContext:
                        arrangedData.length > 0 && arrangedData[0].whichOne
                            ? arrangedData
                            : [{ whichOne: '', howOftenDrugs: '' }],
                });
            }
        }
    }, [allDataApi]);

    useEffect(() => {
        if (
            life_style['multipleDiet'] !== null &&
            life_style['multipleDiet'] !== undefined &&
            life_style['multipleDiet'].length > 0
        ) {
            let diet = [];
            life_style['multipleDiet'].forEach((medi) => {
                let dietArr = {};
                Object.keys(allData).forEach((val) => {
                    if (val === 'en-us') {
                        allData[val].diet_responses.find((valu) => {
                            if (medi === valu.diet_response_api_value) {
                                if (medi === 'OTHER') {
                                    dietArr['en-us'] =
                                        `${valu.diet_reponse[0].text}. ${life_style.whichOtherDietSubQuestion}`;
                                } else {
                                    dietArr['en-us'] = valu.diet_reponse[0].text;
                                }
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val].diet_responses.forEach((valu) => {
                            if (medi === valu.diet_response_api_value) {
                                if (medi === 'OTHER') {
                                    dietArr['de-de'] =
                                        `${valu.diet_reponse[0].text}. ${life_style.whichOtherDietSubQuestion}`;
                                } else {
                                    dietArr['de-de'] = valu.diet_reponse[0].text;
                                }
                            }
                        });
                    }
                });
                console.debug('Questions322: value', dietArr[lan]);

                diet.push(dietArr[lan]);

                console.debug('Questions322: diet', diet);
                setDietSummary(diet);
            });
        }
        if (life_style['exercise'] !== undefined && life_style['exercise'] !== null && life_style['exercise'] !== '') {
            let everyDay = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val]['every_day_life_response'].find((valu) => {
                        if (life_style['exercise'] === valu['everyday_life_response_api_value']) {
                            everyDay['en-us'] = valu['everyday_life_response'][0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val]['every_day_life_response'].forEach((valu) => {
                        if (life_style['exercise'] === valu['everyday_life_response_api_value']) {
                            everyDay['de-de'] = valu['everyday_life_response'][0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', everyDay[lan]);
            let pastConditionn = everyDay[lan];
            console.debug('Questions3: pastCondition', pastConditionn);
            setEveryDayLife(pastConditionn);
        }
        // past condition
        if (life_style['sports'] !== undefined && life_style['sports'] !== null && life_style['sports'] !== '') {
            let sports_ = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].sports_yes_no_responses.find((valu) => {
                        if (life_style['sports'] === valu.sports_yes_no_response_api_value) {
                            sports_['en-us'] = valu.sports_yes_no_response[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].sports_yes_no_responses.forEach((valu) => {
                        if (life_style['sports'] === valu.sports_yes_no_response_api_value) {
                            sports_['de-de'] = valu.sports_yes_no_response[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', sports_[lan]);
            let sports__ = sports_[lan];
            setSport(sports__);
        }
        if (life_style['smoke'] !== undefined && life_style['smoke'] !== null && life_style['smoke'] !== '') {
            let smoke_ = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].smoke_yes_no_reponses.find((valu) => {
                        if (life_style['smoke'] === valu.smoke_yes_no_response_api_value) {
                            smoke_['en-us'] = valu.smoke_yes_no_response[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].smoke_yes_no_reponses.forEach((valu) => {
                        if (life_style['smoke'] === valu.smoke_yes_no_response_api_value) {
                            smoke_['de-de'] = valu.smoke_yes_no_response[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', smoke_[lan]);
            let smoke__ = smoke_[lan];
            setSmoke(smoke__);
        }
        if (life_style['alcohol'] !== undefined && life_style['alcohol'] !== null && life_style['alcohol'] !== '') {
            let alcohol_ = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].alcohol_reponses.find((valu) => {
                        if (life_style['alcohol'] === valu.alcohol_yes_no_response) {
                            alcohol_['en-us'] = valu.alcohol_yes_no_responses[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].alcohol_reponses.forEach((valu) => {
                        if (life_style['alcohol'] === valu.alcohol_yes_no_response) {
                            alcohol_['de-de'] = valu.alcohol_yes_no_responses[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', alcohol_[lan]);
            let alcohol__ = alcohol_[lan];
            setAlchol(alcohol__);
        }

        if (life_style['drugs'] !== undefined && life_style['drugs'] !== null && life_style['drugs'] !== '') {
            let drugs_ = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].drugs_yes_no_responses.find((valu) => {
                        if (life_style['drugs'] === valu.drugs_yes_no_reponses_api_value) {
                            drugs_['en-us'] = valu.drugs_yes_no_responses[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].drugs_yes_no_responses.forEach((valu) => {
                        if (life_style['drugs'] === valu.drugs_yes_no_reponses_api_value) {
                            drugs_['de-de'] = valu.drugs_yes_no_responses[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', drugs_[lan]);
            let drugs__ = drugs_[lan];
            setDrugs(drugs__);
        }

        if (
            life_style['alcoholKind'] !== undefined &&
            life_style['alcoholKind'] !== null &&
            life_style['alcoholKind'] !== ''
        ) {
            let alcholKind_ = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val]._5_alcohol_type_group_answer.find((valu) => {
                        if (life_style['alcoholKind'] === valu.alcohol_type_selector) {
                            alcholKind_['en-us'] = valu.alcohol_type_answer[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val]._5_alcohol_type_group_answer.forEach((valu) => {
                        if (life_style['alcoholKind'] === valu.alcohol_type_selector) {
                            alcholKind_['de-de'] = valu.alcohol_type_answer[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', alcholKind_[lan]);
            let gender = alcholKind_[lan];
            setAlcholKindLocal(gender);
        }
    }, [life_style]);
    console.debug('life_style1233:', life_style);
    return (
        <>
            <div className={classes.contentContainerBody}>
                {/* lifeStyle */}
                <Grid container direction={'row'} style={{ height: '50%' }}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 15 }}>
                        {!matches && (
                            <Icon src={summaryLifeStyle} description="life style icon" width={48} height={48} />
                        )}
                        <Typography className={classes.shortHeading} style={{ marginTop: !matches && 10 }}>
                            {mainData.lifestyle_title[0].text}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <div className={classes.flexClass}>
                            <img
                                src={pencilIcon}
                                alt=""
                                className={classes.pointer}
                                onClick={() => {
                                    redirectFunctionSummary(history, 7, 'set_life_style', life_style, true);
                                }}
                            />
                        </div>
                    </Grid>
                    {/* change -- life_style -- */}
                    {life_style['multipleDiet'] !== null &&
                        life_style['multipleDiet'] !== undefined &&
                        life_style['multipleDiet'].length > 0 && (
                            <Grid
                                container
                                direction={'row'}
                                style={{
                                    height: '50%',
                                    marginBottom: matches ? 10 : 5,
                                }}
                            >
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading}>
                                        {mainData.subtitle_diet[0].text}
                                    </Typography>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {dietSummary.map((lifeDiet) => (
                                        <Typography className={classes.subHeading}>
                                            {/* {life_style['multipleDiet'].includes('OTHER') &&
                                                life_style.whichOtherDietSubQuestion}{' '} */}
                                            {lifeDiet}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>
                        )}

                    {life_style['sports'] !== undefined &&
                        life_style['sports'] !== null &&
                        life_style['sports'] !== '' && (
                            <Grid
                                container
                                direction={'row'}
                                style={{
                                    height: '50%',
                                    marginBottom: matches ? 10 : 5,
                                }}
                            >
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading}>
                                        {mainData.subtitle_sports[0].text}
                                    </Typography>
                                </Grid>
                                {/* food */}
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading}>{sport}</Typography>
                                </Grid>
                            </Grid>
                        )}

                    {life_style['weekDays'] !== undefined &&
                        life_style['weekDays'] !== null &&
                        life_style['weekDays'] !== '' && (
                            <Grid
                                container
                                direction={'row'}
                                style={{
                                    height: '50%',
                                    marginBottom: matches ? 10 : 5,
                                }}
                            >
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading}>
                                        {mainData.subtitle_exercise[0].text}
                                    </Typography>
                                </Grid>
                                {/* other */}
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading}>
                                        {life_style['sports'] === 'NO' ? 0 : life_style['weekDays']}{' '}
                                        {mainData.days_per_week[0].text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                    {life_style['exercise'] !== undefined &&
                        life_style['exercise'] !== null &&
                        life_style['exercise'] !== '' && (
                            <Grid
                                container
                                direction={'row'}
                                style={{
                                    height: '50%',
                                    marginBottom: matches ? 10 : 5,
                                }}
                            >
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading}>
                                        {mainData.subtitle_everyday_routine[0].text}
                                    </Typography>
                                </Grid>
                                {/* other */}
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading}>{everyDayLife}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    {life_style['smoke'] !== undefined &&
                    life_style['smoke'] !== null &&
                    life_style['smoke'] !== '' &&
                    life_style['smoke'] === 'YES' ? (
                        <Grid
                            container
                            direction={'row'}
                            style={{
                                height: '50%',
                                marginBottom: matches ? 10 : 5,
                            }}
                        >
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData.subtitle_smoking[0].text}
                                </Typography>
                            </Grid>
                            {/* other */}
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {life_style.smokePerDay !== '' &&
                                        life_style.smokePerDay !== undefined &&
                                        life_style.smokePerDay !== null &&
                                        `${life_style.smokePerDay} ${mainData.cigarettes[0].text}`}
                                    {life_style.smokeHowLong !== '' &&
                                        life_style.smokeHowLong !== undefined &&
                                        life_style.smokeHowLong !== null &&
                                        ` ${mainData.since[0].text} ${life_style.smokeHowLong}`}
                                    {life_style.smokePerDay === '' && life_style.smokeHowLong === '' && smoke}{' '}
                                    {mainData.years_old[0].text}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            direction={'row'}
                            style={{
                                height: '50%',
                                marginBottom: matches ? 10 : 5,
                            }}
                        >
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData.subtitle_smoking[0].text}
                                </Typography>
                            </Grid>
                            {/* other */}
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>{smoke}</Typography>
                            </Grid>
                        </Grid>
                    )}
                    {life_style['alcohol'] !== undefined &&
                    life_style['alcohol'] !== null &&
                    life_style['alcohol'] !== '' &&
                    life_style['alcohol'] === 'YES' ? (
                        <Grid
                            container
                            direction={'row'}
                            style={{
                                height: '50%',
                                marginBottom: matches ? 10 : 5,
                            }}
                        >
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData.subtitle_alcohol[0].text}
                                </Typography>
                            </Grid>
                            {/* other */}
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {life_style.alcoholKind !== '' &&
                                        life_style.alcoholKind !== undefined &&
                                        life_style.alcoholKind !== null &&
                                        `${alcholKindLocal} `}
                                    {life_style.alcoholPerWeek !== '' &&
                                        life_style.alcoholPerWeek !== undefined &&
                                        life_style.alcoholPerWeek !== null &&
                                        `${life_style.alcoholPerWeek} ${mainData.times_per_week[0].text} `}
                                    {life_style.alcoholPerWeek === '' && life_style.alcoholKind === '' && alchol}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            direction={'row'}
                            style={{
                                height: '50%',
                                marginBottom: matches ? 10 : 5,
                            }}
                        >
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData.subtitle_alcohol[0].text}
                                </Typography>
                            </Grid>
                            {/* other */}
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>{alchol}</Typography>
                            </Grid>
                        </Grid>
                    )}
                    {life_style['drugs'] !== undefined &&
                        life_style['drugs'] !== null &&
                        life_style['drugs'] !== '' && (
                            <Grid
                                container
                                direction={'row'}
                                style={{
                                    height: '50%',
                                    marginBottom: matches ? 10 : 5,
                                }}
                            >
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading}>
                                        {mainData.subtitle_drugs[0].text}
                                    </Typography>
                                </Grid>
                                {/* other */}
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {life_style['drugs'] === 'YES' &&
                                    life_style['whichOneDrugsContext'][0]?.whichOne?.length > 0 ? (
                                        life_style['whichOneDrugsContext'].map((m) => (
                                            <Typography className={classes.subHeading}>
                                                {m.whichOne}
                                                {m.howOftenDrugs ? '. ' + m.howOftenDrugs : ''}{' '}
                                                {m.howOftenDrugs && mainData?.drugs_times_per_month[0].text}
                                            </Typography>
                                        ))
                                    ) : (
                                        <Typography className={classes.subHeading}>{drugs}</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                </Grid>
            </div>
        </>
    );
}
