import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import pencilIcon from '@/assets/images/pencilIcon.png';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import summaryExistingConditions from '@/assets/images/summaryExistingConditions.svg';
import { useStyles } from './SummaryStyles';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { ContextProvider } from '@/context/ProjectContext';
import { anamnesisRawDate } from '@/utils/helpers';
import Icon from '@/components/Icon/Icon';

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

export default function SummaryExistingCondition({ mainData, allData }) {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [dateSergery, setDateSergery] = useState([]);
    const [sergeryOne, setSergeryOne] = useState('');
    const [past, setPast] = useState('');
    const [pastConditionSummary, setPastConditionSummary] = useState([]);
    const [imagingSummary, setImagingSummary] = useState([]);
    console.debug('🚀 ~ file: SummaryExistingCondition.jsx:32 ~ imagingSummary', imagingSummary);
    const [imagingTestLocal, setImagingTestLocal] = useState('');
    const {
        state: { existing_condition, review_option },
        redirectFunctionSummary,
        setExistingCondition,
    } = useContext(HealthProfileContextMain);
    const { allDataApi } = useContext(ContextProviderTwo);

    let lan = languageMap[i18n.language];

    const { languageGlobal } = useContext(ContextProvider);

    const preExistingIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'history');
    useLayoutEffect(() => {
        if (allDataApi?.meta?.has_anamnesis && review_option) {
            // your body
            let category1 = allDataApi?.category_data[preExistingIndex].data;
            if (
                allDataApi?.category_data[preExistingIndex] &&
                allDataApi?.category_data[preExistingIndex]['category'] === 'history'
            ) {
                console.debug('category2::', category1);

                let category2 = allDataApi?.category_data[preExistingIndex].data;
                let arr = [];
                if (category1?.preexisting_conditions.length > 0) {
                    let designedArr = category1?.preexisting_conditions;
                    designedArr.filter((d) => {
                        let s = d.since.month !== '' ? d.since.month + '-' + d.since.year : '';
                        arr.push({
                            pastCondition: d.name,
                            specific: d.condition,
                            whenPastCondition: s,
                        });
                    });
                }

                let arr2 = [];
                if (category1?.surgeries.length > 0) {
                    let designedArr = category1?.surgeries;
                    designedArr.filter((d) => {
                        let s = d.since.month !== '' ? d.since.month + '-' + d.since.year : '';
                        arr2.push({
                            sergeriesQuestion: d.name,
                            sergerieDate: s,
                        });
                    });
                }
                let arr3 = [];
                if (category2?.imaging.length > 0) {
                    let designedArr = category2?.imaging;
                    designedArr.forEach((f) => {
                        arr3.push({
                            testPerfomed: f.name,
                            testPerfomedDate: f.since?.year ?? '',
                        });
                    });
                }

                setExistingCondition({
                    pastCondition: arr.length === 0 ? 'NONE OF THE ABOVE' : '',
                    surgeries: category1.had_surgery ? 'YES' : 'NO',
                    surgeriesArr: arr,
                    sergeryDataAll: arr2,
                    sergeryQuestion: '',
                    sergeryWhen: '',
                    whenPastCondition: '',
                    imagingTest: arr3.length === 0 && 'None',
                    imagingEasy: arr3,
                    // imagingArr: []
                });
                console.debug('array123', arr3);
            }
        }
    }, []);

    console.debug('sergeryDataAll123', allDataApi);

    useEffect(() => {
        if (
            existing_condition.sergeryDataAll !== undefined &&
            existing_condition.sergeryDataAll !== null &&
            existing_condition.sergeryDataAll.length > 0
        ) {
            existing_condition.sergeryDataAll.forEach((datee) => {
                if (
                    dateSergery.length < existing_condition['sergeryDataAll'].length &&
                    datee.sergerieDate.split('-').length === 2 &&
                    datee['sergerieDate'] !== undefined &&
                    datee['sergerieDate'] !== null &&
                    datee['sergerieDate'] !== ''
                ) {
                    let data = datee.sergerieDate.split('-');
                    let dob = `${datee.sergeriesQuestion}. ${anamnesisRawDate(data[0], languageGlobal)} ${data[1]}`;
                    dateSergery.push(dob);
                    console.debug('existing_condition12:', data, datee.sergerieDate, 'dob', dob);
                } else if (
                    datee['sergerieDate'].length === 0 ||
                    datee['sergerieDate'] === undefined ||
                    datee['sergerieDate'] === null ||
                    datee['sergerieDate'] === ''
                ) {
                    let dob = `${datee.sergeriesQuestion}`;
                    dateSergery.push(dob);
                    console.debug('existing_condition1', dob);
                }
            });
        }
        //////   surgeriesArr
        if (existing_condition && existing_condition.surgeriesArr) {
            let sergeryDesies = [];
            existing_condition.surgeriesArr.forEach((medi) => {
                let sergeryD = {};
                Object.keys(allData).forEach((val) => {
                    console.debug('allData7: val', val);
                    if (val === 'en-us') {
                        console.debug('allData7:', allData[val]['pre-existing_conditions_answers'], medi.pastCondition);
                        allData[val]['pre-existing_conditions_answers'].find((valu) => {
                            if (medi.pastCondition === valu['pre-existing_conditions_response_api_value']) {
                                console.debug('allData7: ddd', valu['pre-existing_conditions_answer'][0].text);
                                sergeryD['en-us'] = valu['pre-existing_conditions_answer'][0].text;
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val]['pre-existing_conditions_answers'].find((valu) => {
                            if (medi.pastCondition === valu['pre-existing_conditions_response_api_value']) {
                                console.debug('allData7: ddd 44', valu['pre-existing_conditions_answer'][0].text);
                                sergeryD['de-de'] = valu['pre-existing_conditions_answer'][0].text;
                            }
                        });
                    }
                });
                console.debug('Questions5: value', sergeryD);
                let dob = '';
                if (
                    medi.whenPastCondition !== undefined &&
                    medi.whenPastCondition !== null &&
                    medi.whenPastCondition !== ''
                ) {
                    let data1 = medi.whenPastCondition
                        .replaceAll(languageGlobal === 'en' && '-', '/')
                        .replaceAll(languageGlobal === 'de' && '-', '.');
                    let data = data1.split(languageGlobal === 'en' ? '/' : '.');
                    dob = `${anamnesisRawDate(data[0], languageGlobal)} ${data[1]}`;
                }

                sergeryDesies.push({
                    a: sergeryD[lan],
                    b: medi.specific,
                    c: medi.pastCondition,
                    d: dob,
                });
                console.debug('Questions5: sergeryDesies', sergeryDesies);
                setPastConditionSummary(sergeryDesies);
            });
        }

        // past condition
        if (existing_condition?.surgeriesArr?.length === 0) {
            let sergeryD = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    sergeryD['en-us'] =
                        allData[val]['pre-existing_conditions_answers'][
                            allData[val]['pre-existing_conditions_answers'].length - 1
                        ]['pre-existing_conditions_answer'][0].text; //     }
                }
                if (val === 'de-de') {
                    console.debug(
                        'allData61: val1',
                        allData[val]['pre-existing_conditions_answers'][
                            allData[val]['pre-existing_conditions_answers'].length - 1
                        ]['pre-existing_conditions_answer'][0].text
                    );
                    sergeryD['de-de'] =
                        allData[val]['pre-existing_conditions_answers'][
                            allData[val]['pre-existing_conditions_answers'].length - 1
                        ]['pre-existing_conditions_answer'][0].text;
                }
            });

            console.debug('Questions8: value', sergeryD);
            let sergeryDesies = sergeryD[lan];
            setPast(sergeryDesies);
        }
        if (
            existing_condition.surgeries !== undefined &&
            existing_condition.surgeries !== null &&
            existing_condition.surgeries !== ''
        ) {
            let titleP = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].surgeries_yes_no_reponse.find((valu) => {
                        if (existing_condition.surgeries === valu.surgeries_yes_no_api_value) {
                            titleP['en-us'] = valu.surgeries_yes_no_response[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].surgeries_yes_no_reponse.forEach((valu) => {
                        if (existing_condition.surgeries === valu.surgeries_yes_no_api_value) {
                            titleP['de-de'] = valu.surgeries_yes_no_response[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', titleP[lan]);
            let title__ = titleP[lan];
            setSergeryOne(title__);
        }
        let fatherDiseasee = [];

        if (existing_condition?.imagingEasy?.length > 0) {
            let fatherDisease = {};
            existing_condition.imagingEasy.forEach((medi) => {
                Object.keys(allData).forEach((val) => {
                    console.debug('allData6: val', val);
                    if (val === 'en-us') {
                        allData[val]['imaging_answers_group'].find((valu) => {
                            if (medi.testPerfomed === valu['imaging_answer_selector']) {
                                fatherDisease['en-us'] = valu['imagine_answer'][0].text;
                            }
                        });
                    }
                    if (val === 'de-de') {
                        allData[val]['imaging_answers_group'].find((valu) => {
                            if (medi.testPerfomed === valu['imaging_answer_selector']) {
                                fatherDisease['de-de'] = valu['imagine_answer'][0].text;
                            }
                        });
                    }
                });
                let dob = '';
                if (
                    medi.testPerfomedDate !== undefined &&
                    medi.testPerfomedDate !== null &&
                    medi.testPerfomedDate !== ''
                ) {
                    dob = medi.testPerfomedDate;
                }

                fatherDiseasee.push({
                    a: fatherDisease[lan],
                    b: medi.testPerfomed,
                    c: dob,
                });
            });
            setImagingSummary([...fatherDiseasee]);
        }
        console.debug('imagingSummary:', imagingSummary);
        // existing_condition.imagingTest
        // past condition
        if (existing_condition?.imagingTest) {
            let sergeryD = {};
            Object.keys(allData).forEach((val) => {
                console.debug('allData6: val', val);
                if (val === 'en-us') {
                    allData[val]['3_tests_answer_group'].find((valu) => {
                        if (existing_condition.imagingTest === valu['tests_answer_selector']) {
                            sergeryD['en-us'] = valu['tests_answer'][0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val]['3_tests_answer_group'].find((valu) => {
                        if (existing_condition.imagingTest === valu['tests_answer_selector']) {
                            sergeryD['de-de'] = valu['tests_answer'][0].text;
                        }
                    });
                }
            });
            console.debug('Questions8: value', sergeryD);
            let sergeryDesies = sergeryD[lan];
            setImagingTestLocal(sergeryDesies);
        }
    }, [existing_condition]);

    return (
        <>
            <div className={classes.contentContainerBody}>
                {/* pre exiating condition  past */}
                <Grid container direction={'row'} style={{ height: '50%' }}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 15 }}>
                        {!matches && (
                            <Icon
                                src={summaryExistingConditions}
                                description="existing conditions icon"
                                width={48}
                                height={48}
                            />
                        )}
                        <Typography className={classes.shortHeading} style={{ marginTop: !matches && 10 }}>
                            {mainData['pre-existing_conditions_title'][0].text}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <div className={classes.flexClass}>
                            <img
                                src={pencilIcon}
                                alt=""
                                className={classes.pointer}
                                onClick={() => {
                                    redirectFunctionSummary(
                                        history,
                                        3,
                                        'set_existing_condition',
                                        existing_condition,
                                        true,
                                        true,
                                        true
                                    );
                                }}
                            />
                        </div>
                    </Grid>
                    {/* change */}
                    {existing_condition?.surgeriesArr?.length > 0 ? (
                        <Grid container direction={'row'} style={{ height: '50%', marginBottom: 15 }}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData['subtitle_pre-existing_conditions'][0].text}
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                {pastConditionSummary.map((m) => (
                                    <Typography className={classes.subHeading}>
                                        {m.a}
                                        {m.b && ': ' + m.b}
                                        {m.d && '. ' + m.d}
                                    </Typography>
                                ))}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction={'row'} style={{ height: '50%', marginBottom: 15 }}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData['subtitle_pre-existing_conditions'][0].text}
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>{past}</Typography>
                            </Grid>
                        </Grid>
                    )}

                    {existing_condition !== undefined &&
                    existing_condition !== null &&
                    existing_condition.surgeries !== '' &&
                    existing_condition.surgeries !== undefined &&
                    existing_condition.surgeries !== null &&
                    existing_condition.surgeries === 'YES' ? (
                        <Grid container direction={'row'} style={{ height: '50%', marginBottom: 15 }}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData.subtitle_surgeries[0].text}
                                </Typography>
                            </Grid>
                            {/* food  sergeryDataAll */}
                            {existing_condition?.sergeryDataAll?.length > 0 && (
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {dateSergery.map((d) => (
                                        <Typography className={classes.subHeading}>{d}</Typography>
                                    ))}
                                </Grid>
                            )}
                            {/* for sergery question sergeryQuestion */}
                            {existing_condition.sergeryDataAll.length === 0 &&
                                existing_condition.sergeryQuestion !== '' &&
                                existing_condition.sergeryQuestion !== undefined &&
                                existing_condition.sergeryQuestion !== null && (
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {existing_condition.sergeryQuestion}
                                        </Typography>
                                    </Grid>
                                )}
                        </Grid>
                    ) : (
                        // no
                        <Grid container direction={'row'} style={{ height: '50%', marginBottom: 15 }}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData.subtitle_surgeries[0].text}
                                </Typography>
                            </Grid>
                            {/* food  sergeryDataAll */}

                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>{sergeryOne}</Typography>
                            </Grid>
                        </Grid>
                    )}
                    {/* imaging */}
                    {existing_condition?.imagingEasy?.length > 0 ? (
                        <Grid container direction={'row'} style={{ height: '50%', marginBottom: 15 }}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData['3_tests_summary_title'][0].text}
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                {imagingSummary.map((m) => (
                                    <Typography className={classes.subHeading}>
                                        {m.a}. {m.c}
                                    </Typography>
                                ))}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction={'row'} style={{ height: '50%', marginBottom: 15 }}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData['3_tests_summary_title'][0].text}
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>{imagingTestLocal}</Typography>
                            </Grid>
                        </Grid>
                    )}
                    {/* imaging end */}
                </Grid>
            </div>
        </>
    );
}
