import { memo } from 'react';
import { Slider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToolTipBox from '@/components/Common/ToolTipBox';
import info from '@/assets/Icons/info.svg';

const useStyles = makeStyles((theme) => ({
    slider: {
        '& .MuiSlider-root': { pointerEvents: 'none' },
        '& .MuiSlider-track': {
            background: '#4C726D',
            height: '5px',
            borderRadius: '3px',
        },
        '& .MuiSlider-rail': {
            background: '#D5D5D5',
            height: '5px',
            borderRadius: '3px',
        },
        '& .MuiSlider-mark': {
            background: 'FFFFFF',
            opacity: '70%',
            height: '5px',
        },
        '& .MuiSlider-thumb': {
            display: 'none',
            pointerEvents: 'none',
        },
    },
    sliderText: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    textHeading: {
        width: 'fit-content',
        fontSize: '16px',
        fontFamily: 'MessinaSans-Regular',
    },
    textBmi: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        fontFamily: 'MessinaSans-SemiBold',
        gap: 5,
    },
    subHeading: { fontSize: '14px', fontFamily: 'MessinaSans-SemiBold', marginTop: 20 },
    infoIconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
        paddingInline: '5%',
    },
    textCol: {
        textAlign: 'center',
        color: theme.palette.common.brown,
    },
    textColActive: { color: theme.palette.common.darkGrey, fontFamily: 'MessinaSans-SemiBold' },
    colText: {
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 500,
        fontSize: '1rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
    sliderContainer: {
        position: 'relative',
    },
    marksContainer: {
        width: '100%',
        height: '5px',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        top: '50%',
        transform: 'translateY(-60%)',
        overflow: 'hidden',
        zIndex: '3',
    },
    mark: {
        backgroundColor: '#CCC',
        backgroundBlendMode: 'difference',
        height: '100%',
        width: '1.5px',
    },
}));

const SystolicList = ['< 120', 130, 140, 150, 160, 170, '> 180'];
const DiastolicList = ['< 80', 85, 90, 95, 100, 105, '> 110'];

function setBloodPressureRange(sBP, dBP) {
    if (sBP >= 120 && sBP <= 129 && dBP >= 80 && dBP <= 84) {
        return 'blood_pressure_range_normal';
    } else if (sBP >= 130 && sBP <= 139 && dBP >= 85 && dBP <= 89) {
        return 'blood_pressure_range_high_normal';
    } else if (sBP >= 140 && sBP <= 159 && dBP >= 90 && dBP <= 99) {
        return 'blood_pressure_range_grade_1_hypertension';
    } else if (sBP >= 160 && sBP <= 179 && dBP >= 100 && dBP <= 109) {
        return 'blood_pressure_range_grade_2_hypertension';
    } else if (sBP >= 180 && dBP >= 110) {
        return 'blood_pressure_range_grade_3_hypertension';
    } else if (sBP >= 140 && dBP <= 90) {
        return 'blood_pressure_range_isolated_systoic_hypertension';
    } else {
        return 'blood_pressure_range_optimal';
    }
}

const YourBodyBloodPressureMeter = ({ mainData, sBP = 10, dBP = 20 }) => {
    const classes = useStyles();
    return (
        <div className={classes.sliderContainer} style={{ marginTop: 40 }}>
            <div className={classes.sliderText}>
                <Typography className={classes.textHeading}>{mainData?.your_blood_pressure[0].text}</Typography>{' '}
                <Typography className={classes.textBmi}>
                    {mainData?.[setBloodPressureRange(sBP, dBP)][0].text}
                    <ToolTipBox
                        text={mainData?.tooltiptext.map((paragraph) => {
                            return <p>{paragraph.text}</p>;
                        })}
                    >
                        <div className={classes.infoIconContainer}>
                            <img src={info} />
                        </div>
                    </ToolTipBox>
                </Typography>
            </div>
            <div>
                <Typography className={classes.subHeading}>{mainData?.systolic_answer_saved[0].text}</Typography>
                <div className={classes.sliderContainer}>
                    <div className={classes.marksContainer}>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                    </div>
                    <Slider
                        className={classes.slider}
                        value={sBP < 116 ? 116 : sBP}
                        disabled
                        valueLabelDisplay="auto"
                        onChange={() => {}}
                        min={115}
                        max={185}
                    />
                </div>
                <div className={classes.textContainer}>
                    {SystolicList.map((item, index) => (
                        <div className={classes.textCol} key={index}>
                            <Typography className={classes.colText}>{item}</Typography>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <Typography className={classes.subHeading}>{mainData?.diastolic_answer_saved[0].text}</Typography>
                <div className={classes.sliderContainer}>
                    <div className={classes.marksContainer}>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                        <div className={classes.mark}></div>
                    </div>
                    <Slider
                        className={classes.slider}
                        value={dBP < 78 ? 78 + 2.5 : Number(dBP) + 2.5}
                        disabled
                        valueLabelDisplay="auto"
                        onChange={() => {}}
                        min={77.5 + 2.5}
                        max={112.5 + 2.5}
                    />
                </div>
                <div className={classes.textContainer}>
                    {DiastolicList.map((item, index) => (
                        <div className={classes.textCol} key={index}>
                            <Typography className={classes.colText}>{item}</Typography>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default memo(YourBodyBloodPressureMeter);
