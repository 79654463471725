import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import ImageSectionDoctorDetails from './ImageSectionDoctorDetails';
import SecondSideBar from './SecondSideBar';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        backgroundColor: theme.palette.common.white,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            //   paddingLeft: 20,
            //   paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 20,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
    },
    goBackIcon: {
        // width: 9,
        height: 14,
        marginRight: 10,
    },
    upClass: {
        marginBottom: 20,
    },
}));

const SideBarDoctorDetails = ({ doctors_data, myAppointmentsDataStatus, dataSet, mainData }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    // spacing={ matchesMD ? 2 : matchesSM ? 2 : matches ? 2 : matchesXl ? 6 : matchesLg ? 6 :  2}
    return (
        <>
            <div>
                <Grid container direction={matchesMD && 'row'} spacing={1}>
                    <Grid item lg={12} md={12} sm={6} xs={12}>
                        <ImageSectionDoctorDetails
                            doctors_data={doctors_data}
                            myAppointmentsDataStatus={myAppointmentsDataStatus}
                            mainData={mainData}
                            dataSet={dataSet}
                        />
                    </Grid>

                    <Grid item lg={12} md={12} sm={6} xs={12}>
                        <SecondSideBar
                            doctors_data={doctors_data}
                            myAppointmentsDataStatus={myAppointmentsDataStatus}
                            mainData={mainData}
                            dataSet={dataSet}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default SideBarDoctorDetails;
