import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

/** MUI */
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

/** Styles */
import consentFormsStyles from '../../ConsentFormsStyles';

/** Tabs Icons */
import tabOneIconActive from '@/assets/images/tabTwoIconInactive.svg';
import tabOneIconInactive from '@/assets/images/tabOneIconInactive.svg';
import tabTwoIconActive from '@/assets/images/tabTwoIconActive.svg';
import tabTwoIconInactive from '@/assets/images/tabOneIconActive.svg';

/** Signature Canvas */
import SignaturePad from './SignaturePad';
import clsx from 'clsx';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const SignatureTabs = ({ signCanvas }) => {
    const classes = consentFormsStyles();

    const [value, setValue] = useState(0);

    /** Context */
    const {
        consentFormsContent: content,
        formData: { firstName, lastName },
    } = useContext(ConsentFormsContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.tabsRoot}>
            <AppBar position="static" color="default" className={classes.tabsHeader}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="#F5F5F5"
                    textColor="primary"
                    variant="standard"
                    aria-label="full width tabs example"
                    className={classes.tabs}
                >
                    <Tab
                        label={content ? content?.signaturePopup.generatedSignature : 'Generated signature'}
                        {...a11yProps(0)}
                        className={classes.tab}
                        disableRipple
                        icon={<img src={!value ? tabOneIconActive : tabOneIconInactive} className={classes.tabIcon} />}
                    />
                    <Tab
                        label={content ? content?.signaturePopup.ownSign : 'Own signature'}
                        {...a11yProps(1)}
                        className={classes.tab}
                        disableRipple
                        icon={<img src={value ? tabTwoIconActive : tabTwoIconInactive} className={classes.tabIcon} />}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.tabPanel}>
                <Typography className={clsx(classes.digitalSignature, classes.textUnSelect, classes.translateCenter)}>
                    {`${firstName || ''} ${lastName || ''}`}
                </Typography>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
                {/* Signature Pad */}
                <SignaturePad ref={signCanvas} />
            </TabPanel>
        </div>
    );
};

export default SignatureTabs;
