import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ConfirmAppointment/ConfirmClasses';
import ImageGridNew from './ConfirmAppointment/ImageGridNew';
import { Context as BookAppointmentContext } from '@/context/BookAppointmentContext';
import { ContextProvider } from '@/context/ProjectContext';
import LocationPreviewCard from '@/components/LocationPreviewCard';
import { dayjs } from '@/utils/dayjsSetup';
import { useAppointmentStore, usePersistedPrismicStore, useSearchStore } from '@/store';
import { trackStormlyEvent } from '@/utils/helpers';
import useScrollToTop from '@/hooks/useScrollToTop';
import BookingConfirmed from '@/components/BookingConfirmed';

const AppointmentConfirm = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const [bookedLocation, setBookedLocation] = useState();
    const { [i18n.language]: prismicData } = usePersistedPrismicStore((state) => state.confirmAppointmentData);

    const {
        state: { confirmedDoctor },
    } = useContext(BookAppointmentContext);

    const { appointmentSearchConfirmation, appointmentSelectionConfirmation } = useContext(ContextProvider);

    useScrollToTop();

    const entry = useMemo(
        () => prismicData?.find((item) => item.type === 'page_appointment_booking_confirmation'),
        [prismicData]
    );
    const mainData = entry?.data;
    const mobileNumber = t('MobileNumber');
    const mainBenefitsReg = [{ mobileNumber }];
    const dataSet = !!entry;

    console.debug('prismicDataConfirmAppointment', mainData);
    console.debug('mainBenefitsReg', mainBenefitsReg);

    useEffect(() => {
        if (!confirmedDoctor) return;
        const mapping = confirmedDoctor.secondary_location_mappings.find(
            (mapping) => mapping.professional_type.key[0].text === appointmentSearchConfirmation.professional_type_key
        );
        if (mapping)
            setBookedLocation(
                mapping.secondary_locations.find(
                    (location) => location.key[0].text === appointmentSearchConfirmation.sub_loc_key
                )
            );
    }, [confirmedDoctor]);

    useEffect(() => {
        if (!confirmedDoctor || !appointmentSelectionConfirmation || !appointmentSearchConfirmation) return;

        if (useAppointmentStore.getState().bookFromDoctor) {
            trackStormlyEvent('bookingFlowDoctorCompleted', {
                completed: true,
                selectedDoctor: confirmedDoctor.display_name[0].text,
                timeslot: dayjs(appointmentSelectionConfirmation.selected_appointment).format('DD.MM.YYYY HH:mm'),
            });
        } else {
            trackStormlyEvent('bookingFlowFindAppointmentCompleted', {
                completed: true,
                selectedProfession: appointmentSearchConfirmation.professional_type_key,
                selectedDoctor: confirmedDoctor.display_name[0].text,
                timeslot: dayjs(appointmentSelectionConfirmation.selected_appointment).format('DD.MM.YYYY HH:mm'),
            });
        }
    }, [confirmedDoctor, appointmentSelectionConfirmation, appointmentSearchConfirmation]);

    useEffect(() => {
        useSearchStore.setState({ showAvailableProfessionals: false });
    }, []);

    if (confirmedDoctor === null) {
        return <div></div>;
    }

    return (
        <>
            <div className={classes.contentContainer}>
                <Typography className={classes.heading}>
                    {dataSet ? mainData.appointment_confirmed[0].text : t('AppointmentConfirmed')}
                </Typography>
                <Grid container direction={matchesXs ? 'column' : 'row'} style={{ marginTop: 20 }} spacing={2}>
                    <Grid item md={4} sm={5} xs={12}>
                        <ImageGridNew confirmedDoctor={confirmedDoctor} mainData={mainData} dataSet={dataSet} />
                        {appointmentSearchConfirmation.location_key !== 'online' && (
                            <LocationPreviewCard
                                professionalLocation={bookedLocation || confirmedDoctor.locationData}
                                appointmentSearch={appointmentSearchConfirmation}
                            />
                        )}
                    </Grid>
                    <Grid item md={8} sm={7} xs={12}>
                        <BookingConfirmed mainData={mainData} dataSet={dataSet} mainBenefitsReg={mainBenefitsReg} />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default AppointmentConfirm;
