import { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import UnderlinedButton from '@/components/UnderlinedButton';
import { Context as BookAppointmentContext } from '@/context/BookAppointmentContext';
import { Context as AuthContext } from '@/context/AuthContext';
import { ContextProvider } from '@/context/ProjectContext';
import { DropDown } from '@/components/Common/DropDown/DropDown';
import useI18nCountries from '@/hooks/useI18nCountries';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        position: 'relative',
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
    },
    heading: {
        ...theme.typography.HL1,
        fontWeight: 'bold',
    },
    subheading: {
        ...theme.typography.HL4,
        color: '#000',
        fontSize: 14,
    },
    categoryHeading: {
        ...theme.typography.HL5,
        fontFamily: 'MessinaSans-Regular',
    },
    contentText: {
        ...theme.typography.content,
        fontFamily: 'MessinaSans-Regular',
    },
    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            height: 370,
        },
    },
    cardContentBottomClasses: {
        position: 'relative',
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingBottom: 10,
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    doctorImg: {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    findClassbutton: {
        height: '3.75em',
        width: '100%',
        backgroundColor: 'transparent',
        border: `solid 2px ${theme.palette.common.brown}`,
        marginTop: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    findClassText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    priceText: {
        ...theme.typography.HL1,
    },
    formControl: {
        width: '100%',
        backgroundColor: 'white',
        height: '4.3em',

        justifyContent: 'space-between',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    downArrow: {
        height: '0.75em',
        marginRight: 15,
    },
    menuItem: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
    },
    textFields: {
        height: '3.75em',
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
            //backgroundColor: "red",
        },
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
    },
    blackCircle: {
        backgroundColor: theme.palette.common.darkGrey,
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    greenCircle: {
        backgroundColor: '#5ba668',
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    passwordText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    passwordValidText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    selectionPopup: {
        height: 240,
        width: '100%',
        background: 'white',
        top: '2.5em',
        paddingTop: 1,
        zIndex: 9999,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            height: 280,
        },
        [theme.breakpoints.down('xs')]: {
            height: 100,
        },
    },
    closeButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    selectionContainer: {
        marginTop: 28,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            height: 18,
        },
        [theme.breakpoints.down('xs')]: {
            height: 18,
        },
    },
    selectionTitle: {
        ...theme.typography.content,

        textDecoration: 'underline',
    },
    userData: {
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',

        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    progressInner: {
        width: 300,
        [theme.breakpoints.down('sm')]: {
            width: 150,
        },
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 18,
        borderRadius: '2em',
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    disabledButton: {
        backgroundColor: theme.palette.common.yellow,
    },
    buttonText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'uppercase',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    icon: {
        borderRadius: '50%',
        width: 22,
        height: 22,
        backgroundColor: theme.palette.common.lightBrown,

        'input:hover ~ &': {
            backgroundColor: theme.palette.common.lightBrown,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.common.yellow,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 22,
            height: 22,
            backgroundImage: 'radial-gradient(#000,#000 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    radioRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const ContactDetails = ({ setFormStage, selectedDoctor, mainBenefits, dataSetReg, mainData, useriner, signUp }) => {
    console.debug('🚀 ~ file: ContactDetails.jsx:365 ~ useriner', useriner);
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const countryList = useI18nCountries();

    const {
        state: { contactDetails },
        setContactDetails,
    } = useContext(BookAppointmentContext);

    const {
        state: { user },
    } = useContext(AuthContext);
    console.debug('🚀 ~ file: ContactDetails.jsx:378 ~ user', user);

    const [zip, setZip] = useState(contactDetails?.zip || '');
    const [street, setStreet] = useState(contactDetails?.street || '');
    const [city, setCity] = useState(contactDetails?.city || '');
    const [epcode, setePcode] = useState('DE');
    const [countrySelect, setCountrySelect] = useState({});

    let isNotValidated = street === '' || zip === '' || city === '';

    const goBack = () => {
        if (user) {
            setFormStage(2, selectedDoctor, true);
        } else {
            setFormStage(3, selectedDoctor, true);
        }
    };

    useEffect(() => {
        window.addEventListener('popstate', goBack);
        return () => {
            window.removeEventListener('popstate', goBack);
        };
    }, []);

    const {
        appointmentSearch: { insurance_type_key },
    } = useContext(ContextProvider);
    console.debug('🚀 ~ file: ContactDetails.jsx:413 ~ appointmentSearch', insurance_type_key);
    let lan = i18n.language;

    useEffect(() => {
        console.debug('languageGlobal', lan);
        console.debug('languageGlobal', countryList);
        const countryDetail = {
            code: 'DE',
            name: countryList['DE'],
        };
        setCountrySelect(countryDetail);
    }, [lan, countryList]);

    useEffect(() => {
        console.debug('seelcted country ', countrySelect);
        setCountrySelect({
            code: epcode,
            name: countryList[epcode.toLocaleUpperCase()],
        });
    }, [epcode, countryList]);

    const messageRef = useRef();

    useEffect(() => {
        console.debug('messageRef', messageRef);
        if (messageRef.current) {
            messageRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    });

    let nameOfCountry;
    Object.keys(countryList).forEach((key) => {
        if (key === epcode) {
            console.debug('key', countryList[key]);
            nameOfCountry = countryList[key];
        }
    });

    useEffect(() => {
        //returning back to top on mount
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Typography className={classes.categoryHeading} style={{ marginTop: 20 }}>
                {dataSetReg ? mainBenefits.contact_details_heading : 'Contact Details'}
            </Typography>

            <Typography className={classes.contentText} style={{ marginTop: 5 }}>
                {dataSetReg ? mainBenefits.contact_detail_sub_heading : 'Lorem ipsum dolor sit amet'}
            </Typography>

            <Grid container spacing={1} style={{ marginTop: 15 }}>
                <Grid container spacing={3} style={{ marginTop: 4 }}>
                    <Grid item md={12} sm={12} xs={12}>
                        <TextField
                            id="street"
                            label={dataSetReg ? mainBenefits.street_number : t('StreetNumber')}
                            variant="filled"
                            color="primary"
                            value={street}
                            onChange={(event) => {
                                const text = event.target.value;
                                setStreet(text);
                            }}
                            fullWidth
                            classes={{
                                root: classes.textFields,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                    fontFamily: 'MessinaSans-Regular',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                    fontFamily: 'MessinaSans-Regular',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginTop: 4 }}>
                    <Grid item md={3} sm={6} xs={6}>
                        <TextField
                            id="zip"
                            label={dataSetReg ? mainBenefits.zip_code : t('ZIPCode')}
                            variant="filled"
                            color="primary"
                            value={zip}
                            onChange={(event) => {
                                const text = event.target.value;
                                setZip(text);
                            }}
                            fullWidth
                            classes={{
                                root: classes.textFields,
                                fontFamily: 'MessinaSans-Regular',
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                    fontFamily: 'MessinaSans-Regular',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item md={9} sm={6} xs={6}>
                        <TextField
                            id="city"
                            label={dataSetReg ? mainBenefits.city : t('City')}
                            variant="filled"
                            color="primary"
                            value={city}
                            onChange={(event) => {
                                const text = event.target.value;
                                setCity(text);
                            }}
                            fullWidth
                            classes={{
                                root: classes.textFields,
                                fontFamily: 'MessinaSans-Regular',
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                    fontFamily: 'MessinaSans-Regular',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} style={{ marginTop: 4 }}>
                    <Grid item md={12} sm={12} xs={12}>
                        {dataSetReg && (
                            <DropDown
                                type="country"
                                mainDataHeading={mainBenefits.country}
                                setData={setePcode}
                                data={epcode.toLocaleUpperCase()}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <div
                className=""
                style={{
                    marginTop: 30,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <UnderlinedButton
                    text={dataSetReg ? mainData.go_back_button[0].text : 'Go Back'}
                    onPress={() => {
                        goBack();
                    }}
                />

                <Button
                    variant="contained"
                    type="submit"
                    disabled={isNotValidated}
                    classes={{
                        root: classes.submitButton,
                        label: classes.buttonText,
                    }}
                    style={{
                        opacity: 1,
                        backgroundColor: isNotValidated ? theme.palette.common.lightBrown : theme.palette.common.yellow,
                        marginTop: 0,
                        fontFamily: 'MessinaSans-Regular',
                        width: matches ? '40%' : '30%',
                    }}
                    onClick={() => {
                        setContactDetails({
                            street: street,
                            zip: zip,
                            city: city,
                            country: countrySelect,
                        });

                        // SignUp before verification
                        if (!user && (insurance_type_key === 'self-payer' || insurance_type_key === 'public')) {
                            signUp();
                        }

                        setFormStage(20, selectedDoctor, false, {
                            street: street,
                            zip: zip,
                            city: city,
                            country: countrySelect,
                        });
                    }}
                >
                    {dataSetReg ? mainData.continue_button[0].text : t('Continue')}
                </Button>
            </div>
        </div>
    );
};

export default ContactDetails;
