import React, { useEffect, useMemo, useState } from 'react';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fetchAvailableProfessionals, JourneyType, processAPIWithHeadersForPractitioners } from '@/utils/helpers';
import {
    useBookingStore,
    useConfigStore,
    usePersistedPrismicStore,
    useProfessionalsStore,
    useSearchStore,
} from '@/store';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { dayjs } from '@/utils/dayjsSetup';
import FilledButton from '../FilledButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        position: 'relative',
    },
    calendarContainer: {
        display: 'flex',
        gap: '0.75rem',
        fontFamily: 'MessinaSans-Regular',
        boxSizing: 'border-box',
        position: 'relative',
    },
    calendarColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '0.75rem',
        boxSizing: 'border-box',
        flex: 0.2,
        [theme.breakpoints.down('md')]: {
            flex: 0.33,
        },
    },
    columnHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '3rem',
    },
    timeslot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem',
        borderRadius: '0.25rem',
        boxShadow: '0 0 0 1px #dfdfdf',
        background: theme.palette.common.lightGray,
        cursor: 'pointer',
        width: '100%',
        boxSizing: 'border-box',
        height: 40,
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: `0 0 0 1px ${theme.palette.common.yellow}`,
        },
    },
    selectedTimeslot: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        '&:hover': {
            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        },
    },
    placeholderSlot: {
        padding: '0.5rem',
        borderRadius: '0.25rem',
        background: theme.palette.common.lightGray,
        boxSizing: 'border-box',
        height: 40,
        width: '100%',
    },
    calendarControl: {
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            cursor: 'pointer',
        },
    },
    rightCalendarControl: {
        transform: 'rotate(180deg)',
    },
    showMoreContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            cursor: 'pointer',
            margin: 0,
        },
    },
    nextSlotContainer: {
        marginTop: 32,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'absolute',
        width: '85%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        gap: '1rem',
    },
    nextSlotButton: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
    },
    invalidTimeSlot: {
        opacity: 0.3,
        pointerEvents: 'none',
    },
}));

const TimeslotCalendar = ({ scrollToContinueButton }) => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const [availableTimeSlots, setAvailableTimeSlots] = useState(null);
    const [nextSlot, setNextSlot] = useState(null);
    const [nextNonVisibleAppointment, setNextNonVisibleAppointment] = useState(null);
    const [noSlotsAvailable, setNoSlotsAvailable] = useState(false);
    const [showAllSlots, setShowAllSlots] = useState(false);
    const {
        [i18n.language]: { mainData: prismicData },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);
    const professionalMappings = useProfessionalsStore((state) => state.professionalMappings);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const selectedTreatments = useSearchStore((state) => state.selectedTreatments);
    const selectedHealthInsurance = useSearchStore((state) => state.selectedHealthInsurance);
    const selectedSpecialty = useSearchStore((state) => state.selectedSpecialty);
    const isOnlineAppointment = useBookingStore((state) => state.isOnlineAppointment);
    const isReturningPatient = useBookingStore((state) => state.isReturningPatient);
    const selectedProfessional = useBookingStore((state) => state.selectedProfessional);
    const selectedTimeslot = useBookingStore((state) => state.selectedTimeslot);
    const startDate = useBookingStore((state) => state.calendarStartDate);
    const loading = useBookingStore((state) => state.loading);
    const isAtSecondaryLocation = useBookingStore((state) => state.isAtSecondaryLocation);
    const secondaryLocation = useBookingStore((state) => state.secondaryLocation);
    const dcCalendarId = useBookingStore((state) => state.dcCalendarId);
    const isProfessionalSelectionSkipped = useBookingStore((state) => state.isProfessionalSelectionSkipped);
    const invalidTimeSlot = useBookingStore((state) => state.invalidTimeSlot);

    const updateSelectedSlot = (slot) => {
        if (invalidTimeSlot === slot) return;

        useBookingStore.setState({
            selectedTimeslot: selectedTimeslot !== slot ? slot : null,
        });

        if (selectedTimeslot !== slot) scrollToContinueButton();
    };

    const getAvailableTimeSlots = async (date) => {
        useBookingStore.setState({ loading: true });

        let retrievedDcCalendarId = null;
        if (isProfessionalSelectionSkipped && !dcCalendarId) {
            const response = await fetchAvailableProfessionals(
                selectedLocation,
                selectedSpecialty ?? selectedProfessional.specialityType.key[0].text,
                Object.keys(selectedTreatments)
                    .filter((treatmentKey) => selectedTreatments[treatmentKey])
                    .join(),
                selectedHealthInsurance.replace('_', '-'),
                JourneyType.SEARCH_BOOK
            );

            if (!response?.data) {
                useBookingStore.setState({ loading: false });
                setAvailableTimeSlots(undefined);
                setNextSlot(null);
                return;
            }

            const professional = response.data.find(
                (professional) => professional.key === selectedProfessional.key[0].text
            );

            if (!professional) {
                useBookingStore.setState({ loading: false });
                setAvailableTimeSlots(undefined);
                setNextSlot(null);
                return;
            }

            retrievedDcCalendarId = professional.dc_calendar_ids?.[0];

            useBookingStore.setState({ dcCalendarId: professional.dc_calendar_ids?.[0] });
        }

        const queryParams = new URLSearchParams({
            is_online: isOnlineAppointment,
            is_returning: isReturningPatient,
            appointment_type_keys: Object.keys(selectedTreatments)
                .filter((treatmentKey) => selectedTreatments[treatmentKey])
                .join(),
            insurance_type_key: selectedHealthInsurance,
            start_date: date,
            location_key: selectedLocation,
            journey_type: JourneyType.SEARCH_BOOK,
            sub_loc_key: isAtSecondaryLocation ? secondaryLocation.key[0].text : '',
            dc_calendar_id: dcCalendarId ?? retrievedDcCalendarId,
        }).toString();

        const response = await processAPIWithHeadersForPractitioners(
            `public/users/availability/${
                professionalMappings[selectedProfessional.key[0].text].user_id
            }?${queryParams}`,
            'GET',
            {
                headers: {
                    'Content-Type': 'application/json',
                    customer_id: useConfigStore.getState().currentCustomer?.customer_id?.[0]?.text,
                },
            }
        );

        setShowAllSlots(false);
        setAvailableTimeSlots(response.data?.availability ?? undefined);
        setNextSlot(response.data?.next_slot);
        useBookingStore.setState({
            appointmentData: response.data?.appointment,
            loading: false,
        });
    };

    useEffect(() => {
        if (availableTimeSlots) setAvailableTimeSlots(null);
        getAvailableTimeSlots(startDate);
    }, [isReturningPatient]);

    useEffect(() => {
        if (availableTimeSlots === null) return;

        if (availableTimeSlots === undefined || !availableTimeSlots?.[0]?.slots) {
            setNoSlotsAvailable(true);
            setNextNonVisibleAppointment(null);
            return;
        }

        let slotsInVisibleDays = false;
        let slotsAvailable = false;
        for (let i = 0; i < 5; i++) {
            if (availableTimeSlots[i].slots.length > 0) {
                slotsAvailable = true;
                if (i < 3) slotsInVisibleDays = true;
            }
        }

        if (!slotsAvailable) {
            setNoSlotsAvailable(true);
            setNextNonVisibleAppointment(null);
            return;
        } else setNoSlotsAvailable(false);

        if (matchesMd) {
            if (
                !slotsInVisibleDays &&
                (availableTimeSlots[3].slots.length > 0 || availableTimeSlots[4].slots.length > 0)
            ) {
                if (availableTimeSlots[3].slots.length > 0)
                    setNextNonVisibleAppointment(availableTimeSlots[3].slots[0]);
                else setNextNonVisibleAppointment(availableTimeSlots[4].slots[0]);
            } else {
                setNextNonVisibleAppointment(null);
            }
        } else {
            setNextNonVisibleAppointment(null);
        }
    }, [matchesMd, availableTimeSlots]);

    const displayShowMoreButton = useMemo(() => {
        return (availableTimeSlots ?? []).slice(0, !matchesMd ? 5 : 3).some((day) => day.slots.length > 5);
    }, [availableTimeSlots]);

    const jumpToFirstAvailableSlot = async () => {
        const newStartDate = dayjs(nextNonVisibleAppointment ?? nextSlot).format('YYYY-MM-DD');
        setAvailableTimeSlots(null);
        useBookingStore.setState({ calendarStartDate: newStartDate });
        await getAvailableTimeSlots(newStartDate);
    };

    const moveBackward = async () => {
        const newStartDate = dayjs(startDate)
            .subtract(matchesMd ? 3 : 5, 'day')
            .format('YYYY-MM-DD');
        setAvailableTimeSlots(null);
        useBookingStore.setState({ calendarStartDate: newStartDate });
        await getAvailableTimeSlots(newStartDate);
    };

    const moveForward = async () => {
        const newStartDate = dayjs(startDate)
            .add(matchesMd ? 3 : 5, 'day')
            .format('YYYY-MM-DD');
        setAvailableTimeSlots(null);
        useBookingStore.setState({ calendarStartDate: newStartDate });
        await getAvailableTimeSlots(newStartDate);
    };

    const renderPlaceholderSlots = (amount) => {
        return Array(amount)
            .fill(null)
            .map(() => <div className={classes.placeholderSlot}></div>);
    };

    return (
        <div className={classes.container}>
            {loading && (
                <div className={classes.loadingOverlay}>
                    <EternoSpinner />
                </div>
            )}
            <div className={classes.calendarContainer}>
                {(!noSlotsAvailable ||
                    nextSlot ||
                    nextNonVisibleAppointment ||
                    (noSlotsAvailable && availableTimeSlots)) && (
                    <div className={classes.calendarControl}>
                        <svg
                            onClick={moveBackward}
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="18"
                            viewBox="0 0 11 18"
                            fill="none"
                        >
                            <rect
                                x="11"
                                y="15.3643"
                                width="3"
                                height="12"
                                rx="1.5"
                                transform="rotate(135 11 15.3643)"
                                fill="#ABABAB"
                            />
                            <rect
                                x="2.51465"
                                y="11.1211"
                                width="3"
                                height="12"
                                rx="1.5"
                                transform="rotate(-135 2.51465 11.1211)"
                                fill="#ABABAB"
                            />
                        </svg>
                    </div>
                )}
                {(availableTimeSlots ?? []).slice(0, !matchesMd ? 5 : 3).map((day) => (
                    <div key={day.date} className={classes.calendarColumn}>
                        <div className={classes.columnHeader}>
                            <Typography>{dayjs(day.date).format('dddd')}</Typography>
                            <Typography style={{ fontWeight: 300 }}>
                                {dayjs(day.date).format('DD MMM').replace('.', '')}
                            </Typography>
                        </div>
                        {Array.isArray(day.slots) &&
                            day.slots.slice(0, showAllSlots ? day.slots.length : 5).map((slot) => (
                                <div
                                    key={slot}
                                    className={clsx(
                                        classes.timeslot,
                                        selectedTimeslot === slot && classes.selectedTimeslot,
                                        invalidTimeSlot === slot && classes.invalidTimeSlot
                                    )}
                                    onClick={() => updateSelectedSlot(slot)}
                                >
                                    {dayjs(slot).format('HH:mm')}
                                </div>
                            ))}
                        {(((nextSlot || nextNonVisibleAppointment) && availableTimeSlots) ||
                            (noSlotsAvailable && availableTimeSlots)) &&
                            renderPlaceholderSlots(6)}
                    </div>
                ))}
                {noSlotsAvailable &&
                    !availableTimeSlots &&
                    !loading &&
                    Array(!matchesMd ? 5 : 3)
                        .fill(null)
                        .map(() => <div className={classes.calendarColumn}>{renderPlaceholderSlots(6)}</div>)}
                {(!noSlotsAvailable ||
                    nextSlot ||
                    nextNonVisibleAppointment ||
                    (noSlotsAvailable && availableTimeSlots)) && (
                    <div className={clsx(classes.calendarControl, classes.rightCalendarControl)}>
                        <svg
                            onClick={moveForward}
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="18"
                            viewBox="0 0 11 18"
                            fill="none"
                        >
                            <rect
                                x="11"
                                y="15.3643"
                                width="3"
                                height="12"
                                rx="1.5"
                                transform="rotate(135 11 15.3643)"
                                fill="#ABABAB"
                            />
                            <rect
                                x="2.51465"
                                y="11.1211"
                                width="3"
                                height="12"
                                rx="1.5"
                                transform="rotate(-135 2.51465 11.1211)"
                                fill="#ABABAB"
                            />
                        </svg>
                    </div>
                )}
                {(((nextSlot || nextNonVisibleAppointment) && availableTimeSlots) ||
                    (noSlotsAvailable && availableTimeSlots)) && (
                    <div className={classes.nextSlotContainer}>
                        <Typography className={classes.nextSlotText}>
                            {noSlotsAvailable && !(nextSlot || nextNonVisibleAppointment)
                                ? prismicData.no_timeslots_for_this_period[0].text
                                : `${prismicData.next_slot_available_text[0].text} ${dayjs(
                                      nextNonVisibleAppointment ?? nextSlot
                                  ).format('dddd')}, ${dayjs(nextNonVisibleAppointment ?? nextSlot).format(
                                      i18n.language === 'en' ? 'MMMM Do' : 'DD. MMMM'
                                  )}`}
                        </Typography>
                        {(!noSlotsAvailable || nextSlot || nextNonVisibleAppointment) && !loading && (
                            <div className={classes.nextSlotButton}>
                                <FilledButton
                                    text={prismicData.next_slot_cta[0].text}
                                    onPress={jumpToFirstAvailableSlot}
                                />
                            </div>
                        )}
                    </div>
                )}
                {noSlotsAvailable && !nextSlot && !availableTimeSlots && !loading && (
                    <div className={classes.nextSlotContainer} style={{ marginTop: 0 }}>
                        <Typography className={classes.nextSlotText}>
                            {prismicData.no_timeslots_available_text[0].text}
                        </Typography>
                    </div>
                )}
            </div>

            {displayShowMoreButton &&
                !showAllSlots &&
                !noSlotsAvailable &&
                !(nextSlot || nextNonVisibleAppointment) && (
                    <div className={classes.showMoreContainer}>
                        <p onClick={() => setShowAllSlots(true)}>{prismicData.show_more_slots[0].text}</p>
                    </div>
                )}
        </div>
    );
};

export default TimeslotCalendar;
