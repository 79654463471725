import { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import UnderlinedButton from '@/components/UnderlinedButton';
import { useTranslation } from 'react-i18next';
import FilledButton from '@/components/FilledButton';
import OutlinedButton from '@/components/OutlinedButton';
import { Context as AuthContext } from '@/context/AuthContext';
import { Context as DocumentContext } from '@/context/DocumentContext';
import { getISODate, processAPIWithHeaders } from '@/utils/helpers';
import axios from 'axios';
import account_yellow from '@/assets/Illustrations/Yellow/Eterno-Account_Yellow.svg';
import DialogBox from '@/components/Common/DialogBox';
import './PersnalDataDisabled.css';
import ACCOUNT_personal_data from '@/assets/Icons/ACCOUNT_personal_data.svg';
import { ContextProvider } from '@/context/ProjectContext';
import { CircularProgress } from '@material-ui/core';
import { DateTextField } from '@/components/Common/Date/DateTextField';
import { DropDown } from '@/components/Common/DropDown/DropDown';
import { usePersistedPrismicStore } from '@/store';
import Icon from '@/components/Icon/Icon';
import { hexToRgb } from '@/utils/colorHelpers';
import useI18nCountries from '@/hooks/useI18nCountries';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;

const useStyles = makeStyles((theme) => {
    const [r, g, b] = hexToRgb(theme.palette.common.yellow);

    return {
        contentContainer: {
            maxWidth: theme.layout.contentWidth,

            margin: '0 auto',
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 20,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 30,
            },
        },
        heading: {
            ...theme.typography.HL1,
        },
        cardContent: {
            backgroundColor: 'white',

            maxWidth: theme.layout.contentWidth,
            boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
            borderRadius: 8,
            display: 'block',

            marginTop: 23,
        },
        avatar: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            objectFit: 'cover',
            maxHeight: 400,
            backgroundColor: 'red',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        editAvatar: {
            width: 200,
            height: 200,
            borderRadius: 100,
            objectFit: 'cover',
            marginTop: 10,
        },
        accountData: {
            paddingTop: 32,
            paddingBottom: 32,
        },
        icon: {
            width: 50,
            height: 50,
            objectFit: 'contain',
        },
        subHeading: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.75rem',
            textTransform: 'none',

            color: theme.palette.common.brown,
            marginTop: 20,
        },
        userData: {
            fontFamily: 'MessinaSerif-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        downArrow: {
            height: '0.75em',
            marginRight: 15,
        },
        selectedOptions: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        editLinks: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.darkGrey,
            marginTop: 25,
        },
        deleteLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
            marginLeft: 20,
        },
        deleteAccountLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
        },
        editButton: {
            background: 'transparent',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        buttonLabel: {
            padding: 0,
            justifyContent: 'left',
        },
        cardbg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'rgba(255,200,119,0.2)',
            maxHeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        imagebg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            maxHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        card: {
            width: '80%',
            height: '85%',
            maxWidth: 400,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            backgroundColor: theme.palette.common.cream,

            maxHeight: 400,
            [theme.breakpoints.down('sm')]: {
                width: '70%',
                height: '85%',
            },

            [theme.breakpoints.down('xs')]: {
                width: '70%',
                height: '85%',
            },
        },
        visa: {
            width: 53,
            height: 30,
            objectFit: 'contain',
            marginTop: 30,
            marginLeft: 30,
            marginBottom: 20,
        },
        textFields: {
            height: '3.75em',
            marginTop: 10,
        },

        inputLabel: {
            '&.focused': {
                color: theme.palette.common.darkGrey,
            },
            '&.shrink': {
                fontSize: '1rem',
            },
        },
        submitButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.yellow,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.yellow,
            },
        },
        cancelButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.mediumBrown,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.mediumBrown,
            },
        },
        buttonText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        formControl: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        formControlMobileBtn: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',

            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,

            borderRightWidth: 0,

            marginBottom: 15,
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        accountIconWrapper: {
            width: 250,
            height: 250,
            objectFit: 'contain',
            backgroundColor: `rgba(${r},${g},${b},0.2)`,
            padding: 10,
            borderRadius: 135,

            [theme.breakpoints.down('xs')]: {
                width: 150,
                height: 150,
            },
        },
        fieldFocused: {
            backgroundColor: 'white',
            border: `2px solid ${theme.palette.common.yellow} !important`,
        },
        filledInputRoot: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
            },
        },
        filledInputRootEmail: {
            overflow: 'hidden',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        disabledEmail: {
            '& input.Mui-disabled': {
                backgroundColor: 'white',
                border: '1px solid white',
            },
        },
        filledInputRootMobile: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
            },
        },
        btnClass: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },

        blackCircle: {
            backgroundColor: theme.palette.common.darkGrey,
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        greenCircle: {
            backgroundColor: '#5ba668',
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        passwordText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        passwordValidText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.brown,
        },

        errorText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.red,
        },
        font: {
            fontSize: '1.5rem',
            backgroundColor: 'red',
            height: '0',
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
});

const PersonalData = ({ mainData, dataSet }) => {
    const { t, i18n } = useTranslation();
    const { [i18n.language]: registerPrismicData } = usePersistedPrismicStore((state) => state.registerData);
    const { benefits: mainBenefitsReg } = registerPrismicData;

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesxs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesmd = useMediaQuery(theme.breakpoints.down('md'));
    const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesxl = useMediaQuery(theme.breakpoints.down('xl'));
    const [openChangeDialogPersonal, setOpenChangeDialogPersonal] = useState(false);

    const [profileEdit, setProfileEdit] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('asdad');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('6666');

    const [efirstName, seteFirstName] = useState('');
    const [elastName, seteLastName] = useState('');
    const [emiddleName, seteMiddleName] = useState('dss');

    const [eemail, seteEmail] = useState('');
    const [ephone, setePhone] = useState('015578962145');

    const [persnalDataExist, setPersnalDataExist] = useState(false);

    const [streetMainAdress, setSreetMainAdress] = useState('');
    console.debug('🚀 ~ file: PersonalData.jsx:502 ~ PersonalData ~ streetMainAdress', streetMainAdress);
    const [zipMainAdress, setZipMainAdress] = useState('');
    const [cityMainAdress, setCityMainAdress] = useState('');

    const [estreetMainAdress, seteSreetMainAdress] = useState('');
    const [ezipMainAdress, seteZipMainAdress] = useState('');
    const [ecityMainAdress, seteCityMainAdress] = useState('');

    const [dob, setDOB] = useState('');
    const [edob, seteDOB] = useState('');

    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');

    const [mobileCode, setMobileCode] = useState('-');
    const [emobileCode, seteMobileCode] = useState('-');

    const [anchorE7, setanchorE7] = useState(null);

    const [mobileNumber, setMobileNumber] = useState('');
    const [emobileNumber, seteMobileNumber] = useState('');

    const [fullNumber, setFullNumber] = useState('0');

    const [pcountry, setPcountry] = useState('');
    const [epcountry, setePcountry] = useState('');

    const [egender, seteGender] = useState('');

    const [etitle, seteTitle] = useState('-');

    const [haveImg, setHaveImage] = useState(false);

    const [gender, setGender] = useState('Male');
    const [title, setTitle] = useState('Dr');

    const [notificationEmail, setNotificationEmail] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [imgFileSelector, setImgFileSelector] = useState(null);

    const [imgFile, setImgFile] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);

    // Loader
    const [loader, setLoader] = useState(false);

    const [pcode, setPcode] = useState('DE');
    const [epcode, setePcode] = useState('');
    const [countrySelect, setCountrySelect] = useState({});
    const [error, setError] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);

    const { languageGlobal, setImgAccount } = useContext(ContextProvider);

    const countryList = useI18nCountries();

    const handleClosePhone = (mobileCode = '') => {
        setanchorE7(null);
        if (mobileCode !== '') {
            seteMobileCode(mobileCode);
        }
        console.debug('Phone', mobileCode);
    };

    const handleClickPhone = (event) => {
        var w =
            document.getElementById('mobileCodeButton').clientWidth +
            document.getElementById('mobileNumber').clientWidth;
        console.debug(w);
        setMobileCodeWidth(w.toString());
        setanchorE7(event.currentTarget);
    };
    //   Functions

    const {
        state: { user, usersession, userAccount },
        setuser,
        setUserAddress,
    } = useContext(AuthContext);

    // Document Context
    const {
        state: { isDocumentUploading },
        beginFileUpload,
        deleteDocument,
    } = useContext(DocumentContext);

    const userData = user;

    const buildFileSelector = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', 'image/jpeg,image/png');
        return fileSelector;
    };

    useEffect(() => {
        console.debug('seelcted country ', countrySelect);
        setCountrySelect({
            code: epcode,
            name: countryList[epcode.toLocaleUpperCase()],
        });
    }, [epcode]);

    // adress api call
    const adress = async () => {
        try {
            const body = {
                user_id: userAccount.user_id,
                street: estreetMainAdress,
                zip: ezipMainAdress,
                city: ecityMainAdress,
                country: countrySelect,
            };

            console.debug('test Body: ', body);

            const resp = await axios.post(
                `${BASE_API_URL}/secure/user/accounts/${userAccount.user_id}/addresses`,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: usersession.idToken.jwtToken,
                    },
                }
            );
            console.debug('Adress Response ', resp);
            setUserAddress({
                user_id: userAccount.user_id,
                street: estreetMainAdress,
                zip: ezipMainAdress,
                city: ecityMainAdress,
                country: countrySelect,
            });
        } catch (error) {
            console.debug('error is ', error);
        }
    };
    const [adressMain, setAdressMain] = useState('');
    useEffect(() => {
        let user_id = userAccount.user_id;
        let token = usersession.idToken.jwtToken;
        const fetchData = async () => {
            const res = await axios.get(
                `${BASE_API_URL}/secure/user/accounts/${user_id}/addresses`,

                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: token,
                    },
                }
            );
            console.debug(res);
            setAdressMain(res);
        };
        fetchData().catch((error) => {
            console.debug('error is adress ', error);
        });
    }, []);

    const [localCountry, setLocalCountry] = useState('');
    const [localCountryCode, setLocalCountryCode] = useState('');
    useEffect(() => {
        if (adressMain && adressMain['data'] !== undefined && Object.keys(adressMain.data).length > 0) {
            console.debug('🚀 ~ file: PersonalData.jsx:729 ~ useEffect ~ adressMain', adressMain);
            setCityMainAdress(adressMain.data.city);
            setZipMainAdress(adressMain.data.zip);
            setSreetMainAdress(adressMain.data.street);
            setPcountry(adressMain.data.country.name);
            setLocalCountry(adressMain.data.country.name);
            setPcode(adressMain.data.country.code);
            setLocalCountryCode(adressMain.data.country.code);
            console.debug('Adress Street ', adressMain.data.country.code);

            console.debug('Zip name', ezipMainAdress, estreetMainAdress);
        }
    }, [adressMain]);

    useEffect(() => {
        deleteDocument();
        if (userData) {
            console.debug('useerdata', userData);
            setFirstName(userData.name.first);
            setLastName(userData.name.last);
            setDOB(userData.date_of_birth);
            setMiddleName(userData.name.middle);
            if (userData['phone'] !== undefined) {
                let p = userData['phone'];
                if (p['number'] !== undefined) {
                    let n = p.number;
                    if (n.length >= 6) {
                        setMobileNumber(userData.phone.number);
                        setFullNumber(userData.phone.number);
                    } else {
                        setMobileNumber('');
                        setFullNumber('');
                    }
                } else {
                    setMobileNumber('');
                    setFullNumber('');
                }
            } else {
                setMobileNumber('');
                setFullNumber('');
            }

            setTitle(userData.title);
            setGender(userData.gender);
            setCardHolderName(userData.name.first + ' ' + userData.name.last);
            setEmail(userData.email);
            setNotificationEmail(userData.email);

            let imageFileSelector = buildFileSelector();
            imageFileSelector.addEventListener('input', (event) => {
                let file = imageFileSelector.files[0];
                console.debug(file);
                var reader = new FileReader();
                var url = reader.readAsDataURL(file);

                reader.onloadend = function (e) {
                    console.debug(reader.result);
                    setImgSrc(reader.result);
                    setImgAccount(reader.result);
                };
                console.debug(url);
                prepareToUpload(file);
                setImgFile(file);
                setHaveImage(true);
            });
            let a;
            if (userData['phone']) {
                let num = userData['phone'];
                if (num['number']) {
                    a = userData.phone.number.split(' ');
                    if (a.length >= 2) {
                        setMobileNumber(a[1]);
                        setMobileCode(a[0]);
                    } else {
                        setMobileNumber(a[0]);
                        setMobileCode('+49');
                    }
                }
            }

            setImgFileSelector(imageFileSelector);

            let dobb = userData?.date_of_birth.split('-');
            const dobbb = `${dobb[2]}${languageGlobal === 'en' ? '/' : '.'}${dobb[1]}${
                languageGlobal === 'en' ? '/' : '.'
            }${dobb[0]}`;
            setDOB(dobbb);
        }
        console.debug('languageGlobal12', languageGlobal);
    }, []);

    const prepareToUpload = (file = null) => {
        if (file && user && user['id'] !== undefined && usersession) {
            let user_id = user.id;
            let type = 'OTHER';
            let mime_type = file.type;
            let fileNameWithExtension = file.name;
            let fileNameTempArr = fileNameWithExtension.split('.');
            let data = {
                user_id,
                type,
                mime_type,
                name: fileNameTempArr[0],
            };
            console.debug('fileUpload: Preparing to process this data:', data);
            beginFileUpload(data, file, usersession);
        }
    };

    const updateProfile = async () => {
        try {
            const response = await processAPIWithHeaders('secure/users/' + userData.id, 'POST', {
                body: {
                    name: {
                        first: efirstName,
                        middle: emiddleName,
                        last: elastName,
                    },
                    gender: egender,
                    date_of_birth: getISODate(
                        edob
                            .replace(languageGlobal === 'en' && '.', '/')
                            .replace(languageGlobal === 'en' && '.', '/')
                            .replace(languageGlobal === 'de' && '/', '.')
                            .replace(languageGlobal === 'de' && '/', '.'),
                        languageGlobal
                    ),
                    title: etitle === '-' ? '' : etitle,
                    phone: {
                        number: fullNumber,
                    },
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: usersession.idToken.jwtToken,
                },
            });
            console.debug('USER ', response);

            setuser(response);

            setFirstName(efirstName);
            setLastName(elastName);
            setDOB(edob);
            setEmail(eemail);
            setPhone(ephone);
            setProfileEdit(false);
            setMiddleName(emiddleName);
            setPersnalDataExist(true);
        } catch (error) {
            console.debug(error);
        }
    };

    const messageRefTwo = useRef();

    useEffect(() => {
        console.debug('messageRefTwo', messageRefTwo);
        if (messageRefTwo.current) {
            messageRefTwo.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    });

    return (
        <Grid container direction={matches ? 'column-reverse' : 'row'}>
            <Grid item md={profileEdit ? 12 : 6} xs={12}>
                <div
                    className={classes.accountData}
                    style={{
                        paddingRight: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                        paddingLeft: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                    }}
                >
                    <Icon src={ACCOUNT_personal_data} description="personal data" />
                    {!profileEdit && (
                        <Typography className={classes.subHeading}>
                            {dataSet ? mainData.personal_data[0].text : t('PERSONALDATA')}
                        </Typography>
                    )}
                    {profileEdit && (
                        <Grid container spacing={2} style={{ marginTop: -10 }}>
                            <Grid item md={6} xs={12}>
                                <Grid item md={12} xs={12}>
                                    <Typography className={classes.subHeading}>
                                        {dataSet ? mainData.personal_data[0].text : t('PERSONALDATA')}
                                    </Typography>

                                    {dataSet && (
                                        <DropDown
                                            type="default"
                                            mainDataHeading={mainData.gender[0].text}
                                            mainDataArray={mainBenefitsReg?.gender_values}
                                            setData={seteGender}
                                            data={egender}
                                            widthID="gender"
                                        />
                                    )}
                                </Grid>
                                <TextField
                                    id="firstName"
                                    label={dataSet ? mainData.first_name[0].text : t('FirstName')}
                                    variant="filled"
                                    color="primary"
                                    value={efirstName}
                                    onChange={(event) => {
                                        const text = event.target.value;
                                        seteFirstName(text);
                                    }}
                                    fullWidth
                                    classes={{
                                        root: classes.textFields,
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRoot,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                    }}
                                />

                                <TextField
                                    id="lastName"
                                    label={dataSet ? mainData.last_name[0].text : t('LastName')}
                                    variant="filled"
                                    color="primary"
                                    value={elastName}
                                    onChange={(event) => {
                                        const text = event.target.value;
                                        seteLastName(text);
                                    }}
                                    fullWidth
                                    classes={{
                                        root: classes.textFields,
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRoot,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} style={{ marginTop: 17 }}>
                                {/* Date of birth strats */}
                                <Typography className={classes.subHeading}></Typography>
                                {mainData && (
                                    <DateTextField
                                        type={'dateOfBirth'}
                                        separator={mainData.dob_placeholder_separator[0].text}
                                        setDOB={seteDOB}
                                        dob={edob}
                                        setError={setError}
                                        error={error}
                                        errorMessage={mainData?.date_error[0].text}
                                        placeHolderMonth={mainData.dob_placeholder_month[0].text}
                                        placeHolderYear={mainData.dob_placeholder_year[0].text}
                                        placeHolderDay={mainData.dob_placeholder_day[0].text}
                                        mainHeading={mainData.dob[0].text}
                                        mt={9}
                                    />
                                )}
                                {/* Date of birth ends */}
                                <TextField
                                    id="email"
                                    label={dataSet ? mainData.email[0].text : t('Email')}
                                    variant="filled"
                                    color="primary"
                                    value={eemail}
                                    disabled
                                    fullWidth
                                    className={classes.disabledEmail}
                                    style={{
                                        disabled: {
                                            color: 'green',
                                        },
                                    }}
                                    classes={{
                                        root: classes.textFields,
                                        style: {
                                            backgroundColor: '#fff',
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRootEmail,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                    }}
                                />
                                {/* phone no starts */}
                                {dataSet && (
                                    <DropDown
                                        type="telephone"
                                        data={fullNumber}
                                        setData={setFullNumber}
                                        mainDataHeading={mainData.mobile_number[0].text}
                                        isScroll={true}
                                        error={errorPhone}
                                        setError={setErrorPhone}
                                        errorText={mainBenefitsReg?.required_field_error_message[0]?.text}
                                    />
                                )}
                                {/* phone no ends */}
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {dataSet ? mainData.personal_data[0].text : t('PERSONALDATA')}
                                </Typography>
                                {/* street vala sect starts */}
                                <TextField
                                    id="street"
                                    label={dataSet ? mainData.street_number[0].text : t('StreetNumber')}
                                    variant="filled"
                                    color="primary"
                                    value={estreetMainAdress}
                                    onChange={(event) => {
                                        const text = event.target.value;
                                        seteSreetMainAdress(text);
                                    }}
                                    fullWidth
                                    classes={{
                                        root: classes.textFields,
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRoot,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <TextField
                                        id="zip"
                                        label={dataSet ? mainData.zip_code[0].text : t('ZIPCode')}
                                        variant="filled"
                                        color="primary"
                                        value={ezipMainAdress}
                                        onChange={(event) => {
                                            const text = event.target.value;
                                            seteZipMainAdress(text);
                                        }}
                                        fullWidth
                                        classes={{
                                            root: classes.textFields,
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.inputLabel,
                                                focused: 'focused',
                                                shrink: 'shrink',
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.filledInputRoot,
                                                focused: classes.fieldFocused,
                                                shrink: 'shrink',
                                            },
                                        }}
                                        style={{ flex: 0.37 }}
                                    />
                                    {/* Checking for api */}
                                    <TextField
                                        id="city"
                                        label={dataSet ? mainData.city[0].text : t('City')}
                                        variant="filled"
                                        color="primary"
                                        value={ecityMainAdress}
                                        onChange={(event) => {
                                            const text = event.target.value;
                                            seteCityMainAdress(text);
                                        }}
                                        fullWidth
                                        classes={{
                                            root: classes.textFields,
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.inputLabel,
                                                focused: 'focused',
                                                shrink: 'shrink',
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.filledInputRoot,
                                                focused: classes.fieldFocused,
                                                shrink: 'shrink',
                                            },
                                        }}
                                        style={{ flex: 0.6 }}
                                    />
                                </div>

                                {dataSet && (
                                    <DropDown
                                        type="country"
                                        mainDataHeading={mainData.country[0].text}
                                        setData={setePcode}
                                        data={epcode.toLocaleUpperCase()}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item md={12} xs={12}>
                                        {matchesxs ? (
                                            <div className="">
                                                <div style={{ marginBottom: 10 }}>
                                                    <FilledButton
                                                        disabled={loader || error || errorPhone}
                                                        text={
                                                            dataSet ? mainData.save_changes[0].text : t('SaveChanges')
                                                        }
                                                        onPress={() => {
                                                            updateProfile();
                                                            setCityMainAdress(ecityMainAdress);
                                                            setZipMainAdress(ezipMainAdress);
                                                            setSreetMainAdress(estreetMainAdress);
                                                            setPcountry(epcountry);
                                                            setPcode(epcode);
                                                            adress();
                                                            setPersnalDataExist(true);
                                                        }}
                                                        color={'yellow'}
                                                        fullWidth
                                                    />
                                                </div>
                                                <OutlinedButton
                                                    text={dataSet ? mainData.cancel[0].text : t('Cancel')}
                                                    onPress={() => {
                                                        setOpenChangeDialogPersonal(true);
                                                    }}
                                                    color={'yellow'}
                                                    fullWidth
                                                    disableRipple={true}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <OutlinedButton
                                                    text={dataSet ? mainData.cancel[0].text : t('Cancel')}
                                                    onPress={() => {
                                                        setOpenChangeDialogPersonal(true);
                                                    }}
                                                    color={'yellow'}
                                                    disableRipple={true}
                                                />

                                                <FilledButton
                                                    disabled={loader || error || errorPhone}
                                                    loading={isDocumentUploading}
                                                    text={
                                                        dataSet && !loader ? (
                                                            mainData.save_changes[0].text
                                                        ) : !loader ? (
                                                            t('SaveChanges')
                                                        ) : (
                                                            <CircularProgress
                                                                style={{
                                                                    color: theme.palette.common.yellow,
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    onPress={() => {
                                                        setLoader(true);
                                                        updateProfile();
                                                        seteCityMainAdress(ecityMainAdress);
                                                        seteZipMainAdress(ezipMainAdress);
                                                        seteSreetMainAdress(estreetMainAdress);
                                                        setPcountry(epcountry);
                                                        setPcode(epcode);
                                                        setMobileNumber(emobileNumber);
                                                        console.debug('Full Number ', fullNumber);
                                                        adress();
                                                        setPersnalDataExist(true);
                                                    }}
                                                    color={'yellow'}
                                                />
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {userData && !profileEdit && (
                        <Typography className={classes.userData}>
                            {firstName} {middleName} {lastName}
                        </Typography>
                    )}
                    {userData && !profileEdit && <Typography className={classes.userData}>{email}</Typography>}

                    {userData && !profileEdit && <Typography className={classes.userData}>{fullNumber}</Typography>}

                    {streetMainAdress && userData && !profileEdit && (
                        <Typography className={classes.subHeading}>
                            {dataSet ? mainData.personal_address_section_title[0].text : 'PESRONAL ADDRESS'}
                        </Typography>
                    )}

                    {streetMainAdress && userData && !profileEdit && (
                        <Typography className={classes.userData}>{streetMainAdress}</Typography>
                    )}
                    {streetMainAdress && userData && !profileEdit && (
                        <Typography className={classes.userData}>
                            {zipMainAdress} {cityMainAdress}, {countryList[pcode.toLocaleUpperCase()]}{' '}
                        </Typography>
                    )}
                    {/* pcountry ko cmnt kya hai */}
                    {!profileEdit && (
                        <UnderlinedButton
                            text={dataSet ? mainData.edit_cta[0].text : t('Edit')}
                            onPress={() => {
                                seteFirstName(firstName);
                                seteLastName(lastName);
                                seteEmail(email);
                                setePhone(phone);
                                seteGender(gender);
                                setProfileEdit(true);
                                seteTitle(title);
                                seteMiddleName(middleName);
                                seteDOB(dob);
                                setePcode(pcode);
                                setePcountry(pcountry);
                                seteSreetMainAdress(streetMainAdress);
                                seteZipMainAdress(zipMainAdress);
                                seteCityMainAdress(cityMainAdress);
                                seteMobileNumber(mobileNumber);
                                seteMobileCode(mobileCode);
                            }}
                            color={theme.palette.common.darkGrey}
                        />
                    )}
                </div>
            </Grid>

            {!profileEdit && (
                <Grid item md={6} xs={12}>
                    {haveImg ? (
                        <img src={imgSrc} alt="avatar" className={classes.avatar} />
                    ) : (
                        <div className={classes.imagebg}>
                            <div className={classes.accountIconWrapper}>
                                <Icon
                                    color={theme.palette.common.yellow}
                                    src={account_yellow}
                                    description="account"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                            <div
                                style={{
                                    marginTop: matchesxs ? -30 : -60,
                                }}
                            >
                                <FilledButton
                                    text={
                                        dataSet ? mainData.personal_data_upload_image_cta[0].text : t('Upload an image')
                                    }
                                    color={'yellow'}
                                    onPress={() => {
                                        imgFileSelector.click();
                                    }}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    )}
                </Grid>
            )}
            <DialogBox
                open={openChangeDialogPersonal}
                onClose={() => {
                    console.debug('closing dialog');
                    setOpenChangeDialogPersonal(false);
                }}
                onPress={() => {
                    /** Resetting Country on don't save click */
                    setPcountry(localCountry);
                    setPcode(localCountryCode);

                    setOpenChangeDialogPersonal(false);
                    setProfileEdit(false);
                }}
                title={dataSet ? mainData.sure_warning[0].text : 'Are you sure?'}
                text={dataSet ? mainData.changes_not_saved[0].text : 'The changes you have made will not be saved.'}
                cancelButton={true}
                cancelText={dataSet ? mainData.cancel[0].text : 'Are you sure?'}
                mainButton={true}
                mainButtonText={dataSet ? mainData.don_t_save[0].text : "Don't Save"}
                mainButtonColor={'yellow'}
            />
        </Grid>
    );
};

export default PersonalData;
