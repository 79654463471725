import { useEffect, useState } from 'react';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const CURRENT_ENV = import.meta.env.VITE_ENVIRONMENT;

const useHubName = () => {
    const { i18n } = useTranslation();

    const welcomePageAnamnesis = usePersistedPrismicStore((state) => state.welcomePageAnamnesisData);
    const {
        [i18n.language]: { mainDataProfessionalDoctors, dataSet },
    } = usePersistedPrismicStore((state) => state.anamnesisData);
    console.debug('label1: contentProfessional1', mainDataProfessionalDoctors);

    const [content] = useState({
        'en-us': {},
        'de-de': {},
    });
    const [allResult, setAllResult] = useState([]);
    const [welcomePagePrismicText, setWelcomePagePrismicText] = useState('');
    const [specificData, setSpecificData] = useState(Array);
    const [doctorSpecific, setDoctorSpecific] = useState([]);

    useEffect(() => {
        const results = welcomePageAnamnesis.data;

        if (results && results.length > 0) {
            console.debug('combinedResultsWelcome', results, CURRENT_ENV);
            let c = content;
            let keys = [];
            setAllResult(results);
            results.forEach((result) => {
                if (
                    result.type === 'hub' &&
                    result.data.application === 'Patients' &&
                    result.tags.includes(CURRENT_ENV)
                ) {
                    let key = {};
                    if (result.lang === 'en-us') {
                        key['title'] = result.data.title[0].text;
                        key['key'] = result.data.key[0].text;
                        key[result.data.key[0].text] = result.data.professional_types;
                        keys.push(key);
                    }
                }
                if (result.type === 'welcome_page_to_anamnesis_in_hub') {
                    c[result.lang] = result.data;
                }
            });
            setWelcomePagePrismicText(c);
            setSpecificData(keys);
        }
    }, [welcomePageAnamnesis]);

    useEffect(() => {
        let doctorSpecificArray = [];
        allResult?.forEach((result) => {
            mainDataProfessionalDoctors.forEach((professionalData) => {
                let obj = {};
                if (professionalData?.location_hub?.id === result.id) {
                    obj[result?.data?.key[0].text] = professionalData;
                }
                console.debug('obj11', obj);
                Object.values(obj).length > 0 && doctorSpecificArray.push(obj);
            });
        });
        setDoctorSpecific(doctorSpecificArray);
        console.debug(
            '🚀 ~ file: useHubName.jsx:67 ~ mainDataProfessionalDoctors.map ~ doctorSpecific:',
            doctorSpecific
        );
    }, [mainDataProfessionalDoctors, allResult, dataSet]);

    const SpecificDoctor = (selectedHub) => {
        let doctor = [];
        doctorSpecific.forEach((doc) => {
            let obj = {};
            if (doc[selectedHub]) {
                obj = doc[selectedHub];
            }
            obj.hasOwnProperty('key') && doctor?.push(obj);
        });
        return doctor;
    };

    return { eternolocationDoctorsData: specificData, welcomePagePrismicText, doctorSpecific, SpecificDoctor };
};

export default useHubName;
