import { Grid, TextField, useTheme } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './LifeStyleStyles';
import dustbinIcon from '@/assets/images/dustbinIcon.png';
import UnderLinedAddButton from '../UnderLinedAddButton/UnderLinedAddButton';

const LifeStyleWhichOneDrugs = ({ mainData, whichOneDrugsLocal, setWhichOneDrugsLocal }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    function handleInputChange(index, e) {
        let newFormValues = [...whichOneDrugsLocal];
        newFormValues[index][e.target.id] = e.target.value;
        setWhichOneDrugsLocal(newFormValues);
    }
    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...whichOneDrugsLocal];
        list.splice(index, 1);
        setWhichOneDrugsLocal(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        console.debug('whichOneDrugs12123', whichOneDrugsLocal);
        setWhichOneDrugsLocal([...whichOneDrugsLocal, { whichOne: '', howOftenDrugs: '' }]);
    };

    return (
        <>
            {whichOneDrugsLocal.map((valueField, index) => (
                <div className="">
                    <Grid item container spacing={2}>
                        <Grid item md={5} xs={12}>
                            <TextField
                                id="whichOne"
                                label={
                                    mainData
                                        ? mainData['6_drugs_subquestion_which_one_if_yes_'][0].text
                                        : t('TransferSlip')
                                }
                                variant="filled"
                                autoComplete="off"
                                color="primary"
                                value={valueField.whichOne}
                                onChange={(e) => {
                                    handleInputChange(index, e);
                                }}
                                classes={{
                                    root: classes.textFields,
                                }}
                                style={{
                                    width: '100%',
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                id="howOftenDrugs"
                                label={
                                    mainData
                                        ? mainData['6_drugs_subquestion_how_often__if_yes_'][0].text
                                        : t('TransferSlip')
                                }
                                variant="filled"
                                autoComplete="off"
                                color="primary"
                                value={valueField.howOftenDrugs.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/||[\]]/, '')}
                                onChange={(e) => {
                                    handleInputChange(index, e);
                                }}
                                classes={{
                                    root: classes.textFields,
                                }}
                                style={{
                                    width: '100%',
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={1} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            {whichOneDrugsLocal.length > 1 && (
                                <img
                                    src={dustbinIcon}
                                    alt=""
                                    onClick={() => handleRemoveClick(index)}
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </div>
            ))}

            {/* <a className={classes.contentTextLink} onClick={() => handleAddClick()}>
                    {`+ ${mainData.add_another_drugs[0].text}`}
                </a> */}
            <UnderLinedAddButton
                onClick={() => handleAddClick()}
                underLineText={mainData.add_another_drugs[0].text}
                marginTop={true}
            />
        </>
    );
};

export default memo(LifeStyleWhichOneDrugs);
