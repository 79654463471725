import { makeStyles, useTheme } from '@material-ui/core/styles';
import TimeslotCalendar from './BookingModal/TimeslotCalendar';
import {
    useBookingStore,
    useConfigStore,
    usePersistedPrismicStore,
    useProfessionalsStore,
    useSearchStore,
} from '@/store';
import Typography from '@material-ui/core/Typography';
import { AntSwitch } from './Common/AntSwitchCommon';
import React, { useContext, useEffect, useRef, useState } from 'react';
import UnderlinedButton from './UnderlinedButton';
import FilledButton from './FilledButton';
import BorderLinearProgress from './Common/BorderLinearProgress';
import Registration from './BookingModal/Registration';
import Address from './BookingModal/Address';
import Consent from './BookingModal/Consent';
import VerificationCode from './BookingModal/VerificationCode';
import ConfirmAppointment from './BookingModal/ConfirmAppointment';
import { Context as AuthContext } from '@/context/AuthContext';
import isEmail from 'validator/es/lib/isEmail';
import { isValidPhoneNumber } from 'libphonenumber-js';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { Auth } from 'aws-amplify';
import isPostalCode from 'validator/es/lib/isPostalCode';
import { bookAppointment, JourneyType, registerUser } from '@/utils/helpers';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Context as BookAppointmentContext } from '@/context/BookAppointmentContext';
import { ContextProvider } from '@/context/ProjectContext';
import { HealthInsurance } from './Search/SearchBar';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import SecondaryLocationSelection from './BookingModal/SecondaryLocationSelection';
import { useMediaQuery } from '@material-ui/core';
import { adressApi } from '@/service/AnamnesisSectionAPI/PersonalnformationAPI';
import useI18nCountries from '@/hooks/useI18nCountries';
import InvalidTimeslot from './BookingModal/InvalidTimeslot';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'start',
        paddingTop: '10vh',
        justifyContent: 'center',
        zIndex: 5,
        background: 'rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(0.1rem)',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '0',
        },
    },
    modalWrapper: {
        position: 'relative',
        width: '50rem',
        maxHeight: '80vh',

        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            maxHeight: '100vh',
        },
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
        maxHeight: '80vh',
        background: 'white',
        borderRadius: '1.5rem',
        padding: '2.5rem',
        boxSizing: 'border-box',
        fontFamily: 'MessinaSans-Regular',
        userSelect: 'none',
        overflow: 'scroll',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            maxHeight: '100vh',
            borderRadius: 0,
            position: 'relative',
            padding: '2.5rem',
        },
    },
    closeIcon: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            zIndex: 1,
        },
    },
    stepTitle: {
        fontWeight: 400,
        fontSize: '1.5rem',
    },
    infoBox: {
        padding: '0.75rem 1rem',
        background: theme.palette.common.lightGray,
        border: '1px solid #d5d5d5',
        borderRadius: '0.5rem',
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('xs')]: {
            gap: '0.5rem',
        },
    },
    infoBoxRow: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'start',
            gap: '0.25rem',
        },

        '& > a': {
            textDecoration: 'underline',
            color: '#686868',
        },

        '& > p:first-child': {
            fontWeight: 700,
        },

        '& > p:nth-child(2)': {
            color: '#686868',
        },
    },
    navBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        marginTop: '1rem',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
            gap: '1rem',
        },
    },
    progressContainer: {
        width: 200,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    progressText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
        width: 300,
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap',
        },
    },
}));

export const BookingStep = {
    LOCATION_SELECTION: 'location selection',
    TIMESLOT_SELECTION: 'timeslot selection',
    REGISTRATION: 'registration',
    ADDRESS: 'address',
    VERIFICATION_CODE: 'verification code',
    CONFIRM_APPOINTMENT: 'confirm appointment',
    CONSENT: 'treatment consent',
    INVALID_TIMESLOT: 'invalid timeslot',
};

const StepComponent = {
    [BookingStep.LOCATION_SELECTION]: SecondaryLocationSelection,
    [BookingStep.TIMESLOT_SELECTION]: TimeslotCalendar,
    [BookingStep.REGISTRATION]: Registration,
    [BookingStep.ADDRESS]: Address,
    [BookingStep.VERIFICATION_CODE]: VerificationCode,
    [BookingStep.CONFIRM_APPOINTMENT]: ConfirmAppointment,
    [BookingStep.CONSENT]: Consent,
    [BookingStep.INVALID_TIMESLOT]: InvalidTimeslot,
};

const BookingModal = () => {
    const history = useHistory();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const countryList = useI18nCountries();
    const {
        state: { user, userAddresses, usersession },
        setuser,
        setuseraccounts,
        setusersession,
        setUserAddress,
    } = useContext(AuthContext);
    const { setConfirmDoctor } = useContext(BookAppointmentContext);
    const { setAppointmentSearchConfirmation, setAppointmentSelectionConfirmation, setAppointmentSearch } =
        useContext(ContextProvider);
    const classes = useStyles();
    const currentCustomer = useConfigStore((state) => state.currentCustomer);
    const treatments = useSearchStore((state) => state.treatments);
    const locations = useSearchStore((state) => state.locations);
    const allDocuments = useSearchStore((state) => state.allDocuments);
    const selectedTreatments = useSearchStore((state) => state.selectedTreatments);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const selectedHealthInsurance = useSearchStore((state) => state.selectedHealthInsurance);
    const professionalMappings = useProfessionalsStore((state) => state.professionalMappings);
    const selectedProfessional = useBookingStore((state) => state.selectedProfessional);
    const currentStep = useBookingStore((state) => state.currentStep);
    const showReturningPatientToggle = useBookingStore((state) => state.showReturningPatientToggle);
    const isReturningPatient = useBookingStore((state) => state.isReturningPatient);
    const selectedTimeslot = useBookingStore((state) => state.selectedTimeslot);
    const loading = useBookingStore((state) => state.loading);
    const appointmentData = useBookingStore((state) => state.appointmentData);
    const additionalInfo = useBookingStore((state) => state.additionalInfo);
    const signUpStarted = useBookingStore((state) => state.signUpStarted);
    const isOnlineAppointment = useBookingStore((state) => state.isOnlineAppointment);
    const isAtSecondaryLocation = useBookingStore((state) => state.isAtSecondaryLocation);
    const consentTreatments = useBookingStore((state) => state.consentTreatments);
    const secondaryLocation = useBookingStore((state) => state.secondaryLocation);
    const secondaryLocationOptions = useBookingStore((state) => state.secondaryLocationOptions);
    const [consentToTreatments, setConsentToTreatments] = useState(false);
    const {
        [i18n.language]: { mainData: bookAppointmentPrismicData },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);
    const {
        [i18n.language]: { content: accountPrismicData },
    } = usePersistedPrismicStore((state) => state.accountData);
    const {
        [i18n.language]: { benefits: registerPrismicData },
    } = usePersistedPrismicStore((state) => state.registerData);
    const {
        selectedGender,
        firstName,
        lastName,
        dob,
        email,
        phoneNumber,
        confirmPassword,
        password,
        street,
        zipCode,
        city,
        country,
        verificationCode,
        isConsentConfirmed,
    } = useBookingStore((state) => state.registrationFormData);
    const continueButtonRef = useRef();
    const modalRef = useRef();

    const registrationSteps = [BookingStep.REGISTRATION, BookingStep.ADDRESS, BookingStep.VERIFICATION_CODE];
    const showProgressBar = registrationSteps.includes(currentStep);

    const scrollToContinueButton = () => {
        continueButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const emailExists = async () => {
        try {
            await Auth.signIn(email, '123');
            return false;
        } catch (error) {
            return error.code === 'NotAuthorizedException';
        }
    };

    const updateAddress = async () => {
        useBookingStore.setState({ loading: true });
        try {
            const addressData = {
                user_id: user.id,
                street: street,
                zip: zipCode,
                city: city,
                country: {
                    code: country,
                    name: countryList[country.toUpperCase()],
                },
            };

            await adressApi(usersession, addressData);
            setUserAddress(addressData);

            useBookingStore.setState({
                loading: false,
                currentStep: BookingStep.CONFIRM_APPOINTMENT,
            });
        } catch (e) {
            console.debug(e);
            useBookingStore.setState({ loading: false });
        }
    };

    const signUpUser = async () => {
        try {
            useBookingStore.setState({ loading: true });

            const genderCode = registerPrismicData.gender_values.find(
                ({ gender_display_value }) => gender_display_value[0].text === selectedGender
            )?.gender_api_value;

            await Auth.signUp({
                username: email,
                password: confirmPassword,
                attributes: {
                    email: email,
                    'custom:firstName': firstName.trim(),
                    'custom:lastName': lastName.trim(),
                    'custom:gender': genderCode,
                    'custom:dob': dob.trim(),
                    'custom:language': i18n.language,
                    'custom:phone': phoneNumber.trim(),
                },
            });

            useBookingStore.setState({
                currentStep: BookingStep.VERIFICATION_CODE,
                loading: false,
                signUpStarted: true,
            });
        } catch (e) {
            // TODO Fail Message
            console.debug(e);
            useBookingStore.setState({ loading: false });
        }
    };

    const confirmSignUp = async () => {
        useBookingStore.setState({ loading: true });
        try {
            await Auth.confirmSignUp(email, verificationCode);
            const signInUser = await Auth.signIn(email, confirmPassword);

            const genderCode = registerPrismicData.gender_values.find(
                ({ gender_display_value }) => gender_display_value[0].text === selectedGender
            )?.gender_api_value;

            const registerData = {
                user: {
                    id: signInUser.username,
                    email: email,
                    name: {
                        first: firstName,
                        last: lastName,
                    },
                    gender: genderCode,
                    date_of_birth: dob,
                    phone: {
                        number: phoneNumber,
                    },
                },
                account: {
                    user_id: signInUser.username,
                    privacy_policy_status: 'ACCEPTED',
                    terms_and_conditions_status: 'ACCEPTED',
                    use_of_analytics_status: 'UNKNOWN',
                    verification_status: 'VERIFIED',
                    locale: {
                        language: i18n.language,
                    },
                    status: 'ACTIVE',
                },
                address: {
                    user_id: signInUser.username,
                    street: street,
                    zip: zipCode,
                    city: city,
                    country: {
                        code: country,
                        name: countryList[country.toUpperCase()],
                    },
                },
                health_insurance: {
                    user_id: signInUser.username,
                    insurance_type: selectedHealthInsurance.toUpperCase().replace('_', ''),
                },
            };

            const response = await registerUser(registerData, signInUser.signInUserSession.idToken.jwtToken);

            if (!response) {
                useBookingStore.setState({ loading: false });
                return;
            }

            setuseraccounts(response.account);
            setuser(response.user);
            setusersession(signInUser.signInUserSession);
            setUserAddress(response.address);

            useBookingStore.getState().setVerificationCodeCheck(false, '');
            useBookingStore.setState({
                currentStep: BookingStep.CONFIRM_APPOINTMENT,
                loading: false,
                signUpStarted: false,
            });
        } catch (error) {
            if (error.code === 'CodeMismatchException') {
                useBookingStore.getState().setVerificationCodeCheck(true, verificationCode);
            }
            useBookingStore.setState({ loading: false });
        }
    };

    const buildBookAppointmentDataObj = () => {
        const patientObj = {
            id: user.id,
            is_for_self: true,
            is_returning: isReturningPatient,
            is_public_insurance: selectedHealthInsurance === HealthInsurance.PUBLIC,
            is_private_insurance: selectedHealthInsurance === HealthInsurance.PRIVATE,
            is_self_payer: selectedHealthInsurance === HealthInsurance.SELF_PAYER,
            documents: [],
            symptoms: [],
            additional_info: additionalInfo,
            is_with_prescription: false,
        };

        const professionalObj = {
            id: professionalMappings[selectedProfessional.key[0].text].user_id,
            key: selectedProfessional.key[0].text,
            type_key: allDocuments[selectedProfessional.type.id].key[0].text,
            location_key: selectedLocation,
        };

        if (isAtSecondaryLocation) professionalObj.sub_loc_key = secondaryLocation.key[0].text;

        const appointmentObj = {
            id: appointmentData.id,
            services: appointmentData?.type_keys?.map((key) => treatments[selectedLocation]?.[key].name[0].text),
            type_keys: appointmentData.type_keys,
            slot: selectedTimeslot,
            duration: appointmentData.duration,
            meta: {
                type_id: appointmentData.meta.type_id,
                calendar_id: appointmentData.meta.calendar_id,
                user_id: appointmentData.meta.user_id,
                is_at_location: appointmentData.is_at_location,
                is_online: appointmentData.is_online,
            },
        };

        return {
            patient: patientObj,
            professional: professionalObj,
            appointment: appointmentObj,
        };
    };

    const processErrorMessage = (errorMessage) => {
        // TODO handle more error types
        const formattedErrorMessage = errorMessage.toLowerCase();
        const isSlotInvalid =
            formattedErrorMessage.includes('no longer available') || formattedErrorMessage.includes('overlap');

        if (isSlotInvalid) {
            useBookingStore.setState({
                loading: false,
                selectedTimeslot: null,
                invalidTimeSlot: selectedTimeslot,
                currentStep: BookingStep.INVALID_TIMESLOT,
            });
            return;
        }

        useBookingStore.setState({ loading: false });
    };

    const book = async () => {
        useBookingStore.setState({ loading: true });
        const bookAppointmentData = buildBookAppointmentDataObj();

        const [isBooked, bookedAppointmentId, errorMessage] = await bookAppointment(
            user.id,
            bookAppointmentData,
            usersession.idToken.jwtToken
        );

        if (!isBooked) {
            processErrorMessage(errorMessage);
            return;
        }

        useBookingStore.setState({ loading: false });

        const {
            [i18n.language]: { professionalProfiles },
        } = usePersistedPrismicStore.getState().findAppointmentData;
        setConfirmDoctor({
            ...professionalProfiles.find(
                (professional) => selectedProfessional.key[0].text === professional.key[0].text
            ),
            id: professionalMappings[selectedProfessional.key[0].text].user_id,
        });

        const {
            [i18n.language]: { content: findAppointmentPrismicData },
        } = usePersistedPrismicStore.getState().findAppointmentData;
        setAppointmentSearchConfirmation({
            location_key: selectedLocation,
            professional_type_key: allDocuments[selectedProfessional.type.id].key[0].text,
            insurance_type_key: selectedHealthInsurance,
            professional_name: selectedProfessional.display_name[0].text,
            is_for_self: true,
            is_with_prescription: false,
            is_haveImg: false,
            symptoms: [],
            other_symptom: '',
            map_title: findAppointmentPrismicData[selectedLocation]
                ? findAppointmentPrismicData[selectedLocation].title
                : 'Online',
            location_title: isAtSecondaryLocation
                ? (secondaryLocation?.title?.[0]?.text ?? 'Online')
                : (findAppointmentPrismicData[selectedLocation]?.eterno_map_title ?? 'Online'),
            externalLocation: isAtSecondaryLocation,
            sub_loc_key: isAtSecondaryLocation ? secondaryLocation?.key?.[0]?.text : null,
            journey_type: JourneyType.SEARCH_BOOK,
        });
        setAppointmentSelectionConfirmation({
            selected_appointment: selectedTimeslot,
        });
        setAppointmentSearch({
            insurance_type_key: selectedHealthInsurance,
            treatment_keys_array: appointmentData.type_keys,
        });
        useBookingStore.setState({ bookedAppointmentId: bookedAppointmentId });

        document.body.style.overflow = 'unset';

        history.push('/confirm-appointment');
    };

    const nextStep = async () => {
        switch (currentStep) {
            case BookingStep.TIMESLOT_SELECTION:
                if (!user && signUpStarted) useBookingStore.setState({ currentStep: BookingStep.VERIFICATION_CODE });
                else if (!user) useBookingStore.setState({ currentStep: BookingStep.REGISTRATION });
                else if (user && !userAddresses) useBookingStore.setState({ currentStep: BookingStep.ADDRESS });
                else useBookingStore.setState({ currentStep: BookingStep.CONFIRM_APPOINTMENT });
                break;
            case BookingStep.REGISTRATION:
                useBookingStore.setState({ loading: true });
                const isEmailInUse = await emailExists();
                if (isEmailInUse) {
                    useBookingStore.getState().setEmailCheck(true, email);
                    useBookingStore.setState({ loading: false });
                    return;
                }
                useBookingStore.getState().setEmailCheck(false, '');
                useBookingStore.setState({ currentStep: BookingStep.ADDRESS, loading: false });
                break;
            case BookingStep.CONSENT:
                if (secondaryLocationOptions.length > 1) {
                    useBookingStore.setState({ currentStep: BookingStep.LOCATION_SELECTION });
                } else {
                    useBookingStore.setState({ currentStep: BookingStep.TIMESLOT_SELECTION });
                }
                break;
            case BookingStep.ADDRESS:
                if (user) await updateAddress();
                else await signUpUser();
                break;
            case BookingStep.VERIFICATION_CODE:
                await confirmSignUp();
                break;
            case BookingStep.CONFIRM_APPOINTMENT:
                await book();
                break;
            default:
                break;
        }
    };

    const previousStep = () => {
        switch (currentStep) {
            case BookingStep.CONSENT:
                useBookingStore.setState({ showBookingModal: false });
                break;
            case BookingStep.LOCATION_SELECTION:
                if (consentTreatments.length > 0) {
                    useBookingStore.setState({ currentStep: BookingStep.CONSENT });
                } else {
                    useBookingStore.setState({ showBookingModal: false });
                }
                break;
            case BookingStep.TIMESLOT_SELECTION:
                const noConsentTreatments = consentTreatments.length === 0;
                const multipleSecondaryLocations = secondaryLocationOptions.length > 1;

                if (!multipleSecondaryLocations && noConsentTreatments) {
                    useBookingStore.setState({ showBookingModal: false });
                } else if (multipleSecondaryLocations) {
                    useBookingStore.setState({ currentStep: BookingStep.LOCATION_SELECTION });
                } else {
                    useBookingStore.setState({ currentStep: BookingStep.CONSENT });
                }
                break;
            case BookingStep.REGISTRATION:
                useBookingStore.setState({ currentStep: BookingStep.TIMESLOT_SELECTION });
                break;
            case BookingStep.ADDRESS:
                useBookingStore.setState({ currentStep: BookingStep.REGISTRATION });
                break;
            case BookingStep.VERIFICATION_CODE:
                useBookingStore.setState({ currentStep: BookingStep.TIMESLOT_SELECTION });
                break;
            case BookingStep.CONFIRM_APPOINTMENT:
                useBookingStore.setState({ currentStep: BookingStep.TIMESLOT_SELECTION });
                break;
            default:
                break;
        }
    };

    const getStepTitle = () => {
        switch (currentStep) {
            case BookingStep.LOCATION_SELECTION:
                return bookAppointmentPrismicData.location_cta_external_address[0].text;
            case BookingStep.TIMESLOT_SELECTION:
                return bookAppointmentPrismicData.select_time_slot[0].text;
            case BookingStep.CONSENT:
                return bookAppointmentPrismicData.consent_step_title[0].text;
            case BookingStep.REGISTRATION:
                return registerPrismicData.step_title;
            case BookingStep.ADDRESS:
                return accountPrismicData.contact_details_heading[0].text;
            case BookingStep.VERIFICATION_CODE:
                return registerPrismicData.verification_code_input_label;
            case BookingStep.CONFIRM_APPOINTMENT:
                return bookAppointmentPrismicData.booking_summary.summary_heading[0].text;
            case BookingStep.INVALID_TIMESLOT:
                return bookAppointmentPrismicData.invalid_timeslot_title[0].text;
            default:
                return '';
        }
    };

    const getButtonText = () => {
        switch (currentStep) {
            case BookingStep.CONSENT:
                return bookAppointmentPrismicData.your_selection_cancel_button[0].text;
            case BookingStep.LOCATION_SELECTION:
                if (consentTreatments.length > 0) {
                    return bookAppointmentPrismicData.go_back_button[0].text;
                } else {
                    return bookAppointmentPrismicData.your_selection_cancel_button[0].text;
                }
            case BookingStep.TIMESLOT_SELECTION:
                const noConsentTreatments = consentTreatments.length === 0;
                const multipleSecondaryLocations = secondaryLocationOptions.length > 1;

                if (!noConsentTreatments || multipleSecondaryLocations) {
                    return bookAppointmentPrismicData.go_back_button[0].text;
                } else {
                    return bookAppointmentPrismicData.your_selection_cancel_button[0].text;
                }
            default:
                return bookAppointmentPrismicData.go_back_button[0].text;
        }
    };

    const checkDisabledState = () => {
        switch (currentStep) {
            case BookingStep.TIMESLOT_SELECTION:
                return !selectedTimeslot;
            case BookingStep.REGISTRATION:
                return (
                    !selectedGender ||
                    !dob ||
                    !firstName ||
                    !lastName ||
                    !email ||
                    !isEmail(email) ||
                    !phoneNumber ||
                    !isValidPhoneNumber(phoneNumber, 'DE') ||
                    !isMobilePhone(phoneNumber, 'de-DE') ||
                    !password ||
                    !isStrongPassword(password, {
                        minLength: 8,
                        minNumbers: 1,
                        minSymbols: 1,
                        minUppercase: 0,
                        minLowercase: 0,
                    }) ||
                    !confirmPassword ||
                    password !== confirmPassword ||
                    !isConsentConfirmed
                );
            case BookingStep.ADDRESS:
                return !street || !city || !country || !zipCode || !isPostalCode(zipCode, country);
            case BookingStep.VERIFICATION_CODE:
                return !verificationCode || verificationCode.length !== 6;
            case BookingStep.CONSENT:
                return !consentToTreatments;
            default:
                return false;
        }
    };

    const getPracticeAddress = () => {
        if (isAtSecondaryLocation) {
            return secondaryLocation?.address[0]?.text;
        }
        return locations[selectedLocation]?.address[0]?.text;
    };

    const getGoogleMapsUrl = () => {
        if (isAtSecondaryLocation) {
            return secondaryLocation?.google_maps?.url;
        }
        return locations[selectedLocation]?.google_maps?.url;
    };

    useEffect(() => {
        modalRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [currentStep]);

    const Component = StepComponent[currentStep];

    return (
        <div className={classes.container}>
            <div className={classes.modalWrapper}>
                <svg
                    onClick={() => useBookingStore.setState({ showBookingModal: false })}
                    className={classes.closeIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                >
                    <path
                        d="M22.2499 20.4167C22.7916 20.9167 22.7916 21.7917 22.2499 22.2917C21.9999 22.5417 21.6666 22.6667 21.3333 22.6667C20.9583 22.6667 20.6249 22.5417 20.3749 22.2917L15.9999 17.9167L11.5833 22.2917C11.3333 22.5417 10.9999 22.6667 10.6666 22.6667C10.2916 22.6667 9.95825 22.5417 9.70825 22.2917C9.16659 21.7917 9.16659 20.9167 9.70825 20.4167L14.0833 16L9.70825 11.625C9.16659 11.125 9.16659 10.25 9.70825 9.75C10.2083 9.20833 11.0833 9.20833 11.5833 9.75L15.9999 14.125L20.3749 9.75C20.8749 9.20833 21.7499 9.20833 22.2499 9.75C22.7916 10.25 22.7916 11.125 22.2499 11.625L17.8749 16.0417L22.2499 20.4167Z"
                        fill="#385759"
                    />
                </svg>
                <div className={classes.modal} ref={modalRef}>
                    {showProgressBar && (
                        <div style={{ width: '100%' }}>
                            <div className={classes.progressContainer}>
                                <Typography className={classes.progressText}>
                                    {`${registrationSteps.indexOf(currentStep) + 1}/${
                                        registrationSteps.length
                                    } ${getStepTitle()}`}
                                </Typography>
                                <BorderLinearProgress
                                    variant="determinate"
                                    value={
                                        ((registrationSteps.indexOf(currentStep) + 1) * 100) / registrationSteps.length
                                    }
                                />
                            </div>
                        </div>
                    )}
                    <Typography className={classes.stepTitle}>{getStepTitle()}</Typography>
                    {(currentStep === BookingStep.TIMESLOT_SELECTION ||
                        currentStep === BookingStep.LOCATION_SELECTION) && (
                        <>
                            <div className={classes.infoBox}>
                                <div className={classes.infoBoxRow}>
                                    <Typography>{`${bookAppointmentPrismicData.selected_treatments[0].text}:`}</Typography>
                                    <Typography>
                                        {`${Object.keys(selectedTreatments)
                                            .filter((treatmentKey) => selectedTreatments[treatmentKey])
                                            .map(
                                                (treatmentKey) =>
                                                    treatments[selectedLocation]?.[treatmentKey].name[0].text
                                            )
                                            .join(', ')}`}
                                    </Typography>
                                </div>
                                {currentStep === BookingStep.TIMESLOT_SELECTION && (
                                    <div className={classes.infoBoxRow}>
                                        <Typography>{`${bookAppointmentPrismicData.address[0].text}:`}</Typography>
                                        {isOnlineAppointment ? (
                                            <Typography>{'Online'}</Typography>
                                        ) : (
                                            <a href={getGoogleMapsUrl()} target="_blank" rel="noreferrer">
                                                {getPracticeAddress()}
                                            </a>
                                        )}
                                    </div>
                                )}
                            </div>
                            {showReturningPatientToggle && currentStep === BookingStep.TIMESLOT_SELECTION && (
                                <div style={{ display: 'flex' }}>
                                    <AntSwitch
                                        checked={!isReturningPatient}
                                        onChange={() =>
                                            useBookingStore.setState({ isReturningPatient: !isReturningPatient })
                                        }
                                    />
                                    <Typography style={{ marginLeft: 10 }}>
                                        {bookAppointmentPrismicData.new_patient_card[0].text}
                                    </Typography>
                                </div>
                            )}
                        </>
                    )}
                    {currentStep === BookingStep.CONFIRM_APPOINTMENT && secondaryLocation?.external && (
                        <div className={classes.infoBox}>
                            <div className={classes.infoBoxRow}>
                                <Typography />
                                <Typography>
                                    {bookAppointmentPrismicData.external_appointment_message[0].text}
                                </Typography>
                            </div>
                        </div>
                    )}
                    {Component && (
                        <Component
                            scrollToContinueButton={scrollToContinueButton}
                            consent={consentToTreatments}
                            setConsent={setConsentToTreatments}
                        />
                    )}
                    {currentStep !== BookingStep.INVALID_TIMESLOT && (
                        <div className={classes.navBar}>
                            <UnderlinedButton
                                onPress={previousStep}
                                noMarginTop
                                text={getButtonText()}
                                disabled={loading}
                            />
                            {currentStep !== BookingStep.LOCATION_SELECTION && (
                                <FilledButton
                                    ref={continueButtonRef}
                                    disabled={checkDisabledState() || loading}
                                    onPress={nextStep}
                                    text={bookAppointmentPrismicData.continue_button[0].text}
                                    fullWidth={matchesXs}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BookingModal;
