import axios from 'axios';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;

export async function insuranceApi(usersession, body) {
    let user_id = usersession?.idToken.payload.sub;
    let token = usersession?.idToken.jwtToken;
    try {
        const resp = await axios.post(`${BASE_API_URL}/secure/user/accounts/${user_id}/health-insurances`, body, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        console.debug('personalInfoAdress13 resp is ', resp);
        return resp;
    } catch (error) {
        console.debug('personalInfoAdress1 error is ', error);
        return new Error(error.message);
    }
}

export async function getUserInsurance(userAccount, usersession) {
    try {
        let user_id = userAccount.user_id;
        let token = usersession.idToken.jwtToken;
        const res = await axios.get(`${BASE_API_URL}/secure/user/accounts/${user_id}/health-insurances`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        return res;
    } catch (error) {
        console.debug('Error: ', error);
    }
}
