import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FilledButton from './FilledButton';
import { useTranslation } from 'react-i18next';
import { usePersistedPrismicStore, useUploadDocumentModalStore } from '../store';
import UnderlinedButton from './UnderlinedButton';
import { useFeatureFlag } from '../hooks/useFeatureFlag';

const useStyles = makeStyles((theme) => ({
    classTitle: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1.31rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
        marginBottom: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
        marginBottom: 20,
    },
    cardContentClassDetail: {
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
        marginBottom: 20,
    },
    cardWrapper: {
        display: 'flex',
        height: '70%',
        flexDirection: 'column',
    },
    ctaWrapper: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    h50: {
        height: '50%',
    },
    waitForConfirmation: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
        marginTop: 20,
    },
}));

const ConfirmCheckInCard = ({
    heading = '',
    subHeading = '',
    buttonLabel = '',
    onClick,
    disabledButton = false,
    showWaitForConfirmationText = false,
    waitForConfirmationText,
    appointmentId,
}) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const theme = useTheme();

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);
    const openModal = useUploadDocumentModalStore((state) => state.openModal);
    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');

    const handleClick = () => {
        onClick && onClick();
    };

    return (
        <div className={classes.cardContentClassDetail}>
            <Grid container direction="row" className={classes.h50}>
                <Grid item xs={12}>
                    <div className={classes.cardWrapper}>
                        <Typography className={classes.classTitle}>{heading}</Typography>
                        <Typography className={classes.classTeacher}>{subHeading}</Typography>
                        <div className={classes.ctaWrapper}>
                            <FilledButton
                                text={buttonLabel}
                                onPress={handleClick}
                                color={theme.palette.common.darkGrey}
                                disabled={disabledButton}
                            />
                            {isDocumentExchangeFeatureFlagActivated && (
                                <UnderlinedButton
                                    text={content?.upload_document_cta[0]?.text}
                                    noMarginTop={true}
                                    onPress={() => {
                                        openModal(appointmentId);
                                    }}
                                    noJSLeft={false}
                                />
                            )}
                        </div>
                        {showWaitForConfirmationText && waitForConfirmationText && (
                            <Typography className={classes.waitForConfirmation}>{waitForConfirmationText}</Typography>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default ConfirmCheckInCard;
