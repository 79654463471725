import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        backgroundColor: theme.palette.common.white,
        marginBottom: 20,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',

        borderRadius: 10,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 10,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.7',
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
    },
    goBackIcon: {
        // width: 9,
        height: 14,
        marginRight: 10,
    },
    line: {
        height: 1,
        width: '100%',
        backgroundColor: theme.palette.common.mediumBrown,
        marginBottom: 30,
        marginTop: 30,
    },
    subheading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
    },
    services: {
        backgroundColor: theme.palette.common.lightBrown,
        marginRight: 20,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        borderRadius: 20,
        marginTop: 15,
    },
    servicesIner: {
        width: 'auto',
    },
    serviceOuter: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 20,
    },
    showMore: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginRight: 7,
        cursor: 'pointer',
    },
    showMoreOuter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const MapDoctors = ({ doctor_location, dataSet }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const containerStyle = {
        maxWidth: theme.layout.contentWidth,
        // height: '-webkit-fill-available',
        height: 400,
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    });

    let defaultCenterMap = {
        lat: doctor_location.coordinates.latitude,
        lng: doctor_location.coordinates.longitude,
    };
    console.debug('defaultCenterMap: of: ', defaultCenterMap, doctor_location.google_maps.url);

    return (
        <>
            <div>
                <div className="map">
                    <div className="google-map">
                        {isLoaded && (
                            <GoogleMap
                                options={{ streetViewControl: false }}
                                mapContainerStyle={containerStyle}
                                center={defaultCenterMap}
                                zoom={15}
                            >
                                <Marker position={defaultCenterMap} />
                            </GoogleMap>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MapDoctors;
