import { useContext, useEffect, useState } from 'react';
import { Context as AuthContext } from '@/context/AuthContext';
// Child Components
import Welcome from '@/components/HealthProfileOverview/Welcome';
import HealthData from '@/components/HealthProfileOverview/HealthData';
import HealthContainer from './HealthContainer';
import Loader from '@/components/Loader';
// reusable hooks
import useScrollToTop from '@/hooks/useScrollToTop';
// Others
import { yourBodyGetAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';
import { usePersistedPrismicStore } from '@/store';
import { delay } from 'lodash';
import { useTranslation } from 'react-i18next';

const HealthProfileOverview = () => {
    const [userDataLocal, setUserDataLocal] = useState(null);
    const [loading, setLoading] = useState(true);
    const { i18n } = useTranslation();

    const {
        state: { usersession, userData },
    } = useContext(AuthContext);

    const { [i18n.language]: healthProfilePrismicData } = usePersistedPrismicStore((state) => state.healthProfileData);

    console.debug(
        '🚀 ~ file: HealthProfileOverview.jsx:28 ~ HealthProfileOverview ~ healthProfilePrismicData:',
        healthProfilePrismicData
    );
    const { content, dataArr, dataSet } = healthProfilePrismicData;

    useEffect(() => {
        if (userData) {
            setUserDataLocal(userData);
        }

        /** Loads new user data */
        let delayVal = delay(() => {
            yourBodyGetAPI(usersession)
                .then((res) => {
                    if (res.status === 200) {
                        setUserDataLocal(res.data);
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        }, 900);

        return () => {
            clearTimeout(delayVal);
        };
    }, []);

    useScrollToTop();

    if (!content || !dataSet || !dataArr) return <Loader />;

    return (
        <>
            {Object.keys(content).length !== 0 && (
                <HealthContainer>
                    {(() => {
                        switch (userDataLocal !== null || userDataLocal?.category_data.length > 0) {
                            case true:
                                return <HealthData loading={loading} userData={userDataLocal} />;
                            case false:
                                return <Welcome />;
                            default:
                                return <Loader />;
                        }
                    })()}
                </HealthContainer>
            )}
        </>
    );
};

export default HealthProfileOverview;
