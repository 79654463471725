import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import onlineAccess from '@/assets/Illustrations/Online-Acces.svg';
import FilledButton from '@/components/FilledButton';
import LegacyUploadDocumentModal from '@/components/HealthProfile/LegacyUploadDocumentModal.jsx';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { Context as AuthContext } from '@/context/AuthContext';
import { useTranslation } from 'react-i18next';
import Loader from '@/components/Loader';
import UnderlinedButton from '@/components/UnderlinedButton';
import DocumentTable from '@/components/HealthProfileOverview/DocumentTable';
import HealthContainer from './HealthContainer';
import CardWrapper from '@/components/HealthProfileOverview/HealthData/CardWrapper';
import { useHistory } from 'react-router-dom';
import { usePersistedPrismicStore } from '@/store';
import Icon from '@/components/Icon/Icon';
import { useFeatureFlag } from '@/hooks/useFeatureFlag.js';
import { useUploadDocumentModalStore } from '../../store';

const useStyles = makeStyles((theme) => {
    return {
        mainHeading: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: 24,
            fontWeight: 'normal',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '.6rem',
            },
        },
        heading: {
            ...theme.typography.HL1,
        },
        buttonLabel: {
            padding: 0,
            justifyContent: 'left',
        },
        cardbg: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'rgba(255,200,119,0.2)',
            maxHeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        imagebg: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            maxHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        text: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            lineHeight: '24px',
            color: theme.palette.common.darkGrey,
            marginTop: '1rem',
            width: '100%',
        },
        listItems: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            lineHeight: '24px',
            color: theme.palette.common.darkGrey,
            marginBlock: '.2rem',
            width: '95%',
        },
        font: {
            fontSize: '1.5rem',
            backgroundColor: 'red',
            height: '0',
            display: 'flex',
            justifyContent: 'space-between',
        },
        textFieldsError: {
            height: '3.75em',
            boxShadow: '0 0 0 1px #da3030',
            borderRadius: 5,
            marginTop: 10,
        },
        img: {
            width: '90%',
            marginLeft: '5%',
            marginBlock: '1rem',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                width: '70%',
                marginLeft: '15%',
            },
        },
        uploadWithFilesHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };
});

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

const UploadedByMe = () => {
    const { i18n } = useTranslation();

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');

    const {
        state: { isDocumentAvailable },
        setUploadedByMeData,
        setIsDocumentAvailable,
    } = useContext(HealthProfileContextMain);

    const {
        state: { user, userDocuments },
    } = useContext(AuthContext);

    useEffect(() => {
        if (content) setUploadedByMeData(content);
    }, [content]);

    useMemo(() => {
        if (userDocuments?.data.length === 0) {
            setIsDocumentAvailable(false);
        }
    }, [userDocuments]);

    if (user) {
        switch (isDocumentAvailable) {
            case true:
                return (
                    <UploadedByMeWithFiles
                        isDocumentExchangeFeatureFlagActivated={isDocumentExchangeFeatureFlagActivated}
                    />
                );
            case false:
                return (
                    <UploadedByMeBlank
                        isDocumentExchangeFeatureFlagActivated={isDocumentExchangeFeatureFlagActivated}
                    />
                );
            default:
                return <Loader />;
        }
    } else {
        return <UploadedByMeBlank isDocumentExchangeFeatureFlagActivated={isDocumentExchangeFeatureFlagActivated} />;
    }
};

const UploadedByMeBlank = ({ isDocumentExchangeFeatureFlagActivated }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { i18n } = useTranslation();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    // Prismic Data
    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);

    const openModal = useUploadDocumentModalStore((state) => state.openModal);

    const {
        state: { uploadedByMeData },
        setIsUploadButtonDisabled,
        setUploadedByMeData,
    } = useContext(HealthProfileContextMain);

    const {
        state: { user },
    } = useContext(AuthContext);

    useEffect(() => {
        if (content) setUploadedByMeData(content);
    }, [content]);

    let lan = languageMap[i18n.language];
    const [introData, setIntroData] = useState({});
    useMemo(() => {
        if (lan === 'en-us' && Object.keys(uploadedByMeData).length > 0) {
            const splitText = uploadedByMeData?.uploaded_by_me_subtitle[0].text.split(':');
            if (splitText.length > 1) {
                const splitList = splitText[1].split('\n').splice(1);
                const mainText = splitText[0];
                const list = splitList;
                setIntroData({
                    mainText,
                    list,
                });
            }
        }
    }, [uploadedByMeData, lan]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
        setIsUploadButtonDisabled(true);
    };

    const handleModalOpen = () => {
        if (user) {
            if (isDocumentExchangeFeatureFlagActivated) {
                openModal();
                return;
            }
            setIsModalOpen(true);
        } else {
            history.replace('/login?redirectTo=/uploaded-by-me');
        }
    };

    return (
        <>
            <HealthContainer>
                <CardWrapper pBlockXs={20} pInlineXs={0}>
                    {Object.keys(uploadedByMeData).length !== 0 ? (
                        <Grid container spacing={2} style={{ padding: matches ? 30 : null }}>
                            <Grid item md={12} xs={12} style={{ padding: 0 }}>
                                <Typography className={classes.mainHeading}>
                                    {uploadedByMeData?.uploaded_by_me_title[0].text}
                                </Typography>
                            </Grid>
                            <Grid container md={12} xs={12} style={{ padding: 0 }}>
                                <Grid item md={8} sm={8} style={{ padding: 0 }}>
                                    <Typography className={classes.text}>
                                        {lan === 'en-us'
                                            ? `${uploadedByMeData?.uploaded_by_me_subtitle[0].text?.split(':')[0]}:`
                                            : uploadedByMeData?.uploaded_by_me_subtitle.map(({ type, text }) => {
                                                  if (type === 'paragraph') return <>{text}</>;
                                              })}
                                    </Typography>
                                    <ul>
                                        {lan === 'en-us' && Object.keys(introData).length > 0
                                            ? introData.list.map((item, key) => (
                                                  <li className={classes.listItems} key={key}>
                                                      {item}
                                                  </li>
                                              ))
                                            : uploadedByMeData?.uploaded_by_me_subtitle.map(({ text }, index) => {
                                                  if (index >= 1)
                                                      return (
                                                          <li className={classes.listItems} key={index}>
                                                              {text}
                                                          </li>
                                                      );
                                              })}
                                    </ul>
                                    <div>
                                        <FilledButton
                                            text={uploadedByMeData?.upload_document_cta[0].text}
                                            onPress={handleModalOpen}
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={4} style={{ padding: 0, display: matches ? 'none' : 'block' }}>
                                    <div className={classes.img}>
                                        <Icon src={onlineAccess} width="100%" height="100%" />
                                    </div>
                                </Grid>
                            </Grid>
                            {!isDocumentExchangeFeatureFlagActivated && (
                                <LegacyUploadDocumentModal onClose={() => closeModal()} open={isModalOpen} />
                            )}
                        </Grid>
                    ) : (
                        <Loader />
                    )}
                </CardWrapper>
            </HealthContainer>
        </>
    );
};

const UploadedByMeWithFiles = ({ isDocumentExchangeFeatureFlagActivated }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const { i18n } = useTranslation();

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.medicalRecordsData);
    const openModal = useUploadDocumentModalStore((state) => state.openModal);

    const {
        state: { uploadedByMeData },
        setIsUploadButtonDisabled,
        setUploadedByMeData,
    } = useContext(HealthProfileContextMain);

    useEffect(() => {
        if (content) setUploadedByMeData(content);
    }, [content]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
        setIsUploadButtonDisabled(true);
    };

    return (
        <>
            <HealthContainer>
                <CardWrapper pBlockXs={20} pInlineXs={0}>
                    {Object.keys(uploadedByMeData).length !== 0 ? (
                        <Grid container spacing={2} style={{ paddingInline: '.5rem' }}>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                className={classes.uploadWithFilesHeader}
                                style={{
                                    paddingBlock: 0,
                                    paddingInline: matchesXs ? 20 : 0,
                                    flexDirection: matchesXs ? 'column' : 'row',
                                    alignItems: matchesXs ? 'flex-start' : 'center',
                                }}
                            >
                                <Typography className={classes.mainHeading}>
                                    {uploadedByMeData?.uploaded_by_me_title[0].text}
                                </Typography>
                                <UnderlinedButton
                                    text={uploadedByMeData?.upload_new_document_cta[0].text ?? 'Upload a new document'}
                                    noMarginTop={true}
                                    onPress={() => {
                                        if (isDocumentExchangeFeatureFlagActivated) {
                                            openModal();
                                            return;
                                        }
                                        setIsModalOpen(true);
                                    }}
                                    noJSLeft={false}
                                />
                            </Grid>
                            <Grid container md={12} xs={12} style={{ padding: 0 }}>
                                <DocumentTable />
                            </Grid>
                            {!isDocumentExchangeFeatureFlagActivated && (
                                <LegacyUploadDocumentModal onClose={() => closeModal()} open={isModalOpen} />
                            )}
                        </Grid>
                    ) : (
                        <Loader />
                    )}
                </CardWrapper>
            </HealthContainer>
        </>
    );
};

export default memo(UploadedByMe);
