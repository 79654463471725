import { useContext, useLayoutEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import pencilIcon from '@/assets/images/pencilIcon.png';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import summaryAllergies from '@/assets/images/summaryAllergies.svg';
import { useStyles } from './SummaryStyles';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import Icon from '@/components/Icon/Icon';

export default function SummaryAllergy({ mainData }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const {
        state: { allergiesTwo, review_option },
        redirectFunctionSummary,
        setAllergiesTwo,
    } = useContext(HealthProfileContextMain);

    const { allDataApi } = useContext(ContextProviderTwo);
    const allergiesIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'allergies');

    useLayoutEffect(() => {
        if (allDataApi?.meta?.has_anamnesis && review_option) {
            // your body
            if (allDataApi?.category_data[allergiesIndex]['category'] === 'allergies') {
                let category2 = allDataApi?.category_data[allergiesIndex].data;
                let allergyNew = {};
                let abc = category2?.allergies;
                abc.filter((e) => {
                    let a = abc.filter((d) => d.type === e.type);
                    allergyNew[e.type] = a;
                });
                console.debug('allergyArrLocal1: ', allergyNew);
                setAllergiesTwo({
                    data2: category2?.allergies,
                    anyAllergy: category2.active ? 'YES' : 'NO',
                    allergyType: '',
                    allergyArr: allergyNew,
                });
            }
        }
    }, [allDataApi]);

    return (
        <>
            <div className={classes.contentContainerBody}>
                {allergiesTwo['anyAllergy'] === 'YES' ? (
                    <Grid container direction={'row'} style={{ height: '50%' }}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 15 }}>
                            {!matches && (
                                <Icon src={summaryAllergies} description="allergies icon" width={48} height={48} />
                            )}
                            <Typography className={classes.shortHeading} style={{ marginTop: !matches && 10 }}>
                                {mainData.allergies_title[0].text}
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <div className={classes.flexClass}>
                                <img
                                    src={pencilIcon}
                                    alt=""
                                    className={classes.pointer}
                                    onClick={() => {
                                        redirectFunctionSummary(history, 4, 'set_allergies_two', allergiesTwo, true);
                                    }}
                                />
                            </div>
                        </Grid>
                        {/* change */}
                        {allergiesTwo['allergyArr'] !== undefined &&
                            allergiesTwo['allergyArr'] !== null &&
                            allergiesTwo.allergyArr['MEDICINES'] &&
                            allergiesTwo.allergyArr['MEDICINES'] !== undefined &&
                            allergiesTwo.allergyArr['MEDICINES'] !== null &&
                            allergiesTwo.allergyArr['MEDICINES'].length > 0 && (
                                <Grid
                                    container
                                    direction={'row'}
                                    style={{
                                        height: '50%',
                                        marginBottom: 15,
                                    }}
                                >
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.subtitle_allergies_medicine[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        {allergiesTwo.allergyArr['MEDICINES'].map((medi) => (
                                            <div className="">
                                                <Typography
                                                    className={classes.subHeading}
                                                    style={{
                                                        wordBreak: 'break-all',
                                                    }}
                                                >
                                                    {medi.value}
                                                </Typography>
                                                <Typography className={classes.subHeading}>{medi.reaction}</Typography>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}

                        {allergiesTwo['allergyArr'] !== undefined &&
                            allergiesTwo['allergyArr'] !== null &&
                            allergiesTwo.allergyArr['FOOD'] &&
                            allergiesTwo.allergyArr['FOOD'] !== undefined &&
                            allergiesTwo.allergyArr['FOOD'] !== null &&
                            allergiesTwo.allergyArr['FOOD'].length > 0 && (
                                <Grid
                                    container
                                    direction={'row'}
                                    style={{
                                        height: '50%',
                                        marginBottom: 15,
                                    }}
                                >
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.subtitle_allergies_food[0].text}
                                        </Typography>
                                    </Grid>
                                    {/* food */}
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        {allergiesTwo.allergyArr['FOOD'].map((medi) => (
                                            <div className="">
                                                <Typography className={classes.subHeading}>{medi.value}</Typography>
                                                <Typography className={classes.subHeading}>{medi.reaction}</Typography>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}

                        {allergiesTwo['allergyArr'] !== undefined &&
                            allergiesTwo['allergyArr'] !== null &&
                            allergiesTwo.allergyArr['OTHER ALLERGIES'] &&
                            allergiesTwo.allergyArr['OTHER ALLERGIES'] !== undefined &&
                            allergiesTwo.allergyArr['OTHER ALLERGIES'] !== null &&
                            allergiesTwo.allergyArr['OTHER ALLERGIES'].length > 0 && (
                                <Grid container direction={'row'} style={{ height: '50%' }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.subtitle_allergies_other[0].text}
                                        </Typography>
                                    </Grid>
                                    {/* other */}
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        {allergiesTwo.allergyArr['OTHER ALLERGIES'].map((medi) => (
                                            <div className="">
                                                <Typography className={classes.subHeading}>{medi.value}</Typography>
                                                <Typography className={classes.subHeading}>{medi.reaction}</Typography>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                ) : (
                    <Grid container direction={'row'} style={{ height: '50%' }}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            {!matches && (
                                <Icon src={summaryAllergies} description="allergies icon" width={48} height={48} />
                            )}
                            <Typography className={classes.shortHeading} style={{ marginTop: !matches && 10 }}>
                                {mainData.allergies_title[0].text}
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <div className={classes.flexClass}>
                                <img
                                    src={pencilIcon}
                                    alt=""
                                    className={classes.pointer}
                                    onClick={() => {
                                        redirectFunctionSummary(history, 4, 'set_allergies_two', allergiesTwo, true);
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading}>{mainData.nothing_added[0].text}</Typography>
                        </Grid>
                    </Grid>
                )}
            </div>
        </>
    );
}
