import { makeStyles, useTheme } from '@material-ui/core/styles';
import FilledButton from '../FilledButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CountryFlag from '../Common/CountryFlag';
import OutlinedButton from '../OutlinedButton';
import { useEffect, useMemo, useState } from 'react';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import { useBookingStore, useConfigStore, usePersistedPrismicStore, useSearchStore } from '@/store';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import OnlineAppointmentIcon from './OnlineAppointmentIcon';
import { BookingStep } from '../BookingModal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getConsentTreatments, getInsuranceAvailability } from '@/utils/helpers';
import NoTreatmentsMessage from '../Common/NoTreatmentsMessage';

const useStyles = makeStyles((theme) => ({
    spinnerContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh',
        fontFamily: 'MessinaSans-Regular',
    },
    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingBottom: 20,
    },
    doctorImg: {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        textAlign: 'center',
        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    mainHeading: {
        ...theme.typography.HL1,
    },
    mainContentContainer: {
        display: 'flex',
        boxSizing: 'border-box',
        gap: '1rem',
        width: '100%',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    section: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'start',
    },
    cardsContainer: {
        display: 'flex',
        gap: '1rem',
    },
    contentCard: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        padding: '30px',
        background: 'white',
        boxShadow: '0px 3px 12px 0px rgba(120, 120, 120, 0.22)',
        borderRadius: '0.5rem',
        boxSizing: 'border-box',
        gap: '2rem',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    card: {
        borderRadius: '8px',
        boxShadow: '0 0 0 1px #D5D5D5',
        background: '#FBFBF9',
        padding: '16px',
        userSelect: 'none',
        cursor: 'pointer',
        whiteSpace: 'pre-wrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '3rem',
        transition: 'background 0.2s ease, box-shadow 0.3s ease',
        textOverflow: 'ellipsis',
        '&:hover': {
            background: '#f3f3f3',
        },
        '&:active': {
            background: '#eaeaea',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    treatmentCard: {
        borderRadius: '8px',
        boxShadow: '0 0 0 1px #D5D5D5',
        background: '#FBFBF9',
        padding: '16px',
        userSelect: 'none',
        cursor: 'pointer',
        whiteSpace: 'pre-wrap',
        display: 'flex',
        transition: 'background 0.2s ease, box-shadow 0.3s ease',
        textOverflow: 'ellipsis',
        justifyContent: 'center',
        minHeight: '71px',
        width: '100%',
        boxSizing: 'border-box',
        gap: 0,
        flexDirection: 'column',
        alignItems: 'start',
        '&:hover': {
            background: '#f3f3f3',
        },
        '&:active': {
            background: '#eaeaea',
        },
    },
    treatmentCardTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        gap: '0.5rem',
    },
    treatmentDetailsWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '0.25rem',
    },
    treatmentDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: '12rem',
        width: '100%',
        fontWeight: 700,
    },
    treatmentDescription: {
        margin: 0,
        flexShrink: 1,
        flexGrow: 1,
        color: '#686868',
        fontSize: '1rem',
    },
    infoText: {
        color: 'rgba(46, 46, 46, 0.70)',
        fontWeight: 350,
        fontSize: '14px',
    },
    selectedCard: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
    },
}));

const BookWithProfessional = () => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [selectedProfessional, setSelectedProfessional] = useState(null);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [selectedTreatments, setSelectedTreatments] = useState({});
    const [filteredTreatments, setFilteredTreatments] = useState(null);
    const [isOnlineAppointment, setIsOnlineAppointment] = useState(false);
    const prismicData = useSearchStore((state) => state.prismicData);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);
    const professionals = useSearchStore((state) => state.professionals);
    const queryProfessional = useSearchStore((state) => state.selectedProfessional);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const allDocuments = useSearchStore((state) => state.allDocuments);
    const selectedHealthInsurance = useSearchStore((state) => state.selectedHealthInsurance);
    const treatments = useSearchStore((state) => state.treatments);

    const {
        [i18n.language]: {
            contentHeading: mainHeading,
            bookingHeading: mainBookingData,
            professionalProfiles: processedProfessionalProfiles,
            dataSet,
        },
    } = usePersistedPrismicStore((state) => state.findAppointmentData);

    const getGridColumns = () => {
        if (matchesXs) return '1';
        if (matchesSm) return '1';
        if (matchesMd) return '2';
        return '3';
    };

    const availableInsurances = useMemo(() => {
        if (!selectedProfessional || !selectedSpecialty) return [];

        const treatmentsArray = selectedProfessional.servicesList
            .filter((treatment) => !!treatments[selectedLocation][treatment.key[0].text])
            .map((treatment) => treatments[selectedLocation][treatment.key[0].text])
            .filter((treatment) => treatment.specialty === selectedSpecialty);

        const insuranceAvailability = getInsuranceAvailability(treatmentsArray);

        return Object.keys(insuranceAvailability).filter((key) => insuranceAvailability[key]);
    }, [selectedProfessional, selectedSpecialty]);

    const updateSpecialty = (locationMapping) => {
        selectedSpecialty !== locationMapping.professional_type.key[0].text
            ? setSelectedSpecialty(locationMapping.professional_type.key[0].text)
            : setSelectedSpecialty(null);

        setSelectedTreatments({});
    };

    const updateSelectedTreatments = (treatment, isOnline = false) => {
        const newSelectedTreatments = { ...selectedTreatments };

        if (newSelectedTreatments[treatment] && isOnline === isOnlineAppointment) {
            delete newSelectedTreatments[treatment];
        } else if (Object.keys(newSelectedTreatments).length === 0 || (!isOnlineAppointment && !isOnline)) {
            newSelectedTreatments[treatment] = true;
        } else {
            return;
        }

        setSelectedTreatments(newSelectedTreatments);
        setIsOnlineAppointment(isOnline);
    };

    const startBooking = () => {
        const professional = processedProfessionalProfiles.find(
            (professional) => professional.key[0].text === queryProfessional
        );

        useBookingStore.getState().resetStore();
        useSearchStore.setState({
            selectedSpecialty: selectedSpecialty,
            selectedHealthInsurance: selectedHealthInsurance,
            selectedTreatments: selectedTreatments,
            selectedLocation: professional.locationData.key[0].text,
        });

        const secondaryLocations =
            professional.secondary_location_mappings.find(
                (secondaryLocation) => secondaryLocation.professional_type.key[0].text === selectedSpecialty
            )?.secondary_locations ?? [];

        const consentTreatments = getConsentTreatments({
            selectedTreatments,
            treatments,
            selectedLocation,
            allDocuments,
            selectedHealthInsurance,
        });

        const determineBookingStep = () => {
            if (consentTreatments.length > 0) {
                return BookingStep.CONSENT;
            } else if (secondaryLocations.length > 1) {
                return BookingStep.LOCATION_SELECTION;
            } else {
                return BookingStep.TIMESLOT_SELECTION;
            }
        };

        useBookingStore.setState({
            selectedProfessional: selectedProfessional,
            isOnlineAppointment: isOnlineAppointment,
            showBookingModal: true,
            consentTreatments: consentTreatments,
            isAtSecondaryLocation: professional.secondary_location_mappings.length > 0 && secondaryLocations.length > 0,
            secondaryLocation: secondaryLocations.length === 1 ? secondaryLocations[0] : null,
            currentStep: determineBookingStep(),
            secondaryLocationOptions:
                professional.secondary_location_mappings.length > 0 && secondaryLocations.length > 1
                    ? secondaryLocations
                    : [],
        });
    };

    useEffect(() => {
        if (!currentCustomer || !professionals) return;

        const professional = processedProfessionalProfiles.find(
            (professional) => professional.key[0].text === queryProfessional
        );

        if (
            !professionals[queryProfessional] ||
            !currentCustomer.locations.some(
                (customerLocation) => customerLocation.prismicId === professionals[queryProfessional].location_hub.id
            ) ||
            !professional
        ) {
            history.replace('/');
            useSearchStore.setState({ selectedProfessional: null });
            return;
        }

        setSelectedProfessional(professional);
        useSearchStore.setState({ selectedLocation: professional.locationData.key[0].text });
        if (professional.secondary_location_mappings.length === 0) {
            setSelectedSpecialty(professional.specialityType.key[0].text);
        }
        setLoading(false);
    }, [queryProfessional, currentCustomer, professionals]);

    useEffect(() => {
        if (!selectedSpecialty) {
            setFilteredTreatments([]);
            return;
        }

        const newFilteredTreatments = selectedProfessional?.servicesList
            .filter((treatment) => !!treatments[selectedLocation][treatment.key[0].text])
            .map((treatment) => ({
                ...treatments[selectedLocation][treatment.key[0].text],
                public: treatments[selectedLocation][treatment.key[0].text].public_insurance,
                private: treatments[selectedLocation][treatment.key[0].text].private_insurance,
            }))
            .filter((treatment) => treatment.specialty === selectedSpecialty && treatment[selectedHealthInsurance]);

        setFilteredTreatments(newFilteredTreatments);
        setSelectedTreatments({});
    }, [selectedSpecialty, selectedHealthInsurance]);

    if (loading || !selectedProfessional || !dataSet) {
        return (
            <div className={classes.spinnerContainer}>
                <EternoSpinner />
            </div>
        );
    }

    return (
        <div className={classes.mainContentContainer}>
            <Grid item md={4} sm={5} xs={12}>
                <div className={classes.cardContentClasses}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                            marginTop: 0,
                        }}
                    >
                        <img
                            src={selectedProfessional.profile_picture.url}
                            className={classes.doctorImg}
                            alt={selectedProfessional.profile_picture.alt}
                        />
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography className={classes.classTitle}>
                                {selectedProfessional.display_name[0].text}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', gap: 15, padding: '0.5rem 0' }}>
                            {selectedProfessional.languages.map((lang) => (
                                <CountryFlag
                                    countryCode={lang.languages1 === 'en' ? 'gb' : lang.languages1}
                                    alt={lang.languages1}
                                />
                            ))}
                        </div>
                        <Typography
                            className={classes.classTeacher}
                            style={{
                                marginBottom: 10,
                                textAlign: 'center',
                                padding: '0 0.5rem',
                            }}
                        >
                            {selectedProfessional.practice_name[0].text}
                        </Typography>
                        <div
                            style={{
                                width: '-webkit-fill-available',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <OutlinedButton
                                text={dataSet ? mainBookingData?.see_profile_cta[0].text : 'See Profile'}
                                onPress={() => history.push(`/professional/${selectedProfessional.key[0].text}`)}
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
            </Grid>
            <div className={classes.contentCard}>
                {selectedProfessional.secondary_location_mappings.length > 0 && (
                    <div className={classes.section}>
                        <Typography>{mainHeading[0].sub_heading_3}</Typography>
                        <div className={classes.cardsContainer}>
                            {selectedProfessional.secondary_location_mappings.map((locationMapping) => (
                                <div
                                    className={clsx(
                                        classes.card,
                                        selectedSpecialty === locationMapping.professional_type.key[0].text &&
                                            classes.selectedCard
                                    )}
                                    onClick={() => updateSpecialty(locationMapping)}
                                    key={locationMapping.professional_type.key[0].text}
                                >
                                    {locationMapping.professional_type.name[0].text}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {selectedSpecialty &&
                    (filteredTreatments.length > 0 ? (
                        <div className={classes.section}>
                            <Typography>{mainHeading[0].sub_heading_6}</Typography>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: `repeat(${getGridColumns()}, 1fr)`,
                                    columnGap: '1rem',
                                    rowGap: '1rem',
                                    width: '100%',
                                }}
                            >
                                {filteredTreatments.map((treatment) => (
                                    <>
                                        {treatment.location && (
                                            <div
                                                key={treatment.key[0].text + '-location'}
                                                className={clsx(
                                                    classes.card,
                                                    classes.treatmentCard,
                                                    selectedTreatments[treatment.key[0].text] &&
                                                        !isOnlineAppointment &&
                                                        classes.selectedCard
                                                )}
                                                onClick={() => updateSelectedTreatments(treatment.key[0].text)}
                                            >
                                                <Typography
                                                    lang={i18n.language}
                                                    style={{
                                                        wordBreak: 'break-word',
                                                        hyphens: 'auto',
                                                    }}
                                                >
                                                    {treatment.name[0].text}
                                                </Typography>
                                            </div>
                                        )}
                                        {treatment.online && (
                                            <div
                                                key={treatment.key[0].text + '-online'}
                                                className={clsx(
                                                    classes.card,
                                                    classes.treatmentCard,
                                                    selectedTreatments[treatment.key[0].text] &&
                                                        isOnlineAppointment &&
                                                        classes.selectedCard
                                                )}
                                                onClick={() => updateSelectedTreatments(treatment.key[0].text, true)}
                                            >
                                                <div className={classes.treatmentCardTitleContainer}>
                                                    <Typography
                                                        lang={i18n.language}
                                                        style={{
                                                            wordBreak: 'break-word',
                                                            hyphens: 'auto',
                                                        }}
                                                    >
                                                        {treatment.name[0].text}
                                                    </Typography>
                                                    <OnlineAppointmentIcon />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    ) : (
                        availableInsurances.length > 0 && (
                            <div className={classes.section}>
                                {
                                    <NoTreatmentsMessage
                                        selectedHealthInsurance={selectedHealthInsurance}
                                        publicText={prismicData.no_treatments_available_for_public_insurance[0].text}
                                        privateText={prismicData.no_treatments_available_for_private_insurance[0].text}
                                        selfPayText={prismicData.no_treatments_available_for_self_payer[0].text}
                                        changeInsuranceText={prismicData.change_insurance_to_see_treatments[0].text}
                                        availableInsurances={availableInsurances}
                                    />
                                }
                            </div>
                        )
                    ))}
                {selectedSpecialty && Object.keys(selectedTreatments).length > 0 && (
                    <FilledButton onPress={startBooking} text={mainHeading[0].select_a_time} />
                )}
            </div>
        </div>
    );
};

export default BookWithProfessional;
