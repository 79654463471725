import { useContext, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, rgbToHex, useTheme } from '@material-ui/core/styles';
import onlineAccess from '@/assets/Illustrations/Online-Acces.svg';
import FilledButton from '@/components/FilledButton';
import LegacyUploadDocumentModal from '@/components/HealthProfile/LegacyUploadDocumentModal.jsx';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import Icon from '@/components/Icon/Icon';

const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        mainHeading: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: 24,
            fontWeight: 'normal',
        },
        heading: {
            ...theme.typography.HL1,
        },
        buttonLabel: {
            padding: 0,
            justifyContent: 'left',
        },
        cardbg: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'rgba(255,200,119,0.2)',
            maxHeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        imagebg: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            maxHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        text: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            lineHeight: '24px',
            color: theme.palette.common.darkGrey,
            marginTop: '1rem',
            width: '100%',
        },
        listItems: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            lineHeight: '24px',
            color: theme.palette.common.darkGrey,
            marginBlock: '.2rem',
            width: '95%',
        },
        font: {
            fontSize: '1.5rem',
            backgroundColor: 'red',
            height: '0',
            display: 'flex',
            justifyContent: 'space-between',
        },
        textFieldsError: {
            height: '3.75em',
            boxShadow: '0 0 0 1px #da3030',
            borderRadius: 5,
            marginTop: 10,
        },
        img: {
            width: '90%',
            marginLeft: '5%',
            marginBlock: '1rem',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                width: '70%',
                marginLeft: '15%',
            },
        },
    };
});

const list = [
    'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus consectetur cum architecto a ratione nulla. Natus consectetur cum architecto a ratione nulla',
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus consectetur cum architecto a ratione nulla. Natus consectetur cum architecto a ratione nulla',
];

const UploadedByMe = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesxs = useMediaQuery(theme.breakpoints.down('xs'));

    const { setIsUploadButtonDisabled } = useContext(HealthProfileContextMain);

    const [openModal, setOpenModal] = useState(false);

    const closeModal = () => {
        setOpenModal(false);
        setIsUploadButtonDisabled(true);
    };

    return (
        <Grid container spacing={2} style={{ padding: 0 }}>
            <Grid item md={12} xs={12} style={{ padding: 0 }}>
                <Typography className={classes.mainHeading}>Uploaded by me</Typography>
            </Grid>
            <Grid container md={12} xs={12} style={{ padding: 0 }}>
                <Grid item md={8} sm={8} style={{ padding: 0 }}>
                    <Typography className={classes.text}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus consectetur cum architecto a
                        ratione nulla, deleniti mollitia deserunt quod eos obcaecati consequatur nihil expedita.
                        Expedita ratione aliquid at explicabo est?
                    </Typography>
                    <ul>
                        {list.map((item, key) => (
                            <li className={classes.listItems} key={key}>
                                {item}
                            </li>
                        ))}
                    </ul>
                    <div>
                        <FilledButton text="Upload A Document" onPress={() => setOpenModal(true)} />
                    </div>
                </Grid>
                <Grid item md={4} sm={4} style={{ padding: 0, display: matches ? 'none' : 'block' }}>
                    <div className={classes.img}>
                        <Icon src={onlineAccess} height="100%" width="100%" />
                    </div>
                </Grid>
            </Grid>
            <LegacyUploadDocumentModal mainData={null} onClose={() => closeModal()} open={openModal} />
        </Grid>
    );
};

export default UploadedByMe;
