import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

const usePollingData = (key, fetcher, conditionCheck, initialInterval = 10000, maxAttempts = 10) => {
    const [isPolling, setIsPolling] = useState(true);
    const attemptCountRef = useRef(0);

    const { data } = useSWR(key, fetcher, {
        refreshInterval: isPolling ? initialInterval : 0,
    });

    useEffect(() => {
        if (!data) return;

        attemptCountRef.current += 1;

        const isConditionMet = conditionCheck(data);
        const reachedMaxAttempts = attemptCountRef.current >= maxAttempts;

        setIsPolling(!isConditionMet && !reachedMaxAttempts);
    }, [data, conditionCheck, maxAttempts]);

    return { data };
};

export default usePollingData;
