import { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import pencilIcon from '@/assets/images/pencilIcon.png';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import summaryMan from '@/assets/images/summaryProfessional.svg';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as AuthContext } from '@/context/AuthContext';
import { useStyles } from './SummaryStyles';
import Icon from '@/components/Icon/Icon';

export default function SummaryProfessionals({ mainData }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const {
        state: { user },
    } = useContext(AuthContext);
    const { nameOfDoctor } = useContext(ContextProviderTwo);

    console.debug('hello8', mainData, user);

    const {
        state: {},
        redirectFunctionSummary,
    } = useContext(HealthProfileContextMain);

    return (
        <>
            <div className={classes.contentContainerBody}>
                {/* summary portion professional */}
                <Grid container direction={'row'} style={{ height: '50%' }}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: !matches && 15 }}>
                        {!matches && <Icon src={summaryMan} height={48} width={48} description="professional icon" />}
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <div className={classes.flexClass}>
                            <img
                                src={pencilIcon}
                                alt=""
                                className={classes.pointer}
                                onClick={() => {
                                    redirectFunctionSummary(history, 0);
                                }}
                            />
                        </div>
                    </Grid>
                    {/* change */}
                    <Grid container direction={'row'}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading}>
                                {mainData.professional_title[0].text}
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading}>{nameOfDoctor}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
