import { useBookingStore, usePersistedPrismicStore } from '@/store';
import { makeStyles } from '@material-ui/core/styles';
import DropdownQuestion from '../CustomAnamnesis/QuestionTypes/DropdownQuestion';
import { createQuestionObj } from '@/utils/helpers';
import DatefieldQuestion from '../CustomAnamnesis/QuestionTypes/DatefieldQuestion';
import TextfieldQuestion, { TextValidation } from '../CustomAnamnesis/QuestionTypes/TextfieldQuestion';
import { useShallow } from 'zustand/react/shallow';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { isValidPhoneNumber } from 'libphonenumber-js';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { AntSwitch } from '../Common/AntSwitchCommon';
import PrivacyPolicyText from '../Common/PrivacyPolicyText';
import { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        position: 'relative',
    },
    questionGroupContainer: {
        display: 'flex',
        alignItems: 'start',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
    },
    consentContainer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
    },
    passwordRestriction: {
        fontSize: '0.875rem',
        fontFamily: 'MessinaSans-SemiBold',
    },
    passwordRestrictionsList: {
        margin: 0,
        padding: '0 1rem',
    },
    errorMessage: {
        color: '#da3030',
        fontSize: '0.75rem',
        marginTop: '0.5rem',
    },
}));

const Registration = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { benefits: prismicData, dataSet },
    } = usePersistedPrismicStore((state) => state.registerData);
    const {
        selectedGender,
        firstName,
        lastName,
        dob,
        email,
        phoneNumber,
        confirmPassword,
        password,
        isEmailInUse,
        previouslyTriedEmail,
        isConsentConfirmed,
    } = useBookingStore((state) => state.registrationFormData);
    const {
        loading,
        setSelectedGender,
        setDob,
        setFirstName,
        setLastName,
        setEmail,
        setPhoneNumber,
        setPassword,
        setConfirmPassword,
        setIsConsentConfirmed,
    } = useBookingStore(
        useShallow((state) => ({
            loading: state.loading,
            setSelectedGender: state.setSelectedGender,
            setDob: state.setDob,
            setFirstName: state.setFirstName,
            setLastName: state.setLastName,
            setEmail: state.setEmail,
            setPhoneNumber: state.setPhoneNumber,
            setPassword: state.setPassword,
            setConfirmPassword: state.setConfirmPassword,
            setIsConsentConfirmed: state.setIsConsentConfirmed,
        }))
    );

    const privacyPolicyContent = useMemo(() => {
        if (dataSet) {
            return [
                {
                    id: 1,
                    text: prismicData?.['opt-in_text'],
                    link: prismicData?.term_and_condition_one,
                    href: prismicData?.link_one,
                },
                {
                    id: 2,
                    text: prismicData?.opt_text_2,
                    link: prismicData?.term_and_condition_two,
                    href: prismicData?.link_two,
                },
                {
                    id: 3,
                    text: prismicData?.opt_text_3,
                    link: prismicData?.privacy_policy_link[0]?.data?.title[0]?.text,
                    href: prismicData?.privacy_policy_link[0]?.data?.path[0]?.text,
                },
            ];
        }
        return [];
    }, [dataSet, prismicData]);

    return (
        <div className={classes.container}>
            {loading && (
                <div className={classes.loadingOverlay}>
                    <EternoSpinner />
                </div>
            )}
            <div className={classes.questionGroupContainer}>
                <DropdownQuestion
                    question={createQuestionObj('', prismicData.gender_label, 'gender', {
                        dropdown_options: prismicData.gender_values
                            .filter(({ gender_display_value }) => gender_display_value[0].text !== '-')
                            .map(({ gender_display_value }) => ({
                                option: [{ text: gender_display_value[0].text }],
                            })),
                    })}
                    answer={selectedGender}
                    setAnswer={(value) => setSelectedGender(value)}
                    hideTitle
                    noMarginTop
                />
                <DatefieldQuestion
                    question={createQuestionObj('', prismicData.date_of_birth_label, 'date-of-birth')}
                    answer={dob}
                    setAnswer={(value) => setDob(value)}
                    hideTitle
                />
            </div>
            <div className={classes.questionGroupContainer}>
                <TextfieldQuestion
                    question={createQuestionObj('', prismicData.first_name_label, 'first-name')}
                    answer={firstName}
                    setAnswer={(value) => setFirstName(value)}
                    hideTitle
                />
                <TextfieldQuestion
                    question={createQuestionObj('', prismicData.last_name_label, 'last-name')}
                    answer={lastName}
                    setAnswer={(value) => setLastName(value)}
                    hideTitle
                />
            </div>
            <div className={classes.questionGroupContainer}>
                <div style={{ width: '100%' }}>
                    <TextfieldQuestion
                        question={createQuestionObj('', prismicData.email_label, 'email', {
                            textfield_validation: TextValidation.EMAIL,
                        })}
                        answer={email}
                        setAnswer={(value) => setEmail(value)}
                        hideTitle
                        customValidation={(value) => (isEmailInUse ? value !== previouslyTriedEmail : true)}
                    />
                    {isEmailInUse && email === previouslyTriedEmail && (
                        <Typography className={classes.errorMessage}>{prismicData.used_email}</Typography>
                    )}
                </div>
                {/* TODO replace with proper phone number input field*/}
                <TextfieldQuestion
                    question={createQuestionObj('', prismicData.mobile_number__optional_, 'phone-number')}
                    answer={phoneNumber}
                    setAnswer={(value) => setPhoneNumber(value)}
                    hideTitle
                    customValidation={(value) => isValidPhoneNumber(value, 'DE') && isMobilePhone(value, 'de-DE')}
                />
            </div>
            <div className={classes.questionGroupContainer}>
                <TextfieldQuestion
                    question={createQuestionObj('', prismicData.password_label, 'password')}
                    answer={password}
                    setAnswer={(value) => setPassword(value)}
                    hideTitle
                    type="password"
                    customValidation={(value) =>
                        isStrongPassword(value, {
                            minLength: 8,
                            minNumbers: 1,
                            minSymbols: 1,
                            minUppercase: 0,
                            minLowercase: 0,
                        })
                    }
                />
                <div style={{ width: '100%' }}>
                    <TextfieldQuestion
                        question={createQuestionObj('', prismicData.confirm_password_label, 'confirm-password')}
                        answer={confirmPassword}
                        setAnswer={(value) => setConfirmPassword(value)}
                        hideTitle
                        type="password"
                        customValidation={(value) => value === password}
                    />
                    {confirmPassword && confirmPassword !== password && (
                        <Typography className={classes.errorMessage}>{prismicData.same_password}</Typography>
                    )}
                </div>
            </div>
            {password.length > 0 && (
                <div>
                    <Typography className={classes.passwordRestriction}>
                        {prismicData.password_restrictions_heading}
                    </Typography>
                    <ul className={classes.passwordRestrictionsList}>
                        {prismicData.password_restrictions.map(({ password_restriction }) => (
                            <li key={password_restriction[0].text}>
                                <Typography className={classes.passwordRestriction}>
                                    {password_restriction[0].text}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className={classes.consentContainer}>
                <AntSwitch checked={isConsentConfirmed} onChange={() => setIsConsentConfirmed(!isConsentConfirmed)} />

                <PrivacyPolicyText
                    data={privacyPolicyContent}
                    seeLess={prismicData?.see_less_cta}
                    seeMore={prismicData?.see_more_cta}
                />
            </div>
        </div>
    );
};

export default Registration;
