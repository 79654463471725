import SearchBar from './SearchBar';
import SearchResults from './SearchResults';
import React from 'react';
import { useBookingStore, usePersistedPrismicStore, useSearchStore } from '@/store';
import BookingModal from '../BookingModal';
import EternoSpinner from '../EternoLogoSpinner/EternoSpinner';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useProfessionalMappings from '@/hooks/useProfessionalMappings';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh',
        fontFamily: 'MessinaSans-Regular',
    },
    title: {
        width: '100%',
        fontFamily: 'MessinaSans-Regular',
        marginBottom: '1rem',
        '& > p': {
            fontSize: '1.5rem',
        },
    },
}));

const SearchTool = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { mainData: bookAppointmentPrismicData, dataSet },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);
    const locations = useSearchStore((state) => state.locations);
    const prismicData = useSearchStore((state) => state.prismicData);
    const showBookingModal = useBookingStore((state) => state.showBookingModal);

    useProfessionalMappings();

    if (!prismicData || !locations || !dataSet) {
        return (
            <div className={classes.spinnerContainer}>
                <EternoSpinner />
            </div>
        );
    }

    return (
        <>
            <div className={classes.title}>
                <Typography>{bookAppointmentPrismicData.book_an_appointment[0].text}</Typography>
            </div>
            <SearchBar />
            <SearchResults />
            {showBookingModal && <BookingModal />}
        </>
    );
};

export default SearchTool;
