import React from 'react';
import { Typography } from '@material-ui/core';
import consentFormsStyles from '../ConsentFormsStyles';
import clsx from 'clsx';

const ListItem = ({ children, bold = false }) => {
    const classes = consentFormsStyles();
    return (
        <Typography variant="body1" className={clsx(classes.paragraph, bold ? classes.bold : null)}>
            {children}
        </Typography>
    );
};

export default ListItem;
