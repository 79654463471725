import { makeStyles } from '@material-ui/core';
import { getColorWithOpacity } from '@/utils/colorHelpers';

export const useStyles = makeStyles((theme) => ({
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
    },
    mainHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
    },
    heading: {
        ...theme.typography.HL1,
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        marginTop: 23,
    },
    selectItemCard: {
        backgroundColor: theme.palette.common.lightGray,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'inline-block',

        '&:hover': {
            backgroundColor: theme.palette.common.lightGray,
            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        },
        [theme.breakpoints.down('sm')]: {
            addingTop: 18,
            paddingBottom: 18,
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 25,
            paddingRight: 25,
        },
    },
    selectedCard: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    selectItemCardBorder: {
        boxShadow: `0 0 0 1px ${theme.palette.common.mediumBrown}`,
    },
    subHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        color: theme.palette.common.black,
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    allergyType: {
        marginTop: 40,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    flexImg: {
        display: 'flex',
    },
    flexClass: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'baseline',
        },
    },
    maginB: {
        marginTop: 20,
        marginBottom: 40,
    },
    graySection: {
        marginTop: 20,
        padding: 20,
        backgroundColor: theme.palette.common.lightBrown,
        borderRadius: 8,
    },
    icons: {
        marginRight: 30,
        cursor: 'pointer',
    },
    pointer: {
        cursor: 'pointer',
    },
    line: {
        marginTop: 40,
        marginBottom: 40,
        backgroundColor: theme.palette.common.lightBrown,
        height: 2,
        width: '100%',
    },
    BtncontentText: {
        textTransform: 'none',
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        color: theme.palette.common.darkGrey,
    },
    divider: { height: '2px', background: '#F5F5F5', border: 'none' },
    main: {
        transition: 'background .5s',
    },
    highlight: {
        background: getColorWithOpacity(theme.palette.common.secondary, 0.15),
        padding: 10,
        marginBlock: 10,
    },
    formControl: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.3em',
        // marginBottom: 15,
        justifyContent: 'space-between',
        // [theme.breakpoints.down("md")]: {
        //   width: 150,
        // },
        // [theme.breakpoints.down("sm")]: {
        //   width: 100,
        // },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 15,
        },
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
}));
