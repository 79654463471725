import Item from '../Common/StepMenu/MenuItem';
import CardContainer from '../Common/CardContainer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useCustomAnamnesisStore } from '@/store';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, Menu, Typography } from '@material-ui/core';
import clsx from 'clsx';
import CircularProgressBar from '../Common/StepMenu/CircularProgressBar';
import sideBarArrow from '@/assets/images/sideBarArrow.png';
import sideBarArrowUp from '@/assets/images/sideBarArrowUp.png';
import { menuPropsStyles } from '../Common/StepMenu/StepMenuStyles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    sideMenu: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    formControl: {
        backgroundColor: 'white',
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        paddingTop: 13,
        paddingBottom: 13,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    classRoot: {
        minWidth: 0,
        paddingLeft: 13,
        paddingRight: 13,
        borderTopLeftRadius: 4,
        borderEndEndRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
    },
    borderLeftNone: {
        borderBottomLeftRadius: 0,
    },
    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        textTransform: 'none',
        marginBottom: 0,
        fontWeight: 600,
        marginLeft: 10,
        whiteSpace: 'nowrap',
    },
}));

const StepsCard = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const anamnesisTemplate = useCustomAnamnesisStore((state) => state.anamnesisTemplate);
    const currentCategory = useCustomAnamnesisStore((state) => state.currentCategory);
    const completedCategories = useCustomAnamnesisStore((state) => state.completedCategories);
    const [anchorE1, setAnchorE1] = useState(null);
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');

    const handleClick = (event) => {
        const mobileBtn = document.getElementById('mobileCodeButton').clientWidth;
        setMobileCodeWidth(mobileBtn.toString());
        setAnchorE1(event.currentTarget);
    };

    const handleClose = () => setAnchorE1(null);
    const vertical = (position) => ({ vertical: position });

    if (matchesSM) {
        return (
            <>
                <Button
                    id="mobileCodeButton"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    disableRipple
                    className={classes.formControl}
                    classes={{
                        label: classes.menuLabel,
                        root: clsx(classes.classRoot, Boolean(anchorE1) ? classes.borderLeftNone : null),
                    }}
                >
                    <div className={classes.loadingProgress}>
                        <CircularProgressBar
                            value={(currentCategory.position * 100) / anamnesisTemplate?.content.categories.length}
                        />
                    </div>
                    <Typography className={classes.shortText} id="main_">
                        {
                            anamnesisTemplate.content.categories.find(
                                (category) => category.position === currentCategory.position
                            )?.[`name_${i18n.language}`]
                        }
                    </Typography>
                    <img src={anchorE1 === null ? sideBarArrow : sideBarArrowUp} alt="arrow-icon" />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorE1}
                    keepMounted
                    open={Boolean(anchorE1)}
                    onClose={handleClose}
                    anchorOrigin={vertical('bottom')}
                    transformOrigin={vertical('top')}
                    className={classes.mobileMenu}
                    PaperProps={{
                        style: {
                            width: parseFloat(mobileCodeWidth),
                            ...menuPropsStyles,
                            padding: '0.5rem 1rem',
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    {anamnesisTemplate?.content.categories.map((category) => (
                        <Item
                            key={category.position}
                            highlight={currentCategory.position === category.position}
                            showIcon={completedCategories[category.position]}
                        >
                            {category[`name_${i18n.language}`]}
                        </Item>
                    ))}
                </Menu>
            </>
        );
    }

    return (
        <CardContainer classNames={[classes.sideMenu]}>
            {anamnesisTemplate?.content.categories.map((category) => (
                <Item
                    key={category.position}
                    highlight={currentCategory.position === category.position}
                    showIcon={completedCategories[category.position]}
                >
                    {category[`name_${i18n.language}`]}
                </Item>
            ))}
        </CardContainer>
    );
};

export default StepsCard;
