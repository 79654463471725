import createDataContext from './createDataContext';
import { Auth } from 'aws-amplify';
import { processAPIWithHeaders } from '@/utils/helpers';

const initialState = {
    resetDataMainData: false,
    resetDataActice: null,
    email: '',
    loading: false,
    error: null,
    resetVerficationCode: '',
    passwordValid: {
        characters: false,
        number: false,
        special: false,
        password: '',
    },
    confirmPaswd: '',
};

const resetPassword = (state, action) => {
    switch (action.type) {
        case 'set_reset_data_main_data':
            return {
                ...state,
                resetDataMainData: action.payload,
            };
        case 'set_reset_data_active':
            return {
                ...state,
                resetDataActice: action.payload,
            };
        case 'set_email_field':
            return {
                ...state,
                email: action.payload,
            };
        case 'set_loading':
            return {
                ...state,
                loading: action.payload,
            };
        case 'set_error':
            console.debug('set_error', action.payload);
            return {
                ...state,
                error: action.payload,
            };
        case 'reset':
            return {
                ...state,
                initialState,
            };
        case 'set_reset_verification_code':
            return {
                ...state,
                resetVerficationCode: action.payload,
            };
        case 'set_password_valid':
            return {
                ...state,
                passwordValid: action.payload,
            };
        case 'set_confirm_password':
            return {
                ...state,
                confirmPaswd: action.payload,
            };
        default:
            return state;
    }
};

const setResetDataMainData = (dispatch) => async (open) => {
    dispatch({
        type: 'set_reset_data_main_data',
        payload: open,
    });
};

const setResetDataActive = (dispatch) => async (item) => {
    dispatch({
        type: 'set_reset_data_active',
        payload: item,
    });
};

const sendResetEmail = (dispatch) => async (email, history) => {
    dispatch({
        type: 'set_loading',
        payload: true,
    });

    Auth.forgotPassword(email)
        .then((data) => {
            dispatch({
                type: 'set_loading',
                payload: false,
            });
            history.push('/reset-password?step=verify-code');
        })
        .catch((err) => {
            dispatch({
                type: 'set_loading',
                payload: false,
            });
            // console.debug("Username/client: ",err.code)
            dispatch({
                type: 'set_error',
                payload: err.code,
            });
        });
};

const setEmailField = (dispatch) => async (email) => {
    dispatch({
        type: 'set_email_field',
        payload: email,
    });
};

const setLoading = (dispatch) => async (loading) => {
    dispatch({
        type: 'set_loading',
        payload: loading,
    });
};

const setError = (dispatch) => async (error) => {
    dispatch({
        type: 'set_error',
        payload: error,
    });
};
// verification work

const setResetVerficationCode = (dispatch) => async (data) => {
    dispatch({
        type: 'set_reset_verification_code',
        payload: data,
    });
};

const resetVerfication = (dispatch) => async (history) => {
    dispatch({
        type: 'set_loading',
        payload: true,
    });

    dispatch({
        type: 'set_loading',
        payload: false,
    });
    history.push('/reset-password?step=set-new-password');
};

// chose new password

const setPasswordValid = (dispatch) => async (data) => {
    dispatch({
        type: 'set_password_valid',
        payload: data,
    });
};
// confirmPaswd ,
// setConfirmPassword ,

const setConfirmPassword = (dispatch) => async (data) => {
    dispatch({
        type: 'set_confirm_password',
        payload: data,
    });
};

const passwordSame =
    (dispatch) => async (history, e, resetVerficationCode, confirmPaswd, setuser, setusersession, setuseraccounts) => {
        dispatch({
            type: 'set_loading',
            payload: true,
        });
        Auth.forgotPasswordSubmit(e, resetVerficationCode, confirmPaswd)
            .then((data) => {
                dispatch({
                    type: 'set_loading',
                    payload: false,
                });
                // history.push("/reset-password?step=reset-completed");
                //  login
                signIn(e, confirmPaswd, dispatch, history, setuser, setusersession, setuseraccounts);
                // login
            })
            .catch((err) => {
                dispatch({
                    type: 'set_loading',
                    payload: false,
                });
                console.debug(err);
                dispatch({
                    type: 'set_error',
                    payload: err.message,
                });
            });
    };

// login
async function signIn(e, confirmPaswd, dispatch, history, setuser, setusersession, setuseraccounts) {
    console.debug('signing in: reset password');
    try {
        dispatch({
            type: 'set_loading',
            payload: true,
        });
        const user = await Auth.signIn(e, confirmPaswd);
        console.debug('USer32', user);

        const username = user.username;
        const token = user.signInUserSession.idToken.jwtToken;
        const userData = user.attributes;
        //
        const response = await processAPIWithHeaders('secure/users/' + username, 'GET', {
            headers: {
                //"Content-Type": "application/json",
                Authorization: token,
            },
        });
        console.debug('USer32: response32', response);

        const userAccounts = await processAPIWithHeaders('secure/user/accounts/' + username, 'GET', {
            headers: {
                //"Content-Type": "application/json",
                Authorization: token,
            },
        });
        console.debug(userAccounts);
        setuser(response);
        setuseraccounts(userAccounts);
        setusersession(user.signInUserSession);
        dispatch({
            type: 'set_loading',
            payload: false,
        });
        history.push('/reset-password?step=reset-completed');
    } catch (error) {
        dispatch({
            type: 'set_loading',
            payload: false,
        });
        dispatch({
            type: 'set_error',
            payload: error.message,
        });
        // if (error.code === "UserNotConfirmedException") {
        //   await Auth.resendSignUp(e);
        //   setVerifyAccount(true);
        // } else {

        // }

        console.debug('error signing in', error);
    }
}

// login end
export const { Provider, Context } = createDataContext(
    resetPassword,
    {
        setResetDataMainData,
        setResetDataActive,
        sendResetEmail,
        setEmailField,
        setLoading,
        setError,
        setResetVerficationCode,
        resetVerfication,
        setPasswordValid,
        setConfirmPassword,
        passwordSame,
    },
    initialState
);
