import { useCustomAnamnesisStore } from '@/store';
import { useEffect, useRef } from 'react';

export const useScrollToQuestion = (question) => {
    const highlightedQuestionForScrolling = useCustomAnamnesisStore((state) => state.highlightedQuestionForScrolling);
    const questionRef = useRef();

    useEffect(() => {
        if (highlightedQuestionForScrolling?.question !== question.question_key[0].text || !questionRef.current) return;

        questionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [highlightedQuestionForScrolling]);

    return questionRef;
};
