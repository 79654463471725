import { memo } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { dayjs } from '@/utils/dayjsSetup';
import { useStyles } from '../DateOfBirh/MainDateStyles';

const DateOnlyYearField = ({ setDOB, dob, setError, error, errorMessage, placeHolderYear, mainHeading }) => {
    const classes = useStyles();

    const checkDateFormat = (event) => {
        let text = event.target.value;

        var year = dayjs(text, 'YYYY'); // year in your date
        var currentYearObj = dayjs(new Date(), 'YYYY'); // current year object
        var currentYear = dayjs().year(); // current year

        var yearCountDiff = currentYearObj.diff(year, 'year');

        if (text.length === 5) {
            return;
        }

        setDOB(text);

        if (text.length === 4) {
            setError(false);
            console.debug('renderrrr');
            if (text < 1900 || yearCountDiff < 0 || text > currentYear) {
                setError(true);
            }
        }
    };

    const validateForm = () => {
        if (dob.length >= 5) {
            setError(true);
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        autoComplete="off"
                        label={mainHeading}
                        type="number"
                        value={dob.replace(/[a-z||A-Z]/, '')}
                        onChange={checkDateFormat}
                        variant="filled"
                        color="primary"
                        fullWidth
                        onBlur={() => validateForm()}
                        placeholder={placeHolderYear}
                        classes={{
                            root: error ? classes.textFieldsError : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                </Grid>
            </Grid>
            {error && (
                <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                    {errorMessage}
                </Typography>
            )}
        </>
    );
};

export default memo(DateOnlyYearField);
