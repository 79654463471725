import createDataContext from './createDataContext';

const healthProfileOverviewReducer = (state, action) => {
    switch (action.type) {
        case 'set_main_data':
            console.debug('main_overview123', action.payload);
            return { ...state, mainData: action.payload };
        case 'set_data_set':
            return { ...state, dataSet: action.payload };
        case 'set_menu_data':
            return { ...state, menuData: action.payload };
        case 'set_menu_open':
            return { ...state, menuOpen: action.payload };
        case 'set_all_data':
            return { ...state, allData: action.payload };
        case 'set_load_all_data':
            return { ...state, loadAllData: action.payload };
        case 'set_landing_page':
            return { ...state, landingPage: action.payload };
        default:
            return state;
    }
};

// Actions
const setMainData = (dispatch) => (data) => {
    dispatch({
        type: 'set_main_data',
        payload: data,
    });
};

const setDataSet = (dispatch) => (data) => {
    dispatch({
        type: 'set_data_set',
        payload: data,
    });
};

const setMenuData = (dispatch) => (data) => {
    dispatch({
        type: 'set_menu_data',
        payload: data,
    });
};

const setAllData = (dispatch) => (data) => {
    dispatch({
        type: 'set_all_data',
        payload: data,
    });
};

const setMenuOpen = (dispatch) => (data) => {
    dispatch({
        type: 'set_menu_open',
        payload: data,
    });
};

const setLoadAllData = (dispatch) => (data) => {
    dispatch({
        type: 'set_load_all_data',
        payload: data,
    });
};

const setLandingPage = (dispatch) => (data) => {
    dispatch({
        type: 'set_landing_page',
        payload: data,
    });
};

export const { Provider, Context } = createDataContext(
    // Reducer
    healthProfileOverviewReducer,
    {
        // Actions
        setLandingPage,
        setMainData,
        setDataSet,
        setMenuData,
        setMenuOpen,
        setAllData,
        setLoadAllData,
    },
    {
        // Initial State
        mainData: {},
        dataSet: false,
        menuData: {},
        menuOpen: false,
        allData: null,
        loadAllData: false,
        landingPage: false,
    }
);
