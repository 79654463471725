import { createContext, useState } from 'react';
import { processAPIWithHeaders } from '@/utils/helpers';
import { useConfigStore } from '@/store';

export const ContextProvider = createContext();

export const AuthContext = (props) => {
    const [step, setStep] = useState('1');
    const [insuranceType, setInsuranceType] = useState('');
    const [insuranceTypeContext, setInsuranceTypeContext] = useState('');
    const [activeHub, setActiveHub] = useState();
    const [appointmentSearch, setAppointmentSearch] = useState(null);
    const [calendarDataAppointmentKey, setCalendarDataAppointmentKey] = useState(null);
    const [calendarDataInsurance, setCalendarDataInsurance] = useState(null);
    const [myAppointmentsData, setMyAppointmentsData] = useState(null);
    const [myAppointmentsDataStatus, setMyAppointmentsDataStatus] = useState(false);
    const [dateSlot, setDateSlot] = useState();
    const [calenderCanceldData, setCalenderCanceldData] = useState(null);
    const [calenderCanceldDataActive, setCalenderCanceldDataActive] = useState(false);
    const [count, setCount] = useState(0);
    const [cancelData, setCancelData] = useState(null);
    const [activeResponseDataCon, setActiveResponseDataCon] = useState(null);
    const [currDoc, setCurrDoc] = useState([]);
    const [typeId, setTypeId] = useState('');
    const [professionalProfiles, setProfessionalProfiles] = useState({
        'en-us': [],
        'de-de': [],
    });
    const [languageGlobal, setLanguageGlobal] = useState('');

    const updateStep = (s) => {
        setStep(s);
    };

    const fetchAvailableDoctors = async (data) => {
        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;
        const response = await processAPIWithHeaders('public/users/availability/search?', 'GET', {
            body: data,
            headers: {
                'Content-Type': 'application/json',
                customer_id,
            },
        });

        console.debug(response);
    };

    const [stepsList, setStepsList] = useState([]);
    const [mainDataArray, setMainDataArray] = useState(null);
    const [mainDataActive, setMainDataActive] = useState(false);
    const [dataSetDoctosCon, setDataSetDoctosCon] = useState(false);
    const [mainDatadoctosCon, setMainDatadoctosCon] = useState(null);
    const [activeResp, setActiveResp] = useState([]);

    const updateActiveData = (a) => {
        if (a) {
            let mapping = activeResponseDataCon?.active.filter((fil) => fil.id !== a.id);
            console.debug('activeResponseData: calenderCanceldDataActive', mapping);
        }
    };

    const [isDoctorsPage, setIsDoctorsPage] = useState(false);
    const [loadingIndecator, setLoadingIndecator] = useState(false);

    const [imgAccount, setImgAccount] = useState(null);
    const [appointmentSearchConfirmation, setAppointmentSearchConfirmation] = useState(null);
    const [appointmentSelectionConfirmation, setAppointmentSelectionConfirmation] = useState(null);
    const [doctorConfirmation, setDoctorConfirmation] = useState(null);
    const [verificationLoading, setVerificationLoading] = useState(false);
    const [forBackState, setForBackState] = useState('');
    // logic for find an appointment login
    const [openLogin, setOpenLogin] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState({
        title: '',
        text: '',
    });
    const [onceRunState, setOnceRunState] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [sideBarMainData, setSideBarMainData] = useState({});
    return (
        <ContextProvider.Provider
            value={{
                typeId,
                setTypeId,
                currDoc,
                setCurrDoc,
                setError,
                error,
                open,
                setOpen,
                setOpenLogin,
                openLogin,
                onceRunState,
                setOnceRunState,
                setForBackState,
                forBackState,
                updateStep,
                step,
                insuranceType,
                setInsuranceType,
                insuranceTypeContext,
                setInsuranceTypeContext,
                steps: [
                    'Location',
                    'Profesional',
                    'Type of insurance',
                    'appointment costs',
                    'first appointment vs recurrent',
                    'prescription',
                    'Treatment',
                    'Symptoms',
                    'Group Appointments',
                    'number of appointments',
                ],
                activeHub,
                setActiveHub,
                professionalProfiles,
                setProfessionalProfiles,
                appointmentSearch,
                setAppointmentSearch,
                fetchAvailableDoctors,
                setStepsList,
                stepsList,
                setLanguageGlobal,
                languageGlobal,
                setCalendarDataAppointmentKey,
                calendarDataAppointmentKey,
                setCalendarDataInsurance,
                calendarDataInsurance,
                setMyAppointmentsData,
                myAppointmentsData,
                setMyAppointmentsDataStatus,
                myAppointmentsDataStatus,
                setDateSlot,
                dateSlot,
                setCalenderCanceldData,
                calenderCanceldData,
                setCalenderCanceldDataActive,
                calenderCanceldDataActive,
                count,
                setCount,
                mainDataArray,
                setMainDataArray,
                setMainDataActive,
                mainDataActive,
                setCancelData,
                cancelData,
                setActiveResponseDataCon,
                activeResponseDataCon,
                setDataSetDoctosCon,
                setMainDatadoctosCon,
                mainDatadoctosCon,
                dataSetDoctosCon,
                updateActiveData,
                setActiveResp,
                activeResp,
                setIsDoctorsPage,
                isDoctorsPage,
                setLoadingIndecator,
                loadingIndecator,
                setImgAccount,
                imgAccount,
                setAppointmentSearchConfirmation,
                appointmentSearchConfirmation,
                setAppointmentSelectionConfirmation,
                appointmentSelectionConfirmation,
                setDoctorConfirmation,
                doctorConfirmation,
                setVerificationLoading,
                verificationLoading,
                selectedIndex,
                setSelectedIndex,
                sideBarMainData,
                setSideBarMainData,
            }}
        >
            {props.children}
        </ContextProvider.Provider>
    );
};
