import { forwardRef, useCallback, useContext, useState } from 'react';

/** Icons */
import restartIcon from '@/assets/images/restartIcon.svg';

/** Signature Canvas */
import ReactSignatureCanvas from 'react-signature-canvas';
import './SignaturePad.css';

/** Styles */
import consentFormsStyles from '../../ConsentFormsStyles';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { useTheme } from '@material-ui/core/styles';

const buttonProps = {
    disableElevation: true,
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
    color: 'primary',
};

const SignaturePad = forwardRef((props, signCanvas) => {
    const classes = consentFormsStyles();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const [started, setStarted] = useState(false);

    /** Context */
    const { consentFormsContent: content } = useContext(ConsentFormsContext);

    /** Clear Signature */
    const clearSignature = () => {
        signCanvas?.current?.clear();
        setStarted(false);
    };

    /** Get Signature */
    const getSignature = () =>
        console.debug('signCanvas', signCanvas.current.getTrimmedCanvas().toDataURL('image/png'));

    const renderMobilePad = useCallback(() => {
        if (started)
            return (
                <Button
                    startIcon={<img src={restartIcon} />}
                    className={classes.restartBtn}
                    onClick={clearSignature}
                    {...buttonProps}
                >
                    {content ? content.buttons.startOver : 'Start over'}
                </Button>
            );
        return (
            <Typography className={clsx(classes.paragraph, classes.textUnSelect)}>
                {content ? content.signaturePopup.ownSignField : 'Draw inside the grey box'}
            </Typography>
        );
    }, [started]);

    return (
        <div className={matchesXs ? classes.mx10 : null}>
            <div className={clsx(classes.signCanvasHeader, matchesXs ? classes.mt10 : null)}>
                {matchesXs ? (
                    renderMobilePad()
                ) : (
                    <>
                        <Typography className={clsx(classes.paragraph, classes.textUnSelect)}>
                            {content ? content.signaturePopup.ownSignField : 'Draw inside the grey box'}
                        </Typography>
                        <Button
                            startIcon={<img src={restartIcon} />}
                            className={classes.restartBtn}
                            onClick={clearSignature}
                            {...buttonProps}
                        >
                            {content ? content.buttons.startOver : 'Start over'}
                        </Button>
                    </>
                )}
            </div>
            <ReactSignatureCanvas
                ref={signCanvas}
                onBegin={() => setStarted(true)}
                canvasProps={{ className: 'sigCanvas' }}
            />
        </div>
    );
});

export default SignaturePad;
