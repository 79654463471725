import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    detailsButton: {
        background: 'transparent',
        padding: 0,
        minWidth: ({ noPresetWidth }) => (noPresetWidth ? 'unset' : '64px'),
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    buttonLabel: {
        padding: 0,
        justifyContent: 'left',
        width: ({ noPresetWidth }) => (noPresetWidth ? 'unset' : '100%'),
    },
    buttonLabelN: {
        padding: 0,
        justifyContent: 'center',
    },
    detailsLinks: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        textDecorationLine: 'underline',

        textDecorationThickness: 2,

        color: theme.palette.common.darkGrey,
        marginTop: 30,
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    detailsLinksNoMarginTop: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        textDecorationLine: 'underline',

        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    disabled: {
        opacity: 0.5,
    },
    fontOverride: {
        fontSize: ({ fontOverride }) => fontOverride,
    },
}));

export default function UnderlinedButton({
    text,
    color,
    onPress,
    fullWidth,
    noMarginTop,
    marginBottom,
    marginLeft,
    marginRight,
    noJSLeft = false,
    underlineColor,
    textTransformNone = false,
    disabled = false,
    classNameProp = null,
    icon = null,
    noPresetWidth = false,
    fontOverride = '',
}) {
    const { t } = useTranslation();
    const classes = useStyles({ noPresetWidth, fontOverride });
    const theme = useTheme();

    underlineColor = underlineColor ?? theme.palette.common.yellow;

    return (
        <Button
            disabled={disabled}
            disableElevation
            disableRipple
            fullWidth={fullWidth === true}
            classes={{
                root: classes.detailsButton,
                label: noJSLeft ? classes.buttonLabelN : classes.buttonLabel,
            }}
            onClick={() => {
                onPress();
            }}
            className={disabled && classes.disabled}
        >
            <Typography
                className={clsx(
                    noMarginTop ? classes.detailsLinksNoMarginTop : classes.detailsLinks,
                    classNameProp,
                    fontOverride && classes.fontOverride
                )}
                style={{
                    color: color,
                    marginBottom: marginBottom ? marginBottom : null,
                    textDecorationColor: underlineColor,
                    textUnderlineOffset: '0.25em',
                    marginLeft: marginLeft ? marginLeft : null,
                    marginRight: marginRight ? marginRight : null,
                    marginTop: !noMarginTop && 20,
                    textTransform: textTransformNone ? 'none' : null,
                }}
            >
                {text}
                {icon && (
                    <span
                        style={{
                            borderBottom: `2px solid ${underlineColor}`,
                            paddingLeft: '5px',
                        }}
                    >
                        <img src={icon} alt="icon" />
                    </span>
                )}
            </Typography>
        </Button>
    );
}
