import { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ConfirmClasses';
import OutlinedButton from '@/components/OutlinedButton';
import { ContextProvider } from '@/context/ProjectContext';
import { useHistory } from 'react-router-dom';
import { usePersistedPrismicStore } from '@/store';

const ImageGridNew = ({ confirmedDoctor, dataSet, mainData }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const { setMyAppointmentsData, setMyAppointmentsDataStatus, setIsDoctorsPage } = useContext(ContextProvider);
    const { [i18n.language]: findAppointmentPrismicData } = usePersistedPrismicStore(
        (state) => state.findAppointmentData
    );
    const { professionalProfiles } = findAppointmentPrismicData;
    const forDoctorDetails = (doctor) => {
        let uniqueKey = doctor.key[0].text;
        let data = [];
        let dataArr = {
            'en-us': [],
            'de-de': [],
        };

        const foundProfile = professionalProfiles.find((data) => data.key[0].text === uniqueKey);
        data['en-us'] = foundProfile;
        data['de-de'] = foundProfile;
        console.debug('myDoctorID : data book', data);

        setMyAppointmentsData(data);
        setMyAppointmentsDataStatus(dataSet);
        setIsDoctorsPage(true);
        // setIsDoctorsPage(false);
        history.push('/professional/' + uniqueKey);
    };
    console.debug('professionalProfiles32:', professionalProfiles, confirmedDoctor);

    return (
        <div className={classes.cardContentClasses}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',

                    marginTop: 0,
                }}
            >
                <img
                    src={confirmedDoctor.profile_picture.url}
                    className={classes.doctorImg}
                    alt={confirmedDoctor.profile_picture.alt}
                />

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography className={classes.classTitle}>{confirmedDoctor.display_name[0].text}</Typography>

                    <Typography className={classes.classTeacher} style={{ marginBottom: 10 }}>
                        {confirmedDoctor.professionName}
                    </Typography>
                </div>
                <div className={classes.images}>
                    {confirmedDoctor.languages.map((lang) => (
                        <img
                            src={`https://flagcdn.com/w40/${lang.languages1 === 'en' ? 'gb' : lang.languages1}.png`}
                            alt={lang.languages1}
                            style={{ height: 20, marginRight: 15 }}
                        />
                    ))}
                </div>
                <Typography className={classes.classTeacher} style={{ marginBottom: 10, textAlign: 'center' }}>
                    {confirmedDoctor.practice_name[0].text}
                </Typography>
                <div
                    style={{
                        width: '-webkit-fill-available',
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                    <OutlinedButton
                        text={dataSet ? mainData.see_profile_cta[0].text : t('SeeProfile')}
                        onPress={() => {
                            forDoctorDetails(confirmedDoctor);
                        }}
                        fullWidth
                    />
                </div>
            </div>
        </div>
    );
};

export default ImageGridNew;
