import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FilledButton from '../FilledButton';
import UnderlinedButton from '../UnderlinedButton';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        backgroundColor: 'transparent',
    },
    scrollRoot: {
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    text: {
        ...theme.typography.content,
    },
    title: {
        paddingLeft: 0,
        paddingRight: 0,
        ...theme.typography.HL1,
        paddingTop: 0,
    },
    detailsButton: {
        background: 'transparent',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    buttonLabel: {
        padding: 0,
    },
    detailsLinks: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        textDecorationLine: 'underline',

        textDecorationThickness: 2,

        color: theme.palette.common.darkGrey,
        marginTop: 20,
    },
    detailsLinksNoMarginTop: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        textDecorationLine: 'underline',

        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',

        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
}));

const CookieConstant = ({ open, onClose, setLargeCookie, mainData, rejectCookies, acceptCookies }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesmd = useMediaQuery(theme.breakpoints.down('md'));
    const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesxl = useMediaQuery(theme.breakpoints.down('xl'));

    const closeAndOpen = () => {
        setLargeCookie(true);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    root: classes.dialogRoot,
                    scrollPaper: classes.scrollRoot,
                }}
                PaperProps={{
                    style: {
                        backgroundColor: 'white',
                        minHeight: 250,
                        width: '100%',
                        borderRadius: 8,

                        paddingLeft: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                        paddingRight: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                        paddingTop: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                        paddingBottom: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                    },
                }}
            >
                <DialogTitle
                    id="alert-dialog-title"
                    classes={{
                        root: classes.title,
                    }}
                >
                    {mainData?.title[0].text}
                </DialogTitle>
                <DialogContent
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        paddingBottom: 20,
                        paddingLeft: 0,
                        paddingRight: 0,
                        padding: 0,
                    }}
                >
                    <DialogContentText
                        id="alert-dialog-description"
                        classes={{
                            root: classes.text,
                        }}
                    >
                        {mainData?.desciption[0].text}
                        <a
                            className={classes.contentTextLink}
                            href={mainData?.privacy_policy_link_[0].data.path[0].text}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            {mainData?.privacy_policy_link_[0].data.title[0].text}
                        </a>
                    </DialogContentText>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: matches ? 'column' : 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <FilledButton
                            text={mainData?.allow_all_cookies_cta[0].text}
                            onPress={() => {
                                acceptCookies(true, true);
                            }}
                            fullWidth={matches && true}
                        />

                        <div
                            style={{
                                display: !matches ? 'flex' : 'contents',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <UnderlinedButton
                                text={mainData?.reject_all_cta[0].text}
                                onPress={() => {
                                    rejectCookies();
                                }}
                                noMarginTop={!matches && true}
                                marginLeft={!matches && 30}
                                fullWidth={matches && true}
                                noJSLeft={true}
                            />
                            <UnderlinedButton
                                text={mainData?.manage_cookies[0].text}
                                onPress={() => closeAndOpen()}
                                noMarginTop={!matches && true}
                                marginLeft={!matches && 30}
                                fullWidth={matches && true}
                                noJSLeft={true}
                            />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CookieConstant;
