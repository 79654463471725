import { Button, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './LifeStyleStyles';

const LifeStyleSmoking = ({ mainData, isFromHealthData }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const { smoke, smokePerDay, smokeHowLong, setValue_, setSmoke, setSmokePerDay, setSmokeHowLong } =
        useContext(ContextProviderTwo);

    return (
        <div className="" style={{ marginTop: isFromHealthData ? smoke && (matches ? 20 : 30) : 30 }}>
            <Typography className={classes.mainHeading}>{mainData.smoke[0].text}</Typography>
            <Grid
                style={{
                    marginTop: 20,
                }}
                container
            >
                {mainData &&
                    mainData.smoke_yes_no_reponses.map((parts) => (
                        <Grid lg={'auto'} sm={'auto'} xs={6}>
                            <Button
                                onClick={() => {
                                    console.debug(parts.smoke_yes_no_response_api_value);
                                    setSmoke(parts.smoke_yes_no_response_api_value);
                                }}
                                disableRipple
                                className={
                                    smoke === parts.smoke_yes_no_response_api_value
                                        ? clsx(classes.selectItemCard, classes.selectedCard)
                                        : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                }
                                style={{
                                    marginBottom: matchesMD && 15,
                                    marginRight: !matches && 15,
                                    width: matches && '90%',
                                    display: matches ? null : 'flex',
                                    // height: "100%",
                                }}
                            >
                                <div className="">
                                    <Typography className={classes.BtncontentText}>
                                        {parts.smoke_yes_no_response[0].text}
                                    </Typography>
                                </div>
                            </Button>
                        </Grid>
                    ))}
            </Grid>

            {/* optional */}
            {smoke.toLocaleLowerCase() === 'yes' && (
                <div
                    className=""
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: matches ? 'column' : 'row',
                        gap: 12,
                        marginTop: !matchesMD && 20,
                    }}
                >
                    <TextField
                        id="lifeStyleSmokeHowMany"
                        label={mainData ? mainData.smoke_subquestion_how_many[0].text : t('TransferSlip')}
                        variant="filled"
                        autoComplete="off"
                        color="primary"
                        value={smokePerDay.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '')}
                        onChange={(event) => {
                            setSmokePerDay(event.target.value);
                        }}
                        classes={{
                            root: classes.textFields,
                        }}
                        style={{
                            width: matches ? '100%' : '40%',
                            flex: matches ? 1 : 0.5,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />

                    <TextField
                        id="lifeStyleSmokeHowLong"
                        label={mainData ? mainData.smoke_subquestion_how_log[0].text : t('TransferSlip')}
                        variant="filled"
                        autoComplete="off"
                        color="primary"
                        value={smokeHowLong.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '')}
                        onChange={(event) => {
                            setSmokeHowLong(event.target.value);
                        }}
                        classes={{
                            root: classes.textFields,
                        }}
                        style={{
                            flex: matches ? 1 : 0.5,
                            width: matches ? '100%' : '40%',
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default LifeStyleSmoking;
