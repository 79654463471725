import { useCallback } from 'react';
//** MUI */
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
//** OTHERS */
import pencilIcon from '@/assets/images/pencilIcon.png';
import { signTypes } from '@/context/ConsentFormsContext';
import consentFormsStyles from '../ConsentFormsStyles';

const SignatureView = ({
    text = '',
    editLabel = '',
    signature = { type: signTypes.TEXT, src: '' },
    openPopup = () => {},
}) => {
    const classes = consentFormsStyles(160);

    const renderSignature = useCallback(() => {
        if (signature.type === signTypes.TEXT) {
            return (
                <Typography variant="h1" className={classes.digitalSignature}>
                    {signature.src}
                </Typography>
            );
        }
        return <img src={signature.src} height={160} className={classes.mt20} />;
    }, [signature]);

    const handleEdit = () => openPopup();

    return (
        <Box className={[classes.signContainer, classes.mt30]}>
            <Box className={[classes.dFlex, classes.contentBetween]}>
                <Typography className={classes.paragraph}>{text || 'Hamburg, 5 Januar 2023'}</Typography>
                <IconButton onClick={handleEdit} className={classes.restartBtn} disableRipple>
                    <Box className={[classes.dFlex, classes.gap10]}>
                        <img src={pencilIcon} className={classes.editIcon} />
                        {editLabel || 'Edit'}
                    </Box>
                </IconButton>
            </Box>
            <Box className={[classes.height, classes.dFlex, classes.contentCenter, classes.itemsCenter]}>
                {renderSignature()}
            </Box>
        </Box>
    );
};

export default SignatureView;
