import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: ({ width }) => width,
        height: ({ height }) => height,
        backgroundColor: ({ color }) => color ?? theme.palette.common.secondary,
        maskImage: ({ src }) => `url(${src})`,
        WebkitMaskImage: ({ src }) => `url(${src})`,
        maskPosition: 'center',
        maskSize: 'contain',
        maskRepeat: 'no-repeat',
        display: 'inline-block',
        aspectRatio: ({ aspectRatio }) => aspectRatio,
    },
}));
const Icon = ({ src, color, description, width = 50, height = 50, aspectRatio }) => {
    const classes = useStyles({ src, color, width, height, aspectRatio });

    return <span className={classes.icon} role="img" aria-label={description}></span>;
};

export default Icon;
