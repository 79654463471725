import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    mainPage: {
        backgroundColor: theme.palette.common.white,
        paddingInline: 30,
        paddingBlock: 20,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
    },
}));

/**
 *
 * @param {*} Classnames as String:Array [class1,class2]
 * @param {*} Rest Props
 */
const CardContainer = ({ children, classNames = [], ...props }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.mainPage, ...classNames)} {...props}>
            {children}
        </div>
    );
};

export default CardContainer;
