import { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../Login/LoginClasses';
import FilledButton from '@/components/FilledButton';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import UnderlinedButton from '@/components/UnderlinedButton';
import { Context as ResetContext } from '@/context/ResetPaswdContext';
import isEmail from 'validator/es/lib/isEmail';

export default function ResetPage({ dataSet, mainData }) {
    const classes = useStyles();
    const history = useHistory();
    const {
        state: { email, loading, error },
        setEmailField,
        sendResetEmail,
        setLoading,
        setError,
    } = useContext(ResetContext);
    const [errEmail, setErrEmail] = useState('');

    const checkAndSendEmail = () => {
        setErrEmail('');
        console.debug('helllo', error);

        console.debug('ResetPassword: Init');
        setError(null);
        let e = email.trim();

        if (e === '') {
            console.debug('checkinge', mainData.required_field_error_message[0].text);
            setError(mainData.required_field_error_message[0].text);
            return;
        }
        if (e.length > 0 && !isEmail(email)) {
            setError(dataSet && mainData?.invalid_email_error_message[0].text);
            return;
        }
        sendResetEmail(e, history);
    };

    useEffect(() => {
        setLoading(false);
        setEmailField('');
    }, []);

    return (
        <div className="">
            <Typography className={classes.heading}>{dataSet && mainData.heading_1_reset_password[0].text}</Typography>
            <Typography className={classes.subheading} style={{ marginTop: 5 }}>
                {dataSet && mainData.reset_password_description[0].text}
            </Typography>
            <form className={classes.form} noValidate autoComplete="off">
                <TextField
                    id="resetEmail"
                    label={dataSet && mainData.email_text_filed[0].text}
                    variant="filled"
                    color="primary"
                    classes={{
                        root: error ? classes.textFieldsError : classes.textFields,
                    }}
                    value={email}
                    onChange={(event) => {
                        const text = event.target.value;
                        setEmailField(text);
                    }}
                    fullWidth
                    InputLabelProps={{
                        classes: {
                            root: classes.inputLabel,
                            focused: 'focused',
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: classes.filledInputRoot,
                            focused: classes.fieldFocused,
                        },
                    }}
                />
                {error && (
                    <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                        {error === 'UserNotFoundException'
                            ? dataSet && mainData?.user_not_found[0].text
                            : error === 'InvalidParameterException'
                              ? dataSet && mainData?.unauthorised_user[0].text
                              : error === 'LimitExceededException'
                                ? dataSet && mainData?.try_again[0].text
                                : error}
                    </Typography>
                )}
            </form>
            <div className="" style={{ marginTop: 20 }}>
                <FilledButton
                    loading={loading}
                    text={dataSet && mainData.reset_password_button[0].text}
                    onPress={checkAndSendEmail}
                    fullWidth={true}
                />
            </div>
            <div className="" style={{ width: '100%' }}>
                <UnderlinedButton
                    text={dataSet && mainData.go_back_button[0].text}
                    onPress={() => {
                        history.replace('/login');
                    }}
                    fullWidth={true}
                    noJSLeft={true}
                />
            </div>
        </div>
    );
}
