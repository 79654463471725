import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import pencilIcon from '@/assets/images/pencilIcon.png';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import summaryMedication from '@/assets/images/summaryMedication.svg';
import { useStyles } from './SummaryStyles';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import Icon from '@/components/Icon/Icon';

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

export default function SummaryMedication({ mainData, allData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [bloodThinnerr, setBloodThinerr] = useState('');
    const [sleepingPillsLocal, setSleepingPillsLocal] = useState('');
    const {
        state: { medications, review_option },
        redirectFunctionSummary,
        setMedications,
    } = useContext(HealthProfileContextMain);

    let lan = languageMap[i18n.language];
    const { allDataApi } = useContext(ContextProviderTwo);
    const medicationIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'medications');

    useLayoutEffect(() => {
        if (allDataApi?.meta?.has_anamnesis && review_option) {
            // your body
            if (allDataApi?.category_data[medicationIndex]['category'] === 'medications') {
                let category2 = allDataApi?.category_data[medicationIndex].data;
                setMedications({
                    bloodThinner: category2?.blood_thinners.active ? 'YES' : 'NO',
                    whichBloodThinner: category2?.blood_thinners.value,
                    sleepingPills: category2?.sleeping_pills.active ? 'YES' : 'NO',
                    whichSleepingPills: category2?.sleeping_pills.value,
                    medicationType: category2?.active ? 'YES' : 'NO',
                    allData: category2?.medications,
                });
            }
        }
    }, [allDataApi]);

    useEffect(() => {
        if (
            medications['bloodThinner'] !== undefined &&
            medications['bloodThinner'] !== null &&
            medications['bloodThinner'] !== ''
        ) {
            let bloodThinner = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].blood_thinner_responses.find((valu) => {
                        if (medications['bloodThinner'] === valu.blood_thinner_response_api_value) {
                            bloodThinner['en-us'] = valu.yes_no_responses[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].blood_thinner_responses.forEach((valu) => {
                        if (medications['bloodThinner'] === valu.blood_thinner_response_api_value) {
                            bloodThinner['de-de'] = valu.yes_no_responses[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', bloodThinner[lan]);
            let Bllodlan = bloodThinner[lan];
            setBloodThinerr(Bllodlan);
        }

        // sleeping
        if (
            medications['sleepingPills'] !== undefined &&
            medications['sleepingPills'] !== null &&
            medications['sleepingPills'] !== ''
        ) {
            let sleepingPills = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].sleeping_pills_responses.find((valu) => {
                        if (medications['sleepingPills'] === valu.sleeping_pill_yes_no_selector) {
                            sleepingPills['en-us'] = valu.sleeping_pills_yes_no_responses[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].sleeping_pills_responses.forEach((valu) => {
                        if (medications['sleepingPills'] === valu.sleeping_pill_yes_no_selector) {
                            sleepingPills['de-de'] = valu.sleeping_pills_yes_no_responses[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', sleepingPills[lan]);
            let Bllodlan = sleepingPills[lan];
            setSleepingPillsLocal(Bllodlan);
        }
    }, [medications]);

    return (
        <>
            <div className={classes.contentContainerBody}>
                {/* medication */}
                <Grid container direction={'row'} style={{ height: '50%' }}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 10 }}>
                        {!matches && (
                            <Icon src={summaryMedication} description="medication icon" width={48} height={48} />
                        )}
                        <Typography className={classes.shortHeading} style={{ marginTop: !matches && 10 }}>
                            {mainData.subtitle_medications[0].text}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <div className={classes.flexClass}>
                            <img
                                src={pencilIcon}
                                alt=""
                                className={classes.pointer}
                                onClick={() => {
                                    redirectFunctionSummary(history, 5, 'set_medications', medications, true);
                                }}
                            />
                        </div>
                    </Grid>
                    {/* change */}
                    <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading}>
                                {mainData.subtitle_blood_thinners[0].text}
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading}>
                                {medications['bloodThinner'] !== undefined &&
                                medications['bloodThinner'] !== null &&
                                medications['bloodThinner'] !== '' &&
                                medications.bloodThinner.toLocaleLowerCase() === 'yes' &&
                                medications['whichBloodThinner'] !== undefined &&
                                medications['whichBloodThinner'] !== null &&
                                medications['whichBloodThinner'] !== ''
                                    ? medications['whichBloodThinner']
                                    : bloodThinnerr}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* sleeping pills */}
                    <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading}>{mainData.sleeping_pills[0].text}</Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading}>
                                {medications['sleepingPills'] !== undefined &&
                                medications['sleepingPills'] !== null &&
                                medications['sleepingPills'] !== '' &&
                                medications.sleepingPills.toLocaleLowerCase() === 'yes' &&
                                medications['whichSleepingPills'] !== undefined &&
                                medications['whichSleepingPills'] !== null &&
                                medications['whichSleepingPills'] !== ''
                                    ? medications['whichSleepingPills']
                                    : sleepingPillsLocal}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* sleeping pills ends */}
                    {medications['medicationType'] !== '' &&
                        medications['medicationType'] !== null &&
                        medications['medicationType'] !== undefined && (
                            <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading}>
                                        {medications.medicationType === 'YES' && mainData.subtitle_medications[0].text}
                                    </Typography>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {medications.medicationType === 'YES' &&
                                        medications['allData'] !== undefined &&
                                        medications['allData'] !== null &&
                                        medications['allData'] !== '' &&
                                        medications.allData.map((all) => (
                                            <Typography className={classes.subHeading}>{all}</Typography>
                                        ))}
                                </Grid>
                            </Grid>
                        )}
                </Grid>
            </div>
        </>
    );
}
