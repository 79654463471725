/** React */
import React from 'react';

/** MUI */
import { Typography } from '@material-ui/core';

/** Components */
import consentFormsStyles from '../ConsentFormsStyles';

/** Others */
import clsx from 'clsx';

const Heading = ({ children, classes = [] }) => {
    const style = consentFormsStyles();
    return (
        <Typography variant="h2" className={clsx(style.secondaryHeading, ...classes)}>
            {children}
        </Typography>
    );
};

export default Heading;
