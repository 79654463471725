import { useContext } from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import SelectableButton from '../../Common/SelectableButton/SelectableButton';
import SetPassword from './SetPassword';
import { ConsentFormsContext, toggleValues } from '@/context/ConsentFormsContext';
import consentFormsStyles from '../ConsentFormsStyles';
import { getText } from '@/utils/helpers';
import Loader from '../../Loader';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const CreateUserAccount = () => {
    const classes = consentFormsStyles();
    const { i18n } = useTranslation();

    const {
        [i18n.language]: { mainData: accountContent, dataSet },
    } = usePersistedPrismicStore((state) => state.anamnesisData);
    const {
        [i18n.language]: { benefits: registrationContent },
    } = usePersistedPrismicStore((state) => state.registerData);

    //** Context */
    const { createAccount, setCreateAccount } = useContext(ConsentFormsContext);

    const handleYes = () => setCreateAccount(toggleValues.YES);
    const handleNo = () => setCreateAccount(toggleValues.NO);

    if (!dataSet) return <Loader />;

    return (
        <div>
            <Typography variant="h2" className={[classes.secondaryHeading, classes.mb10]}>
                {getText(accountContent['create_an_eterno_account']) || 'Möchtest du jetzt ein Eterno-Konto erstellen?'}
            </Typography>
            <Typography variant="body1" className={[classes.paragraph, classes.mb20]}>
                {getText(accountContent['create_a_health_profile']) ||
                    `Hier kannst du deine Gesuntheitsinformationen speichern und wiederverwenden, deine Termine einsehen und
                ein Gesundheitsprofil für dich erstellen.`}
            </Typography>

            {/* Selectable Buttons */}
            <div className={clsx(classes.dFlex, classes.gap15)}>
                <SelectableButton
                    text={getText(accountContent['register_yes_no_response'][0]['register_yes_no_responses']) || 'Ja'}
                    selected={createAccount === toggleValues.YES}
                    onClick={handleYes}
                />
                <SelectableButton
                    text={getText(accountContent['register_yes_no_response'][1]['register_yes_no_responses']) || 'Nein'}
                    selected={createAccount === toggleValues.NO}
                    onClick={handleNo}
                />
            </div>

            {createAccount === toggleValues.YES && (
                <div className={classes.my20}>
                    <SetPassword
                        content={registrationContent}
                        accountContent={{ ...accountContent, ...registrationContent }}
                    />
                </div>
            )}
        </div>
    );
};

export default CreateUserAccount;
