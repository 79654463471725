import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AntSwitch } from '../Common/AntSwitchCommon';
import { useBookingStore, usePersistedPrismicStore } from '@/store';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: '0.75rem 0',
    },
    sectionHeading: {
        color: theme.palette.common.darkGrey,
        textTransform: 'uppercase',
        opacity: 0.5,
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: 10,
    },
    consentSwitch: {
        display: 'flex',
        gap: '0.5rem',
        marginTop: 10,
    },
    treatmentName: {
        fontWeight: 'bolder',
    },
    infoText: {
        whiteSpace: 'pre-line',
    },
}));

const Consent = ({ consent, setConsent }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { mainData: bookAppointmentPrismicData },
    } = usePersistedPrismicStore((state) => state.bookAppointmentData);
    const consentTreatments = useBookingStore((state) => state.consentTreatments);

    return (
        <div className={classes.container}>
            {consentTreatments.map((consentTreatment, index) => {
                const isLast = index === consentTreatments.length - 1;
                const isSingle = consentTreatments.length === 1;

                return (
                    <div
                        key={consentTreatment.treatmentKey}
                        className={classes.section}
                        style={{
                            borderBottom: !isLast && !isSingle ? '1px solid #d5d5d5' : 'none',
                        }}
                    >
                        <Typography className={classes.sectionHeading}>
                            {consentTreatment.treatmentName} - {consentTreatment.title}
                        </Typography>
                        <Typography className={classes.infoText}>{consentTreatment.infoText}</Typography>
                    </div>
                );
            })}
            <div className={classes.consentSwitch}>
                <AntSwitch checked={consent} onChange={(e) => setConsent(e.target.checked)} />
                <label>{bookAppointmentPrismicData.consent_toggle_text[0].text}</label>
            </div>
        </div>
    );
};

export default Consent;
