import createDataContext from './createDataContext';
import { processAPIWithHeadersForPractitioners } from '@/utils/helpers';
import { useConfigStore } from '@/store';

const bookAppointmentReducer = (state, action) => {
    switch (action.type) {
        case 'set_doctors':
            return { ...state, availableDoctors: action.payload };
        case 'reset_available_doctors':
            return { ...state, availableDoctors: [] };
        case 'set_contact_details':
            return { ...state, contactDetails: action.payload };
        case 'set_health_insurance':
            return { ...state, healthInsuranceDetails: action.payload };
        case 'set_form_stage':
            return { ...state, formStage: action.payload };
        case 'set_selected_doctor':
            return { ...state, selectedDoctor: action.payload };
        case 'set_confirm_doctor':
            return { ...state, confirmedDoctor: action.payload };
        case 'set_appointment_data':
            return { ...state, appointmentData: action.payload };
        case 'set_appointment_selection':
            return { ...state, appointmentSelection: action.payload };
        case 'set_summary_notes':
            return { ...state, summaryNotes: action.payload };
        case 'set_current_doctor':
            return { ...state, currentDoctor: action.payload };
        case 'set_already_selected_doctor':
            return { ...state, alreadySelectedDoctor: action.payload };
        case 'set_is_completed':
            return { ...state, isCompleted: action.payload };
        case 'set_account_context':
            return { ...state, accountContext: action.payload };
        case 'set_token_context':
            return { ...state, tokenContext: action.payload };
        case 'set_pipeline':
            return { ...state, pipeline: action.payload };
        case 'set_useriner':
            return { ...state, useriner1: action.payload };
        case 'set_confirm_password':
            return { ...state, confirmPassword: action.payload };
        case 'set_password':
            return { ...state, password: action.payload };
        case 'set_email':
            return { ...state, email: action.payload };
        default:
            return state;
    }
};

const setContactDetails = (dispatch) => (data) => {
    dispatch({
        type: 'set_contact_details',
        payload: data,
    });
};

const setHealthInsuranceDetails = (dispatch) => (data) => {
    dispatch({
        type: 'set_health_insurance',
        payload: data,
    });
};

const setLocalFormStage = (dispatch) => (stage) => {
    console.debug('BookAppointment: Form Stage Change to ', stage);
    dispatch({
        type: 'set_form_stage',
        payload: stage,
    });
};

const setSelectedDoctor = (dispatch) => (stage) => {
    dispatch({
        type: 'set_selected_doctor',
        payload: stage,
    });
};

const setConfirmDoctor = (dispatch) => (stage) => {
    dispatch({
        type: 'set_confirm_doctor',
        payload: stage,
    });
};

const setAppointmentData = (dispatch) => (stage) => {
    dispatch({
        type: 'set_appointment_data',
        payload: stage,
    });
};

const setAppointmentSelection = (dispatch) => (stage) => {
    dispatch({
        type: 'set_appointment_selection',
        payload: stage,
    });
};

const resetAvailableDoctors = (dispatch) => () => {
    dispatch({
        type: 'reset_available_doctors',
    });
};

const setSummaryNotes = (dispatch) => (notes) => {
    dispatch({
        type: 'set_summary_notes',
        payload: notes,
    });
};

const setCurrentDoctorContext = (dispatch) => (cd) => {
    dispatch({
        type: 'set_current_doctor',
        payload: cd,
    });
};

const fetchAvailableDoctors =
    (dispatch) =>
    async ({ location_key, professional_type_key, appointment_type_keys, insurance_type_key, journey_type }) => {
        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;
        // fetch user data from server
        const response = await processAPIWithHeadersForPractitioners(
            'public/users/availability/search?location_key=' +
                location_key +
                '&professional_type_key=' +
                professional_type_key +
                '&appointment_type_keys=' +
                appointment_type_keys +
                '&insurance_type_key=' +
                insurance_type_key +
                '&journey_type=' +
                journey_type,
            'GET',
            {
                headers: {
                    'Content-Type': 'application/json',
                    customer_id,
                },
            }
        );

        console.debug(response);
        dispatch({
            type: 'set_doctors',
            payload: response.data,
        });
    };

const setAlreadySelectedDoctor = (dispatch) => (cd) => {
    dispatch({
        type: 'set_already_selected_doctor',
        payload: cd,
    });
};

const setIsCompleted = (dispatch) => (cd) => {
    dispatch({
        type: 'set_is_completed',
        payload: cd,
    });
};

const setAccountContext = (dispatch) => (cd) => {
    dispatch({
        type: 'set_account_context',
        payload: cd,
    });
};

const setTokenContext = (dispatch) => (cd) => {
    dispatch({
        type: 'set_token_context',
        payload: cd,
    });
};

const setPipeline = (dispatch) => (cd) => {
    dispatch({
        type: 'set_pipeline',
        payload: cd,
    });
};

const setUseriner = (dispatch) => (cd) => {
    dispatch({
        type: 'set_useriner',
        payload: cd,
    });
};

const setConfirmPassword = (dispatch) => (cd) => {
    dispatch({
        type: 'set_confirm_password',
        payload: cd,
    });
};

const setPassword = (dispatch) => (cd) => {
    dispatch({
        type: 'set_password',
        payload: cd,
    });
};

const setEmail = (dispatch) => (cd) => {
    dispatch({
        type: 'set_email',
        payload: cd,
    });
};

export const { Provider, Context } = createDataContext(
    bookAppointmentReducer,
    {
        fetchAvailableDoctors,
        setContactDetails,
        setHealthInsuranceDetails,
        setLocalFormStage,
        setSelectedDoctor,
        setConfirmDoctor,
        setAppointmentData,
        setAppointmentSelection,
        resetAvailableDoctors,
        setSummaryNotes,
        setAlreadySelectedDoctor,
        setCurrentDoctorContext,
        setIsCompleted,
        setAccountContext,
        setTokenContext,
        setPipeline,
        setUseriner,
        setConfirmPassword,
        setPassword,
        setEmail,
    },
    {
        availableDoctors: [],
        contactDetails: null,
        healthInsuranceDetails: null,
        formStage: 1,
        selectedDoctor: null,
        confirmedDoctor: null,
        appointmentData: null,
        appointmentSelection: {},
        summaryNotes: '',
        currentDoctor: [],
        alreadySelectedDoctor: false,
        isCompleted: false,
        accountContext: {},
        tokenContext: '',
        pipeline: [],
        useriner1: {},
        email: '',
        confirmPassword: '',
        password: '',
    }
);
