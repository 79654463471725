import { useContext, useEffect, useMemo, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import HealthProfileComplete from './HealthProfileComplete';
import HealthSimpleDataCard from './HealthSimpleDataCard';
import YourBodyDetails from './YourBodyDetails';
import { Context as AuthContext } from '@/context/AuthContext';
import Tag from './Tag';
import CardWrapper from './CardWrapper';
import CardHeading from './CardHeading';
import HealthLifestyle from './HealthLifestyle';
import LevelMeter from './LevelMeter';
import { calculateBMI, menuItems } from '@/utils/helpers';
import { useHistory } from 'react-router-dom';
import { dayjs } from '@/utils/dayjsSetup';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import Loader from '../../Loader';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const menuList = menuItems();

const HealthData = ({ userData, loading }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const { i18n } = useTranslation();
    const [preConditionPrismic, setPreConditionPrismic] = useState([]);
    const [familyHistoryPrismic, setFamilyHistoryPrismic] = useState([]);
    const [genderPrismic, setGenderPrismic] = useState('');
    const [dietSummaryPrismic, setDietSummaryPrismic] = useState([]);
    console.debug('🚀 ~ file: index.jsx:42 ~ dietSummaryPrismic', dietSummaryPrismic);
    const [bloodTypePrismic, setBloodTypePrismic] = useState('');
    const {
        state: { user, usersession },
    } = useContext(AuthContext);
    console.debug('🚀 ~ file: index.jsx:44 ~ usersession', usersession);
    console.debug('🚀 ~ file: index.jsx:44 ~ userData:', userData);
    const {
        state: {},
        setIsEditOverview,
    } = useContext(HealthProfileContextMain);

    const { sleepHours, setSleepHours, energySelect, setEnergySelect } = useContext(ContextProviderTwo);

    const {
        [i18n.language]: { content, dataArr },
    } = usePersistedPrismicStore((state) => state.healthProfileData);

    const medicationIndex = userData?.category_data?.findIndex((d) => d.category === 'medications');
    const lifestyleIndex = userData?.category_data?.findIndex((d) => d.category === 'lifestyle');
    const allergiesIndex = userData?.category_data?.findIndex((d) => d.category === 'allergies');
    const familyHistoryIndex = userData?.category_data?.findIndex((d) => d.category === 'family-history');
    const preExistingIndex = userData?.category_data?.findIndex((d) => d.category === 'history');

    let percentage = parseInt(userData?.meta.percent_complete) ?? 0;
    let preConditions = userData?.category_data[preExistingIndex]?.data.preexisting_conditions ?? [];
    let familyHistory = userData?.category_data[familyHistoryIndex]?.data.history ?? [];
    let isSmoke = userData?.category_data[lifestyleIndex]?.data.habits.smoke.active ?? false;
    let isAlcohol = userData?.category_data[lifestyleIndex]?.data.habits.alcohol.active ?? false;
    let isAllergy = userData?.category_data[allergiesIndex]?.data?.allergies?.length > 0;
    let isMedication =
        userData?.category_data[medicationIndex]?.data?.medications?.length > 0 ||
        userData?.category_data[medicationIndex]?.data?.blood_thinners.active ||
        userData?.category_data[medicationIndex]?.data?.sleeping_pills.active;

    let totalMedications = useMemo(() => {
        let medicationsTotal = 0;
        if (userData) {
            let isBloodThinner = userData?.category_data[medicationIndex]?.data?.blood_thinners.active ? 1 : 0;
            let isSleepingPills = userData?.category_data[medicationIndex]?.data?.sleeping_pills.active ? 1 : 0;
            let medicationsLength = userData?.category_data[medicationIndex]?.data.medications.length;
            /** Calculating medications total */
            medicationsTotal = medicationsLength + isBloodThinner + isSleepingPills;
            return medicationsTotal;
        } else return medicationsTotal;
    }, [userData]);

    useMemo(() => {
        if (userData.meta.has_anamnesis && userData?.category_data[lifestyleIndex]?.data.sleep?.hours_per_night) {
            setSleepHours(userData?.category_data[lifestyleIndex]?.data.sleep.hours_per_night);
            console.debug('sleepHours123', userData?.category_data[lifestyleIndex]?.data.sleep.hours_per_night);
        }
        if (userData.meta.has_anamnesis && userData?.category_data[lifestyleIndex]?.data.energy?.energy_6_months) {
            setEnergySelect(userData?.category_data[lifestyleIndex]?.data.energy.energy_6_months);
        }
    }, []);

    console.debug('hours123', sleepHours);

    const handleEdit = (index) => {
        history.push(`/health-data?step=${menuList[index]}`);
        setIsEditOverview(true);
    };

    const calculatedAge = () => {
        return dayjs().diff(dayjs(user?.date_of_birth, 'YYYY-MM-DD'), 'years');
    };

    useEffect(() => {
        console.debug('render:', ' rendered');
        if (userData?.category_data[preExistingIndex]?.data.preexisting_conditions?.length > 0) {
            console.debug('preConditions123', preConditions);
            let fatherDiseasee = [];

            userData?.category_data[preExistingIndex]?.data.preexisting_conditions?.forEach((medi) => {
                const condition = dataArr['pre-existing_conditions_answers'].find(
                    (item) => medi.name === item['pre-existing_conditions_response_api_value']
                );

                fatherDiseasee.push({ name: condition['pre-existing_conditions_answer'][0]?.text });
            });
            setPreConditionPrismic(fatherDiseasee);
        }

        if (familyHistory.length > 0) {
            let fatherDiseasee = [];

            familyHistory.forEach((medi) => {
                const disease = dataArr.diseases_responses.find((item) => medi.name === item.desease_response_api_value)
                    ?.disease_response_value[0].text;

                fatherDiseasee.push({ name: disease });
            });
            setFamilyHistoryPrismic(fatherDiseasee);
        }

        if (user?.gender) {
            const gender = dataArr.gender_responses.find((item) => user?.gender === item.gender_response_api_value)
                ?.gender_response_display_value[0].text;

            setGenderPrismic(gender);
        }

        if (userData?.category_data[lifestyleIndex]?.data.diet) {
            let diet = [];
            userData?.category_data[lifestyleIndex]?.data.diet.forEach((medi) => {
                const dietItem = dataArr.diet_responses.forEach((item) => medi === item.diet_response_api_value)
                    ?.diet_reponse[0].text;

                console.debug('Questions3: value-', dietItem);
                console.debug('Questions3: value', userData);
                console.debug('Questions3: value', userData?.category_data[lifestyleIndex]?.data.diet_other);

                if (medi === 'OTHER') {
                    userData?.category_data[lifestyleIndex]?.data.diet_other.length > 0 &&
                        diet.push(`${dietItem}: ${userData?.category_data[lifestyleIndex]?.data.diet_other}`);
                } else {
                    diet.push(dietItem);
                }

                console.debug('Questions3: diet123', diet);
            });
            setDietSummaryPrismic(diet);
        }

        if (userData?.category_data[0]?.data.blood_type) {
            const bloodType = dataArr.blood_type_responses.find(
                (item) => userData?.category_data[0]?.data.blood_type === item.blood_type_answers
            )?.blood_type_answer_title[0].text;

            console.debug('userData1234:', bloodType);
            setBloodTypePrismic(bloodType);
        }
    }, [userData]);

    if (loading) return <Loader />;

    return (
        <>
            {/* Your Body */}
            <YourBodyDetails
                gender={genderPrismic}
                age={calculatedAge()}
                weight={
                    userData?.category_data[0]?.data.hasOwnProperty('weight') &&
                    userData?.category_data[0]?.data?.weight.value
                }
                weightUnit={dataArr?.weight_response_unit_label[0].text}
                bmi={
                    userData?.category_data[0]?.data.hasOwnProperty('weight') &&
                    userData?.category_data[0]?.data.hasOwnProperty('height')
                        ? calculateBMI(
                              userData?.category_data[0]?.data?.weight.value,
                              userData?.category_data[0]?.data?.height.value
                          )
                        : '-'
                }
                height={
                    userData?.category_data[0]?.data.hasOwnProperty('height') &&
                    userData?.category_data[0]?.data?.height.value
                }
                heightUnit={dataArr.height_response_unit_label[0].text}
                bloodType={bloodTypePrismic}
                handleEdit={handleEdit}
            />

            {/* Health Profile Progress */}
            {percentage < 100 && <HealthProfileComplete percentage={percentage} userData={userData} />}

            {Object.keys(content).length !== 0 && (
                <>
                    <Grid container spacing={!matches && 2}>
                        {/* Pre-existing Conditions */}
                        {preConditionPrismic.length > 0 &&
                            userData?.category_data[preExistingIndex]?.category === 'history' && (
                                <Grid item sm={6} xs={12}>
                                    <CardWrapper isEditable={true} height={'160px'} onEdit={() => handleEdit(2)}>
                                        <CardHeading>{dataArr['pre-existing_conditions_title'][0].text}</CardHeading>
                                        <div>
                                            {preConditionPrismic?.map(({ name }, key) => {
                                                return <Tag key={key}>{name}</Tag>;
                                            })}
                                        </div>
                                    </CardWrapper>
                                </Grid>
                            )}
                        {/* Family History */}
                        {familyHistoryPrismic.length > 0 &&
                            userData?.category_data[familyHistoryIndex]?.category === 'family-history' && (
                                <Grid item sm={6} xs={12}>
                                    <CardWrapper isEditable={true} height={'160px'} onEdit={() => handleEdit(5)}>
                                        <CardHeading>{content?.family_history_title[0].text}</CardHeading>
                                        <div>
                                            {familyHistoryPrismic?.map(({ name }, key) => {
                                                return <Tag key={key}>{name}</Tag>;
                                            })}
                                        </div>
                                    </CardWrapper>
                                </Grid>
                            )}
                    </Grid>
                    <Grid container spacing={!matches && 2}>
                        {/* Medications */}
                        {isMedication && (
                            <Grid item sm={6} xs={12}>
                                <HealthSimpleDataCard
                                    isEditable={true}
                                    onEdit={() => handleEdit(4)}
                                    heading={content?.medications_title[0].text}
                                    numbers={totalMedications}
                                    subHeading={`${content?.medications_subtitle[0].text} & ${content?.blood_thinner_subtitle[0].text}`}
                                    // Image
                                    image={content?.medications_illustrations.url}
                                    width={content?.medications_illustrations.dimensions.width}
                                    height={content?.medications_illustrations.dimensions.height}
                                    alt={content?.medications_illustrations.alt}
                                />
                            </Grid>
                        )}
                        {/* Allergies */}
                        {isAllergy && userData?.category_data[allergiesIndex]?.category === 'allergies' && (
                            <Grid item sm={6} xs={12}>
                                <HealthSimpleDataCard
                                    isEditable={true}
                                    onEdit={() => handleEdit(3)}
                                    heading={content?.allergies_title[0].text}
                                    numbers={userData?.category_data[allergiesIndex]?.data.allergies.length}
                                    subHeading={`${content?.food_allergy_subtitle[0].text}, ${content?.medicines_allergy_subtitle[0].text}, ${content?.other_allergy_title[0].text}`}
                                    // Image
                                    image={content?.allergies.url}
                                    width={content?.allergies.dimensions.width}
                                    height={content?.allergies.dimensions.height}
                                    alt={content?.allergies.alt}
                                />
                            </Grid>
                        )}
                    </Grid>
                </>
            )}

            {/* Lifestyle */}
            {userData?.category_data[lifestyleIndex]?.category === 'lifestyle' &&
                (!!dietSummaryPrismic?.length ||
                    userData?.category_data[lifestyleIndex]?.data.sports.exercise_per_week ||
                    isSmoke ||
                    isAlcohol) && (
                    <div
                        className=""
                        style={{
                            paddingTop:
                                isAllergy ||
                                isMedication ||
                                preConditionPrismic.length > 0 ||
                                familyHistoryPrismic.length > 0
                                    ? 0
                                    : 20,
                        }}
                    >
                        <HealthLifestyle
                            dietAnswers={dietSummaryPrismic}
                            noTimes={userData?.category_data[lifestyleIndex]?.data.sports.exercise_per_week}
                            smoke={isSmoke}
                            drink={isAlcohol}
                        />
                    </div>
                )}
            {Object.keys(content).length !== 0 && (
                <Grid container spacing={!matches && 2}>
                    {/* Sleep */}
                    {sleepHours > 5 && (
                        <Grid item sm={6} xs={12}>
                            <CardWrapper isEditable={true} onEdit={() => handleEdit(7)}>
                                <CardHeading>{content.sleep_title[0].text}</CardHeading>
                                <div>
                                    <LevelMeter hours={sleepHours} text={content?.sleep_level_subtitle[0].text} />
                                </div>
                            </CardWrapper>
                        </Grid>
                    )}
                    {/* Energy */}
                    {energySelect && energySelect.length > 0 && (
                        <Grid item sm={6} xs={12}>
                            <CardWrapper isEditable={true} onEdit={() => handleEdit(7)}>
                                <CardHeading>{content?.energy_title[0].text}</CardHeading>
                                <div>
                                    <LevelMeter
                                        isEnergy={true}
                                        energyLevel={energySelect}
                                        text={content?.energy_level_subtitle[0].text}
                                    />
                                </div>
                            </CardWrapper>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

export default HealthData;
