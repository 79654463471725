import createDataContext from './createDataContext';
import { processAPIWithHeaders } from '@/utils/helpers';

const sectionKey = ['body_features', 'preexisting_conditions', 'diet', 'lifestyle', 'goals'];

const healthProfileReducer = (state, action) => {
    switch (action.type) {
        case 'set_steps':
            return { ...state, step: action.payload, localHealthData: {} };
        case 'set_edit_health_profile':
            return { ...state, editHealthProfile: action.payload };
        case 'set_health_profile_data':
            let s = -2;
            let d = action.payload;
            if (d[sectionKey[4]] === undefined) {
                s = 4;
            }
            if (d[sectionKey[3]] === undefined) {
                s = 3;
            }
            if (d[sectionKey[2]] === undefined) {
                s = 2;
            }

            if (d[sectionKey[1]] === undefined) {
                s = 1;
            }

            if (d[sectionKey[0]] === undefined) {
                s = 0;
            }

            if (s === -2) {
                s = -1;
            }
            return { ...state, healthProfileData: d, step: s };
        case 'set_local_health_data':
            let lhd = state.localHealthData;
            let lhddata = action.payload;
            lhd[lhddata.key.toLowerCase()] = lhddata.value;

            return {
                ...state,
                localHealthData: lhd,
            };
        case 'clear_local_health_data':
            return {
                ...state,
                localHealthData: {},
            };
        case 'set_local_health_data_object':
            return {
                ...state,
                localHealthData: action.payload,
            };
        default:
            return state;
    }
};

const setStep = (dispatch) => async (number) => {
    dispatch({ type: 'set_steps', payload: number });
};

const setEditHealthProfile = (dispatch) => async (edit) => {
    dispatch({ type: 'set_edit_health_profile', payload: edit });
};

const setLocalHealthData = (dispatch) => async (data) => {
    dispatch({ type: 'set_local_health_data', payload: data });
};

const setLocalHealthDataObject = (dispatch) => async (data) => {
    dispatch({ type: 'set_local_health_data_object', payload: data });
};

const fetchUserHealthProfile =
    (dispatch) =>
    async ({ userId, token }) => {
        // fetch user data from server
        const response = await processAPIWithHeaders('secure/user/health-profiles/v2/' + userId, 'GET', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });

        console.debug(response);
        dispatch({
            type: 'set_health_profile_data',
            payload: response,
        });
    };

const updateUserHealthProfile =
    (dispatch) =>
    async ({ userId, token, step, localHealthData, healthProfileData, editHealthProfile }) => {
        const data = healthProfileData;
        const currentStage = sectionKey[step];
        data[currentStage] = localHealthData;
        data['user_id'] = userId;
        console.debug(data);

        // post user data to server
        const response = await processAPIWithHeaders('secure/user/health-profiles/v2/' + userId, 'POST', {
            body: data,
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        console.debug(response);
        dispatch({
            type: 'set_health_profile_data',
            payload: response,
        });
        dispatch({
            type: 'set_steps',
            payload: step < 3 && !editHealthProfile ? step + 1 : -1,
        });
        dispatch({ type: 'clear_local_health_data' });
    };

export const { Provider, Context } = createDataContext(
    healthProfileReducer,
    {
        setStep,
        fetchUserHealthProfile,
        updateUserHealthProfile,
        setLocalHealthData,
        setLocalHealthDataObject,
        setEditHealthProfile,
    },
    {
        step: -3,
        healthProfileData: {},
        localHealthData: {},
        editHealthProfile: false,
    }
);
