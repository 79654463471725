import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useStyles as useAnamnesisStyles } from '../../../pages/HealthProfileAnamnesis/LifeStyleComponents/LifeStyleStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useCustomAnamnesisStore } from '@/store';
import { useCustomAnamnesisStyles } from '../customAnamnesisStyles';
import { QuestionComponent } from '../../../pages/CustomAnamnesis';
import { useScrollToQuestion } from '@/hooks/useScrollToQuestion';

export const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
    },
}));

const RadioQuestion = ({ question, answer, setAnswer }) => {
    const anamnesisClasses = useAnamnesisStyles();
    const classes = useStyles();
    const customAnamnesisStyles = useCustomAnamnesisStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [showSubQuestions, setShowSubQuestions] = useState(false);
    const subQuestions = useCustomAnamnesisStore((state) => state.subQuestions);
    const highlightedQuestions = useCustomAnamnesisStore((state) => state.highlightedQuestions);
    const questionRef = useScrollToQuestion(question);

    useEffect(() => {
        setShowSubQuestions(
            answer?.answer &&
                question.radio_options.find((item) => item.radio_option[0].text === answer.answer)
                    ?.radio_option_has_subquestion
        );
    }, [answer]);

    const updateAnswer = (item) => {
        setAnswer({ answer: item.radio_option[0].text, subQuestions: {} });

        useCustomAnamnesisStore.setState({
            highlightedQuestions: {
                ...highlightedQuestions,
                [question.question_key[0].text]: false,
            },
        });
    };

    const renderSubQuestions = () => {
        return question.radio_subquestions.map(({ radio_subquestion }) => {
            const Question = QuestionComponent[subQuestions[radio_subquestion.id].question_type];
            return (
                <Question
                    question={subQuestions[radio_subquestion.id]}
                    answer={answer}
                    setAnswer={setAnswer}
                    isSubQuestion
                />
            );
        });
    };

    const subQuestionComponents = renderSubQuestions();

    return (
        <div
            ref={questionRef}
            className={clsx(
                customAnamnesisStyles.defaultQuestion,
                highlightedQuestions[question.question_key[0].text] && customAnamnesisStyles.highlight
            )}
        >
            <Typography className={classes.title}>{question.title[0].text}</Typography>
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 20, marginTop: '1rem' }}>
                {question.radio_options.map((item) => (
                    <Button
                        key={item.radio_option[0].text}
                        onClick={() => updateAnswer(item)}
                        disableRipple
                        className={clsx(
                            anamnesisClasses.selectItemCard,
                            answer?.answer === item.radio_option[0].text
                                ? anamnesisClasses.selectedCard
                                : anamnesisClasses.selectItemCardBorder
                        )}
                        style={{
                            flex: matches && '1 0 45%',
                            paddingInline: matches && 2,
                            textAlign: 'center',
                        }}
                    >
                        <Typography className={anamnesisClasses.BtncontentText} style={{ wordWrap: 'break-word' }}>
                            {item.radio_option[0].text}
                        </Typography>
                    </Button>
                ))}
            </div>
            {showSubQuestions && (
                <div
                    style={{
                        width: '50%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 20,
                        marginTop: '1rem',
                    }}
                >
                    {subQuestionComponents}
                </div>
            )}
        </div>
    );
};

export default RadioQuestion;
