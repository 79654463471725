import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FilledButton from '@/components/FilledButton';
import { useHistory } from 'react-router-dom';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import OutlinedButton from '@/components/OutlinedButton';
import { AntSwitch } from '@/components/Common/AntSwitchCommon';
import { Context as AuthContext } from '@/context/AuthContext';
import { useStyles } from './SummaryComponents/SummaryStyles';
import SummaryProfessionals from './SummaryComponents/SummaryProfessional';
import SummaryPersonalInfo from './SummaryComponents/SummaryPersonalInfo';
import SummarYourBody from './SummaryComponents/SummaryYourBody';
import SummaryMedication from './SummaryComponents/SummaryMedication';
import SummaryAllergy from './SummaryComponents/SummaryAllergy';
import SummaryExistingCondition from './SummaryComponents/SummaryExistingCondition';
import SummaryFamilyHistory from './SummaryComponents/SummaryFamilyHistory';
import SummaryLifeStyle from './SummaryComponents/SummaryLifeStyle';
import { ContextProvider } from '@/context/ProjectContext';
import { getISODate } from '@/utils/helpers';
import { unregisterSummaryAPI } from '@/service/AnamnesisSectionAPI/SummaryAPI/SummaryUnregister';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { yourBodyAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';
import { professionalApiCall } from '@/service/AnamnesisSectionAPI/ProfessionalAPI';
import SummaryPrivacyPolicyModal from './SummaryComponents/SummaryPrivacyPolicyModal';
import { useConsentFormsContext } from '@/context/ConsentFormsContext';
import useFormSteps from '@/hooks/useFormSteps';

export default function Summary({ mainData, allData, mainDataAllSection, mainDataProfessionalDoctors }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [checkedPara, setCheckedPara] = useState(false);
    const { languageGlobal } = useContext(ContextProvider);
    const [localDoctorProfile, setLocalDoctorProfile] = useState([]);
    const {
        state: { user, usersession },
    } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const {
        body1,
        body2,
        body3,
        body4,
        body5,
        body6,
        body7,
        hasAccount,
        selectedDoctorGlobal,
        allDataApi,
        nameOfDoctor,
        setIsConcent,
        newFromTrue,
        setNewFromTrue,
    } = useContext(ContextProviderTwo);
    console.debug('🚀 ~ file: Summary.jsx:56 ~ Summary ~ selectedDoctorGlobal:', selectedDoctorGlobal);
    const {
        state: {
            step,
            selectedPrac,
            personal_information,
            your_body,
            current_problems,
            medications,
            allergiesTwo,
            existing_condition,
            families,
            life_style,
        },
        BackBtn,
        setLoginAnamnesis,
    } = useContext(HealthProfileContextMain);

    const { setConsentAllForms } = useConsentFormsContext();
    const { isConsentAllForms, isLastStep, next } = useFormSteps();

    console.debug(
        'allStates1: ',
        selectedPrac,
        personal_information,
        your_body,
        current_problems,
        medications,
        allergiesTwo,
        existing_condition,
        families,
        life_style
    );

    const SummaryApi = () => {
        // data for API
        let personal_information_local = {};
        let contact_details_local = {};
        let your_body_data_local = {};
        let medications_data_local = {};
        let weight_change = null;
        if (personal_information !== undefined && personal_information !== null) {
            personal_information_local = {
                gender: personal_information?.gender,
                title: personal_information?.title === '' ? '-' : personal_information?.title,
                first_name: personal_information?.firstName.trim(),
                middle_name: personal_information?.middleName?.trim(),
                last_name: personal_information?.lastName.trim(),
                date_of_birth: getISODate(
                    personal_information?.dob
                        .replace(languageGlobal === 'en' && '.', '/')
                        .replace(languageGlobal === 'en' && '.', '/')
                        .replace(languageGlobal === 'de' && '/', '.')
                        .replace(languageGlobal === 'de' && '/', '.'),
                    languageGlobal
                ),
                email: personal_information?.email,
                phone: personal_information?.phoneNumber,
            };
            contact_details_local = {
                street: personal_information?.streetNumber,
                zip: personal_information?.zipCode,
                city: personal_information?.city,
                country: personal_information?.country,
            };
        }
        if (your_body !== undefined && your_body !== null) {
            if (your_body?.gainedOrLost !== 'NEITHER') {
                weight_change = {
                    type: your_body?.gainedOrLost,
                    amount:
                        your_body?.kg !== undefined && your_body?.kg !== null && your_body?.kg !== ''
                            ? your_body?.kg
                            : '',
                    num_weeks:
                        your_body?.weeks !== undefined && your_body?.weeks !== null && your_body?.weeks !== ''
                            ? your_body?.weeks
                            : '',
                };
            }
            your_body_data_local = {
                pregnancy: {
                    active: your_body.pergnant.toLocaleLowerCase() === 'yes',
                    months_active: your_body?.mounth,
                },
                weight: {
                    value: your_body?.weight,
                    units: mainDataAllSection.weight_response_unit_label[0].text,
                    weight_change,
                },
                height: {
                    value: your_body?.height,
                    units: mainDataAllSection.height_response_unit_label[0].text,
                },
            };
        }
        if (medications !== null && medications !== undefined) {
            medications_data_local = {
                blood_thinners: {
                    active: medications?.bloodThinner === 'YES',
                    value: medications?.whichBloodThinner,
                },
                sleeping_pills: {
                    active: medications?.sleepingPills === 'YES',
                    value: medications?.whichSleepingPills,
                },
                active: medications?.medicationType === 'YES',
                medications: medications?.allData,
            };
        }
        // existing conditions
        let preexisting_conditions_ = [];

        if (existing_condition?.surgeriesArr.length > 0) {
            existing_condition?.surgeriesArr.forEach((m) => {
                preexisting_conditions_.push({
                    name: m.pastCondition,
                    condition: m.specific,
                    since: {
                        month: m.whenPastCondition === '' ? '' : m.whenPastCondition.split('-')[0],
                        year: m.whenPastCondition === '' ? '' : m.whenPastCondition.split('-')[1],
                    },
                });
            });
        }

        let sergeryData = [];
        if (existing_condition?.sergeryDataAll.length > 0) {
            existing_condition?.sergeryDataAll.forEach((sm) => {
                sergeryData.push({
                    name: sm.sergeriesQuestion,
                    since: {
                        month: sm.sergerieDate === '' ? '' : sm.sergerieDate.split('-')[0],
                        year: sm.sergerieDate === '' ? '' : sm.sergerieDate.split('-')[1],
                    },
                });
            });
        }
        // test Perfomed
        let APITestPerfomed = [];
        if (
            existing_condition?.imagingEasy !== undefined &&
            existing_condition?.imagingEasy !== null &&
            existing_condition?.imagingEasy.length > 0
        ) {
            existing_condition?.imagingEasy.forEach((f) => {
                APITestPerfomed.push({
                    name: f.testPerfomed,
                    since: {
                        month: f.testPerfomedDate === '' ? '' : f.testPerfomedDate.split('-')[0],
                        year: f.testPerfomedDate === '' ? '' : f.testPerfomedDate.split('-')[1],
                    },
                });
            });
        }

        const existing_condition_local = {
            preexisting_conditions: preexisting_conditions_,
            had_surgery: existing_condition?.surgeries === 'YES',
            surgeries: sergeryData,
            testing: APITestPerfomed,
            had_testing: APITestPerfomed.length > 0,
        };
        // family
        let familyAPIArr = [];
        if (families?.dataFamily.length > 0) {
            families?.dataFamily.forEach((m) => {
                familyAPIArr.push({
                    name: m.relativeDisease,
                    family_member: m.whichRelative,
                    since_age: m.relativeAge,
                    condition: m.whichOther,
                });
            });
        }
        let professionalData;
        if (selectedDoctorGlobal !== null && selectedDoctorGlobal !== undefined && selectedDoctorGlobal.length > 0) {
            professionalData = {
                id: selectedDoctorGlobal[0]?.id,
                key: selectedDoctorGlobal[0]?.key[0].text,
                name: selectedDoctorGlobal[0]?.display_name[0].text,
            };
        }

        let transformedArr = [];
        if (life_style?.drugs === 'YES' && life_style?.whichOneDrugsContext.length > 0) {
            for (let i = 0; i < life_style?.whichOneDrugsContext.length; i++) {
                transformedArr.push({
                    type: life_style?.whichOneDrugsContext[i].whichOne,
                    frequency: life_style?.whichOneDrugsContext[i].howOftenDrugs,
                    frequency_type: 'month',
                });
            }
        }

        const body = {
            personal_data: {
                professional: professionalData,
                personal: personal_information_local,
                contact: contact_details_local,
                occupation: personal_information?.occuption,
            },
            body_data: your_body_data_local,
            active_data: {
                present: current_problems?.anyProblem === 'YES',
                problems: current_problems?.whichOne,
            },
            medications_data: medications_data_local,
            allergies_data: {
                allergies:
                    allergiesTwo?.data2 !== undefined && allergiesTwo?.data2 !== null && allergiesTwo?.data2.length > 0
                        ? allergiesTwo?.data2
                        : [],
                active: allergiesTwo?.anyAllergy === 'YES',
            },
            history_data: existing_condition_local,
            family_history_data: {
                history: familyAPIArr,
            },
            lifestyle_data: {
                diet: life_style?.multipleDiet,
                diet_other: life_style?.whichOtherDietSubQuestion,
                sports: {
                    active: life_style?.sports === 'YES',
                    exercise_per_week: life_style?.sports === 'NO' ? 0 : life_style?.weekDays,
                    daily_exercise_type: life_style?.exercise,
                },
                habits: {
                    smoke: {
                        active: life_style?.smoke === 'YES',
                        packs_per_day: life_style?.smokePerDay,
                        num_years: life_style?.smokeHowLong,
                    },
                    alcohol: {
                        active: life_style?.alcohol === 'YES',
                        type: life_style?.alcoholKind,
                        drinks_per_week: life_style?.alcoholPerWeek,
                    },
                    drugs: {
                        active: life_style?.drugs === 'YES',
                        data: transformedArr,
                    },
                },
            },
            consent: checkedPara,
        };
        setLoading(true);
        unregisterSummaryAPI(body)
            .then(() => {
                setLoading(false);
                if (!isConsentAllForms) history.replace('/confirmConsent');
            })
            .catch((err) => {
                setLoading(false);
            });
        console.debug('SummaryBody', body);
    };

    const ProfessionalAPI = () => {
        setLoading(true);

        let user_id_local = usersession?.idToken.payload.sub;

        const body = {
            user_id: user_id_local,
            consent: true,
            professional: {
                id: selectedDoctorGlobal[0]?.id,
                key: selectedDoctorGlobal[0]?.key[0].text,
                name: selectedDoctorGlobal[0]?.display_name[0].text,
                ext_id: selectedDoctorGlobal[0]?.dc_user_id,
            },
        };
        console.debug('selectedDoctorGlobal1', body);
        professionalApiCall(usersession, body).then((resp) => {
            setLoading(false);
            if (!isConsentAllForms) history.replace('/confirmConsent');
        });
    };
    console.debug('professionalApi1', allDataApi);

    const lifeStyleData = () => {
        const saveData = () => {
            if (!checkedPara) {
                setNewFromTrue(true);
                return;
            }
            if (!user) {
                SummaryApi();
                setIsConcent(true);
                setLoginAnamnesis(false);
            }
            if (hasAccount && user) {
                registerAPI();
            }
            if (user && !hasAccount) ProfessionalAPI();
        };

        if (isConsentAllForms) {
            saveData();
            next();
            return;
        }

        saveData();
    };

    console.debug('allDataApi123', allDataApi);
    const registerAPI = () => {
        let user_idLocal = user ? usersession?.idToken.payload.sub : '';
        setLoading(true);
        body1['user_id'] = user_idLocal;
        body3['user_id'] = user_idLocal;
        body4['user_id'] = user_idLocal;
        body5['user_id'] = user_idLocal;
        body6['user_id'] = user_idLocal;
        body7['user_id'] = user_idLocal;

        Promise.all([
            yourBodyAPI(usersession, body1),
            yourBodyAPI(usersession, body3),
            yourBodyAPI(usersession, body4),
            yourBodyAPI(usersession, body5),
            yourBodyAPI(usersession, body6),
            yourBodyAPI(usersession, body7),
        ]).then((res) => {
            ProfessionalAPI();
            console.debug('Body1 Summary', res);
        });
    };
    useEffect(() => {
        if (mainDataProfessionalDoctors && mainDataProfessionalDoctors.length > 0) {
            let filterdDoctor = mainDataProfessionalDoctors.filter(
                (m) => m.key[0].text === selectedDoctorGlobal[0].key[0].text
            );
            console.debug('localDoctorProfile1:', localDoctorProfile);
            setLocalDoctorProfile(filterdDoctor);
        }
    }, [mainDataProfessionalDoctors]);

    console.debug('mainDatamainData', mainData);

    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div className="">
                    <Typography className={classes.mainHeading}>{mainData.summary_title[0].text}</Typography>
                    <Typography className={classes.subHeading}>{mainData.summary_subtitle[0].text}</Typography>
                </div>
                <div className={classes.line}></div>
                {/* summary portion professional */}
                <SummaryProfessionals mainData={mainData} />
                <div className={classes.line}></div>
                {/* personal Info */}
                <SummaryPersonalInfo mainData={mainData} allData={allData} />
                <div className={classes.line}></div>
                {/* Your Body */}
                <SummarYourBody mainData={mainData} allData={allData} />
                {/* <div className={classes.line}></div> */}
                {/* current problems
                <SummaryCurrentProblem mainData={mainData} allData={allData} /> */}
                <div className={classes.line}></div>
                {/* medication */}
                <SummaryExistingCondition mainData={mainData} allData={allData} />
                <div className={classes.line}></div>
                {/* allergiesTwo */}
                <SummaryAllergy mainData={mainData} allData={allData} />
                <div className={classes.line}></div>
                {/* pre exiating condition  past */}
                <SummaryMedication mainData={mainData} allData={allData} />
                <div className={classes.line}></div>
                {/* family disease history */}
                <SummaryFamilyHistory mainData={mainData} allData={allData} />
                <div className={classes.line}></div>
                {/* lifeStyle */}
                <SummaryLifeStyle mainData={mainData} allData={allData} mainDataAllSection={mainDataAllSection} />
                <div className={classes.line}></div>
                {/* consent portion */}
                <div className="">
                    <Typography className={classes.mainHeading}>
                        {mainData.consent_to_privacy_policy_title[0].text}
                    </Typography>
                    <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                        {mainData.subtitle_privacy_policy[0].text}
                    </Typography>
                    <Grid
                        className={newFromTrue && !checkedPara ? classes.highlight : classes.main}
                        container
                        direction="row"
                        style={{ marginTop: 20 }}
                    >
                        <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                            <AntSwitch
                                checked={checkedPara}
                                onChange={() => {
                                    setCheckedPara(!checkedPara);
                                }}
                                name="notifications"
                            />
                        </Grid>
                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                            {localDoctorProfile && localDoctorProfile[0] && (
                                <div className={classes.subHeading}>
                                    <span> {mainData['privacy_policy_text'][0].text}</span>
                                    <Typography
                                        className={classes.contentTextLink}
                                        onClick={() => setOpenModal(!openModal)}
                                        target="_blank"
                                    >
                                        {' '}
                                        {mainData.privacy_policy_link_name[0].text}
                                    </Typography>
                                    {mainData['2nd_consent_subtitle'][0].text}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    {/* Privacy Policy Modal/Popup */}
                    {localDoctorProfile.length > 0 && (
                        <SummaryPrivacyPolicyModal
                            open={openModal}
                            onClose={() => setOpenModal(false)}
                            link={selectedDoctorGlobal[0].practitioner_privacy_policy.url}
                            mainData={mainData}
                            subHeading={nameOfDoctor || ''}
                        />
                    )}
                    {/* Privacy Policy Modal/Popup */}
                    <div
                        className=""
                        style={{
                            marginTop: 30,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: matches && 'column-reverse',
                        }}
                    >
                        <OutlinedButton
                            text={mainData?.back_cta[0].text}
                            onPress={() => {
                                BackBtn(step, history, user);
                            }}
                            fullWidth={matches}
                        />
                        <FilledButton
                            text={mainData?.consent_confirm_cta[0].text}
                            onPress={() => {
                                lifeStyleData();
                            }}
                            fullWidth={matches}
                            marginBottom={matches && 20}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
