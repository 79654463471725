import createDataContext from './createDataContext';

const doctorReducer = (state, action) => {
    switch (action.type) {
        case 'set_main_data':
            return {
                ...state,
                mainDataArr: action.payload,
            };
        case 'set_main_data_active':
            return {
                ...state,
                mainDataArrActive: action.payload,
            };
        case 'set_main_data_my_doctors':
            return {
                ...state,
                mainDataMyDoctors: action.payload,
            };
        case 'set_main_data_my_doctors_id':
            return {
                ...state,
                mainDataMyDoctorsID: action.payload,
            };
        case 'set_all_doctors': {
            return {
                ...state,
                allDoctors: action.payload,
            };
        }
        default:
            return state;
    }
};

const setMainDetaArr = (dispatch) => async (data) => {
    dispatch({
        type: 'set_main_data',
        payload: data,
    });
};

const setMainDetaArrActive = (dispatch) => async (data) => {
    dispatch({
        type: 'set_main_data_active',
        payload: data,
    });
};

const setMainDataMyDoctors = (dispatch) => async (data) => {
    dispatch({
        type: 'set_main_data_my_doctors',
        payload: data,
    });
};

const setMainDataMyDoctorsID = (dispatch) => async (data) => {
    dispatch({
        type: 'set_main_data_my_doctors_id',
        payload: data,
    });
};

const setAllDoctors = (dispatch) => async (data) => {
    dispatch({
        type: 'set_all_doctors',
        payload: data,
    });
};

export const { Provider, Context } = createDataContext(
    doctorReducer,
    {
        setMainDetaArr,
        setMainDetaArrActive,
        setMainDataMyDoctors,
        setMainDataMyDoctorsID,
        setAllDoctors,
    },
    {
        mainDataArr: null,
        mainDataArrActive: null,
        mainDataMyDoctors: {
            'en-us': [],
            'de-de': [],
        },
        mainDataMyDoctorsID: [],
        allDoctors: {
            'en-us': [],
            'de-de': [],
        },
    }
);
