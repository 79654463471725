import { makeStyles, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useStyles as useAnamnesisStyles } from '../../../pages/HealthProfileAnamnesis/YourBodyComponents/YourBodyStyles';
import { useCustomAnamnesisStyles } from '../customAnamnesisStyles';
import { useCustomAnamnesisStore } from '@/store';
import isEmail from 'validator/es/lib/isEmail';
import { useScrollToQuestion } from '@/hooks/useScrollToQuestion';

export const TextValidation = {
    NONE: 'None',
    EMAIL: 'Email',
};

export const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
    },
    error: {
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
    },
}));

const TextfieldQuestion = ({
    question,
    answer,
    setAnswer,
    isSubQuestion = false,
    hideTitle = false,
    disabled = false,
    type = 'text',
    customValidation = () => true,
    multiline = false,
    maxRows = 1,
    height = '3.75rem',
}) => {
    const anamnesisClasses = useAnamnesisStyles();
    const classes = useStyles();
    const [isValidAnswer, setIsValidAnswer] = useState(false);
    const customAnamnesisStyles = useCustomAnamnesisStyles();
    const highlightedQuestions = useCustomAnamnesisStore((state) => state.highlightedQuestions);
    const questionRef = useScrollToQuestion(question);

    useEffect(() => {
        if ((!isSubQuestion && !answer) || (isSubQuestion && !answer?.subQuestions[question.question_key[0].text])) {
            return;
        }

        switch (question.textfield_validation) {
            case TextValidation.NONE:
            default:
                setIsValidAnswer(true);
                break;
            case TextValidation.EMAIL:
                setIsValidAnswer(
                    isEmail(!isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text])
                );
                break;
        }
    }, [answer, isSubQuestion]);

    const onChange = (e) => {
        if (!isSubQuestion) {
            setAnswer(e.target.value);
        } else {
            setAnswer({
                ...answer,
                subQuestions: {
                    ...answer.subQuestions,
                    [question.question_key[0].text]: e.target.value,
                },
            });
        }

        if (e.target.value && highlightedQuestions[question.question_key[0].text]) {
            useCustomAnamnesisStore.setState({
                highlightedQuestions: {
                    ...highlightedQuestions,
                    [question.question_key[0].text]: false,
                },
            });
        }
    };

    return (
        <div
            ref={questionRef}
            className={clsx(
                customAnamnesisStyles.defaultQuestion,
                highlightedQuestions[question.question_key[0].text] && customAnamnesisStyles.highlight
            )}
        >
            {!isSubQuestion && !hideTitle && (
                <Typography style={{ marginBottom: '1rem' }} className={classes.title}>
                    {question.title[0].text}
                </Typography>
            )}
            <TextField
                label={!isSubQuestion ? question.label[0]?.text : question.title[0].text}
                disabled={disabled}
                variant="filled"
                autoComplete="off"
                type={type}
                value={(!isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text]) ?? ''}
                onChange={onChange}
                classes={{
                    root: clsx(
                        (!isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text]) &&
                            (!isValidAnswer ||
                                !customValidation(
                                    !isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text]
                                )) &&
                            classes.error
                    ),
                }}
                style={{ width: '100%', height }}
                InputLabelProps={{
                    classes: {
                        root: anamnesisClasses.inputLabel,
                        focused: 'focused',
                    },
                }}
                InputProps={{
                    classes: {
                        root: anamnesisClasses.filledInputRoot,
                        focused: anamnesisClasses.fieldFocused,
                    },
                }}
                multiline={multiline}
                maxRows={maxRows}
            />
        </div>
    );
};

export default TextfieldQuestion;
