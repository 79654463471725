import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import UnderlinedButton from '@/components/UnderlinedButton';
import { Context as BookAppointmentContext } from '@/context/BookAppointmentContext';
import { ContextProvider } from '@/context/ProjectContext';
import { Context as AuthContext } from '@/context/AuthContext';
import { DateTextField } from '@/components/Common/Date/DateTextField';
import { DropDown } from '@/components/Common/DropDown/DropDown';
import { insurance_list_private, insurance_list_public } from '@/components/Common/User/InsuranceData';
import { IconButton, InputAdornment } from '@material-ui/core';
import ToolTipBox from '@/components/Common/ToolTipBox';
import info from '@/assets/Icons/info.svg';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        position: 'relative',
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
    },
    heading: {
        ...theme.typography.HL1,
        fontWeight: 'bold',
    },
    subheading: {
        ...theme.typography.HL4,
        color: '#000',
        fontSize: 14,
    },
    categoryHeading: {
        ...theme.typography.HL5,
    },
    contentText: {
        ...theme.typography.content,
    },
    cardContentClasses: {
        position: 'relative',
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            height: 370,
        },
    },
    cardContentBottomClasses: {
        position: 'relative',
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        paddingBottom: 10,
    },
    classIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
    },
    doctorImg: {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    classTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    classTeacher: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    findClassbutton: {
        height: '3.75em',
        width: '100%',
        backgroundColor: 'transparent',
        border: `solid 2px ${theme.palette.common.brown}`,
        marginTop: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    findClassText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    priceText: {
        ...theme.typography.HL1,
    },
    downArrow: {
        height: '0.75em',
        marginRight: 15,
    },
    menuItem: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
    },
    textFields: {
        height: '3.75em',
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
    },
    blackCircle: {
        backgroundColor: theme.palette.common.darkGrey,
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    greenCircle: {
        backgroundColor: '#5ba668',
        width: 7,
        height: 7,
        borderRadius: 5,
        marginRight: 5,
    },
    passwordText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    passwordValidText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.brown,
    },
    selectionPopup: {
        height: 240,
        width: '100%',
        background: 'white',
        top: '2.5em',
        paddingTop: 1,
        zIndex: 9999,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            height: 280,
        },
        [theme.breakpoints.down('xs')]: {
            height: 100,
        },
    },
    closeButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    selectionContainer: {
        marginTop: 28,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            height: 18,
        },
        [theme.breakpoints.down('xs')]: {
            height: 18,
        },
    },
    selectionTitle: {
        ...theme.typography.content,

        textDecoration: 'underline',
    },
    userData: {
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginTop: 10,
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',

        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    progressInner: {
        width: 300,
        [theme.breakpoints.down('sm')]: {
            width: 150,
        },
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 18,
        borderRadius: '2em',
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    disabledButton: {
        backgroundColor: theme.palette.common.yellow,
    },
    disabledInsuranceButton: {
        backgroundColor: '#F9F8F4',
        opacity: 0.7,
    },
    buttonText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'uppercase',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    icon: {
        borderRadius: '50%',
        width: 22,
        height: 22,
        backgroundColor: theme.palette.common.lightBrown,

        'input:hover ~ &': {
            backgroundColor: theme.palette.common.lightBrown,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.common.yellow,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 22,
            height: 22,
            backgroundImage: 'radial-gradient(#000,#000 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.common.yellow,
        },
    },
    radioRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    formControl: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.3em',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 15,
        },
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    formControlMobileBtn: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.3em',

        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

        borderRightWidth: 0,

        marginBottom: 15,
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 15,
        },
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
}));

const HealthInsurance = ({
    setFormStage,
    selectedDoctor,
    mainBenefits,
    dataSetReg,
    mainData,
    appointmentSearch,
    signUp,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        state: { user },
    } = useContext(AuthContext);
    const {
        state: { healthInsuranceDetails },
        setHealthInsuranceDetails,
    } = useContext(BookAppointmentContext);
    const { languageGlobal } = useContext(ContextProvider);

    const [validUntil, setValidUntil] = useState(healthInsuranceDetails?.insurance_valid_until || '');
    const [einsuranceType, seteInsuranceType] = useState(healthInsuranceDetails?.insurance_type || '');
    const [eInsuranceName, seteInsuranceName] = useState(healthInsuranceDetails?.insurance_company || '');
    const [einsuranceNumber, seteInsuranceNumber] = useState(healthInsuranceDetails?.insurance_company_id || '');
    const [einsuranceNumberTwo, seteInsuranceNumberTwo] = useState(healthInsuranceDetails?.insurance_id || '');
    const [etext, seteText] = useState(healthInsuranceDetails?.insurance_status || '');

    const [formatChange, setFormatChange] = useState('');
    const [error, setError] = useState(false);

    const goBack = () => {
        setFormStage(4, selectedDoctor, true);
    };

    useEffect(() => {
        window.addEventListener('popstate', goBack);
        return () => {
            window.removeEventListener('popstate', goBack);
        };
    }, []);
    //Validation
    let isValidated =
        einsuranceType === '' ||
        (einsuranceType.toLowerCase() === 'public' &&
            (eInsuranceName === '' ||
                einsuranceNumber === '' ||
                einsuranceNumberTwo === '' ||
                validUntil === '' ||
                etext === '')) ||
        (einsuranceType.toLowerCase() === 'private' && eInsuranceName === '');

    useEffect(() => {
        if (appointmentSearch && appointmentSearch['insurance_type_key'] !== undefined) {
            seteInsuranceType(appointmentSearch.insurance_type_key);
        }
        //returning back to top on mount
        window.scrollTo(0, 0);
    }, []);

    useMemo(() => {
        let regex = languageGlobal === 'en' ? /\./gi : /\//gi;
        let langSeperator = languageGlobal === 'en' ? '/' : '.';
        let newDateUntill = validUntil.replace(regex, langSeperator);
        setFormatChange(newDateUntill);
        setHealthInsuranceDetails({
            ...healthInsuranceDetails,
            insurance_valid_until: newDateUntill,
        });
    }, [validUntil, languageGlobal]);

    // Tooltip Card
    const TooltipCard = useCallback(
        ({ order = 1, isWithPlacement = false }) => {
            if (!mainData && !('insurance_card_1' in mainData)) return null;

            const style = { backgroundColor: 'transparent' };

            const getCardImg = () => {
                if (order === 2) return 'insurance_card_2';
                return 'insurance_card_1';
            };

            return (
                <InputAdornment position="end">
                    <IconButton disableFocusRipple disableRipple style={style}>
                        <ToolTipBox
                            colorInvert
                            text={<img alt="Insurance Card" src={mainData[getCardImg()]?.url} width={200} />}
                            {...(isWithPlacement && {
                                placement: einsuranceType.toLowerCase() === 'public' ? 'top-end' : 'top',
                            })}
                        >
                            <img
                                alt="Info"
                                src={info}
                                style={{
                                    width: 16,
                                    height: 16,
                                    objectFit: 'contain',
                                }}
                            />
                        </ToolTipBox>
                    </IconButton>
                </InputAdornment>
            );
        },
        [mainData, einsuranceType]
    );

    return (
        <div>
            <Typography className={classes.categoryHeading} style={{ marginTop: 20 }}>
                {dataSetReg ? mainBenefits.health_insurance_heading : 'Health Insurance'}
            </Typography>

            <Typography className={classes.contentText} style={{ marginTop: 5 }}>
                {dataSetReg ? mainBenefits.health_insurance_sub_heading : 'Lorem ipsum dolor sit amet.'}
            </Typography>

            <Grid container spacing={1} style={{ marginTop: 15 }}>
                <Grid container spacing={2} style={{ marginTop: 4 }}>
                    <Grid item md={6} sm={12} xs={12}>
                        {dataSetReg && (
                            <DropDown
                                type="default"
                                mainDataHeading={mainBenefits.insurance_type}
                                mainDataArray={mainBenefits.insurance_type_values}
                                setData={seteInsuranceType}
                                data={einsuranceType.toLocaleUpperCase()}
                                compulsory={''}
                                validateForm={null}
                                disabled={einsuranceType && einsuranceType.length > 0}
                            />
                        )}
                    </Grid>
                    {/* insurance name  */}
                    <Grid item md={6} sm={12} xs={12}>
                        <DropDown
                            type="default"
                            mainDataHeading={mainBenefits.insurance_name}
                            mainDataArray={
                                einsuranceType === 'PUBLIC'
                                    ? insurance_list_public
                                    : einsuranceType === 'PRIVATE'
                                      ? insurance_list_private
                                      : []
                            }
                            setData={seteInsuranceName}
                            data={eInsuranceName}
                            compulsory={''}
                            fullWidth={false}
                            validateForm={null}
                            widthID="insurance_company_type"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 4 }}>
                    {einsuranceType.toLowerCase() === 'public' && (
                        <Grid item md={6} sm={12} xs={12}>
                            <TextField
                                id="insuranceCompanyId"
                                label={dataSetReg ? mainBenefits.insurance_company_id : 'Insurance Company Id'}
                                variant="filled"
                                color="primary"
                                value={einsuranceNumber}
                                onChange={(event) => {
                                    const text = event.target.value;
                                    seteInsuranceNumber(text);
                                }}
                                fullWidth
                                classes={{
                                    root: classes.textFields,
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                    endAdornment: <TooltipCard order={1} />,
                                }}
                                style={{ flex: 1 }}
                            />
                        </Grid>
                    )}
                </Grid>

                {einsuranceType.toLowerCase() === 'public' && (
                    <Grid container spacing={2} style={{ marginTop: 4 }}>
                        <Grid item md={6} sm={12} xs={12} style={{ marginTop: -10 }}>
                            {mainBenefits && (
                                <DateTextField
                                    type={'insuranceDate'}
                                    separator={mainBenefits.date_seprator}
                                    mainHeading={mainBenefits.valid_until}
                                    setDOB={setValidUntil}
                                    dob={validUntil}
                                    setError={setError}
                                    error={error}
                                    errorMessage={mainData?.invalid_date[0].text}
                                    placeHolderMonth={mainBenefits.health_insurance_expiry_placeholder_month}
                                    placeHolderYear={mainBenefits.health_insurance_expiry_placeholder_year}
                                    placeHolderDay={mainBenefits.health_insurance_expiry_placeholder_day}
                                />
                            )}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} style={{ marginTop: -10 }}>
                            {dataSetReg && (
                                <DropDown
                                    type="default"
                                    mainDataHeading={mainBenefits.type}
                                    mainDataArray={mainBenefits.health_insurance_membership_types}
                                    setData={seteText}
                                    data={etext}
                                    compulsory={''}
                                    validateForm={null}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <div
                style={{
                    marginTop: 30,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <UnderlinedButton
                    text={dataSetReg ? mainData.go_back_button[0].text : 'Go Back'}
                    onPress={() => {
                        goBack();
                    }}
                />

                <Button
                    variant="contained"
                    disabled={isValidated || error}
                    classes={{
                        root: classes.submitButton,
                        label: classes.buttonText,
                    }}
                    style={{
                        opacity: 1,
                        backgroundColor: isValidated ? theme.palette.common.lightBrown : theme.palette.common.yellow,
                        width: matches ? '40%' : '30%',
                        marginTop: 0,
                    }}
                    onClick={() => {
                        // SignUp before verification
                        if (!user) {
                            signUp();
                        }
                        // SignUp before verification
                        setHealthInsuranceDetails({
                            insurance_type: einsuranceType.toUpperCase(),
                            insurance_company: eInsuranceName,
                            insurance_company_id: einsuranceNumber,
                            insurance_id: einsuranceNumberTwo,
                            insurance_valid_until: formatChange,
                            insurance_status: etext.toUpperCase(),
                        });

                        setFormStage(6, selectedDoctor, false, {
                            insurance_type: einsuranceType.toUpperCase(),
                            insurance_company: eInsuranceName,
                            insurance_company_id: einsuranceNumber,
                            insurance_id: einsuranceNumberTwo,
                            insurance_valid_until: formatChange,
                            insurance_status: etext.toUpperCase(),
                        });
                    }}
                >
                    {dataSetReg ? mainData.continue_button[0].text : t('Continue')}
                </Button>
            </div>
        </div>
    );
};

export default HealthInsurance;
