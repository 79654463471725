import { useContext, useEffect, useState } from 'react';
import {
    getRequiredTemplates,
    getSharedDocuments,
    getSingleFormTemplate,
} from '@/service/ConsentForms/consentFormService';
import { Context as AuthContext } from '@/context/AuthContext';

const useRequiredTemplatesAndAnamnesis = ({
    professionalId = '',
    appointmentTypeKeys,
    insuranceType,
    areFormTemplatesRequired = false,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [customAnamnesisTemplates, setCustomAnamnesisTemplates] = useState([]);
    const [requiredFormsCount, setRequiredFormsCount] = useState(0);
    const [sharedDocuments, setSharedDocuments] = useState(null);
    const [sharedFormTemplates, setSharedFormTemplates] = useState({});

    const {
        state: { user },
    } = useContext(AuthContext);

    const checkRequiredTemplatesAndAnamnesis = async () => {
        try {
            const requiredTemplates = await getRequiredTemplates({
                userId: user?.id ?? '',
                appointment_type_keys: appointmentTypeKeys,
                insurance_type: insuranceType,
                professional_id: professionalId,
            });

            const consentForms = [];
            const customAnamnesisForms = [];

            requiredTemplates.templates
                .filter((form) => !form.is_completed)
                .forEach((form) => {
                    if (form.key) consentForms.push(form);
                    else customAnamnesisForms.push(form);
                });

            const { data: sharedDocumentsData } = await getSharedDocuments({ userId: user?.id });

            const filteredSharedDocuments = sharedDocumentsData.filter(
                (form) => form.context.professional_id === professionalId
            );

            const sharedDocumentTemplates = await Promise.all(
                filteredSharedDocuments.map((form) =>
                    getSingleFormTemplate(form.context.professional_id, form.context.template_id)
                )
            );

            const sharedFormTemplatesObj = sharedDocumentTemplates.reduce((obj, template) => {
                obj[template.id] = template;

                return obj;
            }, {});

            setSharedFormTemplates(sharedFormTemplatesObj);
            setSharedDocuments(filteredSharedDocuments);
            setCustomAnamnesisTemplates(customAnamnesisForms);

            setTemplates(consentForms);
            setRequiredFormsCount(consentForms.length);
            setIsLoading(false);
        } catch (error) {
            console.debug(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkRequiredTemplatesAndAnamnesis();
    }, []);

    return {
        isLoading,
        templates,
        requiredFormsCount,
        customAnamnesisTemplates,
        sharedDocuments,
        sharedFormTemplates,
    };
};

export default useRequiredTemplatesAndAnamnesis;
