import { unsubscribeGuest, useQuery } from '@/utils/helpers';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EternoSpinner from '@/components/EternoLogoSpinner/EternoSpinner';
import { makeStyles } from '@material-ui/core/styles';
import FilledButton from '@/components/FilledButton';
import { usePrismicStore } from '@/store';

const useStyles = makeStyles((theme) => ({
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh',
    },
    container: {
        minHeight: '50vh',
        fontFamily: 'MessinaSans-Regular',
        padding: '0 5rem',
        width: 'auto',

        [theme.breakpoints.down('xs')]: {
            padding: '0 1.5rem',
        },
    },
}));

const GuestUnsubscribe = () => {
    const query = useQuery();
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [hasFailed, setHasFailed] = useState(false);
    const [hasUnsubscribed, setHasUnsubscribed] = useState(false);
    const prismicData = usePrismicStore((state) => state.unsubscribeData);

    useEffect(() => {
        if (!query.get('user_id') || !query.get('code')) history.replace('/');
    }, [query]);

    const unsubscribe = async () => {
        setLoading(true);
        const result = await unsubscribeGuest(query.get('user_id'), query.get('code'));
        setHasFailed(!result);
        setHasUnsubscribed(result);
        setLoading(false);
    };

    if (!prismicData || loading) {
        return (
            <div className={classes.spinnerContainer}>
                <EternoSpinner />
            </div>
        );
    }

    if (hasFailed) {
        return (
            <div className={classes.container}>
                <h1>{prismicData.title_to_error_message[0].text}</h1>
                <h3>{prismicData.unsubscribe_error_message[0].text}</h3>
            </div>
        );
    }

    if (hasUnsubscribed) {
        return (
            <div className={classes.container}>
                <h1>{prismicData.successfully_unsubscribed_title[0].text}</h1>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <h1>{prismicData.unsubscribe_title[0].text}</h1>
            <h3>{prismicData.unsubscribe_subtitle_guest[0].text}</h3>
            <FilledButton
                loading={loading}
                onPress={unsubscribe}
                color="red"
                text={prismicData.unsubscribe_cta[0].text}
            />
        </div>
    );
};

export default GuestUnsubscribe;
