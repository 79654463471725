import { useContext, useEffect } from 'react';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { Context as AuthContext } from '@/context/AuthContext';
import { getText } from '@/utils/helpers';

/**
 *
 * @param {*} content and loading
 * @set set filtered content in context
 */
function useConsentFormsData(
    { treatmentLoading, popupLoading, translationLoading, extendedLoading, messagePageLoading, heraklesLoading = null },
    {
        content,
        popupContent,
        translationContent,
        extendedContent,
        messagePageContent,
        heraklesContent = null /** Won't be coming from all flows */,
    }
) {
    const { setConsentFormsContent, isAllForms, allFormsStepCounter } = useContext(ConsentFormsContext);
    const {
        state: { user },
    } = useContext(AuthContext);
    console.debug('🚀 ~ file: useConsentFormsData.js:32 ~ useEffect ~ content:', content);

    useEffect(() => {
        if (!treatmentLoading && !popupLoading && !translationLoading && !extendedLoading && !messagePageLoading) {
            setConsentFormsContent({
                navSteps: [
                    getText(content?.personal_information_nav),
                    getText(content?.treatment_details_nav),
                    ...(!user ? [getText(extendedContent?.eterno_account) || 'create-account'] : []),
                    getText(content?.summary_and_consent_nav),
                ],

                formData: {
                    title: getText(content['title_-_use_from_account_form']),
                    firstName: getText(content['first_name']),
                    fullName: getText(content['full_name']),
                    middleName: getText(content['middle_name']),
                    lastName: getText(content['last_name']),
                    insuranceType: getText(content['insurance_type']),
                    insuranceName: getText(content['insurance_name1']),
                    dob: getText(content['date_of_birth1']),
                    location: getText(popupContent['location_text']),
                    date: getText(popupContent['date_text']),
                    /** EXTENDED COTRACT */
                    representativeName:
                        getText(extendedContent['name_of_representative']) || 'First name of the legal guardian',
                    representativeLastName:
                        getText(extendedContent['last_name_of_representative']) || 'Last name of the legal guardian',
                    representativeAddress:
                        getText(extendedContent['address_of_representative']) ||
                        'If different from patient, street & number',
                    zipCode: getText(extendedContent['zip_code']) || 'ZIP Code',
                    city: getText(extendedContent['city']) || 'City',
                    representativeLegalAnswer: extendedContent['legal_representative_options'],
                    streetAndNumber: getText(extendedContent['street_and_number']) || 'Street & Number',
                    email: getText(extendedContent['email']) || 'Email',
                    phone: getText(extendedContent['mobile_number__optional']) || 'Mobile Number (optional)',
                    ...(heraklesContent && {
                        phoneNo: getText(heraklesContent['mobile_number__optional']) || 'Phone number',
                    }),
                    country: getText(extendedContent['country']) || 'Country',
                    representativeFullName:
                        getText(extendedContent['full_name_of_representative']) || 'Name of legal guardian',
                    representativeStreet: getText(extendedContent['street_of_representative']) || 'Street',
                    continueAsGuest: getText(messagePageContent['continue_as_guest_cta']) || 'continue as guest',
                    continueWithAccount:
                        getText(messagePageContent['continue_with_account_cta']) || 'continue with your eterno account',
                    continueAsGuestTitle:
                        getText(messagePageContent['continue_as_guest_title']) || 'You don’t have an Eterno account?',
                    logout: getText(messagePageContent['autologout_cta']) || 'Log out',
                    courseStartDate: getText(extendedContent['course_start_date_title']) || 'Kursstart Datum',
                    phoneNumber: getText(extendedContent['phone_number']) || 'Phone number',
                    remarks: getText(extendedContent['remarks']) || 'Remarks',
                },
                titles: {
                    mainTitle: getText(content['treatment_contract_title']),
                    personalInformation: getText(content['personal_information_title']),
                    insuranceDetails: getText(content['insurance_details_title']),
                    summaryTitle: getText(content['summary_title']),
                    summarySubtitle: getText(content['summary_subtitle']),
                    treatmentContractSubtitle: getText(content['treatment_contract_subtitle_text']),
                    treatmentContractTitle: getText(content['treatment_contract_title']),
                    /** Extended Contrat */
                    extendedContractTitle:
                        getText(extendedContent['extended_treatment_contract_title']) || 'Patient declaration',
                    toShare: getText(extendedContent?.zu_teilen) || '',
                },
                buttons: {
                    continue: getText(content['continue_cta']),
                    back: getText(content['back_cta']),
                    cancel: getText(popupContent['cancel_cta']),
                    startOver: getText(popupContent['start_over_cta']) || 'Start over',
                    signDocument: getText(popupContent['sign_document_cta']) || 'Sign document',
                    close: getText(translationContent['close_cta']) || 'Close',
                    readyToSign: getText(extendedContent['i_m_ready_to_sign']) || 'I’m ready to sign',
                },
                contractDetails: {
                    englishMessage: content['message_for_english_translation'].length
                        ? getText(content['message_for_english_translation'])
                        : null,
                    text: content['contract_details_text'],
                    herePopup: content['here_cta_-_popup'].length ? getText(content['here_cta_-_popup']) : null,
                    textPlaceholder: getText(content['consent_text_placeholder']),
                },
                summary: {
                    summarySubtitle: getText(content['summary_subtitle']) || 'Summary',
                    summaryTitle:
                        getText(content['summary_title']) ||
                        'Please confirm all data you previously entered is correct and your consent to share it now with Dr. Margrit Riede.',
                },
                signaturePopup: {
                    title: getText(popupContent['signature_popup_title']) || 'Sign document',
                    subTitle:
                        getText(popupContent['signature_subtitile']) ||
                        'You can use a generated signature or sign with your own signature.',
                    ownSign: getText(popupContent['own_signature_title']) || 'Own signature',
                    ownSignField: getText(popupContent['own_signature_field']) || 'Draw inside the grey box',
                    generatedSignature: getText(popupContent['generated_signature_title']) || 'Generated signature',
                    useOwnSignature: getText(popupContent['use_my_own_signature']) || 'Use my own signature',
                },
                translationPopup: {
                    englistTitle: getText(translationContent['english_translation_title']) || 'English translation',
                    trasnlatinoSubtitle:
                        getText(translationContent['translation_subtitle']) ||
                        'Please be aware that this is a suggested translation, current document can only be signed in original german version.',
                    explanation:
                        getText(translationContent['explanation_of_the_settlement']) || 'Explanation of the settlement',
                    firstPara:
                        getText(translationContent['first_paragraph']) ||
                        'All private medical services are billed on the basis of the official GOÄ (scale of fees for physicians) of 01.01.96 and in compliance with the current recommendations for analogous evaluations by the German Medical Association and in consideration of the current legal situation. The increase factor for the individual services is between 1.0 and 3.5 times the rate, depending on the difficulty and required effort. Since there is currently no GOÄ fee schedule available for many services in complementary medicine and TCM, they have to be calculated by analogous evaluations according to § 6, Para. 2 GOÄ. As a result, the reimbursement of the resulting fee claim by the private health insurers and / or aid agencies can not always be guaranteed without problems, reductions of the invoice amount by the PKV / aid are possible and may not be passed on to the doctor.',
                    patientConsent: getText(translationContent['patient_payor_consent']) || 'Patient/Payor Consent:',
                    physicianStatement:
                        getText(translationContent['physician_s_statement']) || "Physician's Statement: ",
                    secondPara:
                        getText(translationContent['second_paragraph']) ||
                        'In principle, services are provided in accordance with the rules of medical practice for the purpose of medically necessary treatment; the patient is informed accordingly about the nature and scope of the services.',
                    thirdPara:
                        getText(translationContent['third_paragraph']) ||
                        'By signing below, I confirm that I have taken note of the billing explanations and the information enclosed with this contract regarding any treatment costs incurred. Furthermore, I agree that I will bear the full costs of the treatment myself. I am also informed that the health insurance/benefit office may refuse to reimburse all or part of the invoice amount.',
                },
                corterierContract: {
                    introText:
                        getText(extendedContent['intro_text_with_professional_name']) ||
                        'Patient declaration for private medical treatment between',
                    and: getText(extendedContent['and_']) || 'and:',
                    contactInfoTitle:
                        getText(extendedContent['title_for_contact_info']) || 'Enter your contact information',
                    insuranceTitle: getText(extendedContent['title_for_insurance']) || 'Health insurance',
                    additionalQuestion:
                        getText(extendedContent['additional_question_-_legal_repr']) ||
                        'Do you need to provide the details of your legal guardian (if applicable)?',
                    enterAddress: getText(extendedContent['enter_your_address']) || 'Gib deine Adresse ein',
                },
                corterierContractDetails: {
                    detailsText:
                        getText(extendedContent['contract_details_text']) ||
                        'Hiennit erkläre ich ausdrücklich, dass ich die privatärztliche Behandlung wünsche.',
                    paperSignText:
                        getText(extendedContent['paper_sign_text_-_conditional']) ||
                        'This document is required to be signed in paper form, please go to the front desk to quickly sign it.',
                },
                messagePage: {
                    welcome: getText(messagePageContent['welcome_title']) || 'Welcome to Eterno',
                    welcomeText:
                        getText(messagePageContent['welcome_text']) ||
                        'Please fillout this questionnaire about your medical history and current health, and let your doctor learn about you to better help during your appointment. We take privacy issues seriously. Your personal and account data will be deleted from this device.',
                    thankYouTitleForUser:
                        getText(messagePageContent['eterno_user_thank_you_title']) ||
                        'Thank you, your completed forms are signed and successfully submitted via your Eterno account.',
                    thankYouTitleForGuest:
                        getText(messagePageContent['guest_thank_you_title']) ||
                        'Thank you, your completed forms are signed and successfully sent to the reception via email.',
                    thankYouSubtitle:
                        getText(messagePageContent['thank_you_subtitle']) ||
                        'You can bring now the iPad to the Front Desk.',
                },
                icons: {
                    thankYouGuest: messagePageContent['thank_you_icon_guest'],
                    thankYouUser: messagePageContent['thank_you_icon_for_eterno_user'],
                },
                //** Adding herkles prismic content conditionally */
                ...(heraklesContent && {
                    heraklesContent: {
                        courseTitle: getText(heraklesContent?.course_start_date) || 'Kursstart',
                        introText:
                            getText(heraklesContent?.intro_text_with_professional_name) ||
                            'Contract between Herakles Therapiezentrum Hamburg GmbH, Bleichenbrücke 10, 20354',
                        contactInfoTitleMain:
                            getText(heraklesContent?.title_for_contact_info) || 'Deine Kontaktinformationen',
                        title:
                            getText(heraklesContent?.extended_treatment_contract_title) ||
                            'Data protection patient information',
                        summarySubtitle:
                            getText(heraklesContent?.summary_subtitle) ||
                            'Bitte bestätige, dass alle Informationen, die du eingegeben hast, korrekt sind, und dass du damit einverstanden bist, dass diese an das Herakles Therapiezentrum übermitteln werden.',

                        contactInfoTitle: getText(heraklesContent?.enter_your_address) || 'Deine Adressdaten',
                        menuTitle: getText(heraklesContent?.template_name) || 'Herakles',
                        commentHeading:
                            getText(heraklesContent?.title_for_additional_comments) || 'Do you have any comments?',
                        commentlabel: getText(heraklesContent?.comments_placeholder_text) || 'Add comments here',
                        translationMessage:
                            getText(heraklesContent?.message_for_english_translation) ||
                            'This text is only available in german. You can read the english version',
                        textProvidedHeading:
                            getText(heraklesContent?.text_provided_by_herakles_therapiezentrum) ||
                            'Text provided by Herakles Therapiezentrum',
                        contractDetailsText:
                            getText(heraklesContent?.contract_details_text) ||
                            'Durch die Verwendung dieses Formulars stimmen Sie der Speicherung und Verarbeitung Ihrer Daten zu...',
                        //** CONTRACT LOGO */
                        contractLogo: heraklesContent?.logo_of_practice || '',
                        popupTranslation:
                            heraklesContent?.popup_translation ||
                            'By using this form, you consent to the storage and processing of your data...',

                        //** SUMMARY PAGE TITLE */
                        consentTitle: getText(heraklesContent?.consent_title) || 'Einverständniserklärung',

                        //** SUMMARY PAGE TOGGLE TEXT */
                        toggleText:
                            getText(heraklesContent?.consent_toggle_text) ||
                            'Please confirm that all the information you have entered is correct and that you agree to share it with now with Herakles Therapiezentrum.',

                        //** SUMMARY PAGE SEND CTA (BUTTON TEXT) */
                        sendButton: getText(heraklesContent?.send_and_confirm_cta) || 'senden',

                        //** SUMMARY PAGE COMMUNICATION */
                        communicationTitle: getText(heraklesContent?.communication_section) || 'Communication channel',
                        emailSummary: getText(heraklesContent?.email_subtitle) || 'Email',
                        phoneSummary: getText(heraklesContent?.phone_number) || 'Phone number',
                        otherSummary: getText(heraklesContent?.other_subtitle) || 'Other',

                        //** Herakles Communication */
                        communicationSectionTitle:
                            getText(heraklesContent?.communication_title) ||
                            'Wählen Sie Ihren bevorzugten Kommunikationskanal',
                        emailToggle: getText(heraklesContent?.email_toggle) || 'Email',
                        emailAddress: getText(heraklesContent?.email_address_field) || 'Email address',
                        smsToggle: getText(heraklesContent?.sms_toggle) || 'SMS',
                        otherToggle: getText(heraklesContent?.other_toggle) || 'Sonstige',
                        mobileNumber: getText(heraklesContent?.mobile_number) || 'Mobile Number',
                        specifyHere: getText(heraklesContent?.please_specify_here_open_text) || 'Bitte hier angeben',

                        //** Save Button */
                        save: getText(heraklesContent?.save_cta) || 'Save',

                        //** Required Errors */
                        requiredError: getText(heraklesContent?.required_error) || 'This is a required field.',
                        dobError: getText(heraklesContent?.dob_error) || 'The date format is invalid',
                        emailAlreadyExistError:
                            getText(heraklesContent?.email_already_exists) || 'Email already exists',
                        invalidEmailError: getText(heraklesContent?.invalid_email) || 'Invalid email',
                        invalidDateFormat:
                            getText(heraklesContent?.the_date_format_is_invalid) || 'The date format is invalid.',
                        passwordsNotMatched:
                            getText(heraklesContent?.passwords_not_match) || 'Both passwords should be same.',
                    },

                    //** Herakles Communication Nav Steps */
                    ...(heraklesContent &&
                    heraklesContent?.hasOwnProperty('title_nav') &&
                    heraklesContent['title_nav']?.length
                        ? {
                              communicationNavSteps: [
                                  getText(heraklesContent?.personal_information_nav),
                                  `${getText(heraklesContent?.title_nav)} & ${getText(
                                      heraklesContent?.treatment_details_nav
                                  )}`,
                                  ...(!user && isAllForms && !allFormsStepCounter?.isNotLastForm
                                      ? [getText(extendedContent?.eterno_account) || 'create-account']
                                      : []),
                                  getText(heraklesContent?.summary_and_consent_nav),
                              ],
                          }
                        : {}),
                }),
            });
        }
    }, [content, popupContent, heraklesContent]);
}

export default useConsentFormsData;
