import { useContext, useMemo } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import pencilIcon from '@/assets/images/pencilIcon.png';
import summaryPersonalInfo from '@/assets/images/summaryPersonalInfo.svg';
import consentFormsStyles from '../ConsentFormsStyles';
import ListItem from './ListItem';
import { useHistory } from 'react-router-dom';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { formatDateByLanguage, getText } from '@/utils/helpers';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useInsuranceType from '@/hooks/useInsuranceType';
import Loader from '../../Loader';
import { usePersistedPrismicStore } from '@/store';

const SummaryPersonalInfoTreatment = () => {
    const classes = consentFormsStyles();
    const history = useHistory();
    const { i18n } = useTranslation();

    /** Context */
    const { consentFormsContent: content, formData } = useContext(ConsentFormsContext);

    const { insuranceCompany, insuranceType: insuranceTypeMain } = useContext(ContextProviderTwo);

    const { firstName, lastName, dob, insuranceType, insuranceName, email, gender } = formData;

    const { insuranceTypeText } = useInsuranceType(insuranceTypeMain || insuranceType);

    const {
        [i18n.language]: { benefits: accountData },
    } = usePersistedPrismicStore((state) => state.registerData);

    const genderText = useMemo(() => {
        if (!accountData) return null;
        const selectedGender = accountData?.gender_values?.find((item) => item.gender_api_value === gender);
        return selectedGender ? getText(selectedGender.gender_display_value) : '';
    }, [accountData, gender]);

    /** Edit personal information */
    const handleEdit = () => history.push(`${window.location.pathname}?step=personal-information`);

    if (!accountData) return <Loader />;

    return (
        <div className={classes.summaryPersonalInfo}>
            {/* Header */}
            <div className={classes.personalInfoHeader}>
                <img src={summaryPersonalInfo} className={classes.personalInfoIcon} alt="eterno-personal-info-icon" />
                <Typography variant="body1" className={classes.shortHeading}>
                    {content ? content?.titles.personalInformation : 'Personal Information'}
                </Typography>
            </div>
            {/* Personal Information */}
            <Grid container className={clsx(classes.personalInfoData, classes.my10)}>
                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content ? content?.formData.fullName : 'Full name'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{`${firstName || '-'} ${lastName || '-'}`}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content ? content?.formData.dob : 'Date of birth'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>
                            {formatDateByLanguage(dob, i18n) === 'Invalid date'
                                ? '- -'
                                : formatDateByLanguage(dob, i18n) || '- -'}
                        </ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{getText(accountData?.gender_label ?? []) || 'Gender'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{genderText || '- -'}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content?.formData?.email || 'E-Mail'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{email || '- -'}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content ? content?.formData.insuranceType : 'Insurance Type'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{insuranceTypeText || '- -'}</ListItem>
                    </Grid>
                </Grid>

                {insuranceTypeMain?.toUpperCase() === 'PRIVATE' && (
                    <Grid container className={classes.mb10}>
                        <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                            <ListItem>{content ? content?.formData.insuranceName : 'Insurance Name'}</ListItem>
                        </Grid>
                        <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                            <ListItem bold>{insuranceCompany || insuranceName || '- -'}</ListItem>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {/* Edit Icon */}
            <IconButton onClick={handleEdit} className={classes.editButton} disableRipple>
                <img src={pencilIcon} className={classes.editIcon} alt="eterno-edit-icon" />
            </IconButton>
        </div>
    );
};

export default SummaryPersonalInfoTreatment;
