import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';
import { ThankYou } from '@/components/ConsentForms';
import Loader from '@/components/Loader';
import { Context as AuthContext } from '@/context/AuthContext';
import {
    ConsentAllFormsInitialRoutes,
    INITIAL_STATE_ALL_FORMS,
    useConsentFormsContext,
} from '@/context/ConsentFormsContext';
import useAutoLogoutAndRedirect from '@/hooks/useAutoLogoutAndRedirect';
import useFormSteps from '@/hooks/useFormSteps';
import { getText, useQuery } from '@/utils/helpers';
import { getByTag } from '@/utils/fetchers';

const pages = [
    { name: 'Anamnesis', active: true },
    { name: 'Additional Questionnaire', active: true },
];

const isCompleted = pages.every((page) => page.active);

const ConsentAllFormsThankYou = () => {
    //** Router */
    const history = useHistory();
    const { i18n } = useTranslation();
    const query = useQuery();
    const step = query.get('step');

    const { data, isLoading: loading } = useSWRImmutable(
        [
            'treatment_contract_welcome_page',
            {
                lang: i18n.language === 'en' ? 'en-us' : 'de-de',
            },
        ],
        getByTag
    );
    const content = useMemo(() => data?.results[0].data, [data]);

    //** Context */
    const {
        setIsAllForms,
        setAllFormsPages,
        setAllSelectedForms,
        consentAllForms: { initialRoute },
    } = useConsentFormsContext();
    const {
        state: { user },
    } = useContext(AuthContext);

    const { pages } = useFormSteps();

    useEffect(() => {
        if (step) history.replace('/consent-all-forms-thankyou');
    }, [history, step]);

    const shouldLogout = initialRoute === ConsentAllFormsInitialRoutes.ALL_FORMS_CODE;

    // Autologout hook
    const { forceRedirect } = useAutoLogoutAndRedirect(shouldLogout ? '/all-forms-code' : '/appointments', {
        shouldLogout: shouldLogout,
        shouldAutoRedirect: true,
    });

    const clearStates = () => {
        setIsAllForms(false);
        setAllFormsPages(INITIAL_STATE_ALL_FORMS);
        setAllSelectedForms({});
    };

    // clears all context states
    useEffect(clearStates, []);

    const getUserText = (userText, guestText) => {
        return getText(content?.[user ? userText : guestText]);
    };

    if (loading) return <Loader />;

    return (
        <ThankYou
            /** ICON */
            icon={content?.[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_guest']?.url || WelcomeIcon}
            iconHeight={content?.[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_guest']?.dimensions?.height}
            iconWidth={content?.[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_guest']?.dimensions?.width}
            title={getUserText('eterno_user_thank_you_title', 'guest_thank_you_title') || 'Thank You'}
            centerParagraph={
                getText(content?.profile_synced_text) ||
                'Your health profile is now synced and updated accordingly in the Eterno App. You can simply share it in the future with a selected professional or export it as a pdf.'
            }
            pages={pages.map((page) => ({ ...page, active: true }))}
            paragraph={getText(content?.thank_you_subtitle) || 'You can bring now the iPad to the Front Desk.'}
            completed={isCompleted}
            buttonText={getText(content?.all_forms_thankyou_continue) || 'Done'}
            onButtonClick={forceRedirect}
        />
    );
};

export default ConsentAllFormsThankYou;
