import { makeStyles } from '@material-ui/core';
import { getColorWithOpacity } from '@/utils/colorHelpers';

export const useStyles = makeStyles((theme) => ({
    //
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
    },
    mainHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 21,
    },
    heading: {
        ...theme.typography.HL1,
    },
    shortHeading: {
        ...theme.typography.HL4,
    },
    cardContent: {
        backgroundColor: 'white',
        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        marginTop: 23,
    },
    selectItemCard: {
        backgroundColor: theme.palette.common.lightGray,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'inline-block',
        '&:hover': {
            backgroundColor: theme.palette.common.lightGray,
            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        },
        [theme.breakpoints.down('sm')]: {
            addingTop: 18,
            paddingBottom: 18,
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 25,
            paddingRight: 25,
        },
    },
    selectedCard: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    selectItemCardBorder: {
        boxShadow: `0 0 0 1px ${theme.palette.common.mediumBrown}`,
    },
    subHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        fontWeight: '500',
        color: theme.palette.common.darkGray,
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    allergyType: {
        marginTop: 40,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    graySection: {
        marginTop: 20,
        padding: 20,
        backgroundColor: theme.palette.common.lightBrown,
        borderRadius: 8,
    },
    flexClass: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
    },
    icons: {
        marginRight: 30,
        cursor: 'pointer',
    },
    pointer: {
        cursor: 'pointer',
    },
    line: {
        marginTop: 30,
        marginBottom: 30,
        backgroundColor: theme.palette.common.lightBrown,
        height: 2,
        width: '100%',
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        display: 'inline-block',
        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3, cursor: 'pointer' },
    },
    highlight: {
        background: getColorWithOpacity(theme.palette.common.secondary, 0.15),
        padding: 10,
        marginBlock: 10,
    },
}));
