import { TextField, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from '../MedicationComponents/MedicationClasses';

const LifeStyleOtherInfo = ({ mainData }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { otherInfo, setOtherInfo } = useContext(ContextProviderTwo);

    return (
        <div>
            <Typography className={classes.mainHeading}>{mainData?.other_info_question[0].text}</Typography>
            <TextField
                id="medicationSubQuestionWhich"
                label={mainData ? mainData.other_info_text_bof[0].text : t('TransferSlip')}
                variant="filled"
                autoComplete="off"
                color="primary"
                value={otherInfo}
                autoFocus={!!otherInfo}
                onChange={(event) => {
                    setOtherInfo(event.target.value);
                }}
                classes={{
                    root: classes.textFields,
                }}
                style={{
                    width: matches ? '100%' : '100%',
                    flex: matches ? 1 : 0.5,
                    marginTop: !matches && 20,
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.inputLabel,
                        focused: 'focused',
                        shrink: 'shrink',
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.filledInputRoot,
                        focused: classes.fieldFocused,
                        shrink: 'shrink',
                    },
                }}
            />
        </div>
    );
};

export default memo(LifeStyleOtherInfo);
