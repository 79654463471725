import { memo } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FilledButton from '../FilledButton';
import CardWrapper from './HealthData/CardWrapper';
import { useHistory } from 'react-router-dom';
import { menuItems, replaceProfessionalType } from '@/utils/helpers';
import { useTranslation } from 'react-i18next';
import { usePersistedPrismicStore } from '@/store';

const useStyles = makeStyles((theme) => ({
    mainPage: {
        backgroundColor: theme.palette.common.white,
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
    },
    mainHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 24,
        fontWeight: 'normal',
    },
    heading: {
        ...theme.typography.HL1,
    },
    buttonLabel: {
        padding: 0,
        justifyContent: 'left',
    },
    cardbg: {
        width: '100%',
        height: '100%',
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        backgroundColor: 'rgba(255,200,119,0.2)',
        maxHeight: 400,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 300,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
        },
        [theme.breakpoints.down('xs')]: {
            height: 250,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
        },
    },
    imagebg: {
        width: '100%',
        height: '100%',
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        maxHeight: 400,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 300,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
        },
        [theme.breakpoints.down('xs')]: {
            height: 250,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
        },
    },
    text: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginTop: '1rem',
        width: '100%',
    },
    listItems: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        marginBlock: '.2rem',
        width: '95%',
    },
    font: {
        fontSize: '1.5rem',
        backgroundColor: 'red',
        height: '0',
        display: 'flex',
        justifyContent: 'space-between',
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
        marginTop: 10,
    },
}));
const menuList = menuItems();

const Welcome = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const { i18n } = useTranslation();
    const history = useHistory();

    const {
        [i18n.language]: { content },
    } = usePersistedPrismicStore((state) => state.healthProfileData);

    return (
        <>
            {Object.keys(content).length !== 0 && (
                <CardWrapper>
                    <Grid container spacing={2} style={{ padding: 0 }}>
                        <Grid item md={12} xs={12} style={{ padding: 0 }}>
                            <Typography className={classes.mainHeading}>
                                {content?.welcome_health_profile_title[0]?.text}
                            </Typography>
                        </Grid>
                        <Grid container md={12} xs={12} style={{ padding: 0 }}>
                            <Grid item md={8} sm={8} style={{ padding: 0 }}>
                                <Typography className={classes.text}>
                                    {replaceProfessionalType({
                                        text: content?.welcome_to_health_profile_text[0].text,
                                        language: i18n.language,
                                    })}
                                </Typography>
                                <ul>
                                    {content?.welcome_to_health_profile_text.map(({ type, text }, key) => {
                                        if (type === 'list-item') {
                                            return (
                                                <li className={classes.listItems} key={key}>
                                                    {replaceProfessionalType({ text, language: i18n.language })}
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                                <div>
                                    <FilledButton
                                        text={content?.welcome_health_profile_cta[0].text}
                                        onPress={() => {
                                            history.push(`/health-data?step=${menuList[0]}`);
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={4} sm={4} style={{ padding: 0, display: matches ? 'none' : 'block' }}>
                                <img
                                    src={content?.welcome_to_health_profile_image.url}
                                    className={classes.img}
                                    alt={content?.welcome_to_health_profile_image.alt}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </CardWrapper>
            )}
        </>
    );
};

export default memo(Welcome);
