import { useContext } from 'react';
import Loader from '@/components/Loader';
import usePrismicData from '@/hooks/usePrismicData';
import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';
import { Context as AuthContext } from '@/context/AuthContext';
import { getText } from '@/utils/helpers';
import useAutoLogoutAndRedirect from '@/hooks/useAutoLogoutAndRedirect';
/** Child Components */
import { ThankYou } from '@/components/ConsentForms';
import { usePersistedPrismicStore } from '@/store';

const WELCOME_PAGE = '/consent-admission-welcome';

const ThankYouPage = () => {
    const prismicDataHerakles = usePersistedPrismicStore((state) => state.heraklesTherapy);
    const { loading, content } = usePrismicData(prismicDataHerakles);

    const {
        state: { user },
    } = useContext(AuthContext);

    // Autologout hook
    const { forceRedirect } = useAutoLogoutAndRedirect(WELCOME_PAGE);

    const getUserText = (userText, guestText) => {
        return getText(content?.[user ? userText : guestText]);
    };

    if (loading) return <Loader />;

    return (
        <ThankYou
            /** ICON */
            icon={content?.[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_guest']?.url || WelcomeIcon}
            iconHeight={content?.[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_guest']?.dimensions?.height}
            iconWidth={content?.[user ? 'thank_you_icon_for_eterno_user' : 'thank_you_icon_guest']?.dimensions?.width}
            title={getUserText('eterno_user_thank_you_title', 'guest_thank_you_title') || 'Thank You'}
            centerParagraph={
                getText(content?.profile_synced_text) ||
                'Your health profile is now synced and updated accordingly in the Eterno App. You can simply share it in the future with a selected professional or export it as a pdf.'
            }
            paragraph={getText(content?.thank_you_subtitle) || 'You can bring now the iPad to the Front Desk.'}
            // Done Button
            buttonText={getText(content?.all_forms_thankyou_continue) || 'Done'}
            onButtonClick={forceRedirect}
        />
    );
};

export default ThankYouPage;
