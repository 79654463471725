import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { IconButton, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { useStyles } from './AccountsStyles';

export default function AccountPasswordSection({ mainData, mainDataAccount, errPassword }) {
    const classes = useStyles();

    const { showPassword, setShowPassword, confirmPassword, setConfirmPassword, passwordValid, setPasswordValid } =
        useContext(ContextProviderTwo);

    const hasNumber = (myString) => {
        return /\d/.test(myString);
    };

    const hasSpecial = (myString) => {
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return format.test(myString);
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div className="">
                    <div className="" style={{ marginTop: 20 }}>
                        <Typography className={classes.mainHeading}>{mainData.password_question[0].text}</Typography>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    id="password"
                                    label={mainDataAccount.password[0].text}
                                    variant="filled"
                                    color="primary"
                                    value={passwordValid.password}
                                    onChange={(event) => {
                                        const password = event.target.value;
                                        let characters = false;

                                        if (password.length > 7) {
                                            characters = true;
                                        }

                                        const number = hasNumber(password);
                                        const special = hasSpecial(password);

                                        setPasswordValid({
                                            characters,
                                            number,
                                            special,
                                            password: password,
                                        });
                                    }}
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    style={{ marginTop: 15 }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRoot,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                        // <-- This is where the toggle button is added.
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOutlinedIcon />
                                                    ) : (
                                                        <VisibilityOffOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {passwordValid.password !== '' ? (
                                    <div>
                                        <Typography className={classes.passwordText} style={{ marginTop: 5 }}>
                                            {mainDataAccount.password_restrictions_heading[0].text}
                                        </Typography>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <div
                                                    className={
                                                        passwordValid.characters
                                                            ? classes.greenCircle
                                                            : classes.blackCircle
                                                    }
                                                ></div>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    className={
                                                        passwordValid.characters
                                                            ? classes.passwordValidText
                                                            : classes.passwordText
                                                    }
                                                >
                                                    {
                                                        mainDataAccount.password_restrictions[0].password_restriction[0]
                                                            .text
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <div
                                                    className={
                                                        passwordValid.number ? classes.greenCircle : classes.blackCircle
                                                    }
                                                ></div>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    className={
                                                        passwordValid.number
                                                            ? classes.passwordValidText
                                                            : classes.passwordText
                                                    }
                                                >
                                                    {
                                                        mainDataAccount.password_restrictions[1].password_restriction[0]
                                                            .text
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <div
                                                    className={
                                                        passwordValid.special
                                                            ? classes.greenCircle
                                                            : classes.blackCircle
                                                    }
                                                ></div>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    className={
                                                        passwordValid.special
                                                            ? classes.passwordValidText
                                                            : classes.passwordText
                                                    }
                                                >
                                                    {
                                                        mainDataAccount.password_restrictions[2].password_restriction[0]
                                                            .text
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ) : null}
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    id="confirmpasword"
                                    label={mainDataAccount.confirm_password[0].text}
                                    variant="filled"
                                    color="primary"
                                    fullWidth
                                    classes={{
                                        root: errPassword ? classes.textFieldsError : classes.textFields,
                                    }}
                                    type={showPassword ? 'text' : 'password'}
                                    style={{ marginTop: 15 }}
                                    value={confirmPassword}
                                    onChange={(event) => {
                                        const text = event.target.value;
                                        setConfirmPassword(text);
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.filledInputRoot,
                                            focused: classes.fieldFocused,
                                            shrink: 'shrink',
                                        },
                                    }}
                                />
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    {errPassword && <Typography>{errPassword}</Typography>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
}
