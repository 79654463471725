import { useContext, useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FilledButton from '@/components/FilledButton';
import { useHistory } from 'react-router-dom';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import { Context as AuthContext } from '@/context/AuthContext';
import OutlinedButton from '@/components/OutlinedButton';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { yourBodyAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';
import { useStyles } from './YourBodyComponents/YourBodyStyles';
import YourBodyWeight from './YourBodyComponents/YourBodyWeight';
import YourBodyGainedORLoss from './YourBodyComponents/YourBodyGainedORLoss';
import YourBodyHeight from './YourBodyComponents/YourBodyHeight';
import YourBodyPregnant from './YourBodyComponents/YourBodyPregnant';
import YourBodyBmi from './YourBodyComponents/YourBodyBmi';
import YourBodyBloodType from './YourBodyComponents/YourBodyBloodType';
import YourBodyDisability from './YourBodyComponents/YourBodyDisability';
import YourBodyCholesterol from './YourBodyComponents/YourBodyCholesterol';
import YourBodyBloodPressure from './YourBodyComponents/YourBodyBloodPressure.jsx';
import YourBodyBloodPressureMeter from './YourBodyComponents/YourBodyBloodPressureMeter';
import { calculateBMI, menuItems, titleCase, trackStormlyAnamnesisSection } from '@/utils/helpers';
import scrollIntoView from 'scroll-into-view';
import { useTrackingStore } from '@/store';
import useFormSteps from '@/hooks/useFormSteps';

export default function YourBody({ mainData, user, usersession, isFromHealthData = false }) {
    const theme = useTheme();
    const classes = useStyles();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    let history = useHistory();
    const [counter, setCounter] = useState(0);
    const {
        genderQuestion,
        gainedWeight,
        pregnancy,
        pregnancyCount,
        KGs,
        weeks,
        value__,
        valueHeight,
        setGenderQuestion,
        setGainedWeight,
        setPregnancy,
        setPregnancyCount,
        setKGs,
        setWeeks,
        setValue__,
        setValueHeight,
        setBody1,
        body1,
        allDataApi,
        setHasAccount,
        bloodType,
        setBloodType,
        disability,
        setDisability,
        disabilityValue,
        setDisabilityValue,
        cholesterol,
        setCholesterol,
        bloodPressure,
        setBloodPressure,
        sBP,
        setSBP,
        dBP,
        setDBP,
        newFromTrue,
        setNewFromTrue,
    } = useContext(ContextProviderTwo);
    console.debug(
        'checkYourBodyData',
        'KGs ',
        value__,
        'valueHeight ',
        valueHeight,
        calculateBMI(value__, valueHeight),
        isFromHealthData
    );

    const {
        state: { step, your_body, save_changes_option, is_edit_overview, personal_information },
        BackBtn,
        UpdateSteps,
        setYourBody,
        onSaveButton,
        onCancelButton,
        setReviewOption,
    } = useContext(HealthProfileContextMain);

    const { state } = useContext(AuthContext);

    const { isConsentAllForms, isLastStep } = useFormSteps();

    const yourBodyData = () => {
        if (
            value__ === '' ||
            valueHeight === '' ||
            gainedWeight === '' ||
            (genderQuestion === 'FEMALE' && pregnancy === '')
        ) {
            setNewFromTrue(true);
            setCounter(counter + 1);
            return;
        }
        setNewFromTrue(false);

        setYourBody({
            weight: value__,
            gainedOrLost: gainedWeight,
            kg: KGs,
            weeks: weeks,
            height: valueHeight,
            pergnant: pregnancy,
            mounth: pregnancyCount,
        });
        YourBodyAPILocal();
        if (!save_changes_option) {
            trackStormlyAnamnesisSection();
            UpdateSteps(step, history, user, isFromHealthData, isConsentAllForms, isLastStep);
        }
        if (save_changes_option) {
            setHasAccount(false);
            setReviewOption(false);
            onSaveButton(history, is_edit_overview);
        }
    };

    useEffect(() => {
        useTrackingStore.setState({
            anamnesisSectionStarted: Date.now(),
            currentAnamnesisSection: menuItems()[step - 1],
        });
    }, []);

    useEffect(() => {
        console.debug('category123::1', user, personal_information);

        if (user && allDataApi !== null && allDataApi !== undefined && body1 === null) {
            console.debug('category1::');
            let category1 = allDataApi?.category_data[0].data;
            console.debug('category123::', allDataApi?.category_data[0].data);

            if (allDataApi?.category_data[0] && allDataApi?.category_data[0]['category'] === 'body') {
                console.debug('category1::', category1);
                setGainedWeight(
                    category1?.weight.weight_change?.type ? category1?.weight.weight_change?.type : 'NEITHER'
                );
                setPregnancy(category1?.pregnancy?.active ? 'YES' : 'NO');
                setPregnancyCount(category1?.pregnancy?.months_active);
                setKGs(category1?.weight?.weight_change?.amount ? category1?.weight?.weight_change?.amount : '');
                setWeeks(
                    category1?.weight?.weight_change?.num_weeks ? category1?.weight?.weight_change?.num_weeks : ''
                );
                setValue__(category1?.weight.value);
                setValueHeight(category1?.height.value);
                if (isFromHealthData) {
                    setBloodType(category1?.blood_type);

                    if (category1?.hasOwnProperty('disability')) {
                        setDisability(category1?.disability && category1?.disability.present ? 'YES' : 'NO');
                        setDisabilityValue(category1?.disability && category1?.disability.degree);
                    }
                    setCholesterol(category1?.cholesterol_level ? titleCase(category1?.cholesterol_level) : '');
                    if (category1?.hasOwnProperty('blood_pressure')) {
                        setBloodPressure(
                            category1?.blood_pressure && category1?.blood_pressure.diastolic ? 'YES' : 'NO'
                        );
                        setSBP(category1?.blood_pressure && category1?.blood_pressure.systolic);
                        setDBP(category1?.blood_pressure && category1?.blood_pressure.diastolic);
                    }
                }
            }
        }
        setGenderQuestion(personal_information.gender ?? state?.user?.gender ?? '');
    }, [allDataApi]);

    console.debug('bodyy^', disability, sBP, dBP);

    const Cancel = () => {
        if (isFromHealthData) {
            history.push('/health-profile-overview');
            return;
        }
        setGainedWeight(your_body.gainedOrLost);
        setPregnancy(your_body.pergnant);
        setPregnancyCount(your_body.mounth);
        setKGs(your_body.kg);
        setWeeks(your_body.weeks);
        setValue__(your_body.value__);
        setValueHeight(your_body.height);

        onCancelButton(history);
    };

    console.debug('gendergender', genderQuestion);

    const YourBodyAPILocal = () => {
        let user_idLocal = user ? usersession?.idToken.payload.sub : '';
        let weight_change = null;

        if (gainedWeight !== 'NEITHER') {
            weight_change = {
                type: gainedWeight,
                amount: KGs !== undefined && KGs !== null && KGs !== '' ? KGs : '',
                num_weeks: weeks !== undefined && weeks !== null && weeks !== '' ? weeks : '',
            };
        }
        const body = {
            user_id: user_idLocal,
            category: 'body',
            data: {
                weight: {
                    value: value__,
                    units: mainData.weight_response_unit_label[0].text,
                    weight_change,
                },
                height: {
                    value: valueHeight,
                    units: mainData.height_response_unit_label[0].text,
                },
                blood_type: bloodType,
                cholesterol_level: cholesterol ? cholesterol.toLocaleLowerCase() : '',
                pregnancy: { active: pregnancy.toLocaleLowerCase() === 'yes', months_active: Number(pregnancyCount) },
            },
        };

        if (disability !== null) {
            if (disability === 'YES') {
                body.data['disability'] = {
                    present: disability === 'YES',
                    degree: disabilityValue,
                };
            } else {
                body.data['disability'] = {
                    present: disability === 'YES',
                };
            }
        }

        if (bloodPressure !== null) {
            body.data['blood_pressure'] = {
                systolic: sBP ? sBP : '',
                diastolic: dBP ? dBP : '',
            };
        }

        console.debug('Body122222', body, state.userData);
        setBody1(body);
        user && yourBodyAPI(usersession, body);
    };

    const messageRef = useRef();
    useEffect(() => {
        messageRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }, [counter]);
    const myRef = useRef(null);
    const allStates = [
        { name: 'value__', value: value__ !== 2 },
        { name: 'gainedWeight', value: !!gainedWeight },
        { name: 'valueHeight', value: valueHeight !== 40 },
        { name: 'bloodType', value: !!bloodType },
        { name: 'disability', value: !!disability },
        { name: 'cholesterol', value: !!cholesterol },
        { name: 'bloodPressure', value: !!bloodPressure },
    ];

    const whichIsNull = () => {
        const firstNull = allStates.find((state) => state.value === false);
        if (typeof firstNull === 'object') return firstNull.name;
    };

    useEffect(() => {
        if (isFromHealthData && state?.userData) {
            const elementName = whichIsNull();
            const element = document.getElementById(elementName);
            scrollIntoView(element, {
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    }, [!value__, !gainedWeight, !valueHeight, !bloodType, !disability, !cholesterol, !bloodPressure]);

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div
                    ref={value__ === '' ? messageRef : null}
                    className={(newFromTrue || isFromHealthData) && value__ === '' ? classes.highlight : classes.main}
                    id={whichIsNull() === 'value__' ? 'value__' : null}
                >
                    <YourBodyWeight mainData={mainData} isFromHealthData={isFromHealthData} />
                </div>

                <div
                    ref={value__ !== '' && !gainedWeight ? messageRef : null}
                    className={(newFromTrue || isFromHealthData) && !gainedWeight ? classes.highlight : classes.main}
                    id={whichIsNull() === 'gainedWeight' ? 'gainedWeight' : null}
                >
                    <YourBodyGainedORLoss mainData={mainData} isFromHealthData={isFromHealthData} />
                </div>

                <div
                    className={
                        (newFromTrue || isFromHealthData) && valueHeight === '' ? classes.highlight : classes.main
                    }
                    ref={value__ !== '' && gainedWeight && valueHeight === '' ? messageRef : null}
                    id={whichIsNull() === 'valueHeight' ? 'valueHeight' : null}
                >
                    <YourBodyHeight mainData={mainData} isFromHealthData={isFromHealthData} />
                </div>

                {isFromHealthData && (
                    <div className={isFromHealthData && !value__ && !valueHeight ? classes.highlight : classes.main}>
                        <YourBodyBmi
                            mainData={mainData}
                            isFromHealthData={isFromHealthData}
                            bmi={calculateBMI(Number(value__), Number(valueHeight))}
                        />
                    </div>
                )}

                {genderQuestion?.toLocaleLowerCase() === 'female' && (
                    <div
                        ref={value__ !== '' && gainedWeight && valueHeight !== '' && !pregnancy ? messageRef : null}
                        className={(newFromTrue || isFromHealthData) && !pregnancy ? classes.highlight : classes.main}
                    >
                        <YourBodyPregnant mainData={mainData} isFromHealthData={isFromHealthData} />
                    </div>
                )}

                {isFromHealthData && (
                    <div
                        id={whichIsNull() === 'bloodType' ? 'bloodType' : null}
                        className={!bloodType ? classes.highlight : classes.main}
                    >
                        <YourBodyBloodType mainData={mainData} />
                    </div>
                )}

                {isFromHealthData && (
                    <div
                        id={whichIsNull() === 'disability' ? 'disability' : null}
                        className={!disability ? classes.highlight : classes.main}
                    >
                        <YourBodyDisability mainData={mainData} />
                    </div>
                )}

                {isFromHealthData ? (
                    <>
                        <div style={{ marginBlock: 30 }}>
                            <hr className={classes.divider} />
                        </div>
                        <div
                            id={whichIsNull() === 'cholesterol' ? 'cholesterol' : null}
                            className={!cholesterol ? classes.highlight : classes.main}
                        >
                            <YourBodyCholesterol mainData={mainData} />
                        </div>
                        <div
                            id={whichIsNull() === 'bloodPressure' ? 'bloodPressure' : null}
                            className={!bloodPressure ? classes.highlight : classes.main}
                        >
                            <YourBodyBloodPressure mainData={mainData} />
                        </div>
                        {sBP && dBP && bloodPressure !== 'NO' && (
                            <YourBodyBloodPressureMeter sBP={sBP} dBP={dBP} mainData={mainData} />
                        )}
                    </>
                ) : null}

                {/* action btns  */}
                <div
                    className=""
                    style={{
                        marginTop: 40,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: matches && 'column-reverse',
                    }}
                >
                    {!save_changes_option ? (
                        <OutlinedButton
                            text={mainData?.back_cta[0].text}
                            onPress={() => {
                                BackBtn(step, history, user, isFromHealthData);
                                setNewFromTrue(false);
                            }}
                            fullWidth={matches && true}
                        />
                    ) : (
                        <OutlinedButton
                            text={!isFromHealthData ? mainData?.cancel[0].text : mainData?.back_cta[0].text}
                            onPress={() => {
                                setNewFromTrue(false);
                                Cancel();
                            }}
                            fullWidth={matches && true}
                        />
                    )}
                    {!save_changes_option ? (
                        <FilledButton
                            text={mainData?.continue_cta[0].text}
                            onPress={() => {
                                yourBodyData();
                            }}
                            fullWidth={matches && true}
                            marginBottom={matches && 20}
                        />
                    ) : (
                        <FilledButton
                            text={mainData?.save_cta[0].text}
                            onPress={() => {
                                yourBodyData();
                            }}
                            fullWidth={matches && true}
                            marginBottom={matches && 20}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
