import { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import OutlinedButton from '@/components/OutlinedButton';
import FilledButton from '@/components/FilledButton';
import { CircularProgress } from '@material-ui/core';
import useScrollToTop from '@/hooks/useScrollToTop';
import { colorStyles } from '@/components/Common/BasicStyles';
import clsx from 'clsx';
import ToolTipBox from '@/components/Common/ToolTipBox';
import { useBookingStore, usePersistedPrismicStore, useSearchStore } from '@/store';
import { BookingStep } from '@/components/BookingModal';
import { getConsentTreatments, replaceProfessionalType } from '@/utils/helpers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    heading: {
        ...theme.typography.HL1,
        fontWeight: 'bold',
    },
    contentText: {
        ...theme.typography.HL5,
    },
    mainCenterCircle: {
        display: 'grid',
        height: 330,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    progressBar: {
        color: theme.palette.common.yellow,
    },
    mainButtonsDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
        flexDirection: (props) => props && 'column',
        alignItems: 'center',
    },
}));

const InroPage = ({ doctor, setPatient, mainData, dataSet }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles(matches);
    const { i18n } = useTranslation();
    const colorStyle = colorStyles();
    const [loader, setLoader] = useState(false);
    const [loaderFilled, setLoaderFilled] = useState(false);
    const selectedTreatments = useSearchStore((state) => state.selectedTreatments);
    const treatments = useSearchStore((state) => state.treatments);
    const selectedLocation = useSearchStore((state) => state.selectedLocation);
    const allDocuments = useSearchStore((state) => state.allDocuments);
    const selectedHealthInsurance = useSearchStore((state) => state.selectedHealthInsurance);
    const selectedSpecialty = useSearchStore((state) => state.selectedSpecialty);
    const {
        [i18n.language]: { professionalProfiles: processedProfessionalProfiles },
    } = usePersistedPrismicStore((state) => state.findAppointmentData);

    useScrollToTop();

    const startBooking = (isReturningPatient) => {
        const professional = processedProfessionalProfiles.find(
            (professional) => professional.key[0].text === doctor.key[0].text
        );

        const dcCalendarId = useSearchStore
            .getState()
            .availableProfessionals.find(
                (availableProfessional) => availableProfessional.key[0].text === doctor.key[0].text
            )?.dc_calendar_ids?.[0];

        const secondaryLocations =
            professional.secondary_location_mappings.find(
                (secondaryLocation) => secondaryLocation.professional_type.key[0].text === selectedSpecialty
            )?.secondary_locations ?? [];

        const consentTreatments = getConsentTreatments({
            selectedTreatments,
            treatments,
            selectedLocation,
            allDocuments,
            selectedHealthInsurance,
        });

        const determineBookingStep = () => {
            if (consentTreatments.length > 0) {
                return BookingStep.CONSENT;
            } else if (secondaryLocations.length > 1) {
                return BookingStep.LOCATION_SELECTION;
            } else {
                return BookingStep.TIMESLOT_SELECTION;
            }
        };

        const isOnlineAppointment = useSearchStore.getState().isOnlineAppointment;
        useBookingStore.getState().resetStore();
        useBookingStore.setState({
            isReturningPatient: isReturningPatient ?? false,
            isOnlineAppointment: isOnlineAppointment,
            showReturningPatientToggle: false,
            selectedProfessional: doctor,
            showBookingModal: true,
            consentTreatments: consentTreatments,
            isAtSecondaryLocation: professional.secondary_location_mappings.length > 0 && secondaryLocations.length > 0,
            secondaryLocation: secondaryLocations.length === 1 ? secondaryLocations[0] : null,
            currentStep: determineBookingStep(),
            secondaryLocationOptions:
                professional.secondary_location_mappings.length > 0 && secondaryLocations.length > 1
                    ? secondaryLocations
                    : [],
            dcCalendarId: dcCalendarId,
            isProfessionalSelectionSkipped: false,
        });
    };

    return (
        <div className={classes.mainCenterCircle}>
            {dataSet && (
                <div>
                    <Typography className={clsx(classes.contentText, colorStyle.mt5)}>
                        {replaceProfessionalType({ text: mainData.heading[0].text, language: i18n.language })}
                    </Typography>
                    <div className={classes.mainButtonsDiv}>
                        <ToolTipBox
                            text={
                                doctor?.allow_new_patients === false || !doctor?.is_for_new_patients
                                    ? mainData?.infotip_for_new_patient_cta_disabled?.[0]?.text
                                    : ''
                            }
                        >
                            <div className={colorStyle.m10}>
                                <FilledButton
                                    disabled={
                                        loader || doctor?.allow_new_patients === false || !doctor?.is_for_new_patients
                                    }
                                    text={
                                        !loader ? (
                                            mainData.new_patient_button[0].text
                                        ) : (
                                            <CircularProgress className={classes.progressBar} />
                                        )
                                    }
                                    onPress={() => startBooking(false)}
                                />
                            </div>
                        </ToolTipBox>
                        <ToolTipBox
                            text={
                                !doctor?.is_for_returning_patients
                                    ? mainData?.infotip_for_returning_patient_cta_disabled?.[0]?.text
                                    : ''
                            }
                        >
                            <div>
                                <OutlinedButton
                                    disabled={loaderFilled || !doctor?.is_for_returning_patients}
                                    text={
                                        !loaderFilled ? (
                                            mainData.current_patient_button[0].text
                                        ) : (
                                            <CircularProgress className={classes.progressBar} />
                                        )
                                    }
                                    onPress={() => startBooking(true)}
                                />
                            </div>
                        </ToolTipBox>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InroPage;
