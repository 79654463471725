import { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import FilledButton from '@/components/FilledButton';
import MyAppointmentDetailsCard from './MyAppointmentDetailsCard';
import ic_arrow_down_black from '@/assets/Icons/ic_down_arrow_black.svg';
import DetailShowMore from './DetailShowMore';
import { yourBodyGetAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';
import { Context as AuthContext } from '@/context/AuthContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import ToolTipBox from '@/components/Common/ToolTipBox';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,

        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
    },
    subHeading: {
        ...theme.typography.HL2,
    },

    mainCardHeading: {
        ...theme.typography.HL5,
        fontFamily: 'MessinaSans-SemiBold',
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
        marginRight: 7,
        cursor: 'pointer',
    },
    showDetails: {
        marginTop: 20,
        textAlign: 'center',
        fontFamily: 'MessinaSans-Regular',
        display: 'flex',
        justifyContent: 'center',
    },
    line: {
        height: 1,
        width: '100%',
        backgroundColor: theme.palette.common.mediumBrown,
        marginBottom: 20,
    },
    showAllBtn: {
        marginTop: 20,
    },
}));

const UpcomingCard = ({
    dataSet,
    mainData,
    upComming,
    dataSetDoctos,
    moveAppointmentToCancelled,
    debugingParams = 0,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const { setAllDataApi } = useContext(ContextProviderTwo);

    const {
        state: { user, usersession },
    } = useContext(AuthContext);

    const [showAll, setShowAll] = useState(false);
    const [hideAll, setHideAll] = useState(true);
    const [startDate] = useState(new Date());
    const [resDate, setResDate] = useState();
    console.debug('mainDatadoctos: upComming', upComming);

    useEffect(() => {
        const d = new Date(upComming?.start);
        let resultDate = Math.abs(startDate.getTime() - d.getTime());
        msToTime(resultDate);
    }, [upComming.is_online]);

    function msToTime(s) {
        let mins = Math.floor(s / 60000);
        console.debug('mainDatadoctos: mints', mins, upComming?.is_online);
        setResDate(mins);
    }

    useEffect(() => {
        if (user) {
            yourBodyGetAPI(usersession)
                .then((res) => {
                    console.debug('Body1 get from Upcomming page', res);
                    setAllDataApi(res?.data);
                })
                .catch((error) => {
                    console.debug('body1 error', error);
                });
        }
    }, [debugingParams]);

    let disabledToolTip = !upComming.links['online_url'] || resDate > 30;

    return (
        <>
            <div>
                <div className="">
                    <MyAppointmentDetailsCard
                        dataSet={dataSet}
                        mainData={mainData}
                        upComming={upComming}
                        dataSetDoctos={dataSetDoctos}
                        moveAppointmentToCancelled={moveAppointmentToCancelled}
                    />
                </div>

                {upComming?.is_online && (
                    <ToolTipBox text={disabledToolTip ? mainData?.tool_tip_for_online_appointment[0].text : ''}>
                        <div
                            style={{
                                width: 'fit-content',
                            }}
                        >
                            <FilledButton
                                text={dataSet ? mainData.online_appointment_cta[0].text : 'Join Online Appointment'}
                                onPress={() => {
                                    window.open(upComming.links['online_url'], '_blank', 'noreferrer');
                                }}
                                disabled={disabledToolTip}
                            />
                        </div>
                    </ToolTipBox>
                )}

                {upComming?.status !== 'CANCELLED' && upComming?.is_cancellation_confirmed === false && hideAll && (
                    <div
                        className={classes.showDetails}
                        onClick={() => {
                            setShowAll(true);
                            setHideAll(false);
                        }}
                    >
                        <Typography className={classes.shortText}>
                            {dataSet ? mainData.show_details_cta[0].text : 'Show details'}
                        </Typography>
                        <img src={ic_arrow_down_black} alt="" />
                    </div>
                )}
                {showAll && (
                    <div className={classes.showAllBtn}>
                        <div className={classes.line}></div>

                        <DetailShowMore
                            setShowAll={setShowAll}
                            setHideAll={setHideAll}
                            dataSet={dataSet}
                            mainData={mainData}
                            upComming={upComming}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default UpcomingCard;
