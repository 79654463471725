import { Auth } from 'aws-amplify';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { ContextProvider } from '@/context/ProjectContext';
import { getISODate, replaceDateSeparator } from '@/utils/helpers';

/**
 *
 * @param {*} email
 * @param {*} password
 * @param {*} body
 * @returns [loading, error, signUp]
 */
export const useSignUp = () => {
    // Local States Variables
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    // Context States Variables
    const { languageGlobal } = useContext(ContextProvider);
    const { setSignUpUser } = useContext(ConsentFormsContext);

    // Hooks
    const { i18n } = useTranslation();

    // SignUp Function
    const signUp = useCallback(async (email, password, body) => {
        try {
            setLoading(true); // Loading state
            const { user } = await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email, // optional
                    'custom:firstName': body?.firstName?.trim(),
                    'custom:middleName': body?.middleName ? body?.middleName?.trim() : '',
                    'custom:lastName': body?.lastName?.trim(),
                    'custom:gender': body?.gender?.trim(),
                    'custom:title': body?.title === '-' ? '' : body?.title,
                    'custom:dob': getISODate(replaceDateSeparator(body?.dob, languageGlobal), languageGlobal),
                    'custom:phone': body?.fullNumber?.trim(),
                    'custom:language': i18n.language,
                },
            });

            setSignUpUser(user); /** setting user in consent forms context */
            setUser(user);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(err.message);
        }
    }, []);

    return { signUp, user, loading, error };
};
