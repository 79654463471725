import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import pencilIcon from '@/assets/images/pencilIcon.png';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import summaryMan from '@/assets/images/summaryMan.svg';
import { Context as AuthContext } from '@/context/AuthContext';
import { useStyles } from './SummaryStyles';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import Icon from '@/components/Icon/Icon';

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

export default function SummarYourBody({ mainData, allData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [pregnantStatus, setPregnantStatus] = useState('');
    const [yourWeight, setYourWeight] = useState('');
    const {
        state: { user },
    } = useContext(AuthContext);
    const { allDataApi } = useContext(ContextProviderTwo);

    console.debug('hello8', mainData, user);
    const {
        state: { personal_information, your_body, review_option },
        redirectFunctionSummary,
        setYourBody,
    } = useContext(HealthProfileContextMain);

    let lan = languageMap[i18n.language];

    useLayoutEffect(() => {
        if (allDataApi?.meta?.has_anamnesis && review_option) {
            // your body
            let category1 = allDataApi?.category_data[0].data;
            if (category1['weight'] && category1['weight'] !== undefined && category1['weight'] !== null) {
                console.debug('category1::', category1);
                setYourBody({
                    weight: category1?.weight.value,
                    gainedOrLost: category1?.weight.weight_change?.type
                        ? category1?.weight.weight_change?.type
                        : 'NEITHER',
                    kg: category1?.weight.weight_change?.amount,
                    weeks: category1?.weight.weight_change?.num_weeks,
                    height: category1?.height.value,
                    pergnant: category1?.pregnancy.active ? 'YES' : 'NO',
                    mounth: category1?.pregnancy.months_active,
                });
            }
        }
    }, [allDataApi]);

    console.debug('hello8', your_body);

    useEffect(() => {
        //your body weight difference
        if (
            your_body['gainedOrLost'] !== undefined &&
            your_body['gainedOrLost'] !== null &&
            your_body['gainedOrLost'] !== ''
        ) {
            let gainRlost = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].changed_weight_responses.find((valu) => {
                        if (your_body.gainedOrLost === valu.changed_weight_response_api_value) {
                            gainRlost['en-us'] = valu.changed_weight_response_display_value[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].changed_weight_responses.forEach((valu) => {
                        if (your_body.gainedOrLost === valu.changed_weight_response_api_value) {
                            gainRlost['de-de'] = valu.changed_weight_response_display_value[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', gainRlost[lan]);
            let GorL = gainRlost[lan];
            setYourWeight(GorL);
        }

        if (your_body.pergnant !== undefined && your_body['pergnant'] !== null && your_body['pergnant'] !== '') {
            let pregnantS = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].pregnancy_yes_no_responses.find((valu) => {
                        if (your_body['pergnant'] === valu.pregnancy_yes_no_response_api_value) {
                            pregnantS['en-us'] = valu.pregnancy_response_lables[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].pregnancy_yes_no_responses.forEach((valu) => {
                        if (your_body['pergnant'] === valu.pregnancy_yes_no_response_api_value) {
                            pregnantS['de-de'] = valu.pregnancy_response_lables[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', pregnantS[lan]);
            let curentPreg = pregnantS[lan];
            setPregnantStatus(curentPreg);
        }
    }, [your_body]);

    return (
        <>
            <div className={classes.contentContainerBody}>
                {/* Your Body */}
                <Grid container direction={'row'} style={{ height: '50%' }}>
                    {/* <Grid container direction={"row"} style> */}
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 10 }}>
                        {!matches && <Icon src={summaryMan} description="your body icon" width={48} height={48} />}
                        <Typography className={classes.shortHeading} style={{ marginTop: !matches && 10 }}>
                            {mainData.your_body_title[0].text}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <div className={classes.flexClass}>
                            <img
                                src={pencilIcon}
                                alt=""
                                className={classes.pointer}
                                onClick={() => {
                                    redirectFunctionSummary(history, 2, 'set_your_body', your_body);
                                }}
                            />
                        </div>
                    </Grid>
                    {/* change */}
                    {your_body['weight'] !== undefined &&
                        your_body['weight'] !== null &&
                        your_body['weight'] !== '' && (
                            <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                                        {mainData.subtitle_weight[0].text}
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                                        {your_body.weight} {mainData.kg_text[0].text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                                {mainData.subtitle_weight_difference[0].text}
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            {your_body['gainedOrLost'] !== undefined &&
                            your_body['gainedOrLost'] !== null &&
                            your_body['gainedOrLost'] !== '' &&
                            your_body['kg'] !== undefined &&
                            your_body['kg'] !== null &&
                            your_body['kg'] !== '' &&
                            your_body['weeks'] !== undefined &&
                            your_body['weeks'] !== null &&
                            your_body['weeks'] !== '' &&
                            your_body['gainedOrLost'].toLocaleLowerCase() !== 'neither' ? (
                                <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                                    {(your_body.gainedOrLost.toLocaleLowerCase() === 'gained'
                                        ? mainData.plus_sign[0].text
                                        : '-') +
                                        ' ' +
                                        (your_body.kg !== undefined &&
                                            your_body['kg'] !== null &&
                                            your_body['kg'] !== '' &&
                                            your_body.kg) +
                                        ' ' +
                                        mainData.kg_text[0].text +
                                        ' ' +
                                        mainData.in_text[0].text +
                                        ' ' +
                                        (your_body.weeks !== undefined &&
                                            your_body['weeks'] !== null &&
                                            your_body['weeks'] !== '' &&
                                            your_body.weeks) +
                                        ' ' +
                                        mainData.weeks[0].text}
                                </Typography>
                            ) : (
                                <Typography className={classes.subHeading}>
                                    {yourWeight}
                                    {/* {changed_weight_responses[2]} */}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                                {mainData.subtitle_height[0].text}
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                                {your_body.height !== undefined &&
                                    your_body['height'] !== null &&
                                    your_body['height'] !== '' &&
                                    your_body.height + ' ' + mainData.cm_text[0].text}
                            </Typography>
                        </Grid>
                    </Grid>
                    {personal_information['gender'] !== undefined &&
                        personal_information['gender'] !== null &&
                        personal_information['gender'] !== '' &&
                        personal_information['gender'].toLocaleLowerCase() === 'female' && (
                            <Grid container direction={'row'}>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {
                                        <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                                            {mainData.subtitle_pregnancy_only_if_female[0].text}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    {your_body.pergnant !== undefined &&
                                    your_body['pergnant'] !== null &&
                                    your_body['pergnant'] !== '' &&
                                    your_body['pergnant'] === 'YES' &&
                                    your_body.mounth !== undefined &&
                                    your_body['mounth'] !== null &&
                                    your_body['mounth'] !== '' ? (
                                        <Typography className={classes.subHeading} style={{ marginBottom: 5 }}>
                                            {`${pregnantStatus}. ${mainData.week_text[0].text} ${your_body.mounth}`}
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.subHeading} style={{ marginTop: 5 }}>
                                            {pregnantStatus}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                </Grid>
            </div>
        </>
    );
}
