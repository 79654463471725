import { memo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useStyles } from './LifeStyleStyles';

function LifeStyleButtonComponent({
    data,
    heading = 'heading',
    setSelected,
    selected,
    marginTop = true,
    setIndex,
    isIndexing = false,
}) {
    console.debug('🚀 ~ file: LifeStyleButtonComponent.jsx ~ line 11 ~ LifeStyleButtonComponent ~ data', data);
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const selectItem = (item, index) => {
        setSelected(item);
        isIndexing && setIndex(index);
        console.debug('hours123', item);
    };

    return (
        <>
            <div className={classes.contentContainerBody} style={{ marginTop: marginTop ? selected && 30 : 0 }}>
                <div className={classes.fixedQuestion}>
                    <Typography className={classes.mainHeading}>{heading}</Typography>
                    <Grid
                        style={{
                            marginTop: 15,
                        }}
                        container
                    >
                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                            {data?.length > 0 &&
                                data.map((item, index) => (
                                    <>
                                        <Button
                                            key={index}
                                            onClick={() => {
                                                selectItem(item.label, index);
                                            }}
                                            disableRipple
                                            className={
                                                selected === item.label
                                                    ? clsx(classes.selectItemCard, classes.selectedCard)
                                                    : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                            }
                                            style={{
                                                flex: matches && '1 0 45%',
                                                paddingInline: matches && 2,
                                                textAlign: 'center',
                                            }}
                                        >
                                            <div>
                                                <Typography className={classes.BtncontentText}>{item.value}</Typography>
                                            </div>
                                        </Button>
                                    </>
                                ))}
                        </div>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default memo(LifeStyleButtonComponent);
