import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { usePersistedPrismicStore } from '@/store';
import { Context as AuthContext } from '@/context/AuthContext';
import { useTranslation } from 'react-i18next';
import { ConsentAllFormsInitialRoutes, useConsentFormsContext } from '@/context/ConsentFormsContext';
import useAutoLogoutAndRedirect from '@/hooks/useAutoLogoutAndRedirect';
import FilledButton from '../FilledButton';
import { getText } from '@/utils/helpers';
import usePrismicData from '@/hooks/usePrismicData';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh',
        fontFamily: 'MessinaSans-Regular',
        flexDirection: 'column',
        textAlign: 'center',
        padding: '0 10rem',
        [theme.breakpoints.down('sm')]: {
            padding: '0 5rem',
        },
    },
    text: {
        maxWidth: '50rem',
    },
}));

const ThankYou = () => {
    const {
        state: { user },
    } = useContext(AuthContext);
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { thankYouData },
    } = usePersistedPrismicStore((state) => state.anamnesisData);

    if (Object.keys(thankYouData).length === 0) return null;

    const {
        consentAllForms: { initialRoute },
    } = useConsentFormsContext();

    const shouldLogout = initialRoute === ConsentAllFormsInitialRoutes.ALL_FORMS_CODE;

    // Autologout hook
    const { forceRedirect } = useAutoLogoutAndRedirect(shouldLogout ? '/all-forms-code' : '/appointments', {
        shouldLogout: shouldLogout,
        shouldAutoRedirect: true,
    });
    const prismicData = usePersistedPrismicStore((state) => state.wielandFormsThankYou);
    const { content } = usePrismicData(prismicData);

    return (
        <div className={classes.container}>
            <img src={thankYouData.icon_unregistered.url} alt="" />
            <h2
                className={classes.text}
                style={{
                    lineHeight: '35px',
                }}
            >
                {user
                    ? thankYouData.thank_you_title_2_registered[0].text
                    : thankYouData.thank_you_title_1_unregistered[0].text}
            </h2>
            <p
                className={classes.text}
                style={{
                    lineHeight: '24px',
                }}
            >
                {user
                    ? thankYouData.thank_you_subtitle_2_registered_[0].text
                    : thankYouData.thank_you_subtitle_1_unregistered[0].text}
            </p>
            <div>
                <FilledButton text={getText(content?.all_forms_thankyou_continue)} onPress={forceRedirect} />
            </div>
        </div>
    );
};

export default ThankYou;
