import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useContext, useState } from 'react';
import FilledButton from '../FilledButton';
import UnderlinedButton from '../UnderlinedButton';
import close from '@/assets/Icons/close_grey.svg';
import { AntSwitch } from '../Common/AntSwitchCommon';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        backgroundColor: 'transparent',
    },
    scrollRoot: {
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    text: {
        color: theme.palette.common.darkGrey,
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '17px',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
    title: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        position: 'absolute',
    },
    titleInner: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: 24,
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: -8,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: -8,
            // width: '85%',
        },
    },
    titleInnerSubmitted: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: 24,
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: -8,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: -8,
            // width: '85%',
        },
    },
    // underLine Btn
    detailsButton: {
        background: 'transparent',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    buttonLabel: {
        padding: 0,
    },
    detailsLinks: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        textDecorationLine: 'underline',
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        marginTop: 20,
    },
    antSwitchText: {
        color: theme.palette.common.darkGrey,
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '17px',
    },
    detailsLinksNoMarginTop: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        textDecorationLine: 'underline',
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
    },
    userData: {
        fontFamily: 'MessinaSerif-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
        // marginTop: 10,
    },
    imageButton: {
        position: 'relative',
        marginRight: -10,
        marginTop: -10,
        [theme.breakpoints.down('sm')]: {
            top: -13,
        },
        [theme.breakpoints.down('xs')]: {
            top: -27,
        },
    },
    imageButtonSubmitted: {
        position: 'relative',
        marginRight: -10,
        marginTop: -10,
        [theme.breakpoints.down('sm')]: {
            // top: -13
        },
        [theme.breakpoints.down('xs')]: {
            top: -13,
        },
    },
    contentTextLink: {
        textDecoration: 'none',
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
    subHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        fontWeight: '500',
        color: theme.palette.common.darkGray,
    },
}));

const AnamnesisModal = ({
    open,
    onClose,
    mainData,
    isAlreadySubmitted = false,
    onPressReview,
    onPressFilled,
    loading,
    setOpenPrivacyModal,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesmd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesxl = useMediaQuery(theme.breakpoints.down('xl'));
    const matchesxs = useMediaQuery(theme.breakpoints.down('xs'));

    const [checkedModal, setCheckedModal] = useState(false);
    const { nameOfDoctor } = useContext(ContextProviderTwo);

    return (
        <>
            {mainData && (
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{
                        root: classes.dialogRoot,
                    }}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                            width: '451px',
                            paddingTop: !isAlreadySubmitted
                                ? matchesxs
                                    ? 40
                                    : matches
                                      ? 20
                                      : matchesmd
                                        ? 10
                                        : matchesxl && 10
                                : matchesxs
                                  ? 20
                                  : 10,
                            borderRadius: 8,
                        },
                    }}
                >
                    <DialogActions>
                        <Button
                            className={isAlreadySubmitted ? classes.imageButtonSubmitted : classes.imageButton}
                            onClick={onClose}
                            color="primary"
                            style={{}}
                        >
                            <img
                                src={close}
                                style={{
                                    width: 24,
                                    height: 24,
                                }}
                            />
                        </Button>
                    </DialogActions>
                    <DialogTitle
                        id="alert-dialog-title"
                        classes={{ root: classes.title }}
                        style={{
                            paddingLeft: matches ? 20 : matchesmd ? 30 : matchesxl && 30,
                            top: matches && 25,
                            width: isAlreadySubmitted
                                ? '78%'
                                : mainData?.share_saved_anamnesis_title[0].text.length
                                  ? matchesxs
                                      ? '80%'
                                      : '85%'
                                  : matchesxs
                                    ? '85%'
                                    : null,
                        }}
                    >
                        {isAlreadySubmitted ? (
                            <Typography className={classes.titleInnerSubmitted}>
                                {mainData?.already_submitted_to_this_doctor_title[0].text}
                            </Typography>
                        ) : (
                            <Typography
                                className={classes.titleInner}
                                style={{
                                    wordBreak:
                                        mainData?.share_saved_anamnesis_title[0].text.length &&
                                        matchesxs &&
                                        'break-all',
                                }}
                            >
                                {mainData?.share_saved_anamnesis_title[0].text}
                            </Typography>
                        )}
                    </DialogTitle>

                    <DialogContent
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            padding: 0,
                            paddingLeft: matches ? 0 : matchesmd ? 30 : matchesxl && 30,
                            paddingRight: matches ? 20 : matchesmd ? 30 : matchesxl && 30,
                            paddingBottom: matches ? 20 : matchesmd ? 30 : matchesxl && 30,
                        }}
                    >
                        <DialogContentText
                            id="alert-dialog-description"
                            classes={{
                                root: classes.text,
                            }}
                            style={{
                                marginTop: isAlreadySubmitted
                                    ? 40
                                    : mainData?.share_saved_anamnesis_title[0].text.length > 30
                                      ? matchesxs
                                          ? 40
                                          : matches
                                            ? 30
                                            : matchesxl && 50
                                      : matchesxs
                                        ? 20
                                        : 10,
                                paddingLeft: matches ? 20 : matchesmd ? 0 : matchesxl && 0,
                            }}
                        >
                            {isAlreadySubmitted
                                ? mainData?.subtitle[0].text
                                : mainData?.subtitle_share_anamnesis[0].text.replace(
                                      '[DR. TITLE AND NAME]',
                                      nameOfDoctor
                                  )}
                        </DialogContentText>
                        {!isAlreadySubmitted && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 10,
                                        paddingLeft: matches && 20,
                                    }}
                                >
                                    <AntSwitch
                                        checked={checkedModal}
                                        onChange={() => {
                                            setCheckedModal(!checkedModal);
                                        }}
                                        name="switch"
                                        className={classes.userData}
                                    />
                                    <div className={classes.subHeading}>
                                        <span>{mainData['privacy_policy_text'][0].text}</span>
                                        <Typography
                                            style={{
                                                display: 'inline-block',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setOpenPrivacyModal(true)}
                                            className={classes.contentTextLink}
                                            // href={
                                            //     localDoctorProfile[0]
                                            //         .practitioner_privacy_policy_link_text
                                            //         .path[0].text
                                            // }
                                            // target="_blank"
                                        >
                                            {' '}
                                            {mainData['privacy_policy_link_name'][0].text}
                                        </Typography>
                                        {mainData['2nd_consent_subtitle'][0].text}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        whiteSpace: 'nowrap',
                                        marginTop: 20,
                                        paddingLeft: 10,
                                        gap: 15,
                                    }}
                                >
                                    <FilledButton
                                        text={mainData?.consent_confirm_cta1[0].text}
                                        onPress={onPressFilled}
                                        fullWidth={true}
                                        loading={loading}
                                        disabled={!checkedModal}
                                    />

                                    <div
                                        style={{
                                            display: !matches ? 'flex' : 'contents',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <UnderlinedButton
                                            text={mainData?.review_cta[0].text}
                                            onPress={onPressReview}
                                            noMarginTop={!matches && true}
                                            // marginRight={!matches && 30}
                                            fullWidth={matches && true}
                                            noJSLeft={true}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default AnamnesisModal;
