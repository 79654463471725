import { memo, useContext } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { dayjs } from '@/utils/dayjsSetup';
import { getISODateMonth } from '@/utils/helpers';
import { ContextProvider } from '@/context/ProjectContext';
import { useStyles } from '../DateOfBirh/MainDateStyles';

const DateOfMonthAndYear = ({
    separator,
    setDOB,
    dob,
    setError,
    error,
    errorMessage,
    placeHolderMonth,
    placeHolderYear,
    mainHeading,
    isOnBlur = false,
    onBlur,
}) => {
    const classes = useStyles();
    let testingRegex = /[a-z||A-Z||&+,:;=?@#|'<>^*()%!-]/;
    const { languageGlobal } = useContext(ContextProvider);

    const checkDateFormat = (event) => {
        let text = event.target.value;
        let formatedDate;

        if (text.length >= 7) {
            formatedDate = getISODateMonth(text, languageGlobal);
        }
        let year = dayjs().diff(dayjs(formatedDate, 'YYYY-MM'), 'years');
        let monthDifference = dayjs().diff(dayjs(formatedDate, 'YYYY-MM'), 'month');
        let year1900 = dayjs().diff(dayjs('1900-01-01', 'YYYY-MM'), 'years');

        if (dob.length > text.length && (text.length === 2 || text.length === 5)) {
            setDOB(text.slice(0, text.length - 1));
            return;
        }
        if (dob.length < text.length && text.length === 2) {
            text += separator;
        }
        if (text.length > 7) {
            return;
        }
        setDOB(text);

        if (text.length === 7) {
            setError(false);
            if (isNaN(year) || year > year1900 || Math.sign(monthDifference) === -1) {
                setError(true);
            }
        }

        if (!dayjs(formatedDate, 'YYYY-MM', true).isValid()) {
            setError(true);
        }
    };

    const validateForm = () => {
        if (dob.length < 7) {
            setError(true);
        }
    };
    console.debug('index: dob', dob);
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        id="monthAndYear"
                        autoComplete="off"
                        label={mainHeading}
                        // type="number"
                        onBlur={(e) => {
                            isOnBlur && onBlur(e);
                            dob.length >= 1 ? validateForm() : setError(false);
                        }}
                        value={dob
                            .replaceAll(languageGlobal === 'en' && '.', '/')
                            .replaceAll(languageGlobal === 'de' && '/', '.')
                            .replace(/[a-z||A-Z||&+,:;=?@#|'<>^*()%!-]/, '')}
                        onChange={(e) => {
                            if (testingRegex.test(e.target.value)) {
                                return;
                            }
                            checkDateFormat(e);
                        }}
                        variant="filled"
                        color="primary"
                        fullWidth
                        // onBlur={() => validateForm()}
                        placeholder={placeHolderMonth + separator + placeHolderYear}
                        // style={{ marginTop: 15 }}
                        classes={{
                            root: error ? classes.textFieldsError : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                </Grid>
            </Grid>
            {error && (
                <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                    {errorMessage}
                </Typography>
            )}
        </>
    );
};

export default memo(DateOfMonthAndYear);
