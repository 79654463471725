import { memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { overviewStyles } from '../OverviewComponents/OverviewStyles';
import PlusIcon from '@/assets/Icons/PlusIcon.svg';
import clsx from 'clsx';

const EmptyDataBox = ({ text }) => {
    const classes = overviewStyles();
    return (
        <Box className={clsx(classes.emptyBox, classes.pb30)}>
            <img src={PlusIcon} className={classes.medicalIcon} />
            <Typography className={classes.emptyText} variant="h5">
                {text}
            </Typography>
        </Box>
    );
};

export default memo(EmptyDataBox);
