import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },
    formControl: {
        width: '100%',
        paddingTop: 14,
        paddingBottom: 14,
        height: 59,

        justifyContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            height: '4.2em',
        },
        borderTopLeftRadius: 5,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        '&:hover': {
            backgroundColor: 'white',
        },
    },

    downArrow: {
        height: '0.65em',
    },

    form: {
        marginTop: 23,
    },
    textFields: {
        height: '3.75em',
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
    },

    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: 59,
        '&:hover': {
            backgroundColor: '#F9F8F4',
            //backgroundColor: "red",
        },
        borderLeft: 'none',
    },
    coloredRoot: {
        borderBottomRightRadius: 0,
        height: '3.45em',
        borderBottom: `3px solid ${theme.palette.common.yellow}`,
        borderLeft: `3px solid ${theme.palette.common.yellow}`,
    },
    imges: {
        paddingLeft: 12,
    },
    ButtonTextClassMain: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.9rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
        textAlign: 'center',
    },
    redBorder: {
        border: `1px solid ${theme.palette.common.red}`,
        borderRadius: '8px',
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
}));
