import EternoSpinner from '@/components/EternoLogoSpinner/EternoSpinner';
import { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useQuery } from '@/utils/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import {
    consentFormServicePublic,
    consentFormServiceSecure,
    consentFormServiceUpdate,
    getSingleFormTemplate,
} from '@/service/ConsentForms/consentFormService';
import StepsCard from '@/components/CustomAnamnesis/StepsCard';
import { useCustomAnamnesisStore, usePersistedPrismicStore, useProfessionalsStore } from '@/store';
import CardContainer from '@/components/Common/CardContainer';
import { Typography, useMediaQuery } from '@material-ui/core';
import BorderLinearProgress from '@/components/Common/BorderLinearProgress';
import { useTranslation } from 'react-i18next';
import OutlinedButton from '@/components/OutlinedButton';
import FilledButton from '@/components/FilledButton';
import clsx from 'clsx';
import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';
import { WelcomeComponent } from '@/components/ConsentForms';
import { Context as AuthContext } from '@/context/AuthContext';
import PrivacyPolicy from '@/components/CustomAnamnesis/PrivacyPolicy';
import DropdownQuestion from '@/components/CustomAnamnesis/QuestionTypes/DropdownQuestion';
import TextfieldQuestion, { TextValidation } from '@/components/CustomAnamnesis/QuestionTypes/TextfieldQuestion';
import TextareaQuestion from '@/components/CustomAnamnesis/QuestionTypes/TextareaQuestion';
import NumberfieldQuestion, { NumberValidation } from '@/components/CustomAnamnesis/QuestionTypes/NumberfieldQuestion';
import DatefieldQuestion from '@/components/CustomAnamnesis/QuestionTypes/DatefieldQuestion';
import RadioQuestion from '@/components/CustomAnamnesis/QuestionTypes/RadioQuestion';
import MultiselectQuestion from '@/components/CustomAnamnesis/QuestionTypes/MultiselectQuestion';
import ListQuestion from '@/components/CustomAnamnesis/QuestionTypes/ListQuestion';
import PersonalInformationCategory from '@/components/CustomAnamnesis/PersonalInformationCategory';
import isEmail from 'validator/es/lib/isEmail';
import ThankYou from '@/components/CustomAnamnesis/ThankYou';
import isInt from 'validator/es/lib/isInt';
import isDecimal from 'validator/es/lib/isDecimal';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { dayjs } from '@/utils/dayjsSetup';
import useProfessionalMappings from '@/hooks/useProfessionalMappings';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    page: {
        display: 'flex',
        padding: '0 50px',
        flexDirection: 'column',
        width: '100%',
        maxWidth: theme.layout.contentWidth,
        fontFamily: 'MessinaSans-Regular',
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px',
        },
    },
    contentContainer: {
        display: 'flex',
        gap: '3rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh',
        fontFamily: 'MessinaSans-Regular',
    },
    questionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '3rem',
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
    },
    progressContainer: {
        width: 200,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    progressText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
        width: 300,
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap',
        },
    },
    navigationContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '1rem',
        },
    },
}));

export const QuestionComponent = {
    Dropdown: DropdownQuestion,
    Textfield: TextfieldQuestion,
    'Text Area': TextareaQuestion,
    'Number Field': NumberfieldQuestion,
    'Date Field': DatefieldQuestion,
    Radio: RadioQuestion,
    'Multi Select': MultiselectQuestion,
    List: ListQuestion,
};

export const QuestionType = {
    DROPDOWN: 'Dropdown',
    TEXTFIELD: 'Textfield',
    TEXT_AREA: 'Text Area',
    NUMBER_FIELD: 'Number Field',
    DATE_FIELD: 'Date Field',
    RADIO: 'Radio',
    MULTI_SELECT: 'Multi Select',
    LIST: 'List',
};

const personalInfoCategory = {
    position: 1,
    name_de: 'Persönliche Informationen',
    name_en: 'Personal Information',
    questions: [
        {
            key: 'first-name',
            is_required: true,
        },
        {
            key: 'last-name',
            is_required: true,
        },
        {
            key: 'date-of-birth',
            is_required: true,
        },
        {
            key: 'email',
            is_required: true,
            textfield_validation: TextValidation.EMAIL,
        },
    ],
};

const CustomAnamnesis = () => {
    const classes = useStyles();
    const {
        state: { user },
    } = useContext(AuthContext);
    const query = useQuery();
    const history = useHistory();
    const location = useLocation();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [isPrivacyPolicyConfirmed, setIsPrivacyPolicyConfirmed] = useState(false);
    const [openPrivacyPolicyPopup, setOpenPrivacyPolicyPopup] = useState(false);
    const { [i18n.language]: anamnesisData } = usePersistedPrismicStore((state) => state.anamnesisData);
    const prismicData = useCustomAnamnesisStore((state) => state.prismicData);
    const loading = useCustomAnamnesisStore((state) => state.loading);
    const hasFailed = useCustomAnamnesisStore((state) => state.hasFailed);
    const anamnesisTemplate = useCustomAnamnesisStore((state) => state.anamnesisTemplate);
    const questions = useCustomAnamnesisStore((state) => state.questions);
    const subQuestions = useCustomAnamnesisStore((state) => state.subQuestions);
    const currentCategory = useCustomAnamnesisStore((state) => state.currentCategory);
    const answers = useCustomAnamnesisStore((state) => state.answers);
    const loginRedirect = useCustomAnamnesisStore((state) => state.loginRedirect);
    const professional = useCustomAnamnesisStore((state) => state.professional);
    const isPreview = useCustomAnamnesisStore((state) => state.isPreview);
    const isFormSent = useCustomAnamnesisStore((state) => state.isFormSent);
    const professionalMappings = useProfessionalsStore((state) => state.professionalMappings);
    const { formData, setFormData } = useContext(ConsentFormsContext);

    const currentCategoryHasAnswers = useMemo(
        () => currentCategory?.position && answers[currentCategory.position],
        [answers, currentCategory]
    );
    useProfessionalMappings();

    const startAnamnesis = () => {
        const sortedCategories = anamnesisTemplate.content.categories.sort((a, b) => a.position - b.position);

        useCustomAnamnesisStore.setState({
            currentCategory: sortedCategories[0],
            loginRedirect: false,
        });
    };

    const startAnamnesisWithAccount = () => {
        if (!user && !isPreview) {
            history.push(`/login?redirectTo=${location.pathname}${location.search}}`);
        } else startAnamnesis();
    };

    const updateAnswer = (value, questionKey) => {
        useCustomAnamnesisStore.setState({
            answers: {
                ...answers,
                [currentCategory.position]: {
                    ...answers[currentCategory.position],
                    [questionKey]: value,
                },
            },
        });
    };

    const getTemplate = async (templateId = null) => {
        const template = await getSingleFormTemplate(
            query.get('professional_id'),
            templateId ?? query.get('template_id')
        );

        if (!template) {
            useCustomAnamnesisStore.setState({ loading: false, hasFailed: true });
            return;
        }

        const updatedTemplate = {
            ...template,
            content: {
                ...template.content,
                categories: [
                    personalInfoCategory,
                    ...template.content.categories.map((category) => ({
                        ...category,
                        position: category.position + 1,
                    })),
                ],
            },
        };

        useCustomAnamnesisStore.setState({
            anamnesisTemplate: updatedTemplate,
            loading: false,
            hasFailed: false,
            storedProfessionalId: query.get('professional_id'),
            storedTemplateId: query.get('template_id'),
            currentCategory: null,
        });
    };

    const resetFormProgress = () => {
        useCustomAnamnesisStore.setState({
            answers: {},
            highlightedQuestions: {},
            completedCategories: {},
        });
        setIsPrivacyPolicyConfirmed(false);
    };

    const transformAnswer = (questionKey, categoryPosition) => {
        switch (questions[questionKey].question_type) {
            case QuestionType.TEXTFIELD:
            case QuestionType.TEXT_AREA:
            case QuestionType.NUMBER_FIELD:
            case QuestionType.DATE_FIELD:
            case QuestionType.DROPDOWN:
                return {
                    key: questionKey,
                    data: answers[categoryPosition][questionKey],
                };
            case QuestionType.RADIO:
                return {
                    key: questionKey,
                    data: [
                        {
                            value: answers[categoryPosition][questionKey].answer,
                            answers: Object.keys(answers[categoryPosition][questionKey].subQuestions).map(
                                (subQuestionKey) => ({
                                    key: subQuestionKey,
                                    value: answers[categoryPosition][questionKey].subQuestions[subQuestionKey],
                                })
                            ),
                        },
                    ],
                };
            case QuestionType.MULTI_SELECT:
                return {
                    key: questionKey,
                    data: Object.keys(answers[categoryPosition][questionKey]).map((value) => ({
                        value,
                        answers: Object.keys(answers[categoryPosition][questionKey][value].subQuestions).map(
                            (subQuestionKey) => ({
                                key: subQuestionKey,
                                value: answers[categoryPosition][questionKey][value].subQuestions[subQuestionKey],
                            })
                        ),
                    })),
                };
            case QuestionType.LIST:
                return {
                    key: questionKey,
                    data: answers[categoryPosition][questionKey].map((listAnswer) => ({
                        values: Object.keys(listAnswer).map((subQuestionKey) => ({
                            key: subQuestionKey,
                            value: listAnswer[subQuestionKey],
                        })),
                    })),
                };
            default:
                return null;
        }
    };

    const transformCategoryData = () => {
        const categoryData = {
            user_id: user.id,
            category: `${anamnesisTemplate.id}_${currentCategory.name_en}`,
            template_id: anamnesisTemplate.id,
            data: {
                answers: Object.keys(answers[currentCategory.position] ?? {}).map((questionKey) =>
                    transformAnswer(questionKey, currentCategory.position)
                ),
            },
        };

        return categoryData;
    };

    const transformFormData = () => {
        const copiedCategories = [...anamnesisTemplate.content.categories];
        copiedCategories.shift();

        const formData = {
            instances: [
                {
                    type: 'custom',
                    id: anamnesisTemplate.id,
                },
            ],
            personal_data: {
                professional: {
                    id: anamnesisTemplate.professional.id,
                    key: anamnesisTemplate.professional.key,
                    name: professional.display_name[0].text,
                },
                personal: {
                    first_name: answers['1']['first-name'].trim(),
                    last_name: answers['1']['last-name'].trim(),
                    date_of_birth: answers['1']['date-of-birth'],
                    email: answers['1']['email'],
                },
            },
            custom_data: [
                {
                    template_id: anamnesisTemplate.id,
                    categories: copiedCategories.map((category) => ({
                        category: `${anamnesisTemplate.id}_${category.name_en}`,
                        is_custom: true,
                        template_id: anamnesisTemplate.id,
                        data: {
                            answers: Object.keys(answers[category.position] ?? {}).map((questionKey) =>
                                transformAnswer(questionKey, category.position)
                            ),
                        },
                    })),
                },
            ],
        };
        return formData;
    };

    const handleSuccess = async () => {
        useCustomAnamnesisStore.setState({
            loading: false,
            currentCategory: null,
        });

        const { redirectToConsentForms, currentForm, formsToFill } = useCustomAnamnesisStore.getState();

        if (!formsToFill || (formsToFill && formsToFill.length === currentForm && !redirectToConsentForms)) {
            useCustomAnamnesisStore.setState({ isFormSent: true });
            return;
        }

        if (formsToFill.length === currentForm && redirectToConsentForms) {
            history.push('/consent-all-forms-welcome');
        } else {
            useCustomAnamnesisStore.setState({
                currentForm: currentForm + 1,
            });
            resetFormProgress();
            history.push(
                `/custom-anamnesis?professional_id=${anamnesisTemplate.professional.id}&template_id=${formsToFill[currentForm].id}`
            );
            await getTemplate(formsToFill[currentForm].id);
        }
    };

    const shareForm = async () => {
        useCustomAnamnesisStore.setState({ loading: true });
        try {
            if (currentCategoryHasAnswers) {
                const isUpdated = await updateCategoryData();
                if (!isUpdated) {
                    useCustomAnamnesisStore.setState({ loading: false });
                    return;
                }
            }

            const professionalMapping = professionalMappings[professional.key[0].text];

            if (!professionalMapping?.dc_user_id) {
                useCustomAnamnesisStore.setState({ loading: false });
                return;
            }

            const shareFormData = {
                user_id: user.id,
                instances: [
                    {
                        type: 'custom',
                        id: anamnesisTemplate.id,
                    },
                ],
                professional: {
                    id: anamnesisTemplate.professional.id,
                    key: anamnesisTemplate.professional.key,
                    name: professional.display_name[0].text,
                    ext_id: professionalMapping.dc_user_id,
                },
                consent: true,
            };

            const response = await consentFormServiceSecure(shareFormData, 'v2');
            if (!response?.status || response.status === 504 || (response.status >= 200 && response.status < 300)) {
                await handleSuccess();
            } else {
                useCustomAnamnesisStore.setState({ loading: false, hasFailed: true });
            }
        } catch (e) {
            console.debug(e);
            useCustomAnamnesisStore.setState({ loading: false, hasFailed: true });
        }
    };

    const sendForm = async () => {
        useCustomAnamnesisStore.setState({ loading: true });
        const formData = transformFormData();

        try {
            const response = await consentFormServicePublic(formData, 'v2');

            if (!response?.status || response.status === 504 || (response.status >= 200 && response.status < 300)) {
                await handleSuccess();
            } else {
                useCustomAnamnesisStore.setState({ loading: false, hasFailed: true });
            }
        } catch (e) {
            console.debug(e);
            useCustomAnamnesisStore.setState({ loading: false, hasFailed: true });
        }
    };

    const updateCategoryData = async () => {
        try {
            const categoryData = transformCategoryData();
            const response = await consentFormServiceUpdate(categoryData);

            return response.status >= 200 && response.status < 300;
        } catch (e) {
            console.debug(e);
            return false;
        }
    };

    const verifyAnswers = () => {
        const savedHighlightedQuestion = useCustomAnamnesisStore.getState().highlightedQuestionForScrolling;
        let result = true;
        let highlightedQuestionForScrolling = null;
        let missingQuestions = {};

        if (currentCategory.position === 1) {
            currentCategory.questions.forEach((question) => {
                if (
                    (question.is_required && !answers[currentCategory.position]?.[question.key]) ||
                    (answers[currentCategory.position]?.[question.key] &&
                        question.textfield_validation === TextValidation.EMAIL &&
                        !isEmail(answers[currentCategory.position]?.[question.key]))
                ) {
                    result = false;
                    missingQuestions[question.key] = true;
                    if (!highlightedQuestionForScrolling) highlightedQuestionForScrolling = question.key;
                }
            });

            useCustomAnamnesisStore.setState({
                highlightedQuestions: missingQuestions,
                highlightedQuestionForScrolling: {
                    question: highlightedQuestionForScrolling,
                    amount:
                        savedHighlightedQuestion?.question === highlightedQuestionForScrolling
                            ? savedHighlightedQuestion.amount + 1
                            : 1,
                },
            });

            return result;
        }

        currentCategory.questions.forEach((question) => {
            if (!question.is_required && !answers[currentCategory.position]?.[question.key]) return;

            if (
                question.is_required &&
                !answers[currentCategory.position]?.[question.key] &&
                questions[question.key].question_type !== QuestionType.LIST
            ) {
                result = false;
                missingQuestions[question.key] = true;
                if (!highlightedQuestionForScrolling) highlightedQuestionForScrolling = question.key;
                return;
            }

            switch (questions[question.key].question_type) {
                case QuestionType.RADIO:
                    const selectedOption = questions[question.key].radio_options.find(
                        (option) =>
                            option.radio_option[0].text === answers[currentCategory.position]?.[question.key]?.answer
                    );

                    if (!selectedOption?.radio_option_has_subquestion) return;

                    questions[question.key].radio_subquestions.forEach(({ radio_subquestion }) => {
                        if (
                            !subQuestions[radio_subquestion.id]?.question_key[0].text ||
                            answers[currentCategory.position]?.[question.key]?.subQuestions[
                                subQuestions[radio_subquestion.id]?.question_key[0].text
                            ]
                        ) {
                            return;
                        }

                        result = false;
                        missingQuestions[question.key] = true;
                        if (!highlightedQuestionForScrolling) highlightedQuestionForScrolling = question.key;
                    });
                    break;
                case QuestionType.MULTI_SELECT:
                    if (Object.keys(answers[currentCategory.position]?.[question.key]).length === 0) break;
                    const selectedOptions = Object.keys(answers[currentCategory.position]?.[question.key]).map(
                        (selectedOption) =>
                            questions[question.key].multi_select_options.find(
                                (option) => option.multi_select_option[0].text === selectedOption
                            )
                    );

                    selectedOptions.forEach((selectedOption) => {
                        if (!selectedOption?.multi_select_option_has_subquestion) return;

                        questions[question.key].multi_select_subquestions.forEach(({ multi_select_subquestion }) => {
                            if (
                                !subQuestions[multi_select_subquestion.id]?.question_key[0].text ||
                                answers[currentCategory.position]?.[question.key]?.[
                                    selectedOption.multi_select_option[0].text
                                ]?.subQuestions[subQuestions[multi_select_subquestion.id]?.question_key[0].text]
                            ) {
                                return;
                            }

                            result = false;
                            missingQuestions[question.key] = true;
                            if (!highlightedQuestionForScrolling) highlightedQuestionForScrolling = question.key;
                        });
                    });
                    break;
                case QuestionType.TEXTFIELD:
                    if (
                        answers[currentCategory.position]?.[question.key] &&
                        questions[question.key].textfield_validation === TextValidation.EMAIL &&
                        !isEmail(answers[currentCategory.position]?.[question.key])
                    ) {
                        result = false;
                        missingQuestions[question.key] = true;
                        if (!highlightedQuestionForScrolling) highlightedQuestionForScrolling = question.key;
                    }
                    break;
                case QuestionType.NUMBER_FIELD:
                    switch (questions[question.key].number_field_validation) {
                        case NumberValidation.WHOLE_NUMBER:
                            if (!isInt(answers[currentCategory.position]?.[question.key])) {
                                result = false;
                                missingQuestions[question.key] = true;
                                if (!highlightedQuestionForScrolling) highlightedQuestionForScrolling = question.key;
                            }
                            break;
                        case NumberValidation.DECIMAL_NUMBER:
                        default:
                            if (!isDecimal(answers[currentCategory.position]?.[question.key])) {
                                result = false;
                                missingQuestions[question.key] = true;
                                if (!highlightedQuestionForScrolling) highlightedQuestionForScrolling = question.key;
                            }
                            break;
                    }
                    break;
                default:
                    break;
            }
        });

        useCustomAnamnesisStore.setState({
            highlightedQuestions: missingQuestions,
            highlightedQuestionForScrolling: {
                question: highlightedQuestionForScrolling,
                amount:
                    savedHighlightedQuestion?.question === highlightedQuestionForScrolling
                        ? savedHighlightedQuestion.amount + 1
                        : 1,
            },
        });

        return result;
    };

    const goToNextCategory = async () => {
        useCustomAnamnesisStore.setState({ highlightedQuestionForScrolling: null });
        const completedCategories = useCustomAnamnesisStore.getState().completedCategories;

        if (!isPreview) {
            const isVerified = verifyAnswers();
            if (!isVerified) {
                useCustomAnamnesisStore.setState({
                    completedCategories: { ...completedCategories, [currentCategory.position]: false },
                });
                return;
            }
        }

        if (currentCategory.position === anamnesisTemplate.content.categories.length) {
            if (!isPreview) {
                if (user) await shareForm();
                else await sendForm();
            }
            return;
        }

        if (!user && currentCategory.position === 1) {
            setFormData({
                ...formData,
                firstName: answers['1']['first-name'].trim(),
                lastName: answers['1']['last-name'].trim(),
                dob: dayjs(answers['1']['date-of-birth']).format('DD.MM.YYYY'),
                email: answers['1']['email'],
            });
        }

        if (user && currentCategory.position !== 1 && currentCategoryHasAnswers) {
            useCustomAnamnesisStore.setState({ loading: true });
            const isUpdated = await updateCategoryData();
            useCustomAnamnesisStore.setState({ loading: false });
            if (!isUpdated) return;
        }

        const newCurrentCategory = anamnesisTemplate.content.categories.find(
            (category) => category.position === currentCategory.position + 1
        );

        if (!newCurrentCategory) return;

        useCustomAnamnesisStore.setState({
            currentCategory: newCurrentCategory,
            completedCategories: { ...completedCategories, [currentCategory.position]: true },
        });
    };

    const goToPreviousCategory = () => {
        if (currentCategory.position === 1) return;

        const newCurrentCategory = anamnesisTemplate.content.categories.find(
            (category) => category.position === currentCategory.position - 1
        );

        if (!newCurrentCategory) return;

        useCustomAnamnesisStore.setState({ currentCategory: newCurrentCategory });
    };

    useEffect(() => {
        if (
            loading &&
            query.get('professional_id') &&
            query.get('template_id') &&
            (!anamnesisTemplate || anamnesisTemplate.id !== query.get('template_id'))
        ) {
            getTemplate();
        }
        if (!query.get('professional_id') || !query.get('template_id')) {
            const customAnamnesisStore = useCustomAnamnesisStore.getState();
            if (customAnamnesisStore.storedProfessionalId && customAnamnesisStore.storedTemplateId) {
                useCustomAnamnesisStore.setState({ loginRedirect: true });
                history.replace(
                    `/custom-anamnesis?professional_id=${customAnamnesisStore.storedProfessionalId}&template_id=${customAnamnesisStore.storedTemplateId}`
                );
            } else history.replace('/');
        }
        if (query.get('preview') === 'true') useCustomAnamnesisStore.setState({ isPreview: true });
        if (anamnesisTemplate?.id === query.get('template_id')) useCustomAnamnesisStore.setState({ loading: false });
    }, []);

    useEffect(() => {
        if (!anamnesisData?.allData || Object.keys(anamnesisData.allData).length === 0) return;

        const languageMap = {
            en: 'en-us',
            de: 'de-de',
        };

        useCustomAnamnesisStore.setState({ prismicData: anamnesisData.allData[languageMap[i18n.language]] });
    }, [i18n.language, anamnesisData]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [currentCategory, loading]);

    useEffect(() => {
        if (anamnesisTemplate && Object.keys(anamnesisData.mainDataProfessionalDoctors).length > 0 && !professional) {
            useCustomAnamnesisStore.setState({
                professional: anamnesisData.mainDataProfessionalDoctors.find(
                    (professional) => professional.key[0].text === anamnesisTemplate?.professional.key
                ),
            });
        }
    }, [anamnesisTemplate, anamnesisData]);

    useEffect(() => {
        if (anamnesisTemplate && loginRedirect) startAnamnesis();
    }, [loginRedirect]);

    if (loading || hasFailed) {
        return <div className={classes.spinnerContainer}>{loading ? <EternoSpinner /> : <h5>FAIL</h5>}</div>;
    }

    if (isFormSent) return <ThankYou />;

    if (!loading && !hasFailed && !currentCategory) {
        return (
            <WelcomeComponent
                width={'70% !important'}
                icon={WelcomeIcon}
                pages={[]}
                title={anamnesisTemplate[`form_name_${i18n.language}`]}
                paragraphs={[anamnesisData?.welcomeData?.welcome_anamnesis_description?.[0]?.text]}
                primaryBtnText={
                    !isPreview
                        ? (anamnesisData?.welcomeData?.login_cta?.[0]?.text ?? 'Continue with your eterno account')
                        : (prismicData?.start_preview?.[0]?.text ?? 'Start Preview')
                }
                continueBtnText={anamnesisData?.welcomeData?.lets_start_cta?.[0]?.text ?? 'Continue as guest'}
                continueText={
                    !user &&
                    !isPreview &&
                    (anamnesisData?.welcomeData?.login_title?.[0]?.text ?? 'Sie haben noch kein Eterno-Konto?')
                }
                onPrimaryBtnClick={startAnamnesisWithAccount}
                onContinueBtnClick={startAnamnesis}
            />
        );
    }

    return (
        <div className={classes.container}>
            <div className={classes.page}>
                <h2>{anamnesisTemplate[`form_name_${i18n.language}`]}</h2>
                <div className={classes.contentContainer}>
                    <div>
                        <StepsCard />
                    </div>
                    <CardContainer classNames={[classes.questionsContainer]}>
                        <div style={{ width: '100%' }}>
                            <div className={classes.progressContainer}>
                                <Typography className={classes.progressText}>
                                    {`${currentCategory.position}/${anamnesisTemplate.content.categories.length} ${
                                        currentCategory[`name_${i18n.language}`]
                                    }`}
                                </Typography>
                                <BorderLinearProgress
                                    variant="determinate"
                                    value={
                                        (currentCategory.position * 100) / anamnesisTemplate.content.categories.length
                                    }
                                />
                            </div>
                        </div>
                        {currentCategory.position !== 1 ? (
                            currentCategory.questions.map((question) => {
                                if (!questions[question.key]) {
                                    useCustomAnamnesisStore.setState({ hasFailed: true });
                                    return null;
                                }
                                const Question = QuestionComponent[questions[question.key].question_type];
                                return (
                                    <Question
                                        question={questions[question.key]}
                                        answer={answers[currentCategory.position]?.[question.key]}
                                        setAnswer={(value) => updateAnswer(value, question.key)}
                                    />
                                );
                            })
                        ) : (
                            <PersonalInformationCategory />
                        )}
                        {currentCategory.position === anamnesisTemplate.content.categories.length && (
                            <PrivacyPolicy
                                openPrivacyPolicyPopup={openPrivacyPolicyPopup}
                                setOpenPrivacyPolicyPopup={setOpenPrivacyPolicyPopup}
                                isPrivacyPolicyConfirmed={isPrivacyPolicyConfirmed}
                                setIsPrivacyPolicyConfirmed={setIsPrivacyPolicyConfirmed}
                            />
                        )}
                        <div className={clsx(classes.navigationContainer)}>
                            <OutlinedButton
                                onPress={goToPreviousCategory}
                                disabled={currentCategory.position === 1}
                                text={prismicData.back_cta?.[0]?.text ?? 'Zurück'}
                                fullWidth={matchesXs}
                            />
                            <FilledButton
                                onPress={goToNextCategory}
                                disabled={
                                    currentCategory.position === anamnesisTemplate.content.categories.length &&
                                    !isPrivacyPolicyConfirmed
                                }
                                text={
                                    currentCategory.position !== anamnesisTemplate.content.categories.length
                                        ? (prismicData.continue_cta?.[0]?.text ?? 'Weiter')
                                        : anamnesisData.mainDataSummary.consent_confirm_cta[0].text
                                }
                                fullWidth={matchesXs}
                            />
                        </div>
                    </CardContainer>
                </div>
            </div>
        </div>
    );
};

export default CustomAnamnesis;
