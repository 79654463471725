import { Slider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';
import ToolTipBox from '@/components/Common/ToolTipBox';
import info from '@/assets/Icons/info.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    sliderContainer: {
        background: '#F5F5F5',
        padding: '20px',
        borderRadius: '8px',
        marginBlock: '20px',
    },
    slider: {
        '& .MuiSlider-root': {
            pointerEvents: 'none',
        },
        '& .MuiSlider-track': {
            background: theme.palette.common.secondary,
            height: '5px',
            borderRadius: '3px',
        },
        '& .MuiSlider-rail': {
            background: '#D5D5D5',
            height: '5px',
            borderRadius: '3px',
        },
        '& .MuiSlider-mark': {
            background: 'FFFFFF',
            opacity: '70%',
            height: '5px',
        },
        '& .MuiSlider-thumb': {
            display: 'none',
            pointerEvents: 'none',
        },
    },
    sliderText: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    textHeading: {
        width: 'fit-content',
        fontSize: '18px',
        fontFamily: 'MessinaSans-Regular',
    },
    textBmi: { fontSize: '24px', fontFamily: 'MessinaSans-SemiBold' },
    infoIconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    textContainer: { display: 'flex', justifyContent: 'space-around' },
    textCol: {
        textAlign: 'center',
        color: theme.palette.common.brown,
        width: '20%',
        wordBreak: 'break-word',
    },
    textColActive: { color: theme.palette.common.darkGrey, fontFamily: 'MessinaSans-SemiBold' },
    colText: {
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 500,
        fontSize: '1rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
}));

const bmiRanges = {
    UNDERWEIGHT: 'Underweight',
    NORMAL: 'Normal',
    OVERWEIGHT: 'Overweight',
    OBESITY: 'Obesity',
};

const setBMIRange = (bmi) => {
    if (bmi < 18.5) {
        return bmiRanges.UNDERWEIGHT;
    } else if (bmi >= 18.5 && bmi < 25) {
        return bmiRanges.NORMAL;
    } else if (bmi >= 25 && bmi < 30) {
        return bmiRanges.OVERWEIGHT;
    } else if (bmi > 30) {
        return bmiRanges.OBESITY;
    }
};

function valuetext(value) {
    return `${value}°C`;
}

const YourBodyBmi = ({ mainData, bmi = 40 }) => {
    const classes = useStyles();
    console.debug('YourBodyBmi ~ mainData:', mainData);

    // geting current bmi status
    const bmiRange = setBMIRange(Number(bmi));

    const highlightRange = (RANGE) => (bmiRange === RANGE ? classes.textColActive : null);

    return (
        <div className={classes.sliderContainer} style={{ marginTop: 40 }}>
            <div className={classes.sliderText}>
                <Typography className={classes.textHeading}>{mainData?.your_bmi_title[0].text}:</Typography>{' '}
                <Typography className={classes.textBmi}>{bmi}</Typography>
                <ToolTipBox
                    text={mainData?.your_bmi_tool_tip.map((paragraph) => {
                        return <p>{paragraph.text}</p>;
                    })}
                >
                    <div className={classes.infoIconContainer}>
                        <img src={info} />
                    </div>
                </ToolTipBox>
            </div>
            <Slider
                className={classes.slider}
                value={bmi ? bmi : null}
                disabled
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                onChange={() => {}}
                step={1}
                marks
                min={18}
                max={30}
            />
            <div className={classes.textContainer}>
                <div className={classes.textCol}>
                    <Typography className={clsx(classes.colText, highlightRange(bmiRanges.UNDERWEIGHT))}>
                        {'<18.5'}
                    </Typography>
                    <Typography className={clsx(classes.colText, highlightRange(bmiRanges.UNDERWEIGHT))}>
                        {mainData?.bmi_range_underweight_title[0].text}
                    </Typography>
                </div>
                <div className={classes.textCol}>
                    <Typography className={clsx(classes.colText, highlightRange(bmiRanges.NORMAL))}>
                        {'18.5-25'}
                    </Typography>
                    <Typography className={clsx(classes.colText, highlightRange(bmiRanges.NORMAL))}>
                        {mainData?.bmi_range_normal_title[0].text}
                    </Typography>
                </div>
                <div className={classes.textCol}>
                    <Typography className={clsx(classes.colText, highlightRange(bmiRanges.OVERWEIGHT))}>
                        {'25-30'}
                    </Typography>
                    <Typography className={clsx(classes.colText, highlightRange(bmiRanges.OVERWEIGHT))}>
                        {mainData?.bmi_range_overweight[0].text}
                    </Typography>
                </div>
                <div className={classes.textCol}>
                    <Typography className={clsx(classes.colText, highlightRange(bmiRanges.OBESITY))}>
                        {'>30'}
                    </Typography>
                    <Typography className={clsx(classes.colText, highlightRange(bmiRanges.OBESITY))}>
                        {mainData?.bmi_range_obesity[0].text}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default memo(YourBodyBmi);
