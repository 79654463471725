import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import red_cross from '@/assets/Icons/not-uploaded-icon.svg';
import greenTick from '@/assets/images/greenTick.png';
import my_appointment from '@/assets/images/Side-Nav-Icon.svg';
import { getText } from '@/utils/helpers';
import { dayjs } from '@/utils/dayjsSetup';
import Icon from '@/components/Icon/Icon';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
    },
    subHeading: {
        ...theme.typography.HL2,
    },
    cardContentClassDetail: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',
        padding: 30,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            height: 300,
        },
        [theme.breakpoints.down('xs')]: {
            height: 270,
        },
    },
    mainCardHeading: {
        ...theme.typography.HL5,
        fontFamily: 'MessinaSans-SemiBold',
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    lightText: {
        fontFamily: 'MessinaSans-Regular',
    },
    innerMainClass: {
        marginTop: 15,
        marginBottom: 20,
        borderRadius: 10,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: theme.palette.common.lightBrown,
        textAlign: 'left',
        display: 'flex',
        marginRight: 20,
        [theme.breakpoints.down('lg')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            marginRight: 10, //classes overrides
            display: 'inherit',
        },
    },
    innerIMG: {
        width: 50,
        height: 50,
        [theme.breakpoints.down('xs')]: {
            width: 32,
            height: 30,
            marginBottom: 10,
        },
    },
    mainClassImg: {
        marginRight: 20,
    },
    line: {
        height: 1,
        width: '100%',
        backgroundColor: theme.palette.common.mediumBrown,
        marginBottom: 20,
    },
    greenSection: {
        display: 'flex',
    },
    greenTick: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
    mainGrid: {
        flexWrap: 'inherit',
    },
    allDetails: {
        fontFamily: 'MessinaSans-Regular',
        marginBottom: 20,
    },
    wFull: {
        width: '100%',
    },
}));

const PastAppointmentDetails = ({ appointment, dataSet, mainData, dataSetDoctos, doctor_location }) => {
    const classes = useStyles();

    return (
        <div className={classes.wFull}>
            <div>
                <Typography className={classes.mainCardHeading}>
                    {dataSet ? mainData.details_heading[0].text : 'Appointment Details'}
                </Typography>
            </div>
            <div className={classes.innerMainClass}>
                <div className={classes.mainClassImg}>
                    <div className={classes.innerIMG}>
                        <Icon src={my_appointment} description="handwritten" width="100%" height="100%" />
                    </div>
                </div>
                <div>
                    <Typography className={classes.buttoncontentText} style={{ marginBottom: 5 }}>
                        {dataSetDoctos
                            ? dayjs(appointment?.start).format('dddd, MMMM D YYYY [at] HH:mm').replace(/,/g, ' ')
                            : ''}
                    </Typography>
                    <Typography className={classes.lightText} style={{ marginBottom: 15 }}>
                        {dataSetDoctos && doctor_location?.address?.[0].text}
                    </Typography>
                </div>
            </div>
            <div className={classes.line}></div>
            {/* Document */}
            <div>
                <Typography className={classes.mainHeading} style={{ marginBottom: 20 }}>
                    {dataSet ? mainData.documents_heading[0].text : 'Documents'}
                </Typography>
                <Grid container direction="row" className={classes.mainGrid}>
                    <Grid item md={3} xs={12}>
                        <Typography className={classes.allDetails} style={{ marginBottom: 20 }}>
                            {dataSet ? mainData.prescription_label[0].text : 'Prescription'}
                        </Typography>
                        <Typography className={classes.allDetails} style={{ marginBottom: 20 }}>
                            {dataSet ? mainData.anamnesis_label[0].text : 'Anamnesis'}
                        </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <div className={classes.greenSection}>
                            <img
                                className={classes.greenTick}
                                src={appointment?.data?.is_with_prescription ? greenTick : red_cross}
                                alt={appointment?.data?.is_with_prescription ? 'green tick' : 'red cross'}
                            />
                            <Typography className={classes.allDetails}>
                                {appointment?.data?.is_with_prescription
                                    ? getText(mainData?.prescription_uploaded ?? [])
                                    : getText(mainData?.prescription_missing ?? [])}
                            </Typography>
                        </div>
                        <div className={classes.greenSection}>
                            <img
                                className={classes.greenTick}
                                src={appointment?.data?.documents?.length !== 0 ? greenTick : red_cross}
                                alt={appointment?.data?.documents?.length !== 0 ? 'green tick' : 'red cross'}
                            />
                            <Typography className={classes.allDetails}>
                                {appointment?.data?.documents?.length !== 0
                                    ? getText(mainData?.prescription_uploaded ?? [])
                                    : getText(mainData?.prescription_missing ?? [])}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default PastAppointmentDetails;
