import { memo, useEffect, useRef, useState } from 'react';
import { Button, makeStyles, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';
import useI18nCountries from '@/hooks/useI18nCountries';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.3em',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 15,
        },
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    downArrow: {
        height: '0.75em',
        marginRight: 15,
    },
    menuItem: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        textTransform: 'none',
    },
}));

const CountryDropDown = ({ mainDataHeading, setData, data, noMarginTop = false }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [privateCountryWidth, setPrivateCountryWidth] = useState('0');
    const [anchorEl, setAnchorEl] = useState(null);

    const countryList = useI18nCountries();

    const messageRefTwo = useRef();

    useEffect(() => {
        if (messageRefTwo.current) {
            messageRefTwo.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    });
    useEffect(() => {
        if (data !== undefined && data !== null && data.length > 0) {
            setData(data);
        }
    }, []);

    const handleClickPrivateCountry = (event) => {
        var w = document.getElementById('privateCountry').clientWidth;
        setPrivateCountryWidth(w.toString());
        setAnchorEl(event.currentTarget);
    };

    const handleClosePrivatePersonalDataCountry = (pcode = '') => {
        setAnchorEl(null);
        if (pcode !== '') {
            setData(pcode);
        }
    };

    return (
        <div>
            <Button
                aria-controls="privateCountry"
                aria-haspopup="true"
                onClick={handleClickPrivateCountry}
                id="privateCountry"
                disableRipple
                className={classes.formControl}
                classes={{ label: classes.menuLabel }}
                style={{ marginTop: noMarginTop ? 0 : 10 }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography
                        style={{
                            fontFamily: 'MessinaSans-Regular',
                            fontSize: '0.875rem',
                            color: theme.palette.common.darkGrey,
                            textTransform: 'none',
                            padding: 0,
                            marginLeft: 3,
                        }}
                    >
                        {/* {t("Country")} */}
                        {mainDataHeading}
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: 'MessinaSans-SemiBold',
                            fontSize: '1rem',
                            color: theme.palette.common.darkGrey,
                            textTransform: 'none',
                            marginLeft: 3,
                            textAlign: 'left',
                        }}
                    >
                        {/* {epcountry} */}
                        {countryList[data]}
                    </Typography>
                </div>
                <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
            </Button>

            <Menu
                id="privateCountry"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={() => {
                    handleClosePrivatePersonalDataCountry();
                }}
                PaperProps={{
                    style: {
                        width: parseFloat(privateCountryWidth),
                        backgroundColor: 'white',
                        paddingTop: 10,

                        border: `2px solid ${theme.palette.common.yellow}`,
                        height: 400,
                    },
                }}
            >
                {Object.keys(countryList).map((key, index) => (
                    <MenuItem
                        key={index}
                        className={classes.menuItem}
                        onClick={() => handleClosePrivatePersonalDataCountry(key)}
                        style={{
                            backgroundColor: key === data ? '#F9F8F4' : 'white',
                        }}
                        ref={key === data ? messageRefTwo : null}
                    >
                        {countryList[key]}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
export default memo(CountryDropDown);
