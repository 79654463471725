import { memo } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: 'fit-content',
    },
    bottom: {
        color: '#D5D5D5',
    },
    top: {
        color: theme.palette.common.secondary,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        borderRadius: 0,
    },
    percentageText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '24px',
        color: theme.palette.common.secondary,
    },
}));

const PercentageProgressBar = ({ size, progressValue, progressPercent }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={size}
                thickness={2.5}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                className={classes.top}
                size={size}
                thickness={2.5}
                value={progressValue}
            />
            {progressPercent && (
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                        className={classes.percentageText}
                    >{`${Math.round(progressPercent)}%`}</Typography>
                </Box>
            )}
        </div>
    );
};

export default memo(PercentageProgressBar);
