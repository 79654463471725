import { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { overviewStyles } from './OverviewStyles';
import EmptyDataBox from '../Common/EmptyDataBox';
import clsx from 'clsx';

const OverviewEmpty = ({ content }) => {
    const classes = overviewStyles();
    return (
        <>
            <Typography className={clsx(classes.mainHeading, classes.mt25)}>
                {content ? content.overview_data_table_title[0].text : 'Data Overview'}
            </Typography>
            <Grid container md={12} xs={12}>
                <Grid item md={12} xs={12} className={classes.mt10}>
                    <EmptyDataBox
                        text={
                            content
                                ? content.you_haven_t_shared_any_data_yet[0].text
                                : 'You haven’t shared any data yet.'
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default memo(OverviewEmpty);
