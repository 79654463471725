import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FilledButton from './FilledButton';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        position: 'relative',
    },
    contentContainerHeader: {
        maxWidth: theme.layout.contentWidth,

        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        marginTop: -250,
        paddingBottom: 270,
        display: 'block',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
            marginTop: -250,
            paddingBottom: 270,
        },
        [theme.breakpoints.down('md')]: {
            display: 'block',
            marginTop: -250,
            paddingBottom: 220,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10,
            paddingBottom: 20,
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10,

            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    expertHeading: {
        ...theme.typography.HL1,
        textAlign: 'center',
    },
    submitButton: {
        height: '3.75em',
        backgroundColor: theme.palette.common.yellow,
        marginTop: 0,
        '&:hover': {
            backgroundColor: theme.palette.common.yellow,
        },
        borderRadius: '2em',
    },
    buttonText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        lineHeight: '1.5rem',
        color: theme.palette.common.white,
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 8,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 150,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
    },
    mainHeading: {
        ...theme.typography.BGHL1,
    },
    buttonHeader: {
        maxWidth: 150,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    contentTextHeader: {
        ...theme.typography.content,
        maxWidth: 366,
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    labelText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: 'white',
    },
    btnMain: {
        padding: '5px 10px',
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        marginBottom: 10,
        '&:hover': {
            backgroundColor: theme.palette.common.black,
        },
    },
    comingSoonTitle: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 21,
        marginBottom: 10,
    },
    comingSoon: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        marginBottom: 20,
    },
    textClassMain: {
        padding: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '10px',
    },
}));

export default function RecommendedClasses({ data }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <>
            <div className={classes.cardContent}>
                <Grid container direction={matches ? 'column-reverse' : 'row'}>
                    <Grid item xl={6} sm={6} md={6} lg={6} xs={12} className={classes.textClassMain}>
                        <Typography className={classes.comingSoonTitle}>{data?.coming_soon_title[0].text}</Typography>
                        <Typography className={classes.comingSoon}>{data?.coming_soon_text[0].text}</Typography>
                        <a
                            href={data?.coming_soon_link.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                textDecoration: 'none',
                                color: 'none',
                            }}
                        >
                            <FilledButton onPress={() => {}} text={data?.coming_soon_cta[0].text} />
                        </a>
                    </Grid>
                    <Grid item xl={6} sm={6} md={6} lg={6} xs={12}>
                        <img alt="Hub" src={data?.coming_soon_image.url} className={classes.image} />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
