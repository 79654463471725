import { useContext, useMemo } from 'react';
import Loader from '@/components/Loader';
import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';
import { Context as AuthContext } from '@/context/AuthContext';
import { getText } from '@/utils/helpers';
import useFormTemplate from '@/hooks/useFormTemplate';
/** Child Components */
import { WelcomeComponent } from '@/components/ConsentForms';
/** Router */
import { useHistory } from 'react-router-dom';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const description =
    'Please fillout this questionnaire about your medical history and current health, and let your doctor learn about you to better help during your appointment. We take privacy issues seriously. Your personal and account data will be deleted from this device.';

const Welcome = () => {
    const history = useHistory();
    const { i18n } = useTranslation();
    const { [i18n.language]: content } = usePersistedPrismicStore((state) => state.treatmentContractWelcomeData);

    const {
        state: { user },
    } = useContext(AuthContext);

    const { loading: formLoading } = useFormTemplate({ type: 'query' });

    const handleContinueWithAccount = () => {
        if (user) history.push('/consent-treatment-extended?step=personal-information');
        else history.push('/login?redirectTo=/consent-treatment-extended?step=personal-information');
    };

    const handleContinueAsGuest = () => {
        history.push('/consent-treatment-extended?step=personal-information');
    };

    const continueText = useMemo(() => {
        return content ? getText(content?.continue_as_guest_title) : 'You don’t have an Eterno account?';
    }, [content]);

    if (!content || formLoading) return <Loader />;

    return (
        <WelcomeComponent
            icon={WelcomeIcon}
            title={getText(content?.welcome_title) || 'Welcome to Eterno'}
            paragraphs={[getText(content?.welcome_text) || description]}
            primaryBtnText={getText(content?.continue_with_account_cta) || 'Continue with your eterno account'}
            continueBtnText={getText(content?.continue_as_guest_cta) || 'Continue as guest'}
            {...(!user && { continueText })}
            onPrimaryBtnClick={handleContinueWithAccount}
            onContinueBtnClick={handleContinueAsGuest}
        />
    );
};

export default Welcome;
