import { makeStyles } from '@material-ui/core/styles';
import { usePrismicStore } from '@/store';
import Typography from '@material-ui/core/Typography';
import FilledButton from '@/components/FilledButton';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    page: {
        display: 'flex',
        fontFamily: 'MessinaSans-Regular',
        flexDirection: 'column',
        maxWidth: theme.layout.contentWidth,
        padding: '1rem 50px 0 50px',
        width: '100%',
        minHeight: '70vh',
        gap: '1.5rem',

        [theme.breakpoints.down('xs')]: {
            padding: '0 20px',
        },
    },
    title: {
        fontSize: '2rem',
        fontWeight: '600',
    },
    description: {
        width: '60%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const history = useHistory();
    const prismicData = usePrismicStore((state) => state.pageNotFoundData);

    return (
        <div className={classes.container}>
            <div className={classes.page}>
                <Typography className={classes.title}>{prismicData.title[0].text}</Typography>
                <Typography className={classes.description}>{prismicData.description[0].text}</Typography>
                <div>
                    <FilledButton onPress={() => history.replace('/')} text={prismicData.cta[0].text} />
                </div>
            </div>
        </div>
    );
};

export default NotFound;
