import axios from 'axios';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;
export async function unregisterSummaryAPI(body) {
    try {
        const resp = await axios.post(
            `${BASE_API_URL}/public/user/health-profiles/unregistered/handle-anamnesis`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        console.debug('unregisterSummaryAPI resp is ', resp);
        return resp;
    } catch (error) {
        console.debug('unregisterSummaryAPI error is ', error);
        return new Error(error.message);
    }
}
