import { useContext, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './ExistingConditionStyles';
import PastConditionSwitch from '@/components/Common/switchs/PastConditionSwitch';
import { ContextProvider } from '@/context/ProjectContext';
import { getLocalDateMonth } from '@/utils/helpers';

export default function ExistingConditionPast({
    mainData,
    checkBoxObj,
    setCheckBoxObj,
    haveProperty,
    sergeriesArr1,
    setSergeriesArr1,
    errOthers,
    setErrOthers,
    errorObj,
    setErrorObj,
    isFromHealthData,
    messageRefTwo,
}) {
    console.debug('🚀 ~ file: ExistingConditonPast.jsx:21 ~ sergeriesArr1', sergeriesArr1, checkBoxObj);
    const classes = useStyles();
    // context for const variables
    const { setSergeriesArr, surgeriesArr, body5, otherFieldError, specific, newFromTrue } =
        useContext(ContextProviderTwo);
    const { languageGlobal } = useContext(ContextProvider);

    let total = mainData['pre-existing_conditions_answers'];
    console.debug('body511', body5);
    useMemo(() => {
        if (body5 !== null) {
            console.debug('body511', body5);

            if (surgeriesArr.length > 0) {
                let a = surgeriesArr;
                let b = [];
                let p = {};
                let e = {};
                console.debug('index1: clicked  222');

                for (let i = 0; i < a.length; i++) {
                    let c = a[i];
                    b.push({
                        pastCondition: c.pastCondition,
                        specific: c.specific,
                        whenPastCondition: c.whenPastCondition
                            ? getLocalDateMonth(c.whenPastCondition, languageGlobal)
                            : '',
                    });
                    for (let k = 0; k < total.length; k++) {
                        if (total[k]['pre-existing_conditions_response_api_value'] === c.pastCondition) {
                            p[k] = true;
                            e[k] = false;
                        }
                        setCheckBoxObj(p);
                    }
                }
                setSergeriesArr1(b);
            } else {
                console.debug('index1: clicked  222');
                setCheckBoxObj({ 0: true });
            }
        }
    }, []);

    const handleFunction = (i) => {
        let noneOfTheAbove = total[i]['pre-existing_conditions_response_api_value'];

        if (!haveProperty || noneOfTheAbove !== 'NONE OF THE ABOVE') {
            delete checkBoxObj[0];
            delete errorObj[0];
            setCheckBoxObj({
                ...checkBoxObj,
                ...{ [i]: !checkBoxObj[i] },
            });

            setErrorObj({
                ...errorObj,
                ...{ [i]: false },
            });
        }

        if (noneOfTheAbove === 'NONE OF THE ABOVE') {
            setSergeriesArr1([]);
            setSergeriesArr([]);
            setCheckBoxObj({ [i]: !checkBoxObj[i] });
            setErrorObj({ [i]: false });
            console.debug('index1: 72');
            return;
        }

        if ((!haveProperty || noneOfTheAbove !== 'NONE OF THE ABOVE') && !checkBoxObj[i]) {
            let b = {
                pastCondition: total[i]['pre-existing_conditions_response_api_value'],
                specific: '',
                whenPastCondition: '',
            };
            setSergeriesArr1([...sergeriesArr1, b]);
        }
        if (checkBoxObj[i]) {
            let deletedArr = sergeriesArr1.filter(
                (b) => b.pastCondition !== total[i]['pre-existing_conditions_response_api_value']
            );
            setSergeriesArr1(deletedArr);
        }
    };
    console.debug('index1:', checkBoxObj, sergeriesArr1, surgeriesArr);
    console.debug('errorObjTrue123', errorObj);

    const existingConditionOtherFunction = () => {
        if (otherFieldError && specific === '') {
            return true;
        }
    };

    let indexOfOther = mainData['pre-existing_conditions_answers'].findIndex((object) => {
        return object['pre-existing_conditions_response_api_value'] === 'OTHERS';
    });
    console.debug('🚀 ~ file: ExistingConditonPast.jsx:140 ~ indexOfOther:', indexOfOther);

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div className={classes.fixedQuestion}>
                    <Typography className={classes.mainHeading}>
                        {mainData['pre-existing_conditions_question'][0].text}
                    </Typography>
                    <Grid container>
                        {mainData &&
                            mainData['pre-existing_conditions_answers'].map((parts, index) => (
                                <Grid
                                    ref={
                                        existingConditionOtherFunction() &&
                                        (isFromHealthData || newFromTrue) &&
                                        otherFieldError &&
                                        index === indexOfOther
                                            ? messageRefTwo
                                            : null
                                    }
                                    className={
                                        existingConditionOtherFunction() &&
                                        (isFromHealthData || newFromTrue) &&
                                        otherFieldError &&
                                        index === indexOfOther
                                            ? classes.highlight
                                            : classes.main
                                    }
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <PastConditionSwitch
                                        mainData={mainData}
                                        switchName={parts['pre-existing_conditions_answer'][0].text}
                                        savedValue={parts['pre-existing_conditions_response_api_value']}
                                        index={index}
                                        sergeriesArr1={sergeriesArr1}
                                        setSergeriesArr1={setSergeriesArr1}
                                        handleFunction={handleFunction}
                                        checkBoxObj={checkBoxObj}
                                        errOthers={errOthers}
                                        setErrOthers={setErrOthers}
                                        errorObj={errorObj}
                                        setErrorObj={setErrorObj}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </div>
            </div>
        </>
    );
}
