import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import UnderlinedButton from '@/components/UnderlinedButton';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './AccountsStyles';
import { Auth } from 'aws-amplify';

export default function AccountVerification({ mainDataAccount, setCode, code, user1 }) {
    const classes = useStyles();
    const theme = useTheme();

    async function resendConfirmationCode() {
        try {
            await Auth.resendSignUp(user1.username);
            console.debug('code resent successfully');
        } catch (err) {
            console.debug('error resending code: ', err);
        }
    }

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div className="">
                    <Typography className={classes.mainHeading}>{mainDataAccount.description[0].text}</Typography>
                </div>
                <div className="" style={{ marginTop: 20 }}>
                    <TextField
                        id="verifyCode"
                        label={mainDataAccount.verification_code_input_label[0].text}
                        variant="filled"
                        color="primary"
                        placeholder={mainDataAccount.verify_code_textfield_placeholder[0].text}
                        value={code}
                        onChange={(event) => {
                            const text = event.target.value;
                            setCode(text);
                        }}
                        fullWidth
                        classes={{ root: classes.textFields }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />

                    <div className="" style={{ display: 'flex' }}>
                        <Typography
                            className={classes.subHeading}
                            style={{
                                fontFamily: 'MessinaSans-Regular',
                                color: theme.palette.common.darkGrey,
                                textTransform: 'none',
                                textAlign: 'left',
                                fontSize: 14,
                                marginTop: 20,
                            }}
                        >
                            {mainDataAccount.resend_code_prompt[0].text}
                        </Typography>
                        <div className="" style={{ marginLeft: 5 }}>
                            <UnderlinedButton
                                text={mainDataAccount.resend_code_cta[0].text}
                                color={theme.palette.common.darkGrey}
                                onPress={() => {
                                    resendConfirmationCode();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
