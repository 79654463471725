import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import DetailSection from './DetailSection';
import SideBarDoctorDetails from './SideBarDoctorDetails';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        // paddingLeft: 50,
        // paddingRight: 50,
        paddingBottom: 20,
        marginTop: 20,

        [theme.breakpoints.down('xs')]: {
            //   paddingLeft: 20,
            //   paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 20,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
    },
    goBackIcon: {
        // width: 9,
        height: 14,
        marginRight: 10,
    },
}));
const MainDetailSection = ({ myAppointmentsData, myAppointmentsDataStatus, dataSet, mainData }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const languageMap = {
        en: 'en-us',
        de: 'de-de',
    };
    let lan = languageMap[i18n.language];
    // console.debug("mainDatadoctos : doctor_data Up", myAppointmentsData)

    let doctors_data = myAppointmentsData[lan];

    // console.debug("mainDatadoctos : doctor_data", doctors_data)

    return (
        <>
            <div className={classes.contentContainer}>
                <Grid container direction="row" spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        {/* <div> */}
                        <SideBarDoctorDetails
                            doctors_data={doctors_data}
                            myAppointmentsDataStatus={myAppointmentsDataStatus}
                            mainData={mainData}
                            dataSet={dataSet}
                        />
                        {/* </div> */}
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <div>
                            <DetailSection
                                doctor_data={doctors_data}
                                myAppointmentsDataStatus={myAppointmentsDataStatus}
                                mainData={mainData}
                                dataSet={dataSet}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default MainDetailSection;
