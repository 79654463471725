import { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Header from './Header';
import Footer from './Footer';
import Login from '../pages/Login';
import Account from '../pages/Account';
import Dashboard from '../pages/Dashboard';
import { Context as AuthContext } from '@/context/AuthContext';
import MyDoctors from '../pages/MyDoctors';
import AppointmentConfirm from '../pages/ConfirmAppointment';
import {
    isCorrectWhiteLabelDomain,
    isDevEnvironment,
    isEternoDomain,
    isLocalEnvironment,
    isZeroDomain,
    processAPIWithHeaders,
    redirectToEternoCloud,
    redirectToZero,
} from '@/utils/helpers';
import MyAppointments from '../pages/MyAppointments';
import DoctorsDetails from '../pages/DoctorsDetails';
import UnSubscribe from '../pages/UnSubscribe';
import { useTranslation } from 'react-i18next';
import { useBookingStore, useConfigStore, useCookiesStore, usePersistedPrismicStore, usePrismicStore } from '@/store';
import CookieConstant from './Common/CookieConstant';
import CookieConstantMain from './Common/CookieConstantMain';
import ResetPassword from '../pages/ResetPassword';
import WelcomePage from '../pages/HealthProfileAnamnesis/WelcomePage';
import HealthProfileMain from '../pages/HealthProfileMain';
import { ConfirmConsent } from '../pages/HealthProfileAnamnesis/ConfirmConsent';
import HealthProfileOverview from '../pages/HealthProfileOverview/HealthProfileOverview';
import HealthDataPage from '../pages/HealthProfileOverview/HealthDataPage';
import UploadedByMe from '../pages/HealthProfileOverview/UploadedByMe';
import ConsentOverview from '../pages/ConsentManagement/Overview';
import ConsentHistory from '../pages/ConsentManagement/History';

///** Consent Forms */
//** Riede */
import ConsentTreatmentWelcome from '../pages/ConsentForms/TreatmentContract/Pages/Welcome';
import ConsentTreatmentThankYouPage from '../pages/ConsentForms/TreatmentContract/Pages/ThankYouPage';
import ConsentTreatment from '../pages/ConsentForms/TreatmentContract/ConsentTreatment';

//** Corterier */
import CorterierTreatment from '../pages/ConsentForms/CorterierTreatmentContract/CorterierTreatment';
import CorterierTreatmentWelcome from '../pages/ConsentForms/CorterierTreatmentContract/Pages/Welcome';

//** HeraklesForms */
import HeraklesForms from '../pages/ConsentForms/HeraklesAllForms/HeraklesForms';
//** HeraklesAllForms Welcome */
import ConsentPrivacyWelcome from '../pages/ConsentForms/HeraklesAllForms/Pages/Welcome';

//** Herakles Admission Forms */
import HeraklesTherapyForms from '../pages/ConsentForms/HeraklesTherapy/HeraklesTherapyForms';
import ConsentAdmissionWelcome from '../pages/ConsentForms/HeraklesTherapy/Pages/Welcome';
import ConsentAdmissionThankYouPage from '../pages/ConsentForms/HeraklesTherapy/Pages/ThankYouPage';

//** HeraklesCommunicationForms */
import HeraklesCommunicationForms from '../pages/ConsentForms/HeraklesCommunication/HeraklesCommunicationForms';
import ConsentCommunicationWelcome from '../pages/ConsentForms/HeraklesCommunication/Pages/Welcome';
import ConsentCommunicationThankYouPage from '../pages/ConsentForms/HeraklesCommunication/Pages/ThankYouPage';

//** HeraklesCourseEnrollmentForms */
import HeraklesCourseForms from '../pages/ConsentForms/HeraklesCourse/HeraklesCourseForms';
import ConsentCourseWelcome from '../pages/ConsentForms/HeraklesCourse/Pages/Welcome';
import ConsentCourseThankYouPage from '../pages/ConsentForms/HeraklesCourse/Pages/ThankYouPage';

import UnRegisterUserLocation from '../pages/HealthProfileAnamnesis/UnRegisterUserLocation';

/** Thank You Pages */
import ThankYouPage from '../pages/ConsentForms/CorterierTreatmentContract/Pages/ThankYouPage';
import HeraklesThankYouPage from '../pages/ConsentForms/HeraklesAllForms/Pages/ThankYouPage';

//** HeraklesAllForms Code */
import AllFormsCheckIn from '../pages/ConsentForms/AllFormsCheckIn';
import AllFormsLocation from '../pages/ConsentForms/AllFormsLocation';
import AllFormsCode from '../pages/ConsentForms/AllFormsCode';
import AllFormsThankYouPage from '../pages/ConsentForms/AllFormsThankyou';
import AllFormsWelcome from '../pages/ConsentForms/AllFormsWelcome';

// CONSENT ALL_FORMS
import ConsentAllFormsWelcome from '../pages/ConsentForms/ConsentAllForms/Welcome';
import ConsentAllFormsThankYou from '../pages/ConsentForms/ConsentAllForms/ThankYou';
import GuestUnsubscribe from '../pages/GuestUnsubscribe';
import QuestionPlayground from '../pages/QuestionPlayground';
import CustomAnamnesis from '../pages/CustomAnamnesis';
import SearchPage from '../pages/SearchPage';
import EternoSpinner from './EternoLogoSpinner/EternoSpinner';
import { makeStyles } from '@material-ui/core/styles';
import NotFound from '../pages/NotFound';
import PrismicFallback from '../pages/PrismicFallback';
import StaticPage from '../pages/StaticPage/StaticPage.jsx';
import UploadDocumentModal from './UploadDocumentModal/UploadDocumentModal';
import { useFeatureFlag } from '../hooks/useFeatureFlag';

const useStyles = makeStyles(() => ({
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        fontFamily: 'MessinaSans-Regular',
    },
}));

const StaticPages = {
    PRIVACY: 'Privacy Policy',
    TERMS: 'Terms & Conditions',
    IMPRINT: 'Imprint',
};

function Router() {
    const {
        state: { user },
        setuser,
        setusersession,
        setuseraccounts,
    } = useContext(AuthContext);

    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [customerKey, setCustomerKey] = useState(null);
    const customers = usePrismicStore((state) => state.customers);
    const showBookingModal = useBookingStore((state) => state.showBookingModal);
    const showPrismicFallback = useConfigStore((state) => state.showPrismicFallback);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);
    const cookieConsentData = usePersistedPrismicStore((state) => state.cookieConsentData);

    const isDocumentExchangeFeatureFlagActivated = useFeatureFlag('document_exchange');

    useEffect(() => {
        getCurrentUser();
    }, []);

    const getAndSetUserSession = async () => {
        console.debug('RefreshToken: Get and set user ');
        try {
            const user = await Auth.currentAuthenticatedUser();
            setusersession(user.signInUserSession);
            refreshTokenAtExp(user.signInUserSession.idToken.payload.exp);
        } catch (err) {
            console.debug(err);
        }
    };

    const refreshTokenAtExp = async (exp) => {
        let expVal = exp * 1000;
        console.debug('RefreshToken: At ', expVal);
        const now = Date.now();
        console.debug('RefreshToken: Now ', now);
        let refreshIn = expVal - now;
        console.debug('RefreshToken: In ', refreshIn);
        setTimeout(() => {
            getAndSetUserSession();
        }, refreshIn);
    };

    const getCurrentUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();

            console.debug('currentUser:', user);
            const username = user.username;
            const token = user.signInUserSession.idToken.jwtToken;

            const response = await processAPIWithHeaders('secure/users/' + username, 'GET', {
                headers: {
                    Authorization: token,
                },
            });

            const userAccounts = await processAPIWithHeaders('secure/user/accounts/' + username, 'GET', {
                headers: {
                    Authorization: token,
                },
            });

            setuser(response);
            setuseraccounts(userAccounts);
            setusersession(user.signInUserSession);
            setLoading(false);
            refreshTokenAtExp(user.signInUserSession.idToken.payload.exp);
        } catch (err) {
            console.debug(err);
            setLoading(false);
        }
    };

    // work for cookie

    const { i18n } = useTranslation();

    const [largeCookie, setLargeCookie] = useState(false);
    const [cookieOpen, setCookieOpen] = useState(false);

    useEffect(() => {
        const cookieAccepted = useCookiesStore.getState().accepted;

        if (cookieAccepted === null) setCookieOpen(true);
    }, []);

    const cookieConsentContent = cookieConsentData[i18n.language];

    const setEternoCustomer = () => {
        useConfigStore.setState({ currentCustomer: customers[import.meta.env.VITE_ETERNO_CUSTOMER_KEY] });
        setCustomerKey(import.meta.env.VITE_ETERNO_CUSTOMER_KEY);
    };

    const setCustomer = (key) => {
        useConfigStore.setState({ currentCustomer: customers[key] });
        setCustomerKey(key);
    };

    useEffect(() => {
        if (!customers) return;

        const match = window.location.pathname.match(/\/customer\/([^/]+)/);

        if (isLocalEnvironment()) {
            if (!match) {
                setEternoCustomer();
                return;
            }

            if (match && customers[match[1]] && !customers[match[1]].is_eterno_customer) {
                setCustomer(match[1]);
                return;
            }
            redirectToEternoCloud();
        }

        if (isEternoDomain()) {
            setEternoCustomer();
            return;
        }

        if (
            match &&
            customers[match[1]] &&
            !customers[match[1]].is_eterno_customer &&
            isCorrectWhiteLabelDomain(customers[match[1]].domain)
        ) {
            setCustomer(match[1]);
            return;
        }

        if (isZeroDomain()) {
            redirectToZero();
            return;
        }
        redirectToEternoCloud();
    }, [customers]);

    const acceptCookies = (essentialCookies = false, analyticsCookies = false) => {
        useCookiesStore.setState({ accepted: true, essentialCookies, analyticsCookies });
        setCookieOpen(false);
        setLargeCookie(false);
    };

    const rejectCookies = () => {
        useCookiesStore.setState({ accepted: false, essentialCookies: false, analyticsCookies: false });
        setCookieOpen(false);
        setLargeCookie(false);
    };

    const PrivateRoute = ({ children, ...rest }) => {
        const location = useLocation();
        console.debug('🚀 ~ file: Router.jsx ~ line 210 ~ PrivateRoute ~ prevLocation', location.pathname);
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    user ? children : <Redirect to={`/login?redirectTo=${location.pathname}`} />
                }
            />
        );
    };

    const notIncludingArr = [
        // Consent Forms Routes
        //** Welcome */
        {
            path: '/question-playground',
            component: QuestionPlayground,
        },
    ];

    if (showPrismicFallback) return <PrismicFallback />;

    if (loading || !customers || !customerKey || !currentCustomer) {
        return (
            <div className={classes.spinnerContainer}>
                <EternoSpinner />
            </div>
        );
    }

    return (
        <BrowserRouter basename={currentCustomer?.is_eterno_customer ? '' : `/customer/${customerKey}`}>
            <Header />
            <Switch>
                <Route exact path="/" component={!user || (user && showBookingModal) ? SearchPage : Dashboard} />
                <Redirect exact from="/dashboard" to="/" />
                <Redirect exact from="/find-appointment/:id" to="/?selected_professional=:id" />
                <Redirect exact from="/find-appointment" to="/" />
                <Redirect from="/book-appointment" to="/" />
                <Redirect exact from="/professional-details/:id" to="/professional/:id" />
                <Route
                    exact
                    path="/login"
                    render={() => {
                        return user ? <Redirect to="/" /> : <Login />;
                    }}
                />

                <Route
                    exact
                    path="/anamnesis-welcome"
                    render={() => {
                        return user ? <Redirect to="/anamnesis-health-profile" /> : <WelcomePage />;
                    }}
                />

                <Route exact path="/privacy" component={() => <StaticPage page={StaticPages.PRIVACY} />} />
                <Route exact path="/terms" component={() => <StaticPage page={StaticPages.TERMS} />} />
                <Route exact path="/imprint" component={() => <StaticPage page={StaticPages.IMPRINT} />} />
                <Route exact path="/reset-password" component={() => <ResetPassword />} />
                <Route exact path="/confirmConsent" component={() => <ConfirmConsent />} />
                <Route exact path="/professional/:id" component={() => <DoctorsDetails />} />
                <Route exact path="/anamnesis-health-profile" component={HealthProfileMain} />
                <Route exact path="/location" component={() => <UnRegisterUserLocation />} />
                <Route exact path="/uploaded-by-me" component={() => <UploadedByMe />} />
                <Route exact path="/all-forms-welcome" component={AllFormsWelcome} />
                <Route exact path="/all-forms-thankyou" component={AllFormsThankYouPage} />
                <Route exact path="/all-forms-checkin" component={AllFormsCheckIn} />
                <Route exact path="/consent-privacy" component={() => <HeraklesForms />} />
                <Route exact path="/consent-privacy-welcome" component={() => <ConsentPrivacyWelcome />} />
                <Route exact path="/consent-privacy-thankyou" component={() => <HeraklesThankYouPage />} />
                <Route exact path="/consent-admission" component={() => <HeraklesTherapyForms />} />
                <Route exact path="/consent-admission-welcome" component={() => <ConsentAdmissionWelcome />} />
                <Route exact path="/consent-admission-thankyou" component={() => <ConsentAdmissionThankYouPage />} />
                <Route exact path="/consent-communication" component={() => <HeraklesCommunicationForms />} />
                <Route exact path="/consent-communication-welcome" component={() => <ConsentCommunicationWelcome />} />
                <Route
                    exact
                    path="/consent-communication-thankyou"
                    component={() => <ConsentCommunicationThankYouPage />}
                />
                <Route exact path="/herakles-course" component={() => <HeraklesCourseForms />} />
                <Route exact path="/herakles-course-welcome" component={() => <ConsentCourseWelcome />} />
                <Route exact path="/herakles-course-thankyou" component={() => <ConsentCourseThankYouPage />} />

                <Route exact path="/consent-treatment-welcome" component={ConsentTreatmentWelcome} />
                <Route exact path="/consent-treatment-thankyou" component={ConsentTreatmentThankYouPage} />
                <Route exact path="/consent-treatment" component={ConsentTreatment} />
                <Route exact path="/consent-treatment-extended" component={CorterierTreatment} />
                <Route exact path="/extended-treatment-welcome" component={CorterierTreatmentWelcome} />
                <Route exact path="/extended-treatment-thankyou" component={ThankYouPage} />
                <Route exact path="/all-forms-code" component={AllFormsCode} />
                <Route exact path="/all-forms-location" component={AllFormsLocation} />
                <Route exact path="/consent-all-forms-welcome" component={ConsentAllFormsWelcome} />
                <Route exact path="/consent-all-forms-thankyou" component={ConsentAllFormsThankYou} />
                <Route exact path="/guest/unsubscribe" component={() => <GuestUnsubscribe />} />
                <Route exact path="/custom-anamnesis" component={CustomAnamnesis} />
                <Route exact path="/professionals" component={MyDoctors} />
                <Redirect exact from="/practitioners" to="/professionals" />

                {isDevEnvironment()
                    ? notIncludingArr.map((r) => <Route exact key={r.path} path={r.path} component={r.component} />)
                    : null}

                <PrivateRoute path="/appointments">
                    <MyAppointments />
                </PrivateRoute>
                <PrivateRoute path="/appointments/:id">
                    <MyAppointments />
                </PrivateRoute>
                <PrivateRoute path="/unsubscribe">
                    <UnSubscribe />
                </PrivateRoute>
                <PrivateRoute path="/account">
                    <Account />
                </PrivateRoute>
                <PrivateRoute path="/health-profile-overview">
                    <HealthProfileOverview />
                </PrivateRoute>
                <PrivateRoute path="/health-data">
                    <HealthDataPage />
                </PrivateRoute>
                <PrivateRoute path="/confirm-appointment">
                    <AppointmentConfirm />
                </PrivateRoute>
                <PrivateRoute path="/consent-overview">
                    <ConsentOverview />
                </PrivateRoute>
                <PrivateRoute path="/consent-history">
                    <ConsentHistory />
                </PrivateRoute>
                <Route path="*" component={NotFound} />
            </Switch>
            {isDocumentExchangeFeatureFlagActivated && <UploadDocumentModal />}
            <Footer />
            {/* cookie work start */}
            {cookieOpen && !largeCookie && cookieConsentContent && (
                <CookieConstant
                    open={cookieOpen}
                    onClose={() => setCookieOpen(false)}
                    setLargeCookie={setLargeCookie}
                    mainData={cookieConsentContent}
                    acceptCookies={acceptCookies}
                    rejectCookies={rejectCookies}
                />
            )}

            {cookieConsentContent && (
                <CookieConstantMain
                    open={largeCookie}
                    onClose={() => setLargeCookie(false)}
                    mainData={cookieConsentContent}
                    acceptCookies={acceptCookies}
                />
            )}
            {/* cookie work end */}
        </BrowserRouter>
    );
}

export default Router;
