import { useContext, useEffect, useLayoutEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './AccountsStyles';
import { DropDown } from '@/components/Common/DropDown/DropDown';
import { insurance_list_private, insurance_list_public } from '@/components/Common/User/InsuranceData';

export default function AccountInsuranceSection({ mainData, mainDataAccountEterno, fullWidth = false, heading = '' }) {
    const classes = useStyles();

    const {
        validUntil,
        setValidUntil,
        insuranceCompany,
        setInsuranceCompany,
        setInsuranceNumber,
        setInsuranceStatus,
        setInsuraceType,
        insuranceType,
    } = useContext(ContextProviderTwo);

    let notSelfPayer = insuranceType === 'PRIVATE';

    useEffect(() => {
        if (typeof validUntil === 'undefined') setValidUntil('');
    }, [validUntil]);

    useLayoutEffect(() => {
        setInsuranceCompany('');
        if (insuranceType !== 'SELFPAYER') {
            setValidUntil('');
            setInsuranceStatus('');
            setInsuranceCompany('');
            setInsuranceNumber('');
        }
    }, [insuranceType]);

    return (
        <>
            <div className="" style={{ marginTop: fullWidth ? null : 40 }}>
                <ConditionalWrapper
                    condition={fullWidth}
                    wrapper={(children) => (
                        <Grid container spacing={1}>
                            {children}
                        </Grid>
                    )}
                >
                    <Typography className={classes.mainHeading}>
                        {heading || mainData.health_insurance_details_question[0].text}
                    </Typography>
                    <Grid container direction={'row'} spacing={1} style={{ marginTop: fullWidth ? null : 10 }}>
                        <Grid
                            item
                            xl={fullWidth ? 12 : 6}
                            lg={fullWidth ? 12 : 6}
                            md={fullWidth ? 12 : 6}
                            sm={12}
                            xs={12}
                        >
                            <DropDown
                                type="default"
                                mainDataHeading={mainDataAccountEterno.health_insurance_type_label[0].text}
                                mainDataArray={mainDataAccountEterno.insurance_type_values}
                                setData={setInsuraceType}
                                data={insuranceType}
                                compulsory={''}
                                validateForm={null}
                                widthID="insurance_type"
                            />
                        </Grid>

                        {notSelfPayer && (
                            <Grid item md={fullWidth ? 12 : 6} sm={12} xs={12}>
                                <DropDown
                                    type="default"
                                    mainDataHeading={mainDataAccountEterno.health_insurance_provider_name_label[0].text}
                                    mainDataArray={
                                        insuranceType === 'PUBLIC'
                                            ? insurance_list_public
                                            : insuranceType === 'PRIVATE'
                                              ? insurance_list_private
                                              : []
                                    }
                                    setData={setInsuranceCompany}
                                    data={insuranceCompany}
                                    compulsory={''}
                                    fullWidth={false}
                                    validateForm={null}
                                    widthID="insurance_company_type"
                                />
                            </Grid>
                        )}
                    </Grid>
                </ConditionalWrapper>
            </div>
        </>
    );
}

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);
