import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { generateColorFilter, getTextColor } from '@/utils/colorHelpers';

const darkGrey = '#2e2e2e';
const lightBrown = '#efede8';
const brown = '#ABABAB';
const yellow = '#ffc877';
const hvrYellow = '#E5B46B';
export const createTheme = (primaryColor = null, secondaryColor = null) => {
    return createMuiTheme({
        palette: {
            common: {
                darkGrey: `${darkGrey}`,
                brown: `${brown}`,
                lightBrown: `${lightBrown}`,
                yellow: primaryColor ?? yellow,
                hoverYellow: primaryColor ?? `${hvrYellow}`,
                green: '#5ba668',
                cream: '#faf9f8',
                white: '#ffffff',
                red: '#da3030',
                mediumBrown: '#cac5b4',
                footerBG: '#E1DFD5',
                secondary: secondaryColor ?? '#4C726D',
                lightGray: '#FBFBF9',
                creamCalendar: '#fbfbf9',
                cream_disable: '#F9F8F4',
                footerGreen: secondaryColor ?? '#4C726D',
                lightBrownDropdown: '#F5F5F5',
                lightBrownSelected: '#DFDFDF',
            },
            primary: {
                main: '#faf9f8',
            },
            secondary: {
                main: `${lightBrown}`,
            },
        },
        // custom breakpoints
        breakpoints: {
            values: {
                xs: 0,
                sm: 768,
                md: 1024,
                lg: 1280,
                xl: 1920,
            },
        },
        icons: {
            primary: {
                filter: generateColorFilter(primaryColor ?? yellow),
            },
            secondary: {
                filter: generateColorFilter(secondaryColor ?? '#4C726D'),
            },
        },
        typography: {
            markPRO: {
                textTransform: 'none',
                fontFamily: 'MARKPRO',
            },
            menu: {
                textTransform: 'none',
                fontFamily: 'MessinaSans-SemiBold',
                fontSize: '1rem',
            },
            mainMenu: {
                textTransform: 'none',
                fontFamily: 'MessinaSans-SemiBold',
                fontSize: '1.125rem',
            },
            HL1: {
                fontFamily: 'MessinaSans-SemiBold',
                fontSize: '1.5rem',
                textTransform: 'none',
                lineHeight: '1.875rem',
                color: `${darkGrey}`,
            },
            HL2: {
                fontFamily: 'MessinaSans-Regular',
                fontSize: '1.5rem',
                textTransform: 'none',
                lineHeight: '1.875rem',
                color: `${darkGrey}`,
            },
            HL3: {
                fontFamily: 'MessinaSans-SemiBold',
                fontSize: '1.313rem',
                textTransform: 'none',
                lineHeight: '1.75rem',
                color: `${darkGrey}`,
            },
            HL4: {
                fontFamily: 'MessinaSans-Regular',
                fontSize: '0.75rem',
                textTransform: 'none',
                lineHeight: '1rem',
                color: `${brown}`,
            },
            HL5: {
                fontFamily: 'MessinaSans-Regular',
                fontSize: '1.313rem',
                textTransform: 'none',
                lineHeight: '1.75rem',
                color: `${darkGrey}`,
            },
            subheading: {
                fontFamily: 'MessinaSans-SemiBold',
                fontSize: '1rem',
                textTransform: 'none',
                lineHeight: '1.5rem',
                color: `${darkGrey}`,
            },
            BGHL1: {
                fontFamily: 'MessinaSans-SemiBold',
                fontSize: '1.75rem',
                textTransform: 'none',
                lineHeight: '2rem',
                color: `${darkGrey}`,
            },

            content: {
                fontFamily: 'MessinaSerif-Regular',
                fontSize: '1rem',
                textTransform: 'none',
                lineHeight: '1.5rem',
                color: `${darkGrey}`,
            },
            contentMainText: {
                fontFamily: 'MessinaSans-Regular',
                fontSize: '1.125',
                textTransform: 'none',
                lineHeight: '1.5rem',
                color: `${darkGrey}`,
            },
            buttonText: {
                fontFamily: 'MessinaSans-SemiBold',
                fontSize: '0.875rem',
                textTransform: 'uppercase',
                lineHeight: '1.5rem',
                color: getTextColor(primaryColor ?? yellow, darkGrey, 'white'),
                paddingLeft: 36,
                paddingRight: 36,
                paddingTop: 12,
                paddingBottom: 12,
            },
            buttonTextNoColor: {
                fontFamily: 'MessinaSans-SemiBold',
                fontSize: '0.875rem',
                textTransform: 'uppercase',
                lineHeight: '1.5rem',
                paddingLeft: 36,
                paddingRight: 36,
                paddingTop: 12,
                paddingBottom: 12,
            },
        },
        layout: {
            contentWidth: 1280,
        },
        button: {
            height: '3.75em',
            backgroundColor: primaryColor ?? yellow,
            marginTop: 0,
            borderRadius: '2em',
            '&:hover': {
                backgroundColor: `${primaryColor ?? hvrYellow} !important`,
            },
        },

        overrides: {
            MuiFilledInput: {
                root: {
                    overflow: 'hidden',
                    backgroundColor: 'white',
                    paddingRight: 10,
                    borderRadius: 5,
                    fontFamily: 'MessinaSans-Regular',
                    fontSize: '1rem',
                    height: '100%',
                    '&:hover': {
                        backgroundColor: '#F9F8F4',
                    },
                    '&$focused': {
                        backgroundColor: 'white',
                        border: `2px solid ${primaryColor ?? yellow}`,
                    },
                },
            },
            MuiFormLabel: {
                root: {
                    overflow: 'hidden',
                    backgroundColor: 'transparent',
                    paddingRight: 10,
                    fontFamily: 'MessinaSans-Regular',
                    fontSize: '1rem',
                    color: `${darkGrey}`,

                    '&$focused': {
                        color: `${darkGrey}`,
                    },
                },
            },
            MuiInputLabel: {
                filled: {
                    '&$shrink': {
                        transform: 'translate(12px, 10px) scale(0.875)',
                    },
                },
            },
        },
        props: {
            MuiFilledInput: {
                disableUnderline: true,
            },
        },
    });
};
