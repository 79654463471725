import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { ContextProvider } from '@/context/ProjectContext';
import TextField from '@material-ui/core/TextField';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './PersonalInfoClass';
import { DateTextField } from '@/components/Common/Date/DateTextField';
import { DropDown } from '@/components/Common/DropDown/DropDown';
import { useDebounce } from '@/hooks/useDebounce';
import isEmail from 'validator/es/lib/isEmail';
import { dayjs } from '@/utils/dayjsSetup';

export default function PersonalInfoLoginForm({ mainData, user, emailExists }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { languageGlobal } = useContext(ContextProvider);

    /** State to stop continous API calls */
    const [isFetching, setIsFetching] = useState(false);

    let testingRegex = /[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_ ||[\]]/;
    const {
        efirstName,
        elastName,
        emiddleName,
        eemail,
        estreetMainAdress,
        ezipMainAdress,
        ecityMainAdress,
        dob,
        mobileCode,
        mobileNumber,
        privateTitle,
        anchorE6,
        setAnchorE6,
        privateGender,
        anchorE5,
        setAnchorE5,
        epcountry,
        egender,
        etitle,
        epcode,
        occuption,
        seteFirstName,
        seteLastName,
        seteMiddleName,
        seteEmail,
        setDOB,
        setMobileNumber,
        setPrivateTitle,
        setPrivateGender,
        seteGender,
        seteTitle,
        setOccuption,
        setGenderQuestion,
        errDOB,
        setErrDOB,
        errLastName,
        setErrLastName,
        errFirstName,
        setErrFirstName,
        errGender,
        setErrGender,
        errEmail,
        setErrEmail,
        errPhoneNumber,
        setErrPhoneNumber,
    } = useContext(ContextProviderTwo);

    // Drop Down Functions
    console.debug(
        'personal_info1: profile',
        efirstName,
        elastName,
        emiddleName,
        eemail,
        estreetMainAdress,
        ezipMainAdress,
        ecityMainAdress,
        dob,
        mobileCode,
        mobileNumber,
        egender,
        etitle,
        epcountry,
        occuption,
        epcode,
        user
    );

    const { debounceEmail } = useDebounce();

    const validateForm = (type, gender_values = '-') => {
        switch (type) {
            case 'GenderSelection':
                setErrGender('');
                if (gender_values === '-') {
                    setErrGender(t('RequiredField'));
                }
                break;

            case 'firstName':
                setErrFirstName('');
                if (efirstName === '') {
                    setErrFirstName(t('RequiredField'));
                }
                break;

            case 'lastName':
                setErrLastName('');
                if (elastName === '') {
                    setErrLastName(t('RequiredField'));
                }
                break;
            case 'dob':
                setErrDOB('');
                if (dob === '') {
                    setErrDOB(mainData.required_field_error_message1[0].text);
                } else {
                    if (!dayjs(dob, languageGlobal === 'en' ? 'DD/MM/YYYY' : 'DD.MM.YYYY', true).isValid()) {
                        setErrDOB(t('InvalidDate'));
                    }
                }
                break;
            case 'email':
                setErrEmail('');
                if (eemail === '') {
                    setErrEmail(mainData.required_field_error_message[0].text);
                } else {
                    if (!isEmail(eemail)) {
                        setErrEmail(mainData.invalid_email_error_message[0].text);
                    } else {
                        if (!user) {
                            emailExists();
                        }
                    }
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (user) {
            console.debug('🚀 ~ file: PersonalInfoLoginForm.jsx ~ line 156 ~ useEffect ~ user', user);

            let dob = user?.date_of_birth?.split('-').length ? user?.date_of_birth.split('-') : '';
            console.debug('🚀 ~ file: PersonalInfoLoginForm.jsx:176 ~ useEffect ~ dob', dob, user);

            let validFormat = `${dob[2]}/${dob[1]}/${dob[0]}`;

            seteFirstName(user.name.first);
            seteLastName(user.name.last);
            seteMiddleName(
                user.name.middle !== '' && user.name.middle !== undefined && user.name.middle !== null
                    ? user.name.middle
                    : ''
            );
            seteEmail(user.email);
            setDOB(validFormat);

            setMobileNumber(user?.phone?.number);
            setPrivateTitle(user.title !== '' && user.title !== undefined && user.title !== null ? user.title : '-');
            setPrivateGender(user.gender);
            seteGender(user.gender);
            seteTitle(user.title !== '' && user.title !== undefined && user.title !== null ? user.title : '-');
            if (user.occupation !== null && user.occupation !== undefined) setOccuption(user.occupation);
        }
    }, []);

    return (
        <>
            <Grid item md={12} xs={12} style={{ padding: 0 }}>
                <Typography className={classes.mainHeading}>
                    {mainData ? mainData?.personal_details_question[0].text : t('PERSONALDATA')}
                </Typography>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 8,
                    }}
                >
                    {/* Gender Starts */}
                    <div className="" style={{ flex: 6 }}>
                        <DropDown
                            type="default"
                            mainDataHeading={mainData?.gender_label[0]?.text}
                            mainDataArray={mainData?.gender_values}
                            setData={seteGender}
                            data={egender}
                            compulsory={'GenderSelection'}
                            widthID="gender"
                            validateForm={validateForm}
                            error={errGender}
                            errorMessage={mainData?.required_field_error_message[0]?.text}
                        />
                    </div>
                    {/* Gender Ends */}
                    {/* date starts */}
                    <div className={classes.flex}>
                        {mainData && (
                            <DateTextField
                                type={'dateOfBirth'}
                                separator={mainData.dob_placeholder_separator[0].text}
                                setDOB={setDOB}
                                dob={dob}
                                mainHeading={mainData.date_of_birth_label[0].text}
                                setError={setErrDOB}
                                error={errDOB}
                                errorMessage={mainData?.date_error[0].text}
                                placeHolderMonth={mainData.dob_placeholder_month[0].text}
                                placeHolderYear={mainData.dob_placeholder_year[0].text}
                                placeHolderDay={mainData.dob_placeholder_day[0].text}
                                requiredDateField={mainData.required_field_error_message1[0].text}
                            />
                        )}
                    </div>
                    {/* date ends */}
                </div>

                {/* </Grid> */}
                <Grid container spacing={1} direction="row">
                    <Grid item md={6} xs={12}>
                        <TextField
                            id="firstName"
                            label={mainData ? mainData.first_name_label[0].text : t('FirstName')}
                            variant="filled"
                            color="primary"
                            value={efirstName}
                            onChange={(event) => {
                                const text = event.target.value;
                                seteFirstName(text);
                            }}
                            fullWidth
                            onBlur={() => validateForm('firstName')}
                            classes={{
                                root: errFirstName ? classes.textFieldsError : classes.textFields,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />
                        <Grid xs="12" sm="12">
                            {errFirstName && (
                                <Typography className={classes.errorText}>
                                    {mainData.required_field_error_message1[0].text}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            id="lastName"
                            label={mainData ? mainData.last_name_label[0].text : t('LastName')}
                            variant="filled"
                            color="primary"
                            value={elastName}
                            onChange={(event) => {
                                const text = event.target.value;
                                seteLastName(text);
                            }}
                            fullWidth
                            classes={{
                                root: errLastName ? classes.textFieldsError : classes.textFields,
                            }}
                            onBlur={() => validateForm('lastName')}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />
                        <Grid xs="12" sm="12">
                            {errLastName && (
                                <Typography className={classes.errorText}>
                                    {mainData.required_field_error_message1[0].text}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1} direction="row"></Grid>

                {/* Date of birth ends */}
                <Grid container spacing={1} direction="row">
                    <Grid item md={6} xs={12}>
                        <TextField
                            id="email"
                            disabled={!!user}
                            label={mainData ? mainData.email_label[0].text : t('Email')}
                            variant="filled"
                            color="primary"
                            value={eemail.replaceAll(' ', '')}
                            onChange={(event) => {
                                const text = event.target.value.replaceAll(' ', '');
                                debounceEmail([emailExists, text, { isFetching, setIsFetching }]);
                                seteEmail(text);
                            }}
                            fullWidth
                            onBlur={() => validateForm('email')}
                            className={classes.disabledEmail}
                            style={{
                                disabled: {
                                    color: 'green',
                                },
                            }}
                            classes={{
                                root: errEmail ? classes.textFieldsError : classes.textFields,
                                style: {
                                    backgroundColor: '#fff',
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRootEmail,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                        />
                        <Grid item md={12} xs={12}>
                            {errEmail && <Typography className={classes.errorText}>{errEmail}</Typography>}
                        </Grid>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <DropDown
                            type="telephone"
                            setData={setMobileNumber}
                            mainDataHeading={mainData?.mobile_number_label[0]?.text}
                            compulsory={'phoneNumber'}
                            data={mobileNumber}
                            error={errPhoneNumber}
                            errorText={mainData.required_field_error_message1[0].text}
                            setError={setErrPhoneNumber}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
