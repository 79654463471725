import { useContext, useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';
import { makeStyles, rgbToHex, useTheme } from '@material-ui/core/styles';
import { ContextProvider } from '@/context/ProjectContext';
import useI18nCountries from '@/hooks/useI18nCountries';

const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        contentContainer: {
            maxWidth: theme.layout.contentWidth,

            margin: '0 auto',
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 20,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 30,
            },
        },
        heading: {
            ...theme.typography.HL1,
        },
        cardContent: {
            backgroundColor: 'white',

            maxWidth: theme.layout.contentWidth,
            boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
            borderRadius: 8,
            display: 'block',

            marginTop: 23,
        },
        avatar: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            objectFit: 'cover',
            maxHeight: 400,
            backgroundColor: 'red',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        editAvatar: {
            width: 200,
            height: 200,
            borderRadius: 100,
            objectFit: 'cover',
            // maxHeight: 400,
            // minHeight: 250,
            marginTop: 10,
        },
        accountData: {
            // paddingLeft: 38,
            // paddingRight: 38,
            paddingTop: 32,
            paddingBottom: 32,
        },
        icon: {
            width: 50,
            height: 50,
            objectFit: 'contain',
        },
        subHeading: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.75rem',
            textTransform: 'none',

            color: theme.palette.common.brown,
            marginTop: 20,
        },
        userData: {
            fontFamily: 'MessinaSerif-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        downArrow: {
            height: '0.75em',
            marginRight: 15,
        },
        selectedOptions: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        editLinks: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.darkGrey,
            marginTop: 25,
        },
        deleteLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
            marginLeft: 20,
        },
        deleteAccountLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
        },
        editButton: {
            background: 'transparent',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        buttonLabel: {
            padding: 0,
            justifyContent: 'left',
        },
        cardbg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'rgba(255,200,119,0.2)',
            maxHeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        imagebg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            maxHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        card: {
            width: '80%',
            height: '85%',
            maxWidth: 400,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            backgroundColor: theme.palette.common.cream,

            maxHeight: 400,
            [theme.breakpoints.down('sm')]: {
                width: '70%',
                height: '85%',
            },

            [theme.breakpoints.down('xs')]: {
                width: '70%',
                height: '85%',
            },
        },
        visa: {
            width: 53,
            height: 30,
            objectFit: 'contain',
            marginTop: 30,
            marginLeft: 30,
            marginBottom: 20,
        },
        textFields: {
            height: '3.75em',
            marginTop: 10,
        },

        inputLabel: {
            '&.focused': {
                color: theme.palette.common.darkGrey,
            },
            '&.shrink': {
                fontSize: '1rem',
            },
        },
        submitButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.yellow,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.yellow,
            },
        },
        cancelButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.mediumBrown,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.mediumBrown,
            },
        },
        buttonText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        // menuItem: {
        //   height: 55,
        //   // fontFamily: "MessinaSans-SemiBold",
        //   fontSize: "0.8rem",
        //   color: theme.palette.common.darkGrey,
        //   textTransform: "none",
        // },
        formControl: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',
            // marginBottom: 15,
            justifyContent: 'space-between',
            // [theme.breakpoints.down("md")]: {
            //   width: 150,
            // },
            // [theme.breakpoints.down("sm")]: {
            //   width: 100,
            // },
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        formControlMobileBtn: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',

            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,

            borderRightWidth: 0,

            marginBottom: 15,
            justifyContent: 'space-between',
            // [theme.breakpoints.down("md")]: {
            //   width: 150,
            // },
            // [theme.breakpoints.down("sm")]: {
            //   width: 100,
            // },
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        accountIcon: {
            width: 250,
            height: 250,
            objectFit: 'contain',
            backgroundColor: 'rgba(255,200,119,0.2)',
            padding: 10,
            borderRadius: 135,

            [theme.breakpoints.down('xs')]: {
                width: 150,
                height: 150,
            },
        },
        butnMargin: {},
        fieldFocused: {
            backgroundColor: 'white',
            border: `2px solid ${theme.palette.common.yellow} !important`,
        },
        filledInputRoot: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
                //backgroundColor: "red",
            },
        },
        filledInputRootEmail: {
            overflow: 'hidden',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            // background: "red",
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        disabledEmail: {
            '& input.Mui-disabled': {
                backgroundColor: 'white',
                border: '1px solid white',
            },
        },
        filledInputRootMobile: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
                //backgroundColor: "red",
            },
        },
        btnClass: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },

        blackCircle: {
            backgroundColor: theme.palette.common.darkGrey,
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        greenCircle: {
            backgroundColor: '#5ba668',
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        passwordText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        passwordValidText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.brown,
        },

        errorText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.red,
        },
        font: {
            fontSize: '1.5rem',
            backgroundColor: 'red',
            height: '0',
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
});

const CountrySelection = ({
    setCountrySelect,
    setePcode,
    setePcountry,
    pcode,
    epcode,
    epcountry,
    pcountry,
    setPcountry,
    setPcode,
    text,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { languageGlobal } = useContext(ContextProvider);
    const countryList = useI18nCountries();

    const [privateCountryWidth, setPrivateCountryWidth] = useState('0');
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        const code = pcode.toUpperCase();

        const countryDetail = {
            code: code,
            name: countryList[code],
        };

        if (countryDetail.code !== epcode) {
            console.debug('pCode1', countryDetail);
            setCountrySelect(countryDetail);
        }
    }, [languageGlobal, pcode, epcode, countryList]);

    const handleClosePrivatePersonalDataCountry = (pcountry = '', pcode = '') => {
        setAnchorEl(null);
        console.debug('pcountry', pcountry, pcode);
        if (pcountry !== '') {
            setePcountry(epcountry);
            setPcountry(pcountry);
            setePcode(pcode);
            setPcode(pcode);
            const countryDetail = {
                code: pcode,
                name: pcountry,
            };
            setCountrySelect(countryDetail);
        }
        console.debug(pcountry);
        console.debug(pcode);
    };

    console.debug('setePcountry', epcountry);
    console.debug('setePcode', epcode);
    console.debug('setPcode', pcode);

    console.debug('setPcountry', pcountry);

    let nameOfCountry;
    Object.keys(countryList).forEach((key) => {
        if (key === pcode) {
            console.debug('key', countryList[key]);
            nameOfCountry = countryList[key];
        }
    });

    const handleClickPrivateCountry = (event) => {
        var w = document.getElementById('privateCountry').clientWidth;
        setPrivateCountryWidth(w.toString());
        setAnchorEl(event.currentTarget);
    };

    const messageRefTwo = useRef();

    useEffect(() => {
        if (messageRefTwo.current) {
            messageRefTwo.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    });

    return (
        <div>
            <Button
                aria-controls="privateCountry"
                aria-haspopup="true"
                onClick={handleClickPrivateCountry}
                id="privateCountry"
                disableRipple
                className={classes.formControl}
                classes={{ label: classes.menuLabel }}
                style={{ marginTop: 10 }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography
                        style={{
                            fontFamily: 'MessinaSans-Regular',
                            fontSize: '0.875rem',
                            color: theme.palette.common.darkGrey,
                            textTransform: 'none',
                            padding: 0,
                            marginLeft: 3,
                        }}
                    >
                        {text}
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: 'MessinaSans-SemiBold',
                            fontSize: '1rem',
                            color: theme.palette.common.darkGrey,
                            textTransform: 'none',
                            marginLeft: 3,
                            textAlign: 'left',
                        }}
                    >
                        {nameOfCountry}
                    </Typography>
                </div>
                <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
            </Button>

            <Menu
                id="privateCountry"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={() => {
                    handleClosePrivatePersonalDataCountry();
                }}
                //className={classes.customWidth}
                // classes={{
                //   paper: classes.dropDownMenuPopup,
                // }}
                PaperProps={{
                    style: {
                        width: parseFloat(privateCountryWidth),
                        backgroundColor: 'white',
                        paddingTop: 10,

                        border: `2px solid ${theme.palette.common.yellow}`,
                        height: 400,
                    },
                }}
            >
                {Object.keys(countryList).map((key, index) => (
                    <MenuItem
                        key={index}
                        className={classes.menuItem}
                        onClick={() => handleClosePrivatePersonalDataCountry(countryList[key], key)}
                        style={{
                            backgroundColor: key === epcode ? '#F9F8F4' : 'white',
                        }}
                        ref={key === epcode ? messageRefTwo : null}
                    >
                        {countryList[key]}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default CountrySelection;
