import { Button, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from '../MedicationComponents/MedicationClasses';

const LifeStyleDiet = ({ mainData }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const [deleteMultipleLocal, setDeleteMultipleLocal] = useState([]);
    const [multipleDietLocal, setMultipleDietLocal] = useState([]);

    const {
        setSelectedCount,
        multipleDiet,
        setMultipleDiet,
        selectedCount,
        whichOtherDietSubQuestion,
        setWhichOtherDietSubQuestion,
    } = useContext(ContextProviderTwo);

    console.debug('multipleDiet123:', multipleDiet);

    return (
        <div>
            <Typography className={classes.mainHeading}>{mainData['diet_question'][0].text}</Typography>
            <Grid
                style={{
                    marginBlock: 20,
                }}
                container
            >
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                    {mainData &&
                        mainData['diet_responses'].map((parts) => (
                            <Button
                                onClick={() => {
                                    if (multipleDiet.includes(parts['diet_response_api_value'])) {
                                        let i = multipleDiet.indexOf(parts['diet_response_api_value']);
                                        var s = multipleDiet;
                                        let newR = s.filter((a) => a !== parts['diet_response_api_value']);
                                        setDeleteMultipleLocal(newR);
                                        setSelectedCount(newR.length);
                                        setMultipleDiet(newR);
                                        setDeleteMultipleLocal([]);
                                    } else {
                                        if (multipleDiet[0] === 'NO PREFERENCES') {
                                            let p = multipleDiet.filter((a) => a !== 'NO PREFERENCES');
                                            setMultipleDiet([]);
                                        }
                                        var s = multipleDietLocal;
                                        s.push(parts['diet_response_api_value']);
                                        setMultipleDietLocal(s);
                                        setSelectedCount(s.length);
                                        if (multipleDiet[0] === 'NO PREFERENCES') {
                                            setMultipleDiet([...multipleDietLocal]);
                                        } else {
                                            setMultipleDiet([...multipleDiet, ...multipleDietLocal]);
                                        }
                                        setMultipleDietLocal([]);
                                        let delet = [...multipleDiet, ...multipleDietLocal];
                                        let noPref = parts['diet_response_api_value'];
                                        if (noPref === 'NO PREFERENCES') {
                                            let p = delet.filter((a) => a === noPref);
                                            console.debug('lifeStyle1: 12', p);
                                            setMultipleDiet(p);
                                        }
                                    }
                                }}
                                disableRipple
                                className={
                                    multipleDiet.includes(parts['diet_response_api_value'])
                                        ? clsx(
                                              classes.selectItemCard,
                                              classes.selectedCard,
                                              !matches ? classes.selectedHover : classes.noHover
                                          )
                                        : clsx(
                                              classes.selectItemCard,
                                              classes.selectItemCardBorder,
                                              !matches ? classes.selectedHover : classes.noHover
                                          )
                                }
                                style={{
                                    paddingInline: matches && 10,
                                    wordWrap: 'break-word',
                                    flex: matches && '1 0 40%',
                                }}
                            >
                                <div className="">
                                    <Typography className={classes.BtncontentText}>
                                        {parts['diet_reponse'][0].text}
                                    </Typography>
                                </div>
                            </Button>
                        ))}

                    {multipleDiet.includes('OTHER') && (
                        <Grid lg={12} sm={12} xs={12}>
                            <TextField
                                id="lifeStyleOther"
                                label={
                                    mainData ? mainData.diet_subquestion_which__if_other_[0].text : t('TransferSlip')
                                }
                                variant="filled"
                                autoComplete="off"
                                color="primary"
                                value={whichOtherDietSubQuestion}
                                onChange={(event) => {
                                    setWhichOtherDietSubQuestion(event.target.value);
                                }}
                                classes={{
                                    root: classes.textFields,
                                }}
                                style={{
                                    width: '100%',
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                }}
                            />
                        </Grid>
                    )}
                </div>
            </Grid>
        </div>
    );
};

export default LifeStyleDiet;
