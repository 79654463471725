import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import pencilIcon from '@/assets/images/pencilIcon.png';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import summaryPersonalInfo from '@/assets/images/summaryPersonalInfo.svg';
import { Context as AuthContext } from '@/context/AuthContext';
import { useStyles } from './SummaryStyles';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { getAdressAPI } from '@/service/AnamnesisSectionAPI/PersonalnformationAPI';
import Icon from '@/components/Icon/Icon';
import useI18nCountries from '@/hooks/useI18nCountries';

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

export default function SummaryPersonalInfo({ mainData, allData }) {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [genderPersonal, setGenderPersonal] = useState('');
    const [titlePersonal, setTitlePersonal] = useState('');
    const [countryPersonal, setCountryPersonal] = useState('');
    const {
        state: { user, usersession },
    } = useContext(AuthContext);

    console.debug('hello8', mainData, user);

    const {
        state: { personal_information, review_option },
        redirectFunctionSummary,
        setPersonalInformation,
    } = useContext(HealthProfileContextMain);
    const { allDataApi } = useContext(ContextProviderTwo);

    let lan = languageMap[i18n.language];
    const countryList = useI18nCountries();

    useLayoutEffect(() => {
        if (allDataApi?.meta?.has_anamnesis && review_option) {
            if (user) {
                let phoneCode =
                    user.phone['number'] &&
                    user.phone.number.split(' ')[0] !== null &&
                    user.phone.number.split(' ')[0] !== undefined &&
                    user.phone.number.split(' ')[0] !== ''
                        ? user.phone.number.split(' ')[0]
                        : '+49';
                let phoneNumber =
                    user.phone['number'] &&
                    user.phone.number.split(' ')[1] !== null &&
                    user.phone.number.split(' ')[1] !== undefined &&
                    user.phone.number.split(' ')[1] !== ''
                        ? user.phone.number.split(' ')[1]
                        : '';

                let dob = user.date_of_birth.split('-');
                let validFormat = `${dob[2]}/${dob[1]}/${dob[0]}`;

                getAdressAPI(usersession)
                    .then((response) => {
                        let adressGetApi = response?.data;
                        console.debug('adress_get_api', adressGetApi);
                        if (adressGetApi !== undefined && adressGetApi !== null) {
                            setPersonalInformation({
                                gender: user.gender,
                                title:
                                    user.title !== '' && user.title !== undefined && user.title !== null
                                        ? user.title
                                        : '-',
                                firstName: user.name.first,
                                lastName: user.name.last,
                                middleName:
                                    user.name.middle !== '' &&
                                    user.name.middle !== undefined &&
                                    user.name.middle !== null
                                        ? user.name.middle
                                        : '',
                                email: user.email,
                                dob: validFormat,
                                phoneNumber: phoneCode.concat(' ' + phoneNumber),
                                streetNumber: adressGetApi.street,
                                zipCode: adressGetApi.zip,
                                city: adressGetApi.city,
                                country: adressGetApi?.country.name,
                                countryCode: adressGetApi?.country.code,
                            });
                        }
                    })
                    .catch((error) => {
                        console.debug('adress_get_api ERROR', error);
                    });
            }
        }
    }, [allDataApi]);

    useEffect(() => {
        if (
            personal_information['gender'] !== undefined &&
            personal_information['gender'] !== null &&
            personal_information['gender'] !== ''
        ) {
            let genderP = {};
            Object.keys(allData).forEach((val) => {
                if (val === 'en-us') {
                    allData[val].gender_values.find((valu) => {
                        if (personal_information['gender'] === valu.gender_api_value) {
                            genderP['en-us'] = valu.gender_display_value[0].text;
                        }
                    });
                }
                if (val === 'de-de') {
                    allData[val].gender_values.forEach((valu) => {
                        if (personal_information['gender'] === valu.gender_api_value) {
                            genderP['de-de'] = valu.gender_display_value[0].text;
                        }
                    });
                }
            });
            console.debug('Questions3: value', genderP[lan]);
            let gender__ = genderP[lan];
            setGenderPersonal(gender__);
        }
        setCountryPersonal(countryList[personal_information['countryCode']]);
    }, [personal_information]);

    if (personal_information['phoneNumber']) {
        console.debug(
            'phone123',
            personal_information['phoneNumber'].split(' '),
            personal_information['phoneNumber'].split(' ')[1] !== ''
        );
    }

    return (
        <>
            <div className={classes.contentContainerBody}>
                {/* personal Info */}
                <Grid container direction={'row'} style={{ height: '50%' }}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 10 }}>
                        {!matches && (
                            <Icon src={summaryPersonalInfo} description="personal info icon" width={48} height={48} />
                        )}
                        <Typography className={classes.shortHeading} style={{ marginTop: !matches && 10 }}>
                            {mainData.personal_information_title[0].text}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <div className={classes.flexClass}>
                            <img
                                src={pencilIcon}
                                alt=""
                                className={classes.pointer}
                                onClick={() => {
                                    redirectFunctionSummary(
                                        history,
                                        1,
                                        'set_personal_information',
                                        personal_information
                                    );
                                }}
                            />
                        </div>
                    </Grid>
                    {/* change */}

                    {/* for unregister user */}

                    <>
                        {personal_information['gender'] !== undefined &&
                            personal_information['gender'] !== null &&
                            personal_information['gender'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.gender_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>{genderPersonal}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        {/* title */}
                        {/* {personal_information['title'] !== undefined &&
                            personal_information['title'] !== null &&
                            personal_information['title'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.title_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>{titlePersonal}</Typography>
                                    </Grid>
                                </Grid>
                            )} */}
                        {/* first naem */}
                        {personal_information['firstName'] !== undefined &&
                            personal_information['firstName'] !== null &&
                            personal_information['firstName'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.first_name_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {personal_information.firstName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        {/* last */}
                        {/* {personal_information['middleName'] !== undefined &&
                            personal_information['middleName'] !== null &&
                            personal_information['middleName'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.middle_name_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {personal_information.middleName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )} */}
                        {/* middle */}
                        {personal_information['lastName'] !== undefined &&
                            personal_information['lastName'] !== null &&
                            personal_information['lastName'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.last_name_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {personal_information.lastName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                        {/* dob */}
                        {personal_information['dob'] !== undefined &&
                            personal_information['dob'] !== null &&
                            personal_information['dob'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.dob_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {personal_information.dob}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        {/* email */}
                        {personal_information['email'] !== undefined &&
                            personal_information['email'] !== null &&
                            personal_information['email'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>{mainData.email[0].text}</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading} style={{ wordBreak: 'break-word' }}>
                                            {personal_information.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        {/* mobile Nimber */}
                        {personal_information['phoneNumber'] &&
                            personal_information['phoneNumber'].split(' ').length > 1 &&
                            personal_information['phoneNumber'].split(' ')[1] && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.mobile_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {personal_information.phoneNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        {/* street  */}
                        {personal_information['streetNumber'] !== undefined &&
                            personal_information['streetNumber'] !== null &&
                            personal_information['streetNumber'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.street_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {personal_information.streetNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        {/* zip code] */}
                        {personal_information['zipCode'] !== undefined &&
                            personal_information['zipCode'] !== null &&
                            personal_information['zipCode'] !== '' &&
                            personal_information['city'] !== undefined &&
                            personal_information['city'] !== null &&
                            personal_information['city'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.zip_code_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {personal_information.zipCode} {personal_information.city}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                        {/* country */}
                        {personal_information['countryCode'] !== undefined &&
                            personal_information['countryCode'] !== null &&
                            personal_information['countryCode'] !== '' && (
                                <Grid container direction={'row'} style={{ marginBottom: 10 }}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>
                                            {mainData.country_personal[0].text}
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Typography className={classes.subHeading}>{countryPersonal}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                    </>

                    {/* for register User */}
                    {personal_information['occupation'] && personal_information['occupation']?.trim().length > 0 ? (
                        <>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {mainData.subtitle_occupation[0].text}
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.subHeading}>
                                    {personal_information?.occupation}
                                </Typography>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </div>
        </>
    );
}
