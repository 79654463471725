import React from 'react';
//** Clild Components */
import { CreateUserAccount } from '@/components/ConsentForms';
import useScrollToTop from '@/hooks/useScrollToTop';

const CreateAccount = () => {
    useScrollToTop();
    return <CreateUserAccount />;
};

export default CreateAccount;
