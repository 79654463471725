import { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './StaticPageClasses';
import Loader from '@/components/Loader';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const StaticPage = ({ page }) => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const { [i18n.language]: staticPages } = usePersistedPrismicStore((state) => state.staticPages);

    const mainData = useMemo(() => staticPages?.find((item) => item.data.page === page)?.data, [staticPages]);

    console.debug(mainData);

    if (!mainData || !staticPages) return <Loader />;

    return (
        <div className={classes.contentContainer}>
            <Typography className={classes.mainHeading}>{mainData.main_heading[0].text}</Typography>

            {mainData.page_content.map((content, index) => {
                let props = { key: `${content.text.replace(' ', '_')}_${index}` };
                if (content.type === 'heading2')
                    props = { ...props, className: classes.heading, style: { marginTop: 36 } };
                if (content.type === 'paragraph')
                    props = { ...props, className: classes.subHeading, style: { marginTop: 10 } };
                if (content.type === 'heading3')
                    props = { ...props, className: classes.smallHeading, style: { marginTop: 28 } };

                return <Typography {...props}>{content.text}</Typography>;
            })}
        </div>
    );
};

export default StaticPage;
