import { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles, rgbToHex, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import UnderlinedButton from '@/components/UnderlinedButton';
import { Context as AuthContext } from '@/context/AuthContext';
import { useTranslation } from 'react-i18next';
import ACCOUNT_security_icon from '@/assets/Icons/ACCOUNT_security_icon.svg';
import FilledButton from '@/components/FilledButton';
import OutlinedButton from '@/components/OutlinedButton';
import DialogBox from '@/components/Common/DialogBox';
import { processAPIWithHeaders } from '@/utils/helpers';
import { CircularProgress } from '@material-ui/core';
import Icon from '@/components/Icon/Icon';

const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        contentContainer: {
            maxWidth: theme.layout.contentWidth,

            margin: '0 auto',
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 20,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 30,
            },
        },
        heading: {
            ...theme.typography.HL1,
        },
        cardContent: {
            backgroundColor: 'white',

            maxWidth: theme.layout.contentWidth,
            boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
            borderRadius: 8,
            display: 'block',

            marginTop: 23,
        },
        avatar: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            objectFit: 'cover',
            maxHeight: 400,
            backgroundColor: 'red',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        editAvatar: {
            width: 200,
            height: 200,
            borderRadius: 100,
            objectFit: 'cover',
            // maxHeight: 400,
            // minHeight: 250,
            marginTop: 10,
        },
        accountData: {
            // paddingLeft: 40,
            // paddingRight: 40,
            paddingTop: 32,
            paddingBottom: 32,
        },
        icon: {
            width: 50,
            height: 50,
            objectFit: 'contain',
        },
        subHeading: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.75rem',
            textTransform: 'none',

            color: theme.palette.common.brown,
            marginTop: 20,
        },
        userData: {
            fontFamily: 'MessinaSerif-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        downArrow: {
            height: '0.75em',
            marginRight: 15,
        },
        selectedOptions: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',

            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        editLinks: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.darkGrey,
            marginTop: 25,
        },
        deleteLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
            marginLeft: 20,
        },
        deleteAccountLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
        },
        editButton: {
            background: 'transparent',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        buttonLabel: {
            padding: 0,
            justifyContent: 'left',
        },
        cardbg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'rgba(255,200,119,0.2)',
            maxHeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        imagebg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            maxHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        card: {
            width: '80%',
            height: '85%',
            maxWidth: 400,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            backgroundColor: theme.palette.common.cream,

            maxHeight: 400,
            [theme.breakpoints.down('sm')]: {
                width: '70%',
                height: '85%',
            },

            [theme.breakpoints.down('xs')]: {
                width: '70%',
                height: '85%',
            },
        },
        visa: {
            width: 53,
            height: 30,
            objectFit: 'contain',
            marginTop: 30,
            marginLeft: 30,
            marginBottom: 20,
        },
        textFields: {
            height: '3.75em',
            marginTop: 10,
        },

        inputLabel: {
            '&.focused': {
                color: theme.palette.common.darkGrey,
            },
            '&.shrink': {
                fontSize: '1rem',
            },
        },
        submitButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.yellow,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.yellow,
            },
        },
        cancelButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.mediumBrown,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.mediumBrown,
            },
        },
        buttonText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        menuItem: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            color: theme.palette.common.darkGrey,
            textTransform: 'none',
        },
        formControl: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',
            // marginBottom: 15,
            justifyContent: 'space-between',
            // [theme.breakpoints.down("md")]: {
            //   width: 150,
            // },
            // [theme.breakpoints.down("sm")]: {
            //   width: 100,
            // },
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        formControlMobileBtn: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',

            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,

            borderRightWidth: 0,

            marginBottom: 15,
            justifyContent: 'space-between',
            // [theme.breakpoints.down("md")]: {
            //   width: 150,
            // },
            // [theme.breakpoints.down("sm")]: {
            //   width: 100,
            // },
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        accountIcon: {
            width: 250,
            height: 250,
            objectFit: 'contain',
            backgroundColor: 'rgba(255,200,119,0.2)',
            padding: 10,
            borderRadius: 135,

            [theme.breakpoints.down('xs')]: {
                width: 150,
                height: 150,
            },
        },
        butnMargin: {},
        fieldFocused: {
            backgroundColor: 'white',
            border: `2px solid ${theme.palette.common.yellow} !important`,
        },
        filledInputRoot: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
                //backgroundColor: "red",
            },
        },
        filledInputRootMobile: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
                //backgroundColor: "red",
            },
        },
        btnClass: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },

        blackCircle: {
            backgroundColor: theme.palette.common.darkGrey,
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        greenCircle: {
            backgroundColor: '#5ba668',
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        passwordText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        passwordValidText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.brown,
        },

        errorText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.red,
        },
        font: {
            fontSize: '1.5rem',
            backgroundColor: 'red',
            height: '0',
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
});

const Security = ({ mainData, dataSet }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesxs = useMediaQuery(theme.breakpoints.down('xs'));
    // const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesmd = useMediaQuery(theme.breakpoints.down('md'));
    const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesxl = useMediaQuery(theme.breakpoints.down('xl'));
    const [confirmPassword, setConfirmPassword] = useState('');
    const [openChangeDialogBilling, setOpenChangeDialogBilling] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
    const handleClickShowPassword3 = () => setShowPassword3(!showPassword3);

    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);
    const handleMouseDownPassword3 = () => setShowPassword3(!showPassword3);
    const [billingEdit, setBillingEdit] = useState(false);

    const [errPassword, setErrPassword] = useState('');
    const [currentPswd, setCurrentPswd] = useState('');
    const [errConfirmPassword, setErrConfirmPassword] = useState('');
    const [security, setSecurity] = useState(false);
    const [NewPswd, setNewPswd] = useState('');
    const [CPswd, setCPswd] = useState('');
    const [insurance, setInsurance] = useState(false);

    // loader
    const [loader, setLoader] = useState(false);

    const [passwordValid, setPasswordValid] = useState({
        characters: false,
        number: false,
        special: false,
        Newpassword: '',
    });

    const isSaveButtonDisabled =
        errConfirmPassword || !currentPswd || !confirmPassword || !Object.values(passwordValid).every(Boolean);
    const {
        state: { user, usersession, userAccount },
        setuser,
        setuseraccounts,
    } = useContext(AuthContext);
    //   Functions

    const [state, setState] = useState({
        analytics: userAccount.use_of_analytics_status === 'ACCEPTED',
        notifications: true,
    });

    const validateForm = (type) => {
        switch (type) {
            case 'confirmPassword':
                setErrConfirmPassword('');
                if (confirmPassword === '') {
                    setErrConfirmPassword(t('RequiredField'));
                } else {
                    if (confirmPassword !== passwordValid.Newpassword) {
                        setErrConfirmPassword(t('PasswordsNotMatch'));
                    }
                }
                break;

            default:
                break;
        }
    };
    const hasNumber = (myString) => {
        return /\d/.test(myString);
    };

    const hasSpecial = (myString) => {
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return format.test(myString);
    };

    const updateUserAccount = async () => {
        const updateAccount = await processAPIWithHeaders('secure/user/accounts/' + userAccount.user_id, 'POST', {
            body: {
                user_id: userAccount.user_id,
                privacy_policy_status: userAccount.privacy_policy_status,
                terms_and_conditions_status: userAccount.terms_and_conditions_status,
                use_of_analytics_status: state.analytics ? 'ACCEPTED' : 'DECLINED',
                verification_status: userAccount.verification_status,
                locale: {
                    language: userAccount.locale.language,
                    country: userAccount.locale.country,
                },
                notifications: {
                    is_email_active: userAccount.notifications.is_email_active,
                    is_sms_active: userAccount.notifications.is_sms_active,
                },
                subscriptions: {
                    default: state.notifications,
                },
                status: userAccount.status,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: usersession.idToken.jwtToken,
            },
        });
        console.debug(updateAccount);
        setuseraccounts(updateAccount);
        setSecurity(false);
        // Change Paswd
        Auth.currentAuthenticatedUser()
            .then((user) => {
                console.debug('user: ', user);
                console.debug('User OP ' + currentPswd);
                console.debug('User NP ' + confirmPassword);
                return Auth.changePassword(user, currentPswd, confirmPassword);
            })
            .then((data) => console.debug('here data is ' + data))
            .catch((err) => console.debug('here error is ' + JSON.stringify(err)));
        // Change Paswd ends
        // console.debug("user" + JSON.stringify(user));
    };

    console.debug('secuirtymaindata', mainData);

    return (
        <Grid container>
            <Grid item xs={12} lg={6} md={7} sm={9}>
                <div
                    className={classes.accountData}
                    style={{
                        paddingRight: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                        paddingLeft: matches ? 20 : matchesmd ? 30 : matcheslg ? 40 : matchesxl && 40,
                    }}
                >
                    <Icon src={ACCOUNT_security_icon} description="eterno security" />
                    <Typography className={classes.subHeading}>
                        {dataSet ? mainData.security_section_title[0].text : t('Security')}
                    </Typography>

                    {security && (
                        <div style={{ marginTop: 0 }}>
                            <TextField
                                id="CurrentPswd"
                                label={dataSet ? mainData.current_password[0].text : t('CurrentPswd')}
                                variant="filled"
                                color="primary"
                                type={showPassword3 ? 'text' : 'password'}
                                value={currentPswd}
                                onChange={(event) => {
                                    const text = event.target.value;
                                    setCurrentPswd(text);
                                }}
                                fullWidth
                                classes={{
                                    root: classes.textFields,
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword3}
                                                onMouseDown={handleMouseDownPassword3}
                                            >
                                                {showPassword3 ? (
                                                    <VisibilityOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                id="NewPswd"
                                label={dataSet ? mainData.new_password[0].text : t('NewPswd')}
                                variant="filled"
                                color="primary"
                                type={showPassword ? 'text' : 'password'}
                                value={passwordValid.Newpassword}
                                onChange={(event) => {
                                    // const text = event.target.value;
                                    // setNewPswd(text);
                                    const password = event.target.value;
                                    var characters = false;

                                    if (password.length > 7) {
                                        characters = true;
                                    }

                                    const number = hasNumber(password);
                                    const special = hasSpecial(password);

                                    setPasswordValid({
                                        characters,
                                        number,
                                        special,
                                        Newpassword: password,
                                    });
                                }}
                                fullWidth
                                classes={{
                                    root: classes.textFields,
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },

                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? (
                                                    <VisibilityOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errPassword && passwordValid.password === '' && (
                                <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                                    {errPassword}
                                </Typography>
                            )}

                            {/* password stage */}

                            {passwordValid.Newpassword !== '' ? (
                                <div>
                                    <Typography className={classes.passwordText} style={{ marginTop: 8 }}>
                                        Your password must contain:
                                    </Typography>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <div
                                                className={
                                                    passwordValid.characters ? classes.greenCircle : classes.blackCircle
                                                }
                                            ></div>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                className={
                                                    passwordValid.characters
                                                        ? classes.passwordValidText
                                                        : classes.passwordText
                                                }
                                            >
                                                At least 8 characters
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <div
                                                className={
                                                    passwordValid.number ? classes.greenCircle : classes.blackCircle
                                                }
                                            ></div>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                className={
                                                    passwordValid.number
                                                        ? classes.passwordValidText
                                                        : classes.passwordText
                                                }
                                            >
                                                A number
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <div
                                                className={
                                                    passwordValid.special ? classes.greenCircle : classes.blackCircle
                                                }
                                            ></div>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                className={
                                                    passwordValid.special
                                                        ? classes.passwordValidText
                                                        : classes.passwordText
                                                }
                                            >
                                                A special character
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            ) : null}

                            {/* ends */}

                            <TextField
                                id="confirmpasword"
                                label={dataSet ? mainData.confirm_password[0].text : 'Confirm Password'}
                                variant="filled"
                                color="primary"
                                fullWidth
                                type={showPassword2 ? 'text' : 'password'}
                                style={{ marginTop: 15 }}
                                value={confirmPassword}
                                onChange={(event) => {
                                    const text = event.target.value;
                                    setConfirmPassword(text);
                                }}
                                onBlur={() => validateForm('confirmPassword')}
                                classes={{
                                    root: errConfirmPassword ? classes.textFieldsError : classes.textFields,
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword2}
                                                onMouseDown={handleMouseDownPassword2}
                                            >
                                                {showPassword2 ? (
                                                    <VisibilityOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {errConfirmPassword && (
                                <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                                    {errConfirmPassword}
                                </Typography>
                            )}
                        </div>
                    )}

                    {!security && (
                        <UnderlinedButton
                            text={dataSet ? mainData.change_password_cta[0].text : t('ChangePswd')}
                            onPress={() => {
                                setNewPswd(passwordValid.Newpassword);
                                setCurrentPswd(currentPswd);
                                setCPswd(confirmPassword);
                                setSecurity(true);
                            }}
                            color={theme.palette.common.darkGrey}
                        />
                    )}
                </div>
                {/* //////////////////////// */}
                {security && (
                    <Grid
                        item
                        xs={12}
                        style={{
                            paddingLeft: 40,
                            paddingRight: 40,
                            paddingBottom: 32,
                        }}
                    >
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <div style={{}}>
                                    {matchesxs ? (
                                        <div className="">
                                            <div style={{ marginBottom: 10 }}>
                                                <FilledButton
                                                    text={dataSet ? mainData.save_changes[0].text : t('SaveChanges')}
                                                    onPress={() => {
                                                        updateUserAccount();
                                                        setSecurity(false);
                                                    }}
                                                    color={'yellow'}
                                                    fullWidth
                                                />
                                            </div>
                                            <OutlinedButton
                                                text={dataSet ? mainData.cancel[0].text : t('Cancel')}
                                                onPress={() => {
                                                    setOpenChangeDialogBilling(true);
                                                }}
                                                color={'yellow'}
                                                disableRipple={true}
                                                fullWidth
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <OutlinedButton
                                                text={dataSet ? mainData.cancel[0].text : t('Cancel')}
                                                onPress={() => {
                                                    setOpenChangeDialogBilling(true);
                                                }}
                                                disableRipple={true}
                                                color={'yellow'}
                                            />
                                            <div style={{ marginLeft: 5 }}>
                                                <FilledButton
                                                    disabled={loader || isSaveButtonDisabled}
                                                    text={
                                                        dataSet && !loader ? (
                                                            mainData.save_changes[0].text
                                                        ) : !loader ? (
                                                            t('SaveChanges')
                                                        ) : (
                                                            <CircularProgress
                                                                style={{
                                                                    color: theme.palette.common.yellow,
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    onPress={() => {
                                                        setLoader(true);
                                                        updateUserAccount();
                                                    }}
                                                    color={'yellow'}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <DialogBox
                open={openChangeDialogBilling}
                onClose={() => {
                    console.debug('closing dialog');
                    setOpenChangeDialogBilling(false);
                }}
                onPress={() => {
                    /** Resetting Passwords on don't save click */
                    setCurrentPswd('');
                    setConfirmPassword('');
                    setPasswordValid({
                        characters: false,
                        number: false,
                        special: false,
                        Newpassword: '',
                    });

                    setOpenChangeDialogBilling(false);
                    setBillingEdit(false);
                    setInsurance(false);
                    setSecurity(false);
                }}
                title={dataSet ? mainData.sure_warning[0].text : 'Are you sure?'}
                text={dataSet ? mainData.changes_not_saved[0].text : 'The changes you have made will not be saved.'}
                cancelButton={true}
                cancelText={dataSet ? mainData.cancel[0].text : 'Are you sure?'}
                mainButton={true}
                mainButtonText={dataSet ? mainData.don_t_save[0].text : "Don't Save"}
                mainButtonColor={'yellow'}
            />
        </Grid>
    );
};

export default Security;
