import { Fragment, memo, useContext, useEffect, useState } from 'react';
import useHubValues from '@/hooks/useHubValues';
import { useStyles } from './HealthProfileMainClasses';
import LifeStyleButtonComponent from './LifeStyleComponents/LifeStyleButtonComponent';
import { Grid } from '@material-ui/core';
import { DropDown } from '@/components/Common/DropDown/DropDown';
import useHubName from '@/hooks/useHubName';
import FilledButton from '@/components/FilledButton';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as AuthContext } from '@/context/AuthContext';

const UnRegisterUserLocation = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedHub, setSelectedHub] = useState('');
    const [selectedHubIndex, setSelectedHubIndex] = useState();
    const [specificDoctor, setSpecificDoctor] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState([]);
    const [hasDoctor, setHasDoctor] = useState([]);
    const { doctorSpecific } = useHubName();
    const { mainData, data } = useHubValues();
    const history = useHistory();
    const { setFromAppointment, setAppointmentDoctorInfo, setFromLocation } = useContext(ContextProviderTwo);
    const {
        state: { user },
    } = useContext(AuthContext);
    useEffect(() => {
        if (user) {
            history.replace('/anamnesis-health-profile');
            return;
        }
        if (selectedHub) {
            localStorage.setItem('hub', selectedHubIndex + 1);
            localStorage.setItem('sessionInSpace', 'true');
        }
        let checkingDoctor = [];
        let doctor = [];
        doctorSpecific.forEach((doc) => {
            let obj = {};
            if (doc[selectedHub]) {
                let data = doc[selectedHub];
                checkingDoctor.push(data);
                obj.title = data?.display_name;
                obj.key = data?.key[0]?.text;
            }
            obj.hasOwnProperty('key') && doctor?.push(obj);
        });
        doctor.sort((a, b) => {
            const nameA = a?.title[0]?.text.toUpperCase(); // ignore upper and lowercase
            const nameB = b?.title[0]?.text.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        setSpecificDoctor(doctor);
        setHasDoctor(checkingDoctor);
    }, [selectedHub]);

    const disabledDoctor = () => {
        if (specificDoctor.length === 0 || selectedDoctor.length === 0) {
            return true;
        }
        return hasDoctor.filter((doc) => doc?.key[0].text === selectedDoctor).length === 0;
    };

    return (
        <Fragment>
            <div className={classes.contentContainerBody}>
                <div className={classes.mainPage}>
                    <LifeStyleButtonComponent
                        setSelected={setSelectedHub}
                        heading={mainData?.select_location_pre_welcome_page_title[0]?.text}
                        data={data}
                        selected={selectedHub}
                        marginTop={false}
                        setIndex={setSelectedHubIndex}
                        isIndexing
                    />
                    <Grid container direction="row" spacing={2} className={classes.center}>
                        <Grid item md={10} xs={12}>
                            <DropDown
                                type="default"
                                mainDataHeading={mainData?.select_professional_dropdwon_placeholder[0]?.text}
                                mainDataArray={specificDoctor}
                                setData={setSelectedDoctor}
                                data={selectedDoctor}
                                widthID="SeleteDoctor"
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <FilledButton
                                text={mainData?.continue[0].text}
                                onPress={() => {
                                    setFromAppointment(true);
                                    setFromLocation(true);
                                    setAppointmentDoctorInfo(selectedDoctor);
                                    history.push('/anamnesis-welcome');
                                }}
                                color={theme.palette.common.darkGrey}
                                disabled={disabledDoctor()}
                                fullWidth={true}
                                marginTop={10}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
};

export default memo(UnRegisterUserLocation);
