import { useContext, useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import FilledButton from '@/components/FilledButton';
import { ContextProvider } from '@/context/ProjectContext';
import { useHistory } from 'react-router-dom';
import { Context as AuthContext } from '@/context/AuthContext';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import OutlinedButton from '@/components/OutlinedButton';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Auth } from 'aws-amplify';
import { adressApi, userAccountsApi } from '@/service/AnamnesisSectionAPI/PersonalnformationAPI';
import { getISODate, trackStormlyAnamnesisSection } from '@/utils/helpers';
import { useStyles } from './PersonalInfoComponents/PersonalInfoClass';
import PersonalInfoLoginForm from './PersonalInfoComponents/PersonalInfoLoginForm';
import { TextField, Typography } from '@material-ui/core';
import PersonalInfocontactDetails from './PersonalInfoComponents/PersonalInfoContactDetails';
import useFormSteps from '@/hooks/useFormSteps';
import useI18nCountries from '@/hooks/useI18nCountries';
import isEmail from 'validator/es/lib/isEmail';

export default function PersonalInfo({ mainData, user, usersession }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    let history = useHistory();
    const { languageGlobal } = useContext(ContextProvider);

    const {
        state: { step, personal_information, save_changes_option },
        BackBtn,
        UpdateSteps,
        setPersonalInformation,
        onSaveButton,
        onCancelButton,
        setReviewOption,
    } = useContext(HealthProfileContextMain);

    const { isConsentAllForms, isLastStep } = useFormSteps();

    const countryList = useI18nCountries();

    const [loading, setLoading] = useState(false);

    const {
        epcountry,
        estreetMainAdress,
        ezipMainAdress,
        ecityMainAdress,
        efirstName,
        elastName,
        emiddleName,
        eemail,
        dob,
        mobileCode,
        mobileNumber,
        egender,
        etitle,
        pcode,
        epcode,
        occuption,
        seteFirstName,
        seteLastName,
        seteEmail,
        setDOB,
        setPrivateGender,
        seteGender,
        setOccuption,
        anchorE7,
        setIsConcent,
        setErrDOB,
        setErrLastName,
        setErrFirstName,
        setErrGender,
        setErrEmail,
        errZip,
        setErrZip,
        setErrorCity,
        setErrorStreet,
        errPhoneNumber,
        setErrPhoneNumber,
        setMobileNumber,
        errDOB,
        seteSreetMainAdress,
        seteZipMainAdress,
        seteCityMainAdress,
        setCountrySelect,
        setPcountry,
        setePcountry,
    } = useContext(ContextProviderTwo);

    const emailExists = async (value) => {
        Auth.signIn(eemail || value, '123')
            .then(() => {
                setErrEmail('');
            })
            .catch((error) => {
                const code = error.code;
                switch (code) {
                    case 'NotAuthorizedException':
                        setErrEmail(mainData.used_email[0].text);
                        break;
                    default:
                        break;
                }
            });
    };

    const messageRef = useRef();
    useEffect(() => {
        if (messageRef.current) {
            console.debug('messageRef 12', messageRef);
            messageRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }, [anchorE7]);

    const { setuser } = useContext(AuthContext);

    const handleFormSubmit = () => {
        setErrEmail('');
        setErrFirstName('');
        setErrGender('');
        setErrLastName('');
        setErrDOB('');
        setErrPhoneNumber(false);
        setErrZip('');
        setErrorCity('');
        setErrorStreet('');
        {
            let err = false;
            if (eemail === '') {
                setErrEmail(mainData.required_field_error_message[0].text);
                err = true;
            } else {
                if (!isEmail(eemail)) {
                    setErrEmail(mainData.invalid_email_error_message[0].text);
                    err = true;
                }
            }
            if (!mobileNumber || mobileNumber?.split(' ')[1]?.length === 0) {
                setErrPhoneNumber(true);
                err = true;
            }
            if (!egender) {
                setErrGender(mainData.required_field_error_message[0].text);
                err = true;
            }

            if (efirstName === '') {
                setErrFirstName(mainData.required_field_error_message[0].text);
                err = true;
            }

            if (elastName === '') {
                setErrLastName(mainData.required_field_error_message[0].text);
                err = true;
            }
            if (dob.length === 0) {
                setErrDOB(mainData.required_field_error_message[0].text);
                err = true;
            }
            if (!ezipMainAdress) {
                setErrZip(mainData.required_field_error_message[0].text);
                err = true;
            }
            if (!ecityMainAdress) {
                setErrorCity(mainData.required_field_error_message[0].text);
                err = true;
            }
            if (!estreetMainAdress) {
                setErrorStreet(mainData.required_field_error_message[0].text);
                err = true;
            }
            if (err) {
                return;
            }
            profileData();
        }
    };

    const profileDisabled = () => {
        return (
            !efirstName ||
            !elastName ||
            !eemail ||
            !dob ||
            !egender ||
            !epcode ||
            !estreetMainAdress ||
            !ecityMainAdress ||
            !ezipMainAdress
        );
    };

    console.debug('personal_info: profile1', egender);
    const profileData = () => {
        if (profileDisabled()) {
            return;
        }
        if (!isEmail(eemail)) {
            setErrEmail(mainData.invalid_email_error_message[0].text);
            return;
        } else {
            if (!user) {
                emailExists();
            }
        }

        setPersonalInformation({
            gender: egender,
            title: etitle,
            firstName: efirstName,
            lastName: elastName,
            middleName: emiddleName,
            email: eemail,
            dob: dob,
            phoneNumber: mobileNumber,
            streetNumber: estreetMainAdress,
            zipCode: ezipMainAdress,
            city: ecityMainAdress,
            country: epcountry,
            occupation: occuption || '',
            countryCode: epcode,
        });
        PersonalInfoApi();
        if (!save_changes_option) {
            trackStormlyAnamnesisSection();
            UpdateSteps(step, history, user, false, isConsentAllForms, isLastStep);
        }
    };

    const Cancel = () => {
        seteFirstName(personal_information.firstName);
        seteLastName(personal_information.lastName);
        seteEmail(personal_information.email);
        setDOB(personal_information.dob);
        setPrivateGender(personal_information.gender);
        seteGender(personal_information.gender);
        setOccuption(personal_information.occuption?.trim());
        setMobileNumber(personal_information.phoneNumber);
        seteSreetMainAdress(personal_information.streetNumber);
        seteZipMainAdress(personal_information.zipCode);
        seteCityMainAdress(personal_information.city);
        setPcountry(personal_information.country);
        setePcountry(personal_information.country);
        setCountrySelect(personal_information.country);
        onCancelButton(history);
    };

    const PersonalInfoApi = () => {
        if (user) {
            setLoading(true);

            // api call for address
            const body = {
                user_id: usersession?.idToken.payload.sub,
                street: estreetMainAdress,
                zip: ezipMainAdress,
                city: ecityMainAdress,
                country: {
                    code: epcode,
                    name: countryList[epcode],
                },
            };
            adressApi(usersession, body);
            // for user
            const bodyTwo = {
                name: {
                    first: efirstName,
                    middle: emiddleName,
                    last: elastName,
                },
                gender: egender,
                date_of_birth: getISODate(
                    dob
                        .replace(languageGlobal === 'en' && '.', '/')
                        .replace(languageGlobal === 'en' && '.', '/')
                        .replace(languageGlobal === 'de' && '/', '.')
                        .replace(languageGlobal === 'de' && '/', '.'),
                    languageGlobal
                ),
                title: etitle === '-' ? '' : etitle,
                phone: {
                    number: mobileNumber,
                },
                occupation: occuption?.trim() || '',
            };

            userAccountsApi(usersession, bodyTwo)
                .then((response) => {
                    setuser(response);
                    if (save_changes_option) {
                        setReviewOption(false);
                        onSaveButton(history);
                    }
                })
                .catch((error) => {
                    console.debug('ErrorInPersonalInfo', error);
                });
            setLoading(false);
        } else {
            if (save_changes_option) {
                setReviewOption(false);
                onSaveButton(history);
            }
        }
    };

    return (
        <>
            <div className={classes.contentContainerBody}>
                {/* full section starts */}
                <Grid container style={{ padding: 0 }}>
                    <PersonalInfoLoginForm
                        mainData={mainData}
                        user={user}
                        messageRef={messageRef}
                        emailExists={emailExists}
                    />
                    <PersonalInfocontactDetails mainData={mainData} user={user} usersession={usersession} />

                    <Grid item md={12} xs={12} style={{ padding: 0, marginTop: 40 }}>
                        <Typography className={classes.mainHeading}>{mainData?.occupation_question[0].text}</Typography>
                        <TextField
                            label={mainData ? mainData.textbox_occupation_placeholder_text[0].text : t('City')}
                            variant="filled"
                            color="primary"
                            value={occuption}
                            placeholder={mainData.textbox_occupation_placeholder_text[0].text}
                            onChange={(event) => {
                                const text = event.target.value;
                                setOccuption(text);
                            }}
                            fullWidth
                            classes={{
                                root: classes.textFields,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    focused: 'focused',
                                    shrink: 'shrink',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.filledInputRoot,
                                    focused: classes.fieldFocused,
                                    shrink: 'shrink',
                                },
                            }}
                            style={{ flex: 0.6 }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0, marginTop: 30 }}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: matches && 'column-reverse',
                                    }}
                                >
                                    {!save_changes_option ? (
                                        <OutlinedButton
                                            text={mainData ? mainData.back_cta[0].text : t('Cancel')}
                                            onPress={() => {
                                                BackBtn(step, history);
                                            }}
                                            color={'yellow'}
                                            disableRipple={true}
                                            fullWidth={matches && true}
                                        />
                                    ) : (
                                        <OutlinedButton
                                            text={mainData?.cancel[0].text}
                                            onPress={() => {
                                                Cancel();
                                            }}
                                            fullWidth={matches && true}
                                        />
                                    )}

                                    {!save_changes_option ? (
                                        <FilledButton
                                            text={mainData?.continue_cta[0].text}
                                            onPress={() => {
                                                if (errDOB) return;
                                                handleFormSubmit();
                                                setIsConcent(false);
                                            }}
                                            fullWidth={matches && true}
                                            marginBottom={matches && 20}
                                            loading={loading}
                                        />
                                    ) : (
                                        <FilledButton
                                            text={mainData?.save_cta[0].text}
                                            onSaveButton
                                            onPress={() => {
                                                handleFormSubmit();
                                                setIsConcent(false);
                                            }}
                                            fullWidth={matches && true}
                                            marginBottom={matches && 20}
                                        />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* full section ends */}
            </div>
        </>
    );
}
