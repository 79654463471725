import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import CardWrapper from '@/components/HealthProfileOverview/HealthData/CardWrapper';
import { menuItems, useQuery } from '@/utils/helpers';
import HealthContainer from './HealthContainer';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as AuthContext } from './../../context/AuthContext';
import PersonalInfoOccupation from '../HealthProfileAnamnesis/PersonalInfoComponents/PersonalInfoOccupation';
import YourBody from '../HealthProfileAnamnesis/YourBody';
import Medications from '../HealthProfileAnamnesis/Medications';
import { Allergies } from '../HealthProfileAnamnesis/Allergies';
import ExistingCondition from '../HealthProfileAnamnesis/ExistingCondition';
import Family from '../HealthProfileAnamnesis/Family';
import LifeStyle from '../HealthProfileAnamnesis/LifeStyle';
import Loader from '@/components/Loader';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    mainPage: {
        backgroundColor: theme.palette.common.white,
        paddingBottom: 30,
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
    },
    mainHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 24,
        fontWeight: 'normal',
        paddingBottom: '1rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '.6rem',
        },
    },
}));

const HealthDataPage = () => {
    const classes = useStyles();
    const query = useQuery();
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { allData },
    } = usePersistedPrismicStore((state) => state.anamnesisData);

    const { setAllDataApi } = useContext(ContextProviderTwo);
    const {
        state: { userData },
    } = useContext(AuthContext);

    const [contentProfessional, setContentProfessional] = useState({
        'en-us': {},
        'de-de': {},
    });
    const {
        [i18n.language]: { content: mainData, dataSet, mainDataProfessionalDoctors },
    } = usePersistedPrismicStore((state) => state.healthData);

    useMemo(() => {
        if (userData) {
            setAllDataApi(userData);
        }
    }, [userData]);

    useEffect(() => {
        setContentProfessional({
            'en-us': mainDataProfessionalDoctors,
            'de-de': mainDataProfessionalDoctors,
        });
    }, [mainDataProfessionalDoctors]);

    const stepsPathname = menuItems();
    const stepsIndex = stepsPathname?.indexOf(query.get('step'));
    const hSteps = dataSet && [
        mainData.personal_info_nav_title[0].text,
        mainData.your_body_title[0].text,
        mainData['pre-existing_conditions_nav_title'][0].text,
        mainData.allergies_nav_title[0].text,
        mainData.medications_nav_title[0].text,
        mainData.family_disease_history_nav_title[0].text,
        mainData.lifestyle_title[0].text,
    ];

    return (
        <HealthContainer>
            <CardWrapper pBlockXs={20} pInlineXs={20}>
                <Grid item md={12} xs={12} style={{ padding: 0 }}>
                    <Typography className={classes.mainHeading}>{hSteps[stepsIndex]}</Typography>
                </Grid>
                {Object.keys(mainData).length > 0 ? (
                    <HealthDataSteps
                        allData={allData}
                        mainData={mainData}
                        mainDataProfessionalDoctors={mainDataProfessionalDoctors}
                        contentProfessional={contentProfessional}
                    />
                ) : (
                    <Loader />
                )}
            </CardWrapper>
        </HealthContainer>
    );
};

const HealthDataSteps = ({ mainData, allData, mainDataProfessionalDoctors, contentProfessional }) => {
    const query = useQuery();
    let step = query.get('step');
    const stepsPathname = menuItems();

    const {
        state: { user, usersession },
    } = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    switch (step) {
        case stepsPathname[0]:
            return (
                <PersonalInfoOccupation
                    mainData={mainData}
                    mainDataProfessionalDoctors={mainDataProfessionalDoctors}
                    contentProfessional={contentProfessional}
                    isFromHealthData={true}
                />
            );
        case stepsPathname[1]:
            return (
                <YourBody
                    mainData={mainData}
                    allData={allData}
                    user={user}
                    usersession={usersession}
                    isFromHealthData={true}
                />
            );
        case stepsPathname[2].toString():
            return (
                <ExistingCondition
                    mainData={mainData}
                    allData={allData}
                    user={user}
                    usersession={usersession}
                    isFromHealthData={true}
                />
            );
        case stepsPathname[3].toString():
            return (
                <Allergies
                    mainData={mainData}
                    allData={allData}
                    user={user}
                    usersession={usersession}
                    isFromHealthData={true}
                />
            );
        case stepsPathname[4]:
            return (
                <Medications
                    mainData={mainData}
                    allData={allData}
                    user={user}
                    usersession={usersession}
                    isFromHealthData={true}
                />
            );
        case stepsPathname[5].toString():
            return (
                <Family
                    mainData={mainData}
                    allData={allData}
                    user={user}
                    usersession={usersession}
                    isFromHealthData={true}
                />
            );
        case stepsPathname[6].toString():
            return (
                <LifeStyle
                    mainData={mainData}
                    allData={allData}
                    user={user}
                    usersession={usersession}
                    isFromHealthData={true}
                />
            );
        default:
            // returning first step
            return (
                <PersonalInfoOccupation
                    mainData={mainData}
                    mainDataProfessionalDoctors={mainDataProfessionalDoctors}
                    contentProfessional={contentProfessional}
                    isFromHealthData={true}
                />
            );
    }
};

export default memo(HealthDataPage);
