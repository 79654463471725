import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';
import CardHeading from './CardHeading';
import CardWrapper from './CardWrapper';

const useStyles = makeStyles((theme) => ({
    italicHeading: {
        fontFamily: 'MessinaSerif-Regular',
        fontStyle: 'italic',
        fontSize: '24px',
    },
    typeText: {
        fontSize: '1.2rem',
        marginBottom: 20,
        color: theme.palette.common.brown,
        fontFamily: 'MessinaSans-Regular',
    },
    bottomImage: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 0,
        objectFit: 'content',
    },
}));

const HealthSimpleDataCard = ({ heading, numbers, subHeading, image, width, height, alt, isEditable, onEdit }) => {
    const classes = useStyles();

    return (
        <CardWrapper isEditable={isEditable} onEdit={onEdit}>
            <CardHeading>{heading}</CardHeading>
            <Typography className={classes.italicHeading}>{numbers}</Typography>
            <Typography className={classes.typeText}>{subHeading}</Typography>
            <img src={image} className={classes.bottomImage} width={width} height={height} alt={alt} />
        </CardWrapper>
    );
};

export default memo(HealthSimpleDataCard);
