import { useCallback, useContext, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import TextInputField from '../../Common/TextInputField';
import UnderlinedButton from '../../UnderlinedButton';
import consentFormsStyles from '../ConsentFormsStyles';
import Loader from '../../Loader';
import { getText, titleCase } from '@/utils/helpers';
import { Auth } from 'aws-amplify';
import { usePersistedPrismicStore } from '@/store';
import { useTranslation } from 'react-i18next';

const VerifyAccount = () => {
    const classes = consentFormsStyles();
    const { i18n } = useTranslation();

    const [resendLoading, setResendLoading] = useState(false);

    const {
        [i18n.language]: { mainData: content, dataSet: prismicHealthProfileDataSet },
    } = usePersistedPrismicStore((state) => state.anamnesisData);
    const {
        [i18n.language]: { benefits: registrationContent, dataSet: prismicRegistrationDataSet },
    } = usePersistedPrismicStore((state) => state.registerData);

    //** Context */
    const { formData, verificationCode, setVerificationCode, isVerificationError } = useContext(ConsentFormsContext);

    const handleChange = ({ target: { value } }) => {
        setVerificationCode(value);
    };

    const resendConfirmationCode = async () => {
        try {
            setResendLoading(true);
            await Auth.resendSignUp(formData?.email);
            setResendLoading(false);
        } catch (err) {
            setResendLoading(false);
            console.debug('Error resending code:', err);
        }
    };

    const verificationErrorMessage = useCallback(() => {
        if (!prismicRegistrationDataSet && !registrationContent) return '';
        // if (verificationCode.length !== 6) return getText(registrationContent?.invalid_code_message);
        if (isVerificationError) return getText(registrationContent?.invalid_code_message);
        return '';
    }, [verificationCode, isVerificationError, registrationContent]);

    if (!prismicHealthProfileDataSet || !prismicRegistrationDataSet) return <Loader />;

    return (
        <div>
            <Typography variant="h4" className={classes.subHeading}>
                {getText(content?.enter_your_verification_code) ||
                    `Bevor du die Gesuntheitsinformationen abschickst, gib deinen Verifizierungscode ein, um ein Konto zu
                erstellen und deine Daten zu schützen.`}
            </Typography>
            <Typography variant="body1" className={[classes.paragraph, classes.mt10]}>
                {getText(content?.verify_code) ||
                    'Wir haben einen Verifizierungscode an deine E-Mail Adresse gesendet.'}
            </Typography>
            <Grid container className={classes.my15}>
                <Grid item sm={8} xs={12}>
                    <TextInputField
                        label={titleCase(getText(content?.verification_code)) || 'Verifizierungscode'}
                        onChange={handleChange}
                        value={verificationCode.replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '')}
                        errorMessage={verificationErrorMessage()}
                    />
                </Grid>
            </Grid>
            <Typography
                variant="body1"
                className={[classes.dFlex, classes.itemsCenter, classes.gap4, classes.paragraph]}
            >
                {getText(content['verification_code']) || 'Du hast keinen Code bekommen?'}{' '}
                <UnderlinedButton
                    noMarginTop
                    disabled={resendLoading}
                    text={getText(content['send_again']) || 'Nochmal schicken'}
                    classNameProp={[classes.paragraph, classes.transformLowercase]}
                    onPress={resendConfirmationCode}
                />
            </Typography>
        </div>
    );
};

export default VerifyAccount;
