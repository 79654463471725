import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import { darkenColor } from '@/utils/colorHelpers';

export const AntSwitch = withStyles((theme) => ({
    root: {
        width: 34,
        height: 23,
        padding: 0,
        display: 'flex',
    },

    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&:hover + $track': {
            backgroundColor: '#898989',
            borderColor: '#898989',
        },
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.common.yellow,
                borderColor: theme.palette.common.yellow,
            },
            '&:hover + $track': {
                backgroundColor: darkenColor(theme.palette.common.yellow, 0.2),
                borderColor: darkenColor(theme.palette.common.yellow, 0.2),
            },
        },
    },
    thumb: {
        width: 14,
        height: 14,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.common.brown}`,
        borderRadius: 18 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.brown,
        height: 16,
        transition: '.5s',
    },
    checked: {},
}))(Switch);
