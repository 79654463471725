import { useCallback, useContext, useEffect } from 'react';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { Context as AuthContext } from '@/context/AuthContext';
import { getAdressAPI } from '@/service/AnamnesisSectionAPI/PersonalnformationAPI';

/**
 * Set User Address In ConsentForms Context
 */
const useUserAddress = () => {
    const { formData, setFormData } = useContext(ConsentFormsContext);
    const {
        state: { usersession },
    } = useContext(AuthContext);

    const { street: streetForm, zipCode: zipCodeForm, city: cityForm, country: countryForm } = formData;

    const setAddess = useCallback(
        (street, zipCode, city, country) => {
            setFormData((prev) => ({
                ...prev,
                ...(!streetForm && { street }),
                ...(!zipCodeForm && { zipCode }),
                ...(!cityForm && { city }),
                ...(!countryForm && { country: { code: country?.code, name: country?.name } }),
            }));
        },
        [cityForm, countryForm, streetForm, zipCodeForm]
    );

    const getAddress = useCallback(async (session) => {
        try {
            const res = await getAdressAPI(session);
            const { street, zip, city, country } = res.data;
            setAddess(street, zip, city, country);
        } catch (error) {
            console.debug(error);
        }
    }, []);

    useEffect(() => {
        if (usersession) getAddress(usersession);
    }, [usersession]);
};

export default useUserAddress;
