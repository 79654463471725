import { useContext } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import consentFormsStyles from '../ConsentFormsStyles';
import close from '@/assets/Icons/close_grey.svg';
import clsx from 'clsx';
import FilledButton from '../../FilledButton';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { useTheme } from '@material-ui/core/styles';

const dialogPaper = {
    style: {
        backgroundColor: 'white',
        width: '60%',
        borderRadius: 8,
        paddingBlock: 15,
    },
};

const dialogMobile = {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    paddingBlock: 15,
};

const TranslationModal = ({ open, onClose, modalContent = '' }) => {
    const classes = consentFormsStyles();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    /** Context */
    const { consentFormsContent: content } = useContext(ConsentFormsContext);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.dialogRoot,
            }}
            PaperProps={matchesXs ? dialogMobile : dialogPaper}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h4" className={classes.mainHeading}>
                    {content?.translationPopup?.englistTitle}
                </Typography>
                <Typography variant="body1" className={clsx(classes.paragraph, classes.fontSerif, classes.my10)}>
                    {content?.translationPopup?.trasnlatinoSubtitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {/* --- MODAL CONTENT --- */}
                {modalContent?.length ? (
                    <>
                        {modalContent.map((content) => {
                            return (
                                <Typography
                                    key={content.text}
                                    className={clsx(
                                        classes.paragraph,
                                        classes.whiteSpacePreLine,
                                        classes.mt10,
                                        classes.mb30
                                    )}
                                >
                                    {content.text}
                                </Typography>
                            );
                        })}
                    </>
                ) : (
                    <>
                        <Typography variant="h3" className={classes.secondaryHeading}>
                            {content?.translationPopup?.explanation}
                        </Typography>
                        <Typography variant="body1" className={clsx(classes.paragraph, classes.mt10, classes.mb30)}>
                            {content?.translationPopup?.firstPara}
                        </Typography>

                        <Typography variant="h3" className={classes.secondaryHeading}>
                            {content?.translationPopup?.physicianStatement}
                        </Typography>
                        <Typography variant="body1" className={clsx(classes.paragraph, classes.mt10, classes.mb30)}>
                            {content?.translationPopup?.secondPara}
                        </Typography>

                        <Typography variant="h3" className={classes.secondaryHeading}>
                            {content?.translationPopup?.patientConsent}
                        </Typography>
                        <Typography variant="body1" className={clsx(classes.paragraph, classes.mt10, classes.mb30)}>
                            {content?.translationPopup?.thirdPara}
                        </Typography>
                    </>
                )}

                {/* Button */}
                <div className={clsx(classes.signModalTextBoxContainer, classes.my15, matchesXs ? classes.px24 : null)}>
                    <FilledButton
                        text={content ? content?.buttons.close : 'Close'}
                        classNameProp={classes.btnMobile}
                        fullWidth={matchesXs}
                        onPress={onClose}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button disableRipple color="primary" onClick={onClose}>
                    <img src={close} className={classes.closeButton} alt="eterno-cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TranslationModal;
