import React, { useContext } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './YourBodyStyles';
import { Slider, TextField } from '@material-ui/core';

const PrettoSlider = withStyles((theme) => ({
    root: {
        color: '#ABABAB',
        height: 2,
    },
    active: {},
    valueLabel: {},
    track: {
        height: 8,
        borderRadius: 4,
        color: theme.palette.common.yellow,
    },
    rail: {
        height: 8,
        borderRadius: 4,
        opacity: 1,
    },
}))(Slider);

export default function YourBodyHeight({ mainData, isFromHealthData }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { valueHeight, setValueHeight } = useContext(ContextProviderTwo);

    // height
    const handleChangeHeight = (event) => {
        setValueHeight(event.target.value);
    };

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div
                    className={classes.rangeQuestion}
                    style={{
                        marginTop: matches ? 20 : !isFromHealthData || valueHeight !== 40 ? 40 : null,
                    }}
                >
                    <Typography className={classes.mainHeading}>{mainData.height_question_title[0].text}</Typography>
                    <div className={classes.questionContainer}>
                        <div className={classes.answersContainer}>
                            <TextField
                                id="yourBodyChangedWeightAmount"
                                label={mainData ? mainData.enter_height_here[0].text : 'Enter height here (cm)'}
                                variant="filled"
                                autoComplete="off"
                                color="primary"
                                value={String(valueHeight).replace(/[a-z||A-Z||&+,:;=?@#|'<>.^*()%!-/_{}||[\]]/, '')}
                                onChange={handleChangeHeight}
                                classes={{
                                    root: classes.textFields,
                                }}
                                style={{ width: '100%' }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
