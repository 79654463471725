import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useCustomAnamnesisStore } from '@/store';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { QuestionComponent } from './CustomAnamnesis';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'MessinaSans-Regular',
        flexDirection: 'column',
        gap: '5rem',
    },
    selectionContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    questionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
    },
    dropdown: {
        width: '40rem',
    },
    plus: {
        cursor: 'pointer',
        width: '2rem',
        height: '2rem',
        scale: 1,
        transition: 'all 0.3s ease',
        '&:hover': {
            scale: 1.1,
        },
        '&:active': {
            scale: 0.9,
        },
    },
}));

const QuestionPlayground = () => {
    const classes = useStyles();
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedQuestionType, setSelectedQuestionType] = useState('-');
    const [answer, setAnswer] = useState(null);
    const [filteredQuestions, setFilteredQuestions] = useState({});
    const questions = useCustomAnamnesisStore((state) => state.questions);

    const handleChange = (e) => {
        setAnswer(null);
        setSelectedQuestion(e.target.value);
    };

    useEffect(() => {
        if (!questions) return;
        if (selectedQuestionType === '-') {
            setFilteredQuestions(questions);
            return;
        }

        const clonedQuestions = cloneDeep(questions);

        Object.keys(questions).forEach((questionKey) => {
            if (!clonedQuestions[questionKey] || clonedQuestions[questionKey].question_type === selectedQuestionType) {
                return;
            }

            delete clonedQuestions[questionKey];
        });

        setFilteredQuestions(clonedQuestions);
    }, [selectedQuestionType, questions]);

    const Question = QuestionComponent[questions[selectedQuestion]?.question_type];

    return (
        <div className={classes.container}>
            <div className={classes.selectionContainer}>
                <FormControl className={classes.dropdown}>
                    <InputLabel id="dropdown-label">Question Type</InputLabel>
                    <Select
                        labelId="dropdown-label"
                        id="demo-simple-select"
                        value={selectedQuestionType}
                        onChange={(e) => setSelectedQuestionType(e.target.value)}
                    >
                        <MenuItem value={'-'}>{'All Types'}</MenuItem>
                        {Object.keys(QuestionComponent).map((questionType) => (
                            <MenuItem value={questionType} key={questionType}>
                                {questionType}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.dropdown}>
                    <InputLabel id="dropdown-label">Custom Question</InputLabel>
                    <Select
                        labelId="dropdown-label"
                        id="demo-simple-select"
                        value={selectedQuestion}
                        onChange={handleChange}
                    >
                        {Object.keys(filteredQuestions).map((questionKey) => (
                            <MenuItem value={questionKey} key={questionKey}>
                                {filteredQuestions[questionKey].title[0].text}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/*<Icon className={classes.plus} component={AddCircleOutlineIcon} />*/}
            </div>
            <div className={classes.questionContainer}>
                {selectedQuestion && (
                    <Question question={questions[selectedQuestion]} answer={answer} setAnswer={setAnswer} />
                )}
            </div>
        </div>
    );
};

export default QuestionPlayground;
