import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ConsentStyles } from '..';
import FilledButton from '../../FilledButton';
import OutlinedButton from '../../OutlinedButton';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
/** ICONS */
import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';
import checkedIcon from '@/assets/images/checkedIcon.svg';
import uncheckedIcon from '@/assets/images/uncheckedIcon.svg';
import { useTranslation } from 'react-i18next';
import { replaceProfessionalType } from '@/utils/helpers';

const Welcome = ({
    icon,
    title,
    paragraphs = [],
    primaryBtnText,
    continueText,
    continueBtnText,
    pages,
    width = null,
    onPrimaryBtnClick = () => {},
    onContinueBtnClick = () => {},
}) => {
    const classes = ConsentStyles();
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const { i18n } = useTranslation();

    return (
        <Box className={classes.welcomeContainer} {...(width ? { width } : {})}>
            {/* Image holder */}
            <div className={classes.welcomeImageContainer}>
                <img src={icon || WelcomeIcon} className={classes.welcomeImage} alt="Allforms welcome" />
            </div>
            {/* Header */}
            <div className={classes.welcomeHeader}>
                <Typography variant="h2" className={clsx(classes.mainHeading, matchesXS ? classes.mx30 : classes.mx50)}>
                    {title}
                </Typography>
            </div>
            {/* Body */}
            <div className={classes.welcomeBody}>
                {paragraphs.length > 0 &&
                    paragraphs.map((paragraph) => (
                        <Typography key={paragraph} variant="body1" className={clsx(classes.paragraph, classes.mx20)}>
                            {replaceProfessionalType({ text: paragraph, language: i18n.language })}
                        </Typography>
                    ))}
            </div>
            {pages ? (
                <div
                    className={clsx(
                        classes.dFlex,
                        classes.contentCenter,
                        classes.flexWrap,
                        classes.gap20,
                        matchesXS ? classes.directionCol : null
                    )}
                >
                    {pages.map((page) => (
                        <div
                            className={clsx(classes.dFlex, classes.gap10, ...(matchesXS ? [classes.itemsStart] : []))}
                            key={page.name}
                        >
                            <img
                                src={page.active ? checkedIcon : uncheckedIcon}
                                width={20}
                                alt="allforms selected forms checked list"
                            />
                            <Typography className={clsx(classes.paragraph, classes.bold)}>{page.name}</Typography>
                        </div>
                    ))}
                </div>
            ) : null}
            {/* Footer */}
            <div className={classes.welcomeFooter}>
                <FilledButton
                    classNameProp={classes.btn}
                    text={primaryBtnText || 'continue with your eterno account'}
                    onPress={onPrimaryBtnClick}
                />
                {continueText && (
                    <div className={classes.btnOutlineContainer}>
                        <Typography variant="body1" className={classes.paragraph}>
                            {continueText || 'You don’t have an Eterno account?'}
                        </Typography>
                        <OutlinedButton
                            text={continueBtnText || 'continue as guest'}
                            classNameProp={classes.btn}
                            onPress={onContinueBtnClick}
                        />
                    </div>
                )}
            </div>
        </Box>
    );
};

export default Welcome;
