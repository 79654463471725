import { useContext } from 'react';

/** Styles */
import consentFormsStyles from '../../ConsentFormsStyles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';

const SignatureDigitalMobile = () => {
    const classes = consentFormsStyles();

    /** Context */
    const {
        consentFormsContent: content,
        formData: { firstName, lastName },
    } = useContext(ConsentFormsContext);

    return (
        <div className={clsx(classes.textCenter, classes.mt15, classes.mb10)}>
            <Typography className={clsx(classes.paragraph, classes.textMuted, classes.textUnSelect)}>
                {content?.signaturePopup?.generatedSignature || 'Generated signature'}
            </Typography>
            <Typography className={clsx(classes.digitalSignature, classes.textUnSelect, classes.px20, classes.my15)}>
                {`${firstName || ''} ${lastName || ''}`}
            </Typography>
        </div>
    );
};

export default SignatureDigitalMobile;
