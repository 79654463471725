import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import my_appointment from '@/assets/images/Side-Nav-Icon.svg';
import Icon from '@/components/Icon/Icon'; //halfBoxIcon

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        paddingTop: 25,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',

        backgroundColor: theme.palette.common.white,
        // background: "blue",
        marginBottom: 20,
        borderRadius: 10,

        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 10,
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        textTransform: 'none',
        // lineHeight: "1.7",
        letterSpacing: 2,
    },
    //   Main Section Styling
    flexClass: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    upClass: {
        marginTop: 10,
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: "space-between",
    },
    shortTextt: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        textTransform: 'none',
        minWidth: 60,
        letterSpacing: 2,
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        textTransform: 'uppercase',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },
}));
const SecondSideBar = ({ doctors_data, myAppointmentsDataStatus, dataSet, mainData }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    console.debug('doctors_data:12', mainData, doctors_data);
    return (
        <>
            <div className={classes.contentContainer}>
                <div className={classes.flexClass}>
                    <Typography className={classes.mainHeading}>
                        {dataSet ? mainData?.office_hours_heading[0].text : 'Office hours'}
                    </Typography>
                    <Icon src={my_appointment} width={40} height={40} />
                </div>

                {myAppointmentsDataStatus
                    ? doctors_data?.office_hours.map((ofc) => (
                          <div className={classes.flex}>
                              <Typography className={classes.shortTextt}>{ofc.day[0].text}</Typography>
                              <Typography className={classes.shortText}>{ofc.hours[0].text}</Typography>
                          </div>
                      ))
                    : null}
                {/* <UnderlinedButton text={ dataSet ? mainData?.website_cta[0].text : "Website"} onPress={() => {}} /> */}
                <div
                    style={{
                        marginTop: 20,
                    }}
                    className=""
                >
                    <a
                        className={classes.contentTextLink}
                        href={dataSet && doctors_data?.website.link_type && doctors_data?.website.url}
                        target="_blank"
                    >
                        {dataSet ? mainData?.website_cta[0].text : 'Website'}

                        {/* <img style={{ marginLeft: 8, height: 16 }}  border="0" src={halfBoxIcon} alt="" /> */}
                        {/* <div className="" style={{  }} ></div> */}
                    </a>
                </div>
            </div>
        </>
    );
};

export default SecondSideBar;
//mainData?.website.url   border="0"
