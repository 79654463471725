import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractDetails, TranslationAlert } from '@/components/ConsentForms';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { languageMap } from '@/utils/helpers';
import useScrollToTop from '@/hooks/useScrollToTop';
import useFormSteps from '@/hooks/useFormSteps';

const defaultText = `This document can only be signed in original form in german. For your convenience, we provide you with a suggested english translation `;

const ContractDetailsPage = () => {
    const { i18n } = useTranslation();
    let lan = languageMap[i18n.language];

    /** Scroll to top on mount */
    useScrollToTop();

    /** Context */
    const { consentFormsContent: content, contractContent, consentAllForms } = useContext(ConsentFormsContext);

    const { currentStepIndex } = useFormSteps();

    const ContractContentForAllForms = consentAllForms?.formTemplates[currentStepIndex]?.content;
    console.debug(
        '🚀 ~ file: ContractDetailsPage.jsx:19 ~ consentAllForms:',
        '\n\n',
        ContractContentForAllForms?.text?.de,
        '\n\n',
        ContractContentForAllForms?.consent_text?.de,
        '\n\n',
        ContractContentForAllForms,
        consentAllForms,
        currentStepIndex
    );

    return (
        <>
            {lan === languageMap.en ? (
                <TranslationAlert
                    text={content?.contractDetails.englishMessage ?? defaultText}
                    underlineText={content?.contractDetails.herePopup ?? `here.`}
                    modalContent={[{ text: ContractContentForAllForms?.text?.en ?? contractContent?.text?.en ?? '' }]}
                />
            ) : null}
            <ContractDetails
                data={
                    ContractContentForAllForms?.text?.de ??
                    contractContent?.text?.de ??
                    content?.corterierContractDetails?.detailsText
                }
                bottomHeading={
                    ContractContentForAllForms?.consent_text?.de ??
                    contractContent?.consent_text?.de ??
                    content?.contractDetails.textPlaceholder
                }
            />
        </>
    );
};

export default ContractDetailsPage;
