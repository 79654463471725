import { useCallback, useContext, useState } from 'react';

/** Styles */
import consentFormsStyles from '../../ConsentFormsStyles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { AntSwitch } from '../../../Common/AntSwitchCommon';
import SignaturePad from './SignaturePad';
import SignatureDigitalMobile from './SignatureDigitalMobile';

const SignatureFieldMobile = ({ signCanvas }) => {
    const classes = consentFormsStyles();

    /** Context */
    const { consentFormsContent: content } = useContext(ConsentFormsContext);

    const [drawSignature, setDrawSignature] = useState(false);

    const handleDrawSign = useCallback(() => setDrawSignature(!drawSignature), [drawSignature]);

    return (
        <div>
            <div className={clsx(classes.mobileSwichContainer, classes.px24)}>
                <AntSwitch checked={drawSignature} onChange={handleDrawSign} />
                <Typography className={clsx(classes.paragraph, classes.textUnSelect)}>
                    {content?.signaturePopup?.useOwnSignature || 'Use my own signature'}
                </Typography>
            </div>
            <div className={clsx(classes.mobilePanel, classes.my10)}>
                {drawSignature ? <SignaturePad ref={signCanvas} /> : <SignatureDigitalMobile />}
            </div>
        </div>
    );
};

export default SignatureFieldMobile;
