import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    page: {
        display: 'flex',
        fontFamily: 'MessinaSans-Regular',
        flexDirection: 'column',
        maxWidth: theme.layout.contentWidth,
        padding: '5rem 50px 0 50px',
        width: '100%',
        minHeight: '70vh',
        gap: '1.5rem',

        [theme.breakpoints.down('xs')]: {
            padding: '5rem 20px 0 20px',
        },
    },
    title: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '2.5rem',
    },
    textContainer: {
        width: '70%',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '0.25rem',

        '& p': {
            fontSize: '1.25rem',
        },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    phoneNumber: {
        fontSize: '1.25rem',
        color: 'black',
    },
}));

const PrismicFallback = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.page}>
                <Typography className={classes.title}>Sorry,</Typography>
                <div className={classes.textContainer}>
                    {t('prismicFallback')
                        .split(' ')
                        .map((text, index) =>
                            text === '{phone_number_placeholder}' ? (
                                <a key={index} className={classes.phoneNumber} href="tel:040743250600">
                                    040 7432506 00
                                </a>
                            ) : (
                                <Typography key={index}>{text}</Typography>
                            )
                        )}
                </div>
            </div>
        </div>
    );
};

export default PrismicFallback;
