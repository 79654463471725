import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AntSwitch } from '@/components/Common/AntSwitchCommon';
import CardContainer from '@/components/Common/CardContainer';
import TextInputField from '@/components/Common/TextInputField';
import consentFormsStyles from '@/components/ConsentForms/ConsentFormsStyles';
import FilledButton from '@/components/FilledButton';
import Loader from '@/components/Loader';
import { Context as AuthContext } from '@/context/AuthContext';
import { ALL_FORM_PATHS, ConsentFormsContext } from '@/context/ConsentFormsContext';
import useAllFormsPrismic from '@/hooks/useAllFormsPrismic';
import useUserData from '@/hooks/useUserData';
import { getText, moveElementsToStart } from '@/utils/helpers';

const AllFormsCheckIn = () => {
    const classes = consentFormsStyles();
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();

    //** Context */
    const {
        setIsAllForms,
        allFormsPages,
        allSelectedForms,
        setAllSelectedForms,
        allFormsPatientName,
        setAllFormsPatientName,
        setAllFormsPages,
        setFormData,
    } = useContext(ConsentFormsContext);

    const {
        state: { user },
    } = useContext(AuthContext);

    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    useUserData(); // hook for user data
    const { loading, content, wielandContent } = useAllFormsPrismic();

    // Items Classes
    const itemsClasses = useMemo(
        () => clsx(classes.dFlex, classes.gap10, matchesXs ? classes.itemsStart : classes.itemsCenter, classes.my20),
        [classes, matchesXs]
    );

    const handleChange = (e) => setAllFormsPatientName(e.target.value);

    // Handle All Selected Forms Toggle
    const handleAllSelectedFormsToggle = (name, form) => {
        setAllSelectedForms((prev) => ({
            ...prev,
            [name]: { toggle: prev[name] ? !prev[name].toggle : true, form, key: name },
        }));
    };

    const setFormDataIfAvailable = useCallback(() => {
        if (allFormsPatientName) {
            const nameLength = allFormsPatientName.trim().split(' ').length;
            const firstName = allFormsPatientName.split(' ')[0];
            const middleName = nameLength === 3 ? allFormsPatientName.split(' ')[1] : '';
            const lastName =
                nameLength === 3
                    ? allFormsPatientName.split(' ')[2]
                    : nameLength === 2
                      ? allFormsPatientName.split(' ')[1]
                      : '';
            setFormData((prev) => ({ ...prev, firstName, middleName, lastName }));
        }
    }, [allFormsPatientName, setFormData]);

    const getSelectedFormPaths = useCallback(() => {
        const paths = Object.keys(allSelectedForms).flatMap((form) => {
            if (!allSelectedForms[form].toggle) return []; // Skip iteration
            return ALL_FORM_PATHS[form]?.map((route) => ({ id: form, path: route })) || [];
        });

        return paths;
    }, [allSelectedForms]);

    const getFilteredPaths = useCallback((paths) => {
        const filterCourse = paths.filter(({ path: { path } }) => path.includes('herakles-course'));
        return filterCourse.length > 0 ? moveElementsToStart(paths, filterCourse) : paths;
    }, []);

    const getFilteredRoutes = useCallback((paths) => {
        const excludedIndices = [3, 6, 9];
        const routes = paths.filter((_, index) => !excludedIndices.includes(index));
        return routes;
    }, []);

    const getAllForms = useCallback(
        (routes) => {
            const allForms = routes
                .slice(0, Object.keys(allSelectedForms).length * 2 + 1)
                .map((route) => {
                    if (route) {
                        return {
                            name: allSelectedForms[route.id].form,
                            route: route.path.path,
                            active: false,
                            welcome: false,
                        };
                    }
                    return null;
                })
                .filter(Boolean);

            return allForms;
        },
        [allSelectedForms]
    );

    const insertAccountPage = useCallback(
        (allForms) => {
            if (!user) {
                const lastItem = allForms.at(-1);
                const accountPage = {
                    name: lastItem.name,
                    route: `${lastItem.route.split('?')[0]}?step=create-account`,
                    active: lastItem.active,
                    welcome: lastItem.welcome,
                };
                allForms.splice(allForms.length - 1, 0, accountPage); // insert at second last index
            }
        },
        [user]
    );

    const welcomePage = useMemo(() => {
        return {
            name: 'Welcome',
            route: '/all-forms-welcome',
            active: true,
            welcome: true,
        };
    }, []);

    const thankyouPage = useMemo(() => {
        return {
            name: 'Thank You',
            route: '/all-forms-thankyou',
            active: false,
            welcome: false,
        };
    }, []);

    // Handle Continue button click
    const handleContinue = () => {
        if (Object.keys(allSelectedForms).length === 0) return;

        setIsAllForms(true);
        setFormDataIfAvailable();

        const paths = getSelectedFormPaths();
        const newPaths = getFilteredPaths(paths);
        const routes = getFilteredRoutes(newPaths);
        const allForms = getAllForms(routes);

        insertAccountPage(allForms); // insert account page before last form summary
        setAllFormsPages([welcomePage, ...allForms, thankyouPage]);

        /** Redirecting to next route */
        history.push(allFormsPages[0].route);
    };

    const isContinueDisabled = () => {
        if (Object.keys(allSelectedForms).length === 0 || !allFormsPatientName) return true;
        return !Object.keys(allSelectedForms).some((selectedForm) => allSelectedForms[selectedForm].toggle);
    };

    if (loading) return <Loader />;
    return (
        <div className={clsx(classes.ContainerBody, classes.center)}>
            <Grid container>
                <Typography variant="h2" className={[classes.mainHeading, classes.mb20]}>
                    {getText(wielandContent['welcome_page_title']) || t('AllFormsCheckInPrimaryHeading')}
                </Typography>
                <Grid item xs={12}>
                    <CardContainer>
                        <div className={classes.mt20}>
                            <TextInputField
                                name="patient_name"
                                value={allFormsPatientName}
                                onChange={handleChange}
                                label={getText(wielandContent['name_title']) || 'Vor- and Nachname Patient:in'}
                            />
                        </div>
                        <div>
                            <Typography variant="subtitle1" className={[classes.secondaryHeading, classes.mt15]}>
                                {getText(wielandContent['question_to_chose_forms']) ||
                                    'Wählen Sie die Formulare aus, die Sie für diesen Patienten benötigen:'}
                            </Typography>
                            <div className={classes.my15}>
                                {/* Items */}
                                {content?.map((form) => (
                                    <div className={itemsClasses} key={getText(form.key)}>
                                        <AntSwitch
                                            checked={allSelectedForms[getText(form?.key)]?.toggle}
                                            onChange={() =>
                                                handleAllSelectedFormsToggle(
                                                    getText(form?.key),
                                                    getText(form?.extended_treatment_contract_title)
                                                )
                                            }
                                        />
                                        <Typography variant="body1">
                                            {getText(form?.extended_treatment_contract_title) ||
                                                'Datenschutz Patienteninformation'}
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={clsx(classes.mb20, classes.dFlex, classes.contentEnd)}>
                            <FilledButton
                                disabled={isContinueDisabled()}
                                text={getText(wielandContent['cta_to_open_forms']) || t('Continue')}
                                onPress={handleContinue}
                            />
                        </div>
                    </CardContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default AllFormsCheckIn;
