import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        marginTop: 40,
        minHeight: 600,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 0,
        },
    },

    mainHeading: {
        ...theme.typography.HL1,
        marginBottom: 20,
    },

    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        textTransform: 'none',
    },
    goBack: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    goBackIcon: {
        // width: 9,
        height: 14,
        marginRight: 10,
    },
    progressBar: {
        color: theme.palette.common.yellow,
    },
    progressBarSideBar: {
        color: theme.palette.common.green,
    },
    loadingProgress: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    //
    mainContainerImgIner: {
        width: 25,
    },
    flexClass: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    sideBarContainer: {
        padding: 20,
        marginRight: 20,
        height: 'fit-content',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
        paddingTop: 20,
    },
    sideBarIcon: theme.icons.secondary,
    containerMainHeading: {
        display: 'flex',
    },
    sideBarGreenText: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    sideBarGreenTextMain: {
        color: theme.palette.common.footerGreen,
        paddingLeft: 17,
        paddingRight: 20,
    },
    mainPage: {
        backgroundColor: theme.palette.common.white,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 10,
    },
    progressText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    greenImg: {
        width: 18,
        height: 18,
    },
    hamIcon: {
        height: 24,
        width: 18,
    },

    formControl: {
        backgroundColor: 'white',
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        paddingTop: 13,
        paddingBottom: 13,
        marginRight: 20,
        '&:hover': {
            backgroundColor: 'white',
        },
    },

    selectedCard: {
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    sideBarMain: {
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
    },
    classRoot: {
        minWidth: 0,
        paddingLeft: 13,
        paddingRight: 13,
    },
    menuItem: {
        fontSize: 16,
        fontFamily: 'MessinaSans-Regular',
    },
    menuRoot: {
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    // circular progressBar
    root: {
        position: 'relative',
        width: 'fit-content',
    },
    bottom: {
        color: '#D5D5D5',
    },
    center: {
        marginTop: 10,
        alignItems: 'center',
    },
    top: {
        color: theme.palette.common.footerGreen,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        borderRadius: 0,
    },
}));
