import { makeStyles } from '@material-ui/core';
import { rgbToHex } from '@material-ui/core/styles';
import { getColorWithOpacity } from '@/utils/colorHelpers';

export const useStyles = makeStyles((theme) => {
    const [r, g, b] = rgbToHex(theme.palette.common.yellow);

    return {
        contentContainerBody: {
            maxWidth: theme.layout.contentWidth,
            margin: '0 auto',
        },
        mainHeading: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: 18,
            fontWeight: 'normal',
        },
        heading: {
            ...theme.typography.HL1,
        },
        cardContent: {
            backgroundColor: 'white',
            maxWidth: theme.layout.contentWidth,
            boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
            borderRadius: 8,
            display: 'block',
            marginTop: 23,
        },
        avatar: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            objectFit: 'cover',
            maxHeight: 400,
            backgroundColor: 'red',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        editAvatar: {
            width: 200,
            height: 200,
            borderRadius: 100,
            objectFit: 'cover',
            marginTop: 10,
        },
        accountData: {
            paddingTop: 32,
            paddingBottom: 32,
        },
        icon: {
            width: 50,
            height: 50,
            objectFit: 'contain',
        },
        subHeading: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.75rem',
            textTransform: 'none',

            color: theme.palette.common.brown,
            marginTop: 20,
        },
        userData: {
            fontFamily: 'MessinaSerif-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        downArrow: {
            height: '0.75em',
            marginRight: 15,
        },
        selectedOptions: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            color: theme.palette.common.darkGrey,
            marginTop: 10,
        },
        editLinks: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.darkGrey,
            marginTop: 25,
        },
        deleteLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
            marginLeft: 20,
        },
        deleteAccountLink: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '1rem',
            textTransform: 'none',
            textDecorationLine: 'underline',
            color: theme.palette.common.red,
            marginTop: 25,
        },
        editButton: {
            background: 'transparent',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        buttonLabel: {
            padding: 0,
            justifyContent: 'left',
        },
        cardbg: {
            width: '100%',
            height: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: 'rgba(255,200,119,0.2)',
            maxHeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        imagebg: {
            width: '100%',
            height: '100%',

            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            maxHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: 300,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },

            [theme.breakpoints.down('xs')]: {
                height: 250,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
            },
        },
        card: {
            width: '80%',
            height: '85%',
            maxWidth: 400,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            backgroundColor: theme.palette.common.cream,

            maxHeight: 400,
            [theme.breakpoints.down('sm')]: {
                width: '70%',
                height: '85%',
            },

            [theme.breakpoints.down('xs')]: {
                width: '70%',
                height: '85%',
            },
        },
        visa: {
            width: 53,
            height: 30,
            objectFit: 'contain',
            marginTop: 30,
            marginLeft: 30,
            marginBottom: 20,
        },
        textFields: {
            height: '3.75em',
            marginTop: 10,
        },

        inputLabel: {
            lineHeight: 1.1,
            '&.focused': {
                color: theme.palette.common.darkGrey,
            },
            '&.shrink': {
                fontSize: '1rem',
            },
        },
        submitButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.yellow,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.yellow,
            },
        },
        cancelButton: {
            height: '3.75em',
            backgroundColor: theme.palette.common.mediumBrown,
            marginTop: 0,
            '&:hover': {
                backgroundColor: theme.palette.common.mediumBrown,
            },
        },
        buttonText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        formControl: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        formControlMobileBtn: {
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingTop: 14,
            paddingBottom: 14,
            height: '4.3em',

            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,

            borderRightWidth: 0,

            marginBottom: 15,
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                marginBottom: 15,
            },
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        accountIcon: {
            width: 250,
            height: 250,
            objectFit: 'contain',
            backgroundColor: 'rgba(255,200,119,0.2)',
            padding: 10,
            borderRadius: 135,

            [theme.breakpoints.down('xs')]: {
                width: 150,
                height: 150,
            },
        },
        butnMargin: {},
        fieldFocused: {
            backgroundColor: 'white',
            border: `2px solid ${theme.palette.common.yellow} !important`,
        },
        filledInputRoot: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
            },
        },
        filledInputRootEmail: {
            overflow: 'hidden',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            height: '100%',
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        disabledEmail: {
            '& input.Mui-disabled': {
                backgroundColor: 'white',
                border: '1px solid white',
            },
        },
        filledInputRootMobile: {
            overflow: 'hidden',
            backgroundColor: 'white',
            border: '1px solid #ABABAB',
            paddingRight: 10,
            borderRadius: 5,
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            height: '100%',
            '&:hover': {
                backgroundColor: '#F9F8F4',
            },
        },
        btnClass: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },

        blackCircle: {
            backgroundColor: theme.palette.common.darkGrey,
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        greenCircle: {
            backgroundColor: '#5ba668',
            width: 7,
            height: 7,
            borderRadius: 5,
            marginRight: 5,
        },
        passwordText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        passwordValidText: {
            fontFamily: 'MessinaSans-SemiBold',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.brown,
        },

        errorText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.red,
        },
        font: {
            fontSize: '1.5rem',
            backgroundColor: 'red',
            height: '0',
            display: 'flex',
            justifyContent: 'space-between',
        },
        selectItemCard: {
            backgroundColor: theme.palette.common.lightGray,
            boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
            borderRadius: 8,
            paddingTop: 18,
            paddingBottom: 18,
            paddingLeft: 25,
            paddingRight: 25,
            display: 'inline-block',

            '&:hover': {
                backgroundColor: theme.palette.common.lightGray,
                boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
            },
            [theme.breakpoints.down('sm')]: {
                addingTop: 18,
                paddingBottom: 18,
                paddingLeft: 10,
                paddingRight: 10,
            },
            [theme.breakpoints.down('xs')]: {
                marginRight: 0,
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 15,
                paddingRight: 15,
            },
        },
        selectedCard: {
            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
            backgroundColor: 'white',
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        selectItemCardBorder: {
            boxShadow: `0 0 0 1px ${theme.palette.common.mediumBrown}`,
        },
        // slider
        answersContainer: {
            marginLeft: 0,
            width: '100%',

            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        disabilitySliderContainer: { marginTop: 60 },
        rangeLabelContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: -2,
        },
        rangeLabelText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '0.875rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            color: theme.palette.common.darkGrey,
        },
        valueLabel: {
            left: 0,
            color: 'transparent',
            backgroundColor: 'white',
            width: '7em',

            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            '& > span': {
                width: '8em',
                textAlign: 'center',
                color: 'transparent',
            },
            '& > span > span': {
                ...theme.typography.subheading,
            },
        },
        valueLabelTwo: {
            left: 'auto',
            color: 'transparent',
            backgroundColor: 'white',
            width: '7em',

            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            '& > span': {
                width: '8em',
                textAlign: 'center',
                color: 'transparent',
            },
            '& > span > span': {
                ...theme.typography.subheading,
            },
        },
        valueLabelThree: {
            left: '-5.5em',
            color: 'transparent',
            backgroundColor: 'white',
            width: '7em',

            boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            '& > span': {
                width: '8em',
                textAlign: 'center',
                color: 'transparent',
            },
            '& > span > span': {
                ...theme.typography.subheading,
            },
        },

        thumb: {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
            marginTop: -8,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
        },
        BtncontentText: {
            fontFamily: 'MessinaSans-Regular',
            fontSize: '1rem',
            textTransform: 'none',
            lineHeight: '1.5rem',
            textAlign: 'center',
        },
        divider: { height: '2px', background: '#F5F5F5', border: 'none' },
        main: {
            transition: 'background .5s',
        },
        highlight: {
            background: getColorWithOpacity(theme.palette.common.secondary, 0.15),
            padding: 10,
            marginBlock: 10,
        },
        show: {
            position: 'static',
            visibility: 'visible',
            opacity: 1,
            transition: ' visibility 1.5s linear 500ms, opacity 1.5s',
        },
        hide: {
            position: 'absolute',
            pointerEvents: 'none',
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 1.5s linear 500ms, opacity 1.5s',
        },

        hideWithoutPosition: {
            // animation: '$slide 500ms ease',
            visibility: 'hidden',
            opacity: '0',
            transitionProperty: 'visibility 500ms, opacity 500ms linear',
        },

        '@keyframes slide': {
            from: { opacity: '1' },
            to: { opacity: '0' },
        },
    };
});
