import { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Context as AuthContext } from '@/context/AuthContext';
import { signOut } from '@/utils/helpers';
import { ConsentAllFormsInitialRoutes, useConsentFormsContext } from '@/context/ConsentFormsContext';

const TIME_TO_REDIRECT = 5 * 1000; // 5 seconds

/**
 * Hook to autologout or redirect user after `TIME_IN_SECONDS` seconds or redirect page on reload to `redirectPath`.
 *
 * @param {string} redirectPath - the path to redirect to
 * @param {object} options - additional options
 * @param {boolean} options.shouldLogout - should force logout of user
 * @param {boolean} options.shouldAutoRedirect - whether to enable auto redirect after timeout
 * @return {{forceRedirect: function}}
 */
const useAutoLogoutAndRedirect = (redirectPath = '/', options = {}) => {
    const { shouldLogout = true, shouldAutoRedirect = false } = options;
    const {
        state: { user },
    } = useContext(AuthContext);
    const history = useHistory();
    const timer = useRef();
    const {
        consentAllForms: { initialRoute },
    } = useConsentFormsContext();

    const forceRedirect = () => {
        removeListeners();
        if (shouldLogout) {
            // Sign out user and then redirect
            signOut(user, history, redirectPath);
        } else {
            // Only replace the URL
            history.replace(redirectPath);
        }
    };

    const removeListeners = () => {
        window.removeEventListener('popstate', triggerEvent);
        window.removeEventListener('load', triggerEvent);
    };

    const triggerEvent = () => {
        // To prevent going back (in history) on forms, if the new page loaded is not the initial route, force a redirect
        if (initialRoute === ConsentAllFormsInitialRoutes.ALL_FORMS_CODE && window.location.pathname !== initialRoute) {
            forceRedirect();
        } else {
            removeListeners();
        }
    };

    useEffect(() => {
        window.addEventListener('popstate', triggerEvent);
        window.addEventListener('load', triggerEvent);

        if (user || shouldAutoRedirect) {
            timer.current = setTimeout(() => {
                forceRedirect();
            }, TIME_TO_REDIRECT);
        }

        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    return {
        forceRedirect,
    };
};

export default useAutoLogoutAndRedirect;
