import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { replaceProfessionalType } from '@/utils/helpers';

const useStyles = makeStyles((theme) => ({
    buttonIcon: {
        width: '7em',
        height: '5em',
        objectFit: 'contain',
        ...theme.icons.secondary,
    },
    fullSizeButtonIcon: {
        width: '100%',
        height: 120,
        objectFit: 'contain',
        ...theme.icons.secondary,
    },
    contentText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: 16,
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    heading: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        textTransform: 'none',

        color: theme.palette.common.darkGrey,
    },
    countContainer: {
        width: '1em',
        height: '1em',
        backgroundColor: theme.palette.common.red,
        borderRadius: '0.5em',
        position: 'absolute',
        top: '-0.5em',
        right: '-0.5em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconContainer: {
        // width: "7em",
        // height: "3em",
        // position: "relative",
    },
    fullIconContainer: {
        width: '100%',
        position: 'relative',
    },
    countText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '0.6rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: 'white',
    },
    buttonContainer: {
        width: '100%',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

export default function DetailedIcon({ item, size, disabled }) {
    console.debug('🚀 ~ file: DetailedIcon.js:73 ~ DetailedIcon ~ disabled', disabled);
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    return (
        <Button
            disableRipple
            className={classes.buttonContainer}
            component={Link}
            to={item && item['link'] !== undefined && item['link'] !== null && item?.link.path[0].text}
            disabled={!!disabled}
        >
            {/* && item["link"] === null  */}
            {item['link'] === undefined ? (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // position: "relative",
                        opacity: '0.5',
                    }}
                >
                    <div className={size === 'full' ? classes.fullIconContainer : classes.iconContainer}>
                        {item.notificationCount > 0 && (
                            <div className={classes.countContainer}>
                                <Typography className={classes.countText}>{item.notificationCount}</Typography>
                            </div>
                        )}
                        <img
                            src={item?.icon.url}
                            className={size === 'full' ? classes.fullSizeButtonIcon : classes.buttonIcon}
                            alt={item.title}
                        />
                    </div>

                    <Typography className={classes.contentText} style={{ marginTop: 0, textAlign: 'center' }}>
                        {replaceProfessionalType({
                            text: item?.text[0].text,
                            language: i18n.language,
                        })}
                    </Typography>
                </div>
            ) : (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <div className={size === 'full' ? classes.fullIconContainer : classes.iconContainer}>
                        {item.notificationCount > 0 && (
                            <div className={classes.countContainer}>
                                <Typography className={classes.countText}>{item.notificationCount}</Typography>
                            </div>
                        )}
                        <img
                            src={item?.icon.url}
                            className={size === 'full' ? classes.fullSizeButtonIcon : classes.buttonIcon}
                            alt={item.title}
                        />
                    </div>

                    <Typography className={classes.contentText} style={{ marginTop: 0, textAlign: 'center' }}>
                        {replaceProfessionalType({
                            text: item?.text[0].text,
                            language: i18n.language,
                        })}
                    </Typography>
                </div>
            )}
        </Button>
    );
}
