/** React */
import { useContext } from 'react';

/** MUI */
import { Typography } from '@material-ui/core';

/** Components */
import consentFormsStyles from '../ConsentFormsStyles';

/** Context */
import { ConsentFormsContext } from '@/context/ConsentFormsContext';

/** Others */
import clsx from 'clsx';

const SummaryIntro = ({ subtitle = '' }) => {
    const classes = consentFormsStyles();

    /** Context */
    const { consentFormsContent: content, professional, doctor } = useContext(ConsentFormsContext);
    return (
        <div>
            <Typography variant="h2" className={clsx(classes.mainHeading, classes.my10)}>
                {content?.summary.summaryTitle ?? 'Summary'}
            </Typography>
            <Typography variant="body1" className={clsx(classes.paragraph)}>
                {subtitle ||
                    `${content?.summary.summarySubtitle} ${professional?.name ?? doctor} ${
                        content?.titles?.toShare || ''
                    }`}
            </Typography>
        </div>
    );
};

export default SummaryIntro;
