import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useStyles as useAnamnesisStyles } from '../../../pages/HealthProfileAnamnesis/LifeStyleComponents/LifeStyleStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useCustomAnamnesisStore } from '@/store';
import { AntSwitch } from '../../Common/AntSwitchCommon';
import { cloneDeep } from 'lodash';
import { useCustomAnamnesisStyles } from '../customAnamnesisStyles';
import { QuestionComponent } from '../../../pages/CustomAnamnesis';
import { useScrollToQuestion } from '@/hooks/useScrollToQuestion';

export const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
        marginBottom: '1rem',
    },
    optionsContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20,
    },
    switchOptionsContainer: {
        flexDirection: 'column',
    },
    btnContentText: {
        textTransform: 'none',
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
    },
    optionSwitch: {
        margin: '0 5px 0 0',
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
    },
}));

const MultiselectQuestion = ({ question, answer, setAnswer }) => {
    const anamnesisClasses = useAnamnesisStyles();
    const classes = useStyles();
    const customAnamnesisStyles = useCustomAnamnesisStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const subQuestions = useCustomAnamnesisStore((state) => state.subQuestions);
    const highlightedQuestions = useCustomAnamnesisStore((state) => state.highlightedQuestions);
    const questionRef = useScrollToQuestion(question);

    const updateAnswer = (item) => {
        if (!answer?.[item.multi_select_option[0].text]) {
            setAnswer({
                ...answer,
                [item.multi_select_option[0].text]: {
                    answer: !answer?.[item.multi_select_option[0].text],
                    subQuestions: {},
                },
            });
        } else {
            const clonedAnswer = cloneDeep(answer);
            delete clonedAnswer[item.multi_select_option[0].text];
            setAnswer(clonedAnswer);
        }

        useCustomAnamnesisStore.setState({
            highlightedQuestions: {
                ...highlightedQuestions,
                [question.question_key[0].text]: false,
            },
        });
    };

    const updateSubQuestion = (newAnswer, text) => {
        setAnswer({
            ...answer,
            [text]: newAnswer,
        });
    };

    return (
        <div
            ref={questionRef}
            className={clsx(
                customAnamnesisStyles.defaultQuestion,
                highlightedQuestions[question.question_key[0].text] && customAnamnesisStyles.highlight
            )}
        >
            <Typography className={classes.title}>{question.title[0].text}</Typography>
            <div
                className={clsx(
                    classes.optionsContainer,
                    question.multi_select_subquestions.length > 0 && classes.switchOptionsContainer
                )}
            >
                {question.multi_select_options.map((item) =>
                    question.multi_select_subquestions.length === 0 ? (
                        <Button
                            key={item.multi_select_option[0].text}
                            onClick={() => updateAnswer(item)}
                            disableRipple
                            style={{
                                flex: matches && '1 0 45%',
                                paddingInline: matches && 2,
                                textAlign: 'center',
                            }}
                            classes={{
                                root: clsx(
                                    anamnesisClasses.selectItemCard,
                                    answer?.[item.multi_select_option[0].text]
                                        ? anamnesisClasses.selectedCard
                                        : anamnesisClasses.selectItemCardBorder
                                ),
                            }}
                        >
                            <Typography className={classes.btnContentText} style={{ wordWrap: 'break-word' }}>
                                {item.multi_select_option[0].text}
                            </Typography>
                        </Button>
                    ) : (
                        <div>
                            <div className={classes.switchContainer}>
                                <AntSwitch
                                    checked={answer?.[item.multi_select_option[0].text] ?? false}
                                    onChange={() => updateAnswer(item)}
                                    className={classes.optionSwitch}
                                />
                                <Typography className={classes.btnContentText}>
                                    {item.multi_select_option[0].text}
                                </Typography>
                            </div>
                            {answer?.[item.multi_select_option[0].text] && item.multi_select_option_has_subquestion && (
                                <div
                                    style={{
                                        width: '50%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 20,
                                        marginTop: '1rem',
                                    }}
                                >
                                    {question.multi_select_subquestions.map(({ multi_select_subquestion }) => {
                                        const Question =
                                            QuestionComponent[subQuestions[multi_select_subquestion.id].question_type];
                                        return (
                                            <Question
                                                question={subQuestions[multi_select_subquestion.id]}
                                                answer={answer?.[item.multi_select_option[0].text]}
                                                setAnswer={(newAnswer) =>
                                                    updateSubQuestion(newAnswer, item.multi_select_option[0].text)
                                                }
                                                isSubQuestion
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )
                )}
                {question.multi_select_has_none_option &&
                    (question.multi_select_subquestions.length === 0 ? (
                        <Button
                            onClick={() => setAnswer({})}
                            disableRipple
                            className={clsx(
                                anamnesisClasses.selectItemCard,
                                answer && Object.keys(answer).length === 0
                                    ? anamnesisClasses.selectedCard
                                    : anamnesisClasses.selectItemCardBorder
                            )}
                            style={{
                                flex: matches && '1 0 45%',
                                paddingInline: matches && 2,
                                textAlign: 'center',
                            }}
                        >
                            <Typography className={classes.btnContentText}>{'Keine'}</Typography>
                        </Button>
                    ) : (
                        <div className={classes.switchContainer}>
                            <AntSwitch
                                checked={answer && Object.keys(answer).length === 0}
                                onChange={() => setAnswer({})}
                                className={classes.optionSwitch}
                            />
                            <Typography className={classes.btnContentText}>{'Keine'}</Typography>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default MultiselectQuestion;
