import { useContext, useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import FilledButton from '@/components/FilledButton';
import { useHistory } from 'react-router-dom';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import OutlinedButton from '@/components/OutlinedButton';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { yourBodyAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';
import { useStyles } from './AllergiesComponents/AllergiesClasses';
import { AllergiesMainQuestion } from './AllergiesComponents/AllergiesMainQuesion';
import { useTrackingStore } from '@/store';
import { menuItems, trackStormlyAnamnesisSection } from '@/utils/helpers';
import useFormSteps from '@/hooks/useFormSteps';

export const Allergies = ({ mainData, user, usersession, isFromHealthData = false }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const [editOptions, setEditOptions] = useState(false);
    const [cancelEdit, setCancelEdit] = useState({});
    const [allergyNew, setAllergyNew] = useState({});
    const [allNew, setAllNew] = useState({});
    const [dataFile, setDataFile] = useState([]);
    const [counter, setCounter] = useState(0);

    const {
        anyAllergy,
        type,
        reaction,
        value,
        allergyArrCon,
        data2Con,
        setAnyAllergy,
        setType,
        setReaction,
        setValue,
        setData2,
        setAllergyArr,
        setBody4,
        body4,
        allDataApi,
        setHasAccount,
        newFromTrue,
        setNewFromTrue,
    } = useContext(ContextProviderTwo);

    const {
        state: { step, save_changes_option, allergiesTwo, is_open_state, is_edit_overview },
        BackBtn,
        UpdateSteps,
        setAllergiesTwo,
        onSaveButton,
        onCancelButton,
        setIsOpenState,
        setReviewOption,
    } = useContext(HealthProfileContextMain);

    const { isConsentAllForms, isLastStep } = useFormSteps();

    const addData = (seq = false) => {
        console.debug('Questions6 43');

        if (anyAllergy.length === 0) {
            console.debug('asdert');
            return;
        }

        if (value.length === 0) {
            console.debug('Questions6: error occurs');
            return;
        }

        dataFile.push({ value, type, reaction });
        setData2([...data2Con, ...dataFile]);
        let abc = [...data2Con, ...dataFile];
        let a = abc.filter((d) => d.type === type);
        allergyNew[type] = a;
        setAllergyArr({ ...allergyArrCon, ...allergyNew });
        let allergyArrFunction = { ...allergyArrCon, ...allergyNew };

        console.debug('Questions6: added', allergyNew, dataFile, allergyArrCon, abc);
        setValue('');
        setReaction('');
        setType('');
        setEditOptions(false);
        setDataFile([]);
        if (seq) {
            console.debug('Questions6: is working', seq, abc, allergyArrFunction);
            allergiesFunction(seq, abc, allergyArrFunction);
        }
    };

    const messageRef = useRef();

    useEffect(() => {
        messageRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }, [counter]);

    useEffect(() => {
        useTrackingStore.setState({
            anamnesisSectionStarted: Date.now(),
            currentAnamnesisSection: menuItems()[step - 1],
        });
    }, []);

    console.debug('Questions612:  ddd', data2Con);

    const allergiesFunction = (seq = false, abc = [], allergyArrFunction = {}) => {
        if (allergyDisabledFunction()) {
            setCounter(counter + 1);
            setNewFromTrue(true);
            return;
        }
        setNewFromTrue(false);
        console.debug('Questions6 43', seq);
        if (anyAllergy.length === 0) {
            console.debug('asdert');
            return;
        }

        if (anyAllergy === 'NO') {
            setAllergiesTwo({
                anyAllergy: anyAllergy,
                allergyType: type,
                allergyArr: allergyArrCon,
                data2: data2Con,
            });
            allergyAPI({ abc: null });
            if (!save_changes_option) {
                trackStormlyAnamnesisSection();
                UpdateSteps(step, history, user, isFromHealthData, isConsentAllForms, isLastStep);
            }
        }
        if (!seq) {
            if (
                anyAllergy.length === '' ||
                (anyAllergy === 'YES' && (type === '' || value === '') && data2Con.length === 0)
            ) {
                console.debug('Questions6: error occurs');
                return;
            }
        }

        if (seq) {
            setAllergiesTwo({
                data2: abc,
                anyAllergy: anyAllergy,
                allergyType: type,
                allergyArr: allergyArrFunction,
            });
            allergyAPI({ abc });
        } else {
            setAllergiesTwo({
                anyAllergy: anyAllergy,
                allergyType: type,
                allergyArr: allergyArrCon,
                data2: data2Con,
            });
            allergyAPI({ abc: null });
        }

        if (!save_changes_option) {
            trackStormlyAnamnesisSection();
            UpdateSteps(step, history, user, isFromHealthData, isConsentAllForms, isLastStep);
        }
        if (save_changes_option) {
            setHasAccount(false);
            setReviewOption(false);

            onSaveButton(history, is_edit_overview);
        }
    };

    const editOption = (index, all) => {
        console.debug('Questions6 43');

        setCancelEdit(all);
        if (data2Con.length > 0) {
            let newArr_ = data2Con.filter((a) => data2Con.indexOf(a) !== index);
            setData2(newArr_);
            console.debug('Questions6: delete data2Con', newArr_, data2Con);

            console.debug('Questions7: askdjasd', allNew);
            setAllergyArr({});
            let a = newArr_.filter((d) => d.type === all.type);
            allergyNew[all.type] = a;
            setAllergyArr({ ...allergyArrCon, ...allergyNew });
        }
        setType(all.type);
        setValue(all.value);
        setReaction(all.reaction);
        setEditOptions(true);
        console.debug('Questions6: editedData', cancelEdit);
    };

    const deleteEdit = () => {
        console.debug('Questions6 43');
        console.debug('Questions6: delete444', cancelEdit);
        data2Con.push({
            reaction: cancelEdit.reaction,
            value: cancelEdit.value,
            type: cancelEdit.type,
        });
        let a = data2Con.filter((d) => d.type === cancelEdit.type);
        allergyArrCon[cancelEdit.type] = a;
        console.debug('Questions6: delete', allergyArrCon, data2Con);
        setCancelEdit({});
        setValue('');
        setReaction('');
        setType('');
        setEditOptions(false);
    };

    console.debug('Questions7: NTEW', data2Con, '\n 3rd', allergyArrCon, '\n 4th allergy', allergiesTwo);

    const deleteOption = (index, all) => {
        if (data2Con.length > 0) {
            let newArr_ = data2Con.filter((a) => data2Con.indexOf(a) !== index);
            setData2(newArr_);
            console.debug('Questions6: delete data2Con', newArr_, data2Con);

            console.debug('Questions7: askdjasd', allNew);
            setAllergyArr({});
            let a = newArr_.filter((d) => d.type === all.type);
            allergyNew[all.type] = a;
            setAllergyArr({ ...allergyArrCon, ...allergyNew });
        }
    };
    const CancelFunction = () => {
        if (isFromHealthData) {
            history.push('/health-profile-overview');
            return;
        }
        setAnyAllergy(allergiesTwo.anyAllergy);
        setType(allergiesTwo.type);
        setData2(allergiesTwo.data2);
        setAllergyArr(allergiesTwo.allergyArr);

        console.debug('Questions7:', allergiesTwo);
        onCancelButton(history);
    };
    const allergiesIndex = allDataApi?.category_data?.findIndex((d) => d.category === 'allergies');
    useEffect(() => {
        if (user && allDataApi !== null && allDataApi !== undefined && body4 === null) {
            if (
                allDataApi?.category_data[allergiesIndex] &&
                allDataApi?.category_data[allergiesIndex]['category'] === 'allergies'
            ) {
                let category2 = allDataApi?.category_data[allergiesIndex].data;
                setAnyAllergy(category2.active ? 'YES' : 'NO');
                setData2(category2?.allergies);
                let abc = category2?.allergies;
                abc.forEach((e) => {
                    let a = abc.filter((d) => d.type === e.type);
                    allergyNew[e.type] = a;
                    setAllergyArr(allergyNew);
                });
            }
        }
    }, [allDataApi]);

    const allergyAPI = ({ abc }) => {
        let user_id = user ? usersession?.idToken.payload.sub : '';
        let allergyApiArray = abc !== null && abc !== undefined ? abc : data2Con;
        const body = {
            user_id: user_id,
            category: 'allergies',
            data: {
                active: anyAllergy === 'YES',
                allergies: allergyApiArray,
            },
        };
        console.debug('body1', body.data);
        setBody4(body);
        user && yourBodyAPI(usersession, body);
    };

    const allergyDisabledFunction = () => {
        if (anyAllergy.length === 0) {
            return true;
        } else return anyAllergy === 'YES' && (type === '' || value === '') && data2Con.length === 0;
    };

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div
                    className={
                        allergyDisabledFunction() && (isFromHealthData || newFromTrue)
                            ? classes.highlight
                            : classes.main
                    }
                    ref={allergyDisabledFunction ? messageRef : null}
                >
                    <AllergiesMainQuestion
                        mainData={mainData}
                        addData={addData}
                        editOption={editOption}
                        deleteEdit={deleteEdit}
                        deleteOption={deleteOption}
                        editOptions={editOptions}
                    />
                </div>

                <div
                    className=""
                    style={{
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: matches && 'column-reverse',
                    }}
                >
                    {!save_changes_option ? (
                        <OutlinedButton
                            text={mainData?.back_cta[0].text}
                            onPress={() => {
                                BackBtn(step, history, user, isFromHealthData);
                                setNewFromTrue(false);
                            }}
                            fullWidth={matches}
                        />
                    ) : (
                        <OutlinedButton
                            text={!isFromHealthData ? mainData?.cancel[0].text : mainData?.back_cta[0].text}
                            onPress={() => {
                                if (is_open_state) {
                                    setNewFromTrue(false);
                                    setIsOpenState(false);
                                }
                                CancelFunction();
                            }}
                            fullWidth={matches}
                        />
                    )}
                    {!save_changes_option ? (
                        <FilledButton
                            text={mainData?.continue_cta[0].text}
                            onPress={() => {
                                if (value.length > 0) {
                                    setNewFromTrue(false);
                                    let seq = true;
                                    addData(seq);
                                } else {
                                    allergiesFunction();
                                }
                            }}
                            fullWidth={matches}
                            marginBottom={matches && 20}
                        />
                    ) : (
                        <FilledButton
                            text={mainData?.save_cta[0].text}
                            onPress={() => {
                                if (is_open_state) {
                                    setNewFromTrue(false);
                                    setIsOpenState(false);
                                }
                                if (value.length > 0) {
                                    let seq = true;
                                    addData(seq);
                                } else {
                                    allergiesFunction();
                                }
                            }}
                            fullWidth={matches}
                            marginBottom={matches && 20}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
