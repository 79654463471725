import { useCallback, useContext, useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Context as AuthContext } from '@/context/AuthContext';
import { DateTextField } from '../../Common/Date/DateTextField';
import TextInputField from '../../Common/TextInputField';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { DropDown } from '../../Common/DropDown/DropDown';
import consentFormsStyles from '../ConsentFormsStyles';
import clsx from 'clsx';

const AllFormsPersonalInfo = ({
    heading,
    /** Form States */
    formData,
    setFormData,
    handleChange,
    /** Form Labels */
    labels,
    //** Toggle States */
    withPhoneNo = false,
}) => {
    /** Styles */
    const classes = consentFormsStyles();

    const {
        state: { user },
    } = useContext(AuthContext);
    const {
        consentFormsContent: mainContent,
        errors,
        setErrors,
        validateForm,
        debounceFn,
        setEmailError,
        callback,
    } = useContext(ConsentFormsContext);

    /** Destructured labels */
    const { firstNameLabel, lastNameLabel, dobLabel, emailLabel, phoneLabel } = labels;

    /** Destructured context form states */
    const { firstName, lastName, dob, email, phoneNo } = formData;

    /** State to stop continous API calls */
    const [isFetching, setIsFetching] = useState(false);

    /** Set DOB in context */
    const setDob = useCallback(
        (dob) => {
            setFormData((prev) => ({
                ...prev,
                dob,
            }));
        },
        [setFormData]
    );

    const setDobError = (err) => {
        setErrors((error) => ({
            ...error,
            dobError: err ? 'dobError' : '',
        }));
    };

    /** Set Phone No in context */
    const setPhone = useCallback(
        (phoneNo) => {
            setFormData((prev) => ({
                ...prev,
                phoneNo: {
                    number: phoneNo,
                },
            }));
        },
        [setFormData]
    );

    /** Set Phone No Error */
    const setPhoneError = useCallback(
        (err) => {
            setErrors((error) => ({
                ...error,
                phoneNoError: err ? 'phoneNoError' : '',
            }));
        },
        [setErrors]
    );

    const phoneError = useMemo(() => {
        return errors.phoneNoError ? mainContent?.heraklesContent?.requiredError : '';
    }, [errors.phoneNoError, mainContent?.heraklesContent?.requiredError]);

    return (
        <Grid container spacing={1}>
            {heading ? <Typography className={clsx(classes.secondaryHeading)}>{heading}</Typography> : null}
            <Grid container spacing={1} direction="row">
                <Grid item md={6} xs={12}>
                    <TextInputField
                        label={firstNameLabel || 'First Name'}
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        validateForm={validateForm}
                        errorName={'firstName'}
                        errorMessage={mainContent?.heraklesContent?.[errors?.firstNameError]}
                        formData={formData}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextInputField
                        label={lastNameLabel || 'Last Name'}
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        validateForm={validateForm}
                        errorName={'lastName'}
                        errorMessage={mainContent?.heraklesContent?.[errors?.lastNameError]}
                        formData={formData}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextInputField
                        label={emailLabel || 'Email'}
                        name="email"
                        value={email}
                        onChange={(e) => {
                            handleChange(e);
                            debounceFn(
                                callback(e.target.value, setEmailError, 'emailAlreadyExistError', {
                                    isFetching,
                                    setIsFetching,
                                })
                            );
                        }}
                        disabled={!!user} /** Disabled when user logged in */
                        validateForm={validateForm}
                        errorName={'email'}
                        errorMessage={mainContent?.heraklesContent?.[errors?.emailError]}
                        formData={formData}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <DateTextField
                        type={'dateOfBirth'}
                        separator={'.'}
                        mainHeading={dobLabel || 'DOB'}
                        setDOB={setDob}
                        dob={dob}
                        setError={setDobError}
                        error={errors.dobError}
                        errorMessage={mainContent?.heraklesContent?.[errors.dobError]}
                        placeHolderMonth={'MM'}
                        placeHolderYear={'YYYY'}
                        placeHolderDay={'DD'}
                        mt={10}
                    />
                </Grid>
                {withPhoneNo && (
                    <Grid item md={6} xs={12}>
                        <DropDown
                            type="telephone"
                            data={phoneNo?.number}
                            setData={setPhone}
                            mainDataHeading={phoneLabel || 'Mobile Number (optional)'}
                            isScroll={true}
                            error={!!errors.phoneNoError}
                            setError={setPhoneError}
                            errorText={phoneError}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default AllFormsPersonalInfo;
