import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            typeYourCountry: 'Type Your Country',
            Title: 'Eterno Health - Modern and preventive treatments',
            Description:
                'We strengthen your health! Many specialist areas with qualified doctors and therapists and numerous health offers ✓ | Eterno Health',
            Menu: 'Menu',
            Close: 'Close',
            Login: 'Login',
            Register: 'Register',
            RegisterDuringBooking: 'To create a new account, first complete the booking process',
            Verify: 'Verify',
            RequiredField: 'This is a required field.',
            InvalidEmail: 'Please enter a valid email address.',
            InvalidDate: 'Please enter a valid date in DD/MM/YYYY format.',
            PERSONALDATA: 'PERSONAL DATA',
            EternoAccount: 'Eterno Account',
            FirstName: 'First Name',
            LastName: 'Last Name',
            PhoneNumber: 'Phone Number',
            PROFILEIMAGE: 'PROFILE IMAGE',
            UploadImage: 'Upload a new image',
            DeleteImage: 'Delete image',
            SaveChanges: 'Save Changes',
            BILLINGPAYMENT: 'BILLING & PAYMENT',
            StreetNumber: 'Street & Number',
            ZIPCode: 'ZIP Code',
            City: 'City',
            Cancel: 'Cancel',
            Email: 'Email',
            Country: 'Country',
            CardName: 'Card holder name',
            CardNumber: 'Credit card number',
            CVV: 'CVV',
            ActivateNotification: 'Activate notifications for my Eterno appointments',
            UseAnalytics: 'Use of Analytics',
            DeleteAccount: 'Delete Account',
            Notifications: 'Notifications',
            WelcomeEterno: 'Welcome to Eterno',
            TryHomeDiagnostics: 'Try our Home Diagnostics and get to know your body with our tests for home.',
            FindOutHow: 'Find out how',
            YouHave: 'You have',
            AppointmentsThisWeek: 'Appointments this week',
            Details: 'Details',
            LookingForExpert: 'Looking for an expert to talk to?',
            FindAppointment: 'Find an Appointment',
            MyPractitioners: 'My Practitioners',
            Favorites: 'Favorites',
            MyClasses: 'My Classes',
            FindClass: 'Find a Class',
            BookNow: 'Book now',
            AllClasses: 'All my Classes',
            FindAnotherClass: 'Find Another Class',
            FindExpert: 'Find an Expert',
            FindAnotherDoctor: 'Find another doctor',
            FindDoctor: 'Find a doctor',
            BookAppointment: 'Book an Appointment',
            FindTest: 'Find a Home Test',
            FAQ: 'FAQ',
            YourNextAppointment: 'Future Appointments',
            YourLastTest: 'Past Appointments',
            YouHaveNoTest: 'You have not taken any test.',
            GetToKnowBetter: 'Get to know you better with our home tests.',
            FindATest: 'Find a test',
            SeeStatus: 'See status',
            SeeMyResults: 'See my results',
            SeeHealthProfile: 'See my Health Profile',
            SeeAllResults: 'See all results',
            SeeAllAppointments: 'See all appointments',
            RecommendedClasses: 'Recommended classes for you',
            AdvancedMeditation: 'Advanced Meditation',
            BookSpot: 'Book a spot',
            GeneralPractitioner: 'General Practitioner',
            Psychotherapist: 'Psychotherapist',
            Psychiatrist: 'Psychiatrist',
            Physiotherapists: 'Physiotherapists',
            YogaTeacher: 'Yoga Teacher',
            MeditationTrainer: 'Meditation Trainer',
            Coach: 'Coach',
            Naturopath: 'Naturopath',
            MidWives: 'Mid Wives',
            DontFindCategory: "You don't find any category that suit you? Chat with us",
            GetYouRightProfessional: "let's get you the right professional.",
            SelectingCategory: 'Start by selecting a category.',
            MindDescription:
                'Do you feel down and need someone to talk to? Here you will find psychologists, psychiatrists and coaches to talk to.',
            BodyDescription:
                'Do you have pains or are you looking for a recovery therapy? We have physiotherapists and doctors who can help you.',
            SoulDescription:
                'Are you looking for yoga or meditation courses? This is your category. We have more than 10 types of yoga to elevate your soul.',
            ComingSoon: 'Coming Soon',
            SelectCategoryHeading: 'Lorem ipsum dolor sit amet, amet ipsum',
            SelectCategorySubHeading: 'Lorum ipsum',
            SelectCategory: 'Select Category',
            LorumIpsum: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. ',
            SeveralTypesOfMeditation: 'We offer several types of meditations',
            SelectOneOrMore: 'You can select more than one, or continue with all of them',
            AllOfThem: 'All of them',
            Mindfulness: 'Mindfulness',
            Spiritual: 'Spiritual',
            Focused: 'Focused',
            Movement: 'Movement',
            Transcendental: 'Transcendental',
            Progressive: 'Progressive relaxation',
            LovingKindness: 'Loving-kindness',
            Visualization: 'Visualization',
            YouHaveNoAppointment: "You don't have any appointment",
            FindAPractitioner: 'Find a practitioner',
            Lorumipsumbig:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.',
            HomeDiagnostics: 'Home Diagnostics',
            HowDoesItWork: 'How does it work?',
            HomeStep1: 'Order Plan Online in a few cliks lorem',
            HomeStep2: 'Receive and conduct the test at home',
            HomeStep3: 'View your results online lorem',
            HomeStep4: 'Medical consultation lorem ipsum*',
            DisclaimerTextHomeDiagnostics: '*The medical consultation is only available in some of our tests.',
            AlleAnzeigen: 'Alle anzeigen',
            EntdeckenBegreifen: 'Entdecken & Begreifen',
            GesundheitFitness: 'Gesundheit & Fitness',
            FruchtbarkeitGeburt: 'Fruchtbarkeit & Geburt',
            DieserTest:
                'Dieser Test untersucht Blutwerte, die essentiell für Deine Gesundheit, Fitness und Leistungsfähigkeit sind (11 Biomarker)',
            WithExpertConsultation: 'with Eterno expert consultation',
            BuyTest: 'Buy Test',
            MoreDetails: 'More details',
            include: 'inkl.',
            taxDetails: 'USt., zzgl. Versand',
            BuyHomeTest: 'Buy Home Test and Consultation',
            Congratulations: 'Congratulations, your Sleep Better test is on its way!',
            SentYouEmail: 'We have send you a confirmation email with all the details.',
            WhatHappensNext: 'What happens next?',
            CompleteMyHealthProfile: 'Complete my Health Profile',
            WhatOurCustomersSay: 'What our customers say',
            SimilarProducts: 'Similar products',
            WhatsIncludedWithMedicalConsultation: 'What’s included in the Medical Consultation?',
            DontWantMedicalConsultation: 'You don’t want the medical consultation? ',
            GetYourHomeTest: 'Get your Home Test for 39,90 €',
            DoHavePrescription: 'Do you have a prescription?',
            Yes: 'Yes',
            No: 'No',
            UploadYourPrescription: 'Upload your prescription',
            ChooseServiceManually: 'Choose service manually',
            ManuelleTherapie: 'Manuelle Therapie (MT)',
            MTmitWarmapackung: 'MT mit Warmpackung',
            MTmitElektrotherapie: 'MT mit Elektrotherapie',
            mitUltraschall: 'mit Ultraschall- Wärmetherapie',
            Krankengymnastik: 'Krankengymnastik (KG)',
            KGmitWarmapackung: 'KG mit Warmpackung',
            KGmitElektrotherapie: 'KG mit Elektrotherapie',
            ManuelleLymphdrainage30: 'Manuelle Lymphdrainage (MLD) 30 Min',
            ManuelleLymphdrainage45: 'Manuelle Lymphdrainage (MLD) 45 Min',
            ManuelleLymphdrainage60: 'Manuelle Lymphdrainage (MLD) 60 Min',
            Massagen: 'Massagen (KMT)',
            KombiBehandlungen: 'Kombi Behandlungen',
            MedicalTaping: 'Medical Taping & Kinesiotape',
            ChooseTheTherape: 'Choose the therapy that has been prescribed to you',
            HealthInsurance: 'HEALTH INSURANCE',
            CREDITCARD: 'CREDIT CARD',
            CardNum: 'Card number',
            StartTyping: 'Start typing',
            KnowYourHealthInsurance: 'In order to continue, we need to know your health insurance',
            ChangeHealthInsurance: 'Change health insurance',

            MorePeople: 'Yes, with more people',
            PersonalClass: 'No, I prefer a personal class',
            JustOne: 'Just one',
            MoreThanOne: 'More than one',
            NumberOfAppointments: 'Number of appointments',
            appointments: 'appointments',
            HowManyAppointmentToBook: 'How many appointment would you like to book?',
            ChangeAmount: 'Change amount',
            AppointmentDetails: 'Appointment Details',
            SelectTreatment: 'Select Treatment',
            LocationTime: 'Location & Time',
            DayOfTheWeek: 'Day of the week',
            AsSoonAsPossible: 'As soon as possible',
            TimeOfDay: 'Time of the day',
            AtAnyTime: 'At any time',
            EarlyMorning: 'Early mornings 07:00 to 09:00',
            Mornings: 'Mornings 09:00 to 12:00',
            Afternoons: 'Afternoons 12:00 to 18:00',
            LateAfternoons: 'Late afternoons 18:00 to 21:00',
            ChangeLocation: 'Change location',
            OurRecommendationForYou: 'Our recommendation for you',
            SeeProfile: 'See profile',
            YourAppointment: 'YOUR APPOINTMENT',
            ConfirmAppointment: 'Confirm Appointment',
            ShowMoreResults: 'Show more results',
            MedicalConsultation: 'Medical Consultation',
            WeNeedSomeInformation: 'We need some information in order to confrim your appointment',
            WeNeedSomeInformationText: 'We need some further information in order to confirm the appointment',
            AddCardAndContinuePayment: 'Add card and confirm payment',
            AppointmentConfirmed: 'Your appointment is confirmed',
            DoYouWantToBeNotified: 'Do you want to be notified for your appointments?',
            YourSelection: 'Your selection',
            ShowDetails: 'Show details',
            HideDetails: 'Hide details',
            ToolTipText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            NoPastAppointment:
                'You don’t have any past appointments with this profile. But that’s OK, we can help you find the right expert for you.',
            BookClassAgain: 'Book this class again',
            DownloadInvoice: 'Download Invoice',
            AvailableAppointments: 'Available Appointments',
            ViewAllAvailableAppointments: 'View All Available Appointments',
            AvailableAppointmentPoint1: 'Lorem ipsum dolor sit amet',
            AvailableAppointmentPoint2: 'Incididunt ut labore et dolore magna',
            AvailableAppointmentPoint3: 'Exercitation ullamco laboris nisi',
            PasswordsNotMatch: 'Both passwords should be same.',
            EmailAlreadyExists: 'This email address is already in use.',
            WelcomeToHealthProfile: 'Welcome to your Health Profile',
            HealthProfileSub: 'We just need to ask you a few questions so we can help you achieve your health goals.',
            BasicDetails: 'Your individual body characteristics',
            DietDetials: 'Your Diet',
            LifestyleDetails: 'Your Lifestyle',
            GoalsDetails: 'Your Goals',
            YourHealthProfile: 'Your Health Profile',
            PreExistingConditions: 'Pre-existing conditions',
            InsuranceCompany: 'Insurance Company',
            InsuranceName: 'Insurance Name',
            InsuranceNumber: 'Insurance Number',
            ValidUntil: 'Valid Until',
            InsuranceStatus: 'Insurance Status',
            Security: 'SECURITY',
            ChangePswd: 'Change Password',
            CurrentPswd: 'Current Password',
            NewPswd: 'New Password',
            CPswd: 'Confirm Password',
            NameOptional: 'Name (Optional)',
            SameLocation: 'Same as personal adress ',
            MiddleName: 'Middle Name',
            ShouldBeYourAppointment: 'Where should be your appointment?',
            ProfessionLokingFor: 'What profession are you looking for?',
            ShouldHealthInsuranceCoverCost: 'Should your health insurance cover the costs?',
            GroupAppointment: 'Are you looking for a group appointment?',
            HowManyAppointment: 'How many appointments do you need?',
            WhatKindOfHealthInsurance: 'What kind of health insurance do you have?',
            PublicInsurance: 'Public Insurance',
            PrivateInsurance: 'Private Insurance',
            SelfPayer: 'Self-payer',
            public: 'Public',
            private: 'Private',
            self_payer: 'Self Payer',
            DoYouWantFirstContactAppointmentFollowUp: 'Do you want a first contact appointment or a follow up?',
            Erstkontakt: 'First Contact',
            Folgetermin: 'Follow Up Appointment',
            WhatKindOfThreatmentLookingFor: 'What kind of treatment are you looking for?',
            DoYouHaveAnySymptoms: 'Do you have any symptoms?',
            SelectPractitioner: 'Select a practitioner',
            PrescriptionUploaded: 'Prescription uploaded',
            BookYourAppointment: 'Book your appointment',
            CreditCard: 'Credit Card',
            SEPA: 'SEPA-Lastschrift',
            ConfirmPayment: 'Confirm Payment',
            WantAnOnline: 'I want an online appointment',
            YesForMe: 'Yes, For me',
            ForMyChild: 'For my child',
            TransferSlip: 'Do you have a transfer slip?',
            MoreServices: 'Select one or more services.',
            UploadTransferSlip: 'Upload Transfer Slip',
            MobileNumber: 'Mobile Number',
            JumpToFirstSlot: 'Jump to first available slot',
            NextSlotCta: 'Next available slot',
            NoTimeslotsAvailable:
                'Currently, there are no time slots available with this professional, please check again later.',
            AllFormsCheckInPrimaryHeading: 'Heracles Therapy Center check-in',
            AllFormsPrimaryHeading: 'Enter code to access the forms',
            AllFormsSecondaryHeading: 'Enter the code generated during the patient check in',
            AllFormsCodeError: 'Wrong code, please try again',
            Continue: 'continue',
            OpenLink: 'Open Link',
            Locations: 'Locations',
            ExternalLocationMessage:
                'Please note that your appointment will take place at a location outside of the Eterno Hub.',
            prismicFallback:
                'our web app is currently not available. We are already working on a solution. If you would like to book an appointment now, call our contact center on the phone number {phone_number_placeholder}.',
            SeeMore: 'See More',
            ErrorFallbackHeading: 'Sorry, something went wrong. Please reload the page and try again.',
            ErrorFallbackParagraph:
                'In case you still experience any issues, please do not hesitate to contact us at support@eterno.health.',
            ErrorFallbackCta: 'Reload Page',
            RegistrationNoteTitle: 'Note on registration',
            RegistrationNoteText: 'To create a new account, first complete the booking process',
            UnknownForm: 'Unknown Form',
            FormsFlowCta: 'Fill out digital documents',
        },
    },
    de: {
        translation: {
            typeYourCountry: 'Geben Sie Ihr Land ein',
            Title: 'Eterno Health - Moderne und präventive Behandlungen',
            Description:
                'Wir stärken deine Gesundheit! Viele Fachbereiche mit qualifizierten Ärzt:innen und Therapeut:innen und zahlreichen Gesundheitsangeboten ✓ | Eterno Health',
            Menu: 'Menü',
            Close: 'Schließen',
            Login: 'Anmeldung',
            InvalidDate: 'Please enter a valid date in DD/MM/YYYY format.',
            Register: 'Registrieren',
            RegisterDuringBooking: 'Um ein neues Konto anzulegen, schließen Sie zunächst den Buchungsprozess ab',
            Verify: 'Verify',
            KONTAKT: 'KONTAKT',
            Impressum: 'Impressum',
            Angaben: 'Angaben Gemäß § 5 TMG',
            EternoAccount: 'Eterno-Konto',
            'Vertreten durch': 'Vertreten durch',
            Verantworltlich: 'Verantworltlich für den Inhalt nach § 55 Abs. 2 rstv',
            Telefon: 'Telefon',
            HANDELSREGISTEREINTRAG: 'HANDELSREGISTEREINTRAG',
            Registergericht: 'Registergericht',
            Registernummer: 'Registernummer',
            'Umsatzsteuer-ID': 'Umsatzsteuer-ID',
            UmsatzsteuerDetail: 'Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 123456789',
            Haftungsausschluß: 'Haftungsausschluß (Disclaimer)',
            HaftungInhalte: 'Haftung für Inhalte',
            HaftungInhalteDetails:
                'Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.',
            HaftungLinks: 'Haftung für Links',
            HaftungLinksDetails:
                'Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.',
            Urheberrecht: 'Urheberrecht',
            UrheberrechtDetails:
                'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.',
            GeneralPractitioner: 'Arzt:in',
            Psychotherapist: 'Psychotherapeut',
            Psychiatrist: 'Hebamme',
            Physiotherapists: 'Physiotherapeut',
            YogaTeacher: 'Yoga Lehrer',
            MeditationTrainer: 'Meditation Trainer',
            Coach: 'Coach',
            Naturopath: 'Heilpraktiker',
            MidWives: 'Mid Wives',
            DontFindCategory: "You don't find any category that suit you? Chat with us",
            HowDoesItWork: 'Wie funktioniert es?',
            HomeStep1: 'Order Plan Online in a few cliks lorem',
            HomeStep2: 'Receive and conduct the test at home',
            HomeStep3: 'View your results online lorem',
            HomeStep4: 'Medical consultation lorem ipsum*',
            DisclaimerTextHomeDiagnostics: '*The medical consultation is only available in some of our tests.',
            AlleAnzeigen: 'Alle anzeigen',
            EntdeckenBegreifen: 'Entdecken & Begreifen',
            GesundheitFitness: 'Gesundheit & Fitness',
            FruchtbarkeitGeburt: 'Fruchtbarkeit & Geburt',
            DieserTest:
                'Dieser Test untersucht Blutwerte, die essentiell für Deine Gesundheit, Fitness und Leistungsfähigkeit sind (11 Biomarker)',
            WithExpertConsultation: 'with Eterno expert consultation',
            BuyTest: 'Buy Test',
            MoreDetails: 'More details',
            include: 'inkl.',
            taxDetails: 'USt., zzgl. Versand',
            BuyHomeTest: 'Buy Home Test and Consultation',
            Congratulations: 'Congratulations, your Sleep Better test is on its way!',
            WhatHappensNext: 'What happens next?',
            CompleteMyHealthProfile: 'Complete my Health Profile',
            HomeStepTitle1: 'Order Plan online',
            HomeStepTitle2: 'Take test at home',
            HomeStepTitle3: 'Comprehend the results',
            HomeStepText1: 'You will get your test per mail in the next 3-5 business days.',
            HomeStepText2: 'Follow the instructions in the box and send the test back to us.',
            HomeStepText3: 'You will get understandable results and personal recommendations.',
            WhatOurCustomersSay: 'Was unsere Kunden sagen',
            SimilarProducts: 'Similar products',
            WhatsIncludedWithMedicalConsultation: 'What’s included in the Medical Consultation?',
            DontWantMedicalConsultation: 'You don’t want the medical consultation? ',
            GetYourHomeTest: 'Get your Home Test for 39,90 €',
            DoHavePrescription: 'Do you have a prescription?',
            Yes: 'Ja',
            No: 'Nein',
            UploadYourPrescription: 'Upload your prescription',
            ChooseServiceManually: 'Choose service manually',
            ManuelleTherapie: 'Manuelle Therapie (MT)',
            MTmitWarmapackung: 'MT mit Warmpackung',
            MTmitElektrotherapie: 'MT mit Elektrotherapie',
            mitUltraschall: 'mit Ultraschall- Wärmetherapie',
            Krankengymnastik: 'Krankengymnastik (KG)',
            KGmitWarmapackung: 'KG mit Warmpackung',
            KGmitElektrotherapie: 'KG mit Elektrotherapie',
            ManuelleLymphdrainage30: 'Manuelle Lymphdrainage (MLD) 30 Min',
            ManuelleLymphdrainage45: 'Manuelle Lymphdrainage (MLD) 45 Min',
            ManuelleLymphdrainage60: 'Manuelle Lymphdrainage (MLD) 60 Min',
            Massagen: 'Massagen (KMT)',
            KombiBehandlungen: 'Kombi Behandlungen',
            MedicalTaping: 'Medical Taping & Kinesiotape',
            ChooseTheTherape: 'Choose the therapy that has been prescribed to you',
            WhatKindOfHealthInsurance: 'What kind of health insurance do you have?',
            PublicInsurance: 'Public insurance',
            PrivateInsurance: 'Private insurance',
            SelfPayer: 'Selbstzahler:in',
            public: 'Gesetzlich',
            private: 'Privat',
            self_payer: 'Selbstzahler',
            HealthInsurance: 'HEALTH INSURANCE',
            CardNum: 'Card number',
            StartTyping: 'Start typing',
            ToolTipText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            NoPastAppointment:
                'You don’t have any past appointments with this profile. But that’s OK, we can help you find the right expert for you.',
            BookClassAgain: 'Book this class again',
            DownloadInvoice: 'Download Invoice',
            AvailableAppointments: 'Available Appointments',
            ViewAllAvailableAppointments: 'View All Available Appointments',
            AvailableAppointmentPoint1: 'Lorem ipsum dolor sit amet',
            AvailableAppointmentPoint2: 'Incididunt ut labore et dolore magna',
            AvailableAppointmentPoint3: 'Exercitation ullamco laboris nisi',
            PasswordsNotMatch: 'Both passwords should be same.',
            EmailAlreadyExists: 'This email address is already in use.',
            WelcomeToHealthProfile: 'Welcome to your Health Profile',
            HealthProfileSub: 'We just need to ask you a few questions so we can help you achieve your health goals.',
            BasicDetails: 'Deine Individuelle Körpereigenschaften',
            DietDetials: 'Deine Ernährung',
            LifestyleDetails: 'Dein Lebensstil',
            GoalsDetails: 'Deine Ziele',
            YourHealthProfile: 'Your Health Profile',
            PreExistingConditions: 'Vorerkrankungen',
            MobileNumber: 'Handynummer',
            InsuranceNumber: 'Versichertennummer',
            JumpToFirstSlot: 'Spring zum ersten verfügbaren Zeitfenster',
            NextSlotCta: 'Nächstes Zeitfenster',
            NoTimeslotsAvailable:
                'Zurzeit sind keine Zeitfenster für diese Behandler:in verfügbar. Bitte schau später noch einmal nach.',
            AllFormsCheckInPrimaryHeading: 'Herakles Therapiezentrum Check-in',
            AllFormsPrimaryHeading: 'Geben Sie den Code ein, um auf die Formulare zuzugreifen',
            AllFormsSecondaryHeading: 'Geben Sie den beim Patienten-Check-in generierten Code ein',
            AllFormsCodeError: 'Falscher Code, bitte versuchen Sie es erneut',
            Continue: 'Weiter',
            OpenLink: 'Link Öffnen',
            Locations: 'Standorte',
            ExternalLocationMessage:
                'Bitte beachten Sie, dass Ihr Termin an einem Ort außerhalb des Eterno Hub stattfinden wird.',
            prismicFallback:
                'die Seite ist aktuell leider nicht erreichbar. Wir arbeiten bereits an einer Lösung. Wenn Sie jetzt einen Termin buchen möchten, rufen Sie unser Contact Center unter der Telefonnummer {phone_number_placeholder} an.',
            SeeMore: 'Mehr',
            ErrorFallbackHeading:
                'Sorry, da ist etwas schief gelaufen. Bitte laden Sie die Seite neu und versuchen Sie es noch einmal.',
            ErrorFallbackParagraph:
                'Falls Sie immer noch Probleme haben, zögern Sie bitte nicht, uns unter support@eterno.health zu kontaktieren.',
            ErrorFallbackCta: 'Seite neu laden',
            RegistrationNoteTitle: 'Hinweis zur Registrierung',
            RegistrationNoteText: 'Um ein neues Konto anzulegen, schließen Sie zunächst den Buchungsprozess ab',
            UnknownForm: 'Unbekanntes Formular',
            FormsFlowCta: 'Digitale Dokumente ausfüllen',
        },
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('languageGlobal'), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        fallbackLng: 'de',
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
