import { makeStyles } from '@material-ui/core';
import { getColorWithOpacity } from '@/utils/colorHelpers';

export const useStyles = makeStyles((theme) => ({
    //
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
    },
    mainHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
    },
    heading: {
        ...theme.typography.HL1,
    },
    cardContent: {
        backgroundColor: 'white',

        maxWidth: theme.layout.contentWidth,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        display: 'block',

        marginTop: 23,
    },
    selectItemCard: {
        backgroundColor: theme.palette.common.lightGray,
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        borderRadius: 8,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'inline-block',

        '&:hover': {
            backgroundColor: theme.palette.common.lightGray,
            boxShadow: `0 0 0 2px ${theme.palette.common.yellow} !important`,
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 18,
            paddingBottom: 18,
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 25,
            paddingRight: 25,
        },
    },
    selectedCard: {
        backgroundColor: 'white',
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow} !important`,
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    selectItemCardBorder: {
        boxShadow: `0 0 0 1px ${theme.palette.common.mediumBrown}`,
    },
    subHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        // textTransform: "none",
        color: theme.palette.common.black,
    },
    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },
    allergyType: {
        marginTop: 40,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
        },
    },
    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    maginB: {
        marginTop: 40,
        marginBottom: 30,
    },
    graySection: {
        marginTop: 20,
        padding: 20,
        backgroundColor: theme.palette.common.lightBrown,
        borderRadius: 8,
    },
    flexClass: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
    },
    icons: {
        marginRight: 30,
        cursor: 'pointer',
    },
    pointer: {
        cursor: 'pointer',
    },
    line: {
        marginTop: 40,
        marginBottom: 40,
        backgroundColor: theme.palette.common.lightBrown,
        height: 2,
        width: '100%',
    },
    answersContainer: {
        marginLeft: 0,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    rangeLabelContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: -2,
    },
    rangeLabelText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
    },
    valueLabel: {
        left: 0,
        color: 'transparent',
        backgroundColor: 'white',
        width: '7em',

        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '& > span': {
            width: '8em',
            textAlign: 'center',
            color: 'transparent',
        },
        '& > span > span': {
            ...theme.typography.subheading,
        },
    },
    valueLabelTwo: {
        left: 'auto',
        color: 'transparent',
        backgroundColor: 'white',
        width: '7em',

        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '& > span': {
            width: '8em',
            textAlign: 'center',
            color: 'transparent',
        },
        '& > span > span': {
            ...theme.typography.subheading,
        },
    },
    valueLabelThree: {
        left: '-5.5em',
        color: 'transparent',
        backgroundColor: 'white',
        width: '7em',

        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '& > span': {
            width: '8em',
            textAlign: 'center',
            color: 'transparent',
        },
        '& > span > span': {
            ...theme.typography.subheading,
        },
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        boxShadow: '0px 3px 12px rgba(120, 120, 120, 0.21)',
        marginTop: -8,
        marginLeft: (props) => {
            if (props.valueHeight > 0.1 && props.valueHeight <= 0.9) {
                return 0;
            } else if (props.valueHeight > 0.9) {
                return -12;
            }
            return 0;
        },
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },

    downArrow: {
        height: '0.65em',
        marginRight: 10,
    },

    formControl: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.14em',

        justifyContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            marginBottom: 15,
            height: '4.11em',
        },
        borderRadius: 5,

        '&:hover': {
            backgroundColor: 'white',
        },
    },
    BtncontentText: {
        textTransform: 'none',
    },
    divider: { height: '2px', background: '#F5F5F5', border: 'none' },
    highlight: {
        background: getColorWithOpacity(theme.palette.common.secondary, 0.15),
        padding: 10,
        marginBlock: 10,
    },
    main: {
        transition: 'background .5s',
    },
    show: {
        position: 'static',
        visibility: 'visible',
        opacity: 1,
        transition: ' visibility 1.5s linear 500ms, opacity 1.5s',
    },
    hide: {
        position: 'absolute',
        pointerEvents: 'none',
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 1.5s linear 500ms, opacity 1.5s',
    },
    contentTextLink: {
        fontFamily: 'MessinaSans-SemiBold',
        color: theme.palette.common.darkGrey,
    },
}));
