import { Menu, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ic_arrow_bottom from '@/assets/Icons/ic_arrow_bottom.svg';
import arrowTOP from '@/assets/images/arrowTOP.png';
import FilledButton from '@/components/FilledButton';
import AnamnesisModal from '@/components/HealthProfile/AnamnesisModal';
import OutlinedButton from '@/components/OutlinedButton';
import { Context as AuthContext } from '@/context/AuthContext';
import { useConsentFormsContext } from '@/context/ConsentFormsContext';
import { Context as DoctorsContext } from '@/context/DoctorsContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import useFormSteps from '@/hooks/useFormSteps';
import { professionalApiCall, professionalsId } from '@/service/AnamnesisSectionAPI/ProfessionalAPI';
import { getPractitionerForUser } from '@/service/Appointments/appointmentService';
import { usePersistedPrismicStore, useTrackingStore } from '@/store';
import { getText } from '@/utils/helpers';
import SummaryPrivacyPolicyModal from './SummaryComponents/SummaryPrivacyPolicyModal';

const CURRENT_ENV = import.meta.env.VITE_ENVIRONMENT;

const useStyles = makeStyles((theme) => ({
    contentContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
    },

    mainHeading: {
        fontFamily: 'MessinaSans-Regular',

        fontSize: 18,
        fontWeight: 'normal',
    },
    shortText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
        textTransform: 'none',
    },
    inputLabel: {
        '&.focused': {
            color: theme.palette.common.darkGrey,
        },
        '&.shrink': {
            fontSize: '1rem',
        },
    },

    menuItem: {
        fontSize: 16,
        fontFamily: 'MessinaSans-Regular',
        height: 55,
    },

    formControl: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingTop: 14,
        paddingBottom: 14,
        height: '4.14em',

        justifyContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            marginBottom: 15,
            height: '4.11em',
        },
        borderRadius: 5,
        '&:hover': {
            backgroundColor: 'white',
        },
    },

    downArrow: {
        height: '0.65em',
        marginRight: 10,
    },

    form: {
        marginTop: 23,
    },
    textFields: {
        height: '3.75em',
    },
    textFieldsError: {
        height: '3.75em',
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
    },

    fieldFocused: {
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.common.yellow} !important`,
    },
    filledInputRoot: {
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid #ABABAB',
        paddingRight: 10,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,

        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        height: '100%',
        '&:hover': {
            backgroundColor: '#F9F8F4',
        },
    },
    imges: {
        paddingLeft: 20,
    },
    upArrow: {
        height: 'fit-content',
    },
    disabledInsuranceButton: {
        opacity: 0.7,
    },
}));

const languageMap = {
    en: 'en-us',
    de: 'de-de',
};

export default function Professional({
    mainData,
    user,
    // mainDataProfessionalDoctors,
    contentProfessional,
    mainDataSummary,
}) {
    const { i18n } = useTranslation();
    const {
        [i18n.language]: { mainDataProfessionalDoctors },
    } = usePersistedPrismicStore((state) => state.anamnesisData);

    /** Orders professionals in the dropdown in alphabetical order */
    mainDataProfessionalDoctors?.sort((a, b) => a.display_name[0].text.localeCompare(b.display_name[0].text));

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    let history = useHistory();

    const {
        state: { selectedPrac, step, save_changes_option },
        setSelectedPrac,
        UpdateSteps,
        onSaveButton,
        onCancelButton,
        setStep,
        setReviewOption,
    } = useContext(HealthProfileContextMain);

    const { isConsentAllForms, isLastStep, next } = useFormSteps();

    const [openModal, setOpenModal] = useState(false);

    const {
        state: { mainDataArrActive, mainDataMyDoctorsID, mainDataMyDoctors },
        setMainDetaArrActive,
        setMainDataMyDoctorsID,
        setMainDataMyDoctors,
    } = useContext(DoctorsContext);

    const {
        setNameOfDoctor,
        nameOfDoctor,
        allDoctorsDataApi,
        setAllDoctorsDataApi,
        allDataApi,
        selectedDoctorGlobal,
        setSelectedDoctorGlobal,
        apppointmentDoctorInfo,
        fromAppointment,
    } = useContext(ContextProviderTwo);
    console.debug('🚀 ~ file: Professional.jsx:184 ~ selectedDoctorGlobal:', selectedDoctorGlobal);
    console.debug('🚀 ~ file: WelcomePage.jsx:85 ~ WelcomePage ~ fromAppointment:', fromAppointment);

    const { professional: consentFormProfessional } = useConsentFormsContext();
    console.debug(
        '🚀 ~ file: Professional.jsx:187 ~ consentFormProfessional:',
        isConsentAllForms,
        consentFormProfessional
    );

    const [dataSet, setDataSet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');
    const [anchorE1, setAnchorE1] = useState(null);
    const [localDoctor, setLocalDoctor] = useState('');
    const {
        state: { user: uer, usersession },
    } = useContext(AuthContext);

    const fetchAndSetUserDoctors = () => {
        getPractitionerForUser(usersession)
            .then((response) => {
                let data = response.data.data;
                let enDoctors = contentProfessional['en-us'];
                let deDoctors = contentProfessional['de-de'];
                let doctorIds = [];
                let myEnDoctors = [];
                let myDeDoctors = [];
                console.debug('MyDoctors: Data', data);
                data.forEach((doctor) => {
                    doctorIds.push(doctor.key);
                    let filteredEnDoctor = enDoctors.filter((r) => r.key[0].text === doctor.key);
                    let filteredDeDoctor = deDoctors.filter((r) => r.key[0].text === doctor.key);

                    myEnDoctors.push(filteredEnDoctor[0]);
                    myDeDoctors.push(filteredDeDoctor[0]);
                });
                console.debug('MyDoctors: Data', {
                    'en-us': myEnDoctors,
                    'de-de': myDeDoctors,
                });
                console.debug('MyDoctors: Ids', doctorIds);
                setMainDataMyDoctors({
                    'en-us': myEnDoctors,
                    'de-de': myDeDoctors,
                });
                setMainDataMyDoctorsID(doctorIds);
                setMainDetaArrActive(true);
                setDataSet(true);
                setLoading(false);
            })
            .catch((err) => {
                console.debug('MyDoctors: Error', err.message);
                setLoading(false);
            });
    };

    const PreSelectedDoctor = () => {
        if (allDoctorsDataApi && isConsentAllForms) {
            const globalProfessionalKey = consentFormProfessional?.key ?? consentFormProfessional?.professional_key;
            const professionalKey = Array.isArray(globalProfessionalKey)
                ? getText(globalProfessionalKey)
                : globalProfessionalKey;
            const filteredDoctor = allDoctorsDataApi.find((m) => m.prismic_key === professionalKey);
            const consentFormsFilteredProfessional = mainDataProfessionalDoctors?.find(
                (f) => getText(f.key) === professionalKey
            );
            if (filteredDoctor && consentFormsFilteredProfessional) {
                consentFormsFilteredProfessional.id = filteredDoctor?.user_id || '';
                consentFormsFilteredProfessional.dc_user_id = filteredDoctor?.dc_user_id || '';

                setSelectedDoctorGlobal([consentFormsFilteredProfessional]);
                setNameOfDoctor(getText(consentFormsFilteredProfessional?.display_name));
                setSelectedPrac(professionalKey);
            }
        }

        if (
            fromAppointment &&
            apppointmentDoctorInfo !== null &&
            apppointmentDoctorInfo !== undefined &&
            apppointmentDoctorInfo.length > 0
        ) {
            setSelectedPrac(apppointmentDoctorInfo);
            if (
                mainDataProfessionalDoctors === undefined ||
                mainDataProfessionalDoctors === null ||
                mainDataProfessionalDoctors.length === 0
            )
                return;
            let appointmentDoctor = mainDataProfessionalDoctors?.filter(
                (f) => f.key[0].text === apppointmentDoctorInfo
            );
            if (allDoctorsDataApi && allDoctorsDataApi.length > 0) {
                let filteredDoctor = allDoctorsDataApi.filter((m) => m.prismic_key === apppointmentDoctorInfo);
                appointmentDoctor[0]['id'] = filteredDoctor[0]?.user_id;
                appointmentDoctor[0]['dc_user_id'] = filteredDoctor[0]?.dc_user_id;
            }

            setSelectedDoctorGlobal(appointmentDoctor);
            setNameOfDoctor(appointmentDoctor[0]?.display_name[0].text);
        }
    };
    //hh-herakles-therapiezentrum
    useEffect(() => {
        PreSelectedDoctor();
    }, [apppointmentDoctorInfo, mainDataProfessionalDoctors, allDoctorsDataApi]);

    useMemo(() => {
        if (save_changes_option) {
            setLocalDoctor(selectedDoctorGlobal);
        }
        if (user) {
            fetchAndSetUserDoctors();
        }
        professionalsId()
            .then((response) => {
                let responseData = response?.data.data;
                setAllDoctorsDataApi(responseData);
                console.debug('allDoctorsAPI resp: ', response);
            })
            .catch((error) => {
                console.debug('allDoctorsAPI error: ', error);
                setAllDoctorsDataApi([]);
            });
    }, []);

    const handleClick = (event) => {
        var w = document.getElementById('proBTN').clientWidth;
        console.debug(w);
        setMobileCodeWidth(w.toString());
        setAnchorE1(event.currentTarget);
    };
    console.debug('openModal4: doctor API', allDoctorsDataApi);
    const handleClose = (selectedPracc = '') => {
        setAnchorE1(null);
        if (selectedPracc !== '') {
            setSelectedPrac(selectedPracc);
            let selectedDoctor = mainDataProfessionalDoctors?.filter((f) => f.key[0].text === selectedPracc);
            if (allDoctorsDataApi && allDoctorsDataApi.length > 0) {
                console.debug('openModal4: doctor API', allDoctorsDataApi);
                let filteredDoctor = allDoctorsDataApi.filter((m) => m.prismic_key === selectedPracc);
                selectedDoctor[0]['id'] = filteredDoctor[0]?.user_id;
                selectedDoctor[0]['dc_user_id'] = filteredDoctor[0]?.dc_user_id;
                console.debug('filteredDoctor1: ', filteredDoctor);
            }

            console.debug('selectedDoctor1: ', selectedDoctor);
            setSelectedDoctorGlobal(selectedDoctor);
            setNameOfDoctor(selectedDoctor[0]?.display_name[0].text);
        }
    };
    console.debug(
        'selectedDoctorGlobal1: Check',
        selectedPrac,
        save_changes_option,
        selectedDoctorGlobal,
        mainDataProfessionalDoctors
    );

    console.debug('hello3', localDoctor);
    const Cancel = () => {
        setSelectedPrac(localDoctor[0]?.key[0].text);
        setSelectedDoctorGlobal(localDoctor);
        setNameOfDoctor(localDoctor[0]?.display_name[0].text);
        onCancelButton(history);
    };

    const ProfessionalAPI = () => {
        const saveProfessional = () => {
            let user_id_local = usersession?.idToken.payload.sub;

            const body = {
                user_id: user_id_local,
                consent: true,
                professional: {
                    id: selectedDoctorGlobal[0]?.id,
                    key: selectedDoctorGlobal[0]?.key[0].text,
                    name: selectedDoctorGlobal[0]?.display_name[0].text,
                    ext_id: selectedDoctorGlobal[0]?.dc_user_id,
                },
            };
            console.debug('selectedDoctorGlobal1', body);
            professionalApiCall(usersession, body).then((resp) => {
                setLoading(false);
                if (!isConsentAllForms) history.replace('/confirmConsent');
            });
        };

        if (isConsentAllForms) {
            saveProfessional();
            next();
            return;
        }

        saveProfessional();
    };

    let lan = languageMap[i18n.language];
    let myPractitioners =
        mainDataMyDoctors['en-us'].length > 0 && mainDataMyDoctors !== null && mainDataMyDoctors !== undefined
            ? mainDataMyDoctors[lan]
            : [];

    console.debug(
        'mySelected',
        selectedPrac.length === 0 ||
            !selectedDoctorGlobal[0] ||
            !selectedDoctorGlobal[0]?.dc_user_id ||
            !selectedDoctorGlobal[0]?.id
    );
    console.debug('mainDataProfessionalDoctors123', mainDataProfessionalDoctors);

    const ProfessionalContinue = () => {
        if (allDataApi?.meta?.has_anamnesis && allDataApi?.documents) {
            sharedDoctor();
            return;
        }
        useTrackingStore.setState({
            anamnesisStarted: Date.now(),
            completedAnamnesisSections: {},
        });
        UpdateSteps(step, history, user, false, isConsentAllForms, isLastStep);
    };

    const onPressReview = () => {
        setReviewOption(true);
        setStep(8);
        onSaveButton(history);
    };

    const onPressFilled = () => {
        setLoading(true);
        ProfessionalAPI();
    };

    const sharedDoctor = () => {
        let shared_doctor = allDataApi['documents'].filter(
            (i) => i.sharing[0].shared_target.id === selectedDoctorGlobal[0]?.id
        );
        console.debug('shared_doctor', shared_doctor, selectedDoctorGlobal[0]?.id);

        if (shared_doctor.length > 0) {
            setOpenModal(true);
            // setIsAlreadySubmitted(true);
            setIsAlreadySubmitted(!isConsentAllForms);
            console.debug('openModal4: inner IF');
        } else {
            setIsAlreadySubmitted(false);
            setOpenModal(true);
        }
    };

    // privacy policy modal/popup  disabledInsuranceButton
    const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

    return (
        <>
            <div className={classes.contentContainerBody}>
                <Typography className={classes.mainHeading}>{mainData?.professional_question[0].text}</Typography>
                <Button
                    id="proBTN"
                    style={{ marginTop: 15 }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    disabled={fromAppointment || isConsentAllForms}
                    disableRipple
                    className={
                        fromAppointment
                            ? clsx(classes.formControl, classes.disabledInsuranceButton)
                            : classes.formControl
                    }
                    classes={{ label: classes.menuLabel }}
                >
                    <div>
                        <Typography
                            style={{
                                fontFamily: 'MessinaSans-Regular',
                                fontSize: '0.875rem',
                                color: theme.palette.common.darkGrey,
                                textTransform: 'none',
                                padding: 0,
                                marginLeft: 3,
                                textAlign: 'left',
                            }}
                        >
                            {mainData?.dropdown_placeholder_text[0].text}
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'MessinaSans-SemiBold',
                                fontSize: '1rem',
                                color: theme.palette.common.darkGrey,
                                textTransform: 'none',
                                marginLeft: 3,
                                textAlign: 'left',
                            }}
                        >
                            {nameOfDoctor}
                        </Typography>
                    </div>
                    <img src={ic_arrow_bottom} alt="select" className={classes.downArrow} />
                </Button>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorE1}
                    keepMounted
                    open={Boolean(anchorE1)}
                    onClose={() => {
                        handleClose();
                    }}
                    PaperProps={{
                        style: {
                            width: parseFloat(mobileCodeWidth),
                            backgroundColor: theme.palette.common.lightBrown,
                            border: `2px solid ${theme.palette.common.yellow}`,
                            maxHeight: '30%',
                            minHeight: '6%',
                        },
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose();
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: 15,
                            justifyContent: 'space-between',
                            paddingRight: 15,
                            marginBottom: 10,
                        }}
                    >
                        <div>
                            <Typography
                                style={{
                                    fontFamily: 'MessinaSans-Regular',
                                    fontSize: '0.875rem',
                                    color: theme.palette.common.darkGrey,
                                    textTransform: 'none',
                                    padding: 0,
                                    height: 40,
                                }}
                            >
                                {mainData.dropdown_placeholder_text[0].text}
                            </Typography>
                        </div>
                        <img src={arrowTOP} alt="select" className={classes.upArrow} />
                    </MenuItem>
                    {mainDataArrActive &&
                        mainDataMyDoctorsID?.length > 0 &&
                        mainDataMyDoctors?.['en-us'].length > 0 &&
                        myPractitioners?.length > 0 &&
                        myPractitioners.map((data) => (
                            <MenuItem
                                className={classes.menuItem}
                                onClick={() => {
                                    console.debug('selected112', data.key[0].text === selectedPrac);
                                    handleClose(data.key[0].text);
                                }}
                                style={{
                                    backgroundColor:
                                        data.key[0].text === selectedPrac
                                            ? theme.palette.common.lightBrownSelected
                                            : theme.palette.common.lightBrown,
                                }}
                            >
                                <div
                                    className=""
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    {data.display_name[0].text}
                                </div>
                            </MenuItem>
                        ))}

                    {myPractitioners.length === 0 &&
                        mainDataProfessionalDoctors !== undefined &&
                        mainDataProfessionalDoctors !== null &&
                        mainDataProfessionalDoctors?.length > 0 &&
                        allDoctorsDataApi &&
                        allDoctorsDataApi.length > 0 &&
                        mainDataProfessionalDoctors?.map((data) => {
                            const doc = allDoctorsDataApi.find((doc) => doc.prismic_key === data.key[0].text);
                            return (
                                !mainDataMyDoctorsID.includes(data.key[0].text) &&
                                doc &&
                                doc.dc_user_id &&
                                doc.user_id &&
                                data.tags.includes(CURRENT_ENV) && (
                                    <MenuItem
                                        className={classes.menuItem}
                                        onClick={() => {
                                            handleClose(data.key[0].text);
                                        }}
                                        style={{
                                            backgroundColor:
                                                data.key[0].text === selectedPrac
                                                    ? theme.palette.common.lightBrownSelected
                                                    : theme.palette.common.lightBrown,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            {data.display_name[0].text}
                                        </div>
                                    </MenuItem>
                                )
                            );
                        })}
                </Menu>

                {/* btns */}
                <div
                    className=""
                    style={{
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: matches && 'column-reverse',
                    }}
                >
                    {!save_changes_option ? (
                        <OutlinedButton
                            text={mainData?.back_cta[0].text}
                            onPress={() => {
                                history.goBack();
                            }}
                            disabled={!fromAppointment}
                            fullWidth={matches && true}
                        />
                    ) : (
                        <OutlinedButton
                            text={mainData?.cancel[0].text}
                            onPress={() => {
                                Cancel();
                            }}
                            fullWidth={matches && true}
                        />
                    )}
                    {!save_changes_option ? (
                        <FilledButton
                            text={mainData?.continue_cta[0].text}
                            disabled={
                                fromAppointment
                                    ? selectedPrac.length === 0
                                    : selectedPrac.length === 0 ||
                                      !selectedDoctorGlobal[0] ||
                                      !selectedDoctorGlobal[0]?.dc_user_id ||
                                      !selectedDoctorGlobal[0]?.id
                            }
                            onPress={() => {
                                ProfessionalContinue();
                            }}
                            fullWidth={matches && true}
                            marginBottom={matches && 20}
                        />
                    ) : (
                        <FilledButton
                            text={mainData?.save_cta[0].text}
                            disabled={selectedPrac.length <= 0}
                            onPress={() => {
                                if (save_changes_option) {
                                    setReviewOption(false);
                                    onSaveButton(history);
                                }
                            }}
                            fullWidth={matches && true}
                            marginBottom={matches && 20}
                        />
                    )}
                </div>
            </div>
            {selectedPrac?.length > 0 && (
                <>
                    <AnamnesisModal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        onPressReview={() => {
                            onPressReview();
                        }}
                        onPressFilled={() => {
                            onPressFilled();
                        }}
                        loading={loading}
                        mainData={mainDataSummary}
                        isAlreadySubmitted={isAlreadySubmitted}
                        mainDataProfessionalDoctors={mainDataProfessionalDoctors}
                        setOpenPrivacyModal={setOpenPrivacyModal}
                    />
                    <SummaryPrivacyPolicyModal
                        open={openPrivacyModal}
                        onClose={() => setOpenPrivacyModal(false)}
                        link={selectedDoctorGlobal[0]?.practitioner_privacy_policy.url}
                        subHeading={nameOfDoctor || ''}
                        mainData={mainDataSummary}
                    />
                </>
            )}
        </>
    );
}
