import { memo, useContext, useEffect } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './YourBodyStyles';
import { Slider } from '@material-ui/core';

const PrettoSlider = withStyles((theme) => ({
    root: {
        color: '#ABABAB',
        height: 2,
    },
    active: {},
    valueLabel: {},
    track: {
        height: 8,
        borderRadius: 4,
        color: theme.palette.common.yellow,
    },
    rail: {
        height: 8,
        borderRadius: 4,
        opacity: 1,
    },
}))(Slider);

function YourBodyDisability({ mainData }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const { disability, setDisability, disabilityValue, setDisabilityValue } = useContext(ContextProviderTwo);

    useEffect(() => {
        if (disability !== null && disability === 'NO') {
            setDisabilityValue(0);
        }
    }, [disability]);

    // height
    const handleChangeHeight = (event, newValue) => {
        setDisabilityValue(newValue);
    };
    const valueLabelFormatHeight = (disabilityValue) => {
        return `${disabilityValue ? disabilityValue : 0}${mainData.disability_response_unit_label[0].text}`;
    };

    return (
        <>
            <div className={classes.contentContainerBody}>
                <div className="" style={{ marginTop: disability && 40 }}>
                    <div className={classes.fixedQuestion}>
                        <Typography className={classes.mainHeading}>
                            {mainData.disability_question_title[0].text}
                        </Typography>
                        <Grid
                            style={{
                                marginTop: 20,
                            }}
                            container
                        >
                            <div style={{ width: '100%', display: 'flex', gap: 15 }}>
                                {mainData &&
                                    mainData.disability_y_n_answer.map((answer) => (
                                        <Button
                                            onClick={() => {
                                                console.debug(answer.disability_y_n_answer_selection);
                                                setDisability(answer.disability_y_n_answer_selection);
                                            }}
                                            disableRipple
                                            className={
                                                disability === answer.disability_y_n_answer_selection
                                                    ? clsx(classes.selectItemCard, classes.selectedCard)
                                                    : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                            }
                                            style={{
                                                marginBottom: matches && 20,
                                                flex: matches && 1,
                                            }}
                                        >
                                            <Typography className={classes.BtncontentText}>
                                                {answer.disability_y_n_answer_yes[0].text}
                                            </Typography>
                                        </Button>
                                    ))}
                            </div>
                        </Grid>
                    </div>

                    {/* mounths */}
                    {disability && disability.toLocaleLowerCase() === 'yes' && (
                        <div className={classes.disabilitySliderContainer}>
                            <PrettoSlider
                                valueLabelDisplay="on"
                                value={disabilityValue}
                                onChange={handleChangeHeight}
                                min={mainData?.disability_response_min}
                                max={mainData?.disability_response_max}
                                getAriaValueText={valueLabelFormatHeight}
                                valueLabelFormat={valueLabelFormatHeight}
                                step={mainData?.disability_response_step_size}
                                classes={{
                                    valueLabel:
                                        disabilityValue < mainData?.disability_response_min + 5
                                            ? classes.valueLabel
                                            : disabilityValue > mainData?.disability_response_min + 5 &&
                                                disabilityValue <= mainData?.disability_response_max - 5
                                              ? classes.valueLabelTwo
                                              : disabilityValue > mainData?.disability_response_max - 5
                                                ? classes.valueLabelThree
                                                : classes.valueLabel,
                                    thumb: classes.thumb,
                                }}
                            />
                            <div className={classes.rangeLabelContainer}>
                                <Typography className={classes.rangeLabelText}>
                                    {`${mainData?.disability_response_min}${mainData.disability_response_unit_label[0].text}`}
                                </Typography>
                                <Typography className={classes.rangeLabelText}>
                                    {`${mainData?.disability_response_max}${mainData.disability_response_unit_label[0].text}`}
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default memo(YourBodyDisability);
