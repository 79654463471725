import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DropDown } from '../../Common/DropDown/DropDown';
import { useCustomAnamnesisStyles } from '../customAnamnesisStyles';
import { useCustomAnamnesisStore } from '@/store';
import clsx from 'clsx';
import { useScrollToQuestion } from '@/hooks/useScrollToQuestion';

export const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
    },
}));

const DropdownQuestion = ({
    question,
    answer,
    setAnswer,
    isSubQuestion = false,
    hideTitle = false,
    noMarginTop = false,
}) => {
    const classes = useStyles();
    const customAnamnesisStyles = useCustomAnamnesisStyles();
    const highlightedQuestions = useCustomAnamnesisStore((state) => state.highlightedQuestions);
    const questionRef = useScrollToQuestion(question);

    const onChange = (value) => {
        setAnswer(value);

        if (value && highlightedQuestions[question.question_key[0].text]) {
            useCustomAnamnesisStore.setState({
                highlightedQuestions: {
                    ...highlightedQuestions,
                    [question.question_key[0].text]: false,
                },
            });
        }
    };

    return (
        <div
            ref={questionRef}
            className={clsx(
                customAnamnesisStyles.defaultQuestion,
                highlightedQuestions[question.question_key[0].text] && customAnamnesisStyles.highlight
            )}
        >
            {!isSubQuestion && !hideTitle && (
                <Typography style={{ marginBottom: '1rem' }} className={classes.title}>
                    {question.title[0].text}
                </Typography>
            )}
            <DropDown
                type="default"
                data={answer}
                setData={(value) => onChange(value)}
                mainDataHeading={question.label[0].text}
                mainDataArray={question.dropdown_options}
                compulsory={''}
                validateForm={null}
                noMarginTop={noMarginTop}
            />
        </div>
    );
};

export default DropdownQuestion;
