import createDataContext from './createDataContext';
import axios from 'axios';
import { useConfigStore } from '@/store';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;

const authReducer = (state, action) => {
    switch (action.type) {
        case 'set_user':
            return { ...state, user: action.payload };
        case 'set_user_session':
            return { ...state, usersession: action.payload };
        case 'set_user_account':
            return { ...state, userAccount: action.payload };
        case 'set_user_address':
            return { ...state, userAddresses: action.payload };
        case 'set_user_health_insurance':
            return { ...state, userHealthInsurance: action.payload };
        case 'set_verification_loading':
            return { ...state, verificationLoading: action.payload };
        case 'set_is_user':
            return { ...state, isUser: action.payload };
        case 'set_user_data':
            return { ...state, userData: action.payload };
        case 'set_user_all_data':
            return { ...state, userAllData: action.payload };
        case 'set_user_documents':
            return { ...state, userDocuments: action.payload };
        case 'set_user_document_by_id':
            return { ...state, userDocumentById: action.payload };
        default:
            return state;
    }
};

const fetchUserAddress = async (dispatch, user) => {
    console.debug('user', user);
    let user_id = user.idToken.payload.sub;
    let token = user.idToken.jwtToken;
    try {
        const res = await axios.get(
            `${BASE_API_URL}/secure/user/accounts/${user_id}/addresses`,

            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
            }
        );

        dispatch({ type: 'set_user_address', payload: res.data });
    } catch (error) {
        console.debug('error is adress ', error);
    }
};

const fetchUserHealthInsurance = async (dispatch, user) => {
    console.debug('user', user);
    let user_id = user.idToken.payload.sub;
    let token = user.idToken.jwtToken;
    try {
        const res = await axios.get(
            `${BASE_API_URL}/secure/user/accounts/${user_id}/health-insurances`,

            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
            }
        );

        dispatch({ type: 'set_user_health_insurance', payload: res.data });
    } catch (error) {
        console.debug('error is adress ', error);
    }
};

const fetchUserData = async (dispatch, user) => {
    console.debug('user', user);
    let user_id = user.idToken.payload.sub;
    let token = user.idToken.jwtToken;

    const currentCustomer = useConfigStore.getState().currentCustomer;
    const customer_id = currentCustomer?.customer_id?.[0].text;
    if (!customer_id) {
        return;
    }
    try {
        const res = await axios.get(`${BASE_API_URL}/secure/user/health-profiles/${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                customer_id: customer_id,
            },
        });

        dispatch({ type: 'set_user_data', payload: res.data });
    } catch (error) {
        console.debug('error is adress ', error);
    }
};

const getUserData = async (user) => {
    try {
        let user_id = user?.idToken.payload.sub;
        let token = user?.idToken.jwtToken;
        const currentCustomer = useConfigStore.getState().currentCustomer;
        const customer_id = currentCustomer?.customer_id?.[0].text;
        const res = await axios.get(`${BASE_API_URL}/secure/user/health-profiles/${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                customer_id: customer_id,
            },
        });

        return res.data;
    } catch (error) {
        console.debug('Error:', error);
    }
};

// Fetch All User Documents
const fetchUserDocuments = async (dispatch, user) => {
    console.debug('user', user);
    let user_id = user.idToken.payload.sub;
    let token = user.idToken.jwtToken;
    try {
        const res = await axios.get(`${BASE_API_URL}/secure/user/documents/uploaded?user_id=${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });

        dispatch({ type: 'set_user_documents', payload: res.data });
    } catch (error) {
        console.debug('error is adress ', error);
    }
};

// fetch user all documents
const fetchUserAllDocuments = (dispatch) => async (user) => {
    console.debug('user', user);
    let user_id = user.idToken.payload.sub;
    let token = user.idToken.jwtToken;
    try {
        const res = await axios.get(`${BASE_API_URL}/secure/user/documents/uploaded?user_id=${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });

        dispatch({ type: 'set_user_documents', payload: res.data });
    } catch (error) {
        console.debug('error is adress ', error);
    }
};

const fetchUserDocumentById = (dispatch) => async (documentId, user) => {
    console.debug('user', user);
    let user_id = user.idToken.payload.sub;
    let token = user.idToken.jwtToken;
    try {
        const res = await axios.get(`${BASE_API_URL}/secure/user/documents/${documentId}/?user_id=${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        dispatch({ type: 'set_user_document_by_id', payload: res.data });
        return res.data;
    } catch (error) {
        console.debug('error is ', error);
    }
};

const setuser = (dispatch) => async (user) => {
    dispatch({ type: 'set_user', payload: user });
};

const setUserAddress = (dispatch) => async (user) => {
    dispatch({ type: 'set_user_address', payload: user });
};

const setUserHealthInsurance = (dispatch) => async (user) => {
    dispatch({ type: 'set_user_health_insurance', payload: user });
};

const setUserData = (dispatch) => async (user) => {
    dispatch({ type: 'set_user_data', payload: user });
};

const setUserDocuments = (dispatch) => async (user) => {
    dispatch({ type: 'set_user_documents', payload: user });
};

const setusersession = (dispatch) => async (user) => {
    dispatch({ type: 'set_user_session', payload: user });
    if (user) {
        fetchUserAddress(dispatch, user);
        fetchUserHealthInsurance(dispatch, user);
        fetchUserData(dispatch, user);
        fetchUserDocuments(dispatch, user);
    }
};

const setuseraccounts = (dispatch) => async (userAccount) => {
    dispatch({ type: 'set_user_account', payload: userAccount });
};

const setVerificationLoading = (dispatch) => async (val) => {
    dispatch({ type: 'set_verification_loading', payload: val });
};

const setIsUser = (dispatch) => async (val) => {
    dispatch({ type: 'set_is_user', payload: val });
};

export const { Provider, Context } = createDataContext(
    authReducer,
    {
        setuser,
        setusersession,
        setuseraccounts,
        setUserHealthInsurance,
        setUserAddress,
        setVerificationLoading,
        setIsUser,
        setUserData,
        setUserDocuments,
        fetchUserAllDocuments,
        fetchUserDocumentById,
        getUserData,
    },
    {
        user: null,
        usersession: null,
        userAccount: null,
        userAddresses: null,
        userHealthInsurance: null,
        verificationLoading: false,
        isUser: false,
        userData: null,
        userAllData: null,
        userDocuments: null,
        userDocumentById: null,
    }
);
