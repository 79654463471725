import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { useStyles } from './LifeStyleStyles';
import LifeStyleWhichOneDrugs from './LifeStyleWhichOneDrugs';

const LifeStyleDrugs = ({
    mainData,
    isFromHealthData,
    whichOneDrugsLocal = [{ whichOne: '', howOftenDrugs: '' }],
    setWhichOneDrugsLocal,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const { drugs, setDrugs } = useContext(ContextProviderTwo);

    return (
        <div
            className={classes.fixedQuestion}
            style={{
                marginTop: isFromHealthData ? drugs && (matches ? 20 : 30) : 30,
            }}
        >
            <Typography className={classes.mainHeading}>{mainData['drugs_question'][0].text}</Typography>

            <Grid
                style={{
                    marginTop: 20,
                }}
                container
            >
                {mainData &&
                    mainData['drugs_yes_no_responses'].map((parts) => (
                        <Grid lg={'auto'} sm={'auto'} xs={6}>
                            <Button
                                onClick={() => {
                                    setDrugs(parts['drugs_yes_no_reponses_api_value']);

                                    if (
                                        parts['drugs_yes_no_reponses_api_value'] === 'NO' &&
                                        whichOneDrugsLocal[0]?.howOftenDrugs.length > 0
                                    ) {
                                        setWhichOneDrugsLocal([{ whichOne: '', howOftenDrugs: '' }]);
                                    }
                                }}
                                disableRipple
                                className={
                                    drugs.includes(parts['drugs_yes_no_reponses_api_value'])
                                        ? clsx(classes.selectItemCard, classes.selectedCard)
                                        : clsx(classes.selectItemCard, classes.selectItemCardBorder)
                                }
                                style={{
                                    marginBottom: 10,
                                    width: matches && '90%',
                                    display: matches ? null : 'flex',
                                    marginRight: 15,
                                }}
                            >
                                <div className="">
                                    <Typography className={classes.BtncontentText}>
                                        {parts['drugs_yes_no_responses'][0].text}
                                    </Typography>
                                </div>
                            </Button>
                        </Grid>
                    ))}
            </Grid>
            {drugs === 'YES' && (
                <div
                    className=""
                    style={{
                        marginTop: 10,
                    }}
                >
                    <LifeStyleWhichOneDrugs
                        mainData={mainData}
                        whichOneDrugsLocal={whichOneDrugsLocal}
                        setWhichOneDrugsLocal={setWhichOneDrugsLocal}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(LifeStyleDrugs);
