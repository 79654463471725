import React, { useContext, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import CountrySelection from '../../Account/CountrySelection';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { getAdressAPI } from '@/service/AnamnesisSectionAPI/PersonalnformationAPI';
import { useStyles } from './PersonalInfoClass';

export default function PersonalInfocontactDetails({ mainData, user, usersession }) {
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const {
        estreetMainAdress,
        ezipMainAdress,
        ecityMainAdress,
        pcountry,
        epcountry,
        pcode,
        epcode,
        occuption,
        seteSreetMainAdress,
        seteZipMainAdress,
        seteCityMainAdress,
        setPcountry,
        setePcountry,
        setPcode,
        setePcode,
        setOccuption,
        setCountrySelect,
        errZip,
        setErrZip,
        errCity,
        setErrorCity,
        errStreet,
        setErrorStreet,
    } = useContext(ContextProviderTwo);

    useMemo(() => {
        if (user) {
            // GET api integration for Adress
            getAdressAPI(usersession)
                .then((response) => {
                    let adressGetApi = response?.data;
                    console.debug('adress_get_api', adressGetApi);
                    if (adressGetApi !== undefined && adressGetApi !== null) {
                        seteSreetMainAdress(adressGetApi.street);
                        seteZipMainAdress(adressGetApi.zip);
                        seteCityMainAdress(adressGetApi.city);
                        setPcountry(adressGetApi?.country.name);
                        setePcountry(adressGetApi?.country.name);
                        setePcode(adressGetApi?.country.code);
                        setPcode(adressGetApi?.country.code);
                    }
                })
                .catch((error) => {
                    console.debug('adress_get_api ERROR', error);
                });
        }
    }, []);

    const validateForm = (type) => {
        switch (type) {
            case 'street':
                setErrorStreet('');
                if (estreetMainAdress === '') {
                    setErrorStreet(mainData.required_field_error_message[0].text);
                }
                break;

            case 'ZIP':
                setErrZip('');
                if (ezipMainAdress === '') {
                    setErrZip(mainData.required_field_error_message[0].text);
                }
                break;

            case 'city':
                setErrorCity('');
                if (ecityMainAdress === '') {
                    setErrorCity(mainData.required_field_error_message[0].text);
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Grid className="" item md={12} xs={12}>
                <Grid item md={12} xs={12} style={{ padding: 0, marginTop: 25 }}>
                    <Typography className={classes.mainHeading}>
                        {mainData ? mainData.contact_details_question[0].text : t('PERSONALDATA')}
                    </Typography>

                    <TextField
                        id="street"
                        label={mainData ? mainData.street_label[0].text : t('StreetNumber')}
                        variant="filled"
                        color="primary"
                        value={estreetMainAdress}
                        onChange={(event) => {
                            const text = event.target.value;
                            seteSreetMainAdress(text);
                        }}
                        fullWidth
                        onBlur={() => validateForm('street')}
                        classes={{
                            root: errStreet ? classes.textFieldsError : classes.textFields,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                focused: 'focused',
                                shrink: 'shrink',
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.filledInputRoot,
                                focused: classes.fieldFocused,
                                shrink: 'shrink',
                            },
                        }}
                    />
                    {errStreet && (
                        <Typography className={classes.errorText}>
                            {mainData.required_field_error_message1[0].text}
                        </Typography>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="" style={{ flex: 0.38 }}>
                            <TextField
                                id="zip"
                                label={mainData ? mainData.zip_code_label[0].text : t('ZIPCode')}
                                variant="filled"
                                color="primary"
                                value={ezipMainAdress}
                                onChange={(event) => {
                                    const text = event.target.value;
                                    seteZipMainAdress(text);
                                }}
                                fullWidth
                                classes={{
                                    root: errZip ? classes.textFieldsError : classes.textFields,
                                }}
                                onBlur={() => validateForm('ZIP')}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                }}
                            />
                            {errZip && (
                                <Typography className={classes.errorText}>
                                    {mainData.required_field_error_message1[0].text}
                                </Typography>
                            )}
                        </div>

                        <div className="" style={{ flex: 0.6 }}>
                            <TextField
                                id="city"
                                label={mainData ? mainData.city_label[0].text : t('City')}
                                variant="filled"
                                onBlur={() => validateForm('city')}
                                color="primary"
                                value={ecityMainAdress}
                                onChange={(event) => {
                                    const text = event.target.value;
                                    seteCityMainAdress(text);
                                }}
                                fullWidth
                                classes={{
                                    root: errCity ? classes.textFieldsError : classes.textFields,
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        focused: 'focused',
                                        shrink: 'shrink',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.filledInputRoot,
                                        focused: classes.fieldFocused,
                                        shrink: 'shrink',
                                    },
                                }}
                            />
                            {errCity && (
                                <Typography className={classes.errorText}>
                                    {mainData.required_field_error_message1[0].text}
                                </Typography>
                            )}
                        </div>
                    </div>

                    <CountrySelection
                        setCountrySelect={setCountrySelect}
                        setePcode={setePcode}
                        setePcountry={setePcountry}
                        pcode={pcode}
                        epcode={epcode}
                        epcountry={epcountry}
                        pcountry={pcountry}
                        setPcountry={setPcountry}
                        setPcode={setPcode}
                    />
                </Grid>
            </Grid>
        </>
    );
}
