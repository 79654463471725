import { CircularProgress, LinearProgress, Menu, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { greenBoxTick } from '@/assets/images/greenBoxTick';
import greenHeart from '@/assets/images/greenHeart.svg';
import sideBarArrow from '@/assets/images/sideBarArrow.png';
import sideBarArrowUp from '@/assets/images/sideBarArrowUp.png';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { Context as HealthProfileContextMain } from '@/context/HealthProfileContextMain';
import useFormSteps from '@/hooks/useFormSteps';
import { useStyles } from './HealthProfileAnamnesis/HealthProfileMainClasses';
import { yourBodyGetAPI } from '@/service/AnamnesisSectionAPI/YourBoduApi';
import { usePersistedPrismicStore, useTrackingStore } from '@/store';
import { trackStormlyEvent } from '@/utils/helpers';
import { Context as AuthContext } from './../context/AuthContext';
import Account from './HealthProfileAnamnesis/Account';
import { Allergies } from './HealthProfileAnamnesis/Allergies';
import ExistingCondition from './HealthProfileAnamnesis/ExistingCondition';
import Family from './HealthProfileAnamnesis/Family';
import LifeStyle from './HealthProfileAnamnesis/LifeStyle';
import Medications from './HealthProfileAnamnesis/Medications';
import PersonalInfo from './HealthProfileAnamnesis/PersonalInfo';
import Professional from './HealthProfileAnamnesis/Professional';
import Summary from './HealthProfileAnamnesis/Summary';
import UploadedByMe from './HealthProfileAnamnesis/UploadedByMe';
import YourBody from './HealthProfileAnamnesis/YourBody';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 5,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.common.yellow,
    },
}))(LinearProgress);

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const CircularProgressBar = ({ save_changes_option, step, user, page_no_summary }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress variant="determinate" className={classes.bottom} size={24} thickness={5} value={100} />
            <CircularProgress
                variant="determinate"
                className={classes.top}
                size={24}
                thickness={5}
                value={
                    !save_changes_option
                        ? parseInt(step) * (user ? 10 : 9)
                        : parseInt(page_no_summary) * (user ? 10 : 9)
                }
            />
        </div>
    );
};

function HealthProfileMain() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const {
        state: { step, pages, page_no_summary, save_changes_option, pagesLogout, loginAnamnesis, selectedPrac },
        setStep,
    } = useContext(HealthProfileContextMain);

    const { fromLocation, emptyStates, setAllDataApi, allDataApi, isConcent, fromAppointment } =
        useContext(ContextProviderTwo);
    const {
        [i18n.language]: {
            allData,
            mainDataProfessionalDoctors,
            mainDataAccountEterno,
            mainDataSummary,
            mainData,
            mainDataAccount,
            contentProfessional,
            dataSet,
        },
    } = usePersistedPrismicStore((state) => state.anamnesisData);

    const {
        state: { user, usersession },
    } = useContext(AuthContext);

    const { isConsentAllForms, isLastStep } = useFormSteps();
    const isAccountPage = isConsentAllForms ? isLastStep && !user : !user;

    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');
    const [anchorE1, setAnchorE1] = useState(null);

    const renderGreenBoxTick = useMemo(() => {
        return `data:image/svg+xml;utf8,${encodeURIComponent(
            greenBoxTick.replace('#4C726D', theme.palette.common.secondary)
        )}`;
    }, []);

    const steps = dataSet && [
        mainData['professional_title'][0].text,
        mainData.personal_information_title[0].text,
        mainData.your_body_title[0].text,
        mainData['pre-existing_conditions_title'][0].text, // here pre existing condition
        mainData.allergies_title[0].text,
        mainData.medications_title[0].text, // here medication
        mainData.family_disease_history_title[0].text,
        mainData.lifestyle_title[0].text,
        ...(isAccountPage ? [mainData.account_title[0].text] : []),
        mainDataSummary.summary_and_consent_title[0].text,
    ];

    console.debug('Questions: main', loginAnamnesis, step);

    window.addEventListener('popstate', () => {
        if (isConsentAllForms) return;
        if (user && allDataApi?.meta?.has_anamnesis && allDataApi?.documents) {
            if (step === 9) {
                console.debug('pageNot123');
                setStep(0);
            }
        }
    });

    const resetOnUnlock = () => {
        setStep(0);
        emptyStates();
        history.replace('/location');
    };

    useEffect(() => {
        if (isConsentAllForms || user) return;

        if (localStorage.getItem('sessionInSpace') === 'true') {
            document.addEventListener('visibilitychange', function () {
                if (document.visibilityState === 'visible') {
                    resetOnUnlock();
                }
            });
        }

        return () => {
            document.removeEventListener('visibilitychange', resetOnUnlock);
        };
    }, []);

    useMemo(() => {
        if (isConsentAllForms) return;
        if (!user && !loginAnamnesis) {
            if (step === 0 || step === 9) {
                history.replace('/location');
            }

            return;
        }

        if (!user && isConcent) {
            if (step === 9) {
                setStep(0);
                emptyStates('');
                console.debug('renderAgain: ');
            }
        }
        Object.keys(user ? pages[step] : pagesLogout[step]).forEach((s) => {
            if (parseInt(s) === step) {
                history.replace(`/anamnesis-health-profile?step=${user ? pages[step] : pagesLogout[step]}`);
            }
        });
        console.debug('step1234', step);

        if (user && fromAppointment && !fromLocation) {
            console.debug('step1234', step);
            if (step === 0 || step === 8) {
                setStep(0);
                history.replace(`/anamnesis-health-profile?step=${pages[0]}`);
            }
        }
    }, []);

    const onConfirmRefresh = function (event) {
        if (window.location.pathname === '/anamnesis-health-profile' && selectedPrac.length !== 0) {
            event.preventDefault();
            event.returnValue = '';
            return '';
        }
    };

    window.addEventListener('beforeunload', onConfirmRefresh);

    useEffect(() => {
        if (location) window.scrollTo(0, 0);
    }, [step, location]);

    const renderStep = () => {
        let pageStep = query.get('step');
        if (pageStep === undefined || pageStep === null) {
            pageStep = 'professional';
        }
        switch (pageStep.toLocaleLowerCase()) {
            case 'professional':
                return (
                    <Professional
                        mainData={mainData}
                        user={user}
                        mainDataProfessionalDoctors={mainDataProfessionalDoctors}
                        contentProfessional={contentProfessional}
                        mainDataSummary={mainDataSummary}
                    />
                );
            case 'personal-info':
                return <PersonalInfo mainData={mainData} allData={allData} user={user} usersession={usersession} />;
            case 'your-body':
                return <YourBody mainData={mainData} allData={allData} user={user} usersession={usersession} />;
            case 'medications':
                return <Medications mainData={mainData} user={user} usersession={usersession} />;
            case 'allergies':
                return <Allergies mainData={mainData} allData={allData} user={user} usersession={usersession} />;
            case 'existing-conditions':
                return (
                    <ExistingCondition mainData={mainData} allData={allData} user={user} usersession={usersession} />
                );
            case 'families':
                return <Family mainData={mainData} allData={allData} user={user} usersession={usersession} />;
            case 'life-style':
                return <LifeStyle mainData={mainData} allData={allData} user={user} usersession={usersession} />;
            case 'account':
                return (
                    <Account
                        mainData={mainData}
                        user={user}
                        mainDataAccount={mainDataAccount}
                        mainDataAccountEterno={mainDataAccountEterno}
                        usersession={usersession}
                    />
                );
            case 'summary':
                return (
                    <Summary
                        mainDataAllSection={mainData}
                        mainData={mainDataSummary}
                        allData={allData}
                        user={user}
                        mainDataProfessionalDoctors={mainDataProfessionalDoctors}
                        usersession={usersession}
                    />
                );
            case 'uploaded-by-me':
                return <UploadedByMe />;
            default:
                return <Professional mainData={mainData} />;
        }
    };

    useMemo(() => {
        if (!allDataApi && user) {
            yourBodyGetAPI(usersession)
                .then((res) => {
                    console.debug('Body1 get from Health Profile Main page', res);
                    setAllDataApi(res?.data);
                })
                .catch((error) => {
                    console.debug('body1 error', error);
                });
        }
    }, []);

    const handleClick = (event) => {
        var w =
            document.getElementById('mobileCodeButton').clientWidth +
            document.getElementById('main_').clientWidth +
            100;
        console.debug(w);
        setMobileCodeWidth(w.toString());
        setAnchorE1(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorE1(null);
    };

    useEffect(
        () => () => {
            const time = useTrackingStore.getState().anamnesisStarted;
            if (time) {
                trackStormlyEvent('anamnesisCancelled', {
                    time: time,
                });
            }
        },
        []
    );

    return (
        <>
            {dataSet ? (
                <div className={classes.contentContainerBody}>
                    {matches && (
                        <div
                            className=""
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 20,
                            }}
                        >
                            {/* try */}

                            <Button
                                id="mobileCodeButton"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                disableRipple
                                className={classes.formControl}
                                classes={{
                                    label: classes.menuLabel,
                                    root: classes.classRoot,
                                }}
                            >
                                <div className={classes.loadingProgress}>
                                    <CircularProgressBar
                                        save_changes_option={save_changes_option}
                                        step={step}
                                        user={user}
                                        page_no_summary={page_no_summary}
                                    />
                                </div>
                                <Typography
                                    className={classes.shortText}
                                    style={{
                                        marginBottom: 0,
                                        fontWeight: 600,
                                        marginLeft: 10,
                                        whiteSpace: 'nowrap',
                                    }}
                                    id="main_"
                                >
                                    {mainData?.anamnesis[0].text}
                                </Typography>
                                <img src={anchorE1 === null ? sideBarArrow : sideBarArrowUp} alt="" />
                            </Button>

                            <Menu
                                id="simple-menu"
                                anchorEl={anchorE1}
                                keepMounted
                                open={Boolean(anchorE1)}
                                onClose={() => {
                                    handleClose();
                                }}
                                anchorOrigin={{ vertical: 'bottom' }}
                                transformOrigin={{ vertical: 'top' }}
                                PaperProps={{
                                    style: {
                                        width: parseFloat(mobileCodeWidth),
                                        backgroundColor: 'white',
                                        boxShadow: '0px 8px 12px rgba(120, 120, 120, 0.21)',
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                    },
                                }}
                            >
                                <>
                                    {steps.map((health, index) => (
                                        <div className="" style={{ display: 'flex' }}>
                                            <div style={{ flex: 0.2 }}>
                                                <MenuItem
                                                    disableRipple
                                                    classes={{
                                                        root: classes.menuRoot,
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                    }}
                                                >
                                                    {allDataApi?.meta?.has_anamnesis &&
                                                        !save_changes_option &&
                                                        step !== index && <img src={renderGreenBoxTick} />}

                                                    {allDataApi &&
                                                        !allDataApi.meta?.has_anamnesis &&
                                                        user &&
                                                        !save_changes_option &&
                                                        step - 1 >= index && <img src={renderGreenBoxTick} />}

                                                    {!allDataApi &&
                                                        user &&
                                                        !save_changes_option &&
                                                        step - 1 >= index && <img src={renderGreenBoxTick} />}

                                                    {!user && !save_changes_option && step - 1 >= index && (
                                                        <img src={renderGreenBoxTick} />
                                                    )}

                                                    {save_changes_option &&
                                                        page_no_summary !== index &&
                                                        index < (user ? 10 : 11) && (
                                                            <img src={renderGreenBoxTick} alt="" />
                                                        )}
                                                </MenuItem>
                                            </div>
                                            <div style={{ flex: 0.7 }}>
                                                <MenuItem
                                                    disableRipple
                                                    classes={{
                                                        root: classes.menuRoot,
                                                    }}
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                        whiteSpace: 'inherit',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            marginBottom: 5,
                                                            marginTop: 5,
                                                            color: allDataApi?.meta?.has_anamnesis
                                                                ? !save_changes_option
                                                                    ? index === step
                                                                        ? theme.palette.common.footerGreen
                                                                        : theme.palette.common.brown
                                                                    : page_no_summary === index
                                                                      ? theme.palette.common.footerGreen
                                                                      : theme.palette.common.brown
                                                                : !save_changes_option
                                                                  ? index === step
                                                                      ? theme.palette.common.footerGreen
                                                                      : theme.palette.common.brown
                                                                  : page_no_summary === index
                                                                    ? theme.palette.common.footerGreen
                                                                    : theme.palette.common.brown,
                                                        }}
                                                    >
                                                        {health}
                                                    </Typography>
                                                </MenuItem>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="" style={{ marginBottom: 10 }}></div>
                                </>
                            </Menu>
                            {/* try */}
                        </div>
                    )}
                    {!matches && (
                        <Typography
                            className={classes.mainHeading}
                            style={{
                                fontWeight: 600,
                                marginLeft: 10,
                                marginBottom: 20,
                            }}
                            id="main_"
                        >
                            {mainData?.anamnesis[0].text}
                        </Typography>
                    )}
                    <Grid container>
                        {!matches && (
                            <Grid item xl={3} lg={3} md={3} className={classes.sideBarContainer}>
                                <div
                                    className=""
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: 20,
                                    }}
                                >
                                    <img src={greenHeart} alt="" className={classes.sideBarIcon} />
                                    <Typography className={classes.sideBarGreenTextMain}>
                                        {mainData?.health_data[0].text}
                                    </Typography>
                                </div>
                                {steps.map((health, index) => (
                                    <Grid container direction="row">
                                        <Grid item xl={1} lg={1} md={1}>
                                            <div
                                                className=""
                                                style={{
                                                    marginBottom: 5,
                                                    marginTop: 5,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {allDataApi &&
                                                    allDataApi !== null &&
                                                    allDataApi !== undefined &&
                                                    allDataApi['meta'] &&
                                                    allDataApi['meta'] !== undefined &&
                                                    allDataApi['meta'] !== null &&
                                                    allDataApi['meta'].has_anamnesis &&
                                                    !save_changes_option &&
                                                    step !== index && <img src={renderGreenBoxTick} />}

                                                {allDataApi &&
                                                    allDataApi !== null &&
                                                    allDataApi !== undefined &&
                                                    !allDataApi['meta'].has_anamnesis &&
                                                    user &&
                                                    !save_changes_option &&
                                                    step - 1 >= index && <img src={renderGreenBoxTick} />}

                                                {!allDataApi && user && !save_changes_option && step - 1 >= index && (
                                                    <img src={renderGreenBoxTick} />
                                                )}

                                                {!user && !save_changes_option && step - 1 >= index && (
                                                    <img src={renderGreenBoxTick} />
                                                )}
                                                {save_changes_option &&
                                                    page_no_summary !== index &&
                                                    index < (user ? 9 : 10) && <img src={renderGreenBoxTick} alt="" />}
                                            </div>
                                        </Grid>
                                        <Grid item xl={9} lg={9} md={9}>
                                            <Typography
                                                className={classes.sideBarGreenText}
                                                style={{
                                                    marginBottom: 5,
                                                    marginTop: 5,
                                                    color: allDataApi?.meta?.has_anamnesis
                                                        ? !save_changes_option
                                                            ? index === step
                                                                ? theme.palette.common.footerGreen
                                                                : theme.palette.common.brown
                                                            : page_no_summary === index
                                                              ? theme.palette.common.footerGreen
                                                              : theme.palette.common.brown
                                                        : !save_changes_option
                                                          ? index === step
                                                              ? theme.palette.common.footerGreen
                                                              : theme.palette.common.brown
                                                          : page_no_summary === index
                                                            ? theme.palette.common.footerGreen
                                                            : theme.palette.common.brown,
                                                }}
                                            >
                                                {health}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className={classes.mainContaint}>
                            {dataSet && (
                                <div className={classes.mainPage}>
                                    <div style={{ width: 200, marginBottom: 24 }}>
                                        <Typography
                                            className={classes.progressText}
                                            style={{
                                                whiteSpace: !matches && 'nowrap',
                                            }}
                                        >
                                            {!save_changes_option &&
                                                `${step + 1}${
                                                    user || (isConsentAllForms && !isLastStep) ? '/9' : '/10'
                                                } ${steps[step]}`}
                                            {save_changes_option &&
                                                `${page_no_summary + 1}${
                                                    user || (isConsentAllForms && !isLastStep) ? '/9' : '/10'
                                                } ${t(steps[page_no_summary])}`}
                                        </Typography>
                                        <BorderLinearProgress
                                            variant="determinate"
                                            value={
                                                !save_changes_option
                                                    ? parseInt(step) * (user || !isLastStep ? 11 : 10)
                                                    : parseInt(page_no_summary) * (user || !isLastStep ? 11 : 10)
                                            }
                                        />
                                    </div>
                                    {/* Calling Pages */}
                                    {renderStep()}
                                    {/* Calling Pages end */}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <div className={classes.loadingProgress}>
                    <CircularProgress className={classes.progressBar} size={50} />
                </div>
            )}
        </>
    );
}

export default HealthProfileMain;
//Practitioner>Personal Information>Your body> Pre-existing conditions page > Allergies&Reactions > then Medication page >Family history >Lifestyle Account >Summary and Consent
