import axios from 'axios';
import { processAPIWithHeaders } from '@/utils/helpers';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;
const BASE_API_URL_FOR_PRACTITIONERS = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_PRAC_API_DOMAIN}`;

export async function getUserStatus(usersession) {
    let user_id = usersession?.idToken.payload.sub;
    let token = usersession?.idToken.jwtToken;
    console.debug('user_id', user_id, token);
    try {
        const response = await axios.get(`${BASE_API_URL}/secure/user/accounts/${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        console.debug('Unsubscribe: response', response);
        return response;
    } catch (error) {
        console.debug('Unsubscribe: error of unsubscribe', error);
        return new Error(error.message);
    }
}

export async function toUnsubscribe(
    usersession,
    user_id,
    privacy_policy_status,
    terms_and_conditions_status,
    use_of_analytics_status,
    verification_status,
    language,
    country,
    status,
    notifications
) {
    let token = usersession?.idToken.jwtToken;
    try {
        const response = await processAPIWithHeaders('secure/user/accounts/' + user_id, 'POST', {
            body: {
                user_id: user_id,
                privacy_policy_status: privacy_policy_status,
                terms_and_conditions_status: terms_and_conditions_status,
                use_of_analytics_status: use_of_analytics_status,
                verification_status: verification_status,
                locale: {
                    language: language,
                    country: country,
                },
                status: status,
                subscriptions: {
                    default: false,
                },
                notifications: notifications,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        console.debug('response of reschedule', response);
        return response;
    } catch (error) {
        console.debug('error of reschedule', error);
        return new Error(error.message);
    }
}
